// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as SsoChecks from "./SsoChecks.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function SsoAllowedChecker(Props) {
  var schemaId = Props.schemaId;
  var children = Props.children;
  var match = React.useState(function (param) {
        return "InitialLoading";
      });
  var setState = match[1];
  var user = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.getAuth().currentUser));
  React.useEffect((function (param) {
          if (FirebaseFetcherHooks.isSuperUser()) {
            Promise.resolve(Curry._1(setState, (function (param) {
                        return "Allowed";
                      })));
          } else {
            SsoChecks.checkIsCurrentProviderAllowed(user, schemaId).then(function (providerAllowed) {
                  if (providerAllowed === "ProviderAllowed") {
                    return Promise.resolve(Curry._1(setState, (function (param) {
                                      return "Allowed";
                                    })));
                  } else {
                    return Promise.resolve(Curry._1(setState, (function (param) {
                                      return "NotAllowed";
                                    })));
                  }
                });
          }
        }), [schemaId]);
  return children(match[0]);
}

var make = SsoAllowedChecker;

export {
  make ,
}
/* react Not a pure module */
