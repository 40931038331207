// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ModelStore from "./ModelStore.mjs";

function EnsureModelStore(Props) {
  var children = Props.children;
  var modelStoreIsInitialized = ModelStore.useIsInitialized();
  if (modelStoreIsInitialized) {
    return children;
  } else {
    return null;
  }
}

var make = EnsureModelStore;

export {
  make ,
}
/* ModelStore Not a pure module */
