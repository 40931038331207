// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function AutoFillInputCreateItem(Props) {
  var name = Props.name;
  var autoCorrect = Props.autoCorrect;
  var correctCasing = Props.correctCasing;
  var isActive = Props.isActive;
  var isHoverable = Props.isHoverable;
  var nameWithCorrectCasing = autoCorrect ? NameUtils.getCorrectCase(correctCasing, name) : name;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("row"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: {
                          hd: Css.padding(Css.px(16)),
                          tl: {
                            hd: Css.backgroundColor(isActive ? Styles.Color.light02 : Styles.Color.white),
                            tl: {
                              hd: Css.selector("path", {
                                    hd: Css_Legacy_Core.SVG.fill(isActive ? Styles.Color.darkBlue : Styles.Color.light12),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", isActive ? Styles.Color.darkBlue : "transparent"),
                                tl: {
                                  hd: Css.color(isActive ? Styles.Color.darkBlue : Styles.Color.light12),
                                  tl: {
                                    hd: Css.hover(isHoverable ? ({
                                              hd: Css.backgroundColor(Styles.Color.light02),
                                              tl: /* [] */0
                                            }) : /* [] */0),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(1)),
                        tl: /* [] */0
                      })
                }, React.createElement(Icon.make, {
                      type_: "plus"
                    })), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement("div", undefined, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "Create \"" + nameWithCorrectCasing + "\""
                    }), name === nameWithCorrectCasing ? null : React.createElement($$Text.make, {
                        size: "Small",
                        children: "Converted to " + Case.getLabel(correctCasing) + " Case"
                      })));
}

var make = AutoFillInputCreateItem;

export {
  make ,
}
/* Css Not a pure module */
