// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";

function getId(t) {
  return t.VAL;
}

function cmp(a, b) {
  return $$String.compare(a.VAL, b.VAL);
}

var Standalone = {
  getId: getId,
  cmp: cmp
};

function getId$1(t) {
  return t.VAL;
}

function cmp$1(a, b) {
  return $$String.compare(a.VAL, b.VAL);
}

var EventSpecific = {
  getId: getId$1,
  cmp: cmp$1
};

function getId$2(t) {
  return t.VAL;
}

function cmp$2(a, b) {
  return $$String.compare(a.VAL, b.VAL);
}

var SourceSpecific = {
  getId: getId$2,
  cmp: cmp$2
};

function getId$3(t) {
  return t.VAL;
}

function cmp$3(a, b) {
  return $$String.compare(a.VAL, b.VAL);
}

var $$Event = {
  getId: getId$3,
  cmp: cmp$3
};

function toStandalone(t) {
  var variant = t.NAME;
  if (variant === "eventProperty") {
    return {
            NAME: "property",
            VAL: t.VAL
          };
  } else if (variant === "eventPropertyBundle") {
    return {
            NAME: "propertyBundle",
            VAL: t.VAL
          };
  } else if (variant === "eventSource") {
    return {
            NAME: "source",
            VAL: t.VAL
          };
  } else {
    return {
            NAME: "destination",
            VAL: t.VAL
          };
  }
}

function sourceSpecificToEventSpecific(t) {
  return {
          NAME: "eventDestination",
          VAL: t.VAL
        };
}

function getId$4(t) {
  return t.VAL;
}

function getId$5(t) {
  return t.VAL;
}

function cmp$4(a, b) {
  var a$1;
  var b$1;
  var variant = a.NAME;
  if (variant === "sourceDestination") {
    var variant$1 = b.NAME;
    if (!(variant$1 === "source" || variant$1 === "category" || variant$1 === "property" || variant$1 === "propertyBundle" || variant$1 === "eventSource" || variant$1 === "eventPropertyBundle" || variant$1 === "eventProperty" || variant$1 === "sourceDestination")) {
      if (variant$1 === "destination") {
        return -1;
      } else {
        return 1;
      }
    }
    a$1 = a;
    b$1 = b;
  } else if (variant === "eventProperty") {
    if (b.NAME === "property") {
      return -1;
    }
    a$1 = a;
    b$1 = b;
  } else if (variant === "eventPropertyBundle") {
    if (b.NAME === "propertyBundle") {
      return -1;
    }
    a$1 = a;
    b$1 = b;
  } else if (variant === "eventSource") {
    if (b.NAME === "source") {
      return -1;
    }
    a$1 = a;
    b$1 = b;
  } else if (variant === "propertyBundle") {
    if (b.NAME === "eventPropertyBundle") {
      return 1;
    }
    a$1 = a;
    b$1 = b;
  } else if (variant === "destination") {
    var variant$2 = b.NAME;
    if (!(variant$2 === "source" || variant$2 === "category" || variant$2 === "property" || variant$2 === "destination" || variant$2 === "propertyBundle" || variant$2 === "eventSource" || variant$2 === "eventPropertyBundle" || variant$2 === "eventProperty")) {
      return 1;
    }
    a$1 = a;
    b$1 = b;
  } else if (variant === "property") {
    if (b.NAME === "eventProperty") {
      return 1;
    }
    a$1 = a;
    b$1 = b;
  } else if (variant === "category") {
    a$1 = a;
    b$1 = b;
  } else if (variant === "source") {
    if (b.NAME === "eventSource") {
      return 1;
    }
    a$1 = a;
    b$1 = b;
  } else {
    var variant$3 = b.NAME;
    if (variant$3 === "destination" || variant$3 === "sourceDestination") {
      return -1;
    }
    a$1 = a;
    b$1 = b;
  }
  return $$String.compare(getId$5(a$1), getId$5(b$1));
}

var TypeDiscrepancies = {
  getId: getId$5,
  cmp: cmp$4
};

function cmp$5(a, b) {
  if (typeof a === "object") {
    var variant = a.NAME;
    if ((variant === "source" || variant === "category" || variant === "property" || variant === "destination" || variant === "propertyBundle" || variant === "eventSource" || variant === "eventPropertyBundle" || variant === "eventProperty" || variant === "sourceDestination" || variant === "eventDestination") && typeof b === "object") {
      var variant$1 = b.NAME;
      if (variant$1 === "source" || variant$1 === "category" || variant$1 === "property" || variant$1 === "destination" || variant$1 === "propertyBundle" || variant$1 === "eventSource" || variant$1 === "eventPropertyBundle" || variant$1 === "eventProperty" || variant$1 === "sourceDestination" || variant$1 === "eventDestination") {
        return cmp$4(a, b);
      }
      
    }
    
  }
  return $$String.compare(getId$4(a), getId$4(b));
}

var Comparator = Belt_Id.MakeComparable({
      cmp: cmp$5
    });

function cmp$6(a, b) {
  return $$String.compare(AnalyticsRe.selectedShapeRelatedChangeItemsExcludedToJs(a), AnalyticsRe.selectedShapeRelatedChangeItemsExcludedToJs(b));
}

var SelectedShapeRelatedChangeItemsExcludedComparator = Belt_Id.MakeComparable({
      cmp: cmp$6
    });

function getIgnoredAnalyticsTypes(ignoredItems) {
  return Belt_Set.toArray(Belt_Set.fromArray(Belt_Map.valuesToArray(Belt_Map.mapWithKey(ignoredItems, (function (item, param) {
                            var variant = item.NAME;
                            if (variant === "property" || variant === "eventProperty") {
                              return "Property";
                            } else if (variant === "propertyBundle" || variant === "eventPropertyBundle") {
                              return "PropertyBundle";
                            } else if (variant === "event") {
                              return "Event";
                            } else if (variant === "source" || variant === "eventSource") {
                              return "Source";
                            } else if (variant === "category") {
                              return "Category";
                            } else {
                              return "Destination";
                            }
                          }))), SelectedShapeRelatedChangeItemsExcludedComparator));
}

var CopyItem = {
  Standalone: Standalone,
  EventSpecific: EventSpecific,
  SourceSpecific: SourceSpecific,
  $$Event: $$Event,
  toStandalone: toStandalone,
  sourceSpecificToEventSpecific: sourceSpecificToEventSpecific,
  getId: getId$4,
  TypeDiscrepancies: TypeDiscrepancies,
  cmp: cmp$5,
  Comparator: Comparator,
  SelectedShapeRelatedChangeItemsExcludedComparator: SelectedShapeRelatedChangeItemsExcludedComparator,
  getIgnoredAnalyticsTypes: getIgnoredAnalyticsTypes
};

function toString(t) {
  if (t === "Property") {
    return "property";
  } else if (t === "Event") {
    return "event";
  } else if (t === "PropertyBundle") {
    return "property bundle";
  } else {
    return "source";
  }
}

function fromCopyItems(copyItems) {
  return Belt_Option.mapWithDefault(Belt_Array.get(Belt_SortArray.stableSortBy(copyItems, (function (a, b) {
                        var exit = 0;
                        var exit$1 = 0;
                        var exit$2 = 0;
                        var exit$3 = 0;
                        var exit$4 = 0;
                        var exit$5 = 0;
                        if (typeof a === "object") {
                          if (a.NAME === "event") {
                            return -1;
                          }
                          exit$5 = 7;
                        } else {
                          exit$5 = 7;
                        }
                        if (exit$5 === 7) {
                          if (typeof b === "object") {
                            if (b.NAME === "event") {
                              return 1;
                            }
                            exit$4 = 6;
                          } else {
                            exit$4 = 6;
                          }
                        }
                        if (exit$4 === 6) {
                          if (typeof a === "object") {
                            if (a.NAME === "propertyBundle") {
                              return -1;
                            }
                            exit$3 = 5;
                          } else {
                            exit$3 = 5;
                          }
                        }
                        if (exit$3 === 5) {
                          if (typeof b === "object") {
                            if (b.NAME === "propertyBundle") {
                              return 1;
                            }
                            exit$2 = 4;
                          } else {
                            exit$2 = 4;
                          }
                        }
                        if (exit$2 === 4) {
                          if (typeof a === "object") {
                            if (a.NAME === "property") {
                              return -1;
                            }
                            exit$1 = 3;
                          } else {
                            exit$1 = 3;
                          }
                        }
                        if (exit$1 === 3) {
                          if (typeof b === "object") {
                            if (b.NAME === "property") {
                              return 1;
                            }
                            exit = 2;
                          } else {
                            exit = 2;
                          }
                        }
                        if (exit === 2 && typeof a === "object" && a.NAME === "source") {
                          return -1;
                        }
                        if (typeof b === "object" && b.NAME === "source") {
                          return 1;
                        } else {
                          return 0;
                        }
                      })), 0), "Event", (function (copyItem) {
                if (typeof copyItem !== "object") {
                  return Pervasives.failwith("No other copy items supported yet");
                }
                var variant = copyItem.NAME;
                if (variant === "event") {
                  return "Event";
                } else if (variant === "propertyBundle") {
                  return "PropertyBundle";
                } else if (variant === "property") {
                  return "Property";
                } else if (variant === "source") {
                  return "Source";
                } else {
                  return Pervasives.failwith("No other copy items supported yet");
                }
              }));
}

var ParentType = {
  toString: toString,
  fromCopyItems: fromCopyItems
};

export {
  CopyItem ,
  ParentType ,
}
/* Comparator Not a pure module */
