// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as FetchModel from "./FetchModel.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RouterStore from "./RouterStore.mjs";
import * as RouterTypes from "./RouterTypes.mjs";
import * as EventDetails from "./detailviews/EventDetails.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as PropertyDetails from "./detailviews/PropertyDetails.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as LoadingFullscreen from "./LoadingFullscreen.mjs";
import * as NamespaceIndexStore from "./namespaceIndex/NamespaceIndexStore.mjs";
import * as DrawerContainer__ItemNotFound from "./DrawerContainer__ItemNotFound.mjs";
import * as DrawerContainer__EventNotFound from "./DrawerContainer__EventNotFound.mjs";

function DrawerContainer__BranchPreview$EventPreview(Props) {
  var branchStatus = Props.branchStatus;
  var $$event = Props.event;
  var schema = Props.schema;
  var branchId = Props.branchId;
  var openBranches = Props.openBranches;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var isFullscreen = Props.isFullscreen;
  var model = Props.model;
  var openModal = GlobalSendContext.useOpenModal();
  var events = Curry._1(NamespaceIndexStore.Events.use, undefined).items;
  var drawerItems = RouterStore.Schema.useDrawerItems();
  var eventNamespaceItems = React.useMemo((function (param) {
          return Belt_MapString.get(events, $$event.name);
        }), [
        $$event.name,
        events
      ]);
  var pullPreviewItem = function (param) {
    if (eventNamespaceItems !== undefined) {
      return Curry._1(openModal, {
                  NAME: "PullChanges",
                  VAL: [
                    eventNamespaceItems,
                    (function (_sendActions, resolvedCopyItems) {
                        var maybeEvent = Belt_Array.get(Belt_Array.keepMap(resolvedCopyItems, (function (item) {
                                    if (typeof item === "object" && item.NAME === "event") {
                                      return item.VAL;
                                    }
                                    
                                  })), 0);
                        if (maybeEvent === undefined) {
                          return Curry._1(openModal, {
                                      NAME: "AlertModal",
                                      VAL: [
                                        "Could not pull event",
                                        "Please try adding the event again.",
                                        "Ok",
                                        (function (param) {
                                            
                                          })
                                      ]
                                    });
                        }
                        var newDrawerItems_0 = {
                          NAME: "event",
                          VAL: [
                            maybeEvent.id,
                            undefined,
                            undefined,
                            false
                          ]
                        };
                        var newDrawerItems_1 = Belt_List.keep(drawerItems, (function (drawerItem) {
                                if (typeof drawerItem !== "object") {
                                  return true;
                                }
                                if (drawerItem.NAME !== "branchPreview") {
                                  return true;
                                }
                                var match = drawerItem.VAL[1];
                                if (typeof match === "object" && match.NAME === "event") {
                                  return maybeEvent.id !== match.VAL[0];
                                } else {
                                  return true;
                                }
                              }));
                        var newDrawerItems = {
                          hd: newDrawerItems_0,
                          tl: newDrawerItems_1
                        };
                        Router.Schema.replaceDrawerItems(undefined, newDrawerItems);
                      }),
                    "Event",
                    "EventDetails"
                  ]
                });
    }
    
  };
  return React.createElement(EventDetails.make, {
              branchStatus: branchStatus,
              event: $$event,
              schema: schema,
              currentBranch: {
                NAME: "Branch",
                VAL: branchId
              },
              openBranches: openBranches,
              currentFilters: currentFilters,
              filters: filters,
              role: "Viewer",
              isBranchPreview: true,
              pullPreviewItem: pullPreviewItem,
              isFullscreen: isFullscreen,
              model: model
            });
}

var EventPreview = {
  make: DrawerContainer__BranchPreview$EventPreview
};

function DrawerContainer__BranchPreview$PropertyPreview(Props) {
  var branchId = Props.branchId;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var maybeCommentId = Props.maybeCommentId;
  var member = Props.member;
  var model = Props.model;
  var property = Props.property;
  var schema = Props.schema;
  var openModal = GlobalSendContext.useOpenModal();
  var drawerItems = RouterStore.Schema.useDrawerItems();
  var match = property.sendAs;
  var properties;
  if (typeof match !== "object") {
    switch (match) {
      case "SystemProperty" :
          properties = Curry._1(NamespaceIndexStore.SystemProperties.use, undefined).items;
          break;
      case "EventProperty" :
          properties = Curry._1(NamespaceIndexStore.EventProperties.use, undefined).items;
          break;
      case "UserProperty" :
          properties = Curry._1(NamespaceIndexStore.UserProperties.use, undefined).items;
          break;
      
    }
  } else {
    properties = Pervasives.failwith("Group properties not supported in branch preview");
  }
  var propertyNamespaceItems = React.useMemo((function (param) {
          return Belt_MapString.get(properties, property.name);
        }), [
        property.name,
        properties
      ]);
  var pullPreviewItem = function (param) {
    if (propertyNamespaceItems !== undefined) {
      return Curry._1(openModal, {
                  NAME: "PullChanges",
                  VAL: [
                    propertyNamespaceItems,
                    (function (_sendActions, resolvedCopyItems) {
                        var maybeProperty = Belt_Array.get(Belt_Array.keepMap(resolvedCopyItems, (function (item) {
                                    if (typeof item !== "object") {
                                      return ;
                                    }
                                    var variant = item.NAME;
                                    if (variant === "property" || variant === "eventProperty") {
                                      return item.VAL;
                                    }
                                    
                                  })), 0);
                        if (maybeProperty === undefined) {
                          return Curry._1(openModal, {
                                      NAME: "AlertModal",
                                      VAL: [
                                        "Could not pull property",
                                        "Please try adding the property again.",
                                        "Ok",
                                        (function (param) {
                                            
                                          })
                                      ]
                                    });
                        }
                        var newDrawerItems_0 = {
                          NAME: "property",
                          VAL: [
                            maybeProperty.id,
                            undefined
                          ]
                        };
                        var newDrawerItems_1 = Belt_List.keep(drawerItems, (function (drawerItem) {
                                if (typeof drawerItem !== "object") {
                                  return true;
                                }
                                if (drawerItem.NAME !== "branchPreview") {
                                  return true;
                                }
                                var match = drawerItem.VAL[1];
                                if (typeof match === "object" && match.NAME === "property") {
                                  return maybeProperty.id !== match.VAL[0];
                                } else {
                                  return true;
                                }
                              }));
                        var newDrawerItems = {
                          hd: newDrawerItems_0,
                          tl: newDrawerItems_1
                        };
                        Router.Schema.replaceDrawerItems(undefined, newDrawerItems);
                      }),
                    "Property",
                    "PropertyDetails"
                  ]
                });
    }
    
  };
  return React.createElement(PropertyDetails.make, {
              currentBranch: {
                NAME: "Branch",
                VAL: branchId
              },
              currentFilters: currentFilters,
              filters: filters,
              eventId: undefined,
              goToCommentId: maybeCommentId,
              member: member,
              model: model,
              openBranches: model.openBranches,
              propertyId: property.id,
              role: "Viewer",
              schema: schema,
              isBranchPreview: true,
              pullPreviewItem: pullPreviewItem
            });
}

var PropertyPreview = {
  make: DrawerContainer__BranchPreview$PropertyPreview
};

function DrawerContainer__BranchPreview(Props) {
  var schema = Props.schema;
  var branchId = Props.branchId;
  var previewItem = Props.previewItem;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var mainModel = ModelStore.useMainModel();
  var member = WorkspaceContext.useMember();
  var model = FetchModel.use(undefined, undefined, undefined, {
        NAME: "branch",
        VAL: branchId
      });
  var maybeRenderModel = React.useMemo((function (param) {
          switch (model.TAG) {
            case "Loading" :
            case "Error" :
                return ;
            case "Result" :
                var model$1 = model._0;
                return AvoModel.enrichModel(AvoModel.softEnrichModel(model$1, AvoConfig.getValidSources(model$1.sources, model$1)), AvoConfig.getValidSources(model$1.sources, model$1));
            
          }
        }), [model]);
  switch (model.TAG) {
    case "Loading" :
        return React.createElement(LoadingFullscreen.make, {
                    message: model._0
                  });
    case "Error" :
        return React.createElement(FetchModel.$$Error.make, {
                    message: model._0
                  });
    case "Result" :
        if (maybeRenderModel === undefined) {
          return React.createElement(LoadingFullscreen.make, {
                      message: "Preparing model..."
                    });
        }
        if (typeof previewItem === "object") {
          var variant = previewItem.NAME;
          var branchStatus = model._4;
          if (variant === "event") {
            var match = previewItem.VAL;
            var isFullscreen = match[3];
            var eventId = match[0];
            return Belt_Option.mapWithDefault(ModelUtils.getEventById(eventId, maybeRenderModel), React.createElement(DrawerContainer__EventNotFound.make, {
                            eventId: eventId
                          }), (function ($$event) {
                          return React.createElement(DrawerContainer__BranchPreview$EventPreview, {
                                      branchStatus: branchStatus,
                                      event: $$event,
                                      schema: schema,
                                      branchId: branchId,
                                      openBranches: mainModel.openBranches,
                                      currentFilters: currentFilters,
                                      filters: filters,
                                      isFullscreen: isFullscreen,
                                      model: maybeRenderModel
                                    });
                        }));
          }
          if (variant === "property" && member !== undefined) {
            var match$1 = previewItem.VAL;
            var propertyId = match$1[0];
            var match$2 = Belt_List.getBy(maybeRenderModel.properties, (function (p) {
                    if (p.TAG === "PropertyRef") {
                      return false;
                    } else {
                      return p._0.id === propertyId;
                    }
                  }));
            if (match$2 === undefined) {
              return React.createElement(DrawerContainer__ItemNotFound.make, {
                          kind: "Property"
                        });
            }
            if (match$2.TAG === "PropertyRef") {
              return React.createElement(DrawerContainer__ItemNotFound.make, {
                          kind: "Property"
                        });
            }
            var tmp = {
              branchId: branchId,
              currentFilters: currentFilters,
              filters: filters,
              member: Caml_option.valFromOption(member),
              model: maybeRenderModel,
              property: match$2._0,
              schema: schema
            };
            var tmp$1 = match$1[1];
            if (tmp$1 !== undefined) {
              tmp.maybeCommentId = Caml_option.valFromOption(tmp$1);
            }
            return React.createElement(DrawerContainer__BranchPreview$PropertyPreview, tmp);
          }
          
        }
        break;
    
  }
  return React.createElement(DrawerContainer__ItemNotFound.make, {
              kind: "Branch Preview: " + RouterTypes.DrawerItem.toIdentifier(previewItem)
            });
}

var make = DrawerContainer__BranchPreview;

export {
  EventPreview ,
  PropertyPreview ,
  make ,
}
/* react Not a pure module */
