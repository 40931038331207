// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "./Hover.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Models from "./Models.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "./DateFns.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconErase from "./IconErase.mjs";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as GlobalPill from "./GlobalPill.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";

function SidebarBranchPopup$SidebarBranchPopupListItemContent(Props) {
  var openAction = Props.openAction;
  var user = FirebaseFetcherHooks.useUser(openAction.createdBy);
  var date = Models.toDateOption(openAction.createdAt);
  return React.createElement(React.Fragment, undefined, "Opened ", date !== undefined ? DateFns.formatDistanceToNow(false, false, Caml_option.valFromOption(date)) + " ago" : null, typeof user === "object" ? " by " + AvoConfig.getUserDisplayName(user.VAL) : (
                user === "NotFound" ? null : " by …"
              ));
}

var SidebarBranchPopupListItemContent = {
  make: SidebarBranchPopup$SidebarBranchPopupListItemContent
};

function listItemStyles(isCurrentBranch) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.display("block"),
                    tl: {
                      hd: Css.padding2(Css.px(5), Css.px(20)),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$default
                            }, undefined, undefined, "background-color"),
                        tl: {
                          hd: Css.hover({
                                hd: Css.backgroundColor(Styles.Color.light02),
                                tl: /* [] */0
                              }),
                          tl: {
                            hd: Css.active({
                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.focus({
                                    hd: Css.outlineStyle("none"),
                                    tl: {
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), true, Styles.Color.darkBlue)),
                                      tl: {
                                        hd: Css.borderRadius(Styles.Border.radius),
                                        tl: /* [] */0
                                      }
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: isCurrentBranch ? Curry._1(Css.style, {
                        hd: Css.backgroundColor(Styles.Color.light02),
                        tl: /* [] */0
                      }) : Curry._1(Css.style, /* [] */0),
                tl: /* [] */0
              }
            });
}

function branchNameStyles(isHover) {
  return Curry._1(Css.style, {
              hd: Css.fontWeight(Styles.FontWeight.medium),
              tl: {
                hd: Css.fontSize(Styles.FontSize.regular),
                tl: {
                  hd: Css.lineHeight(Styles.LineHeight.regular),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: Styles.Duration.$$default
                        }, undefined, undefined, "all"),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.gap(Css.px(4)),
                          tl: {
                            hd: Css.color(isHover ? Styles.Color.light12 : Styles.Color.light10),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function infoStyles(isHover) {
  return Curry._1(Css.style, {
              hd: Css.fontWeight(Styles.FontWeight.medium),
              tl: {
                hd: Css.fontSize(Styles.FontSize.small),
                tl: {
                  hd: Css.lineHeight(Styles.LineHeight.regular),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: Styles.Duration.$$default
                        }, undefined, undefined, "color"),
                    tl: {
                      hd: Css.wordBreak("normal"),
                      tl: {
                        hd: Css.color(isHover ? Styles.Color.light10 : Styles.Color.light08),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function SidebarBranchPopup$SidebarBranchPopupListItem(Props) {
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var isGlobalRequirementsBranch = GlobalRequirementsUtils.isGlobalBranch(branchId);
  var schemaBundle = SchemaBundleContext.use();
  var match = Router.Schema.getBranch();
  var isCurrentBranch = typeof match === "object" ? match.VAL === branchId : branchId === "master";
  var normalOpenAction = FirebaseFetcherHooks.useBranchOpenAction(schemaBundle.schemaId, branchId, "NonDemo", undefined, undefined);
  var demoOpenAction = FirebaseFetcherHooks.useBranchOpenAction(schemaBundle.schemaId, branchId, "Demo", undefined, undefined);
  var openAction = React.useMemo((function (param) {
          if (normalOpenAction !== undefined) {
            return normalOpenAction;
          } else if (demoOpenAction !== undefined) {
            return demoOpenAction;
          } else {
            return ;
          }
        }), [
        normalOpenAction,
        demoOpenAction
      ]);
  return React.createElement(Hover.make, {
              children: (function (isHover) {
                  var match = branchId === "master";
                  return React.createElement(Link.make, {
                              path: Router.Schema.getSwappedBranches(undefined, {
                                    NAME: "branch",
                                    VAL: branchId
                                  }),
                              className: listItemStyles(isCurrentBranch),
                              onClick: (function (param) {
                                  AnalyticsRe.branchLinkClicked(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, branchId, "BranchesPopup");
                                }),
                              children: null
                            }, React.createElement("div", {
                                  className: branchNameStyles(isHover)
                                }, React.createElement($$Text.make, {
                                      singleLine: true,
                                      children: branchName
                                    }), isGlobalRequirementsBranch ? React.createElement(GlobalPill.make, {}) : null), React.createElement("div", {
                                  className: infoStyles(isHover)
                                }, openAction !== undefined ? React.createElement(SidebarBranchPopup$SidebarBranchPopupListItemContent, {
                                        openAction: Caml_option.valFromOption(openAction)
                                      }) : (
                                    match ? "The default branch" : "Opened …"
                                  )));
                })
            });
}

var SidebarBranchPopupListItem = {
  listItemStyles: listItemStyles,
  branchNameStyles: branchNameStyles,
  infoStyles: infoStyles,
  make: SidebarBranchPopup$SidebarBranchPopupListItem
};

var rootStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(300)),
      tl: {
        hd: Css.marginBottom(Css.px(15)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.borderRadius(Styles.Border.radius),
            tl: {
              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                            NAME: "num",
                            VAL: 0.10
                          }))),
              tl: {
                hd: Css.overflow("hidden"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.minHeight(Css.px(50)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
              tl: {
                hd: Css.padding4(Css.px(7), Css.px(12), Css.px(5), Css.px(20)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var filterStyles = Curry._1(Css.style, {
      hd: Css.minWidth(Css.px(0)),
      tl: {
        hd: Css.width(Css.px(100)),
        tl: {
          hd: Css.flexShrink(1.0),
          tl: {
            hd: Css.flexGrow(1.0),
            tl: {
              hd: Css.borderWidth(Css.px(0)),
              tl: {
                hd: Css.fontSize(Styles.FontSize.regular),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.medium),
                  tl: {
                    hd: Css.lineHeight(Styles.LineHeight.regular),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: {
                        hd: Css.paddingTop(Css.px(0)),
                        tl: {
                          hd: Css.paddingRight(Css.px(8)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(0)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(0)),
                              tl: {
                                hd: Css.placeholder({
                                      hd: Css.color(Styles.Color.light08),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.focus({
                                        hd: Css.outlineStyle("none"),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var eraseButtonStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(0)),
      tl: {
        hd: Css.marginTop(Css.px(0)),
        tl: {
          hd: Css.marginRight(Css.px(8)),
          tl: {
            hd: Css.marginBottom(Css.px(0)),
            tl: {
              hd: Css.marginLeft(Css.px(0)),
              tl: {
                hd: Css.borderWidth(Css.px(0)),
                tl: {
                  hd: Css.borderRadius(Css.px(2)),
                  tl: {
                    hd: Css.background("transparent"),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "opacity"),
                      tl: {
                        hd: Css.unsafe("appearance", "none"),
                        tl: {
                          hd: Css.outlineStyle("none"),
                          tl: {
                            hd: Css.cursor("pointer"),
                            tl: {
                              hd: Css.opacity(1.0),
                              tl: {
                                hd: Css.disabled({
                                      hd: Css.opacity(0.0),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.active({
                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.focus({
                                          hd: Css.outlineStyle("none"),
                                          tl: {
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var listStyles = Curry._1(Css.style, {
      hd: Css.maxHeight({
            NAME: "subtract",
            VAL: [
              Css.vh(50),
              Css.px(50)
            ]
          }),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.paddingTop(Css.px(6)),
          tl: {
            hd: Css.paddingBottom(Css.px(6)),
            tl: /* [] */0
          }
        }
      }
    });

var noBranchesStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.padding2(Css.px(16), Css.px(20)),
            tl: /* [] */0
          }
        }
      }
    });

var linkStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.darkBlue),
      tl: {
        hd: Css.hover({
              hd: Css.color(Styles.Color.deepBlue),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var footerStyles = Curry._1(Css.style, {
      hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
      tl: {
        hd: Css.paddingTop(Css.px(12)),
        tl: {
          hd: Css.paddingRight(Css.px(8)),
          tl: {
            hd: Css.paddingBottom(Css.px(16)),
            tl: {
              hd: Css.paddingLeft(Css.px(20)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var footerLinkStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.darkBlue),
      tl: {
        hd: Css.hover({
              hd: Css.color(Styles.Color.deepBlue),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

function SidebarBranchPopup(Props) {
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var onClose = Props.onClose;
  var globalSend = Props.globalSend;
  var role = Props.role;
  var match = React.useState(function (param) {
        return "";
      });
  var setFilter = match[1];
  var filter = match[0];
  var handleNewBranchClick = function (param) {
    Curry._1(globalSend, {
          TAG: "OpenModal",
          _0: {
            NAME: "NewBranch",
            VAL: [
              undefined,
              filter
            ]
          }
        });
    Curry._1(onClose, undefined);
  };
  var branchRecords = Belt_List.map({
        hd: [
          "master",
          "main"
        ],
        tl: Belt_List.reverse(openBranches)
      }, (function (param) {
          return {
                  id: param[0],
                  name: param[1]
                };
        }));
  var filteredBranches = Fuzzaldrin.filter(Belt_List.toArray(branchRecords), filter, {
        key: "name",
        maxResults: 100
      });
  var getBranchName = function (branchId, name) {
    if (GlobalRequirementsUtils.isGlobalBranch(branchId)) {
      return GlobalRequirementsUtils.getNormalizedName(branchId);
    } else {
      return name;
    }
  };
  var tmp;
  var exit = 0;
  if (currentBranch === "Master" && !openBranches) {
    tmp = React.createElement("div", {
          className: noBranchesStyles
        }, React.createElement($$Text.make, {
              size: "Medium",
              weight: "Medium",
              textAlign: "center",
              color: Styles.Color.light08,
              children: null
            }, "There are no open branches—yet. Branches make it seamless to collaborate on tracking plan changes. Read more in ", React.createElement("a", {
                  className: linkStyles,
                  href: "https://www.avo.app/docs/workspace/branches",
                  rel: "noopener",
                  target: "_blank"
                }, "our docs"), "."));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = filteredBranches.length !== 0 ? React.createElement("div", {
            className: listStyles
          }, Belt_Array.mapU(filteredBranches, (function (param) {
                  var id = param.id;
                  return React.createElement(SidebarBranchPopup$SidebarBranchPopupListItem, {
                              branchId: id,
                              branchName: getBranchName(id, param.name),
                              key: id
                            });
                }))) : React.createElement("div", {
            className: noBranchesStyles
          }, React.createElement($$Text.make, {
                size: "Medium",
                weight: "Medium",
                textAlign: "center",
                color: Styles.Color.light08,
                children: "Found no matches"
              }));
  }
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("div", {
                  className: headerStyles
                }, React.createElement("input", {
                      className: filterStyles,
                      autoFocus: true,
                      placeholder: "Switch branch...",
                      value: filter,
                      onChange: (function ($$event) {
                          var value = $$event.target.value;
                          Curry._1(setFilter, (function (param) {
                                  return value;
                                }));
                        })
                    }), React.createElement("button", {
                      className: eraseButtonStyles,
                      disabled: filter === "",
                      onClick: (function (param) {
                          Curry._1(setFilter, (function (param) {
                                  return "";
                                }));
                        })
                    }, React.createElement(IconErase.make, {
                          color: Styles.Color.light07,
                          hoverColor: Styles.Color.red
                        })), RoleUtils.canEdit(role) && currentBranch === "Master" ? React.createElement(Button.make, {
                        label: "Create branch",
                        onClick: handleNewBranchClick
                      }) : null), tmp, openBranches === /* [] */0 ? null : React.createElement("div", {
                    className: footerStyles
                  }, React.createElement(Link.make, {
                        path: Router.Link.getSchemaRouteLink(undefined, "dashboard"),
                        className: footerLinkStyles,
                        onClick: (function (param) {
                            Curry._1(onClose, undefined);
                          }),
                        children: React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Medium",
                              children: "See all branches"
                            })
                      })));
}

var make = SidebarBranchPopup;

export {
  SidebarBranchPopupListItemContent ,
  SidebarBranchPopupListItem ,
  rootStyles ,
  headerStyles ,
  filterStyles ,
  eraseButtonStyles ,
  listStyles ,
  noBranchesStyles ,
  linkStyles ,
  footerStyles ,
  footerLinkStyles ,
  make ,
}
/* rootStyles Not a pure module */
