// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as DiffSource from "./DiffSource.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CopyChangesToBranchModal__Utils from "./CopyChangesToBranchModal__Utils.mjs";
import * as CopyChangesToBranchModal__IncludeItemDropdown from "./CopyChangesToBranchModal__IncludeItemDropdown.mjs";

function CopyChangesToBranch__Sources(Props) {
  var allEventActions = Props.allEventActions;
  var currentBranchId = Props.currentBranchId;
  var eventActions = Props.eventActions;
  var fromModel = Props.fromModel;
  var ignoredItems = Props.ignoredItems;
  var onToggleItem = Props.onToggleItem;
  var parentType = Props.parentType;
  var sourceActions = Props.sourceActions;
  var toModel = Props.toModel;
  var toModelWithAppliedActions = Props.toModelWithAppliedActions;
  var eventSourceActions = CopyChangesToBranchModal__Utils.includeEventActionsForSources(allEventActions, sourceActions);
  if (eventSourceActions !== /* [] */0) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.gap(Css.px(8)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.paddingTop(Css.px(16)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(12)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light10,
                        children: allEventActions !== /* [] */0 ? "Source changes related to the event" : "Source changes"
                      })), Belt_List.toArray(Belt_List.map(eventSourceActions, (function (actions) {
                          var itemId = CopyChangesToBranchModal__Utils.Actions.getItemId(actions);
                          var item = {
                            NAME: "eventSource",
                            VAL: itemId
                          };
                          var tmp = {
                            key: itemId
                          };
                          var tmp$1 = CopyChangesToBranchModal__Utils.IgnoreState.itemIsDisabled(ignoredItems, item) ? Curry._1(Css.style, {
                                  hd: Css.opacity(0.6),
                                  tl: {
                                    hd: Css.filter({
                                          hd: {
                                            NAME: "grayscale",
                                            VAL: 100.0
                                          },
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }) : undefined;
                          if (tmp$1 !== undefined) {
                            tmp.className = Caml_option.valFromOption(tmp$1);
                          }
                          var tmp$2;
                          if (allEventActions === /* [] */0) {
                            tmp$2 = null;
                          } else {
                            var actions$1 = Belt_List.getBy(sourceActions, (function (sourceItemActions) {
                                    if (!sourceItemActions) {
                                      return false;
                                    }
                                    var match = sourceItemActions.hd[0];
                                    if (typeof match === "object" && match.NAME === "Source") {
                                      return match.VAL === CopyChangesToBranchModal__Utils.Actions.getItemId(actions);
                                    } else {
                                      return false;
                                    }
                                  }));
                            tmp$2 = React.createElement("div", {
                                  onClick: (function (prim) {
                                      prim.stopPropagation();
                                    })
                                }, React.createElement(CopyChangesToBranchModal__IncludeItemDropdown.make, {
                                      onChange: Curry._1(onToggleItem, item),
                                      value: CopyChangesToBranchModal__Utils.IgnoreState.getItemIgnoreStateValue(ignoredItems, item),
                                      allowExcludeItem: CopyChangesToBranchModal__Utils.Item.itemAdded(eventActions, item),
                                      allowExcludeChanges: actions$1 !== undefined ? CopyChangesToBranchModal__Utils.Item.existingItem(actions$1, item) : false,
                                      itemName: "source",
                                      parentType: parentType
                                    }));
                          }
                          return React.createElement("div", tmp, React.createElement(DiffSource.make, {
                                          actions: actions,
                                          masterModel: fromModel,
                                          branchModel: Belt_Map.has(ignoredItems, {
                                                NAME: "eventSource",
                                                VAL: CopyChangesToBranchModal__Utils.Actions.getItemId(actions)
                                              }) ? toModel : toModelWithAppliedActions,
                                          branchStatus: "Loading",
                                          sourceId: CopyChangesToBranchModal__Utils.Actions.getItemId(actions),
                                          branchId: currentBranchId,
                                          withCopyToBranch: false,
                                          collapsible: true,
                                          rightElement: tmp$2,
                                          key: CopyChangesToBranchModal__Utils.Actions.getItemId(actions)
                                        }));
                        }))));
  } else {
    return null;
  }
}

var Utils;

var make = CopyChangesToBranch__Sources;

export {
  Utils ,
  make ,
}
/* Css Not a pure module */
