// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AutofillInputNew from "./AutofillInputNew.mjs";

function getId(item) {
  return item.VAL.id;
}

function messageToString(msg) {
  if (msg.NAME === "AlreadyExists") {
    var match = msg.VAL;
    return match[0] + "-" + getId(match[1]);
  }
  var match$1 = msg.VAL;
  return match$1[0] + "-" + match$1[1].id;
}

var Config = {
  getId: getId,
  messageToString: messageToString
};

var include = AutofillInputNew.Make(Config);

var Popup = include.Popup;

var make = include.make;

export {
  Config ,
  Popup ,
  make ,
}
/* include Not a pure module */
