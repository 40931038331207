// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

function Hover(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : /* [] */0;
  var match = React.useState(function (param) {
        return false;
      });
  var setHover = match[1];
  return React.createElement("span", {
              className: Curry._1(Css.style, className),
              onMouseEnter: (function (param) {
                  Curry._1(setHover, (function (param) {
                          return true;
                        }));
                }),
              onMouseLeave: (function (param) {
                  Curry._1(setHover, (function (param) {
                          return false;
                        }));
                })
            }, children(match[0]));
}

var make = Hover;

export {
  make ,
}
/* Css Not a pure module */
