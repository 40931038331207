// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as GetFirstAdobeExperiencePlatfromBreakingChangeUseCase from "./integration/GetFirstAdobeExperiencePlatfromBreakingChangeUseCase.mjs";

var overlay = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.zIndex(Styles.ZIndex.aboveAll),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var container = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.maxWidth(Css.px(450)),
        tl: {
          hd: Css.padding4(Css.px(35), Css.px(32), Css.px(32), Css.px(32)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.borderRadius(Css.px(10)),
              tl: {
                hd: Css.boxShadow(Styles.Shadow.standard),
                tl: {
                  hd: Css.cursor("default"),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var iconContainer = Curry._1(Css.style, {
      hd: Css.margin2(Css.px(15), "auto"),
      tl: {
        hd: Css.marginTop(Css.px(-78)),
        tl: {
          hd: Css.textAlign("center"),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  overlay: overlay,
  container: container,
  iconContainer: iconContainer
};

function AdobeExperiencePlatformBreakingChangeModal(Props) {
  var actions = Props.actions;
  var onContinueEditing = Props.onContinueEditing;
  var onClose = Props.onClose;
  var schemaBundle = SchemaBundleContext.use();
  var mappedModel = ModelStore.Mapped.use();
  var firstBreakingChange = GetFirstAdobeExperiencePlatfromBreakingChangeUseCase.getFirstBreakingChangeInfo(actions, mappedModel);
  if (firstBreakingChange !== undefined) {
    var handleDismiss = function (param) {
      Curry._1(onClose, undefined);
      var arg = schemaBundle.branchId;
      var arg$1 = schemaBundle.schemaId;
      AnalyticsUtils.withSchemaBundle((function (param) {
              var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
                return AnalyticsRe.branchPromptInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14);
              };
              return function (param) {
                var func$1 = Curry._1(func, param);
                return function (param) {
                  var func$2 = Curry._1(func$1, param);
                  return function (param) {
                    var func$3 = Curry._1(func$2, param);
                    return function (param) {
                      var func$4 = Curry._1(func$3, param);
                      return function (param) {
                        var func$5 = Curry._1(func$4, param);
                        return function (param) {
                          var func$6 = Curry._1(func$5, param);
                          return function (param) {
                            var func$7 = Curry._1(func$6, param);
                            return function (param) {
                              var func$8 = Curry._1(func$7, param);
                              return function (param) {
                                var func$9 = Curry._1(func$8, param);
                                return function (param) {
                                  return Curry._5(func$9, param, "Dismiss", "EditMain", arg, arg$1);
                                };
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                };
              };
            }), schemaBundle);
    };
    return React.createElement("div", {
                className: overlay,
                onClick: handleDismiss
              }, React.createElement("div", {
                    className: container,
                    onClick: (function (prim) {
                        prim.stopPropagation();
                      })
                  }, React.createElement($$Text.make, {
                        size: "Huge",
                        weight: "Bold",
                        textAlign: "left",
                        maxWidth: Css.px(350),
                        children: "Warning: Breaking change"
                      }), React.createElement(Spacer.make, {
                        height: 24
                      }), React.createElement($$Text.make, {
                        color: Styles.Color.light11,
                        children: null
                      }, "This change to the property ", React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontWeight("bold"),
                                  tl: /* [] */0
                                })
                          }, firstBreakingChange.propertyName), " is backwards incompatible (", React.createElement("a", {
                            className: Curry._1(Css.style, {
                                  hd: Css.textDecoration("underline"),
                                  tl: /* [] */0
                                }),
                            href: "https://avo.app/docs",
                            rel: "noopener noreferrer",
                            target: "_blank"
                          }, "learn more"), ")."), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement($$Text.make, {
                        textAlign: "left",
                        color: Styles.Color.light11,
                        children: null
                      }, "Publishing this change to Adobe Experience Platform will create a new version of the field group ", React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontWeight("bold"),
                                  tl: /* [] */0
                                })
                          }, firstBreakingChange.propertyBundleName)), React.createElement(Spacer.make, {
                        height: 32
                      }), React.createElement($$Text.make, {
                        textAlign: "left",
                        color: Styles.Color.light11,
                        children: "Do you want to proceed with this change?"
                      }), React.createElement(Spacer.make, {
                        height: 32
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.alignSelf("flexEnd"),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement(Button.make, {
                            label: "Make breaking change",
                            onClick: (function (param) {
                                Curry._1(onContinueEditing, undefined);
                                Curry._1(onClose, undefined);
                              }),
                            size: "large",
                            style: "outline"
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Button.make, {
                            label: "Cancel",
                            onClick: handleDismiss,
                            size: "large"
                          }))));
  }
  Curry._1(onClose, undefined);
  return null;
}

var make = AdobeExperiencePlatformBreakingChangeModal;

export {
  Style ,
  make ,
}
/* overlay Not a pure module */
