// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as DiffComponents from "./DiffComponents.mjs";

function MergeConflicts(Props) {
  var mergeConflicts = Props.mergeConflicts;
  var mode = Props.mode;
  var destinationBranchName;
  switch (mode) {
    case "MergeBranch" :
        destinationBranchName = "main";
        break;
    case "PushChanges" :
    case "PullChanges" :
        destinationBranchName = "destination";
        break;
    
  }
  var tmp;
  switch (mode) {
    case "MergeBranch" :
        tmp = "Merge";
        break;
    case "PushChanges" :
        tmp = "Push";
        break;
    case "PullChanges" :
        tmp = "Pull";
        break;
    
  }
  var eventNames = Belt_List.keepMap(mergeConflicts, (function (action) {
          var match = action[0];
          if (typeof match !== "object") {
            return ;
          }
          if (match.NAME !== "Event") {
            return ;
          }
          var match$1 = action[1];
          if (typeof match$1 === "object" && match$1.NAME === "AddEvent") {
            return match$1.VAL[1];
          }
          
        }));
  var propertyNames = Belt_List.keepMap(mergeConflicts, (function (action) {
          var match = action[0];
          if (typeof match !== "object") {
            return ;
          }
          if (match.NAME !== "Property") {
            return ;
          }
          var match$1 = action[1];
          if (typeof match$1 === "object" && match$1.NAME === "CreateProperty") {
            return match$1.VAL[1];
          }
          
        }));
  var propertyGroupNames = Belt_List.keepMap(mergeConflicts, (function (action) {
          var match = action[0];
          if (typeof match !== "object") {
            return ;
          }
          if (match.NAME !== "PropertyGroup") {
            return ;
          }
          var match$1 = action[1];
          if (typeof match$1 === "object" && match$1.NAME === "CreatePropertyGroup") {
            return match$1.VAL[1];
          }
          
        }));
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(20)),
                    tl: {
                      hd: Css.margin2(Css.px(15), Css.px(0)),
                      tl: {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.maxWidth(DiffComponents.maxWidthPx),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.border(Css.px(1), Css.solid, Styles.Color.red),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.lightRed),
                                tl: {
                                  hd: Css.color(Styles.Color.red),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontSize(Styles.FontSize.medium),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.bold),
                          tl: {
                            hd: Css.paddingBottom(Css.px(10)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, tmp + " Conflict"), React.createElement("div", undefined, eventNames ? (
                    eventNames.tl ? React.createElement("div", undefined, "Events with the names \"" + Js_array.joinWith(", ", Belt_List.toArray(eventNames)) + "\" already exists on the " + destinationBranchName + " branch." + (
                            mode === "MergeBranch" ? " Please rename or archive the events on this branch before merging." : ""
                          )) : React.createElement("div", undefined, "An event with the name \"" + eventNames.hd + "\" already exists on the " + destinationBranchName + " branch." + (
                            mode === "MergeBranch" ? " Please rename or archive the event on this branch before merging." : ""
                          ))
                  ) : null, propertyNames ? (
                    propertyNames.tl ? React.createElement("div", undefined, "Properties with the names \"" + Js_array.joinWith(", ", Belt_List.toArray(propertyNames)) + "\" already exists on the " + destinationBranchName + " branch." + (
                            mode === "MergeBranch" ? " Please rename or archive the properties on this branch before merging." : ""
                          )) : React.createElement("div", undefined, "A property with the name \"" + propertyNames.hd + "\" already exists on the " + destinationBranchName + " branch." + (
                            mode === "MergeBranch" ? " Please rename or archive the property on this branch before merging." : ""
                          ))
                  ) : null, propertyGroupNames ? (
                    propertyGroupNames.tl ? React.createElement("div", undefined, "Property bundles with the names \"" + Js_array.joinWith(", ", Belt_List.toArray(propertyGroupNames)) + "\" already exists on the " + destinationBranchName + " branch." + (
                            mode === "MergeBranch" ? " Please rename or archive the property bundles on this branch before merging." : ""
                          )) : React.createElement("div", undefined, "A property bundle with the name \"" + propertyGroupNames.hd + "\" already exists on the " + destinationBranchName + " branch." + (
                            mode === "MergeBranch" ? " Please rename or archive the property bundle on this branch before merging." : ""
                          ))
                  ) : null));
}

var make = MergeConflicts;

export {
  make ,
}
/* Css Not a pure module */
