// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Nanoid from "nanoid";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Zustand from "./Zustand.mjs";
import * as Zustand$1 from "zustand";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import LodashIsequal from "lodash.isequal";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as GettingStarted__Types from "./GettingStarted__Types.mjs";

var defaultOnboarding_completed = {};

var defaultOnboarding = {
  onboardingChoice: "liveData",
  completed: defaultOnboarding_completed,
  dismissed: false,
  complete: false
};

var StoreConfig = {};

var ZustandStore = Zustand.MakeStore(StoreConfig);

function onboardingIsSame(a, b) {
  if (a.complete === b.complete && a.dismissed === b.dismissed && Models.GettingStarted.onboardingChoiceToJs(a.onboardingChoice) === Models.GettingStarted.onboardingChoiceToJs(b.onboardingChoice)) {
    return LodashIsequal(a.completed, b.completed);
  } else {
    return false;
  }
}

var store = Zustand$1.create(function (set, get) {
      return {
              initialized: false,
              onboarding: defaultOnboarding,
              initOnServer: (function (schemaId) {
                  var state = Curry._1(get, undefined);
                  if (!state.initialized) {
                    set(function (state) {
                          return {
                                  initialized: true,
                                  onboarding: defaultOnboarding,
                                  initOnServer: state.initOnServer,
                                  updateFromServer: state.updateFromServer,
                                  updateOnboarding: state.updateOnboarding,
                                  listeners: state.listeners,
                                  registerListener: state.registerListener,
                                  unregisterListener: state.unregisterListener,
                                  isPrimaryRequestHandler: state.isPrimaryRequestHandler,
                                  flush: state.flush
                                };
                        });
                    FirebaseUtils.setOnboarding(schemaId, defaultOnboarding);
                    return ;
                  }
                  
                }),
              updateFromServer: (function (onboarding) {
                  if (!(!onboardingIsSame(Curry._1(get, undefined).onboarding, onboarding) || !Curry._1(get, undefined).initialized)) {
                    return ;
                  }
                  var match = onboarding.onboardingChoice;
                  var stepIdsToComplete = match === "manual" ? GettingStarted__Types.StepId.manualSteps : GettingStarted__Types.StepId.liveDataSteps;
                  set(function (state) {
                        return {
                                initialized: true,
                                onboarding: {
                                  onboardingChoice: onboarding.onboardingChoice,
                                  completed: onboarding.completed,
                                  dismissed: onboarding.dismissed,
                                  complete: Belt_Array.every(stepIdsToComplete, (function (stepId) {
                                          return Belt_Option.getWithDefault(Js_dict.get(onboarding.completed, GettingStarted__Types.StepId.tToJs(stepId)), false);
                                        }))
                                },
                                initOnServer: state.initOnServer,
                                updateFromServer: state.updateFromServer,
                                updateOnboarding: state.updateOnboarding,
                                listeners: state.listeners,
                                registerListener: state.registerListener,
                                unregisterListener: state.unregisterListener,
                                isPrimaryRequestHandler: state.isPrimaryRequestHandler,
                                flush: state.flush
                              };
                      });
                }),
              updateOnboarding: (function (schemaId, fn) {
                  var state = Curry._1(get, undefined);
                  var oldOnboarding = state.onboarding;
                  var newOnboarding = Curry._1(fn, oldOnboarding);
                  if (onboardingIsSame(oldOnboarding, newOnboarding)) {
                    return ;
                  }
                  var match = newOnboarding.onboardingChoice;
                  var stepIdsToComplete = match === "manual" ? GettingStarted__Types.StepId.manualSteps : GettingStarted__Types.StepId.liveDataSteps;
                  var complete = Belt_Array.every(stepIdsToComplete, (function (stepId) {
                          return Belt_Option.getWithDefault(Js_dict.get(newOnboarding.completed, GettingStarted__Types.StepId.tToJs(stepId)), false);
                        }));
                  var onboarding_onboardingChoice = newOnboarding.onboardingChoice;
                  var onboarding_completed = newOnboarding.completed;
                  var onboarding_dismissed = newOnboarding.dismissed;
                  var onboarding = {
                    onboardingChoice: onboarding_onboardingChoice,
                    completed: onboarding_completed,
                    dismissed: onboarding_dismissed,
                    complete: complete
                  };
                  if (state.initialized) {
                    FirebaseUtils.setOnboarding(schemaId, onboarding);
                  }
                  set(function (state) {
                        return {
                                initialized: state.initialized,
                                onboarding: onboarding,
                                initOnServer: state.initOnServer,
                                updateFromServer: state.updateFromServer,
                                updateOnboarding: state.updateOnboarding,
                                listeners: state.listeners,
                                registerListener: state.registerListener,
                                unregisterListener: state.unregisterListener,
                                isPrimaryRequestHandler: state.isPrimaryRequestHandler,
                                flush: state.flush
                              };
                      });
                }),
              listeners: [],
              registerListener: (function (listener) {
                  set(function (state) {
                        return {
                                initialized: state.initialized,
                                onboarding: state.onboarding,
                                initOnServer: state.initOnServer,
                                updateFromServer: state.updateFromServer,
                                updateOnboarding: state.updateOnboarding,
                                listeners: Belt_SetString.toArray(Belt_SetString.add(Belt_SetString.fromArray(state.listeners), listener)),
                                registerListener: state.registerListener,
                                unregisterListener: state.unregisterListener,
                                isPrimaryRequestHandler: state.isPrimaryRequestHandler,
                                flush: state.flush
                              };
                      });
                }),
              unregisterListener: (function (listener) {
                  set(function (state) {
                        var listeners = Belt_SetString.toArray(Belt_SetString.remove(Belt_SetString.fromArray(state.listeners), listener));
                        return {
                                initialized: listeners.length === 0 ? false : state.initialized,
                                onboarding: state.onboarding,
                                initOnServer: state.initOnServer,
                                updateFromServer: state.updateFromServer,
                                updateOnboarding: state.updateOnboarding,
                                listeners: listeners,
                                registerListener: state.registerListener,
                                unregisterListener: state.unregisterListener,
                                isPrimaryRequestHandler: state.isPrimaryRequestHandler,
                                flush: state.flush
                              };
                      });
                }),
              isPrimaryRequestHandler: (function (id) {
                  return Caml_obj.equal(Belt_Array.get(Curry._1(get, undefined).listeners, 0), id);
                }),
              flush: (function (param) {
                  set(function (state) {
                        return {
                                initialized: false,
                                onboarding: {
                                  onboardingChoice: "liveData",
                                  completed: {},
                                  dismissed: false,
                                  complete: false
                                },
                                initOnServer: state.initOnServer,
                                updateFromServer: state.updateFromServer,
                                updateOnboarding: state.updateOnboarding,
                                listeners: state.listeners,
                                registerListener: state.registerListener,
                                unregisterListener: state.unregisterListener,
                                isPrimaryRequestHandler: state.isPrimaryRequestHandler,
                                flush: state.flush
                              };
                      });
                })
            };
    });

function useStore(param) {
  var schemaId = SchemaBundleContext.use().schemaId;
  var listenerId = React.useMemo((function (param) {
          return Nanoid.nanoid();
        }), []);
  var state = Curry._2(ZustandStore.use, store, (function (state) {
          return state;
        }));
  var isPrimaryRequestHandler = Caml_obj.equal(Belt_Array.get(state.listeners, 0), listenerId);
  var initOnServer = React.useCallback((function (param) {
          if (isPrimaryRequestHandler) {
            return Curry._1(state.initOnServer, schemaId);
          }
          
        }), [
        isPrimaryRequestHandler,
        state.initOnServer,
        schemaId
      ]);
  var updateFromServer = React.useCallback((function (newOnboarding) {
          if (isPrimaryRequestHandler) {
            return Curry._1(state.updateFromServer, newOnboarding);
          }
          
        }), [
        isPrimaryRequestHandler,
        state.updateFromServer
      ]);
  var updateOnboarding = React.useCallback((function (fn) {
          if (isPrimaryRequestHandler) {
            return Curry._2(state.updateOnboarding, schemaId, fn);
          }
          
        }), [
        isPrimaryRequestHandler,
        state.updateOnboarding,
        schemaId
      ]);
  React.useEffect((function (param) {
          Curry._1(state.registerListener, listenerId);
          return (function (param) {
                    Curry._1(state.unregisterListener, listenerId);
                  });
        }), []);
  return React.useMemo((function (param) {
                return {
                        complete: state.onboarding.complete,
                        completedSteps: state.onboarding.completed,
                        dismissed: state.onboarding.dismissed,
                        initialized: state.initialized,
                        initOnServer: initOnServer,
                        onboardingChoice: state.onboarding.onboardingChoice,
                        updateFromServer: updateFromServer,
                        updateOnboarding: updateOnboarding,
                        listen: isPrimaryRequestHandler
                      };
              }), [
              state,
              initOnServer,
              updateFromServer,
              updateOnboarding,
              isPrimaryRequestHandler
            ]);
}

function use(param) {
  var match = SchemaBundleContext.use();
  var match$1 = ViewerContext.use();
  var store = useStore();
  var userSchemaState = FirebaseFetcherHooks.useUserSchema(store.listen, match.schemaId, match$1.id);
  var handleOnboardingChoiceChange = React.useCallback((function (choice) {
          Curry._1(store.updateOnboarding, (function (onboarding) {
                  return {
                          onboardingChoice: choice,
                          completed: onboarding.completed,
                          dismissed: onboarding.dismissed,
                          complete: onboarding.complete
                        };
                }));
        }), [store.updateOnboarding]);
  var handleDismiss = React.useCallback((function (param) {
          Curry._1(store.updateOnboarding, (function (onboarding) {
                  return {
                          onboardingChoice: onboarding.onboardingChoice,
                          completed: onboarding.completed,
                          dismissed: true,
                          complete: onboarding.complete
                        };
                }));
        }), [store.updateOnboarding]);
  var handleCompleteStep = React.useCallback((function (stepId) {
          Curry._1(store.updateOnboarding, (function (onboarding) {
                  return {
                          onboardingChoice: onboarding.onboardingChoice,
                          completed: Js_dict.fromArray(Belt_Array.concat(Js_dict.entries(onboarding.completed), [[
                                      GettingStarted__Types.StepId.tToJs(stepId),
                                      true
                                    ]])),
                          dismissed: onboarding.dismissed,
                          complete: onboarding.complete
                        };
                }));
        }), [store.updateOnboarding]);
  React.useEffect((function (param) {
          if (typeof userSchemaState === "object") {
            var newOnboarding = userSchemaState.VAL.onboardingChecklist;
            if (newOnboarding == null) {
              Curry._1(store.initOnServer, undefined);
            } else {
              Curry._1(store.updateFromServer, newOnboarding);
            }
          } else if (userSchemaState === "NotFound") {
            Curry._1(store.initOnServer, undefined);
          }
          
        }), [userSchemaState]);
  var loading = typeof userSchemaState === "object" ? false || !store.initialized : userSchemaState !== "NotFound";
  return React.useMemo((function (param) {
                return {
                        setOnboardingChoice: handleOnboardingChoiceChange,
                        onboardingChoice: store.onboardingChoice,
                        completedSteps: store.completedSteps,
                        complete: store.complete,
                        dismissed: store.dismissed,
                        handleDismiss: handleDismiss,
                        handleCompleteStep: handleCompleteStep,
                        loading: loading
                      };
              }), [
              handleOnboardingChoiceChange,
              handleDismiss,
              handleCompleteStep,
              store,
              loading
            ]);
}

export {
  defaultOnboarding ,
  StoreConfig ,
  ZustandStore ,
  onboardingIsSame ,
  store ,
  useStore ,
  use ,
}
/* ZustandStore Not a pure module */
