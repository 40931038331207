// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Mantine from "../Mantine.mjs";
import * as Accordion from "../Accordion.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RingProgress from "./RingProgress.mjs";
import * as IconCheckmark from "../IconCheckmark.mjs";
import * as BranchImplementationUtils from "./BranchImplementationUtils.mjs";
import * as CodeChangesAuditAccordionItem from "../CodeChangesAuditAccordionItem.mjs";

var container = Curry._1(Css.style, {
      hd: Css.padding(Css.px(20)),
      tl: {
        hd: Css.paddingRight(Css.px(0)),
        tl: {
          hd: Css.maxWidth(Css.px(954)),
          tl: {
            hd: Css.minWidth(Css.px(728)),
            tl: {
              hd: Css.marginRight(Css.px(BranchImplementationUtils.commentMarginWidth)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gap(Css.px(10)),
            tl: {
              hd: Css.paddingTop(Css.px(16)),
              tl: {
                hd: Css.paddingRight(Css.px(12)),
                tl: {
                  hd: Css.paddingBottom(Css.px(16)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(12)),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.marginBottom(Css.px(20)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var loadingContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.maxWidth(Css.px(24)),
            tl: {
              hd: Css.height(Css.px(24)),
              tl: {
                hd: Css.marginLeft(Css.px(2)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var ringProgressContainer = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(24)),
      tl: {
        hd: Css.maxHeight(Css.px(24)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

function BranchImplementationOverview(Props) {
  var branchStatus = Props.branchStatus;
  var sourceUpdates = Props.sourceUpdates;
  var sourceUpdatesLoadingState = Props.sourceUpdatesLoadingState;
  var seenEvents = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("implementedWithCodegen", "updated", "completed", param[0].id, param[1]);
                }))));
  var implementedWithCodegen = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("implementedWithCodegen", "updated", undefined, param[0].id, param[1]);
                }))));
  var implementedManually = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("implementedManually", "updated", undefined, param[0].id, param[1]);
                }))));
  var deletedEvents = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("any", "removed", undefined, param[0].id, param[1]);
                }))));
  var shouldExpandByDefault = Belt_List.every(sourceUpdates, (function (param) {
          return Belt_List.length(param[1]) <= 30;
        })) && Belt_List.length(sourceUpdates) <= 3;
  var tmp;
  if (implementedWithCodegen === 0) {
    tmp = (
      implementedManually !== 1 ? String(implementedManually) + " events not using Codegen need" : "1 event not using Codegen needs"
    ) + " manual implementation and validation";
  } else {
    var n = Belt_List.length(sourceUpdates);
    tmp = String(seenEvents) + " of " + (
      implementedWithCodegen !== 1 ? String(implementedWithCodegen) + " Codegen events" : "1 Codegen event"
    ) + " implemented across " + (
      n !== 1 ? String(n) + " sources" : "1 source"
    );
  }
  return React.createElement("section", {
              className: container
            }, React.createElement("header", {
                  className: header
                }, sourceUpdatesLoadingState === "loading" ? React.createElement("div", {
                        className: loadingContainer
                      }, React.createElement(Mantine.Loader.make, {
                            size: 22,
                            color: Styles.Color.darkBlue
                          })) : (
                    implementedWithCodegen !== 0 && seenEvents === implementedWithCodegen ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.marginLeft(Css.px(6)),
                                  tl: {
                                    hd: Css.marginRight(Css.px(6)),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement(IconCheckmark.make, {
                                size: 13,
                                color: Styles.Color.green
                              })) : (
                        implementedWithCodegen !== 0 ? React.createElement("div", {
                                className: ringProgressContainer
                              }, React.createElement(RingProgress.make, {
                                    size: 30,
                                    thickness: 4,
                                    rootColor: Styles.Color.light03,
                                    sections: CodeChangesAuditAccordionItem.getImplementationRingProgress(Styles.Color.darkBlue, implementedWithCodegen, seenEvents),
                                    tooltip: String(seenEvents) + " of " + String(implementedWithCodegen) + " event" + (
                                      implementedWithCodegen === 1 ? " has" : "s have"
                                    ) + " been implemented using Codegen"
                                  })) : React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginLeft(Css.px(3)),
                                      tl: {
                                        hd: Css.marginRight(Css.px(3)),
                                        tl: {
                                          hd: Css.height(Css.px(20)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement(Icon.make, {
                                    type_: "code",
                                    size: {
                                      NAME: "int",
                                      VAL: 18
                                    },
                                    color: Styles.Color.light10
                                  }))
                      )
                  ), React.createElement("div", undefined, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: tmp
                        }), implementedManually === 0 || implementedWithCodegen === 0 ? null : React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light10,
                            children: (
                              implementedManually !== 1 ? String(implementedManually) + " events not using Codegen need" : "1 event not using Codegen needs"
                            ) + " manual implementation and validation"
                          }), deletedEvents === 0 ? null : React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light10,
                            children: deletedEvents !== 1 ? String(deletedEvents) + " events removed" : "1 event removed"
                          }))), React.createElement(Accordion.make, {
                  allowMultipleOpens: true,
                  size: "large",
                  chevronPosition: "right",
                  children: Belt_List.toArray(Belt_List.map(sourceUpdates, (function (param) {
                              var source = param[0];
                              return React.createElement(CodeChangesAuditAccordionItem.NestedCodeChangesSourceAccordion.make, {
                                          source: source,
                                          eventsList: param[1],
                                          branchStatus: branchStatus,
                                          initiallyExpanded: shouldExpandByDefault,
                                          updateColorsWithBranchStatus: false,
                                          sourceUpdatesLoadingState: sourceUpdatesLoadingState,
                                          onCodeChangesAuditSourceToggled: (function (param, param$1, param$2, param$3) {
                                              
                                            }),
                                          onCodeChangesEventLinkClicked: (function (param, param$1, param$2) {
                                              
                                            }),
                                          onCodeChangesAuditSourceLinkClicked: (function (param, param$1, param$2) {
                                              
                                            }),
                                          key: source.id
                                        });
                            })))
                }));
}

var make = BranchImplementationOverview;

export {
  make ,
}
/* container Not a pure module */
