// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as GenericModal from "./GenericModal.mjs";
import * as Core from "@mantine/core";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SelectBranchPopup from "./SelectBranchPopup.mjs";
import * as BillingPromptModal from "./BillingPromptModal.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as CopyChangesToBranchModal__Types from "./CopyChangesToBranchModal__Types.mjs";
import * as CopyChangesToBranchModal__Utils from "./CopyChangesToBranchModal__Utils.mjs";
import * as GlobalSendOnNextBranchScheduler from "./branchImplementation/GlobalSendOnNextBranchScheduler.mjs";
import * as CopyChangesToBranchModal__Tooltip from "./CopyChangesToBranchModal__Tooltip.mjs";
import * as CopyChangesToBranchModal__BranchPicker from "./import/CopyChangesToBranchModal__BranchPicker.mjs";
import * as CopyChangesToBranchModal__ChangesToCopy from "./CopyChangesToBranchModal__ChangesToCopy.mjs";

var modalStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.gap(Css.px(8)),
            tl: {
              hd: Css.justifyContent("stretch"),
              tl: {
                hd: Css.boxShadow(Styles.Shadow.standard),
                tl: {
                  hd: Css.borderRadius(Css.px(10)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.width(Css.px(600)),
                        tl: {
                          hd: Css.padding(Css.px(24)),
                          tl: {
                            hd: Css.wordWrap("breakWord"),
                            tl: {
                              hd: Css.maxHeight(Css.vh(85)),
                              tl: {
                                hd: Css.overflowY("scroll"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function selectedDestinationBranchToKey(selectedDestinationBranch) {
  if (selectedDestinationBranch !== undefined) {
    if (typeof selectedDestinationBranch === "object") {
      if (selectedDestinationBranch.NAME === "Create") {
        return "create";
      } else {
        return selectedDestinationBranch.VAL.id;
      }
    } else {
      return "master";
    }
  } else {
    return "none";
  }
}

function CopyChangesToBranchModal$CopyModal(Props) {
  var copyItems = Props.copyItems;
  var branchItemSyncOrigin = Props.branchItemSyncOrigin;
  var currentPageName = Props.currentPageName;
  var onSuccess = Props.onSuccess;
  var onClose = Props.onClose;
  var currentBranchId = Router.Schema.getBranchId();
  var mappedEvents = ModelStore.Mapped.useEvents();
  var mappedProperties = ModelStore.Mapped.useProperties();
  var mappedPropertyBundles = ModelStore.Mapped.usePropertyBundles();
  var schemaBundle = SchemaBundleContext.use();
  var schemaId = schemaBundle.schemaId;
  var mainModel = ModelStore.useMainModel();
  var match = Belt_Array.getExn(copyItems, 0);
  var copyItems$1 = match[1];
  var match$1 = match[0];
  var destinationBranch = match$1.destinationBranch;
  var originBranch = match$1.originBranch;
  var match$2 = React.useState(function (param) {
        if (destinationBranch !== undefined) {
          if (typeof destinationBranch === "object") {
            return {
                    NAME: "Branch",
                    VAL: {
                      id: currentBranchId,
                      name: NamedBranch.getName(NamedBranch.fromUnnamed(mainModel, {
                                NAME: "Branch",
                                VAL: destinationBranch.VAL
                              }))
                    }
                  };
          } else {
            return {
                    NAME: "Branch",
                    VAL: {
                      id: currentBranchId,
                      name: NamedBranch.getName(NamedBranch.fromUnnamed(mainModel, {
                                NAME: "Branch",
                                VAL: currentBranchId
                              }))
                    }
                  };
          }
        }
        
      });
  var selectedDestinationBranch = match$2[0];
  var copyMode = destinationBranch !== undefined && typeof destinationBranch !== "object" ? "Pull" : "Push";
  var copyModeString = copyMode === "Push" ? "Push" : "Pull";
  var match$3 = CopyChangesToBranchModal__Utils.getAnalyticsItem(mappedEvents, mappedProperties, mappedPropertyBundles, copyItems$1);
  var itemType = match$3[2];
  var itemName = match$3[1];
  var itemId = match$3[0];
  var parentType = CopyChangesToBranchModal__Types.ParentType.fromCopyItems(copyItems$1);
  React.useEffect((function (param) {
          var arg = destinationBranch !== undefined ? (
              typeof destinationBranch === "object" ? destinationBranch.VAL : currentBranchId
            ) : undefined;
          var arg$1 = destinationBranch !== undefined ? (
              typeof destinationBranch === "object" ? NamedBranch.getName(NamedBranch.fromUnnamed(mainModel, {
                          NAME: "Branch",
                          VAL: destinationBranch.VAL
                        })) : ModelUtils.getBranchName(mainModel.openBranches, currentBranchId)
            ) : undefined;
          var arg$2 = typeof originBranch === "object" ? originBranch.VAL : currentBranchId;
          var arg$3 = typeof originBranch === "object" ? NamedBranch.getName(NamedBranch.fromUnnamed(mainModel, {
                      NAME: "Branch",
                      VAL: originBranch.VAL
                    })) : ModelUtils.getBranchName(mainModel.openBranches, currentBranchId);
          AnalyticsUtils.withSchemaBundle((function (param) {
                  var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22) {
                    return AnalyticsRe.branchItemSyncStarted(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22);
                  };
                  return function (param) {
                    var func$1 = Curry._1(func, param);
                    return function (param) {
                      var func$2 = Curry._1(func$1, param);
                      return function (param) {
                        var func$3 = Curry._1(func$2, param);
                        return function (param) {
                          var func$4 = Curry._1(func$3, param);
                          return function (param) {
                            var func$5 = Curry._1(func$4, param);
                            return function (param) {
                              var func$6 = Curry._1(func$5, param);
                              return function (param) {
                                var func$7 = Curry._1(func$6, param);
                                return function (param) {
                                  var func$8 = Curry._1(func$7, param);
                                  return function (param) {
                                    var func$9 = Curry._1(func$8, param);
                                    return function (param) {
                                      return Curry.app(func$9, [
                                                  param,
                                                  itemId,
                                                  itemName,
                                                  itemType,
                                                  arg,
                                                  arg$1,
                                                  arg$2,
                                                  arg$3,
                                                  copyMode,
                                                  branchItemSyncOrigin,
                                                  currentPageName,
                                                  schemaId,
                                                  currentBranchId
                                                ]);
                                    };
                                  };
                                };
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                }), schemaBundle);
        }), []);
  var tmp;
  if (selectedDestinationBranch !== undefined) {
    var tmp$1 = {
      onClose: onClose,
      copyItems: copyItems$1,
      originBranch: originBranch,
      destinationBranch: selectedDestinationBranch,
      copyMode: copyMode,
      branchItemSyncOrigin: branchItemSyncOrigin,
      pageName: currentPageName,
      parentType: parentType,
      key: selectedDestinationBranchToKey(selectedDestinationBranch)
    };
    if (onSuccess !== undefined) {
      tmp$1.onSuccess = Caml_option.valFromOption(onSuccess);
    }
    tmp = React.createElement(CopyChangesToBranchModal__ChangesToCopy.make, tmp$1);
  } else {
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.backgroundColor(Styles.Color.light03),
                tl: {
                  hd: Css.padding(Css.px(24)),
                  tl: {
                    hd: Css.borderRadius(Css.px(8)),
                    tl: /* [] */0
                  }
                }
              })
        }, React.createElement($$Text.make, {
              color: Styles.Color.light10,
              children: "Choose a destination branch to preview how your changes will be applied and impact the branch."
            }));
  }
  return React.createElement("div", {
              className: modalStyles
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("sticky"),
                        tl: {
                          hd: Css.top(Css.px(-24)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.3)),
                            tl: {
                              hd: Css.backdropFilter({
                                    hd: {
                                      NAME: "blur",
                                      VAL: Css.px(3)
                                    },
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.padding(Css.px(24)),
                                tl: {
                                  hd: Css.paddingBottom(Css.px(16)),
                                  tl: {
                                    hd: Css.margin(Css.px(-24)),
                                    tl: {
                                      hd: Css.marginBottom(Css.px(0)),
                                      tl: {
                                        hd: Css.marginTop(Css.px(-48)),
                                        tl: {
                                          hd: Css.zIndex(1),
                                          tl: {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Huge",
                      weight: "Bold",
                      color: Styles.Color.light11,
                      children: copyModeString + " changes to " + (
                        destinationBranch !== undefined && typeof destinationBranch !== "object" ? "current" : "another"
                      ) + " branch"
                    }), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement(CopyChangesToBranchModal__Tooltip.make, {
                      copyMode: copyMode
                    })), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: /* [] */0
                        }
                      })
                }, copyMode === "Push" ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "1. Choose destination branch"
                          }), React.createElement(Spacer.make, {
                            height: 4
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.paddingLeft(Css.px(12)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                color: Styles.Color.light11,
                                children: copyModeString + " tracking plan changes to:"
                              }), React.createElement(Spacer.make, {
                                width: 6
                              }), React.createElement(CopyChangesToBranchModal__BranchPicker.make, {
                                selectedBranch: selectedDestinationBranch,
                                setSelectedBranch: match$2[1]
                              })), React.createElement(Spacer.make, {
                            height: 24
                          })) : null, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100.0)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.light06),
                              tl: {
                                hd: Css.height(Css.px(1)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }), React.createElement(Spacer.make, {
                      height: 24
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: copyMode === "Push" ? "2. Review changes on destination branch" : "Review changes to pull to this branch"
                    }), React.createElement(Spacer.make, {
                      height: 4
                    }), copyMode === "Push" ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(12)),
                                  tl: {
                                    hd: Css.paddingRight(Css.px(12)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            color: Styles.Color.light11,
                            children: "Review and pick the related changes to the you want to pull. By default, all related changes are included.",
                            pretty: true
                          })) : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.paddingRight(Css.px(12)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            color: Styles.Color.light11,
                            children: "Review and pick the related changes to the you want to pull. By default, all related changes are included.",
                            pretty: true
                          })), React.createElement(Spacer.make, {
                      height: 24
                    }), tmp));
}

var CopyModal = {
  modalStyles: modalStyles,
  selectedDestinationBranchToKey: selectedDestinationBranchToKey,
  make: CopyChangesToBranchModal$CopyModal
};

function CopyChangesToBranchModal$PullToMainModal(Props) {
  var onClose = Props.onClose;
  var onBranchSelected = Props.onBranchSelected;
  var mainModel = ModelStore.useMainModel();
  var currentBranchId = Router.Schema.getBranchId();
  var globalSend = GlobalSendContext.use();
  var schemaBundle = SchemaBundleContext.use();
  var openBranches = Belt_List.keep(mainModel.openBranches, (function (param) {
          var branchId = param[0];
          if (branchId !== currentBranchId) {
            return branchId !== "master";
          } else {
            return false;
          }
        }));
  var match = Hooks.useDisclosure(false);
  var branchPopupActions = match[1];
  var showBranchPopup = match[0];
  var handleDismiss = function (param) {
    Curry._1(onClose, undefined);
    var arg = schemaBundle.branchId;
    var arg$1 = schemaBundle.schemaId;
    AnalyticsUtils.withSchemaBundle((function (param) {
            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
              return AnalyticsRe.branchPromptInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14);
            };
            return function (param) {
              var func$1 = Curry._1(func, param);
              return function (param) {
                var func$2 = Curry._1(func$1, param);
                return function (param) {
                  var func$3 = Curry._1(func$2, param);
                  return function (param) {
                    var func$4 = Curry._1(func$3, param);
                    return function (param) {
                      var func$5 = Curry._1(func$4, param);
                      return function (param) {
                        var func$6 = Curry._1(func$5, param);
                        return function (param) {
                          var func$7 = Curry._1(func$6, param);
                          return function (param) {
                            var func$8 = Curry._1(func$7, param);
                            return function (param) {
                              var func$9 = Curry._1(func$8, param);
                              return function (param) {
                                return Curry._5(func$9, param, "Dismiss", "PullToMain", arg, arg$1);
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                };
              };
            };
          }), schemaBundle);
  };
  var handleBranchSelected = function (branchId) {
    Curry._1(onBranchSelected, branchId);
    var arg = schemaBundle.branchId;
    var arg$1 = schemaBundle.schemaId;
    AnalyticsUtils.withSchemaBundle((function (param) {
            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
              return AnalyticsRe.branchPromptInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14);
            };
            return function (param) {
              var func$1 = Curry._1(func, param);
              return function (param) {
                var func$2 = Curry._1(func$1, param);
                return function (param) {
                  var func$3 = Curry._1(func$2, param);
                  return function (param) {
                    var func$4 = Curry._1(func$3, param);
                    return function (param) {
                      var func$5 = Curry._1(func$4, param);
                      return function (param) {
                        var func$6 = Curry._1(func$5, param);
                        return function (param) {
                          var func$7 = Curry._1(func$6, param);
                          return function (param) {
                            var func$8 = Curry._1(func$7, param);
                            return function (param) {
                              var func$9 = Curry._1(func$8, param);
                              return function (param) {
                                return Curry._5(func$9, param, "GoToBranch", "PullToMain", arg, arg$1);
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                };
              };
            };
          }), schemaBundle);
  };
  return React.createElement(GenericModal.make, {
              width: 440,
              onClose: handleDismiss,
              children: (function (_onClose) {
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding(Css.px(40)),
                                    tl: /* [] */0
                                  })
                            }, React.createElement("div", undefined, React.createElement($$Text.make, {
                                      element: "H2",
                                      weight: "Semi",
                                      color: Styles.Color.light12,
                                      children: "Do you want to pull these changes to a branch?"
                                    }), React.createElement(Spacer.make, {
                                      height: 16
                                    }), React.createElement($$Text.make, {
                                      weight: "Regular",
                                      color: Styles.Color.light10,
                                      children: "You cannot pull changes into the main branch. Switch to another branch to continue."
                                    })), React.createElement(Spacer.make, {
                                  height: 32
                                }), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("flexEnd"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement(Button.make, {
                                      label: "Cancel",
                                      onClick: (function (param) {
                                          handleDismiss();
                                        }),
                                      size: "large",
                                      style: "outline"
                                    }), React.createElement(Spacer.make, {
                                      width: 10
                                    }), React.createElement(Mantine.Popover.make, {
                                      dropdownStyles: {
                                        hd: Css.transform({
                                              NAME: "translateY",
                                              VAL: Css.px(3)
                                            }),
                                        tl: /* [] */0
                                      },
                                      offset: 0,
                                      onChange: (function (param) {
                                          Curry._1(branchPopupActions.toggle, undefined);
                                        }),
                                      opened: showBranchPopup,
                                      position: "right-start",
                                      children: null
                                    }, React.createElement(Core.Popover.Target, {
                                          children: React.createElement(Button.make, {
                                                label: "Switch to branch",
                                                onClick: (function (param) {
                                                    Curry._1(branchPopupActions.toggle, undefined);
                                                  }),
                                                size: "large"
                                              })
                                        }), React.createElement(Mantine.Popover.Dropdown.make, {
                                          children: React.createElement(SelectBranchPopup.make, {
                                                canSelectExistingBranches: true,
                                                canSelectMain: false,
                                                onClose: (function (param) {
                                                    Curry._1(branchPopupActions.close, undefined);
                                                  }),
                                                onSelect: (function (selectedBranch) {
                                                    if (typeof selectedBranch !== "object") {
                                                      return ;
                                                    }
                                                    if (selectedBranch.NAME === "Create") {
                                                      return Curry._1(globalSend, {
                                                                  TAG: "OpenModal",
                                                                  _0: {
                                                                    NAME: "NewBranch",
                                                                    VAL: [
                                                                      (function (branchId, _branchName) {
                                                                          handleBranchSelected(branchId);
                                                                        }),
                                                                      selectedBranch.VAL
                                                                    ]
                                                                  }
                                                                });
                                                    }
                                                    var id = selectedBranch.VAL.id;
                                                    var match = Router.getStaticRoute();
                                                    var link = Router.Link.getSchemaLink(match.baseRoute, {
                                                          NAME: "branch",
                                                          VAL: id
                                                        }, Belt_Option.getWithDefault(match.schemaRoute, "dashboard"), match.drawerItems);
                                                    Router.push(undefined, link);
                                                    handleBranchSelected(id);
                                                  }),
                                                openBranches: openBranches
                                              })
                                        }))));
                })
            });
}

var PullToMainModal = {
  make: CopyChangesToBranchModal$PullToMainModal
};

function CopyChangesToBranchModal(Props) {
  var copyItems = Props.copyItems;
  var branchItemSyncOrigin = Props.branchItemSyncOrigin;
  var currentPageName = Props.currentPageName;
  var onSuccess = Props.onSuccess;
  var role = Props.role;
  var onClose = Props.onClose;
  var currentBranchId = Router.Schema.getBranchId();
  var scheduleGlobalSendOnNextBranch = GlobalSendOnNextBranchScheduler.useScheduleGlobalSend();
  var workspace = WorkspaceContext.use();
  var isPullToMain = Belt_Array.some(copyItems, (function (param) {
          var destinationBranch = param[0].destinationBranch;
          if (destinationBranch !== undefined) {
            if (typeof destinationBranch === "object") {
              if (destinationBranch.VAL === "master") {
                return true;
              } else {
                return false;
              }
            } else {
              return currentBranchId === "master";
            }
          } else {
            return false;
          }
        }));
  var pushPullBetweenBranchesAvailability = AvoLimits.PushPullBetweenBranches.computeAvailability(workspace);
  if (!(pushPullBetweenBranchesAvailability === "AvailableDuringTrial" || pushPullBetweenBranchesAvailability === "Available")) {
    return React.createElement(BillingPromptModal.make, {
                onClose: (function (param) {
                    Curry._1(onClose, undefined);
                  }),
                onCloseAll: (function (param) {
                    Curry._1(onClose, undefined);
                  }),
                paywallName: "PushPullChanges",
                viewerRole: role
              });
  }
  if (isPullToMain) {
    return React.createElement(CopyChangesToBranchModal$PullToMainModal, {
                onClose: onClose,
                onBranchSelected: (function (branchId) {
                    if (branchId === "master") {
                      return Pervasives.failwith("Cannot pull to main");
                    }
                    var copyItemsToCurrentBranch = Belt_Array.map(copyItems, (function (param) {
                            return [
                                    {
                                      originBranch: param[0].originBranch,
                                      destinationBranch: "currentBranch"
                                    },
                                    param[1]
                                  ];
                          }));
                    Curry._1(scheduleGlobalSendOnNextBranch, {
                          TAG: "OpenModal",
                          _0: {
                            NAME: "CopyChangesToBranch",
                            VAL: [
                              copyItemsToCurrentBranch,
                              onSuccess,
                              currentPageName,
                              branchItemSyncOrigin
                            ]
                          }
                        });
                  })
              });
  }
  var tmp = {
    copyItems: copyItems,
    branchItemSyncOrigin: branchItemSyncOrigin,
    currentPageName: currentPageName,
    onClose: onClose
  };
  if (onSuccess !== undefined) {
    tmp.onSuccess = Caml_option.valFromOption(onSuccess);
  }
  return React.createElement(CopyChangesToBranchModal$CopyModal, tmp);
}

var make = CopyChangesToBranchModal;

export {
  CopyModal ,
  PullToMainModal ,
  make ,
}
/* modalStyles Not a pure module */
