// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var context = React.createContext("OpenBranches");

var provider = context.Provider;

function BranchOverview__ViewContext(Props) {
  var view = Props.view;
  var children = Props.children;
  return React.createElement(provider, {
              value: view,
              children: children
            });
}

var use;

var make = BranchOverview__ViewContext;

export {
  context ,
  provider ,
  use ,
  make ,
}
/* context Not a pure module */
