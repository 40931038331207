// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";

function InputWithState(Props) {
  var value = Props.value;
  var onChangeOpt = Props.onChange;
  var onChangeCompleted = Props.onChangeCompleted;
  var children = Props.children;
  var onChange = onChangeOpt !== undefined ? onChangeOpt : (function (param) {
        
      });
  var match = React.useState(function (param) {
        return value;
      });
  var setValueChanged = match[1];
  var valueChanged = match[0];
  var match$1 = React.useState(function (param) {
        return value;
      });
  var setValueBefore = match$1[1];
  var valueBefore = match$1[0];
  React.useEffect((function (param) {
          if (Caml_obj.notequal(value, valueChanged)) {
            Curry._1(setValueChanged, (function (param) {
                    return value;
                  }));
            Curry._1(setValueBefore, (function (param) {
                    return value;
                  }));
          }
          
        }), [value]);
  var onChange$1 = function (value) {
    Curry._1(setValueChanged, (function (param) {
            return value;
          }));
    Curry._1(onChange, value);
  };
  var onBlur = function (value) {
    if (onChangeCompleted !== undefined && value !== valueBefore) {
      return Curry._2(onChangeCompleted, value, valueBefore);
    }
    
  };
  var onFocus = function (value) {
    Curry._1(setValueBefore, (function (param) {
            return value;
          }));
  };
  return Curry._4(children, valueChanged, onChange$1, onBlur, onFocus);
}

var make = InputWithState;

export {
  make ,
}
/* react Not a pure module */
