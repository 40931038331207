// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Collapsible from "./Collapsible.mjs";
import * as DiffComments from "./DiffComments.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as DiffComponents from "./DiffComponents.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as DiffCollapseButton from "./DiffCollapseButton.mjs";
import * as DiffPropertyBundleGlobalPill from "./DiffPropertyBundleGlobalPill.mjs";
import * as CopyChangesToBranchModal__Hooks from "./CopyChangesToBranchModal__Hooks.mjs";

function DiffPropertyGroup(Props) {
  var branchStatus = Props.branchStatus;
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var withCommentsAndCopyToBranch = Props.withCommentsAndCopyToBranch;
  var events = Props.events;
  var collapsibleOpt = Props.collapsible;
  var rightElementOpt = Props.rightElement;
  var collapsible = collapsibleOpt !== undefined ? collapsibleOpt : false;
  var rightElement = rightElementOpt !== undefined ? Caml_option.valFromOption(rightElementOpt) : null;
  var openModal = GlobalSendContext.useOpenModal();
  var workspace = WorkspaceContext.use();
  var match = Hooks.useDisclosure(collapsible);
  var collapseAction = match[1];
  var collapsed = match[0];
  var match$1 = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match$1 === undefined) {
    return null;
  }
  if (typeof match$1 !== "object") {
    return null;
  }
  if (match$1.NAME !== "PropertyGroup") {
    return null;
  }
  var groupId = match$1.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "CreatePropertyGroup";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "PropertyGroup";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var group = Belt_Option.getWithDefault(Belt_List.getBy(masterModel.propertyBundles, (function (group) {
              return group.id === groupId;
            })), TrackingPlanModel.emptyPropertyBundle(groupId));
  var nextGroup = Belt_Option.getWithDefault(Belt_List.getBy(branchModel.propertyBundles, (function (group) {
              return group.id === groupId;
            })), TrackingPlanModel.emptyPropertyBundle(groupId));
  var canCopyChangesFromBranch = CopyChangesToBranchModal__Hooks.useCanCopyChangesFromBranch(branchId, branchStatus, hasArchive, withCommentsAndCopyToBranch);
  var pushPullBetweenBranchesAvailability = AvoLimits.PushPullBetweenBranches.computeAvailability(workspace);
  var maybeAction = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "UpdatePropertyGroupDescription";
          } else {
            return false;
          }
        }));
  var addActions = Belt_List.keepMap(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "AddPropertyToGroup") {
            return x;
          }
          
        }));
  var removeActions = Belt_List.keepMap(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "RemovePropertyFromGroup") {
            return x;
          }
          
        }));
  var actions$2 = Belt_List.concat(addActions, removeActions);
  var renderProperty = function (property, metricColor) {
    if (property.TAG === "PropertyRef") {
      return null;
    } else {
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontWeight(Styles.FontWeight.semi),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.small),
                          tl: {
                            hd: Css.padding2(Css.px(10), Css.px(15)),
                            tl: {
                              hd: Css.margin4(Css.px(5), Css.px(20), Css.px(5), Css.px(0)),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", metricColor),
                                  tl: {
                                    hd: Css.color(metricColor),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, property._0.name);
    }
  };
  var tmp;
  if (withCommentsAndCopyToBranch) {
    var tmp$1 = {
      branchId: branchId,
      threadId: group.id,
      itemType: "PropertyGroup",
      itemName: group.name,
      events: events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement(DiffComments.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement(DiffComponents.Item.make, {
                  borderColor: changeType === "Archive" ? Styles.Color.darkRed : (
                      changeType === "Create" ? Styles.Color.darkGreen : Styles.Color.light06
                    ),
                  children: null,
                  onClick: (function (param) {
                      if (collapsible) {
                        return Curry._1(collapseAction.toggle, undefined);
                      } else {
                        return Router.Schema.pushDrawerItem(undefined, undefined, {
                                    NAME: "propertyGroup",
                                    VAL: [
                                      group.id,
                                      undefined
                                    ]
                                  });
                      }
                    })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.margin(Css.px(-15)),
                              tl: {
                                hd: Css.padding2(Css.px(20), Css.px(15)),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(20)),
                                  tl: {
                                    hd: Css.marginBottom(Css.px(-15)),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.borderBottom(Css.px(1), Css.solid, collapsed ? "transparent" : Styles.Color.light04),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, collapsible ? React.createElement(DiffCollapseButton.make, {
                            toggle: collapseAction.toggle,
                            isOpen: !collapsed,
                            changeType: changeType
                          }) : null, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.justifyContent("spaceBetween"),
                                    tl: {
                                      hd: Css.gap(Css.px(8)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              children: JsDiff.renderStringDiff(undefined, undefined, undefined, group.name, nextGroup.name)
                            }), React.createElement(DiffPropertyBundleGlobalPill.make, {
                              propertyBundleId: nextGroup.id,
                              globalRequirements: branchModel.globalRequirements
                            })), React.createElement(Spacer.make, {
                          width: 8,
                          grow: 1.0
                        }), canCopyChangesFromBranch ? React.createElement(SimpleTooltip.make, {
                            position: "bottom",
                            tooltip: pushPullBetweenBranchesAvailability === "Available" ? "Push property bundle to another branch" : (
                                pushPullBetweenBranchesAvailability === "AvailableDuringTrial" ? "Push property bundle to another branch is available during your trial" : "Upgrade your plan to push property bundle to another branch"
                              ),
                            children: React.createElement(Button.make, {
                                  icon: "export",
                                  onClick: (function ($$event) {
                                      $$event.stopPropagation();
                                      Curry._1(openModal, {
                                            NAME: "CopyChangesToBranch",
                                            VAL: [
                                              [[
                                                  {
                                                    originBranch: "currentBranch",
                                                    destinationBranch: undefined
                                                  },
                                                  [{
                                                      NAME: "propertyBundle",
                                                      VAL: groupId
                                                    }]
                                                ]],
                                              undefined,
                                              "Diff",
                                              "ItemDiff"
                                            ]
                                          });
                                    }),
                                  size: "tiny",
                                  style: "outline",
                                  paywallLightningIcon: pushPullBetweenBranchesAvailability !== "Available"
                                })
                          }) : null, rightElement), React.createElement(Collapsible.make, {
                      collapsed: collapsed,
                      children: React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.paddingLeft(Css.px(4)),
                                  tl: /* [] */0
                                })
                          }, React.createElement(Spacer.make, {
                                height: 20
                              }), maybeAction !== undefined ? React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.padding2(Css.px(5), Css.px(0)),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.small),
                                          tl: /* [] */0
                                        }
                                      })
                                }, JsDiff.renderStringDiff(undefined, undefined, undefined, group.description, nextGroup.description)) : null, Belt_List.length(actions$2) > 0 ? React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.padding2(Css.px(5), Css.px(0)),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.small),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.regular),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: {
                                                  hd: Css.marginTop(Css.px(10)),
                                                  tl: {
                                                    hd: Css.paddingBottom(Css.px(8)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          })
                                    }, "Properties"), Belt_List.toArray(Belt_List.map(addActions, (function (x) {
                                            if (typeof x === "object" && x.NAME === "AddPropertyToGroup") {
                                              return Belt_Option.mapWithDefault(ModelUtils.getPropertyById(x.VAL[1], branchModel), null, (function (property) {
                                                            return renderProperty(property, Styles.Color.darkGreen);
                                                          }));
                                            } else {
                                              return null;
                                            }
                                          }))), Belt_List.toArray(Belt_List.map(removeActions, (function (x) {
                                            if (typeof x === "object" && x.NAME === "RemovePropertyFromGroup") {
                                              return Belt_Option.mapWithDefault(ModelUtils.getPropertyById(x.VAL[1], branchModel), null, (function (property) {
                                                            return renderProperty(property, Styles.Color.darkRed);
                                                          }));
                                            } else {
                                              return null;
                                            }
                                          })))) : null)
                    })), tmp);
}

var make = DiffPropertyGroup;

export {
  make ,
}
/* Css Not a pure module */
