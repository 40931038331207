// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as FileView from "../FileView.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconChevron from "../IconChevron.mjs";
import * as TimeMachine from "../TimeMachine.mjs";
import * as SnippetUtils from "../detailviews/SnippetUtils.mjs";
import LodashDebounce from "lodash.debounce";
import * as TrackingPlanModel from "../../../model/src/TrackingPlanModel.mjs";
import * as EventImplementationSnippet from "../detailviews/EventImplementationSnippet.mjs";

function fetchIntegrationExport(schemaId, branchId, integrationId, filename, beforeEqualDate) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app().auth().currentUser)).getIdToken().then(function (token) {
                  return fetch(Firebase.apiUrl + "/c/v1/export", {
                              method: "POST",
                              body: Caml_option.some(JSON.stringify(Js_dict.fromArray(Js_array.concat([
                                                [
                                                  "schemaId",
                                                  schemaId
                                                ],
                                                [
                                                  "branchId",
                                                  branchId
                                                ],
                                                [
                                                  "integrations",
                                                  [Js_dict.fromArray([
                                                          [
                                                            "id",
                                                            integrationId
                                                          ],
                                                          [
                                                            "path",
                                                            filename
                                                          ]
                                                        ])]
                                                ],
                                                [
                                                  "requestClient",
                                                  "web"
                                                ]
                                              ], beforeEqualDate !== undefined ? [[
                                                    "beforeEqualDate",
                                                    beforeEqualDate
                                                  ]] : [])))),
                              headers: Caml_option.some(new Headers([
                                        [
                                          "Accept",
                                          "application/json"
                                        ],
                                        [
                                          "Content-Type",
                                          "application/json"
                                        ],
                                        [
                                          "Authorization",
                                          "Bearer " + token
                                        ]
                                      ]))
                            });
                }).then(function (response) {
                return response.json().then(function (json) {
                            return Promise.resolve([
                                        response,
                                        json
                                      ]);
                          });
              }).then(function (param) {
              if (param[0].status < 300) {
                return Promise.resolve(param[1]);
              } else {
                return Pervasives.failwith("Could not fetch export");
              }
            });
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("stretch"),
          tl: {
            hd: Css.maxWidth(Css.pct(100)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.dark07),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.overflow("hidden"),
                  tl: {
                    hd: Css.marginBottom(Css.px(20)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.dark06),
      tl: {
        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light11),
        tl: {
          hd: Css.width(Css.pct(100.0)),
          tl: /* [] */0
        }
      }
    });

var expandToggleStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.05)),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("spaceBetween"),
                    tl: {
                      hd: Css.paddingTop(Css.px(12)),
                      tl: {
                        hd: Css.paddingRight(Css.px(12)),
                        tl: {
                          hd: Css.paddingBottom(Css.px(12)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(12)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.dark07),
                              tl: {
                                hd: Css.borderRadius(Css.px(3)),
                                tl: {
                                  hd: Css.outlineStyle("none"),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$short
                                        }, undefined, undefined, "all"),
                                    tl: {
                                      hd: Css.width(Css.pct(100.0)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function expandToggleChevronStyles(diffExpanded) {
  return Curry._1(Css.style, {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: Styles.Duration.$$default
                  }, undefined, undefined, "all"),
              tl: {
                hd: Css.transform({
                      NAME: "rotate",
                      VAL: {
                        NAME: "deg",
                        VAL: diffExpanded ? 180.0 : 0.0
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var diffContentContainer = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.dark07),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.maxHeight(Css.vh(60.0)),
          tl: {
            hd: Css.margin(Css.px(0)),
            tl: {
              hd: Css.padding(Css.px(0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var LocalStyles = {
  rootStyles: rootStyles,
  headerStyles: headerStyles,
  expandToggleStyles: expandToggleStyles,
  expandToggleChevronStyles: expandToggleChevronStyles,
  diffContentContainer: diffContentContainer
};

function IntegrationExport(Props) {
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var integration = Props.integration;
  var availability = Props.availability;
  var match = React.useState(function (param) {
        if (availability === "Unavailable") {
          return "Unavailable";
        } else {
          return "Initial";
        }
      });
  var setStatus = match[1];
  var status = match[0];
  var match$1 = TimeMachine.use();
  var whenAmI = match$1.whenAmI;
  var match$2 = React.useState(function (param) {
        return true;
      });
  var setDiffExpanded = match$2[1];
  var diffExpanded = match$2[0];
  var debouncedUpdateExport = LodashDebounce((function (schemaId, branchId, integration) {
          Curry._1(setStatus, (function (param) {
                  return "Loading";
                }));
          var beforeEqualDate = typeof whenAmI === "object" ? whenAmI.VAL.toISOString() : undefined;
          $$Promise.$$catch(fetchIntegrationExport(schemaId, branchId, integration.id, AvoConfig.getIntegrationFilename(integration), beforeEqualDate).then(function (response) {
                    return Promise.resolve(Curry._1(setStatus, (function (param) {
                                      return {
                                              TAG: "Success",
                                              _0: Belt_Array.concatMany(Belt_Array.mapU(response.integrations, (function (integration) {
                                                          return Belt_Array.mapU(integration.code, (function ($$export) {
                                                                        return [
                                                                                $$export.path,
                                                                                $$export.content,
                                                                                $$export.oldContent
                                                                              ];
                                                                      }));
                                                        })))
                                            };
                                    })));
                  }), (function (param) {
                  return Promise.resolve(Curry._1(setStatus, (function (param) {
                                    return "Error";
                                  })));
                }));
        }), 1000, {
        leading: false,
        trailing: true
      });
  var handleFetchExport = function (param) {
    if (availability === "AvailableDuringTrial" || availability === "Available") {
      return debouncedUpdateExport(schemaId, branchId, integration);
    } else {
      return Curry._1(setStatus, (function (param) {
                    return "Unavailable";
                  }));
    }
  };
  React.useEffect((function (param) {
          if (availability === "Unavailable") {
            Curry._1(setStatus, (function (param) {
                    return "Unavailable";
                  }));
          } else if (typeof status !== "object") {
            switch (status) {
              case "Initial" :
              case "Unavailable" :
                  break;
              default:
                handleFetchExport();
            }
          } else {
            handleFetchExport();
          }
        }), [
        integration,
        availability
      ]);
  var match$3 = integration.config;
  if (match$3 === undefined) {
    return null;
  }
  if (typeof status === "object") {
    return Belt_Option.mapWithDefault(Belt_Array.get(status._0, 0), null, (function (param) {
                  var oldCode = param[2];
                  var code = param[1];
                  if (oldCode === undefined) {
                    return React.createElement(FileView.make, {
                                mimeType: AvoConfig.getIntegrationMimeType(integration),
                                filename: param[0],
                                code: code,
                                startExpanded: true,
                                key: "fileview"
                              });
                  }
                  var newSnippet_1 = {
                    snippet: code,
                    source: TrackingPlanModel.emptySource(undefined, "source0", "", "")
                  };
                  var newSnippet = {
                    NAME: "pseudocode",
                    VAL: newSnippet_1
                  };
                  var oldSnippet_1 = {
                    snippet: oldCode,
                    source: TrackingPlanModel.emptySource(undefined, "source0", "", "")
                  };
                  var oldSnippet = {
                    NAME: "pseudocode",
                    VAL: oldSnippet_1
                  };
                  var match = SnippetUtils.makeDiff("unified", oldSnippet, newSnippet);
                  var snippet;
                  var variant = match.NAME;
                  if (variant === "unifiedDiff") {
                    snippet = match.VAL[0];
                  } else {
                    if (variant !== "noDiff") {
                      return null;
                    }
                    snippet = match.VAL;
                  }
                  return React.createElement("section", {
                              className: rootStyles
                            }, React.createElement("div", {
                                  className: headerStyles
                                }, React.createElement("button", {
                                      className: expandToggleStyles,
                                      onClick: (function (param) {
                                          Curry._1(setDiffExpanded, (function (diffExpanded) {
                                                  return !diffExpanded;
                                                }));
                                        })
                                    }, React.createElement($$Text.make, {
                                          element: "H1",
                                          size: "Medium",
                                          weight: "Semi",
                                          color: Styles.Color.white,
                                          children: "Payload Diff"
                                        }), React.createElement("div", {
                                          className: expandToggleChevronStyles(diffExpanded)
                                        }, React.createElement(IconChevron.make, {})))), diffExpanded ? React.createElement("div", {
                                    className: diffContentContainer
                                  }, React.createElement(EventImplementationSnippet.Snippet.make, {
                                        collapsed: false,
                                        enableCollapse: false,
                                        onCollapse: (function (_collapsed) {
                                            
                                          }),
                                        snippet: snippet,
                                        withDiffHighlighting: true,
                                        diffViewType: "unified",
                                        onCopied: (function (_onCodeCopied) {
                                            
                                          }),
                                        onToggled: (function (becameExpanded) {
                                            
                                          })
                                      })) : null);
                }));
  }
  switch (status) {
    case "Initial" :
        return React.createElement(FileView.make, {
                    filename: "Click to view payload preview...",
                    code: "",
                    loading: true,
                    onExpand: handleFetchExport,
                    key: "fileview"
                  });
    case "Loading" :
        return React.createElement(FileView.make, {
                    filename: "Generating your payload preview...",
                    code: "",
                    loading: true,
                    key: "fileview"
                  });
    case "Error" :
        return "Error";
    case "Unavailable" :
        return React.createElement(FileView.make, {
                    filename: "Payload Preview",
                    code: "Upgrade to payload previewing...",
                    key: "fileview"
                  });
    
  }
}

var make = IntegrationExport;

export {
  fetchIntegrationExport ,
  LocalStyles ,
  make ,
}
/* rootStyles Not a pure module */
