// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Models from "./Models.mjs";
import * as Parser from "../../model/src/Parser.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Changes from "../../shared/Changes.mjs";
import * as Mantine from "./Mantine.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as StringExt from "./StringExt.mjs";
import * as Timestamp from "./Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconInfinity from "./IconInfinity.mjs";
import * as Collaborators from "./Collaborators.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as AppVersionsStore from "./AppVersionsStore.mjs";
import * as DiscrepancyUtils from "./DiscrepancyUtils.mjs";
import * as IntegrationUtils from "../../api/functions/src/IntegrationUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as BranchStateReducer from "../../shared/BranchStateReducer.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as BranchDiscrepancies from "./BranchDiscrepancies.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as BranchActionBox__Utils from "./BranchActionBox__Utils.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as BranchActionBox__Reviewers from "./BranchActionBox__Reviewers.mjs";
import * as BranchActionBox__Components from "./BranchActionBox__Components.mjs";
import * as InspectorVersionValidationIntentHelpers from "./InspectorVersionValidationIntentHelpers.mjs";

var contentContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.gap(Css.px(16)),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  contentContainer: contentContainer
};

function BranchActionBox__ApprovalWorkflow$DraftState(Props) {
  var branchCreatorId = Props.branchCreatorId;
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchStatus = Props.branchStatus;
  var collaborators = Props.collaborators;
  var isBranchCreator = Props.isBranchCreator;
  var masterModel = Props.masterModel;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use();
  var sendActions = SendActionsContext.use();
  var workspace = WorkspaceContext.use();
  var match = BranchDiscrepancies.use();
  var branchAuditErrorCount = match.branchAuditErrorCount;
  var branchAuditChecks = match.branchAuditChecks;
  var match$1 = React.useContext(DiscrepancyContext.context);
  var discrepancyConfig = match$1.config;
  var match$2 = ViewerContext.use();
  var viewerEmail = match$2.email;
  var viewerId = match$2.id;
  var member = WorkspaceContext.useMemberWithUserId(viewerId);
  var viewerRole = React.useMemo((function (param) {
          if (member !== undefined) {
            return Models.Role.tFromJs(Caml_option.valFromOption(member).role);
          }
          
        }), [member]);
  var blockBranchWithEnforcement = React.useMemo((function (param) {
          var match = ValidationConfigV2.EventsV2.getCase(discrepancyConfig.events);
          if (DiscrepancyUtils.hasDiscrepencyErrors(typeof match === "object" ? true : false, ValidationConfigV2.EventsV2.hasValueChecking(discrepancyConfig.events), branchAuditChecks)) {
            return discrepancyConfig.pointOfBlock;
          }
          
        }), [
        branchModelWithPulledMaster,
        masterModel,
        discrepancyConfig
      ]);
  var numApprovals;
  numApprovals = typeof branchStatus !== "object" || branchStatus.TAG !== "Approved" ? 0 : Belt_List.length(branchStatus._0);
  var readyForReviewIsBlocked = Caml_obj.equal(blockBranchWithEnforcement, "beforeRequestForReview") && branchAuditErrorCount > 0;
  var match$3 = React.useState(function (param) {
        return false;
      });
  var setLoading = match$3[1];
  var subtitle = readyForReviewIsBlocked && role === "Admin" ? "Fix " + AvoUtils.plural(undefined, undefined, branchAuditErrorCount, "audit issue") + " before requesting a review" : (
      isBranchCreator ? "Done making changes? Mark the branch as ready for review and get feedback before merging." : (
          RoleUtils.canEdit(role) ? "This branch has not been marked as ready for review yet" : "Got any feedback? You can leave comments next to each branch change."
        )
    );
  return React.createElement(BranchActionBox__Components.MainContent.make, {
              icon: readyForReviewIsBlocked ? "exclamationMark" : "pencil",
              colorScheme: readyForReviewIsBlocked ? "orange" : "black",
              children: React.createElement("div", {
                    className: contentContainer
                  }, React.createElement("div", undefined, React.createElement($$Text.make, {
                            size: "Large",
                            weight: "Semi",
                            children: "This branch is still a work in progress"
                          }), React.createElement($$Text.make, {
                            size: "Medium",
                            color: Styles.Color.light11,
                            children: subtitle
                          })), RoleUtils.canEdit(role) ? React.createElement("div", undefined, React.createElement(Button.make, {
                              disabled: Caml_obj.equal(blockBranchWithEnforcement, "beforeRequestForReview"),
                              label: "Ready for review",
                              loading: match$3[0],
                              onClick: (function (param) {
                                  BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "BottomBranchActionBox", branchName, collaborators, "ReadyForReview", numApprovals, schemaBundle, sendActions, setLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                                }),
                              size: "small",
                              style: isBranchCreator ? "primary" : "outline",
                              singleLine: true
                            })) : null)
            });
}

var DraftState = {
  make: BranchActionBox__ApprovalWorkflow$DraftState
};

function BranchActionBox__ApprovalWorkflow$ReadyForReviewState(Props) {
  var approveActions = Props.approveActions;
  var branchCreatorId = Props.branchCreatorId;
  var branchId = Props.branchId;
  var branchInteractionLocation = Props.branchInteractionLocation;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var collaborators = Props.collaborators;
  var isBranchCreator = Props.isBranchCreator;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use();
  var workspace = WorkspaceContext.use();
  var sendActions = SendActionsContext.use();
  var match = ViewerContext.use();
  var viewerEmail = match.email;
  var viewerId = match.id;
  var member = WorkspaceContext.useMemberWithUserId(viewerId);
  var viewerRole = React.useMemo((function (param) {
          if (member !== undefined) {
            return Models.Role.tFromJs(Caml_option.valFromOption(member).role);
          }
          
        }), [member]);
  var protectedMainBranch = workspace.settings.protectedMainBranch;
  var requireAdminApproval = workspace.settings.requireAdminApproval;
  var numRequiredApprovals = workspace.settings.numRequiredApprovals;
  var match$1 = React.useState(function (param) {
        return false;
      });
  var setApproveLoading = match$1[1];
  var match$2 = React.useState(function (param) {
        return false;
      });
  var setRequestChangesLoading = match$2[1];
  var match$3 = React.useState(function (param) {
        return false;
      });
  var setRevertToDraftLoading = match$3[1];
  var userHasApproved = Belt_List.size(Belt_List.keep(approveActions, (function (action) {
              return action.createdBy === viewerId;
            }))) > 0;
  var match$4 = protectedMainBranch ? (
      requireAdminApproval ? [
          userHasApproved ? "You have approved these changes" : "Admin approval required",
          RoleUtils.canEdit(role) ? "At least " + AvoUtils.plural(undefined, undefined, numRequiredApprovals, "approval") + " from an admin " + AvoUtils.plural("are", false, numRequiredApprovals, "is") + " required before this branch can be merged" : "Only admins or editors can be branch reviewers. If you have feedback to give, you can leave comments next to each branch change.",
          "red"
        ] : [
          userHasApproved ? "You have approved these changes" : "Approval required",
          RoleUtils.canEdit(role) ? "At least " + AvoUtils.plural(undefined, undefined, numRequiredApprovals, "approval") + " from an admin or editor " + AvoUtils.plural("are", false, numRequiredApprovals, "is") + " required before this branch can be merged" : "Only admins or editors can be branch reviewers. If you have feedback to give, you can leave comments next to each branch change.",
          "red"
        ]
    ) : [
      userHasApproved ? "You have approved these changes" : "Pending approval",
      RoleUtils.canEdit(role) ? (
          isBranchCreator ? "Get feedback on your changes from at least 1 admin or editor" : (
              userHasApproved ? "No further action is required from you" : "Everything looking good?"
            )
        ) : "Only admins or editors can be branch reviewers. If you have feedback to give, you can leave comments next to each branch change.",
      "purple"
    ];
  var numApprovals;
  numApprovals = typeof branchStatus !== "object" || branchStatus.TAG !== "Approved" ? 0 : Belt_List.length(branchStatus._0);
  return React.createElement(BranchActionBox__Components.MainContent.make, {
              icon: "hourglass",
              colorScheme: match$4[2],
              children: React.createElement("div", {
                    className: contentContainer
                  }, React.createElement("div", undefined, React.createElement($$Text.make, {
                            size: "Large",
                            weight: "Semi",
                            children: match$4[0]
                          }), React.createElement($$Text.make, {
                            size: "Medium",
                            color: Styles.Color.light11,
                            children: match$4[1]
                          })), RoleUtils.canEdit(role) && !isBranchCreator ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.gap(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, userHasApproved ? null : React.createElement(Button.make, {
                                label: "Approve",
                                loading: match$1[0],
                                onClick: (function (param) {
                                    BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "BottomBranchActionBox", branchName, collaborators, "Approved", numApprovals + 1 | 0, schemaBundle, sendActions, setApproveLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                                  }),
                                size: "small",
                                singleLine: true
                              }), React.createElement(Button.make, {
                              label: "Request changes",
                              loading: match$2[0],
                              onClick: (function (param) {
                                  BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "BottomBranchActionBox", branchName, collaborators, "ChangesRequested", numApprovals, schemaBundle, sendActions, setRequestChangesLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                                }),
                              size: "small",
                              style: "outline",
                              singleLine: true
                            })) : null, isBranchCreator ? React.createElement(Button.make, {
                          label: "Revert to draft",
                          loading: match$3[0],
                          onClick: (function (param) {
                              var tmp;
                              tmp = typeof branchStatus !== "object" || branchStatus.TAG !== "Approved" ? 0 : Belt_List.length(branchStatus._0);
                              BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, branchInteractionLocation, branchName, collaborators, "Draft", tmp, schemaBundle, sendActions, setRevertToDraftLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                            }),
                          size: "small",
                          style: "outline",
                          singleLine: true
                        }) : null)
            });
}

var ReadyForReviewState = {
  make: BranchActionBox__ApprovalWorkflow$ReadyForReviewState
};

function BranchActionBox__ApprovalWorkflow$ChangesRequestedState(Props) {
  var branchCreatorId = Props.branchCreatorId;
  var branchId = Props.branchId;
  var branchName = Props.branchName;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchStatus = Props.branchStatus;
  var collaborators = Props.collaborators;
  var masterModel = Props.masterModel;
  var isBranchCreator = Props.isBranchCreator;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use();
  var sendActions = SendActionsContext.use();
  var workspace = WorkspaceContext.use();
  var match = React.useContext(DiscrepancyContext.context);
  var discrepancyConfig = match.config;
  var match$1 = BranchDiscrepancies.use();
  var branchAuditChecks = match$1.branchAuditChecks;
  var match$2 = ViewerContext.use();
  var viewerEmail = match$2.email;
  var viewerId = match$2.id;
  var member = WorkspaceContext.useMemberWithUserId(viewerId);
  var viewerRole = React.useMemo((function (param) {
          if (member !== undefined) {
            return Models.Role.tFromJs(Caml_option.valFromOption(member).role);
          }
          
        }), [member]);
  var blockBranchwithEnforcement = React.useMemo((function (param) {
          var match = ValidationConfigV2.EventsV2.getCase(discrepancyConfig.events);
          if (DiscrepancyUtils.hasDiscrepencyErrors(typeof match === "object" ? true : false, ValidationConfigV2.EventsV2.hasValueChecking(discrepancyConfig.events), branchAuditChecks)) {
            return discrepancyConfig.pointOfBlock;
          }
          
        }), [
        branchModelWithPulledMaster,
        masterModel,
        discrepancyConfig
      ]);
  var numApprovals;
  numApprovals = typeof branchStatus !== "object" || branchStatus.TAG !== "Approved" ? 0 : Belt_List.length(branchStatus._0);
  var subtitle = RoleUtils.canEdit(role) ? (
      isBranchCreator ? "Requested changes should be addressed before merging this branch" : "Changes will need to be made to the branch before approval is possible"
    ) : "Only admins or editors can review and make changes on branches. If you have feedback to give, you can leave comments next to each branch change.";
  var match$3 = React.useState(function (param) {
        return false;
      });
  var setLoading = match$3[1];
  return React.createElement(BranchActionBox__Components.MainContent.make, {
              icon: "exclamationMark",
              colorScheme: "red",
              children: React.createElement("div", {
                    className: contentContainer
                  }, React.createElement("div", undefined, React.createElement($$Text.make, {
                            size: "Large",
                            weight: "Semi",
                            children: "Changes requested"
                          }), React.createElement($$Text.make, {
                            size: "Medium",
                            color: Styles.Color.light11,
                            children: subtitle
                          })), RoleUtils.canEdit(role) ? React.createElement("div", undefined, isBranchCreator ? React.createElement(Button.make, {
                                disabled: Caml_obj.equal(blockBranchwithEnforcement, "beforeRequestForReview"),
                                label: "Ready for review",
                                loading: match$3[0],
                                onClick: (function (param) {
                                    BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "BottomBranchActionBox", branchName, collaborators, "ReadyForReview", numApprovals, schemaBundle, sendActions, setLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                                  }),
                                size: "small",
                                singleLine: true
                              }) : React.createElement(Button.make, {
                                disabled: isBranchCreator || workspace.settings.requireAdminApproval && role !== "Admin",
                                label: "Approve",
                                onClick: (function (param) {
                                    BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "BottomBranchActionBox", branchName, collaborators, "Approved", numApprovals + 1 | 0, schemaBundle, sendActions, setLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                                  }),
                                size: "small",
                                style: "outline",
                                singleLine: true
                              })) : null)
            });
}

var ChangesRequestedState = {
  make: BranchActionBox__ApprovalWorkflow$ChangesRequestedState
};

function BranchActionBox__ApprovalWorkflow$ApprovedState(Props) {
  var approveActions = Props.approveActions;
  var branchCreatorId = Props.branchCreatorId;
  var branchId = Props.branchId;
  var branchInteractionLocation = Props.branchInteractionLocation;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var collaborators = Props.collaborators;
  var isBranchCreator = Props.isBranchCreator;
  var numApprovals = Props.numApprovals;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use();
  var sendActions = SendActionsContext.use();
  var workspace = WorkspaceContext.use();
  var requireAdminApproval = workspace.settings.requireAdminApproval;
  var match = ViewerContext.use();
  var viewerEmail = match.email;
  var viewerId = match.id;
  var member = WorkspaceContext.useMemberWithUserId(viewerId);
  var viewerRole = React.useMemo((function (param) {
          if (member !== undefined) {
            return Models.Role.tFromJs(Caml_option.valFromOption(member).role);
          }
          
        }), [member]);
  var match$1 = BranchDiscrepancies.use();
  var hasBranchAuditErrors = match$1.branchAuditErrorCount > 0;
  var userHasApproved = Belt_List.size(Belt_List.keep(approveActions, (function (action) {
              return action.createdBy === viewerId;
            }))) > 0;
  var title = userHasApproved ? "You have approved these changes" : "Changes approved";
  var subtitle = hasBranchAuditErrors ? "The changes on this branch have been approved and can be merged into the main branch once branch audit warning have been addressed" : "The changes on this branch have been approved and can be merged into the main branch";
  var match$2 = React.useState(function (param) {
        return false;
      });
  var setApproveLoading = match$2[1];
  var match$3 = React.useState(function (param) {
        return false;
      });
  var setRequestChangesLoading = match$3[1];
  var requestChangesLoading = match$3[0];
  var match$4 = React.useState(function (param) {
        return false;
      });
  var setRevertToDraftLoading = match$4[1];
  return React.createElement(BranchActionBox__Components.MainContent.make, {
              icon: hasBranchAuditErrors ? "exclamationMark" : "checkmark",
              colorScheme: hasBranchAuditErrors ? "orange" : "green",
              children: React.createElement("div", {
                    className: contentContainer
                  }, React.createElement("div", undefined, React.createElement($$Text.make, {
                            size: "Large",
                            weight: "Semi",
                            children: title
                          }), React.createElement($$Text.make, {
                            size: "Medium",
                            color: Styles.Color.light11,
                            children: subtitle
                          })), !userHasApproved && !isBranchCreator && RoleUtils.canEdit(role) ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.gap(Css.px(4)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement(Button.make, {
                              disabled: requireAdminApproval && role !== "Admin",
                              label: "Approve",
                              loading: match$2[0],
                              onClick: (function (param) {
                                  BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "BottomBranchActionBox", branchName, collaborators, "Approved", numApprovals + 1 | 0, schemaBundle, sendActions, setApproveLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                                }),
                              size: "small",
                              style: "outline",
                              singleLine: true
                            }), React.createElement(Button.make, {
                              disabled: requireAdminApproval && role !== "Admin",
                              label: "Request changes",
                              loading: requestChangesLoading,
                              onClick: (function (param) {
                                  BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "BottomBranchActionBox", branchName, collaborators, "ChangesRequested", numApprovals, schemaBundle, sendActions, setRequestChangesLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                                }),
                              size: "small",
                              style: "outline",
                              singleLine: true
                            })) : (
                      userHasApproved ? React.createElement(Button.make, {
                              disabled: requireAdminApproval && role !== "Admin",
                              label: "Request changes",
                              loading: requestChangesLoading,
                              onClick: (function (param) {
                                  BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "BottomBranchActionBox", branchName, collaborators, "ChangesRequested", numApprovals, schemaBundle, sendActions, setRequestChangesLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                                }),
                              size: "small",
                              style: "outline",
                              singleLine: true
                            }) : (
                          isBranchCreator ? React.createElement(Button.make, {
                                  label: "Revert to draft",
                                  loading: match$4[0],
                                  onClick: (function (param) {
                                      var tmp;
                                      tmp = typeof branchStatus !== "object" || branchStatus.TAG !== "Approved" ? 0 : Belt_List.length(branchStatus._0);
                                      BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, branchInteractionLocation, branchName, collaborators, "Draft", tmp, schemaBundle, sendActions, setRevertToDraftLoading, branchStatus, viewerEmail, viewerId, viewerRole === "Admin" ? "Admin" : "Editor", workspace);
                                    }),
                                  size: "small",
                                  style: "outline",
                                  singleLine: true
                                }) : null
                        )
                    ))
            });
}

var ApprovedState = {
  make: BranchActionBox__ApprovalWorkflow$ApprovedState
};

function BranchActionBox__ApprovalWorkflow$GlobalWorkspaceInformation(Props) {
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var workspaces = ModelStore.Mapped.useSources();
  var workspaceNamesAffected = React.useMemo((function (param) {
          var changedSourceIds = BranchStateReducer.changedSourceIds(branchModel, masterModel);
          if (changedSourceIds !== undefined) {
            return Belt_Array.map(changedSourceIds, (function (workspaceId) {
                          return TrackingPlanMappedModel.Sources.getName(workspaces, workspaceId);
                        }));
          } else {
            return [];
          }
        }), [
        branchModel,
        masterModel
      ]);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(10), Css.px(20)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(24)),
                      tl: {
                        hd: Css.borderTop(Css.px(1), Css.solid, Styles.Color.light06),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.light03),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(20)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(20)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("flexEnd"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(Icon.make, {
                      type_: "warningCircle",
                      size: "small",
                      color: Styles.Color.darkBlue
                    })), React.createElement("div", undefined, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Tiny",
                      color: Styles.Color.light11,
                      children: "When this branch is merged, these changes will be pushed as branches to the following workspaces: "
                    }), React.createElement($$Text.make, {
                      element: "Span",
                      size: "Tiny",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: StringExt.joinToSentence(Belt_Array.map(workspaceNamesAffected, (function (workspaceName) {
                                  return Belt_Option.getWithDefault(workspaceName, "Untitled workspace");
                                })))
                    })));
}

var GlobalWorkspaceInformation = {
  make: BranchActionBox__ApprovalWorkflow$GlobalWorkspaceInformation
};

function BranchActionBox__ApprovalWorkflow$InspectorValidation$Icon(Props) {
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css.flexShrink(0.0),
                    tl: /* [] */0
                  }),
              height: "16",
              width: "15",
              viewBox: "0 0 15 16"
            }, React.createElement("path", {
                  d: "M11.2694 10.8855L13.9461 13.5622L13.0622 14.4461L10.3855 11.7694C9.42306 12.5394 8.2025 13 6.875 13C3.77 13 1.25 10.48 1.25 7.375C1.25 4.27 3.77 1.75 6.875 1.75C9.98 1.75 12.5 4.27 12.5 7.375C12.5 8.7025 12.0394 9.92306 11.2694 10.8855ZM10.0154 10.4218C10.7797 9.63412 11.25 8.55975 11.25 7.375C11.25 4.95781 9.29219 3 6.875 3C4.45781 3 2.5 4.95781 2.5 7.375C2.5 9.79219 4.45781 11.75 6.875 11.75C8.05975 11.75 9.13412 11.2797 9.92175 10.5154L10.0154 10.4218ZM7.61119 4.98515C7.17712 5.18114 6.875 5.61779 6.875 6.125C6.875 6.81537 7.43463 7.375 8.125 7.375C8.63219 7.375 9.06887 7.07288 9.26488 6.63882C9.33644 6.87156 9.375 7.11875 9.375 7.375C9.375 8.75569 8.25569 9.875 6.875 9.875C5.49429 9.875 4.375 8.75569 4.375 7.375C4.375 5.99429 5.49429 4.875 6.875 4.875C7.13125 4.875 7.37844 4.91354 7.61119 4.98515Z",
                  fill: "#6D7783"
                }));
}

var Icon$1 = {
  make: BranchActionBox__ApprovalWorkflow$InspectorValidation$Icon
};

function getActionDetails(contentsJson) {
  try {
    var match = Parser.parseAction(contentsJson);
    if (typeof match !== "object") {
      return ;
    }
    if (match.NAME !== "ConfigureInspectorValidation") {
      return ;
    }
    var match$1 = match.VAL;
    return [
            match$1[0],
            match$1[1]
          ];
  }
  catch (exn){
    return ;
  }
}

function getActionData(action) {
  if (typeof action !== "object") {
    return ;
  }
  if (action.NAME !== "Loaded") {
    return ;
  }
  var actionData = action.VAL;
  if (actionData !== undefined) {
    return getActionDetails(Caml_option.valFromOption(actionData).contentsJson);
  }
  
}

var contentStyle = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(10), Css.px(20)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light03),
            tl: {
              hd: Css.borderTop(Css.px(1), Css.solid, Styles.Color.light06),
              tl: /* [] */0
            }
          }
        }
      }
    });

function BranchActionBox__ApprovalWorkflow$InspectorValidation$Content(Props) {
  var branchId = Props.branchId;
  var branchStatus = Props.branchStatus;
  var branchModel = Props.branchModel;
  var changes = Props.changes;
  var match = Curry._1(Mantine.Hooks.Intersection.useWithoutOptions, undefined);
  var entry = match.entry;
  var inspectorValidationPromptRef = match.ref;
  var member = WorkspaceContext.useMember();
  var schemaBundle = SchemaBundleContext.use();
  var globalSend = GlobalSendContext.use();
  var breakingChanges = Changes.getBreakingChangesReport(changes);
  var match$1 = FirebaseFetcherHooks.useValidationIntents(schemaBundle.schemaId, branchId);
  var validationIntent = match$1[0];
  var configureInspectorValidationAction = FirebaseFetcherHooks.useAction(schemaBundle.schemaId, (function (q) {
          return q.where("branchId", "==", schemaBundle.branchId);
        }), "ConfigureInspectorValidation");
  var isIntersecting = React.useMemo((function (param) {
          if (entry !== undefined) {
            return entry.isIntersecting;
          } else {
            return false;
          }
        }), [entry]);
  React.useEffect((function (param) {
          var match = getActionData(configureInspectorValidationAction);
          if (entry !== undefined && entry.isIntersecting) {
            var exit = 0;
            if (typeof branchStatus !== "object" || !(branchStatus.TAG === "Merged" && match !== undefined)) {
              exit = 1;
            } else {
              var sourceValidation = match[1];
              var eventIds = match[0];
              AnalyticsRe.releaseVersionConfigurationPromptSeen(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_List.size(eventIds), Belt_List.toArray(eventIds), Belt_MapString.keysToArray(sourceValidation), Belt_MapString.size(sourceValidation), schemaBundle.branchId, schemaBundle.schemaId);
            }
            if (exit === 1) {
              var inspectorBreakingChangeInfo = Changes.getAnalyticsBreakingChangeInfo(breakingChanges);
              AnalyticsRe.releaseVersionConfigurationPromptSeen(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, inspectorBreakingChangeInfo.breakingChangeImpactedEventCount, inspectorBreakingChangeInfo.breakingChangeImpactedEventIds, inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds, inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount, schemaBundle.branchId, schemaBundle.schemaId);
            }
            
          }
          
        }), [isIntersecting]);
  var match$2 = getActionData(configureInspectorValidationAction);
  if (typeof branchStatus === "object" && branchStatus.TAG === "Merged") {
    if (match$2 === undefined) {
      return null;
    }
    var sourceValidation = match$2[1];
    var eventIds = match$2[0];
    return React.createElement("div", {
                ref: inspectorValidationPromptRef,
                className: contentStyle
              }, React.createElement(BranchActionBox__ApprovalWorkflow$InspectorValidation$Icon, {}), React.createElement(Spacer.make, {
                    width: 13
                  }), React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.light10,
                    children: null
                  }, "Inspector temporarily ignored issues for ", React.createElement($$Text.make, {
                        element: "Span",
                        weight: "Semi",
                        children: AvoUtils.plural(undefined, undefined, Belt_List.size(eventIds), "event") + " from " + AvoUtils.plural(undefined, undefined, Belt_MapString.size(sourceValidation), "source")
                      }), ". View details to see when validation for those events will resume."), React.createElement(Spacer.make, {
                    width: 8,
                    grow: 1.0
                  }), React.createElement(Button.make, {
                    label: "View Details",
                    onClick: (function (param) {
                        Curry._1(globalSend, {
                              TAG: "OpenModal",
                              _0: {
                                NAME: "InspectorVersionValidationDetails",
                                VAL: [
                                  eventIds,
                                  sourceValidation,
                                  branchModel
                                ]
                              }
                            });
                      }),
                    size: "small",
                    style: "outline"
                  }));
  }
  var breakingSourcesSet = Belt_SetString.fromArray(breakingChanges.breakingSources);
  var tmp;
  if (InspectorVersionValidationIntentHelpers.noSourcesHaveIntent(breakingSourcesSet, validationIntent)) {
    tmp = React.createElement(React.Fragment, undefined, "When merging this branch, Inspector may surface irrelevant issues and alerts from older release versions. To prevent that, configure Inspector to temporarily ignore new issues for ", React.createElement($$Text.make, {
              element: "Span",
              weight: "Semi",
              children: AvoUtils.plural(undefined, undefined, breakingChanges.breakingEvents.length, "event") + " from " + AvoUtils.plural(undefined, undefined, breakingChanges.breakingSources.length, "source")
            }), ".");
  } else if (InspectorVersionValidationIntentHelpers.allSourcesHaveIntent(breakingSourcesSet, validationIntent)) {
    tmp = React.createElement(React.Fragment, undefined, "Inspector has been configured to temporarily ignore new issues for ", React.createElement($$Text.make, {
              element: "Span",
              weight: "Semi",
              children: AvoUtils.plural(undefined, undefined, breakingChanges.breakingEvents.length, "event") + " from " + AvoUtils.plural(undefined, undefined, breakingChanges.breakingSources.length, "source")
            }), " when this branch is merged. To see how each source is configured and to make changes, click configure.");
  } else {
    var match$3 = InspectorVersionValidationIntentHelpers.getValidationReport(breakingChanges, validationIntent);
    tmp = React.createElement(React.Fragment, undefined, "When merging this branch, Inspector may surface irrelevant issues and alerts from older release versions. To prevent that, Inspector has been configured to temporarily ignore new issues for\n                          " + AvoUtils.plural(undefined, undefined, match$3.ignoredEvents.length, "event") + " from " + AvoUtils.plural(undefined, undefined, match$3.ignoredSources.length, "source") + " on this branch but ", React.createElement($$Text.make, {
              element: "Span",
              weight: "Semi",
              children: AvoUtils.plural(undefined, undefined, match$3.passingEvents.length, "event") + " from " + AvoUtils.plural(undefined, undefined, match$3.passingSources.length, "source")
            }), " remain to be configured.");
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: inspectorValidationPromptRef,
                  className: contentStyle,
                  id: "inspector-validation-action-box"
                }, React.createElement(BranchActionBox__ApprovalWorkflow$InspectorValidation$Icon, {}), React.createElement(Spacer.make, {
                      width: 13
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: tmp
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement(Button.make, {
                      label: Belt_Option.mapWithDefault(member, "View", (function (member) {
                              if (RoleUtils.canEdit(Belt_Option.getExn(Models.Role.tFromJs(member.role)))) {
                                return "Configure";
                              } else {
                                return "View";
                              }
                            })),
                      onClick: (function (param) {
                          Curry._1(globalSend, {
                                TAG: "OpenModal",
                                _0: {
                                  NAME: "InspectorVersionValidation",
                                  VAL: breakingChanges
                                }
                              });
                        }),
                      size: "small",
                      style: "outline"
                    })));
}

var Content = {
  contentStyle: contentStyle,
  make: BranchActionBox__ApprovalWorkflow$InspectorValidation$Content
};

function BranchActionBox__ApprovalWorkflow$InspectorValidation(Props) {
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var breakingChanges = Changes.getBreakingChangesReport(changes);
  var match = AppVersionsStore.use(undefined, 2, undefined);
  var appVersionsFetchStatus = match[1];
  var appVersions = match[0];
  var hasSourcesToConfig = React.useMemo((function (param) {
          if (appVersionsFetchStatus !== "success") {
            return false;
          }
          var sourceIdsWithVersionsSet = Belt_SetString.fromArray(Belt_MapString.keysToArray(appVersions));
          var sourceIds = breakingChanges.breakingSources;
          return Belt_Array.some(sourceIds, (function (sourceId) {
                        return Belt_SetString.has(sourceIdsWithVersionsSet, sourceId);
                      }));
        }), [
        appVersions,
        appVersionsFetchStatus
      ]);
  if (breakingChanges.breakingSources.length !== 0 && hasSourcesToConfig) {
    return React.createElement(BranchActionBox__ApprovalWorkflow$InspectorValidation$Content, {
                branchId: branchId,
                branchStatus: branchStatus,
                branchModel: branchModel,
                changes: changes
              });
  } else {
    return null;
  }
}

var InspectorValidation = {
  Icon: Icon$1,
  getActionDetails: getActionDetails,
  getActionData: getActionData,
  Content: Content,
  make: BranchActionBox__ApprovalWorkflow$InspectorValidation
};

function BranchActionBox__ApprovalWorkflow$MergedState(Props) {
  var branchName = Props.branchName;
  var mergeAction = Props.mergeAction;
  var userId = mergeAction.createdBy;
  var user = FirebaseFetcherHooks.useUser(userId);
  return React.createElement(BranchActionBox__Components.MainContent.make, {
              icon: "merged",
              colorScheme: "blue",
              children: React.createElement("div", undefined, React.createElement($$Text.make, {
                        size: "Large",
                        weight: "Semi",
                        children: "Branch has been merged"
                      }), React.createElement("div", undefined, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Regular",
                            color: Styles.Color.light10,
                            children: null
                          }, branchName + " was merged into main ", React.createElement("strong", undefined, React.createElement(Timestamp.make, {
                                    date: mergeAction.createdAt
                                  })), typeof user === "object" ? React.createElement(React.Fragment, undefined, " by ", React.createElement("strong", undefined, AvoConfig.getUserDisplayName(user.VAL))) : null)))
            });
}

var MergedState = {
  make: BranchActionBox__ApprovalWorkflow$MergedState
};

function BranchActionBox__ApprovalWorkflow$ClosedState(Props) {
  var branchName = Props.branchName;
  var closeAction = Props.closeAction;
  var userId = closeAction.createdBy;
  var user = FirebaseFetcherHooks.useUser(userId);
  return React.createElement(BranchActionBox__Components.MainContent.make, {
              icon: "close",
              colorScheme: "red",
              children: React.createElement("div", undefined, React.createElement($$Text.make, {
                        size: "Large",
                        weight: "Semi",
                        children: "Branch has been closed"
                      }), React.createElement("div", undefined, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Regular",
                            color: Styles.Color.light10,
                            children: null
                          }, branchName + " was closed ", React.createElement("strong", undefined, React.createElement(Timestamp.make, {
                                    date: closeAction.createdAt
                                  })), typeof user === "object" ? React.createElement(React.Fragment, undefined, " by ", React.createElement("strong", undefined, AvoConfig.getUserDisplayName(user.VAL))) : null)))
            });
}

var ClosedState = {
  make: BranchActionBox__ApprovalWorkflow$ClosedState
};

var contentStyle$1 = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(10), Css.px(20)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light03),
            tl: {
              hd: Css.borderTop(Css.px(1), Css.solid, Styles.Color.light06),
              tl: /* [] */0
            }
          }
        }
      }
    });

function BranchActionBox__ApprovalWorkflow$PublishingIntegrations(Props) {
  var autoPublishIntegrations = Props.autoPublishIntegrations;
  var branchId = Props.branchId;
  if (Belt_List.size(autoPublishIntegrations) > 0) {
    return React.createElement("div", {
                className: contentStyle$1
              }, React.createElement(IconInfinity.make, {
                    size: 16
                  }), React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.width(Css.px(12)),
                          tl: /* [] */0
                        })
                  }), React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.light10,
                    children: null
                  }, String(Belt_List.length(autoPublishIntegrations)), " of your ", React.createElement(Link.make, {
                        path: Router.Link.getSchemaRouteLink({
                              NAME: "branch",
                              VAL: branchId
                            }, "integrations"),
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.textDecoration("underline"),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        children: "Publishing Integrations"
                      }), " will be auto-published"));
  } else {
    return null;
  }
}

var PublishingIntegrations = {
  contentStyle: contentStyle$1,
  make: BranchActionBox__ApprovalWorkflow$PublishingIntegrations
};

function BranchActionBox__ApprovalWorkflow(Props) {
  var actionDiff = Props.actionDiff;
  var actions = Props.actions;
  var approvalWorkflow = Props.approvalWorkflow;
  var branchCreatorId = Props.branchCreatorId;
  var branchDiffStatus = Props.branchDiffStatus;
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var mergeConflicts = Props.mergeConflicts;
  var role = Props.role;
  var sourceUpdates = Props.sourceUpdates;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var workspace = WorkspaceContext.use();
  var match = workspace.settings;
  var numRequiredApprovals = match.numRequiredApprovals;
  var members = workspace.members;
  var match$1 = ViewerContext.use();
  var isBranchCreator = match$1.id === branchCreatorId;
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace();
  var collaborators = Collaborators.useCollaborators(branchId);
  var match$2 = React.useContext(DiscrepancyContext.context);
  var discrepancyConfig = match$2.config;
  var match$3 = BranchDiscrepancies.use();
  var branchAuditChecks = match$3.branchAuditChecks;
  var isFullyApproved;
  if (typeof branchStatus !== "object" || branchStatus.TAG !== "Approved") {
    isFullyApproved = false;
  } else {
    var approveActions = branchStatus._0;
    if (match.requireAdminApproval) {
      var adminApprovals = Belt_List.keep(approveActions, (function (action) {
              return Belt_Option.getWithDefault(Belt_Option.mapU(Belt_List.getByU(members, (function (member) {
                                    return member.id === action.createdBy;
                                  })), (function (member) {
                                return Caml_obj.equal(Models.Role.tFromJs(member.role), "Admin");
                              })), false);
            }));
      isFullyApproved = Belt_List.size(adminApprovals) >= numRequiredApprovals;
    } else {
      isFullyApproved = Belt_List.size(approveActions) >= numRequiredApprovals;
    }
  }
  var autoPublishIntegrations = IntegrationUtils.toAutoPublish(branchModel, workspace);
  var blockBranchWithEnforcement = React.useMemo((function (param) {
          var match = ValidationConfigV2.EventsV2.getCase(discrepancyConfig.events);
          if (DiscrepancyUtils.hasDiscrepencyErrors(typeof match === "object" ? true : false, ValidationConfigV2.EventsV2.hasValueChecking(discrepancyConfig.events), branchAuditChecks)) {
            return discrepancyConfig.pointOfBlock;
          }
          
        }), [
        branchModelWithPulledMaster,
        masterModel,
        discrepancyConfig
      ]);
  var match$4 = React.useState(function (param) {
        return false;
      });
  var setMergePossible = match$4[1];
  if (typeof branchStatus !== "object") {
    return null;
  }
  var tmp;
  if (typeof branchStatus !== "object") {
    tmp = null;
  } else {
    switch (branchStatus.TAG) {
      case "Merged" :
          tmp = React.createElement(React.Fragment, undefined, React.createElement(BranchActionBox__ApprovalWorkflow$MergedState, {
                    branchName: branchName,
                    mergeAction: branchStatus._0
                  }), React.createElement(BranchActionBox__ApprovalWorkflow$InspectorValidation, {
                    branchId: branchId,
                    branchModel: branchModel,
                    branchStatus: branchStatus,
                    changes: changes
                  }));
          break;
      case "Closed" :
          tmp = React.createElement(BranchActionBox__ApprovalWorkflow$ClosedState, {
                branchName: branchName,
                closeAction: branchStatus._0
              });
          break;
      default:
        var tmp$1;
        var exit = 0;
        if (typeof branchStatus !== "object") {
          tmp$1 = null;
        } else {
          switch (branchStatus.TAG) {
            case "Open" :
            case "Draft" :
                exit = 1;
                break;
            case "ReadyForReview" :
                tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(BranchActionBox__ApprovalWorkflow$ReadyForReviewState, {
                          approveActions: /* [] */0,
                          branchCreatorId: branchCreatorId,
                          branchId: branchId,
                          branchInteractionLocation: "BottomBranchActionBox",
                          branchName: branchName,
                          branchStatus: branchStatus,
                          collaborators: collaborators,
                          isBranchCreator: isBranchCreator,
                          role: role
                        }), React.createElement(BranchActionBox__Reviewers.make, {
                          branchCreatorId: branchCreatorId,
                          branchId: branchId,
                          branchStatus: branchStatus,
                          changes: changes,
                          role: role
                        }));
                break;
            case "ChangesRequested" :
                tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(BranchActionBox__ApprovalWorkflow$ChangesRequestedState, {
                          branchCreatorId: branchCreatorId,
                          branchId: branchId,
                          branchName: branchName,
                          branchModelWithPulledMaster: branchModelWithPulledMaster,
                          branchStatus: branchStatus,
                          collaborators: collaborators,
                          masterModel: masterModel,
                          isBranchCreator: isBranchCreator,
                          role: role
                        }), React.createElement(BranchActionBox__Reviewers.make, {
                          branchCreatorId: branchCreatorId,
                          branchId: branchId,
                          branchStatus: branchStatus,
                          changes: changes,
                          role: role
                        }));
                break;
            case "Approved" :
                var approveActions$1 = branchStatus._0;
                tmp$1 = isFullyApproved ? React.createElement(React.Fragment, undefined, React.createElement(BranchActionBox__ApprovalWorkflow$ApprovedState, {
                            approveActions: approveActions$1,
                            branchCreatorId: branchCreatorId,
                            branchId: branchId,
                            branchInteractionLocation: "BottomBranchActionBox",
                            branchName: branchName,
                            branchStatus: branchStatus,
                            collaborators: collaborators,
                            isBranchCreator: isBranchCreator,
                            numApprovals: Belt_List.size(approveActions$1),
                            role: role
                          }), React.createElement(BranchActionBox__Reviewers.make, {
                            branchCreatorId: branchCreatorId,
                            branchId: branchId,
                            branchStatus: branchStatus,
                            changes: changes,
                            role: role
                          })) : React.createElement(React.Fragment, undefined, React.createElement(BranchActionBox__ApprovalWorkflow$ReadyForReviewState, {
                            approveActions: approveActions$1,
                            branchCreatorId: branchCreatorId,
                            branchId: branchId,
                            branchInteractionLocation: "BottomBranchActionBox",
                            branchName: branchName,
                            branchStatus: branchStatus,
                            collaborators: collaborators,
                            isBranchCreator: isBranchCreator,
                            role: role
                          }), React.createElement(BranchActionBox__Reviewers.make, {
                            branchCreatorId: branchCreatorId,
                            branchId: branchId,
                            branchStatus: branchStatus,
                            changes: changes,
                            role: role
                          }));
                break;
            default:
              tmp$1 = null;
          }
        }
        if (exit === 1) {
          tmp$1 = React.createElement(BranchActionBox__ApprovalWorkflow$DraftState, {
                branchCreatorId: branchCreatorId,
                branchId: branchId,
                branchName: branchName,
                branchModelWithPulledMaster: branchModelWithPulledMaster,
                branchStatus: branchStatus,
                collaborators: collaborators,
                isBranchCreator: isBranchCreator,
                masterModel: masterModel,
                role: role
              });
        }
        tmp = React.createElement(React.Fragment, undefined, tmp$1, React.createElement(BranchActionBox__Components.MergeButtonSection.make, {
                  actionDiff: actionDiff,
                  actions: actions,
                  approvalWorkflow: approvalWorkflow,
                  blockBranchWithEnforcement: blockBranchWithEnforcement,
                  branchCreatorId: branchCreatorId,
                  branchDiffStatus: branchDiffStatus,
                  branchId: branchId,
                  branchModel: branchModel,
                  branchModelWithPulledMaster: branchModelWithPulledMaster,
                  branchName: branchName,
                  branchStatus: branchStatus,
                  changes: changes,
                  collaborators: collaborators,
                  masterModel: masterModel,
                  masterModelBeforeBranch: masterModelBeforeBranch,
                  mergeConflicts: mergeConflicts,
                  onMergePossible: (function (mergePossible) {
                      Curry._1(setMergePossible, (function (param) {
                              return mergePossible;
                            }));
                    }),
                  role: role,
                  sourceUpdates: sourceUpdates,
                  masterModelLatestAction: masterModelLatestAction
                }), match$4[0] ? React.createElement(React.Fragment, undefined, isGlobalWorkspace ? React.createElement(BranchActionBox__ApprovalWorkflow$GlobalWorkspaceInformation, {
                          branchModel: branchModel,
                          masterModel: masterModel
                        }) : null, React.createElement(BranchActionBox__ApprovalWorkflow$PublishingIntegrations, {
                        autoPublishIntegrations: autoPublishIntegrations,
                        branchId: branchId
                      }), React.createElement(BranchActionBox__ApprovalWorkflow$InspectorValidation, {
                        branchId: branchId,
                        branchModel: branchModel,
                        branchStatus: branchStatus,
                        changes: changes
                      })) : null);
    }
  }
  return React.createElement(BranchActionBox__Components.Container.make, {
              children: tmp
            });
}

var make = BranchActionBox__ApprovalWorkflow;

export {
  Style ,
  DraftState ,
  ReadyForReviewState ,
  ChangesRequestedState ,
  ApprovedState ,
  GlobalWorkspaceInformation ,
  InspectorValidation ,
  MergedState ,
  ClosedState ,
  PublishingIntegrations ,
  make ,
}
/* contentContainer Not a pure module */
