// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as TextInput from "./TextInput.mjs";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as PlanLightning from "./PlanLightning.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import LodashDebounce from "lodash.debounce";
import * as AuthMethodPicker from "./AuthMethodPicker.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as WorkspaceDangerZone from "./WorkspaceDangerZone.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.overflow("auto"),
      tl: /* [] */0
    });

function rowStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.padding2(Css.px(24), Css.px(16)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("spaceBetween"),
                    tl: {
                      hd: Css.opacity(disabled ? 0.5 : 1),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function subRowStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.paddingLeft(Css.px(32)),
                    tl: {
                      hd: Css.paddingRight(Css.px(16)),
                      tl: {
                        hd: Css.paddingTop(Css.px(16)),
                        tl: {
                          hd: Css.paddingBottom(Css.px(16)),
                          tl: {
                            hd: Css.opacity(disabled ? 0.5 : 1),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var separatorStyles = Curry._1(Css.style, {
      hd: Css.height(Css.px(1)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light04),
        tl: /* [] */0
      }
    });

var forceAuditConfigStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

function updateName(schemaId, newName, schemaBundle, addToast) {
  FirebaseUtils.setWorkspaceName(schemaId, newName).then(function (param) {
        AnalyticsRe.workspaceNameUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaId, newName);
        Curry._1(addToast, {
              message: "Workspace name successfully updated!",
              action: {
                message: "Undo",
                toastCallback: (function (param) {
                    FirebaseUtils.setWorkspaceName(schemaId, Belt_Option.getWithDefault(schemaBundle.schemaName, "Untitled Workspace"));
                  })
              }
            });
        return Promise.resolve();
      });
}

var debouncedUpdateName = LodashDebounce(updateName, 1000, {
      leading: false,
      trailing: true
    });

function WorkspacePermissions(Props) {
  var openBranches = Props.openBranches;
  var onClose = Props.onClose;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use();
  var globalSend = GlobalSendContext.use();
  var addToast = Toast.useAddToast();
  var canProtectMainBranch = AvoLimits.CanProtectMainBranch.computeAvailability(workspace);
  var hasAdvancedProtectedMainBranch = AvoLimits.AdvancedProtectedMainBranch.computeAvailability(workspace);
  var match = React.useState(function (param) {
        return workspace.name;
      });
  var setWorkspaceName = match[1];
  var workspaceName = match[0];
  React.useEffect((function (param) {
          if (workspace.name !== workspaceName) {
            Curry._1(setWorkspaceName, (function (param) {
                    return workspace.name;
                  }));
          }
          
        }), [workspace.name]);
  var isDisabled = canProtectMainBranch === "Unavailable" || hasAdvancedProtectedMainBranch === "Unavailable" || !workspace.settings.protectedMainBranch;
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("div", {
                  className: rowStyles(false)
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "Workspace name"
                    }), React.createElement(Spacer.make, {
                      width: 16,
                      grow: 1.0
                    }), React.createElement(TextInput.make, {
                      onChange: (function (newName) {
                          Curry._1(setWorkspaceName, (function (param) {
                                  return newName;
                                }));
                          debouncedUpdateName(workspace.id, newName, schemaBundle, addToast);
                        }),
                      value: workspaceName
                    })), React.createElement("div", {
                  className: separatorStyles
                }), React.createElement("div", {
                  className: rowStyles(false)
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "Authentication method"
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement(AuthMethodPicker.make, {
                      workspace: workspace
                    })), React.createElement("div", {
                  className: separatorStyles
                }), React.createElement("div", {
                  className: rowStyles(false)
                }, React.createElement("div", undefined, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Avo Intelligence"
                        }), React.createElement(Spacer.make, {
                          height: 2
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Regular",
                          color: Styles.Color.light10,
                          maxWidth: Css.px(280),
                          children: "Enhance your workspace with features like smart search. Enabling this setting allows Avo to share your Tracking Plan data (e.g., event and property names, descriptions, and metrics) with OpenAI models."
                        })), React.createElement(Switch.make, {
                      value: Belt_Option.getWithDefault(workspace.settings.smartSearchEnabled, false),
                      onChange: (function (param) {
                          Workspace.toggleSetting(workspace, Firebase.app(), "SmartSearchEnabled", schemaBundle);
                        }),
                      faded: false
                    })), React.createElement("div", {
                  className: separatorStyles
                }), React.createElement("div", {
                  className: rowStyles(false)
                }, React.createElement("div", undefined, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Protected main branch"
                        }), React.createElement(Spacer.make, {
                          height: 2
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Regular",
                          color: Styles.Color.light10,
                          maxWidth: Css.px(280),
                          children: "Only admins can make changes directly on the main branch. Branches require approval to be merged."
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          })
                    }, canProtectMainBranch === "Available" ? null : React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css_Legacy_Core.SVG.fill("transparent"),
                                  tl: {
                                    hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light08),
                                    tl: {
                                      hd: Css.marginRight(Css.px(8)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(PlanLightning.make, {
                                tooltipText: (
                                  canProtectMainBranch === "Unavailable" ? "Available " : "Available during trial and "
                                ) + ("on the " + (Workspace.printPlanName(AvoLimits.CanProtectMainBranch.availableOnPlan(workspace).name) + " plan"))
                              })), React.createElement(Switch.make, {
                          value: workspace.settings.protectedMainBranch,
                          onChange: (function (param) {
                              if (canProtectMainBranch === "Unavailable") {
                                return Curry._1(globalSend, {
                                            TAG: "OpenModal",
                                            _0: {
                                              NAME: "BillingPrompt",
                                              VAL: "BranchApprovalWorkflows"
                                            }
                                          });
                              } else {
                                return Workspace.toggleSetting(workspace, Firebase.app(), "ProtectedMainBranch", schemaBundle);
                              }
                            }),
                          faded: canProtectMainBranch === "Unavailable"
                        }))), React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: subRowStyles(isDisabled)
                    }, React.createElement("div", undefined, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light12,
                              children: "Require admin approval"
                            }), React.createElement(Spacer.make, {
                              height: 2
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Regular",
                              color: Styles.Color.light10,
                              maxWidth: Css.px(280),
                              children: "Only admins can approve branches."
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, hasAdvancedProtectedMainBranch === "Available" ? null : React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css_Legacy_Core.SVG.fill("transparent"),
                                      tl: {
                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light08),
                                        tl: {
                                          hd: Css.marginRight(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement(PlanLightning.make, {
                                    tooltipText: (
                                      hasAdvancedProtectedMainBranch === "Unavailable" ? "Available " : "Available during trial and "
                                    ) + ("on the " + (Workspace.printPlanName(AvoLimits.AdvancedProtectedMainBranch.availableOnPlan(workspace).name) + " plan"))
                                  })), React.createElement(Switch.make, {
                              value: workspace.settings.requireAdminApproval,
                              onChange: (function (param) {
                                  Workspace.toggleSetting(workspace, Firebase.app(), "RequireAdminApproval", schemaBundle);
                                }),
                              disabled: isDisabled
                            }))), React.createElement("div", {
                      className: subRowStyles(isDisabled)
                    }, React.createElement("div", undefined, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light12,
                              children: "Number of required approvals"
                            }), React.createElement(Spacer.make, {
                              height: 2
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Regular",
                              color: Styles.Color.light10,
                              maxWidth: Css.px(280),
                              children: "Number of approvals required to merge a branch."
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, hasAdvancedProtectedMainBranch === "Available" ? null : React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css_Legacy_Core.SVG.fill("transparent"),
                                      tl: {
                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light08),
                                        tl: {
                                          hd: Css.marginRight(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement(PlanLightning.make, {
                                    tooltipText: (
                                      hasAdvancedProtectedMainBranch === "Unavailable" ? "Available " : "Available during trial and "
                                    ) + ("on the " + (Workspace.printPlanName(AvoLimits.AdvancedProtectedMainBranch.availableOnPlan(workspace).name) + " plan"))
                                  })), React.createElement(Select.make, {
                              disabled: isDisabled,
                              onSelect: (function (value) {
                                  Workspace.updateNumRequiredApprovals(workspace, Firebase.app(), Caml_format.int_of_string(value), schemaBundle);
                                }),
                              options: Belt_Array.map(Belt_Array.makeBy(Workspace.maxRequiredApprovals, (function (index) {
                                          return index + 1 | 0;
                                        })), (function (value) {
                                      return [
                                              {
                                                NAME: "Label",
                                                VAL: String(value)
                                              },
                                              String(value)
                                            ];
                                    })),
                              value: String(workspace.settings.numRequiredApprovals)
                            }))), React.createElement("div", {
                      className: separatorStyles
                    }), React.createElement("div", {
                      className: rowStyles(false)
                    }, React.createElement("div", undefined, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.light12,
                              children: "Tracking plan audit config"
                            }), React.createElement(Spacer.make, {
                              height: 2
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Regular",
                              color: Styles.Color.light10,
                              maxWidth: Css.px(360),
                              children: "Avo validates your tracking plan in real-time and audits branch changes to help you progress towards better data quality."
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement(Button.make, {
                              label: "Configure",
                              onClick: (function (param) {
                                  Curry._1(onClose, undefined);
                                  Curry._1(globalSend, {
                                        TAG: "OpenSlideOver",
                                        _0: {
                                          NAME: "DiscrepanciesConfig",
                                          VAL: undefined
                                        }
                                      });
                                }),
                              style: "outline"
                            }))), React.createElement("div", {
                      className: separatorStyles
                    }), React.createElement("div", {
                      className: rowStyles(false)
                    }, React.createElement(WorkspaceDangerZone.make, {
                          openBranches: openBranches,
                          onClose: onClose
                        }))));
}

var make = WorkspacePermissions;

export {
  rootStyles ,
  rowStyles ,
  subRowStyles ,
  separatorStyles ,
  forceAuditConfigStyle ,
  updateName ,
  debouncedUpdateName ,
  make ,
}
/* rootStyles Not a pure module */
