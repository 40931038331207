// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.outline(Css.px(1), "solid", Styles.Color.red),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: /* [] */0
          }
        }
      }
    });

var textBoxStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(12)),
      tl: /* [] */0
    });

function WorkspaceDangerZone(Props) {
  var openBranches = Props.openBranches;
  var onClose = Props.onClose;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use();
  var sendActions = SendActionsContext.use();
  var globalSend = GlobalSendContext.use();
  var match = React.useState(function (param) {
        return false;
      });
  var setLoading = match[1];
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: /* [] */0
                      })
                }, React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "Danger zone"
                    }), React.createElement(Spacer.make, {
                      width: 16
                    })), React.createElement("div", {
                  className: textBoxStyles
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Reset tracking plan"
                    }), React.createElement(Spacer.make, {
                      width: 16
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.width(Css.pct(100.0)),
                              tl: {
                                hd: Css.justifyContent("spaceBetween"),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.light10,
                          maxWidth: Css.px(250),
                          children: null
                        }, "Clear tracking plan and close all currently open branches. ", React.createElement("a", {
                              className: Curry._1(Css.style, {
                                    hd: Css.color(Styles.Color.primaryPurpleShade1),
                                    tl: {
                                      hd: Css.textDecoration("none"),
                                      tl: {
                                        hd: Css.selector(" path", {
                                              hd: Css_Legacy_Core.SVG.fill(Styles.Color.primaryPurpleShade1),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }),
                              href: "https://www.avo.app/docs/workspace/reset-tracking-plan"
                            }, React.createElement($$Text.make, {
                                  element: "Span",
                                  weight: "Semi",
                                  children: "Read more in our docs "
                                }), React.createElement("svg", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.verticalAlign("middle"),
                                        tl: /* [] */0
                                      }),
                                  height: "15",
                                  width: "11",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, React.createElement("path", {
                                      d: "M2.95 10.1l4.14-4.14-.01 3.13h1.14V4h-5.1v1.13h3.14L2.13 9.27l.82.83z"
                                    })))), React.createElement(Button.make, {
                          label: "Reset Tracking Plan",
                          loading: match[0],
                          onClick: (function (param) {
                              Curry._1(globalSend, {
                                    TAG: "OpenModal",
                                    _0: {
                                      NAME: "ComposableModal",
                                      VAL: [
                                        {
                                          NAME: "string",
                                          VAL: "Reset tracking plan"
                                        },
                                        {
                                          NAME: "component",
                                          VAL: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                                    color: Styles.Color.light11,
                                                    children: "Tracking Plan reset removes all events, properties, metrics, tags, categories and publishing integrations from your workspace. The reset will also close all currently open branches."
                                                  }), React.createElement(Spacer.make, {
                                                    height: 4
                                                  }), React.createElement($$Text.make, {
                                                    color: Styles.Color.light11,
                                                    children: "Sources, destinations, workspace members and permissions, Slack integrations and the Inspector will not be affected by the reset."
                                                  }), React.createElement(Spacer.make, {
                                                    height: 4
                                                  }), React.createElement($$Text.make, {
                                                    weight: "Semi",
                                                    children: "Are you sure you want to reset your tracking plan?"
                                                  }))
                                        },
                                        [
                                          {
                                            NAME: "secondary",
                                            VAL: {
                                              label: "Cancel",
                                              onClick: (function (closeModal) {
                                                  Curry._1(closeModal, undefined);
                                                })
                                            }
                                          },
                                          {
                                            NAME: "primary",
                                            VAL: {
                                              label: "Reset",
                                              onClick: (function (closeModal) {
                                                  Curry._1(closeModal, undefined);
                                                  Curry._1(setLoading, (function (param) {
                                                          return true;
                                                        }));
                                                  var actionsToCloseOpenBranches = Belt_Array.mapU(Belt_List.toArray(openBranches), (function (param) {
                                                          var branchId = param[0];
                                                          return [
                                                                  {
                                                                    NAME: "CloseBranch",
                                                                    VAL: [
                                                                      branchId,
                                                                      param[1]
                                                                    ]
                                                                  },
                                                                  {
                                                                    branchId: branchId,
                                                                    branchQuery: branchId
                                                                  }
                                                                ];
                                                        }));
                                                  Curry._1(setLoading, (function (param) {
                                                          return false;
                                                        }));
                                                  Curry.app(sendActions, [
                                                        "Master",
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        true,
                                                        undefined,
                                                        undefined,
                                                        (function (branch) {
                                                            Router.Schema.pushSchemaRoute("master", undefined, "events");
                                                            Curry._1(onClose, undefined);
                                                            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                            AnalyticsRe.workspaceSettingUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, undefined, "ResetTrackingPlan", undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId, workspace.id, undefined, undefined, undefined, undefined, undefined);
                                                          }),
                                                        undefined,
                                                        Belt_Array.concat([[
                                                                "ResetTrackingPlan",
                                                                {
                                                                  branchId: "master",
                                                                  branchQuery: "master"
                                                                }
                                                              ]], actionsToCloseOpenBranches)
                                                      ]);
                                                })
                                            }
                                          }
                                        ],
                                        500
                                      ]
                                    }
                                  });
                            }),
                          style: {
                            NAME: "custom",
                            VAL: {
                              customColor: Styles.Color.darkRed,
                              hoverColor: Styles.Color.white,
                              customBackgroundColor: Styles.Color.white,
                              customBackgroundHoverColor: Styles.Color.darkRed,
                              optionalCustomOutlineColor: Styles.Color.darkRed,
                              optionalCustomOutlineHoverColor: Styles.Color.darkRed
                            }
                          }
                        }))));
}

var make = WorkspaceDangerZone;

export {
  rootStyles ,
  textBoxStyles ,
  make ,
}
/* rootStyles Not a pure module */
