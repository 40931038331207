// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Nanoid from "nanoid";
import * as AvoModel from "../../../app/src/avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as ModelUtils_mapped from "../../../app/src/ModelUtils_mapped.mjs";
import * as BeltListExtensions from "../../../app/src/BeltListExtensions.mjs";
import * as ComputeUnionAllowedValuesUseCase from "../../../model/src/eventSpecificPropertyValues/ComputeUnionAllowedValuesUseCase.mjs";
import * as ComputeUniqueAllowedValuesSetsUseCase from "../../../model/src/eventSpecificPropertyValues/ComputeUniqueAllowedValuesSetsUseCase.mjs";

function enrichWithVirtualPropertiesSlow(model, mappedModel, sourceId) {
  return Belt_List.reduceU(model.properties, model, (function (model, property) {
                if (property.TAG === "PropertyRef") {
                  return model;
                } else {
                  var property$1 = property._0;
                  if (!(ModelUtils_mapped.hasMigrated(mappedModel, "EventSpecificAllowedPropertyValues") && property$1.type_ === "string")) {
                    return model;
                  }
                  var handleNoVirtualPropertiesNeeded = function (model) {
                    return ModelUtils.updateProperty(model, property$1.id, (function (property) {
                                  var allowedValues = ComputeUnionAllowedValuesUseCase.computeWithSlowModel(model, [sourceId], property);
                                  var newMatches = Belt_List.fromArray(Belt_Array.mapU(allowedValues, (function (value) {
                                              return [
                                                      {
                                                        NAME: "Literal",
                                                        VAL: {
                                                          NAME: "StringLit",
                                                          VAL: value
                                                        }
                                                      },
                                                      /* [] */0
                                                    ];
                                            })));
                                  var existingValidationsWithoutMatches = Belt_List.keepU(property.validations, (function (item) {
                                          if (typeof item === "object") {
                                            return item.NAME !== "Matches";
                                          } else {
                                            return true;
                                          }
                                        }));
                                  var validations = Belt_List.concat(existingValidationsWithoutMatches, Belt_List.length(newMatches) > 0 ? ({
                                            hd: {
                                              NAME: "Matches",
                                              VAL: newMatches
                                            },
                                            tl: /* [] */0
                                          }) : /* [] */0);
                                  var newrecord = Caml_obj.obj_dup(property);
                                  newrecord.validations = validations;
                                  return newrecord;
                                }));
                  };
                  if (property$1.eventSpecificAllowedPropertyValues.length === 0 || ModelUtils_mapped.isPropertyIdNested(mappedModel, property$1.id)) {
                    return handleNoVirtualPropertiesNeeded(model);
                  }
                  var modelPropertiesArray = Belt_List.toArray(AvoModel.resolveProperties(model, model.properties));
                  var uniqueAllowedValuesSets = ComputeUniqueAllowedValuesSetsUseCase.compute(mappedModel, modelPropertiesArray, [sourceId], undefined, property$1);
                  if (uniqueAllowedValuesSets.allowedValuesWithNames.length <= 1) {
                    return handleNoVirtualPropertiesNeeded(model);
                  }
                  var newProperties = Belt_Array.mapWithIndexU(uniqueAllowedValuesSets.allowedValuesWithNames, (function (index, param) {
                          var newMatches = Belt_List.fromArray(Belt_Array.mapU(param[0], (function (value) {
                                      return [
                                              {
                                                NAME: "Literal",
                                                VAL: {
                                                  NAME: "StringLit",
                                                  VAL: value
                                                }
                                              },
                                              /* [] */0
                                            ];
                                    })));
                          var newrecord = Caml_obj.obj_dup(property$1);
                          return {
                                  TAG: "Property",
                                  _0: (newrecord.virtualForAllowedValues = true, newrecord.validations = {
                                      hd: {
                                        NAME: "Matches",
                                        VAL: newMatches
                                      },
                                      tl: /* [] */0
                                    }, newrecord.uniqueName = param[1], newrecord.id = index === 0 ? property$1.id : Nanoid.nanoid(), newrecord)
                                };
                        }));
                  return {
                          types: model.types,
                          properties: BeltListExtensions.flatMapU(model.properties, (function (sproperty) {
                                  if (sproperty.TAG === "PropertyRef" || sproperty._0.id !== property$1.id) {
                                    return {
                                            hd: sproperty,
                                            tl: /* [] */0
                                          };
                                  } else {
                                    return Belt_List.fromArray(newProperties);
                                  }
                                })),
                          propertyBundles: model.propertyBundles,
                          events: Belt_List.mapU(model.events, (function ($$event) {
                                  return {
                                          id: $$event.id,
                                          name: $$event.name,
                                          uniqueName: $$event.uniqueName,
                                          description: $$event.description,
                                          directPropertyRefs: Belt_List.mapU($$event.directPropertyRefs, (function (propertyRef) {
                                                  var propertyId = ModelUtils.getPropertyId(propertyRef);
                                                  if (propertyId !== property$1.id) {
                                                    return propertyRef;
                                                  }
                                                  var newPropertyUniqueName = Belt_MapString.get(uniqueAllowedValuesSets.eventIdToAllowedValuesDataStructureName, $$event.id);
                                                  var newProperty = Belt_Array.getByU(newProperties, (function (property) {
                                                          return Caml_obj.equal(Belt_Option.flatMapU(ModelUtils.resolveProperty(model, property), (function (x) {
                                                                            return x.uniqueName;
                                                                          })), newPropertyUniqueName);
                                                        }));
                                                  if (newProperty === undefined) {
                                                    return propertyRef;
                                                  }
                                                  if (propertyRef.TAG !== "PropertyRef") {
                                                    return propertyRef;
                                                  }
                                                  var propertyRef$1 = propertyRef._0;
                                                  var newPropertyRef_id = ModelUtils.getPropertyId(newProperty);
                                                  var newPropertyRef_description = propertyRef$1.description;
                                                  var newPropertyRef_pinnedValue = propertyRef$1.pinnedValue;
                                                  var newPropertyRef = {
                                                    id: newPropertyRef_id,
                                                    description: newPropertyRef_description,
                                                    pinnedValue: newPropertyRef_pinnedValue
                                                  };
                                                  return {
                                                          TAG: "PropertyRef",
                                                          _0: newPropertyRef
                                                        };
                                                })),
                                          propertyBundles: $$event.propertyBundles,
                                          variants: $$event.variants,
                                          types: $$event.types,
                                          tags: $$event.tags,
                                          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                                          includeSources: $$event.includeSources,
                                          includeDestinations: $$event.includeDestinations,
                                          hashes: $$event.hashes,
                                          propertyWhitelist: $$event.propertyWhitelist,
                                          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                                          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                                          triggers: $$event.triggers,
                                          globalRequirementsMetadata: $$event.globalRequirementsMetadata
                                        };
                                })),
                          migrations: model.migrations,
                          sources: model.sources,
                          destinations: model.destinations,
                          groupTypes: model.groupTypes,
                          goals: model.goals,
                          metrics: model.metrics,
                          archive: model.archive,
                          openBranches: model.openBranches,
                          branchPointer: model.branchPointer,
                          rules: model.rules,
                          integrations: model.integrations,
                          globalRequirements: model.globalRequirements
                        };
                }
              }));
}

export {
  enrichWithVirtualPropertiesSlow ,
}
/* nanoid Not a pure module */
