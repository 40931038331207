// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as DiffEvent from "./DiffEvent.mjs";
import * as CopyChangesToBranchModal__Utils from "./CopyChangesToBranchModal__Utils.mjs";

function CopyChangesToBranch__Events(Props) {
  var allEventActions = Props.allEventActions;
  var currentBranchId = Props.currentBranchId;
  var eventActions = Props.eventActions;
  var fromModel = Props.fromModel;
  var toModel = Props.toModel;
  var toModelWithAppliedActions = Props.toModelWithAppliedActions;
  if (allEventActions !== /* [] */0) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.gap(Css.px(8)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, allEventActions !== /* [] */0 ? React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingTop(Css.px(16)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(12)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light10,
                          children: "Event changes"
                        })) : null, Belt_List.toArray(Belt_List.map(eventActions, (function (actions) {
                          return React.createElement(DiffEvent.make, {
                                      branchStatus: "Loading",
                                      masterModel: fromModel,
                                      branchModel: toModelWithAppliedActions,
                                      actions: actions,
                                      branchId: currentBranchId,
                                      events: toModelWithAppliedActions.events,
                                      withCommentsAndCopyToBranch: false,
                                      collapsible: true,
                                      key: CopyChangesToBranchModal__Utils.Actions.getItemId(actions)
                                    });
                        }))), eventActions === /* [] */0 ? Belt_List.toArray(Belt_List.map(allEventActions, (function (actions) {
                            var itemId = CopyChangesToBranchModal__Utils.Actions.getItemId(actions);
                            return React.createElement("div", {
                                        key: itemId,
                                        className: Curry._1(Css.style, {
                                              hd: Css.opacity(0.6),
                                              tl: {
                                                hd: Css.filter({
                                                      hd: {
                                                        NAME: "grayscale",
                                                        VAL: 100.0
                                                      },
                                                      tl: /* [] */0
                                                    }),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, React.createElement(DiffEvent.make, {
                                            branchStatus: "Loading",
                                            masterModel: fromModel,
                                            branchModel: toModel,
                                            actions: actions,
                                            branchId: currentBranchId,
                                            events: toModel.events,
                                            withCommentsAndCopyToBranch: false,
                                            collapsible: true
                                          }));
                          }))) : null);
  } else {
    return null;
  }
}

var Utils;

var make = CopyChangesToBranch__Events;

export {
  Utils ,
  make ,
}
/* Css Not a pure module */
