// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as ActionDiff from "../../shared/ActionDiff.mjs";
import * as FetchModel from "./FetchModel.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ViewerContext from "./ViewerContext.mjs";
import * as ActionsReducer from "./actionsReducer.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as BranchStatusModel from "../../shared/models/BranchStatusModel.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";
import * as BranchActionDiffCalculator from "./BranchActionDiffCalculator.mjs";

function useFromAndToModelsForCopy(originBranch, destinationBranch) {
  var workspace = WorkspaceContext.use();
  var mainModel = ModelStore.useMainModel();
  var latestMainAction = ModelStore.useLatestMainAction();
  var model = ModelStore.useModel();
  var fetchOriginBranchModel = FetchModel.use(undefined, undefined, typeof originBranch === "object" ? "Fetch" : "Skip", typeof originBranch === "object" ? ({
            NAME: "branch",
            VAL: originBranch.VAL
          }) : ({
            NAME: "branch",
            VAL: "NA"
          }));
  var match = React.useMemo((function (param) {
          if (typeof originBranch !== "object") {
            return [
                    model,
                    undefined
                  ];
          }
          switch (fetchOriginBranchModel.TAG) {
            case "Loading" :
            case "Error" :
                return [
                        undefined,
                        undefined
                      ];
            case "Result" :
                return [
                        fetchOriginBranchModel._0,
                        fetchOriginBranchModel._2
                      ];
            
          }
        }), [
        originBranch,
        fetchOriginBranchModel
      ]);
  var maybeOriginBranchModel = match[0];
  var maybeCurrentBranchDiffInformation = ModelStore.useDiffInformation();
  var maybeFromMainToCurrentModels = React.useMemo((function (param) {
          return Belt_Option.map(maybeCurrentBranchDiffInformation, (function (diffInformation) {
                        return {
                                fromMainModel: diffInformation.fromAndToModels.from.model,
                                toOriginModel: diffInformation.fromAndToModels.to.model
                              };
                      }));
        }), [maybeCurrentBranchDiffInformation]);
  var fetchFromMainToCurrentBranchModels = BranchActionDiffCalculator.useFromAndToModels(typeof originBranch === "object" ? (
          maybeOriginBranchModel !== undefined ? "Fetch" : "Wait"
        ) : "Skip", workspace.id, typeof originBranch === "object" ? originBranch.VAL : "NA", Belt_Option.getWithDefault(maybeOriginBranchModel, mainModel), match[1], mainModel, latestMainAction, (function (param) {
          
        }));
  var maybeMainToOriginBranchDiffInformation = React.useMemo((function (param) {
          if (typeof originBranch === "object") {
            if (fetchFromMainToCurrentBranchModels !== undefined) {
              return {
                      fromMainModel: fetchFromMainToCurrentBranchModels.from.model,
                      toOriginModel: fetchFromMainToCurrentBranchModels.to.model
                    };
            } else {
              return ;
            }
          } else {
            return maybeFromMainToCurrentModels;
          }
        }), [
        originBranch,
        fetchFromMainToCurrentBranchModels
      ]);
  var fetchDestinationBranchModel = FetchModel.use(undefined, undefined, destinationBranch !== undefined && typeof destinationBranch === "object" && destinationBranch.NAME !== "Create" ? "Fetch" : "Skip", destinationBranch !== undefined && typeof destinationBranch === "object" && destinationBranch.NAME !== "Create" ? ({
            NAME: "branch",
            VAL: destinationBranch.VAL.id
          }) : "master");
  var destinationBranchModelFetched = React.useMemo((function (param) {
          if (destinationBranch === undefined) {
            return false;
          }
          if (typeof destinationBranch !== "object") {
            return false;
          }
          if (destinationBranch.NAME !== "Branch") {
            return false;
          }
          switch (fetchDestinationBranchModel.TAG) {
            case "Loading" :
            case "Error" :
                return false;
            case "Result" :
                var latestAction = fetchDestinationBranchModel._2;
                if (latestAction !== undefined && Caml_obj.equal(destinationBranch.VAL.id, Caml_option.undefined_to_opt(Caml_option.valFromOption(latestAction).branchId))) {
                  return true;
                } else {
                  return false;
                }
            
          }
        }), [
        destinationBranch,
        fetchDestinationBranchModel
      ]);
  var tmp;
  switch (fetchDestinationBranchModel.TAG) {
    case "Loading" :
    case "Error" :
        tmp = mainModel;
        break;
    case "Result" :
        tmp = fetchDestinationBranchModel._0;
        break;
    
  }
  var tmp$1;
  switch (fetchDestinationBranchModel.TAG) {
    case "Loading" :
    case "Error" :
        tmp$1 = undefined;
        break;
    case "Result" :
        tmp$1 = fetchDestinationBranchModel._2;
        break;
    
  }
  var maybeDestinationBranchFromAndToModels = BranchActionDiffCalculator.useFromAndToModels(destinationBranchModelFetched ? "Fetch" : "Skip", workspace.id, destinationBranch !== undefined && typeof destinationBranch === "object" && destinationBranch.NAME !== "Create" ? destinationBranch.VAL.id : "master", tmp, tmp$1, mainModel, latestMainAction, (function (param) {
          
        }));
  var maybeOriginBranchWithDestinationBranchChanges = React.useMemo((function (param) {
          if (maybeDestinationBranchFromAndToModels === undefined) {
            return ;
          }
          if (maybeMainToOriginBranchDiffInformation === undefined) {
            return ;
          }
          var destinationBranchMergeActions = ActionDiff.diff((function (error) {
                  console.error("Destination branch changes diff error", error);
                }), maybeDestinationBranchFromAndToModels.from.model, maybeDestinationBranchFromAndToModels.to.model, undefined);
          var mainWithDestinationBranchChanges = Belt_List.reduce(destinationBranchMergeActions, mainModel, ActionsReducer.reduce);
          var originBranchChanges = ActionDiff.diff((function (error) {
                  console.error("Origin branch changes branch diff error", error);
                }), maybeMainToOriginBranchDiffInformation.fromMainModel, maybeMainToOriginBranchDiffInformation.toOriginModel, undefined);
          return Belt_List.reduce(originBranchChanges, mainWithDestinationBranchChanges, ActionsReducer.reduce);
        }), [
        maybeDestinationBranchFromAndToModels,
        maybeMainToOriginBranchDiffInformation
      ]);
  return React.useMemo((function (param) {
                if (destinationBranch !== undefined && typeof destinationBranch === "object" && destinationBranch.NAME !== "Create") {
                  if (maybeOriginBranchWithDestinationBranchChanges !== undefined && maybeDestinationBranchFromAndToModels !== undefined) {
                    return {
                            NAME: "Loaded",
                            VAL: [
                              maybeDestinationBranchFromAndToModels.to.model,
                              maybeOriginBranchWithDestinationBranchChanges
                            ]
                          };
                  } else {
                    return "Loading";
                  }
                }
                if (maybeMainToOriginBranchDiffInformation !== undefined) {
                  return {
                          NAME: "Loaded",
                          VAL: [
                            maybeMainToOriginBranchDiffInformation.fromMainModel,
                            maybeMainToOriginBranchDiffInformation.toOriginModel
                          ]
                        };
                } else {
                  return "Loading";
                }
              }), [
              destinationBranch,
              maybeMainToOriginBranchDiffInformation,
              maybeOriginBranchWithDestinationBranchChanges,
              maybeDestinationBranchFromAndToModels
            ]);
}

function useCanCopyChangesFromBranch(branchId, branchStatus, hasArchive, withCommentsAndCopyToBranch) {
  var isGlobalBranch = GlobalRequirementsUtils.isGlobalBranch(branchId);
  var viewer = ViewerContext.use();
  var member = WorkspaceContext.useMemberWithUserId(viewer.id);
  var canEdit = Belt_Option.mapWithDefault(member, false, (function (member) {
          return RoleUtils.canEdit(RoleUtils.getRoleFromMember(member));
        }));
  if (!BranchStatusModel.branchIsMergedOrMain(branchStatus) && Belt_Option.isNone(hasArchive) && withCommentsAndCopyToBranch && !isGlobalBranch) {
    return canEdit;
  } else {
    return false;
  }
}

export {
  useFromAndToModelsForCopy ,
  useCanCopyChangesFromBranch ,
}
/* react Not a pure module */
