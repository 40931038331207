// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "../Link.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "../AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Timestamp from "../Timestamp.mjs";
import * as Workspace from "../../../model/src/Workspace.mjs";
import * as EmptyState from "../EmptyState.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RouterStore from "../RouterStore.mjs";
import * as IconInfinity from "../IconInfinity.mjs";
import * as React$1 from "motion/react";
import * as ListLimitsInfo from "../ListLimitsInfo.mjs";
import * as EntityLightning from "../EntityLightning.mjs";
import * as IntegrationIcon from "./IntegrationIcon.mjs";
import * as PublishingEmpty from "../emptystates/PublishingEmpty.mjs";
import * as IntegrationUtils from "../../../api/functions/src/IntegrationUtils.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as EmptyStatesStyles from "../emptystates/EmptyStatesStyles.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";

function rootStyles(isActive) {
  return Curry._1(Css.style, {
              hd: Css.hover(isActive ? /* [] */0 : ({
                        hd: Css.color(Styles.Color.deepBlue),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.light02),
                          tl: /* [] */0
                        }
                      })),
              tl: {
                hd: Css.position("relative"),
                tl: {
                  hd: Css.fontSize(Styles.FontSize.regular),
                  tl: {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.backgroundColor(isActive ? Styles.Color.light02 : Styles.Color.white),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.padding4(Css.px(15), Css.px(20), Css.px(15), Css.px(20)),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "all"),
                              tl: {
                                hd: Css.after({
                                      hd: Css.display("block"),
                                      tl: {
                                        hd: Css.position("absolute"),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.light02),
                                          tl: {
                                            hd: Css.height(Css.px(1)),
                                            tl: {
                                              hd: Css.left(Css.px(16)),
                                              tl: {
                                                hd: Css.right(Css.px(16)),
                                                tl: {
                                                  hd: Css.bottom(Css.px(0)),
                                                  tl: {
                                                    hd: Css.unsafe("content", "' '"),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                tl: {
                                  hd: Css.lastOfType({
                                        hd: Css.after({
                                              hd: Css.display("none"),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var infoStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(16)),
      tl: {
        hd: Css.marginRight(Css.px(16)),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: /* [] */0
        }
      }
    });

var autoPublishStyles = Curry._1(Css.style, {
      hd: Css.hover({
            hd: Css.selector(" .tooltip", {
                  hd: Css.opacity(1.0),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.justifyContent("flexEnd"),
            tl: {
              hd: Css.flexShrink(0.0),
              tl: {
                hd: Css.position("relative"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var tooltipStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.pct(100.0)),
        tl: {
          hd: Css.zIndex(1),
          tl: {
            hd: Css.width(Css.px(200)),
            tl: {
              hd: Css.marginTop(Css.pct(-50.0)),
              tl: {
                hd: Css.marginRight(Css.px(8)),
                tl: {
                  hd: Css.borderRadius(Css.px(5)),
                  tl: {
                    hd: Css.padding(Css.px(8)),
                    tl: {
                      hd: Css.pointerEvents("none"),
                      tl: {
                        hd: Css.opacity(0.0),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.light10),
                          tl: {
                            hd: Css.after({
                                  hd: Css.display("block"),
                                  tl: {
                                    hd: Css.position("absolute"),
                                    tl: {
                                      hd: Css.left(Css.pct(100.0)),
                                      tl: {
                                        hd: Css.top(Css.pct(50.0)),
                                        tl: {
                                          hd: Css.marginTop(Css.px(-6)),
                                          tl: {
                                            hd: Css.border(Css.px(6), "solid", "transparent"),
                                            tl: {
                                              hd: Css.borderLeftColor(Styles.Color.light10),
                                              tl: {
                                                hd: Css.height(Css.px(0)),
                                                tl: {
                                                  hd: Css.width(Css.px(0)),
                                                  tl: {
                                                    hd: Css.unsafe("content", "' '"),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function Integrations$ListItem(Props) {
  var integration = Props.integration;
  var path = Props.path;
  var isActive = Props.isActive;
  var workspaceLimits = Props.workspaceLimits;
  var workspace = WorkspaceContext.use();
  var publishAction = FirebaseFetcherHooks.useLastIntegrationPublishAction(workspace.id, integration.id);
  var match = AvoLimits.Integration.entityStatus(workspaceLimits, integration.id);
  var availability = match[0];
  var tmp;
  if (typeof publishAction === "object") {
    var action = publishAction.VAL;
    if (action !== undefined) {
      var action$1 = Caml_option.valFromOption(action);
      var match$1 = action$1.contents;
      var match$2 = integration.config;
      if (typeof match$1 === "object") {
        var variant = match$1.NAME;
        if (variant === "PublishIntegration") {
          tmp = match$1.VAL[1] ? React.createElement($$Text.make, {
                  element: "Span",
                  color: Styles.Color.light10,
                  children: null
                }, "Published ", React.createElement(Timestamp.make, {
                      date: action$1.createdAt
                    })) : React.createElement($$Text.make, {
                  element: "Span",
                  color: Styles.Color.darkRed,
                  children: null
                }, "Published with errors ", React.createElement(Timestamp.make, {
                      date: action$1.createdAt
                    }));
        } else if (variant === "AutoPublishIntegration") {
          var match$3 = match$1.VAL;
          if (match$3[1]) {
            tmp = React.createElement($$Text.make, {
                  element: "Span",
                  color: Styles.Color.light10,
                  children: null
                }, "Auto published ", React.createElement(Timestamp.make, {
                      date: action$1.createdAt
                    }));
          } else {
            var exit = 0;
            if (match$3[4] !== undefined && match$2 !== undefined && match$2.TAG === "Taxonomy") {
              tmp = React.createElement($$Text.make, {
                    element: "Span",
                    color: Styles.Color.darkRed,
                    children: null
                  }, "Auto published with errors ", React.createElement(Timestamp.make, {
                        date: action$1.createdAt
                      }));
            } else {
              exit = 1;
            }
            if (exit === 1) {
              tmp = React.createElement($$Text.make, {
                    element: "Span",
                    color: Styles.Color.darkRed,
                    children: null
                  }, "Failed to auto publish ", React.createElement(Timestamp.make, {
                        date: action$1.createdAt
                      }));
            }
            
          }
        } else {
          tmp = Pervasives.failwith("Unexpected action when rendering integration status");
        }
      } else {
        tmp = Pervasives.failwith("Unexpected action when rendering integration status");
      }
    } else {
      tmp = IntegrationUtils.hasPublishableConfig(integration.config) ? "Ready to publish" : "Finish configuring to publish";
    }
  } else {
    tmp = publishAction === "Error" ? React.createElement($$Text.make, {
            element: "Span",
            color: Styles.Color.red,
            children: "Failed to fetch publishing status"
          }) : "...";
  }
  return React.createElement(Link.make, {
              path: path,
              className: rootStyles(isActive),
              children: null
            }, React.createElement(IntegrationIcon.make, {
                  config: integration.config,
                  size: 32
                }), React.createElement("div", {
                  className: infoStyles
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: availability === "Unavailable" ? Styles.Color.light08 : Styles.Color.light12,
                      children: AvoConfig.getIntegrationName(integration)
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Regular",
                      color: Styles.Color.light10,
                      children: tmp
                    })), React.createElement(EntityLightning.make, {
                  availability: availability,
                  planHint: match[1]
                }), React.createElement(React$1.AnimatePresence, {
                  children: availability !== "Unavailable" && IntegrationUtils.isAutoPublishable(integration) ? React.createElement(React$1.motion.div, {
                          animate: {
                            opacity: 1,
                            x: 0
                          },
                          transition: {
                            duration: 0.3
                          },
                          initial: {
                            opacity: 0,
                            x: 50
                          },
                          exit: {
                            opacity: 0,
                            x: 50
                          },
                          className: autoPublishStyles,
                          key: "autoPublishing",
                          children: null
                        }, React.createElement(IconInfinity.make, {}), React.createElement("div", {
                              className: "tooltip " + tooltipStyles
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.white,
                                  children: "This integration will auto publish when a branch is merged"
                                }))) : null
                }));
}

var ListItem = {
  rootStyles: rootStyles,
  infoStyles: infoStyles,
  autoPublishStyles: autoPublishStyles,
  tooltipStyles: tooltipStyles,
  make: Integrations$ListItem
};

var rootStyles$1 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(40)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.alignItems("stretch"),
            tl: {
              hd: Css.overflow("auto"),
              tl: {
                hd: Css.height(Css.pct(100.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.paddingTop(Css.px(12)),
              tl: {
                hd: Css.paddingBottom(Css.px(12)),
                tl: {
                  hd: Css.paddingRight(Css.px(24)),
                  tl: {
                    hd: Css.marginRight(Css.px(16)),
                    tl: {
                      hd: Css.height(Css.px(65)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var itegrationsBoxStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexGrow(1.0),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("stretch"),
            tl: {
              hd: Css.overflow("hidden"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var aboutBoxStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("center"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.flexGrow(1.0),
            tl: /* [] */0
          }
        }
      }
    });

var listStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignSelf("stretch"),
          tl: {
            hd: Css.width(Css.pct(100.0)),
            tl: {
              hd: Css.maxWidth(Css.px(380)),
              tl: {
                hd: Css.overflowY("auto"),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: {
                    hd: Css.borderTopLeftRadius(Css.px(10)),
                    tl: {
                      hd: Css.borderTopRightRadius(Css.px(10)),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                        tl: {
                          hd: Css.borderBottomWidth(Css.px(0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function Integrations$EmptyState(Props) {
  var handleCreateClick = Props.handleCreateClick;
  var schemaBundle = SchemaBundleContext.use();
  React.useEffect((function (param) {
          AnalyticsRe.emptyStateDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Publishing", schemaBundle.schemaId);
        }), []);
  return React.createElement(EmptyState.Container.make, {
              artwork: "publishing",
              children: null
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(380)),
                        tl: /* [] */0
                      })
                }, React.createElement(Title.make, {
                      children: "Keep schema registries in sync across your stack with publishing",
                      size: "Small"
                    })), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  children: "Enable our integrations to pipe your tracking plan into your external workflows and apps and unlock powerful data governance super powers for your team."
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement(Button.make, {
                  label: "Set up Integration",
                  onClick: (function (e) {
                      AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Publishing", "Setup", undefined);
                      Curry._1(handleCreateClick, e);
                    })
                }), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement($$Text.make, {
                  size: "Small",
                  children: "Discover what value you get from our integrations: "
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexWrap("wrap"),
                          tl: {
                            hd: Css.gap(Css.px(8)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(EmptyState.LinkButtonWithIcon.make, {
                      url: "https://www.avo.app/docs/publishing/segment-protocols",
                      icon: React.createElement(IntegrationIcon.ByType.make, {
                            integration: "Protocols",
                            size: 16
                          }),
                      label: "Segment",
                      emptyStateLocation: "Publishing"
                    }), React.createElement(EmptyState.LinkButtonWithIcon.make, {
                      url: "https://www.avo.app/docs/workspace/tracking-plan/publishing#a-nameamplitude-governa-amplitude-govern",
                      icon: React.createElement(IntegrationIcon.ByType.make, {
                            integration: "Taxonomy",
                            size: 16
                          }),
                      label: "Amplitude",
                      emptyStateLocation: "Publishing"
                    }), React.createElement(EmptyState.LinkButtonWithIcon.make, {
                      url: "https://www.avo.app/docs/publishing/mixpanel-lexicon",
                      icon: React.createElement(IntegrationIcon.ByType.make, {
                            integration: "Lexicon",
                            size: 16
                          }),
                      label: "Mixpanel",
                      emptyStateLocation: "Publishing"
                    }), React.createElement(EmptyState.LinkButtonWithIcon.make, {
                      url: "https://www.avo.app/docs/publishing/rudderstack",
                      icon: React.createElement(IntegrationIcon.ByType.make, {
                            integration: "RudderstackDataCatalog",
                            size: 16
                          }),
                      label: "Rudderstack",
                      emptyStateLocation: "Publishing"
                    }), React.createElement(EmptyState.LinkButtonWithIcon.make, {
                      url: "https://www.avo.app/docs/publishing/mparticle-data-master",
                      icon: React.createElement(IntegrationIcon.ByType.make, {
                            integration: "MParticleDataMaster",
                            size: 16
                          }),
                      label: "mParticle",
                      emptyStateLocation: "Publishing"
                    }), React.createElement(EmptyState.LinkButtonWithIcon.make, {
                      url: "https://www.avo.app/docs/publishing/webhook-publishing",
                      icon: React.createElement(IntegrationIcon.ByType.make, {
                            integration: "Webhook",
                            size: 16
                          }),
                      label: "Webhook",
                      emptyStateLocation: "Publishing"
                    })), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Small",
                  children: null
                }, "Head to ", React.createElement("a", {
                      className: Curry._1(Css.merge, {
                            hd: EmptyStatesStyles.linkStyles,
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.textDecoration("none"),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      href: "https://www.avo.app/docs/workspace/tracking-plan/publishing",
                      rel: "noopener",
                      target: "_blank",
                      onClick: (function (param) {
                          AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Publishing", "OpenDocs", "https://www.avo.app/docs/workspace/tracking-plan/publishing,");
                        })
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "our docs"
                        })), " for more details on how to set up and make the most of our publishing integrations."));
}

var EmptyState$1 = {
  make: Integrations$EmptyState
};

function Integrations(Props) {
  var integrations = Props.integrations;
  var model = Props.model;
  var role = Props.role;
  var drawerItems = RouterStore.Schema.useDrawerItems();
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var workspaceLimits = AvoLimits.computeLimits(workspace, model);
  var schemaBundle = SchemaBundleContext.use();
  var globalSend = GlobalSendContext.use();
  var createAvailability = AvoLimits.Integration.createActionStatus(workspaceLimits);
  var maybeOpenIntegration = Belt_List.head(Belt_List.keepMap(drawerItems, (function (item) {
              if (typeof item !== "object") {
                return ;
              }
              if (item.NAME !== "integration") {
                return ;
              }
              var match = item.VAL;
              return [
                      match[0],
                      match[1]
                    ];
            })));
  var handleCreateClick = function (param) {
    if (createAvailability === "Unavailable") {
      return Curry._1(globalSend, {
                  TAG: "OpenModal",
                  _0: {
                    NAME: "BillingPrompt",
                    VAL: "AddIntegration"
                  }
                });
    } else {
      AnalyticsRe.integrationInitiated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_List.length(integrations), schemaBundle.branchId, schemaBundle.schemaId);
      return Curry._1(globalSend, {
                  TAG: "OpenModal",
                  _0: "CreateIntegration"
                });
    }
  };
  var tmp;
  if (integrations) {
    var match = Workspace.Integration.getUsedSlots(workspace, model);
    var match$1 = workspace.trial;
    var match$2 = match[1];
    var usedCount = match[0];
    var tmp$1;
    if (typeof match$2 === "object" && match$2.NAME === "Limited") {
      var availableCount = match$2.VAL;
      var exit = 0;
      if (match$1 !== undefined) {
        if (usedCount > availableCount) {
          tmp$1 = React.createElement(ListLimitsInfo.make, {
                title: "You are using " + String(usedCount) + " integrations with a limit of " + String(availableCount) + " on the " + Workspace.printPlanName(workspace.plan.name) + " plan.",
                description: "To continue using all your integrations after the trial expires, you'll need to upgrade your plan.",
                globalSend: globalSend,
                paywallName: "AddIntegration"
              });
        } else {
          exit = 1;
        }
      } else if (usedCount > availableCount) {
        tmp$1 = React.createElement(ListLimitsInfo.make, {
              title: "You are using " + String(usedCount) + " integrations with a limit of " + String(availableCount) + " on the " + Workspace.printPlanName(workspace.plan.name) + " plan.",
              description: "A plan upgrade is needed to keep using all your integrations.",
              globalSend: globalSend,
              paywallName: "AddIntegration"
            });
      } else if (usedCount === availableCount) {
        tmp$1 = React.createElement(ListLimitsInfo.make, {
              title: "You are using " + String(usedCount) + " out of " + String(availableCount) + " integrations on the " + Workspace.printPlanName(workspace.plan.name) + " plan.",
              description: "To add more integrations, you'll need to upgrade your plan.",
              globalSend: globalSend,
              paywallName: "AddIntegration"
            });
      } else {
        exit = 1;
      }
      if (exit === 1) {
        tmp$1 = usedCount > 0 ? React.createElement(ListLimitsInfo.make, {
                title: "You are using " + String(usedCount) + " out of " + String(availableCount) + " integrations on the " + Workspace.printPlanName(workspace.plan.name) + " plan.",
                globalSend: globalSend,
                paywallName: "AddIntegration"
              }) : null;
      }
      
    } else {
      tmp$1 = null;
    }
    tmp = React.createElement("div", {
          className: itegrationsBoxStyles
        }, React.createElement("div", {
              className: listStyles
            }, Belt_List.toArray(Belt_List.mapU(integrations, (function (integration) {
                        return React.createElement(Integrations$ListItem, {
                                    integration: integration,
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "integration",
                                          VAL: [
                                            integration.id,
                                            undefined
                                          ]
                                        }),
                                    isActive: maybeOpenIntegration !== undefined ? maybeOpenIntegration[0] === integration.id : false,
                                    workspaceLimits: workspaceLimits,
                                    key: integration.id
                                  });
                      }))), tmp$1), React.createElement("div", {
              className: aboutBoxStyles
            }, React.createElement(PublishingEmpty.make, {
                  role: role
                })));
  } else {
    tmp = React.createElement(Integrations$EmptyState, {
          handleCreateClick: handleCreateClick
        });
  }
  return React.createElement("main", {
              className: rootStyles$1
            }, React.createElement("header", {
                  className: headerStyles
                }, React.createElement(Title.make, {
                      children: "Publishing",
                      size: "Tiny"
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.px(12)),
                            tl: /* [] */0
                          })
                    }), React.createElement(Button.make, {
                      icon: createAvailability === "Available" ? "plus" : "lightning",
                      label: "Add Integration",
                      onClick: (function (e) {
                          if (integrations) {
                            
                          } else {
                            AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Publishing", "Setup", undefined);
                          }
                          handleCreateClick(e);
                        }),
                      style: "outline"
                    })), tmp);
}

var make = Integrations;

export {
  ListItem ,
  rootStyles$1 as rootStyles,
  headerStyles ,
  itegrationsBoxStyles ,
  aboutBoxStyles ,
  listStyles ,
  EmptyState$1 as EmptyState,
  make ,
}
/* infoStyles Not a pure module */
