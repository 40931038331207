// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as DrawerHeader from "./detailviews/DrawerHeader.mjs";

function DrawerContainer__ItemNotFound(Props) {
  var kind = Props.kind;
  return React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
                  kind: kind,
                  children: "Not Found"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.pct(100.0)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, "Oh noes, this item wasn’t found"));
}

var make = DrawerContainer__ItemNotFound;

export {
  make ,
}
/* Css Not a pure module */
