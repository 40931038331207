// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as ArrayExt from "../../../../../app/src/ArrayExt.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_MutableMap from "rescript/lib/es6/belt_MutableMap.js";
import * as ModelUtils_mapped from "../../../../../app/src/ModelUtils_mapped.mjs";
import * as InspectorPropertyType from "../../../../../shared/models/InspectorPropertyType.mjs";
import * as TrackingPlanMappedModel from "../../../../../model/src/TrackingPlanMappedModel.mjs";
import * as InspectorTrackingPlanUtils from "../../../../../shared/InspectorTrackingPlanUtils.mjs";
import * as InspectorEventPropertiesFlattenedUseCase from "../models/InspectorEventPropertiesFlattenedUseCase.mjs";
import * as InspectorIssuesGeneratorTrackingPlanUtils from "./InspectorIssuesGeneratorTrackingPlanUtils.mjs";
import * as EventPropertiesFlattenedWithObjectsUseCase from "../../../../../shared/models/EventPropertiesFlattenedWithObjectsUseCase.mjs";
import * as GetAllEventUserAndGroupPropertyIdsForEventUseCase from "../../../../../model/src/base/GetAllEventUserAndGroupPropertyIdsForEventUseCase.mjs";

function getTrackingPlanEvent_mappedModel(events, rules, eventName) {
  return Curry._2(TrackingPlanMappedModel.Events.getBy, events, (function ($$event) {
                return Caml_obj.equal(Belt_List.head(Belt_List.keepMapU(ModelUtils.getEventPossibleNamesFromRules($$event, Belt_List.fromArray(rules)), (function (name) {
                                      if (name === eventName) {
                                        return $$event;
                                      }
                                      
                                    }))), $$event);
              }));
}

function checkUnexpectedEventIssue(sourceId, modelEvent, model) {
  var isIncluded = ModelUtils.isEventIncludedInSource(undefined, model, sourceId, modelEvent.id);
  if (isIncluded) {
    return ;
  } else {
    return "UnexpectedEvent";
  }
}

function checkUnexpectedEventIssue_mappedModel(sourceId, modelEvent, events) {
  var isIncluded = ModelUtils_mapped.isEventIncludedInSource(undefined, events, sourceId, modelEvent.id);
  if (isIncluded) {
    return ;
  } else {
    return "UnexpectedEvent";
  }
}

function getLikeliestPropertyForEvent(model, propertyName, modelEvent, propertyType) {
  var maybeProperties = ModelUtils.getAllPropertiesByNameOrNameMapping(Caml_option.some(modelEvent.id), model, propertyName);
  var propertiesOnEvent = Belt_SetString.fromArray(GetAllEventUserAndGroupPropertyIdsForEventUseCase.get(model, modelEvent));
  var maybeEventProperty = Belt_List.getByU(maybeProperties, (function (property) {
          return Belt_SetString.has(propertiesOnEvent, property.id);
        }));
  if (maybeEventProperty !== undefined) {
    return maybeEventProperty;
  }
  var property = Belt_List.getByU(maybeProperties, (function (property) {
          return Belt_Option.getWithDefault(Belt_Option.map(propertyType, (function (propertyType) {
                            if (property.type_ === Curry._1(InspectorPropertyType.V2.PropertyType.rawTypeToString, propertyType.rawType)) {
                              return property.list === propertyType.isList;
                            } else {
                              return false;
                            }
                          })), false);
        }));
  if (property !== undefined) {
    return property;
  } else {
    return Belt_List.head(maybeProperties);
  }
}

function getLikeliestPropertyForEvent__mappedModel(model, propertyName, modelEvent, propertyType) {
  var maybeProperties = ModelUtils_mapped.getAllPropertiesByNameOrNameMapping(model.rules, model.destinations, model.properties, propertyName, modelEvent.id);
  var propertiesOnEvent = Belt_SetString.fromArray(ModelUtils_mapped.getEventPropertyIds(model.events, model.propertyBundles, modelEvent.id));
  var maybeEventProperty = Curry._2(TrackingPlanMappedModel.Properties.getBy, maybeProperties, (function (property) {
          return Belt_SetString.has(propertiesOnEvent, property.id);
        }));
  if (maybeEventProperty !== undefined) {
    return maybeEventProperty;
  }
  var property = Curry._2(TrackingPlanMappedModel.Properties.getBy, maybeProperties, (function (property) {
          return Belt_Option.getWithDefault(Belt_Option.map(propertyType, (function (propertyType) {
                            if (property.type_ === Curry._1(InspectorPropertyType.V2.PropertyType.rawTypeToString, propertyType.rawType)) {
                              return property.list === propertyType.isList;
                            } else {
                              return false;
                            }
                          })), false);
        }));
  if (property !== undefined) {
    return property;
  } else {
    return Curry._2(TrackingPlanMappedModel.Properties.getAtIndex, maybeProperties, 0);
  }
}

function checkUnexpectedPropertyV2(property, modelEvent, model, propertyNames, propertyType, actualPropertyNamesString, trackingPlanEventPropertiesFlattenedMap) {
  if (property !== undefined) {
    return ;
  }
  var lastName = Belt_Array.getExn(propertyNames, propertyNames.length - 1 | 0);
  var maybeProperty = getLikeliestPropertyForEvent(model, lastName, modelEvent, propertyType);
  var enrichedPropertyType = maybeProperty !== undefined ? Curry._2(InspectorPropertyType.V2.PropertyType.toStringEnrichedWithTrackingPlan, propertyType, maybeProperty.type_) : Curry._2(InspectorPropertyType.V2.PropertyType.toStringEnrichedWithTrackingPlan, propertyType, undefined);
  var parentPropertyNamesKey = Belt_Array.keepWithIndexU(propertyNames, (function (param, i) {
          return i < (propertyNames.length - 1 | 0);
        }));
  var parentFound = Caml_obj.equal(parentPropertyNamesKey, []) || Belt_MutableMap.has(trackingPlanEventPropertiesFlattenedMap, parentPropertyNamesKey);
  if (parentFound) {
    return {
            TAG: "UnexpectedProperty",
            _0: {
              eventId: modelEvent.id,
              propertyName: actualPropertyNamesString,
              propertyType: enrichedPropertyType
            }
          };
  }
  
}

function checkMissingExpectedPropertyV2(eventVariationPropertiesMap, modelEvent, trackingPlanEventPropertiesMap, sourceId, model) {
  var allRequiredProperties = Belt_Array.reduce(Belt_MutableMap.toArray(trackingPlanEventPropertiesMap), undefined, (function (acc, param) {
          var property = param[1];
          var propertyNames = param[0];
          var number = propertyNames.length;
          if (number !== 1) {
            if (number <= 1) {
              return acc;
            }
            if (property.optionalWhenInObject) {
              return acc;
            }
            var existing = Belt_Option.getWithDefault(Belt_MapString.get(acc, property.id), []);
            var updated = Belt_Array.concat(existing, [propertyNames]);
            return Belt_MapString.set(acc, property.id, updated);
          }
          if (ModelUtils.isPropertyOptionalOnEventAndSource(property, modelEvent.id, sourceId, model)) {
            return acc;
          }
          var existing$1 = Belt_Option.getWithDefault(Belt_MapString.get(acc, property.id), []);
          var updated$1 = Belt_Array.concat(existing$1, [propertyNames]);
          return Belt_MapString.set(acc, property.id, updated$1);
        }));
  return Belt_Array.keepMap(Belt_MapString.toArray(allRequiredProperties), (function (param) {
                var propertyNames = param[1];
                var propertyFound = Belt_Array.some(propertyNames, (function (propertyNamesKey) {
                        return Belt_MutableMap.has(eventVariationPropertiesMap, propertyNamesKey);
                      }));
                if (propertyFound) {
                  return ;
                }
                var parentFound = Belt_Array.some(propertyNames, (function (propertyNamesKey) {
                        var parentPropertyNamesKey = Belt_Array.keepWithIndexU(propertyNamesKey, (function (param, i) {
                                return i < (propertyNamesKey.length - 1 | 0);
                              }));
                        if (Caml_obj.equal(parentPropertyNamesKey, [])) {
                          return true;
                        } else {
                          return Belt_MutableMap.has(eventVariationPropertiesMap, parentPropertyNamesKey);
                        }
                      }));
                if (!parentFound) {
                  return ;
                }
                var keys = Belt_Array.getExn(propertyNames, 0);
                var actualPropertyNames = EventPropertiesFlattenedWithObjectsUseCase.getPropertiesActualNames(keys, trackingPlanEventPropertiesMap);
                return {
                        TAG: "MissingExpectedProperty",
                        _0: {
                          eventId: modelEvent.id,
                          propertyId: param[0],
                          propertyName: Belt_Array.joinWith(actualPropertyNames, ".", (function (propertyName) {
                                  return propertyName;
                                }))
                        }
                      };
              }));
}

function checkPropertyTypeInconsistentWithTrackingPlan(property, inspectorPropertyType, eventId, sourceId, model, actualPropertyNameString) {
  if (property === undefined) {
    return ;
  }
  if (inspectorPropertyType === undefined) {
    return ;
  }
  var rawType = Curry._1(InspectorPropertyType.V2.PropertyType.rawTypeToString, inspectorPropertyType.rawType);
  var typesAreEqualRegardingLists = true;
  if (property.type_ !== "any") {
    var match = property.list;
    var match$1 = inspectorPropertyType.isList;
    typesAreEqualRegardingLists = match ? (
        match$1 ? true : false
      ) : (
        match$1 ? false : true
      );
  }
  if (InspectorTrackingPlanUtils.receivedPropertyTypeMatchesTrackingPlan(property, rawType, ModelUtils.isPropertyOptionalOnEventsAndSources(Belt_List.keepMap({
                  hd: eventId,
                  tl: /* [] */0
                }, (function (i) {
                    return i;
                  })), {
              hd: sourceId,
              tl: /* [] */0
            }, model, property)) && typesAreEqualRegardingLists || inspectorPropertyType.rawType === "Unknown") {
    return ;
  } else {
    return {
            TAG: "PropertyTypeInconsistentWithTrackingPlan",
            _0: {
              eventId: eventId,
              propertyId: property.id,
              propertyName: Belt_Option.getWithDefault(actualPropertyNameString, property.name),
              expectedPropertyType: property.list ? InspectorPropertyType.addListToRawType(property.type_) : property.type_,
              actualPropertyType: Curry._2(InspectorPropertyType.V2.PropertyType.toStringEnrichedWithTrackingPlan, inspectorPropertyType, property.type_)
            }
          };
  }
}

function checkPropertyIssues(inspectorObjectsEnabled, propertyNameSignature, propertyTypeSignature, sourceId, model, modelEvent) {
  var properties = Belt_Array.zip(propertyNameSignature, propertyTypeSignature);
  var trackingPlanEventPropertiesFlattenedMap = EventPropertiesFlattenedWithObjectsUseCase.get(inspectorObjectsEnabled, modelEvent, model, sourceId);
  var inspectorPropertiesFlattenedMap = InspectorEventPropertiesFlattenedUseCase.get(inspectorObjectsEnabled, properties);
  var missingExpectedPropertyIssues = checkMissingExpectedPropertyV2(inspectorPropertiesFlattenedMap, modelEvent, trackingPlanEventPropertiesFlattenedMap, sourceId, model);
  return Belt_Array.concat(Belt_Array.concatMany(Belt_Array.mapU(Belt_MutableMap.toArray(inspectorPropertiesFlattenedMap), (function (param) {
                        var propertyType = param[1];
                        var propertyNames = param[0];
                        var property = Belt_MutableMap.get(trackingPlanEventPropertiesFlattenedMap, propertyNames);
                        var actualPropertyNamesString = EventPropertiesFlattenedWithObjectsUseCase.actualPropertyNamesAsString(EventPropertiesFlattenedWithObjectsUseCase.getPropertiesActualNames(propertyNames, trackingPlanEventPropertiesFlattenedMap));
                        return Belt_Array.keepMap([
                                    checkPropertyTypeInconsistentWithTrackingPlan(property, propertyType, modelEvent.id, sourceId, model, actualPropertyNamesString),
                                    checkUnexpectedPropertyV2(property, modelEvent, model, propertyNames, propertyType, actualPropertyNamesString, trackingPlanEventPropertiesFlattenedMap)
                                  ], (function (i) {
                                      return i;
                                    }));
                      }))), missingExpectedPropertyIssues);
}

function comparePropertiesVariations(sourceId, propertyNameSignature, propertyTypeSignature, otherPropertyNameSignature, otherPropertyTypeSignature, model) {
  var otherEventVariationProperties = Belt_Array.zip(otherPropertyNameSignature, otherPropertyTypeSignature);
  var eventVariationProperties = Belt_Array.zip(propertyNameSignature, propertyTypeSignature);
  return Belt_Array.keepMapU(eventVariationProperties, (function (param) {
                var type_ = param[1];
                var name = param[0];
                var property = ModelUtils.getPropertyByNameOrNameMapping(name, model, undefined);
                var propertyType = InspectorPropertyType.V2.consolidatePropertyType(type_);
                if (property !== undefined) {
                  return checkPropertyTypeInconsistentWithTrackingPlan(property, propertyType, undefined, sourceId, model, undefined);
                }
                var otherProperty = Belt_Array.getByU(otherEventVariationProperties, (function (param) {
                        return name === param[0];
                      }));
                if (otherProperty === undefined) {
                  return ;
                }
                var otherType = otherProperty[1];
                if (InspectorPropertyType.V2.comparePropertyTypes(type_, otherType)) {
                  return ;
                }
                var type_$1 = Belt_Option.getExn(InspectorPropertyType.V2.consolidatePropertyType(type_));
                var otherType$1 = Belt_Option.getExn(InspectorPropertyType.V2.consolidatePropertyType(otherType));
                var typeStr = Curry._1(InspectorPropertyType.V2.PropertyType.toString, type_$1);
                var otherTypeStr = Curry._1(InspectorPropertyType.V2.PropertyType.toString, otherType$1);
                var types = typeStr.localeCompare(otherTypeStr) <= 0.0 ? [
                    typeStr,
                    otherTypeStr
                  ] : [
                    otherTypeStr,
                    typeStr
                  ];
                return {
                        TAG: "InconsistentType",
                        _0: {
                          propertyName: name,
                          propertyTypes: [
                            types[0],
                            types[1]
                          ]
                        }
                      };
              }));
}

function compare(a, b) {
  var makeKey = function (issue) {
    return Belt_Option.getWithDefault(issue.eventId, "") + issue.propertyId + issue.propertyName + issue.expectedPropertyType + issue.actualPropertyType;
  };
  return Caml.string_compare(makeKey(a), makeKey(b));
}

var DedupePropertyTypeInconsistentWithTrackingPlanIssue = ArrayExt.Dedupe({
      compare: compare
    });

function mergeInconsistentPropertyTypeIssues(allPropertyIssues) {
  var inconsistentTypeIssues = Belt_Array.keepMap(allPropertyIssues, (function (issue) {
          if (typeof issue !== "object" || issue.TAG !== "InconsistentType") {
            return ;
          } else {
            return issue._0;
          }
        }));
  var inconsistentWithTrackingPlanIssues = Belt_Array.keepMap(allPropertyIssues, (function (issue) {
          if (typeof issue !== "object" || issue.TAG !== "PropertyTypeInconsistentWithTrackingPlan") {
            return ;
          } else {
            return issue._0;
          }
        }));
  var nonInconsistentTypeIssues = Belt_Array.keepMap(allPropertyIssues, (function (issue) {
          if (typeof issue !== "object") {
            return issue;
          }
          switch (issue.TAG) {
            case "PropertyTypeInconsistentWithTrackingPlan" :
            case "InconsistentType" :
                return ;
            default:
              return issue;
          }
        }));
  var mergedInconsistentTypeIssues = Belt_Array.map(Js_dict.values(Belt_Array.reduceU(inconsistentTypeIssues, {}, (function (propertyIssues, issue) {
                  var existingIssue = Js_dict.get(propertyIssues, issue.propertyName);
                  if (existingIssue !== undefined) {
                    var propertyTypes = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concat(existingIssue.propertyTypes, issue.propertyTypes)));
                    var newIssue_propertyName = issue.propertyName;
                    var newIssue = {
                      propertyName: newIssue_propertyName,
                      propertyTypes: propertyTypes
                    };
                    propertyIssues[issue.propertyName] = newIssue;
                    return propertyIssues;
                  }
                  propertyIssues[issue.propertyName] = issue;
                  return propertyIssues;
                }))), (function (issue) {
          return {
                  TAG: "InconsistentType",
                  _0: issue
                };
        }));
  var mergedInconsistentWithTrackingPlanIssues = Belt_Array.map(Curry._1(DedupePropertyTypeInconsistentWithTrackingPlanIssue.dedupe, inconsistentWithTrackingPlanIssues), (function (issue) {
          return {
                  TAG: "PropertyTypeInconsistentWithTrackingPlan",
                  _0: issue
                };
        }));
  return Belt_Array.concatMany([
              nonInconsistentTypeIssues,
              mergedInconsistentTypeIssues,
              mergedInconsistentWithTrackingPlanIssues
            ]);
}

function compare$1(a, b) {
  var makeKey = function (variation) {
    return variation.eventKey + variation.sourceKey + variation.eventName + variation.appVersion + variation.sourceId + variation.propertyNameSignature.join(",") + variation.propertyTypeSignature.join(",") + variation.schemaId;
  };
  return Caml.string_compare(makeKey(a), makeKey(b));
}

var DedupeVariation = ArrayExt.Dedupe({
      compare: compare$1
    });

function validateVariationsAgainstOtherVariations(eventVariation, allEventVariationsForEvent, model) {
  var propertyTypeSet = Belt_SetString.fromArray(Belt_Array.map(eventVariation.propertyTypeSignature, (function (propertyType) {
              return Curry._1(InspectorPropertyType.V2.PropertyType.toSimpleTypeString, InspectorPropertyType.V2.consolidatePropertyType(propertyType));
            })));
  var propertyNameSet = Belt_SetString.fromArray(eventVariation.propertyNameSignature);
  var allEventVariationsForEvent$1 = Belt_Array.keep(allEventVariationsForEvent, (function (otherVariation) {
          var otherPropertyTypeSet = Belt_SetString.fromArray(Belt_Array.map(otherVariation.propertyTypeSignature, (function (propertyType) {
                      return Curry._1(InspectorPropertyType.V2.PropertyType.toSimpleTypeString, InspectorPropertyType.V2.consolidatePropertyType(propertyType));
                    })));
          var otherPropertyNameSet = Belt_SetString.fromArray(otherVariation.propertyNameSignature);
          return !(Belt_SetString.eq(propertyNameSet, otherPropertyNameSet) && Belt_SetString.eq(propertyTypeSet, otherPropertyTypeSet));
        }));
  var allPropertyIssues = Belt_Array.concatMany(Belt_Array.map(Curry._1(DedupeVariation.dedupe, allEventVariationsForEvent$1), (function (otherVariation) {
              return comparePropertiesVariations(eventVariation.sourceId, eventVariation.propertyNameSignature, eventVariation.propertyTypeSignature, otherVariation.propertyNameSignature, otherVariation.propertyTypeSignature, model);
            })));
  var propertyIssues = mergeInconsistentPropertyTypeIssues(allPropertyIssues);
  return Belt_Array.concat(["EventNotInTrackingPlan"], propertyIssues);
}

function validateVariationAgainstTrackingPlanEvent(inspectorObjectsEnabled, eventVariation, model, $$event) {
  var issue = checkUnexpectedEventIssue(eventVariation.sourceId, $$event, model);
  if (issue !== undefined) {
    return [issue];
  } else {
    return checkPropertyIssues(inspectorObjectsEnabled, eventVariation.propertyNameSignature, eventVariation.propertyTypeSignature, eventVariation.sourceId, model, $$event);
  }
}

function walkValidateTrackingPlanEvents(eventVariation, model, _trackingPlanEvents, defaultTrackingPlanEvent, inspectorObjectsEnabled) {
  while(true) {
    var trackingPlanEvents = _trackingPlanEvents;
    var $$event = Belt_Option.getWithDefault(Belt_Array.get(trackingPlanEvents, 0), defaultTrackingPlanEvent);
    var trackingPlanEvents$1 = Belt_Array.sliceToEnd(trackingPlanEvents, 1);
    var issues = validateVariationAgainstTrackingPlanEvent(inspectorObjectsEnabled, eventVariation, model, $$event);
    if (issues.length === 0) {
      return [
              [],
              InspectorIssuesGeneratorTrackingPlanUtils.getValidateInConfigIfIgnored(eventVariation, $$event)
            ];
    }
    if (trackingPlanEvents$1.length === 0) {
      return [
              validateVariationAgainstTrackingPlanEvent(inspectorObjectsEnabled, eventVariation, model, defaultTrackingPlanEvent),
              InspectorIssuesGeneratorTrackingPlanUtils.getValidateInConfigIfIgnored(eventVariation, defaultTrackingPlanEvent)
            ];
    }
    _trackingPlanEvents = trackingPlanEvents$1;
    continue ;
  };
}

function validateVariationAgainstTrackingPlanEvents(inspectorObjectsEnabledOpt, eventNamesToTrackingPlanEventsMap, eventVariation, model) {
  var inspectorObjectsEnabled = inspectorObjectsEnabledOpt !== undefined ? inspectorObjectsEnabledOpt : false;
  var trackingPlanEvents = Belt_Option.getWithDefault(Belt_MapString.get(eventNamesToTrackingPlanEventsMap, eventVariation.eventName), []);
  var maybeDefaultTrackingPlanEvent = InspectorIssuesGeneratorTrackingPlanUtils.getDefaultTrackingPlanEvent(trackingPlanEvents, eventVariation.eventName);
  if (maybeDefaultTrackingPlanEvent !== undefined) {
    return walkValidateTrackingPlanEvents(eventVariation, model, trackingPlanEvents, maybeDefaultTrackingPlanEvent, inspectorObjectsEnabled);
  }
  
}

export {
  getTrackingPlanEvent_mappedModel ,
  checkUnexpectedEventIssue ,
  checkUnexpectedEventIssue_mappedModel ,
  getLikeliestPropertyForEvent ,
  getLikeliestPropertyForEvent__mappedModel ,
  checkUnexpectedPropertyV2 ,
  checkMissingExpectedPropertyV2 ,
  checkPropertyTypeInconsistentWithTrackingPlan ,
  checkPropertyIssues ,
  comparePropertiesVariations ,
  DedupePropertyTypeInconsistentWithTrackingPlanIssue ,
  mergeInconsistentPropertyTypeIssues ,
  DedupeVariation ,
  validateVariationsAgainstOtherVariations ,
  validateVariationAgainstTrackingPlanEvents ,
}
/* DedupePropertyTypeInconsistentWithTrackingPlanIssue Not a pure module */
