// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Nanoid from "nanoid";
import * as AvoModel from "./avoModel.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameInput from "./NameInput.mjs";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as NameInputUtils from "./NameInputUtils.mjs";
import * as StateFilterUtils from "./StateFilterUtils.mjs";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function isValid(name, expectedPropertyCase, model) {
  return NameInputUtils.isValid(NameInputUtils.validateInput(ModelUtils_mapped.getPropertyNamespace(model), name, name, expectedPropertyCase, true));
}

function getCreatedToast(id, name) {
  return {
          message: "Property \"" + name + "\" created 🎉",
          toastType: "Success",
          action: {
            message: "View",
            toastCallback: (function (param) {
                Router.Schema.pushDrawerItem(undefined, undefined, {
                      NAME: "property",
                      VAL: [
                        id,
                        undefined
                      ]
                    });
              })
          }
        };
}

function trackPropertyCreated(eventId, name, schemaBundle, sendAs, filters, sourceFilterNames, namingConvention, propertyLocation, correctCase, propertyId) {
  var arg = propertyId;
  var arg$1 = Case.toAnalytics(namingConvention);
  var arg$2 = AnalyticsUtils.sendAsToPropertyType(sendAs);
  var arg$3 = correctCase;
  var arg$4 = Belt_List.toArray(AvoModel.getTagFilters(filters));
  var arg$5 = AnalyticsUtils.propertyLocationToOrigin(propertyLocation);
  var arg$6 = schemaBundle.branchId;
  var arg$7 = schemaBundle.schemaId;
  AnalyticsUtils.withSchemaBundle((function (param) {
          var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22) {
            return AnalyticsRe.propertyCreated(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22);
          };
          return function (param) {
            var func$1 = Curry._1(func, param);
            return function (param) {
              var func$2 = Curry._1(func$1, param);
              return function (param) {
                var func$3 = Curry._1(func$2, param);
                return function (param) {
                  var func$4 = Curry._1(func$3, param);
                  return function (param) {
                    var func$5 = Curry._1(func$4, param);
                    return function (param) {
                      var func$6 = Curry._1(func$5, param);
                      return function (param) {
                        var func$7 = Curry._1(func$6, param);
                        return function (param) {
                          var func$8 = Curry._1(func$7, param);
                          return function (param) {
                            var func$9 = Curry._1(func$8, param);
                            return function (param) {
                              return Curry.app(func$9, [
                                          param,
                                          arg,
                                          name,
                                          arg$1,
                                          arg$2,
                                          arg$3,
                                          arg$4,
                                          sourceFilterNames,
                                          eventId,
                                          propertyLocation,
                                          arg$5,
                                          arg$6,
                                          arg$7
                                        ]);
                            };
                          };
                        };
                      };
                    };
                  };
                };
              };
            };
          };
        }), schemaBundle);
}

function createProperty(propertyId, parent, sendActions, sendAs, name, namingConvention, correctCase, addToast, schemaBundle, filters, sourceFilterNames, propertyLocation, model, onSuccessOpt, extraCreateActionsOpt, currentFilters, param) {
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param, param$1, param$2) {
        
      });
  var extraCreateActions = extraCreateActionsOpt !== undefined ? extraCreateActionsOpt : (function (param) {
        return [];
      });
  if (parent !== undefined) {
    var variant = parent.NAME;
    if (variant === "Event") {
      var eventId = parent.VAL;
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            (function (branch) {
                Curry._1(addToast, getCreatedToast(propertyId, name));
                Curry._3(onSuccess, propertyId, name, branch);
                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                trackPropertyCreated(eventId, name, schemaBundle$1, sendAs, filters, sourceFilterNames, namingConvention, propertyLocation, correctCase, propertyId);
                AnalyticsRe.propertyAdded(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, parent !== undefined && typeof parent === "object" && parent.NAME === "Event" ? parent.VAL : "N/A", parent !== undefined && typeof parent === "object" && parent.NAME === "Event" ? Belt_Option.mapWithDefault(ModelUtils.getEventById(parent.VAL, model), "N/A", (function ($$event) {
                              return $$event.name;
                            })) : "N/A", propertyId, name, Case.toAnalytics(Case.getCasingWithWorkspaceCase(name, namingConvention)), AnalyticsUtils.sendAsToPropertyType(sendAs), correctCase, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, name.length, "New", Case.to_("Sentence", name).split(" ").length, name.length, propertyLocation, schemaBundle$1.branchId, schemaBundle$1.schemaId);
              }),
            undefined,
            Belt_Array.concat([[
                    {
                      NAME: "AddProperty",
                      VAL: [
                        eventId,
                        propertyId,
                        name,
                        sendAs
                      ]
                    },
                    {
                      eventId: eventId,
                      eventQuery: eventId,
                      propertyId: propertyId,
                      propertyQuery: propertyId
                    }
                  ]], Curry._1(extraCreateActions, propertyId))
          ]);
      return ;
    }
    if (variant === "PropertyGroup") {
      var groupId = parent.VAL;
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                      Curry._1(addToast, getCreatedToast(propertyId, name));
                      Curry._3(onSuccess, propertyId, name, branch);
                      trackPropertyCreated("N/A", name, schemaBundle$1, sendAs, filters, sourceFilterNames, namingConvention, propertyLocation, correctCase, propertyId);
                    }),
                  undefined,
                  Belt_Array.concat([
                        [
                          {
                            NAME: "CreateProperty",
                            VAL: [
                              propertyId,
                              name,
                              sendAs
                            ]
                          },
                          {
                            propertyId: propertyId,
                            propertyQuery: propertyId
                          }
                        ],
                        [
                          {
                            NAME: "AddPropertyToGroup",
                            VAL: [
                              groupId,
                              propertyId
                            ]
                          },
                          {
                            propertyId: propertyId,
                            propertyQuery: propertyId,
                            propertyGroupId: groupId,
                            propertyGroupQuery: groupId
                          }
                        ]
                      ], Curry._1(extraCreateActions, propertyId))
                ]);
    }
    if (variant === "EventVariant") {
      var variant$1 = parent.VAL;
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            (function (branch) {
                Curry._1(addToast, getCreatedToast(propertyId, name));
                Curry._3(onSuccess, propertyId, name, branch);
                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                trackPropertyCreated(variant$1.baseEventId, name, schemaBundle$1, sendAs, filters, sourceFilterNames, namingConvention, propertyLocation, correctCase, propertyId);
                var arg;
                if (parent !== undefined && typeof parent === "object") {
                  var variant$2 = parent.NAME;
                  arg = variant$2 === "Event" ? parent.VAL : (
                      variant$2 === "EventVariant" ? parent.VAL.baseEventId : "N/A"
                    );
                } else {
                  arg = "N/A";
                }
                var arg$1 = parent !== undefined && typeof parent === "object" && parent.NAME === "Event" ? Belt_Option.mapWithDefault(ModelUtils.getEventById(parent.VAL, model), "N/A", (function ($$event) {
                          return $$event.name;
                        })) : "N/A";
                var arg$2 = propertyId;
                var arg$3 = Case.toAnalytics(Case.getCasingWithWorkspaceCase(name, namingConvention));
                var arg$4 = AnalyticsUtils.sendAsToPropertyType(sendAs);
                var arg$5 = correctCase;
                var arg$6 = name.length;
                var arg$7 = Case.to_("Sentence", name).split(" ").length;
                var arg$8 = name.length;
                var arg$9 = schemaBundle$1.branchId;
                var arg$10 = schemaBundle$1.schemaId;
                AnalyticsUtils.withCurrentFilters(AnalyticsUtils.withSchemaBundle((function (param) {
                            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31, param$32) {
                              return AnalyticsRe.propertyAdded(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28, param$29, param$30, param$31, param$32);
                            };
                            return function (param) {
                              var func$1 = Curry._1(func, param);
                              return function (param) {
                                var func$2 = Curry._1(func$1, param);
                                return function (param) {
                                  var func$3 = Curry._1(func$2, param);
                                  return function (param) {
                                    var func$4 = Curry._1(func$3, param);
                                    return function (param) {
                                      var func$5 = Curry._1(func$4, param);
                                      return function (param) {
                                        var func$6 = Curry._1(func$5, param);
                                        return function (param) {
                                          var func$7 = Curry._1(func$6, param);
                                          return function (param) {
                                            var func$8 = Curry._1(func$7, param);
                                            return function (param) {
                                              var func$9 = Curry._1(func$8, param);
                                              return function (param) {
                                                var func$10 = Curry._8(func$9, param, arg, arg$1, arg$2, name, arg$3, arg$4, arg$5);
                                                return function (param) {
                                                  var func$11 = Curry._1(func$10, param);
                                                  return function (param) {
                                                    var func$12 = Curry._1(func$11, param);
                                                    return function (param) {
                                                      var func$13 = Curry._1(func$12, param);
                                                      return function (param) {
                                                        var func$14 = Curry._1(func$13, param);
                                                        return function (param) {
                                                          var func$15 = Curry._1(func$14, param);
                                                          return function (param) {
                                                            var func$16 = Curry._1(func$15, param);
                                                            return function (param) {
                                                              var func$17 = Curry._1(func$16, param);
                                                              return function (param) {
                                                                return Curry._8(func$17, param, arg$6, "New", arg$7, arg$8, propertyLocation, arg$9, arg$10);
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          }), schemaBundle$1), currentFilters);
              }),
            undefined,
            Belt_Array.concat([
                  [
                    {
                      NAME: "CreateProperty",
                      VAL: [
                        propertyId,
                        name,
                        sendAs
                      ]
                    },
                    {
                      eventId: variant$1.baseEventId,
                      eventQuery: variant$1.baseEventId,
                      propertyId: propertyId,
                      propertyQuery: propertyId
                    }
                  ],
                  [
                    {
                      NAME: "AddPropertyToEventVariant",
                      VAL: [
                        TrackingPlanModel.EventVariant.makeVariantIdentifier(variant$1),
                        propertyId
                      ]
                    },
                    {
                      eventId: variant$1.baseEventId,
                      eventQuery: variant$1.baseEventId,
                      propertyId: propertyId,
                      propertyQuery: propertyId
                    }
                  ]
                ], Curry._1(extraCreateActions, propertyId))
          ]);
      return ;
    }
    var parentPropertyId = parent.VAL;
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (branch) {
              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
              Curry._1(addToast, getCreatedToast(propertyId, name));
              Curry._3(onSuccess, propertyId, name, branch);
              trackPropertyCreated("N/A", name, schemaBundle$1, sendAs, filters, sourceFilterNames, namingConvention, propertyLocation, correctCase, propertyId);
            }),
          undefined,
          Belt_Array.concat([
                [
                  {
                    NAME: "CreateProperty",
                    VAL: [
                      propertyId,
                      name,
                      sendAs
                    ]
                  },
                  {
                    propertyId: propertyId,
                    propertyQuery: propertyId
                  }
                ],
                [
                  {
                    NAME: "AddPropertyValidation",
                    VAL: [
                      parentPropertyId,
                      {
                        NAME: "NestedProperty",
                        VAL: {
                          hd: {
                            id: propertyId,
                            description: "",
                            pinnedValue: undefined
                          },
                          tl: /* [] */0
                        }
                      }
                    ]
                  },
                  {
                    propertyId: parentPropertyId,
                    propertyQuery: parentPropertyId
                  }
                ]
              ], Curry._1(extraCreateActions, propertyId))
        ]);
    return ;
  }
  Curry.app(sendActions, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
            Curry._1(addToast, getCreatedToast(propertyId, name));
            Curry._3(onSuccess, propertyId, name, branch);
            trackPropertyCreated("N/A", name, schemaBundle$1, sendAs, filters, sourceFilterNames, namingConvention, propertyLocation, correctCase, propertyId);
          }),
        undefined,
        Belt_Array.concat([[
                {
                  NAME: "CreateProperty",
                  VAL: [
                    propertyId,
                    name,
                    sendAs
                  ]
                },
                {
                  propertyId: propertyId,
                  propertyQuery: propertyId
                }
              ]], Curry._1(extraCreateActions, propertyId))
      ]);
}

function NewPropertyModal(Props) {
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var model = Props.model;
  var name = Props.name;
  var onClose = Props.onClose;
  var onCreateOpt = Props.onCreate;
  var parent = Props.parent;
  var propertyLocation = Props.propertyLocation;
  var sendAs = Props.sendAs;
  var onCreate = onCreateOpt !== undefined ? onCreateOpt : (function (param, param$1) {
        
      });
  var modelMapped = ModelStore.Mapped.use();
  var schemaBundle = SchemaBundleContext.use();
  var addToast = Toast.useAddToast();
  var sendActions = SendActionsContext.use();
  var propertyId = React.useRef(Nanoid.nanoid());
  var match = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match.expectedPropertyCase;
  var match$1 = GlobalStateContext.use();
  var sourceFilterNames = StateFilterUtils.getSourceFilterNames(match$1.filters, model);
  React.useEffect((function (param) {
          AnalyticsRe.propertyInitiated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, propertyId.current, name, Case.toAnalytics(Case.getCasingWithWorkspaceCase(name, expectedPropertyCase)), NameUtils.isCorrectCase(expectedPropertyCase, name), AnalyticsUtils.sendAsToPropertyType(sendAs), sourceFilterNames, Belt_List.toArray(AvoModel.getTagFilters(filters)), parent !== undefined && typeof parent === "object" && parent.NAME === "Event" ? parent.VAL : "N/A", schemaBundle.branchId, schemaBundle.schemaId);
        }), []);
  var tmp;
  if (typeof sendAs !== "object") {
    switch (sendAs) {
      case "SystemProperty" :
          tmp = "Create System Property";
          break;
      case "EventProperty" :
          tmp = "Create Event Property";
          break;
      case "UserProperty" :
          tmp = "Create User Property";
          break;
      
    }
  } else {
    tmp = "Create Group Property";
  }
  var tmp$1;
  if (typeof sendAs !== "object") {
    switch (sendAs) {
      case "SystemProperty" :
          tmp$1 = "SystemProperty";
          break;
      case "EventProperty" :
          tmp$1 = "EventProperty";
          break;
      case "UserProperty" :
          tmp$1 = "UserProperty";
          break;
      
    }
  } else {
    tmp$1 = undefined;
  }
  var tmp$2 = {
    name: name,
    existingNames: ModelUtils_mapped.getPropertyNamespace(modelMapped),
    expectedCase: expectedPropertyCase,
    forceCase: Curry._1(ValidationConfigV2.Rule.isEnforced, match.config.properties.case),
    autoFocus: true,
    onChange: (function (name, namingConvention, correctCase, _onReset) {
        createProperty(propertyId.current, parent, sendActions, sendAs, name, namingConvention, correctCase, addToast, schemaBundle, filters, sourceFilterNames, propertyLocation, model, (function (param, param$1, param$2) {
                Curry._2(onCreate, propertyId.current, name);
                Curry._1(onClose, undefined);
              }), undefined, currentFilters, undefined);
      }),
    submitLabel: tmp,
    placeholder: "Property Name",
    onClose: (function (param) {
        Curry._1(onClose, undefined);
      }),
    fullscreen: true,
    dirty: name !== "",
    itemType: "Property",
    actionType: "Create",
    getPossibleItemLink: (function (propertyName) {
        var match = ModelUtils.getPropertyByName(propertyName, model);
        if (match !== undefined) {
          return [
                  Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          match.id,
                          undefined
                        ]
                      }),
                  undefined
                ];
        }
        var match$1 = ModelUtils.getPropertyGroupByName(propertyName, model);
        if (match$1 !== undefined) {
          return [
                  Router.Link.addDrawerItem(undefined, {
                        NAME: "propertyGroup",
                        VAL: [
                          match$1.id,
                          undefined
                        ]
                      }),
                  "property bundle"
                ];
        }
        
      })
  };
  if (tmp$1 !== undefined) {
    tmp$2.itemSubType = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(600)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(NameInput.make, tmp$2));
}

var make = NewPropertyModal;

export {
  isValid ,
  getCreatedToast ,
  trackPropertyCreated ,
  createProperty ,
  make ,
}
/* Css Not a pure module */
