// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DiffDestination from "./DiffDestination.mjs";
import * as CopyChangesToBranchModal__Utils from "./CopyChangesToBranchModal__Utils.mjs";
import * as CopyChangesToBranchModal__IncludeItemDropdown from "./CopyChangesToBranchModal__IncludeItemDropdown.mjs";

function CopyChangesToBranch__Destinations(Props) {
  var actionsByObjectId = Props.actionsByObjectId;
  var allEventActions = Props.allEventActions;
  var eventActions = Props.eventActions;
  var fromModel = Props.fromModel;
  var ignoredItems = Props.ignoredItems;
  var onToggleItem = Props.onToggleItem;
  var parentType = Props.parentType;
  var sourceActions = Props.sourceActions;
  var toModel = Props.toModel;
  var destinationActions = Belt_List.keep(actionsByObjectId, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Destination";
          } else {
            return false;
          }
        }));
  if (destinationActions !== /* [] */0) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.gap(Css.px(8)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.gap(Css.px(8)),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.paddingTop(Css.px(16)),
                              tl: {
                                hd: Css.paddingLeft(Css.px(12)),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light10,
                            children: allEventActions !== /* [] */0 ? "Destination changes related to the event" : "Destination changes"
                          })), Belt_List.toArray(Belt_List.map(destinationActions, (function (actions) {
                              var itemId = CopyChangesToBranchModal__Utils.Actions.getItemId(actions);
                              var item = {
                                NAME: "eventDestination",
                                VAL: itemId
                              };
                              var tmp = {
                                key: itemId
                              };
                              var tmp$1 = CopyChangesToBranchModal__Utils.IgnoreState.itemIsDisabled(ignoredItems, item) ? Curry._1(Css.style, {
                                      hd: Css.opacity(0.6),
                                      tl: {
                                        hd: Css.filter({
                                              hd: {
                                                NAME: "grayscale",
                                                VAL: 100.0
                                              },
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }) : undefined;
                              if (tmp$1 !== undefined) {
                                tmp.className = Caml_option.valFromOption(tmp$1);
                              }
                              return React.createElement("div", tmp, React.createElement(DiffDestination.make, {
                                              masterModel: fromModel,
                                              branchModel: toModel,
                                              actions: actions,
                                              collapsible: true,
                                              rightElement: allEventActions === /* [] */0 && sourceActions === /* [] */0 ? null : React.createElement("div", {
                                                      onClick: (function (prim) {
                                                          prim.stopPropagation();
                                                        })
                                                    }, React.createElement(CopyChangesToBranchModal__IncludeItemDropdown.make, {
                                                          onChange: Curry._1(onToggleItem, item),
                                                          value: CopyChangesToBranchModal__Utils.IgnoreState.getItemIgnoreStateValue(ignoredItems, item),
                                                          allowExcludeItem: CopyChangesToBranchModal__Utils.Item.itemAdded(eventActions, item) || CopyChangesToBranchModal__Utils.Item.itemAdded(sourceActions, item),
                                                          allowExcludeChanges: CopyChangesToBranchModal__Utils.Item.existingItem(actions, item),
                                                          itemName: "destination",
                                                          parentType: parentType
                                                        }))
                                            }));
                            })))));
  } else {
    return null;
  }
}

var Utils;

var make = CopyChangesToBranch__Destinations;

export {
  Utils ,
  make ,
}
/* Css Not a pure module */
