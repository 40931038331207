// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Callout from "../Callout.mjs";
import * as Mantine from "../Mantine.mjs";
import * as Tooltip from "../Tooltip.mjs";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Markdown from "../Markdown.mjs";
import * as Tooltips from "../markdown/tooltips/tooltips.mjs";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as TextButton from "../TextButton.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ContextMenu from "../ContextMenu.mjs";
import * as NamedBranch from "../NamedBranch.mjs";
import * as EditableCell from "../EditableCell.mjs";
import * as CellEditPopup from "../CellEditPopup.mjs";
import * as PropertyUtils from "../PropertyUtils.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as AvoConfigTypes from "../../../shared/models/AvoConfigTypes.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as TrackingPlanModel from "../../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "../BeltListExtensions.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as EditableCellListItem from "../EditableCellListItem.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";
import * as GetEventsSendingLiteralUseCase from "../../../model/src/eventSpecificPropertyValues/GetEventsSendingLiteralUseCase.mjs";
import * as GetVariantsCountOnEventsUseCase from "../../../model/src/variants/GetVariantsCountOnEventsUseCase.mjs";
import * as GetVariantsSendingLiteralUseCase from "../../../model/src/eventSpecificPropertyValues/GetVariantsSendingLiteralUseCase.mjs";
import * as GetEventSpecificPropertyValuesUseCase from "../../../model/src/eventSpecificPropertyValues/GetEventSpecificPropertyValuesUseCase.mjs";
import * as GetEventIdsSendingPropertyForEventSpecificValuesUseCase from "../../../model/src/eventSpecificPropertyValues/GetEventIdsSendingPropertyForEventSpecificValuesUseCase.mjs";

var EditableEventsCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.PropertyEventsWithVariants,
      make: EditableCellListItem.EventsAndVariantsSendingPropertyValues.make
    });

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.marginLeft(Css.px(-29)),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginBottom(Css.px(16)),
            tl: /* [] */0
          }
        }
      }
    });

var headerIcon = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(3)),
      tl: {
        hd: Css.marginRight(Css.px(5)),
        tl: /* [] */0
      }
    });

var tableTop = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.padding(Css.px(16)),
            tl: {
              hd: Css.borderTopLeftRadius(Styles.Border.radius),
              tl: {
                hd: Css.borderTopRightRadius(Styles.Border.radius),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light02),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light06),
                    tl: {
                      hd: Css.borderBottomWidth(Css.px(0)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function table(cols) {
  return Curry._1(Css.style, {
              hd: Css.display("grid"),
              tl: {
                hd: Css.gridTemplateColumns({
                      hd: {
                        NAME: "minmax",
                        VAL: [
                          Css.px(220),
                          Css.fr(1.0)
                        ]
                      },
                      tl: {
                        hd: {
                          NAME: "repeat",
                          VAL: [
                            {
                              NAME: "num",
                              VAL: cols - 1 | 0
                            },
                            {
                              NAME: "minmax",
                              VAL: [
                                Css.px(140),
                                Css.fr(1.0)
                              ]
                            }
                          ]
                        },
                        tl: /* [] */0
                      }
                    }),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.light06),
                  tl: {
                    hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.borderBottomRightRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.light06),
                        tl: {
                          hd: Css.gap(Css.px(1)),
                          tl: {
                            hd: Css.overflowX("auto"),
                            tl: {
                              hd: Css.maxHeight({
                                    NAME: "subtract",
                                    VAL: [
                                      Css.vh(100),
                                      Css.px(230)
                                    ]
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var tableHeaderItem = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.padding(Css.px(8)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light02),
            tl: {
              hd: Css.marginBottom(Css.px(-1)),
              tl: {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light06),
                tl: {
                  hd: Css.zIndex(10),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var tableHeaderItemFirst = Curry._1(Css.merge, {
      hd: tableHeaderItem,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.paddingLeft(Css.px(16)),
                tl: {
                  hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light06),
                  tl: {
                    hd: Css.marginRight(Css.px(-1)),
                    tl: {
                      hd: Css.zIndex(11),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var tableItem = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.padding(Css.px(12)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: /* [] */0
            }
          }
        }
      }
    });

var tableItemFirst = Curry._1(Css.merge, {
      hd: tableItem,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("sticky"),
              tl: {
                hd: Css.left(Css.px(0)),
                tl: {
                  hd: Css.paddingLeft(Css.px(16)),
                  tl: {
                    hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light06),
                    tl: {
                      hd: Css.marginRight(Css.px(-1)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.zIndex(1),
                          tl: {
                            hd: Css.wordBreak("breakAll"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function PropertyDetailsValueRules(Props) {
  var property = Props.property;
  var viewerCanEdit = Props.viewerCanEdit;
  var propertyEvents = Props.propertyEvents;
  var sendActions = SendActionsContext.use();
  var model = ModelStore.Mapped.use();
  var schemaBundle = SchemaBundleContext.use();
  var globalSend = GlobalSendContext.use();
  var sourcesSendingProperty = property.sendAs === "SystemProperty" ? Curry._1(TrackingPlanMappedModel.Sources.toArray, model.sources) : Belt_Array.keepMapU(Belt_List.toArray(Belt_List.reverse(BeltListExtensions.dedupeString(ModelUtils.getIncludedSourceIds(BeltListExtensions.flatMap(propertyEvents, (function ($$event) {
                                return $$event.includeSources;
                              })))))), (function (id) {
            return Curry._2(TrackingPlanMappedModel.Sources.get, model.sources, id);
          }));
  var handleToggleEvent = function (literal, sourceId, eventId, selected) {
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (branch) {
              var value = typeof literal === "object" && literal.NAME === "StringLit" ? literal.VAL : Pervasives.failwith("Unsupported literal type in PropertyDetailsValueRules.res");
              var isSystemProp = eventId === "system";
              var $$event;
              if (isSystemProp) {
                var init = TrackingPlanModel.emptyEvent("system", "System");
                $$event = {
                  id: init.id,
                  name: init.name,
                  uniqueName: init.uniqueName,
                  description: init.description,
                  directPropertyRefs: init.directPropertyRefs,
                  propertyBundles: init.propertyBundles,
                  variants: init.variants,
                  types: init.types,
                  tags: init.tags,
                  excludeSourcesDeprecated: init.excludeSourcesDeprecated,
                  includeSources: Belt_List.fromArray(Belt_Array.mapU(sourcesSendingProperty, (function (param) {
                              return {
                                      id: param.id,
                                      includeInCodegen: false,
                                      inspectorValidation: undefined
                                    };
                            }))),
                  includeDestinations: init.includeDestinations,
                  hashes: init.hashes,
                  propertyWhitelist: init.propertyWhitelist,
                  eventGroupTypeIdsWithArchive: init.eventGroupTypeIdsWithArchive,
                  userGroupTypeIdsWithArchive: init.userGroupTypeIdsWithArchive,
                  triggers: init.triggers,
                  globalRequirementsMetadata: init.globalRequirementsMetadata
                };
              } else {
                $$event = Belt_Option.getExn(Curry._2(TrackingPlanMappedModel.Events.get, model.events, eventId));
              }
              var eventIncludedSources = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.mapU($$event.includeSources, (function (param) {
                              return param.id;
                            }))));
              var disallowedSourcesBefore = Belt_Option.mapWithDefault(Belt_Array.getBy(property.eventSpecificAllowedPropertyValues, (function (param) {
                          var literal = param[0];
                          if (typeof literal === "object" && literal.NAME === "StringLit") {
                            return literal.VAL === value;
                          } else {
                            return false;
                          }
                        })), undefined, (function (param) {
                      var match = Belt_MapString.get(param[1], eventId);
                      if (match !== undefined) {
                        if (typeof match !== "object") {
                          return eventIncludedSources;
                        } else {
                          return Belt_SetString.intersect(match._0, eventIncludedSources);
                        }
                      }
                      
                    }));
              var disallowedSourcesAfter = selected ? Belt_SetString.remove(disallowedSourcesBefore, sourceId) : Belt_SetString.add(disallowedSourcesBefore, sourceId);
              var isDisallowedOnEventAfter = Belt_SetString.eq(disallowedSourcesAfter, eventIncludedSources);
              var matchesBefore = Belt_SetString.fromArray(Belt_Array.keepMap(GetEventSpecificPropertyValuesUseCase.get(property.eventSpecificAllowedPropertyValues, $$event), (function (param) {
                          if (Belt_SetString.has(param[1], sourceId)) {
                            return ;
                          } else {
                            return param[0];
                          }
                        })));
              var matchesAfter = !selected && isDisallowedOnEventAfter ? Belt_SetString.remove(matchesBefore, value) : Belt_SetString.add(matchesBefore, value);
              var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
              var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroupFromCommonCase(undefined, property);
              if (isSystemProp) {
                return AnalyticsRe.matchUpdatedForSource(schema.schemaId, schema.schemaName, schema.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, value, Belt_SetString.size(matchesAfter), Belt_SetString.toArray(matchesAfter), selected ? "EnableSource" : "DisableSource", sourcesSendingProperty.length, Belt_SetString.size(Belt_SetString.diff(eventIncludedSources, disallowedSourcesAfter)), "Property", Belt_Option.getWithDefault(Belt_Option.flatMap(Curry._2(TrackingPlanMappedModel.Sources.get, model.sources, sourceId), (function (source) {
                                      return source.name;
                                    })), "Untitled Source"), sourceId, NamedBranch.getId(branch), schemaBundle.schemaId);
              } else {
                return AnalyticsRe.matchUpdatedForEvent(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, value, Belt_SetString.size(matchesAfter), Belt_SetString.toArray(matchesAfter), selected ? "AllowOnEventSource" : "DisallowOnEventSource", sourcesSendingProperty.length, Belt_SetString.size(Belt_SetString.diff(eventIncludedSources, disallowedSourcesAfter)), "Property", eventId, $$event.name, NamedBranch.getId(branch), schemaBundle.schemaId);
              }
            }),
          undefined,
          [[
              {
                NAME: "SetEventSpecificPropertyValueSources",
                VAL: [
                  eventId,
                  property.id,
                  literal,
                  selected ? Belt_SetString.fromArray([sourceId]) : undefined,
                  selected ? undefined : Belt_SetString.fromArray([sourceId])
                ]
              },
              {
                eventId: eventId,
                eventQuery: eventId,
                propertyId: property.id,
                propertyQuery: property.id
              }
            ]]
        ]);
  };
  var codegenSourcesSendingEventSpecificPropertyValue = [];
  var unsupportedSources = Belt_Array.keepMap(codegenSourcesSendingEventSpecificPropertyValue, (function (source) {
          var language = source.language;
          if (language !== undefined && Belt_Array.every(AvoConfig.supportedEventSpecificPropertyValuesPlatforms, (function (supportedLanguage) {
                    return supportedLanguage !== language;
                  }))) {
            return [
                    source,
                    language
                  ];
          }
          
        }));
  var match = React.useState(function (param) {
        return "Alphabet";
      });
  var setOrderBy = match[1];
  var orderBy = match[0];
  var orderedValues = React.useMemo((function (param) {
          if (orderBy === "DateAdded") {
            return property.eventSpecificAllowedPropertyValues;
          } else {
            return property.eventSpecificAllowedPropertyValues.slice().sort(function (param, param$1) {
                        var literalB = param$1[0];
                        var literalA = param[0];
                        if (typeof literalA === "object" && literalA.NAME === "StringLit" && typeof literalB === "object" && literalB.NAME === "StringLit") {
                          return Caml.string_compare(literalA.VAL, literalB.VAL);
                        } else {
                          return Pervasives.failwith("Unsupported literal type in PropertyDetailsValueRules.res");
                        }
                      });
          }
        }), [
        property.eventSpecificAllowedPropertyValues,
        orderBy
      ]);
  var handleRemoveValueConstraints = function (param) {
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (branch) {
              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
              AnalyticsRe.constraintTypeUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "None", "Remove", "Matches", property.name, NamedBranch.getId(branch), schemaBundle$1.schemaId);
            }),
          undefined,
          [[
              {
                NAME: "ClearAllEventSpecificPropertyValuesFromProperty",
                VAL: property.id
              },
              {
                propertyId: property.id,
                propertyQuery: property.id
              }
            ]]
        ]);
  };
  var tmp;
  if (orderedValues.length !== 0) {
    var propertyEventsSendingSourceMap = Belt_List.reduceU(propertyEvents, undefined, (function (sourcesMap, $$event) {
            return Belt_List.reduceU($$event.includeSources, sourcesMap, (function (sourcesMap, includeSource) {
                          return Belt_MapString.update(sourcesMap, includeSource.id, (function (sources) {
                                        if (sources !== undefined) {
                                          return Belt_Array.concat(sources, [$$event]);
                                        } else {
                                          return [$$event];
                                        }
                                      }));
                        }));
          }));
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: tableTop
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: "Allowed values"
                }), React.createElement(Button.make, {
                  icon: "arrowsDownUp",
                  label: orderBy === "DateAdded" ? "Sort alphabetically" : "Sort by date added",
                  onClick: (function (param) {
                      Curry._1(setOrderBy, (function (orderBy) {
                              if (orderBy === "DateAdded") {
                                return "Alphabet";
                              } else {
                                return "DateAdded";
                              }
                            }));
                    }),
                  style: "ghost"
                })), React.createElement("div", {
              className: table(sourcesSendingProperty.length + 1 | 0)
            }, React.createElement("div", {
                  className: tableHeaderItemFirst
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Values"
                    })), Belt_Array.mapU(sourcesSendingProperty, (function (source) {
                    return React.createElement("div", {
                                key: source.id,
                                className: tableHeaderItem
                              }, React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Semi",
                                    singleLine: true,
                                    color: Styles.Color.light11,
                                    maxWidth: {
                                      NAME: "percent",
                                      VAL: 100
                                    },
                                    title: AvoConfig.getSourceName(source),
                                    children: AvoConfig.getSourceName(source)
                                  }));
                  })), Belt_Array.map(Belt_Array.keepMap(orderedValues, (function (param) {
                        var value = param[0];
                        if (typeof value === "object" && value.NAME === "StringLit") {
                          return [
                                  value.VAL,
                                  param[1]
                                ];
                        }
                        
                      })), (function (param) {
                    var disallowedEvents = param[1];
                    var value = param[0];
                    return React.createElement(React.Fragment, {
                                children: null,
                                key: value
                              }, React.createElement("div", {
                                    className: tableItemFirst
                                  }, React.createElement($$Text.make, {
                                        size: "Medium",
                                        weight: "Semi",
                                        color: Styles.Color.light12,
                                        title: value,
                                        children: value
                                      }), viewerCanEdit ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                              width: 8
                                            }), React.createElement(ContextMenu.make, {
                                              options: [{
                                                  NAME: "Option",
                                                  VAL: {
                                                    label: "Remove Allowed Value",
                                                    onClick: (function (param) {
                                                        Curry.app(sendActions, [
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              (function (branch) {
                                                                  var matchesBefore = Belt_Array.keepMap(property.eventSpecificAllowedPropertyValues, (function (param) {
                                                                          var literal = param[0];
                                                                          if (typeof literal === "object" && literal.NAME === "StringLit") {
                                                                            return literal.VAL;
                                                                          }
                                                                          
                                                                        }));
                                                                  var matchesAfter = Belt_Array.keep(matchesBefore, (function (match) {
                                                                          return match !== value;
                                                                        }));
                                                                  var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                  var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                                                                  AnalyticsRe.matchUpdated(schema.schemaId, schema.schemaName, schema.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, value, matchesAfter.length, matchesAfter, "Remove", sourcesSendingProperty.length, 0, "Property", NamedBranch.getId(branch), schemaBundle.schemaId);
                                                                }),
                                                              undefined,
                                                              [[
                                                                  {
                                                                    NAME: "RemoveEventSpecificPropertyValueForAllEvents",
                                                                    VAL: [
                                                                      property.id,
                                                                      {
                                                                        NAME: "StringLit",
                                                                        VAL: value
                                                                      }
                                                                    ]
                                                                  },
                                                                  {
                                                                    propertyId: property.id,
                                                                    propertyQuery: property.id
                                                                  }
                                                                ]]
                                                            ]);
                                                      })
                                                  }
                                                }]
                                            })) : null), Belt_Array.mapU(sourcesSendingProperty, (function (source) {
                                      if (orderedValues.length > PropertyUtils.eventSpecificValuesLimit) {
                                        var propertyEventsSendingSource = Belt_Option.getWithDefault(Belt_MapString.get(propertyEventsSendingSourceMap, source.id), []);
                                        var eventsSendingPropertyValueOnSource = Belt_Array.keepU(propertyEventsSendingSource, (function (param) {
                                                var match = Belt_MapString.get(disallowedEvents, param.id);
                                                if (match !== undefined) {
                                                  if (typeof match !== "object") {
                                                    return false;
                                                  } else {
                                                    return !Belt_SetString.has(match._0, source.id);
                                                  }
                                                } else {
                                                  return true;
                                                }
                                              }));
                                        return React.createElement("div", {
                                                    key: value + source.id,
                                                    className: tableItem
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        color: Styles.Color.light12,
                                                        children: eventsSendingPropertyValueOnSource.length === propertyEventsSendingSource.length ? "All events" : AvoUtils.plural(undefined, undefined, eventsSendingPropertyValueOnSource.length, "event")
                                                      }));
                                      }
                                      if (property.sendAs === "SystemProperty") {
                                        var match = Belt_MapString.get(disallowedEvents, GetEventIdsSendingPropertyForEventSpecificValuesUseCase.propertyValuesAllEventsKey);
                                        var isSystemValueSentOnSource = match !== undefined ? (
                                            typeof match !== "object" ? false : !Belt_SetString.has(match._0, source.id)
                                          ) : true;
                                        return React.createElement("div", {
                                                    key: value + source.id,
                                                    className: tableItem
                                                  }, React.createElement(Mantine.Checkbox.make, {
                                                        checked: isSystemValueSentOnSource,
                                                        color: "blue100",
                                                        onChange: (function (param) {
                                                            handleToggleEvent({
                                                                  NAME: "StringLit",
                                                                  VAL: value
                                                                }, source.id, "system", !isSystemValueSentOnSource);
                                                          }),
                                                        size: {
                                                          NAME: "num",
                                                          VAL: 16
                                                        }
                                                      }));
                                      }
                                      var propertyEventsSendingSource$1 = Belt_Option.getWithDefault(Belt_MapString.get(propertyEventsSendingSourceMap, source.id), []);
                                      var eventsSendingLiteral = GetEventsSendingLiteralUseCase.getEventsSendingLiteral(propertyEventsSendingSource$1, disallowedEvents);
                                      var numberOfVariantsTotal = GetVariantsCountOnEventsUseCase.getVariantsCountOnEvents(Belt_List.toArray(propertyEvents));
                                      var variantsAllowed = GetVariantsSendingLiteralUseCase.getVariantsSendingLiteral(property.id, Belt_List.toArray(propertyEvents), eventsSendingLiteral, value);
                                      var numberOfVariantsAllowed = variantsAllowed.length;
                                      var eventsSendingValueOnSource = Belt_Array.keepU(propertyEventsSendingSource$1, (function ($$event) {
                                              var match = Belt_MapString.get(disallowedEvents, $$event.id);
                                              if (match !== undefined) {
                                                if (typeof match !== "object") {
                                                  return false;
                                                } else {
                                                  return !Belt_SetString.has(match._0, source.id);
                                                }
                                              } else {
                                                return true;
                                              }
                                            }));
                                      var numEventsSendingValueOnSource = eventsSendingValueOnSource.length;
                                      var eventsIncludingVariantsSendingPropertyValueOnSource = Belt_Array.keepMapU(propertyEventsSendingSource$1, (function ($$event) {
                                              var match = Belt_MapString.get(disallowedEvents, $$event.id);
                                              var eventSendingValue = match !== undefined ? (
                                                  typeof match !== "object" ? false : !Belt_SetString.has(match._0, source.id)
                                                ) : true;
                                              var variantsAllowedForThisEvent = Belt_Array.keepMap(variantsAllowed, (function (variant) {
                                                      if (variant.baseEventId === $$event.id) {
                                                        return variant;
                                                      }
                                                      
                                                    }));
                                              if (eventSendingValue || Caml_obj.notequal(variantsAllowedForThisEvent, [])) {
                                                return [
                                                        $$event,
                                                        variantsAllowedForThisEvent
                                                      ];
                                              }
                                              
                                            }));
                                      return React.createElement(EditableEventsCell.make, {
                                                  allItems: Belt_Array.map(propertyEventsSendingSource$1, (function ($$event) {
                                                          return [
                                                                  $$event,
                                                                  []
                                                                ];
                                                        })),
                                                  children: React.createElement($$Text.make, {
                                                        size: "Small",
                                                        color: Styles.Color.light12,
                                                        children: numEventsSendingValueOnSource === propertyEventsSendingSource$1.length && numberOfVariantsAllowed === numberOfVariantsTotal ? (
                                                            numberOfVariantsTotal === 0 ? "all events" : "all events and variants"
                                                          ) : (
                                                            numberOfVariantsTotal === 0 ? AvoUtils.plural(undefined, undefined, numEventsSendingValueOnSource, "event") : AvoUtils.plural(undefined, undefined, numEventsSendingValueOnSource, "event") + ", " + AvoUtils.plural(undefined, undefined, numberOfVariantsAllowed, "variant")
                                                          )
                                                      }),
                                                  className: tableItem,
                                                  onCreates: (function (param) {
                                                      return [];
                                                    }),
                                                  disabled: false,
                                                  onViewItem: (function (id) {
                                                      Router.Schema.pushDrawerItem(undefined, undefined, {
                                                            NAME: "event",
                                                            VAL: [
                                                              id,
                                                              undefined,
                                                              undefined,
                                                              false
                                                            ]
                                                          });
                                                    }),
                                                  onToggle: (function (param, eventId, selected) {
                                                      handleToggleEvent({
                                                            NAME: "StringLit",
                                                            VAL: value
                                                          }, source.id, eventId, selected);
                                                    }),
                                                  viewerCanEdit: viewerCanEdit,
                                                  selectedItems: eventsIncludingVariantsSendingPropertyValueOnSource,
                                                  onItemClicked: (function (param) {
                                                      
                                                    }),
                                                  onHover: (function (param) {
                                                      
                                                    }),
                                                  onSearch: (function (_value) {
                                                      
                                                    }),
                                                  onCreateInitiated: (function (_value) {
                                                      
                                                    }),
                                                  key: value + source.id
                                                });
                                    })));
                  }))), React.createElement(Spacer.make, {
              height: 10
            }), viewerCanEdit ? React.createElement(TextButton.make, {
                onClick: (function (param) {
                    Curry._1(globalSend, {
                          TAG: "OpenModal",
                          _0: {
                            NAME: "AddPropertyValue",
                            VAL: property.id
                          }
                        });
                  }),
                style: "Blue",
                size: "Medium",
                children: "+ Add Allowed Value"
              }) : null);
  } else {
    tmp = React.createElement(TextButton.make, {
          onClick: (function (param) {
              Curry._1(globalSend, {
                    TAG: "OpenModal",
                    _0: {
                      NAME: "AddPropertyValue",
                      VAL: property.id
                    }
                  });
            }),
          style: "Blue",
          size: "Medium",
          children: "+ Add constraints"
        });
  }
  var tmp$1;
  if (unsupportedSources.length !== 0) {
    var sourceNames = Belt_Array.map(unsupportedSources, (function (param) {
              return AvoConfig.getSourceName(param[0]) + " (" + AvoConfigTypes.languageToJs(param[1]) + ")";
            })).join(", ");
    tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
              height: 16
            }), React.createElement(Callout.make, {
              title: "Event-specific values currently not supported on all your Codegen sources",
              body: "The following sources are using programming languages where Codegen does not yet support event-specific values: " + sourceNames + ". For Codegen on sources in those languages, the property will be represented and validated using a union of all the property values on that source. We're hoping to add full support for more language in the near future."
            }), React.createElement(Spacer.make, {
              height: 16
            }));
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: header
                }, React.createElement("div", {
                      className: headerIcon
                    }, React.createElement(Icon.make, {
                          type_: "equalizer",
                          color: Styles.Color.light07
                        })), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "Property Value Constraints"
                    }), React.createElement(Tooltip.WithAnalytics.make, {
                      fromItem: {
                        itemId: property.id,
                        itemName: property.name,
                        itemType: "Property"
                      },
                      title: "Property Value Constraints",
                      children: React.createElement(Tooltip.Content.make, {
                            children: React.createElement(Markdown.make, {
                                  source: Tooltips.propertyValueRules
                                })
                          })
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), viewerCanEdit ? React.createElement(Button.make, {
                        label: "Remove Value Constraints",
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: "OpenModal",
                                  _0: {
                                    NAME: "AreYouSureModal",
                                    VAL: [
                                      "Caution! This action will impact all events the property is attached to.",
                                      "By removing value constraints you will permanently delete any existing value constraints for this property across all events, including event specific constraints.",
                                      "Remove all constraints",
                                      handleRemoveValueConstraints,
                                      (function (param) {
                                          
                                        })
                                    ]
                                  }
                                });
                          }),
                        style: "ghost"
                      }) : null), tmp, tmp$1);
}

var make = PropertyDetailsValueRules;

export {
  make ,
}
/* EditableEventsCell Not a pure module */
