// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as CmdK from "./CmdK.mjs";
import * as $$Text from "./Text.mjs";
import * as Kbar from "kbar";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Portal from "./Portal.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Nanoid from "nanoid";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as QuillDelta from "../../model/src/externals/quillDelta.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import QuillDelta$1 from "quill-delta";
import * as React$1 from "motion/react";
import * as PlanLightning from "./PlanLightning.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import LodashDebounce from "lodash.debounce";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as TriggerModalImage from "./TriggerModalImage.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as TriggerModalSources from "./TriggerModalSources.mjs";
import * as TriggerModalNavigation from "./TriggerModalNavigation.mjs";
import * as TriggerModalDescription from "./TriggerModalDescription.mjs";

function TriggerFullscreenModal$Lightning(Props) {
  var color = Props.color;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = AvoLimits.Triggers.computeAvailability(workspace);
  if (match === "Available") {
    return null;
  } else {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css_Legacy_Core.SVG.fill("transparent"),
                      tl: {
                        hd: Css_Legacy_Core.SVG.stroke(color),
                        tl: {
                          hd: Css.marginRight(Css.px(2)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement(PlanLightning.make, {
                    tooltipText: (
                      match === "Unavailable" ? "Available " : "Available during trial and "
                    ) + ("on the " + (Workspace.printPlanName(AvoLimits.Triggers.availableOnPlan(workspace).name) + " plan"))
                  }));
  }
}

var Lightning = {
  make: TriggerFullscreenModal$Lightning
};

var wrapper = Curry._1(Css.style, {
      hd: Css.zIndex(Styles.ZIndex.eventTriggerModal),
      tl: {
        hd: Css.position("absolute"),
        tl: {
          hd: Css.left(Css.px(0)),
          tl: {
            hd: Css.top(Css.px(0)),
            tl: {
              hd: Css.width(Css.vw(100)),
              tl: {
                hd: Css.height(Css.vh(100)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light02),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.justifyContent("flexEnd"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var sidebar = Curry._1(Css.style, {
      hd: Css.width(Css.px(300)),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.flexDirection("column"),
              tl: {
                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(32), undefined, undefined, Css.rgba(0, 0, 0, {
                                NAME: "num",
                                VAL: 0.04
                              }))),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var closeButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.left(Css.px(16)),
                tl: {
                  hd: Css.top(Css.px(16)),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.borderRadius(Css.px(16)),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.padding2(Css.px(4), Css.px(8)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.light02),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$default
                                    }, undefined, undefined, "all"),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.backgroundColor(Styles.Color.white),
                                        tl: {
                                          hd: Css.boxShadow(Styles.Shadow.subtle),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function sidebarFooter(withBorder) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.paddingTop(Css.px(15)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(16)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(24)),
                        tl: {
                          hd: Css.paddingRight(Css.px(24)),
                          tl: {
                            hd: Css.borderTop(Css.px(withBorder ? 1 : 0), "solid", Styles.Color.light04),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Style = {
  wrapper: wrapper,
  sidebar: sidebar,
  closeButton: closeButton,
  sidebarFooter: sidebarFooter
};

function updateDescription(sendActions, eventId, triggerId, variantIdentifier, description) {
  if (variantIdentifier !== undefined) {
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                [[
                    {
                      NAME: "UpdateEventVariantTriggerDescription",
                      VAL: [
                        variantIdentifier,
                        triggerId,
                        description
                      ]
                    },
                    {
                      eventId: variantIdentifier.baseEventId,
                      triggerId: triggerId,
                      triggerQuery: triggerId,
                      eventVariantId: variantIdentifier.variantId,
                      eventVariantQuery: variantIdentifier.variantId
                    }
                  ]]
              ]);
  } else {
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                [[
                    {
                      NAME: "UpdateTriggerDescription",
                      VAL: [
                        eventId,
                        triggerId,
                        description
                      ]
                    },
                    {
                      eventId: eventId,
                      eventQuery: eventId,
                      triggerId: triggerId,
                      triggerQuery: triggerId
                    }
                  ]]
              ]);
  }
}

var debouncedUpdateDescription = LodashDebounce(updateDescription, 500, {
      leading: false,
      trailing: true
    });

function TriggerFullscreenModal(Props) {
  var currentBranch = Props.currentBranch;
  var $$event = Props.event;
  var variantIdentifier = Props.variantIdentifier;
  var eventVariant = Props.eventVariant;
  var triggerId = Props.triggerId;
  var modelSources = Props.modelSources;
  var numEvents = Props.numEvents;
  var onClose = Props.onClose;
  var viewerCanEdit = Props.viewerCanEdit;
  var schemaBundle = SchemaBundleContext.use();
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var availability = AvoLimits.Triggers.computeAvailability(workspace);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, $$event.name);
  var sendActions = SendActionsContext.use();
  var globalSend = GlobalSendContext.use();
  var match$1 = React.useState(function (param) {
        if (triggerId === "new") {
          return TrackingPlanModel.emptyTrigger(undefined, undefined, undefined, Nanoid.nanoid());
        }
        
      });
  var setDraft = match$1[1];
  var draft = match$1[0];
  React.useEffect((function (param) {
          Curry._1(setDraft, (function (draft) {
                  if (draft === undefined && triggerId === "new") {
                    return TrackingPlanModel.emptyTrigger(undefined, undefined, undefined, Nanoid.nanoid());
                  } else {
                    return draft;
                  }
                }));
        }), [triggerId]);
  var inDraftMode = draft !== undefined;
  var match$2 = React.useState(function (param) {
        return "Idle";
      });
  var uploadStatus = match$2[0];
  var currentTriggerIndex = React.useMemo((function (param) {
          return Belt_Option.getWithDefault(Belt_Array.getIndexBy($$event.triggers, (function (trigger) {
                            return trigger.id === triggerId;
                          })), 0);
        }), [
        triggerId,
        $$event.triggers
      ]);
  var currentTrigger = inDraftMode ? draft : Belt_Array.get($$event.triggers, currentTriggerIndex);
  var routeToTrigger = React.useCallback((function (triggerId) {
          Router.Schema.swapTopDrawerItem(Belt_Option.mapWithDefault(variantIdentifier, {
                    NAME: "event",
                    VAL: [
                      $$event.id,
                      undefined,
                      triggerId,
                      false
                    ]
                  }, (function (variantIdentifier) {
                      return {
                              NAME: "eventVariant",
                              VAL: [
                                variantIdentifier,
                                triggerId
                              ]
                            };
                    })));
        }), [
        workspace.id,
        currentBranch,
        $$event.id
      ]);
  var onNewTrigger = function (_event) {
    if (availability === "Unavailable") {
      return Curry._1(globalSend, {
                  TAG: "OpenModal",
                  _0: {
                    NAME: "BillingPrompt",
                    VAL: "AddEventTrigger"
                  }
                });
    }
    var match = Belt_List.head(Router.getStaticRoute().drawerItems);
    if (match === undefined) {
      return ;
    }
    if (typeof match !== "object") {
      return ;
    }
    var variant = match.NAME;
    if (variant === "eventVariant" || variant === "event") {
      return Curry._1(routeToTrigger, "new");
    }
    
  };
  Kbar.useRegisterActions(Belt_Array.concatMany([
            [Curry.app(CmdK.Action.t, [
                    "close_triggers",
                    {
                      TAG: "RootItem",
                      _0: "Close triggers"
                    },
                    1.1,
                    ["escape"],
                    undefined,
                    undefined,
                    (function (param) {
                        if (inDraftMode) {
                          return Curry._1(globalSend, {
                                      TAG: "OpenModal",
                                      _0: {
                                        NAME: "ConfirmModal",
                                        VAL: [
                                          "Discard changes?",
                                          "Are you sure you want to discard your draft?",
                                          "Discard",
                                          (function (param) {
                                              Curry._1(setDraft, (function (param) {
                                                      
                                                    }));
                                              Curry._1(onClose, undefined);
                                            }),
                                          (function (param) {
                                              
                                            })
                                        ]
                                      }
                                    });
                        } else {
                          return Curry._1(onClose, undefined);
                        }
                      }),
                    undefined,
                    undefined
                  ])],
            $$event.triggers.length > 1 && currentTriggerIndex !== 0 && !inDraftMode ? [Curry.app(CmdK.Action.t, [
                      "go_to_prev_trigger",
                      {
                        TAG: "RootItem",
                        _0: "Previous Trigger"
                      },
                      1.1,
                      ["arrowleft"],
                      undefined,
                      undefined,
                      (function (param) {
                          Belt_Option.forEach(Belt_Array.get($$event.triggers, currentTriggerIndex - 1 | 0), (function (param) {
                                  Curry._1(routeToTrigger, param.id);
                                }));
                        }),
                      undefined,
                      undefined
                    ])] : [],
            $$event.triggers.length > 1 && currentTriggerIndex !== ($$event.triggers.length - 1 | 0) && !inDraftMode ? [Curry.app(CmdK.Action.t, [
                      "go_to_next_trigger",
                      {
                        TAG: "RootItem",
                        _0: "Next Trigger"
                      },
                      1.1,
                      ["arrowright"],
                      undefined,
                      undefined,
                      (function (param) {
                          Belt_Option.forEach(Belt_Array.get($$event.triggers, currentTriggerIndex + 1 | 0), (function (param) {
                                  Curry._1(routeToTrigger, param.id);
                                }));
                        }),
                      undefined,
                      undefined
                    ])] : []
          ]), [
        inDraftMode,
        currentTriggerIndex,
        $$event.triggers.length
      ]);
  var onCreate = function (createAnotherOpt, trigger) {
    var createAnother = createAnotherOpt !== undefined ? createAnotherOpt : false;
    if (variantIdentifier !== undefined) {
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            (function (branch) {
                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                AnalyticsRe.eventVariantUpdated(variantIdentifier.baseEventId, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "AddEventTrigger", variantIdentifier.variantId, schemaBundle$1.schemaId, NamedBranch.getId(branch), variantIdentifier.baseEventId);
                var schemaBundle$2 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle$1, branch);
                var ids = trigger.sources;
                var tmp;
                tmp = typeof ids !== "object" ? 0 : ids._0.length;
                var match = trigger.content;
                var tmp$1;
                tmp$1 = match !== undefined && match.TAG === "Image" ? "Image" : "None";
                AnalyticsRe.eventTriggerCreatedOnVariant(variantIdentifier.baseEventId, $$event.name, schemaBundle$2.schemaId, schemaBundle$2.schemaName, schemaBundle$2.schemaBillingStatus, schemaBundle$2.branchId, schemaBundle$2.branchName, schemaBundle$2.schemaSubscriptionPlan, schemaBundle$2.schemaTrialPlan, schemaBundle$2.authenticationConfig, schemaBundle$2.schemaSubscriptionPlanSlug, schemaBundle$2.schemaTrialPlanSlug, schemaBundle$2.schemaType, tmp, tmp$1, $$event.triggers.length + 1 | 0, variantIdentifier.variantId, NamedBranch.getId(branch), schemaBundle$2.schemaId);
                var schemaBundle$3 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle$2, branch);
                if (createAnother) {
                  return AnalyticsRe.eventTriggerInitiatedOnVariant(variantIdentifier.baseEventId, $$event.name, schemaBundle$3.schemaId, schemaBundle$3.schemaName, schemaBundle$3.schemaBillingStatus, schemaBundle$3.branchId, schemaBundle$3.branchName, schemaBundle$3.schemaSubscriptionPlan, schemaBundle$3.schemaTrialPlan, schemaBundle$3.authenticationConfig, schemaBundle$3.schemaSubscriptionPlanSlug, schemaBundle$3.schemaTrialPlanSlug, schemaBundle$3.schemaType, $$event.triggers.length + 1 | 0, variantIdentifier.variantId, NamedBranch.getId(branch), schemaBundle$3.schemaId);
                }
                
              }),
            undefined,
            [[
                {
                  NAME: "CreateEventVariantTrigger",
                  VAL: [
                    variantIdentifier,
                    trigger.id,
                    trigger.sources,
                    trigger.description,
                    trigger.content
                  ]
                },
                {
                  eventId: variantIdentifier.baseEventId,
                  triggerId: trigger.id,
                  triggerQuery: trigger.id,
                  eventVariantId: variantIdentifier.variantId,
                  eventVariantQuery: variantIdentifier.variantId
                }
              ]]
          ]);
    } else {
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            (function (branch) {
                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                AnalyticsRe.eventUpdated($$event.id, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", "N/A", "AddEventTrigger", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, numEvents, undefined, undefined, undefined, undefined, "Event", $$event.triggers.length + 1 | 0, correctCase, Case.toAnalytics(expectedEventCase), "ItemDetailsView", $$event.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                var schemaBundle$2 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle$1, branch);
                var ids = trigger.sources;
                var tmp;
                tmp = typeof ids !== "object" ? 0 : ids._0.length;
                var match = trigger.content;
                var tmp$1;
                tmp$1 = match !== undefined && match.TAG === "Image" ? "Image" : "None";
                AnalyticsRe.eventTriggerCreatedOnEvent($$event.id, $$event.name, schemaBundle$2.schemaId, schemaBundle$2.schemaName, schemaBundle$2.schemaBillingStatus, schemaBundle$2.branchId, schemaBundle$2.branchName, schemaBundle$2.schemaSubscriptionPlan, schemaBundle$2.schemaTrialPlan, schemaBundle$2.authenticationConfig, schemaBundle$2.schemaSubscriptionPlanSlug, schemaBundle$2.schemaTrialPlanSlug, schemaBundle$2.schemaType, tmp, tmp$1, $$event.triggers.length + 1 | 0, NamedBranch.getId(branch), schemaBundle$2.schemaId);
                var schemaBundle$3 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle$2, branch);
                if (createAnother) {
                  return AnalyticsRe.eventTriggerInitiatedOnEvent($$event.id, $$event.name, schemaBundle$3.schemaId, schemaBundle$3.schemaName, schemaBundle$3.schemaBillingStatus, schemaBundle$3.branchId, schemaBundle$3.branchName, schemaBundle$3.schemaSubscriptionPlan, schemaBundle$3.schemaTrialPlan, schemaBundle$3.authenticationConfig, schemaBundle$3.schemaSubscriptionPlanSlug, schemaBundle$3.schemaTrialPlanSlug, schemaBundle$3.schemaType, $$event.triggers.length + 1 | 0, NamedBranch.getId(branch), schemaBundle$3.schemaId);
                }
                
              }),
            undefined,
            [[
                {
                  NAME: "CreateEventTrigger",
                  VAL: [
                    $$event.id,
                    trigger.id,
                    trigger.sources,
                    trigger.description,
                    trigger.content
                  ]
                },
                {
                  eventId: $$event.id,
                  eventQuery: $$event.id,
                  triggerId: trigger.id,
                  triggerQuery: trigger.id
                }
              ]]
          ]);
    }
    Curry._1(setDraft, (function (param) {
            if (createAnother) {
              return TrackingPlanModel.emptyTrigger(undefined, undefined, undefined, Nanoid.nanoid());
            }
            
          }));
    Curry._1(routeToTrigger, createAnother ? "new" : trigger.id);
  };
  var onRemove = function (trigger) {
    Curry._1(globalSend, {
          TAG: "OpenModal",
          _0: {
            NAME: "ConfirmModal",
            VAL: [
              "Remove this trigger?",
              "Are you sure you want to remove this trigger?",
              "Remove",
              (function (param) {
                  if (variantIdentifier !== undefined) {
                    Curry.app(sendActions, [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          (function (branch) {
                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                              AnalyticsRe.eventVariantUpdated(variantIdentifier.baseEventId, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "RemoveEventTrigger", variantIdentifier.variantId, schemaBundle$1.schemaId, NamedBranch.getId(branch), variantIdentifier.baseEventId);
                            }),
                          undefined,
                          [[
                              {
                                NAME: "RemoveEventVariantTrigger",
                                VAL: [
                                  variantIdentifier,
                                  trigger.id
                                ]
                              },
                              {
                                eventId: $$event.id,
                                triggerId: trigger.id,
                                triggerQuery: trigger.id,
                                eventVariantId: variantIdentifier.variantId,
                                eventVariantQuery: variantIdentifier.variantId
                              }
                            ]]
                        ]);
                  } else {
                    Curry.app(sendActions, [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          (function (branch) {
                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                              AnalyticsRe.eventUpdated($$event.id, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", "N/A", "RemoveEventTrigger", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, numEvents, undefined, undefined, undefined, undefined, "Event", $$event.triggers.length - 1 | 0, correctCase, Case.toAnalytics(expectedEventCase), "Trigger", $$event.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                            }),
                          undefined,
                          [[
                              {
                                NAME: "RemoveEventTrigger",
                                VAL: [
                                  $$event.id,
                                  trigger.id
                                ]
                              },
                              {
                                eventId: $$event.id,
                                eventQuery: $$event.id,
                                triggerId: trigger.id,
                                triggerQuery: trigger.id
                              }
                            ]]
                        ]);
                  }
                  var newTriggers = Belt_Array.keep($$event.triggers, (function (param) {
                          return param.id !== trigger.id;
                        }));
                  var previousTriggerIndex = 0 > currentTriggerIndex ? 0 : currentTriggerIndex;
                  var match = Belt_Array.get(newTriggers, previousTriggerIndex);
                  if (match !== undefined) {
                    return Curry._1(routeToTrigger, match.id);
                  } else {
                    return Curry._1(onClose, undefined);
                  }
                }),
              (function (param) {
                  
                })
            ]
          }
        });
  };
  var onUpdateDescription = function (description) {
    Belt_Option.forEach(currentTrigger, (function (currentTrigger) {
            if (inDraftMode) {
              return Curry._1(setDraft, (function (draft) {
                            return Belt_Option.map(draft, (function (draft) {
                                          return {
                                                  id: draft.id,
                                                  sources: draft.sources,
                                                  description: description,
                                                  content: draft.content
                                                };
                                        }));
                          }));
            } else {
              return debouncedUpdateDescription(sendActions, $$event.id, currentTrigger.id, variantIdentifier, description);
            }
          }));
  };
  var onUpdateSource = function (sourceId, newValue) {
    Belt_Option.forEach(currentTrigger, (function (currentTrigger) {
            if (inDraftMode) {
              return Curry._1(setDraft, (function (draft) {
                            var match = currentTrigger.sources;
                            var updatedSources;
                            if (typeof match !== "object") {
                              updatedSources = newValue ? ({
                                    TAG: "SourceIds",
                                    _0: [sourceId]
                                  }) : "SourceIndependent";
                            } else {
                              var sourceIds = match._0;
                              if (newValue) {
                                updatedSources = {
                                  TAG: "SourceIds",
                                  _0: Belt_Array.concat(sourceIds, [sourceId])
                                };
                              } else {
                                var ids = Belt_Array.keep(sourceIds, (function (id) {
                                        return id !== sourceId;
                                      }));
                                updatedSources = ids.length !== 0 ? ({
                                      TAG: "SourceIds",
                                      _0: ids
                                    }) : "SourceIndependent";
                              }
                            }
                            return Belt_Option.map(draft, (function (draft) {
                                          return {
                                                  id: draft.id,
                                                  sources: updatedSources,
                                                  description: draft.description,
                                                  content: draft.content
                                                };
                                        }));
                          }));
            } else if (variantIdentifier !== undefined) {
              return Curry.app(sendActions, [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          (function (branch) {
                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                              AnalyticsRe.eventTriggerConfiguredOnVariant(variantIdentifier.baseEventId, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, newValue ? "AddSource" : "RemoveSource", "Sources", $$event.triggers.length, variantIdentifier.variantId, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                            }),
                          undefined,
                          [[
                              newValue ? ({
                                    NAME: "AddEventVariantTriggerSource",
                                    VAL: [
                                      variantIdentifier,
                                      currentTrigger.id,
                                      sourceId
                                    ]
                                  }) : ({
                                    NAME: "RemoveEventVariantTriggerSource",
                                    VAL: [
                                      variantIdentifier,
                                      currentTrigger.id,
                                      sourceId
                                    ]
                                  }),
                              {
                                eventId: variantIdentifier.baseEventId,
                                triggerId: currentTrigger.id,
                                triggerQuery: currentTrigger.id,
                                eventVariantId: variantIdentifier.variantId,
                                eventVariantQuery: variantIdentifier.variantId
                              }
                            ]]
                        ]);
            } else {
              return Curry.app(sendActions, [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          (function (branch) {
                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                              AnalyticsRe.eventTriggerConfiguredOnEvent($$event.id, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, newValue ? "AddSource" : "RemoveSource", "Sources", $$event.triggers.length, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                            }),
                          undefined,
                          [[
                              newValue ? ({
                                    NAME: "AddTriggerSource",
                                    VAL: [
                                      $$event.id,
                                      currentTrigger.id,
                                      sourceId
                                    ]
                                  }) : ({
                                    NAME: "RemoveTriggerSource",
                                    VAL: [
                                      $$event.id,
                                      currentTrigger.id,
                                      sourceId
                                    ]
                                  }),
                              {
                                eventId: $$event.id,
                                eventQuery: $$event.id,
                                triggerId: currentTrigger.id,
                                triggerQuery: currentTrigger.id
                              }
                            ]]
                        ]);
            }
          }));
  };
  var onUpdateContent = function (content) {
    Belt_Option.forEach(currentTrigger, (function (currentTrigger) {
            if (inDraftMode) {
              return Curry._1(setDraft, (function (draft) {
                            return Belt_Option.map(draft, (function (draft) {
                                          return {
                                                  id: draft.id,
                                                  sources: draft.sources,
                                                  description: draft.description,
                                                  content: content
                                                };
                                        }));
                          }));
            } else if (variantIdentifier !== undefined) {
              return Curry.app(sendActions, [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          (function (branch) {
                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                              AnalyticsRe.eventTriggerConfiguredOnVariant(variantIdentifier.baseEventId, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, content === undefined ? "RemoveImage" : "AddImage", "Image", $$event.triggers.length, variantIdentifier.variantId, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                            }),
                          undefined,
                          [[
                              {
                                NAME: "UpdateEventVariantTriggerContent",
                                VAL: [
                                  variantIdentifier,
                                  currentTrigger.id,
                                  content
                                ]
                              },
                              {
                                eventId: variantIdentifier.baseEventId,
                                triggerId: currentTrigger.id,
                                triggerQuery: currentTrigger.id,
                                eventVariantId: variantIdentifier.variantId,
                                eventVariantQuery: variantIdentifier.variantId
                              }
                            ]]
                        ]);
            } else {
              return Curry.app(sendActions, [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          (function (branch) {
                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                              AnalyticsRe.eventTriggerConfiguredOnEvent($$event.id, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, content === undefined ? "RemoveImage" : "AddImage", "Image", $$event.triggers.length, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                            }),
                          undefined,
                          [[
                              {
                                NAME: "UpdateTriggerContent",
                                VAL: [
                                  $$event.id,
                                  currentTrigger.id,
                                  content
                                ]
                              },
                              {
                                eventId: $$event.id,
                                eventQuery: $$event.id,
                                triggerId: currentTrigger.id,
                                triggerQuery: currentTrigger.id
                              }
                            ]]
                        ]);
            }
          }));
  };
  var tmp;
  if (currentTrigger !== undefined) {
    var tmp$1;
    if (viewerCanEdit) {
      if (inDraftMode) {
        var disabled = uploadStatus === "Uploading" || (
          QuillDelta.toStr(new QuillDelta$1(JSON.parse(currentTrigger.description))) !== "" || Belt_Option.isSome(currentTrigger.content) ? false : true
        );
        tmp$1 = React.createElement("footer", {
              className: sidebarFooter(false)
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: /* [] */0
                      })
                }, React.createElement(Button.make, {
                      disabled: disabled,
                      label: "Create Trigger",
                      loading: uploadStatus === "Uploading",
                      onClick: (function (param) {
                          onCreate(undefined, currentTrigger);
                        }),
                      size: "large"
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), inDraftMode ? React.createElement(ContextMenu.make, {
                        button: React.createElement(Button.make, {
                              disabled: disabled || uploadStatus === "Uploading",
                              icon: "more",
                              size: "large",
                              style: "outline"
                            }),
                        options: [{
                            NAME: "Option",
                            VAL: {
                              label: "Create and add another",
                              onClick: (function (param) {
                                  onCreate(true, currentTrigger);
                                })
                            }
                          }]
                      }) : null), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      Curry._1(setDraft, (function (param) {
                              
                            }));
                      Curry._1(onClose, undefined);
                    }),
                  size: "Small",
                  children: "Cancel"
                }));
      } else {
        tmp$1 = React.createElement("footer", {
              className: sidebarFooter(true)
            }, React.createElement(TextButton.make, {
                  onClick: onNewTrigger,
                  style: "Magenta",
                  size: "Small",
                  children: null
                }, React.createElement(TriggerFullscreenModal$Lightning, {
                      color: Styles.Color.avoPink
                    }), "+ New Trigger"));
      }
    } else {
      tmp$1 = null;
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(TriggerModalSources.make, {
              currentTrigger: currentTrigger,
              event: $$event,
              inDraftMode: inDraftMode,
              modelSources: modelSources,
              onRemove: onRemove,
              onUpdate: onUpdateSource,
              sources: currentTrigger.sources,
              viewerCanEdit: viewerCanEdit,
              key: "$sources-" + triggerId
            }), React.createElement(Spacer.make, {
              height: 16
            }), React.createElement(TriggerModalDescription.make, {
              event: $$event,
              description: currentTrigger.description,
              onUpdate: onUpdateDescription,
              variantIdentifier: variantIdentifier,
              triggerId: currentTrigger.id,
              schemaBundle: schemaBundle,
              viewerCanEdit: viewerCanEdit,
              key: "$description-" + triggerId
            }), React.createElement(Spacer.make, {
              height: 0,
              grow: 1.0
            }), tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement(Portal.make, {
              children: React.createElement(React$1.motion.section, {
                    animate: {
                      opacity: 1,
                      y: 0
                    },
                    transition: {
                      duration: 0.15
                    },
                    initial: {
                      opacity: 0,
                      y: 50
                    },
                    exit: {
                      opacity: 0,
                      y: 50
                    },
                    className: wrapper,
                    key: "trigger-modal",
                    children: null
                  }, currentTrigger !== undefined ? React.createElement(TriggerModalImage.make, {
                          content: currentTrigger.content,
                          onUpdateContent: onUpdateContent,
                          setUploadStatus: match$2[1],
                          triggerId: currentTrigger.id,
                          uploadStatus: uploadStatus,
                          viewerCanEdit: viewerCanEdit,
                          key: triggerId
                        }) : null, inDraftMode ? null : React.createElement("button", {
                          className: closeButton,
                          onClick: (function (param) {
                              Curry._1(onClose, undefined);
                            })
                        }, React.createElement(Icons.ArrowLeft.make, {
                              size: 10,
                              color: Styles.Color.avoPink
                            }), React.createElement(Spacer.make, {
                              width: 4
                            }), React.createElement($$Text.make, {
                              weight: "Semi",
                              children: "Back to event"
                            })), React.createElement("aside", {
                        className: sidebar
                      }, React.createElement(TriggerModalNavigation.make, {
                            currentIndex: currentTriggerIndex,
                            draftsToGo: 0,
                            eventName: eventVariant !== undefined ? $$event.name + " - " + eventVariant.nameSuffix : $$event.name,
                            inDraftMode: inDraftMode,
                            onNavigate: (function (newIndex) {
                                Belt_Option.forEach(Belt_Array.get($$event.triggers, newIndex), (function (param) {
                                        Curry._1(routeToTrigger, param.id);
                                      }));
                              }),
                            total: $$event.triggers.length,
                            key: triggerId
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), tmp))
            });
}

var make = TriggerFullscreenModal;

export {
  Lightning ,
  Style ,
  updateDescription ,
  debouncedUpdateDescription ,
  make ,
}
/* wrapper Not a pure module */
