// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DomainStore from "./DomainStore.mjs";
import * as BranchStatus from "./externals/BranchStatus.mjs";
import * as ProfilePhoto from "./ProfilePhoto.mjs";
import * as Collaborators from "./Collaborators.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as BranchActionBox__Components from "./BranchActionBox__Components.mjs";

function getUserRepresentation(user) {
  var name = user.name;
  if (name == null) {
    return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.email), "Unknown User");
  } else {
    return name;
  }
}

function BranchActionBox__Reviewers$Reviewer$Approved(Props) {
  var approveActions = Props.approveActions;
  var isRequestedReviewer = Props.isRequestedReviewer;
  var reviewerId = Props.reviewerId;
  var reviewerIsAdmin = Props.reviewerIsAdmin;
  var reviewerIsStakeholder = Props.reviewerIsStakeholder;
  var reviewerIsViewer = Props.reviewerIsViewer;
  var user = Props.user;
  var bracketText = reviewerIsViewer ? " (you)" : (
      reviewerIsAdmin ? (
          reviewerIsStakeholder ? " (admin and impacted stakeholder)" : " (admin)"
        ) : (
          reviewerIsStakeholder ? " (impacted stakeholder)" : ""
        )
    );
  var isApprovedByReviewer = Belt_List.some(approveActions, (function (a) {
          return a.createdBy === reviewerId;
        }));
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: getUserRepresentation(user) + bracketText
                }), React.createElement(Spacer.make, {
                  width: 4
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: isApprovedByReviewer ? Styles.Color.darkGreen : Styles.Color.light11,
                  children: isRequestedReviewer && !isApprovedByReviewer ? "assigned for review" : "approved these changes"
                }));
}

var Approved = {
  make: BranchActionBox__Reviewers$Reviewer$Approved
};

function BranchActionBox__Reviewers$Reviewer$ChangesRequested(Props) {
  var changeAction = Props.changeAction;
  var isRequestedReviewer = Props.isRequestedReviewer;
  var reviewerId = Props.reviewerId;
  var reviewerIsAdmin = Props.reviewerIsAdmin;
  var reviewerIsStakeholder = Props.reviewerIsStakeholder;
  var reviewerIsViewer = Props.reviewerIsViewer;
  var user = Props.user;
  var bracketText = reviewerIsViewer ? " (you)" : (
      reviewerIsAdmin ? (
          reviewerIsStakeholder ? " (admin and impacted stakeholder)" : " (admin)"
        ) : (
          reviewerIsStakeholder ? " (impacted stakeholder)" : ""
        )
    );
  var changesRequestedByUser = changeAction.createdBy === reviewerId;
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: getUserRepresentation(user) + bracketText
                }), React.createElement(Spacer.make, {
                  width: 4
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: changesRequestedByUser ? Styles.Color.darkRed : Styles.Color.light11,
                  children: isRequestedReviewer && !changesRequestedByUser ? "assigned for review" : "requested changes"
                }));
}

var ChangesRequested = {
  make: BranchActionBox__Reviewers$Reviewer$ChangesRequested
};

function BranchActionBox__Reviewers$Reviewer(Props) {
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var isRequestedReviewerOpt = Props.isRequestedReviewer;
  var reviewerId = Props.reviewerId;
  var viewerId = Props.viewerId;
  var isRequestedReviewer = isRequestedReviewerOpt !== undefined ? isRequestedReviewerOpt : true;
  var match = WorkspaceContext.use();
  var userDomains = DomainStore.useMemberDomains(reviewerId);
  var domainEventsAndEventVariants = DomainStore.useEventsAndEventVariants();
  var userDomainKeys = React.useMemo((function (param) {
          return Belt_SetString.fromArray(Curry._1(Domains.keys, userDomains));
        }), [userDomains]);
  var reviewerIsViewer = reviewerId === viewerId;
  var member = Belt_List.getBy(match.members, (function (m) {
          return m.id === reviewerId;
        }));
  var reviewerIsAdmin = member !== undefined ? Caml_obj.equal(Models.Role.tFromJs(Caml_option.valFromOption(member).role), "Admin") : false;
  var reviewerIsStakeholder = React.useMemo((function (param) {
          return Belt_MapString.someU(domainEventsAndEventVariants, (function (id, param) {
                        if (!(Belt_MapString.has(changes.changed, id) || Belt_MapString.has(changes.new, id) || Belt_MapString.has(changes.removed, id))) {
                          return false;
                        }
                        var domains = Belt_MapString.get(domainEventsAndEventVariants, id);
                        if (domains !== undefined) {
                          return Curry._2(Domains.some, domains, (function (param) {
                                        return Belt_SetString.has(userDomainKeys, param.id);
                                      }));
                        } else {
                          return false;
                        }
                      }));
        }), [
        userDomainKeys,
        domainEventsAndEventVariants,
        changes
      ]);
  var user = FirebaseFetcherHooks.useUser(reviewerId);
  var tmp;
  if (typeof user === "object" && user.NAME === "Success") {
    var user$1 = user.VAL;
    var exit = 0;
    if (typeof branchStatus !== "object") {
      exit = 1;
    } else {
      switch (branchStatus.TAG) {
        case "ChangesRequested" :
            tmp = React.createElement(BranchActionBox__Reviewers$Reviewer$ChangesRequested, {
                  changeAction: branchStatus._0,
                  isRequestedReviewer: isRequestedReviewer,
                  reviewerId: reviewerId,
                  reviewerIsAdmin: reviewerIsAdmin,
                  reviewerIsStakeholder: reviewerIsStakeholder,
                  reviewerIsViewer: reviewerIsViewer,
                  user: user$1
                });
            break;
        case "Approved" :
            tmp = React.createElement(BranchActionBox__Reviewers$Reviewer$Approved, {
                  approveActions: branchStatus._0,
                  isRequestedReviewer: isRequestedReviewer,
                  reviewerId: reviewerId,
                  reviewerIsAdmin: reviewerIsAdmin,
                  reviewerIsStakeholder: reviewerIsStakeholder,
                  reviewerIsViewer: reviewerIsViewer,
                  user: user$1
                });
            break;
        default:
          exit = 1;
      }
    }
    if (exit === 1) {
      tmp = React.createElement(BranchActionBox__Reviewers$Reviewer$Approved, {
            approveActions: /* [] */0,
            isRequestedReviewer: isRequestedReviewer,
            reviewerId: reviewerId,
            reviewerIsAdmin: reviewerIsAdmin,
            reviewerIsStakeholder: reviewerIsStakeholder,
            reviewerIsViewer: reviewerIsViewer,
            user: user$1
          });
    }
    
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(16)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(20)),
                      tl: {
                        hd: Css.borderTop(Css.px(1), Css.solid, Styles.Color.light04),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.justifyContent(Css.spaceBetween),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems(Css.center),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(ProfilePhoto.FromUserId.make, {
                      id: reviewerId,
                      size: 24,
                      withBorder: true
                    }), React.createElement(Spacer.make, {
                      width: 15
                    }), tmp));
}

var Reviewer = {
  getUserRepresentation: getUserRepresentation,
  Approved: Approved,
  ChangesRequested: ChangesRequested,
  make: BranchActionBox__Reviewers$Reviewer
};

function BranchActionBox__Reviewers$Info$Approved(Props) {
  var approveActions = Props.approveActions;
  var requestedReviewerIds = Props.requestedReviewerIds;
  var match = WorkspaceContext.use();
  var match$1 = match.settings;
  var members = match.members;
  var numApprovals = Belt_List.length(approveActions);
  var adminApprovals = React.useMemo((function (param) {
          return Belt_List.keep(approveActions, (function (a) {
                        var member = Belt_List.getBy(members, (function (m) {
                                return m.id === a.createdBy;
                              }));
                        if (member !== undefined) {
                          return Caml_obj.equal(Models.Role.tFromJs(Caml_option.valFromOption(member).role), "Admin");
                        } else {
                          return false;
                        }
                      }));
        }), [
        approveActions,
        members
      ]);
  var numNonEditorApprovals = numApprovals - Belt_List.size(adminApprovals) | 0;
  var numPendingApprovals = Belt_SetString.size(Belt_SetString.diff(Belt_SetString.fromArray(requestedReviewerIds), Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(approveActions, (function (prim) {
                          return prim.createdBy;
                        }))))));
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(16)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, match$1.requireAdminApproval ? React.createElement(React.Fragment, undefined, numNonEditorApprovals > 0 ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.gap(Css.px(4)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement(Icon.make, {
                              type_: "checkmark",
                              size: "small",
                              color: Styles.Color.light11
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light11,
                              children: AvoUtils.plural(undefined, undefined, numNonEditorApprovals, "editor approval")
                            })) : null, Belt_List.size(adminApprovals) > 0 ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.gap(Css.px(4)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement(Icon.make, {
                              type_: "checkmark",
                              size: "small",
                              color: Styles.Color.darkGreen
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light11,
                              children: AvoUtils.plural(undefined, undefined, Belt_List.size(adminApprovals), "admin approval")
                            })) : null) : (
                numApprovals > 0 ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.gap(Css.px(4)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement(Icon.make, {
                            type_: "checkmark",
                            size: "small",
                            color: Styles.Color.darkGreen
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: AvoUtils.plural(undefined, undefined, numApprovals, "approval")
                          })) : null
              ), requestedReviewerIds.length === 0 ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement(Icon.make, {
                        type_: "user",
                        size: "small",
                        color: Styles.Color.light11
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light11,
                        children: "0 reviews requested"
                      })) : (
                numApprovals < match$1.numRequiredApprovals ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.gap(Css.px(4)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement(Icon.make, {
                            type_: "user",
                            size: "small",
                            color: Styles.Color.light11
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: AvoUtils.plural(undefined, undefined, numPendingApprovals, "pending review request")
                          })) : null
              ));
}

var Approved$1 = {
  make: BranchActionBox__Reviewers$Info$Approved
};

function BranchActionBox__Reviewers$Info$ChangesRequested(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement(Icon.make, {
                  type_: "warningCircle",
                  size: "small",
                  color: Styles.Color.darkRed
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: "Changes requested"
                }));
}

var ChangesRequested$1 = {
  make: BranchActionBox__Reviewers$Info$ChangesRequested
};

function BranchActionBox__Reviewers$Info(Props) {
  var branchStatus = Props.branchStatus;
  var requestedReviewerIds = Props.requestedReviewerIds;
  var tmp;
  var exit = 0;
  if (typeof branchStatus !== "object") {
    exit = 1;
  } else {
    switch (branchStatus.TAG) {
      case "ChangesRequested" :
          tmp = React.createElement(BranchActionBox__Reviewers$Info$ChangesRequested, {});
          break;
      case "Approved" :
          tmp = React.createElement(BranchActionBox__Reviewers$Info$Approved, {
                approveActions: branchStatus._0,
                requestedReviewerIds: requestedReviewerIds
              });
          break;
      default:
        exit = 1;
    }
  }
  if (exit === 1) {
    tmp = React.createElement(BranchActionBox__Reviewers$Info$Approved, {
          approveActions: /* [] */0,
          requestedReviewerIds: requestedReviewerIds
        });
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems(Css.center),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(8)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, tmp);
}

var Info = {
  Approved: Approved$1,
  ChangesRequested: ChangesRequested$1,
  make: BranchActionBox__Reviewers$Info
};

function BranchActionBox__Reviewers(Props) {
  var branchCreatorId = Props.branchCreatorId;
  var branchId = Props.branchId;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var role = Props.role;
  var match = ViewerContext.use();
  var viewerId = match.id;
  var match$1 = WorkspaceContext.use();
  var collaborators = Collaborators.useCollaborators(branchId);
  var approveActions = React.useMemo((function (param) {
          if (typeof branchStatus !== "object" || branchStatus.TAG !== "Approved") {
            return /* [] */0;
          } else {
            return branchStatus._0;
          }
        }), [branchStatus]);
  var requestedReviewerIds = Belt_List.toArray(Belt_List.map(Belt_List.keep(collaborators, (function (c) {
                  return Caml_obj.equal(Caml_option.undefined_to_opt(c.isReviewer), true);
                })), (function (prim) {
              return prim.id;
            })));
  var unrequestedReviewerIds;
  if (typeof branchStatus !== "object") {
    unrequestedReviewerIds = [];
  } else {
    switch (branchStatus.TAG) {
      case "ChangesRequested" :
          unrequestedReviewerIds = Belt_SetString.toArray(Belt_SetString.diff(Belt_SetString.fromArray([branchStatus._0.createdBy]), Belt_SetString.fromArray(requestedReviewerIds)));
          break;
      case "Approved" :
          var approversSet = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(branchStatus._0, (function (prim) {
                          return prim.createdBy;
                        }))));
          unrequestedReviewerIds = Belt_SetString.toArray(Belt_SetString.diff(approversSet, Belt_SetString.fromArray(requestedReviewerIds)));
          break;
      default:
        unrequestedReviewerIds = [];
    }
  }
  var numApprovals = Belt_List.length(approveActions);
  return React.createElement("div", undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(16)),
                        tl: {
                          hd: Css.borderTop(Css.px(1), Css.solid, Styles.Color.light06),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.justifyContent(Css.spaceBetween),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement(BranchActionBox__Reviewers$Info, {
                      branchStatus: branchStatus,
                      requestedReviewerIds: requestedReviewerIds
                    }), React.createElement(BranchActionBox__Components.EditReviewers.make, {
                      type_: "textButton",
                      branchId: branchId,
                      branchCreatorId: branchCreatorId,
                      branchInteractionLocation: "BottomBranchActionBox",
                      branchStatus: BranchStatus.toAnalytics(branchStatus),
                      collaborators: collaborators,
                      numApprovals: numApprovals,
                      requireAdminApproval: match$1.settings.requireAdminApproval,
                      requestedReviewerIds: requestedReviewerIds,
                      role: role
                    })), Belt_Array.map(requestedReviewerIds, (function (reviewerId) {
                    return React.createElement(BranchActionBox__Reviewers$Reviewer, {
                                branchStatus: branchStatus,
                                changes: changes,
                                reviewerId: reviewerId,
                                viewerId: viewerId,
                                key: reviewerId
                              });
                  })), Belt_Array.map(unrequestedReviewerIds, (function (reviewerId) {
                    return React.createElement(BranchActionBox__Reviewers$Reviewer, {
                                branchStatus: branchStatus,
                                changes: changes,
                                isRequestedReviewer: false,
                                reviewerId: reviewerId,
                                viewerId: viewerId,
                                key: reviewerId
                              });
                  })));
}

var make = BranchActionBox__Reviewers;

export {
  Reviewer ,
  Info ,
  make ,
}
/* Css Not a pure module */
