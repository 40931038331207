// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as FetchModel from "./FetchModel.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ActionsReducer from "./actionsReducer.mjs";
import * as ActionDiff__Async from "../../shared/ActionDiff__Async.mjs";
import * as BranchStatusModel from "../../shared/models/BranchStatusModel.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

async function calculateRebasedBranchModel(branchModel, masterModelBeforeBranch, currentMasterOrMasterAtBranchMergeOrClose) {
  var mainBranchChanges = await ActionDiff__Async.diff(undefined, undefined, masterModelBeforeBranch, currentMasterOrMasterAtBranchMergeOrClose, undefined);
  return [
          mainBranchChanges,
          Belt_List.reduceU(mainBranchChanges, branchModel, ActionsReducer.reduce)
        ];
}

function getFromAndToModels(branchStatus, masterModel, branchModel, maybeBranchModelJson, rebasedBranchModel, masterModelBeforeBranch, maybeMasterModelBeforeBranchJson) {
  if (typeof branchStatus === "object" && branchStatus.TAG === "Merged") {
    return {
            from: {
              model: masterModelBeforeBranch,
              json: maybeMasterModelBeforeBranchJson
            },
            to: {
              model: branchModel,
              json: maybeBranchModelJson
            }
          };
  }
  return {
          from: {
            model: masterModel,
            json: undefined
          },
          to: {
            model: rebasedBranchModel,
            json: undefined
          }
        };
}

function getDiff(fromModel, toModel, masterModelBeforeBranch, branchModel, calculationId, signal) {
  return $$Promise.$$catch(Promise.all([
                    ActionDiff__Async.diff(signal, undefined, fromModel, toModel, undefined),
                    ActionDiff__Async.diff(signal, undefined, masterModelBeforeBranch, branchModel, undefined)
                  ]).then(function (param) {
                  return {
                          NAME: "success",
                          VAL: {
                            calculationId: calculationId,
                            fromModel: fromModel,
                            toModel: toModel,
                            rawBranchChanges: param[1],
                            rebasedBranchChanges: param[0]
                          }
                        };
                }), (function (_e) {
                return Promise.resolve("aborted");
              }));
}

function useFromAndToModels(mode, schemaId, branchId, branchModel, latestBranchAction, masterModel, latestMasterAction, updateModelStoreDiffInformation) {
  var match = FirebaseFetcherHooks.useBranchLifecycleActions(schemaId, branchId, mode, undefined);
  var setBranchStatusActions = match[3];
  var maybeCloseAction = match[2];
  var maybeMergeAction = match[1];
  var maybeBranchOpenAction = match[0];
  var branchStatus = React.useMemo((function (param) {
          if (maybeBranchOpenAction !== undefined) {
            return BranchStatusModel.get(Caml_option.valFromOption(maybeBranchOpenAction), maybeMergeAction, maybeCloseAction, setBranchStatusActions);
          } else {
            return "Loading";
          }
        }), [
        maybeBranchOpenAction,
        maybeMergeAction,
        maybeCloseAction,
        setBranchStatusActions
      ]);
  var match$1 = branchModel.branchPointer;
  var match$2 = branchModel.branchPointer;
  var fetchStateMainBeforeBranch = FetchModel.use(match$1 !== undefined ? undefined : Belt_Option.flatMap(maybeBranchOpenAction, (function (branchOpenAction) {
                return Models.toDateOption(Models.Action.getCreatedAt(branchOpenAction));
              })), match$2 !== undefined ? Caml_option.some(new Date(match$2[1])) : undefined, mode, "master");
  var match$3 = React.useMemo((function (param) {
          switch (fetchStateMainBeforeBranch.TAG) {
            case "Loading" :
            case "Error" :
                return [
                        undefined,
                        undefined
                      ];
            case "Result" :
                return [
                        fetchStateMainBeforeBranch._0,
                        fetchStateMainBeforeBranch._2
                      ];
            
          }
        }), [fetchStateMainBeforeBranch]);
  var maybeMainModelBeforeBranchLatestAction = match$3[1];
  var maybeMainModelBeforeBranch = match$3[0];
  var branchMergeOrCloseTimestamp = BranchStatusModel.getBranchMergeOrCloseTimestamp(maybeMergeAction, maybeCloseAction);
  var fetchStateMainAtMergeOrClose = FetchModel.use(undefined, branchMergeOrCloseTimestamp, Belt_Option.mapWithDefault(branchMergeOrCloseTimestamp, "Skip", (function (param) {
              return "Fetch";
            })), "master");
  var match$4;
  switch (fetchStateMainAtMergeOrClose.TAG) {
    case "Loading" :
    case "Error" :
        match$4 = [
          undefined,
          undefined
        ];
        break;
    case "Result" :
        match$4 = [
          fetchStateMainAtMergeOrClose._0,
          fetchStateMainAtMergeOrClose._2
        ];
        break;
    
  }
  var match$5 = branchMergeOrCloseTimestamp !== undefined ? [
      match$4[0],
      match$4[1]
    ] : [
      masterModel,
      latestMasterAction
    ];
  var maybeCurrentMasterOrMasterAtBranchMergeOrCloseLatestAction = match$5[1];
  var maybeCurrentMasterOrMasterAtBranchMergeOrClose = match$5[0];
  var match$6 = React.useState(function (param) {
        
      });
  var setRebasedBranchInfo = match$6[1];
  var maybeRebasedBranchInfo = match$6[0];
  var getMaybeActionId = function (action) {
    return Belt_Option.getWithDefault(Belt_Option.map(action, Models.Action.getId), "");
  };
  var getRequestId = function (param) {
    return getMaybeActionId(maybeMainModelBeforeBranchLatestAction) + "-" + getMaybeActionId(maybeCurrentMasterOrMasterAtBranchMergeOrCloseLatestAction) + "-" + getMaybeActionId(latestBranchAction);
  };
  var requestId = React.useMemo((function (param) {
          return getRequestId();
        }), [
        maybeMainModelBeforeBranchLatestAction,
        maybeCurrentMasterOrMasterAtBranchMergeOrCloseLatestAction,
        latestBranchAction
      ]);
  var currentRequestId = React.useRef("");
  React.useEffect((function (param) {
          if (maybeMainModelBeforeBranch !== undefined && maybeCurrentMasterOrMasterAtBranchMergeOrClose !== undefined) {
            var thisRequestId = getRequestId();
            currentRequestId.current = thisRequestId;
            calculateRebasedBranchModel(branchModel, maybeMainModelBeforeBranch, maybeCurrentMasterOrMasterAtBranchMergeOrClose).then(function (param) {
                  if (thisRequestId !== currentRequestId.current) {
                    return ;
                  }
                  var rebasedBranchModel = param[1];
                  var mainBranchChanges = param[0];
                  Curry._1(setRebasedBranchInfo, (function (param) {
                          return {
                                  rebasedBranchModel: rebasedBranchModel,
                                  mainBranchChanges: mainBranchChanges
                                };
                        }));
                });
          }
          
        }), [
        requestId,
        branchModel
      ]);
  var maybeFromAndToModels = React.useMemo((function (param) {
          if (maybeRebasedBranchInfo !== undefined && maybeMainModelBeforeBranch !== undefined) {
            return getFromAndToModels(branchStatus, masterModel, branchModel, undefined, maybeRebasedBranchInfo.rebasedBranchModel, maybeMainModelBeforeBranch, undefined);
          }
          
        }), [
        maybeRebasedBranchInfo,
        maybeMainModelBeforeBranch,
        branchStatus,
        masterModel,
        branchModel
      ]);
  React.useEffect((function (param) {
          if (maybeFromAndToModels !== undefined && maybeRebasedBranchInfo !== undefined && maybeMainModelBeforeBranch !== undefined) {
            Curry._1(updateModelStoreDiffInformation, {
                  fromAndToModels: maybeFromAndToModels,
                  rebasedBranchModel: maybeRebasedBranchInfo.rebasedBranchModel,
                  mainModelBeforeBranch: maybeMainModelBeforeBranch,
                  mainBranchChanges: maybeRebasedBranchInfo.mainBranchChanges
                });
          } else {
            Curry._1(updateModelStoreDiffInformation, undefined);
          }
        }), [maybeFromAndToModels]);
  React.useEffect((function (param) {
          if (Belt_Option.isSome(mode) && Caml_obj.notequal(mode, "Fetch")) {
            Curry._1(updateModelStoreDiffInformation, undefined);
          }
          
        }), [mode]);
  return maybeFromAndToModels;
}

export {
  calculateRebasedBranchModel ,
  getFromAndToModels ,
  getDiff ,
  useFromAndToModels ,
}
/* react Not a pure module */
