// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as LongTask from "../app/src/LongTask.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ActionDiff from "./ActionDiff.mjs";
import * as ModelUtils from "../app/src/ModelUtils.mjs";
import * as ActionsReducer from "../app/src/actionsReducer.mjs";
import * as TrackingPlanMappedModel from "../model/src/TrackingPlanMappedModel.mjs";

async function diffEvents(signal, fromMappedEvents, fromMappedEventsArchive, toMappedEvents, toMappedEventsKeyedByLocalId, toMappedEventsArchiveKeyedByLocalId, toMappedEventsArchive, toGlobalRequirements, toMappedProperties, toMappedPropertyBundles, onError) {
  var toEventsArr = Curry._1(TrackingPlanMappedModel.Events.toArray, toMappedEvents);
  var match = await LongTask.$$Array.map(undefined, signal, undefined, toEventsArr, (function ($$event) {
          return ActionDiff.getNewAndUpdatedEventActions(fromMappedEvents, fromMappedEventsArchive, toMappedProperties, toMappedPropertyBundles, toGlobalRequirements, $$event);
        }));
  var newAndModified;
  if (typeof match === "object") {
    newAndModified = Belt_List.flatten(Belt_List.fromArray(match.VAL));
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  var fromEventsArr = Curry._1(TrackingPlanMappedModel.Events.toArray, fromMappedEvents);
  var match$1 = await LongTask.$$Array.map(undefined, signal, undefined, fromEventsArr, (function ($$event) {
          return ActionDiff.getRemovedEventActions(toMappedEvents, toMappedEventsKeyedByLocalId, toMappedEventsArchive, toMappedEventsArchiveKeyedByLocalId, toMappedPropertyBundles, toMappedProperties, toGlobalRequirements, onError, $$event);
        }));
  var removed;
  if (typeof match$1 === "object") {
    removed = Belt_List.flatten(Belt_List.fromArray(match$1.VAL));
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  return Belt_List.concat(newAndModified, removed);
}

async function diffProperties(signal, fromMappedProperties, toMappedProperties, toMappedPropertiesKeyedByLocalId, fromMappedPropertiesArchive, toMappedPropertiesArchive, toMappedEvents, onError, fromModel, toModel, hasMigratedToEventSpecificAllowedValues) {
  var match = await LongTask.$$Array.map(120, signal, undefined, Curry._1(TrackingPlanMappedModel.Properties.toArray, toMappedProperties), (function (property) {
          return ActionDiff.getNewAndUpdatedPropertyActions(fromMappedProperties, fromMappedPropertiesArchive, fromModel, toModel, toMappedEvents, hasMigratedToEventSpecificAllowedValues, property);
        }));
  var newAndUpdated;
  if (typeof match === "object") {
    newAndUpdated = Belt_List.flatten(Belt_List.fromArray(match.VAL));
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  var match$1 = await LongTask.$$Array.map(120, undefined, undefined, Curry._1(TrackingPlanMappedModel.Properties.toArray, fromMappedProperties), (function (property) {
          return ActionDiff.getRemovedPropertyActions(fromModel, toModel, toMappedProperties, toMappedPropertiesKeyedByLocalId, toMappedPropertiesArchive, toMappedEvents, hasMigratedToEventSpecificAllowedValues, onError, property);
        }));
  var removed;
  if (typeof match$1 === "object") {
    removed = Belt_List.flatten(Belt_List.fromArray(match$1.VAL));
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  return Belt_List.concat(newAndUpdated, removed);
}

async function diffSources(signal, fromSources, toSources, fromSourcesArchive, toSourcesArchive, onError) {
  var match = await LongTask.List.map(120, signal, undefined, toSources, (function (toSource) {
          return ActionDiff.getNewAndUpdatedSourceActions(fromSources, fromSourcesArchive, toSource);
        }));
  var newAndUpdated;
  if (typeof match === "object") {
    newAndUpdated = Belt_List.flatten(match.VAL);
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  var match$1 = await LongTask.List.map(120, signal, undefined, fromSources, (function (fromSource) {
          return ActionDiff.getRemovedSourceActions(toSources, toSourcesArchive, onError, fromSource);
        }));
  var removed;
  if (typeof match$1 === "object") {
    removed = Belt_List.flatten(match$1.VAL);
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  return Belt_List.concat(newAndUpdated, removed);
}

async function diffDestinations(signal, fromDestinations, toDestinations, fromDestinationsArchive, toDestinationsArchive, onError) {
  var match = await LongTask.List.map(120, signal, undefined, toDestinations, (function (toDestination) {
          return ActionDiff.getNewAndUpdatedDestinationActions(fromDestinations, fromDestinationsArchive, toDestination);
        }));
  var newAndUpdated;
  if (typeof match === "object") {
    newAndUpdated = Belt_List.flatten(match.VAL);
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  var match$1 = await LongTask.List.map(120, signal, undefined, fromDestinations, (function (fromDestination) {
          return ActionDiff.getRemovedDestinationActions(toDestinations, toDestinationsArchive, onError, fromDestination);
        }));
  var removed;
  if (typeof match$1 === "object") {
    removed = Belt_List.flatten(match$1.VAL);
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  return Belt_List.concat(newAndUpdated, removed);
}

async function diffMetrics(signal, fromMappedMetrics, toMappedMetrics, fromMappedMetricsArchive, toMappedMetricsArchive, toMappedEvents, onError) {
  var match = await LongTask.$$Array.map(120, signal, undefined, Curry._1(TrackingPlanMappedModel.Metrics.toArray, toMappedMetrics), (function (toMetric) {
          return ActionDiff.getNewAndUpdatedMetricsActions(fromMappedMetrics, fromMappedMetricsArchive, toMappedEvents, toMetric);
        }));
  var newAndUpdated;
  if (typeof match === "object") {
    newAndUpdated = Belt_List.flatten(Belt_List.fromArray(match.VAL));
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  var match$1 = await LongTask.$$Array.map(120, signal, undefined, Curry._1(TrackingPlanMappedModel.Metrics.toArray, fromMappedMetrics), (function (fromMetric) {
          return ActionDiff.getRemovedMetricsActions(toMappedEvents, toMappedMetrics, toMappedMetricsArchive, onError, fromMetric);
        }));
  var removed;
  if (typeof match$1 === "object") {
    removed = Belt_List.flatten(Belt_List.fromArray(match$1.VAL));
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  return Belt_List.concat(newAndUpdated, removed);
}

async function diffGoals(signal, fromGoals, toGoals, fromGoalsArchive, toGoalsArchive, toMappedEvents, onError) {
  var match = await LongTask.List.map(120, signal, undefined, toGoals, (function (toGoal) {
          return ActionDiff.getNewAndUpdatedGoalActions(fromGoals, fromGoalsArchive, toMappedEvents, toGoal);
        }));
  var newAndUpdated;
  if (typeof match === "object") {
    newAndUpdated = Belt_List.flatten(match.VAL);
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  var match$1 = await LongTask.List.map(120, signal, undefined, fromGoals, (function (fromGoal) {
          return ActionDiff.getRemovedGoalActions(toGoals, toGoalsArchive, onError, toMappedEvents, fromGoal);
        }));
  var removed;
  if (typeof match$1 === "object") {
    removed = Belt_List.flatten(match$1.VAL);
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  var reorders = Belt_List.map(ActionDiff.diffListOrder(fromGoals, toGoals, undefined, (function (goals, goal) {
              return Belt_List.get(Belt_List.keepMap(goals, (function (param) {
                                if (param[1].id === goal.id) {
                                  return param[0];
                                }
                                
                              })), 0);
            }), undefined), (function (change) {
          var match = change.VAL;
          var match$1 = match[1];
          var goal = match[0];
          if (match$1 !== undefined) {
            return {
                    NAME: "ReorderGoalsV2",
                    VAL: [
                      goal.id,
                      match$1.id
                    ]
                  };
          } else {
            return {
                    NAME: "ReorderGoalsV2",
                    VAL: [
                      goal.id,
                      undefined
                    ]
                  };
          }
        }));
  return Belt_List.concatMany([
              newAndUpdated,
              reorders,
              removed
            ]);
}

async function diffPropertyGroups(signal, fromGroups, toGroups, fromGroupsArchive, toGroupsArchive, toMappedPropertyBundles, toGlobalRequirements, toMappedProperties) {
  var match = await LongTask.List.map(120, signal, undefined, toGroups, (function (toGroup) {
          return ActionDiff.getNewAndUpdatedPropertyBundleActions(fromGroups, fromGroupsArchive, toMappedPropertyBundles, toGlobalRequirements, toMappedProperties, toGroup);
        }));
  var newAndUpdated;
  if (typeof match === "object") {
    newAndUpdated = Belt_List.flatten(match.VAL);
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  var match$1 = await LongTask.List.map(120, signal, undefined, fromGroups, (function (fromGroup) {
          return ActionDiff.getRemovedPropertyBundleActions(toGroups, toGroupsArchive, toMappedProperties, toGlobalRequirements, fromGroup);
        }));
  var removed;
  if (typeof match$1 === "object") {
    removed = Belt_List.flatten(match$1.VAL);
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  return Belt_List.concat(newAndUpdated, removed);
}

async function diffRules(signal, fromRules, toRules, toModel) {
  var match = await LongTask.List.map(120, signal, undefined, toRules, (function (toRule) {
          return ActionDiff.getNewAndUpdatedRuleActions(fromRules, toRule);
        }));
  var newAndUpdated;
  if (typeof match === "object") {
    newAndUpdated = Belt_List.flatten(match.VAL);
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  var match$1 = await LongTask.List.filterMap(120, signal, undefined, fromRules, (function (param) {
          return ActionDiff.getRemovedRuleActions(toRules, param);
        }));
  var removed;
  if (typeof match$1 === "object") {
    removed = match$1.VAL;
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  var actions = Belt_List.concat(newAndUpdated, removed);
  var match$2 = await LongTask.List.filter(120, signal, undefined, actions, (function (param) {
          return ActionDiff.filterRuleActions(toModel, param);
        }));
  if (typeof match$2 === "object") {
    return match$2.VAL;
  }
  throw {
        RE_EXN_ID: LongTask.TaskAborted,
        Error: new Error()
      };
}

async function diffIntegrations(signal, fromIntegrations, toIntegrations) {
  var match = await LongTask.List.map(120, signal, undefined, toIntegrations, (function (toIntegration) {
          return ActionDiff.getNewAndUpdatedIntegrationActions(fromIntegrations, toIntegration);
        }));
  var newAndUpdated;
  if (typeof match === "object") {
    newAndUpdated = Belt_List.flatten(match.VAL);
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  var match$1 = await LongTask.List.filterMap(120, signal, undefined, fromIntegrations, (function (param) {
          return ActionDiff.getRemovedIntegrationActions(toIntegrations, param);
        }));
  var removed;
  if (typeof match$1 === "object") {
    removed = match$1.VAL;
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  return Belt_List.concat(newAndUpdated, removed);
}

async function diffGroupTypes(signal, fromGroupTypes, toGroupTypes, fromGroupTypesArchive, toGroupTypesArchive, onError) {
  var match = await LongTask.$$Array.map(120, signal, undefined, toGroupTypes, (function (toGroupType) {
          return ActionDiff.getNewAndUpdatedGroupTypeActions(fromGroupTypes, fromGroupTypesArchive, toGroupType);
        }));
  var newAndUpdated;
  if (typeof match === "object") {
    newAndUpdated = Belt_List.concatMany(match.VAL);
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  var match$1 = await LongTask.$$Array.map(120, signal, undefined, fromGroupTypes, (function (fromGroupType) {
          return ActionDiff.getRemovedGroupTypeActions(toGroupTypes, toGroupTypesArchive, onError, fromGroupType);
        }));
  var removed;
  if (typeof match$1 === "object") {
    removed = Belt_List.flatten(Belt_List.fromArray(match$1.VAL));
  } else {
    throw {
          RE_EXN_ID: LongTask.TaskAborted,
          Error: new Error()
        };
  }
  return Belt_List.concat(newAndUpdated, removed);
}

async function diff(signal, onErrorOpt, fromModel, toModel, param) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var match = ModelUtils.hasMigrated(fromModel, "PropertyOptionalAndExcludedSourcesToAbsence");
  var match$1 = ModelUtils.hasMigrated(toModel, "PropertyOptionalAndExcludedSourcesToAbsence");
  var match$2 = match ? (
      match$1 ? [
          fromModel,
          toModel
        ] : [
          fromModel,
          ActionsReducer.reduce(toModel, "MigratePropertyOptionalAndExcludedSourcesToAbsence")
        ]
    ) : (
      match$1 ? [
          ActionsReducer.reduce(fromModel, "MigratePropertyOptionalAndExcludedSourcesToAbsence"),
          toModel
        ] : [
          fromModel,
          toModel
        ]
    );
  var toModel$1 = match$2[1];
  var fromModel$1 = match$2[0];
  var match$3 = ModelUtils.hasMigrated(fromModel$1, "SkipWebFnTagToIncludeInCodegen");
  var match$4 = ModelUtils.hasMigrated(toModel$1, "SkipWebFnTagToIncludeInCodegen");
  var match$5 = match$3 ? (
      match$4 ? [
          fromModel$1,
          toModel$1
        ] : [
          fromModel$1,
          ActionsReducer.reduce(toModel$1, "MigrateSkipWebFnTagToIncludeInCodegen")
        ]
    ) : (
      match$4 ? [
          ActionsReducer.reduce(fromModel$1, "MigrateSkipWebFnTagToIncludeInCodegen"),
          toModel$1
        ] : [
          fromModel$1,
          toModel$1
        ]
    );
  var toModel$2 = match$5[1];
  var fromModel$2 = match$5[0];
  var match$6 = ModelUtils.hasMigrated(fromModel$2, "RemoveUnarchivedItemsFromArchive");
  var match$7 = ModelUtils.hasMigrated(toModel$2, "RemoveUnarchivedItemsFromArchive");
  var match$8 = match$6 ? (
      match$7 ? [
          fromModel$2,
          toModel$2
        ] : [
          fromModel$2,
          ActionsReducer.reduce(toModel$2, "MigrateRemoveUnarchivedItemsFromArchive")
        ]
    ) : (
      match$7 ? [
          ActionsReducer.reduce(fromModel$2, "MigrateRemoveUnarchivedItemsFromArchive"),
          toModel$2
        ] : [
          fromModel$2,
          toModel$2
        ]
    );
  var toModel$3 = match$8[1];
  var fromModel$3 = match$8[0];
  var match$9 = ModelUtils.hasMigrated(fromModel$3, "EventSpecificAllowedPropertyValues");
  var match$10 = ModelUtils.hasMigrated(toModel$3, "EventSpecificAllowedPropertyValues");
  var match$11 = match$9 ? (
      match$10 ? [
          fromModel$3,
          toModel$3
        ] : [
          fromModel$3,
          ActionsReducer.reduce(toModel$3, "MigrateToEventSpecificAllowedPropertyValues")
        ]
    ) : (
      match$10 ? [
          ActionsReducer.reduce(fromModel$3, "MigrateToEventSpecificAllowedPropertyValues"),
          toModel$3
        ] : [
          fromModel$3,
          toModel$3
        ]
    );
  var toModel$4 = match$11[1];
  var fromModel$4 = match$11[0];
  var hasMigratedToEventSpecificAllowedValues = ModelUtils.hasMigrated(toModel$4, "EventSpecificAllowedPropertyValues");
  var fromMappedEvents = Curry._1(TrackingPlanMappedModel.Events.fromList, fromModel$4.events);
  var toMappedEvents = Curry._1(TrackingPlanMappedModel.Events.fromList, toModel$4.events);
  var toMappedEventsKeyedByLocalId = Curry._1(TrackingPlanMappedModel.Events.Utils.getDictKeyedByLocalItemId, toMappedEvents);
  var fromMappedEventsArchive = Curry._1(TrackingPlanMappedModel.Events.fromList, fromModel$4.archive.events);
  var toMappedEventsArchive = Curry._1(TrackingPlanMappedModel.Events.fromList, toModel$4.archive.events);
  var toMappedEventsArchiveKeyedByLocalId = Curry._1(TrackingPlanMappedModel.Events.Utils.getDictKeyedByLocalItemId, toMappedEventsArchive);
  var fromMappedProperties = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, fromModel$4.properties);
  var toMappedProperties = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, toModel$4.properties);
  var toMappedPropertiesKeyedByLocalId = Curry._1(TrackingPlanMappedModel.Properties.Utils.getDictKeyedByLocalItemId, toMappedProperties);
  var fromMappedPropertiesArchive = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, fromModel$4.archive.properties);
  var toMappedPropertiesArchive = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, toModel$4.archive.properties);
  var toMappedPropertyBundles = Curry._1(TrackingPlanMappedModel.PropertyBundles.fromList, toModel$4.propertyBundles);
  var fromMappedMetrics = Curry._1(TrackingPlanMappedModel.Metrics.fromList, fromModel$4.metrics);
  var toMappedMetrics = Curry._1(TrackingPlanMappedModel.Metrics.fromList, toModel$4.metrics);
  var fromMappedMetricsArchive = Curry._1(TrackingPlanMappedModel.Metrics.fromList, fromModel$4.archive.metrics);
  var toMappedMetricsArchive = Curry._1(TrackingPlanMappedModel.Metrics.fromList, toModel$4.archive.metrics);
  var actions = Belt_List.concatMany([
        await diffProperties(signal, fromMappedProperties, toMappedProperties, toMappedPropertiesKeyedByLocalId, fromMappedPropertiesArchive, toMappedPropertiesArchive, toMappedEvents, onError, fromModel$4, toModel$4, hasMigratedToEventSpecificAllowedValues),
        await diffEvents(signal, fromMappedEvents, fromMappedEventsArchive, toMappedEvents, toMappedEventsKeyedByLocalId, toMappedEventsArchiveKeyedByLocalId, toMappedEventsArchive, toModel$4.globalRequirements, toMappedProperties, toMappedPropertyBundles, onError),
        await diffSources(signal, fromModel$4.sources, toModel$4.sources, fromModel$4.archive.sources, toModel$4.archive.sources, onError),
        await diffDestinations(signal, fromModel$4.destinations, toModel$4.destinations, fromModel$4.archive.destinations, toModel$4.archive.destinations, onError),
        Belt_List.keepU(await diffMetrics(signal, fromMappedMetrics, toMappedMetrics, fromMappedMetricsArchive, toMappedMetricsArchive, toMappedEvents, onError), (function (action) {
                if (typeof action !== "object") {
                  return true;
                }
                if (action.NAME !== "AddEventToMetric") {
                  return true;
                }
                var eventId = action.VAL[2];
                return Belt_List.someU(toModel$4.events, (function ($$event) {
                              return $$event.id === eventId;
                            }));
              })),
        await diffGoals(signal, fromModel$4.goals, toModel$4.goals, fromModel$4.archive.goals, toModel$4.archive.goals, toMappedEvents, onError),
        await diffPropertyGroups(signal, fromModel$4.propertyBundles, toModel$4.propertyBundles, fromModel$4.archive.propertyBundles, toModel$4.archive.propertyBundles, toMappedPropertyBundles, toModel$4.globalRequirements, toMappedProperties),
        await diffRules(signal, fromModel$4.rules, toModel$4.rules, toModel$4),
        await diffIntegrations(signal, fromModel$4.integrations, toModel$4.integrations),
        await diffGroupTypes(signal, fromModel$4.groupTypes, toModel$4.groupTypes, fromModel$4.archive.groupTypes, toModel$4.archive.groupTypes, onError),
        ActionDiff.diffGlobalRequirements(fromModel$4.globalRequirements, toModel$4.globalRequirements, onError)
      ]);
  var match$12 = Belt_List.partitionU(actions, (function (action) {
          if (typeof action !== "object") {
            return false;
          }
          var variant = action.NAME;
          if (variant === "Unarchive" || variant === "CreateProperty" || variant === "CreatePropertyGroup" || variant === "CreateSource" || variant === "CreateEventVariant" || variant === "DuplicateEvent" || variant === "ExcludeEventFromSource" || variant === "CreateDestination" || variant === "IncludeEventInSource" || variant === "IncludeEventInSourceV2" || variant === "AddMetric" || variant === "CreateGroupType" || variant === "ExcludeEventFromSourceV2" || variant === "AddRule" || variant === "AddGoal") {
            return true;
          } else {
            return variant === "AddEvent";
          }
        }));
  return Belt_List.concat(match$12[0], match$12[1]);
}

export {
  diffEvents ,
  diffProperties ,
  diffSources ,
  diffDestinations ,
  diffMetrics ,
  diffGoals ,
  diffPropertyGroups ,
  diffRules ,
  diffIntegrations ,
  diffGroupTypes ,
  diff ,
}
/* ActionDiff Not a pure module */
