// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as Input from "./Input.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as InputLabel from "./InputLabel.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as ModalWithArtwork from "./ModalWithArtwork.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function shouldShowModal(user) {
  if (Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.newUserModalSeen), false)) {
    return false;
  } else {
    return Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(user.createdAt), new Date(), (function (prim) {
                    return prim.toDate();
                  })).getTime() > new Date("2024-02-29T00:00:00Z").getTime();
  }
}

function NewUserModal(Props) {
  var onClose = Props.onClose;
  var workspace = WorkspaceContext.use();
  var schemaBundle = SchemaBundleContext.use();
  var user = ViewerContext.use();
  var addToast = Toast.useAddToast();
  var match = React.useState(function (param) {
        return false;
      });
  var setNameLoading = match[1];
  var match$1 = React.useState(function (param) {
        return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), "");
      });
  var setNameInput = match$1[1];
  var nameInput = match$1[0];
  var match$2 = React.useState(function (param) {
        return Uuid.v4();
      });
  var inputId = match$2[0];
  React.useEffect((function (param) {
          AnalyticsRe.namePromptDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, workspace.id);
        }), []);
  var handleSkip = function (param) {
    AnalyticsRe.namePromptInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Skip", workspace.id);
    FirebaseUtils.setUserHasSeenNewUserModal(user.id);
    Curry._1(onClose, undefined);
  };
  var handleDone = function (param) {
    Curry._1(setNameLoading, (function (param) {
            return true;
          }));
    var trimmedNameInput = nameInput.trim();
    $$Promise.$$catch(FirebaseUtils.updateUserName(user.id, trimmedNameInput).then(function (param) {
                AnalyticsRe.namePromptInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Complete", workspace.id);
                console.log("setUserHasSeenNewUserModal", trimmedNameInput);
                return FirebaseUtils.setUserHasSeenNewUserModal(user.id);
              }).then(function (param) {
              Curry._1(onClose, undefined);
            }), (function (_error) {
            Curry._1(setNameLoading, (function (param) {
                    return false;
                  }));
            Curry._1(addToast, {
                  message: "Failed to update name, please try again",
                  toastType: "Error"
                });
            return Promise.resolve();
          }));
  };
  return React.createElement(ModalWithArtwork.make, {
              artwork: React.createElement(Icons.NewUserIllustration.make, {}),
              noDismiss: true,
              onClose: onClose,
              title: "Welcome to your Avo workspace",
              children: null
            }, React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement($$Text.make, {
                  color: Styles.Color.light11,
                  children: "Before we get started, what should we call you?"
                }), React.createElement(Spacer.make, {
                  height: 28
                }), React.createElement(InputLabel.make, {
                  htmlFor: inputId,
                  children: "Your first name or nickname"
                }), React.createElement(Input.make, {
                  value: nameInput,
                  onChange: (function (name) {
                      Curry._1(setNameInput, (function (param) {
                              return name;
                            }));
                    }),
                  id: inputId,
                  autoFocus: true,
                  onEnter: (function (param) {
                      if (nameInput !== "") {
                        return handleDone();
                      }
                      
                    }),
                  containerClassName: {
                    hd: Css.margin("zero"),
                    tl: /* [] */0
                  }
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: {
                            hd: Css.justifyContent("flexEnd"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(Button.make, {
                      label: "Skip",
                      onClick: handleSkip,
                      size: "large",
                      style: "ghost"
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(Button.make, {
                      disabled: nameInput.trim() === "",
                      label: "Done",
                      loading: match[0],
                      onClick: handleDone,
                      size: "large"
                    })));
}

var make = NewUserModal;

export {
  shouldShowModal ,
  make ,
}
/* Css Not a pure module */
