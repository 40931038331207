// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Nanoid from "nanoid";
import * as AvoLimits from "../AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../ModelUtils.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "../NamedBranch.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as BeltListExtensions from "../BeltListExtensions.mjs";
import * as ValidationConfigV2 from "../guardrails/ValidationConfigV2.mjs";
import * as AnalyticsUtilsBundles from "../analyticsUtilsBundles.mjs";
import * as GuardRailsConfigUtils from "../guardrails/GuardRailsConfigUtils.mjs";
import * as InspectorPropertyType from "../../../shared/models/InspectorPropertyType.mjs";

var defaultType_count = {
  comparison: {
    numVersions: 0,
    total: 0,
    versions: []
  },
  current: {
    numVersions: 0,
    total: 0,
    versions: []
  },
  history: {
    numVersions: 0,
    total: 0,
    versions: []
  }
};

var defaultType_firstSeen = {
  current: {
    firstSeen: undefined,
    lastSeen: undefined,
    versions: [],
    numVersions: 0
  },
  comparison: {
    firstSeen: undefined,
    lastSeen: undefined,
    versions: [],
    numVersions: 0
  },
  history: {
    firstSeen: undefined,
    lastSeen: undefined,
    versions: [],
    numVersions: 0
  }
};

var defaultType_lastSeen = {
  current: {
    firstSeen: undefined,
    lastSeen: undefined,
    versions: [],
    numVersions: 0
  },
  comparison: {
    firstSeen: undefined,
    lastSeen: undefined,
    versions: [],
    numVersions: 0
  },
  history: {
    firstSeen: undefined,
    lastSeen: undefined,
    versions: [],
    numVersions: 0
  }
};

var defaultType = {
  type_: "string",
  count: defaultType_count,
  firstSeen: defaultType_firstSeen,
  lastSeen: defaultType_lastSeen
};

function propertyAddedToEventAnalytics(schemaBundle, branch, eventId, eventName, propertyName, propertyGroup, propertyLocation) {
  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
  AnalyticsRe.propertyAdded(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, eventId, eventName, propertyGroup.propertyId, propertyName, propertyGroup.namingConvention, propertyGroup.propertyType, propertyGroup.correctCase, [], [], [], "NoSorting", [], [], [], [], propertyName.length, "New", Case.to_("Sentence", propertyName).split(" ").length, propertyName.length, propertyLocation, schemaBundle$1.branchId, schemaBundle$1.schemaId);
}

function newPropertyAnalytics(eventId, eventName, expectedPropertyCase, schemaBundle, propertyName, propertyId, propertyType, branch, propertyLocation) {
  var correctPropertyCase = NameUtils.isCorrectCase(expectedPropertyCase, propertyName);
  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
  AnalyticsRe.propertyCreated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyId, propertyName, Case.toAnalytics(expectedPropertyCase), "EventProperty", correctPropertyCase, [], [], eventId, propertyLocation, AnalyticsUtils.propertyLocationToOrigin(propertyLocation), schemaBundle$1.branchId, schemaBundle$1.schemaId);
  var propertyGroup = AnalyticsUtilsBundles.property(propertyId, propertyName, Case.toAnalytics(expectedPropertyCase), "EventProperty", correctPropertyCase);
  propertyAddedToEventAnalytics(schemaBundle$1, branch, eventId, eventName, propertyName, propertyGroup, propertyLocation);
  if (propertyType !== "string") {
    return AnalyticsRe.typeChanged(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", Belt_Option.getExn(AnalyticsRe.to_FromJs(propertyType)), Belt_Option.getExn(AnalyticsRe.fromFromJs("string")), propertyName, NamedBranch.getId(branch), schemaBundle$1.schemaId);
  }
  
}

function addPropertyToEventInTrackingPlan(eventId, eventName, inspectorProperty, model, context, expectedPropertyCase, schemaBundle, propertyLocation, isConflict, forceCreateOnConflict, shouldCreateAsSometimesSent) {
  var maybeProperty = ModelUtils.getPropertyByName(inspectorProperty.propertyName, model);
  var propertyAsOptional = function (propertyId) {
    if (shouldCreateAsSometimesSent) {
      return [[
                {
                  NAME: "UpdatePropertyAbsence",
                  VAL: [
                    propertyId,
                    {
                      TAG: "Event",
                      _0: eventId
                    },
                    {
                      TAG: "SometimesSent",
                      _0: ""
                    }
                  ]
                },
                context
              ]];
    } else {
      return [];
    }
  };
  if (maybeProperty !== undefined) {
    return [
            Belt_Array.concat([[
                    {
                      NAME: "AddPropertyRef",
                      VAL: [
                        eventId,
                        maybeProperty.id
                      ]
                    },
                    context
                  ]], propertyAsOptional(maybeProperty.id)),
            undefined,
            (function (branch) {
                var correctPropertyCase = NameUtils.isCorrectCase(expectedPropertyCase, maybeProperty.name);
                var propertyGroup = AnalyticsUtilsBundles.property(maybeProperty.id, maybeProperty.name, Case.toAnalytics(expectedPropertyCase), AnalyticsUtils.sendAsToPropertyType(maybeProperty.sendAs), correctPropertyCase);
                propertyAddedToEventAnalytics(schemaBundle, branch, eventId, eventName, maybeProperty.name, propertyGroup, propertyLocation);
              })
          ];
  }
  var propertyId = Nanoid.nanoid();
  var match = Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(inspectorProperty.sources, 0), (function (source) {
              var mostCommonType = Belt_Array.reduceU(source.propertyTypes, defaultType, (function (propertyType, currentType) {
                      if (propertyType.count.current.total > currentType.count.current.total) {
                        return propertyType;
                      } else {
                        return currentType;
                      }
                    }));
              var match = mostCommonType.type_;
              var propertyType;
              switch (match) {
                case "any" :
                    propertyType = "any";
                    break;
                case "bool" :
                case "boolean" :
                    propertyType = "bool";
                    break;
                case "float" :
                    propertyType = "float";
                    break;
                case "int" :
                    propertyType = "int";
                    break;
                case "long" :
                    propertyType = "long";
                    break;
                case "object" :
                    propertyType = "object";
                    break;
                default:
                  propertyType = "string";
              }
              var updatePropertyAction = propertyType === "string" ? [] : [[
                    {
                      NAME: "UpdatePropertyType",
                      VAL: [
                        propertyId,
                        propertyType
                      ]
                    },
                    context
                  ]];
              return [
                      Belt_Array.concatMany([
                            [[
                                {
                                  NAME: "AddProperty",
                                  VAL: [
                                    eventId,
                                    propertyId,
                                    inspectorProperty.propertyName,
                                    "EventProperty"
                                  ]
                                },
                                context
                              ]],
                            updatePropertyAction,
                            propertyAsOptional(propertyId)
                          ]),
                      propertyId,
                      (function (branch) {
                          newPropertyAnalytics(eventId, eventName, expectedPropertyCase, schemaBundle, inspectorProperty.propertyName, propertyId, mostCommonType.type_, branch, propertyLocation);
                        })
                    ];
            })), [
        [],
        undefined,
        (function (_branch) {
            
          })
      ]);
  var onSuccess = match[2];
  var newPropertyId = match[1];
  var actions = match[0];
  if (isConflict) {
    if (forceCreateOnConflict) {
      return [
              Belt_Array.concat(actions, [[
                      {
                        NAME: "UpdatePropertyUniqueName",
                        VAL: [
                          propertyId,
                          inspectorProperty.propertyName + " 1"
                        ]
                      },
                      context
                    ]]),
              newPropertyId,
              onSuccess
            ];
    } else {
      return [
              [],
              undefined,
              (function (_branch) {
                  
                })
            ];
    }
  } else {
    return [
            actions,
            newPropertyId,
            onSuccess
          ];
  }
}

function addPropertyToEventInTrackingPlanV2(eventId, eventName, inspectorProperty, model, expectedPropertyCase, schemaBundle, propertyLocation, shouldCreateAsSometimesSent) {
  var maybeProperty = ModelUtils.getPropertyByName(inspectorProperty.propertyName, model);
  var propertyAsOptional = function (propertyId) {
    var context = {
      eventId: eventId,
      eventQuery: eventId,
      propertyId: propertyId,
      propertyQuery: propertyId
    };
    if (shouldCreateAsSometimesSent) {
      return [[
                {
                  NAME: "UpdatePropertyAbsence",
                  VAL: [
                    propertyId,
                    {
                      TAG: "Event",
                      _0: eventId
                    },
                    {
                      TAG: "SometimesSent",
                      _0: ""
                    }
                  ]
                },
                context
              ]];
    } else {
      return [];
    }
  };
  if (maybeProperty !== undefined) {
    var context = {
      eventId: eventId,
      eventQuery: eventId,
      propertyId: maybeProperty.id,
      propertyQuery: maybeProperty.id
    };
    var actions = Belt_Array.concat([[
            {
              NAME: "AddPropertyRef",
              VAL: [
                eventId,
                maybeProperty.id
              ]
            },
            context
          ]], propertyAsOptional(maybeProperty.id));
    return [
            actions,
            undefined,
            (function (branch) {
                var correctPropertyCase = NameUtils.isCorrectCase(expectedPropertyCase, maybeProperty.name);
                var propertyGroup = AnalyticsUtilsBundles.property(maybeProperty.id, maybeProperty.name, Case.toAnalytics(expectedPropertyCase), AnalyticsUtils.sendAsToPropertyType(maybeProperty.sendAs), correctPropertyCase);
                propertyAddedToEventAnalytics(schemaBundle, branch, eventId, eventName, maybeProperty.name, propertyGroup, propertyLocation);
              })
          ];
  }
  var propertyId = Nanoid.nanoid();
  var context$1 = {
    eventId: eventId,
    eventQuery: eventId,
    propertyId: propertyId,
    propertyQuery: propertyId
  };
  var propertyType = Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(inspectorProperty.propertyShape.propertyTypes, 0), (function (propertyType) {
              return InspectorPropertyType.toString(propertyType.type_);
            })), "string");
  var updatePropertyAction = propertyType === "string" ? [] : [[
        {
          NAME: "UpdatePropertyType",
          VAL: [
            propertyId,
            propertyType
          ]
        },
        context$1
      ]];
  var actions$1 = Belt_Array.concatMany([
        [[
            {
              NAME: "AddProperty",
              VAL: [
                eventId,
                propertyId,
                inspectorProperty.propertyName,
                "EventProperty"
              ]
            },
            context$1
          ]],
        updatePropertyAction,
        propertyAsOptional(propertyId)
      ]);
  return [
          actions$1,
          propertyId,
          (function (branch) {
              newPropertyAnalytics(eventId, eventName, expectedPropertyCase, schemaBundle, inspectorProperty.propertyName, propertyId, propertyType, branch, propertyLocation);
            })
        ];
}

function onCreateProperty(inspectorEvent, inspectorProperty, model, sendActions, schemaBundle, expectedPropertyCase, addToast, globalSend, propertyLocation, shouldCreateAsSometimesSent) {
  var addProperty = function ($$event, inspectorProperty, isConflict) {
    var context = {
      eventId: $$event.id,
      eventQuery: $$event.id
    };
    var match = addPropertyToEventInTrackingPlan($$event.id, $$event.name, inspectorProperty, model, context, expectedPropertyCase, schemaBundle, propertyLocation, isConflict, true, shouldCreateAsSometimesSent);
    var onSuccess = match[2];
    var maybePropertyId = match[1];
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (branch) {
              Curry._1(onSuccess, branch);
              var text = maybePropertyId !== undefined ? "Property Created 🎉" : "Property Added to Event 🎉";
              Curry._1(addToast, {
                    message: text,
                    toastType: "Success"
                  });
            }),
          undefined,
          match[0]
        ]);
  };
  var $$event = ModelUtils.getEventByName(inspectorEvent.eventName, model);
  var existingNames = ModelUtils.getPropertyNamespace(model);
  var conflictingName = NameUtils.getConflictingNames(existingNames, inspectorProperty.propertyName);
  if ($$event !== undefined) {
    if (conflictingName !== undefined && conflictingName !== inspectorProperty.propertyName) {
      AnalyticsRe.warningPromptDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "InspectorAddPropertyConflictingPropertyFound", schemaBundle.schemaId);
      return Curry._1(globalSend, {
                  TAG: "OpenModal",
                  _0: {
                    NAME: "ConfirmModal",
                    VAL: [
                      "This property already exists as " + conflictingName + ".",
                      "This is most likely a data bug. Are you sure you want to continue?",
                      "Continue",
                      (function (param) {
                          AnalyticsRe.warningPromptInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Confirm", "InspectorAddPropertyConflictingPropertyFound", schemaBundle.schemaId);
                          addProperty($$event, inspectorProperty, true);
                        }),
                      (function (param) {
                          AnalyticsRe.warningPromptInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Cancel", "InspectorAddPropertyConflictingPropertyFound", schemaBundle.schemaId);
                        })
                    ]
                  }
                });
    } else {
      return addProperty($$event, inspectorProperty, false);
    }
  }
  
}

function onCreatePropertyV2(inspectorEvent, inspectorProperty, model, sendActions, schemaBundle, expectedPropertyCase, addToast, globalSend, propertyLocation, shouldCreateAsSometimesSent) {
  var addProperty = function ($$event, inspectorProperty) {
    var match = addPropertyToEventInTrackingPlanV2($$event.id, $$event.name, inspectorProperty, model, expectedPropertyCase, schemaBundle, propertyLocation, shouldCreateAsSometimesSent);
    var onSuccess = match[2];
    var maybePropertyId = match[1];
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (branch) {
              Curry._1(onSuccess, branch);
              var text = maybePropertyId !== undefined ? "Property Created 🎉" : "Property Added to Event 🎉";
              Curry._1(addToast, {
                    message: text,
                    toastType: "Success"
                  });
            }),
          undefined,
          match[0]
        ]);
  };
  var $$event = ModelUtils.getEventByName(inspectorEvent.eventName, model);
  var existingNames = ModelUtils.getPropertyNamespace(model);
  var conflictingName = NameUtils.getConflictingNames(existingNames, inspectorProperty.propertyName);
  if ($$event !== undefined) {
    if (conflictingName !== undefined && conflictingName !== inspectorProperty.propertyName) {
      AnalyticsRe.warningPromptDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "InspectorAddPropertyConflictingPropertyFound", schemaBundle.schemaId);
      return Curry._1(globalSend, {
                  TAG: "OpenModal",
                  _0: {
                    NAME: "ConfirmModal",
                    VAL: [
                      "This property already exists as " + conflictingName + ".",
                      "This is most likely a data bug. Are you sure you want to continue?",
                      "Continue",
                      (function (param) {
                          AnalyticsRe.warningPromptInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Confirm", "InspectorAddPropertyConflictingPropertyFound", schemaBundle.schemaId);
                          addProperty($$event, inspectorProperty);
                        }),
                      (function (param) {
                          AnalyticsRe.warningPromptInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Cancel", "InspectorAddPropertyConflictingPropertyFound", schemaBundle.schemaId);
                        })
                    ]
                  }
                });
    } else {
      return addProperty($$event, inspectorProperty);
    }
  }
  
}

var Property = {
  defaultType: defaultType,
  propertyAddedToEventAnalytics: propertyAddedToEventAnalytics,
  newPropertyAnalytics: newPropertyAnalytics,
  addPropertyToEventInTrackingPlan: addPropertyToEventInTrackingPlan,
  addPropertyToEventInTrackingPlanV2: addPropertyToEventInTrackingPlanV2,
  onCreateProperty: onCreateProperty,
  onCreatePropertyV2: onCreatePropertyV2
};

function updateEventSourceAnalytics(eventId, inspectorEvent, sources, schemaBundle, model, correctCase, namingConvention, branch) {
  Belt_Array.forEachWithIndexU(sources, (function (index, source) {
          AnalyticsRe.eventUpdated(eventId, inspectorEvent.eventName, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, source.id, Belt_Option.getWithDefault(source.name, "N/A"), "AddSources", undefined, undefined, Belt_List.toArray(Belt_List.map(Belt_List.keepWithIndex(model.sources, (function (param, i) {
                              return i < index;
                            })), (function (source) {
                          return source.id;
                        }))), Belt_List.toArray(Belt_List.map(Belt_List.keepWithIndex(model.sources, (function (param, i) {
                              return i < (index - 1 | 0);
                            })), (function (source) {
                          return source.id;
                        }))), undefined, Belt_List.length(BeltListExtensions.dedupeString(ModelUtils.eventsWithNameMapping(model))), Belt_List.length(ModelUtils.eventsWithNameMapping(model)), Belt_List.length(Belt_List.keep(ModelUtils.eventsWithNameMapping(model), (function (id) {
                          return id === eventId;
                        }))), Belt_List.length(model.events), undefined, undefined, undefined, undefined, "InspectorEvents", 0, correctCase, namingConvention, "InspectorEvents", eventId, schemaBundle.schemaId, NamedBranch.getId(branch), eventId, undefined, inspectorEvent.eventName);
        }));
  if (sources.length !== 0) {
    return ;
  } else {
    AnalyticsRe.eventUpdated(eventId, inspectorEvent.eventName, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "N/A", "N/A", "AddSources", undefined, undefined, Belt_List.toArray(Belt_List.map(model.sources, (function (source) {
                    return source.id;
                  }))), [], undefined, Belt_List.length(BeltListExtensions.dedupeString(ModelUtils.eventsWithNameMapping(model))), Belt_List.length(ModelUtils.eventsWithNameMapping(model)), Belt_List.length(Belt_List.keep(ModelUtils.eventsWithNameMapping(model), (function (id) {
                    return id === eventId;
                  }))), Belt_List.length(model.events), undefined, undefined, undefined, undefined, "InspectorEvents", 0, correctCase, namingConvention, "InspectorEvents", eventId, schemaBundle.schemaId, NamedBranch.getId(branch), eventId, undefined, inspectorEvent.eventName);
    return ;
  }
}

function addEventToTrackingPlan(workspace, inspectorEvent, model, sendActions, schemaBundle, expectedEventCase, expectedPropertyCase, addToast, eventOrigin, isConflict, shouldCreateAsSometimesSent, config) {
  var eventId = Nanoid.nanoid();
  var eventName = inspectorEvent.eventName;
  var propertyLocation = eventOrigin === "InspectorSidebar" || eventOrigin !== "InspectorTable" ? "InspectorSidebar" : "InspectorTable";
  var namingConvention = ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events) ? Case.toAnalytics(Case.of_(eventName)) : "Advanced";
  var nameComponents = ValidationConfigV2.EventsV2.getNameComponents(config.events);
  var issueReportEventCasing = GuardRailsConfigUtils.nameComponentsToAnalyticsCase(nameComponents);
  var issueReportEventNamingConvention = GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(nameComponents);
  var correctEventCase = NameUtils.isCorrectCase(expectedEventCase, eventName);
  var areEventSourcesAndDestinationsConfigurable = AvoLimits.ConfigEventSourcesAndDestinations.isAvailable(workspace);
  var context = {
    eventId: eventId,
    eventQuery: eventId
  };
  var sources = Belt_Array.keepMapU(inspectorEvent.sources, (function (eventSource) {
          return ModelUtils.getSourceById(eventSource.sourceId, model);
        }));
  var sourcesActions = Belt_Array.mapU(sources, (function (source) {
          var destinationIds = Belt_List.mapU(source.destinations, (function (d) {
                  return d.destinationId;
                }));
          return [
                  {
                    NAME: "IncludeEventInSourceV2",
                    VAL: [
                      eventId,
                      source.id,
                      destinationIds,
                      false
                    ]
                  },
                  context
                ];
        }));
  var props = Belt_Array.mapU(inspectorEvent.properties, (function (inspectorProperty) {
          var existingNames = ModelUtils.getPropertyNamespace(model);
          var conflictingName = NameUtils.getConflictingNames(existingNames, inspectorProperty.propertyName);
          var isConflict = conflictingName !== undefined ? conflictingName !== inspectorProperty.propertyName : false;
          return addPropertyToEventInTrackingPlan(eventId, eventName, inspectorProperty, model, context, expectedPropertyCase, schemaBundle, propertyLocation, isConflict, false, shouldCreateAsSometimesSent);
        }));
  var eventActions = isConflict ? [
      [
        {
          NAME: "AddEvent",
          VAL: [
            eventId,
            inspectorEvent.eventName
          ]
        },
        context
      ],
      [
        {
          NAME: "UpdateEventUniqueNameV2",
          VAL: [
            eventId,
            inspectorEvent.eventName + " 1"
          ]
        },
        context
      ]
    ] : [[
        {
          NAME: "AddEvent",
          VAL: [
            eventId,
            inspectorEvent.eventName
          ]
        },
        context
      ]];
  var propertyActions = Belt_Array.concatMany(Belt_Array.mapU(props, (function (param) {
              return param[0];
            })));
  var propertiesCreated = Belt_Array.keepMapU(props, (function (param) {
          return param[1];
        }));
  var propertiesOnSuccess = Belt_Array.mapU(props, (function (param) {
          return param[2];
        }));
  Curry.app(sendActions, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
            AnalyticsRe.eventCreated([], [], [], "NoSorting", [], [], [], [], schema.schemaId, schema.schemaName, schema.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, eventId, inspectorEvent.eventName, correctEventCase, namingConvention, eventOrigin, issueReportEventCasing, issueReportEventNamingConvention, false, 1, "Systematic", undefined, NamedBranch.getId(branch), schemaBundle.schemaId);
            updateEventSourceAnalytics(eventId, inspectorEvent, sources, schemaBundle, model, correctEventCase, namingConvention, branch);
            Belt_Array.forEach(propertiesOnSuccess, (function (onSuccess) {
                    Curry._1(onSuccess, branch);
                  }));
            var num = propertiesCreated.length;
            var text = num !== 0 ? (
                num !== 1 ? "Event and " + String(num) + " properties created 🎉" : "Event and 1 property created 🎉"
              ) : "Event Created 🎉";
            Curry._1(addToast, {
                  message: text,
                  toastType: "Success"
                });
          }),
        undefined,
        Belt_Array.concatMany([
              eventActions,
              sourcesActions,
              propertyActions,
              areEventSourcesAndDestinationsConfigurable ? [] : Belt_Array.mapU(Belt_List.toArray(model.sources), (function (source) {
                        return [
                                {
                                  NAME: "IncludeEventInSourceV2",
                                  VAL: [
                                    eventId,
                                    source.id,
                                    ModelUtils.getSourceDestinationIds(source.destinations),
                                    true
                                  ]
                                },
                                {
                                  eventId: eventId,
                                  eventQuery: eventId,
                                  sourceId: source.id,
                                  sourceQuery: source.id
                                }
                              ];
                      }))
            ])
      ]);
}

function onCreateEvent(workspace, inspectorEvent, model, sendActions, schemaBundle, expectedEventCase, expectedPropertyCase, addToast, globalSend, eventOrigin, shouldCreateAsSometimesSent, config) {
  var addEvent = function ($$event, isConflict) {
    addEventToTrackingPlan(workspace, $$event, model, sendActions, schemaBundle, expectedEventCase, expectedPropertyCase, addToast, eventOrigin, isConflict, shouldCreateAsSometimesSent, config);
  };
  var existingNames = Belt_List.mapU(model.events, (function ($$event) {
          return $$event.name;
        }));
  var conflictingName = NameUtils.getConflictingNames(existingNames, inspectorEvent.eventName);
  if (conflictingName !== undefined) {
    AnalyticsRe.warningPromptDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "InspectorAddEventConflictingEventFound", workspace.id);
    return Curry._1(globalSend, {
                TAG: "OpenModal",
                _0: {
                  NAME: "ConfirmModal",
                  VAL: [
                    "This event already exists as " + conflictingName + ".",
                    "This is most likely a data bug. Are you sure you want to continue?",
                    "Continue",
                    (function (param) {
                        AnalyticsRe.warningPromptInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Confirm", "InspectorAddEventConflictingEventFound", workspace.id);
                        addEvent(inspectorEvent, true);
                      }),
                    (function (param) {
                        AnalyticsRe.warningPromptInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Cancel", "InspectorAddEventConflictingEventFound", workspace.id);
                      })
                  ]
                }
              });
  } else {
    return addEvent(inspectorEvent, false);
  }
}

var $$Event = {
  updateEventSourceAnalytics: updateEventSourceAnalytics,
  addEventToTrackingPlan: addEventToTrackingPlan,
  onCreateEvent: onCreateEvent
};

export {
  Property ,
  $$Event ,
}
/* Case Not a pure module */
