// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SelectBranchPopup from "./SelectBranchPopup.mjs";
import * as IconDocumentChanges from "./IconDocumentChanges.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as PortalMenu_deprecated from "./PortalMenu_deprecated.mjs";

var overlay = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.zIndex(Styles.ZIndex.aboveAll),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var container = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.maxWidth(Css.px(450)),
        tl: {
          hd: Css.padding4(Css.px(35), Css.px(32), Css.px(32), Css.px(32)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.borderRadius(Css.px(10)),
              tl: {
                hd: Css.boxShadow(Styles.Shadow.standard),
                tl: {
                  hd: Css.cursor("default"),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var iconContainer = Curry._1(Css.style, {
      hd: Css.margin2(Css.px(15), "auto"),
      tl: {
        hd: Css.marginTop(Css.px(-78)),
        tl: {
          hd: Css.textAlign("center"),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  overlay: overlay,
  container: container,
  iconContainer: iconContainer
};

function MasterEditingModal(Props) {
  var onClose = Props.onClose;
  var onContinue = Props.onContinue;
  var onGoToBranch = Props.onGoToBranch;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use();
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use();
  var selectBranchButtonRef = React.useRef(null);
  var match = React.useState(function (param) {
        return false;
      });
  var setShowBranchPopup = match[1];
  var handleDismiss = function (param) {
    Curry._1(onClose, undefined);
    var arg = schemaBundle.branchId;
    var arg$1 = schemaBundle.schemaId;
    AnalyticsUtils.withSchemaBundle((function (param) {
            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
              return AnalyticsRe.branchPromptInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14);
            };
            return function (param) {
              var func$1 = Curry._1(func, param);
              return function (param) {
                var func$2 = Curry._1(func$1, param);
                return function (param) {
                  var func$3 = Curry._1(func$2, param);
                  return function (param) {
                    var func$4 = Curry._1(func$3, param);
                    return function (param) {
                      var func$5 = Curry._1(func$4, param);
                      return function (param) {
                        var func$6 = Curry._1(func$5, param);
                        return function (param) {
                          var func$7 = Curry._1(func$6, param);
                          return function (param) {
                            var func$8 = Curry._1(func$7, param);
                            return function (param) {
                              var func$9 = Curry._1(func$8, param);
                              return function (param) {
                                return Curry._5(func$9, param, "Dismiss", "EditMain", arg, arg$1);
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                };
              };
            };
          }), schemaBundle);
  };
  return React.createElement("div", {
              className: overlay,
              onClick: handleDismiss
            }, React.createElement("div", {
                  className: container,
                  onClick: (function (prim) {
                      prim.stopPropagation();
                    })
                }, React.createElement("div", {
                      className: iconContainer
                    }, React.createElement(IconDocumentChanges.make, {
                          size: 112
                        })), React.createElement($$Text.make, {
                      size: "Huge",
                      weight: "Semi",
                      textAlign: "center",
                      maxWidth: Css.px(250),
                      children: "Want to make these changes on a branch?"
                    }), React.createElement(Spacer.make, {
                      height: 24
                    }), React.createElement($$Text.make, {
                      color: Styles.Color.light11,
                      children: "Draft and collaborate on your tracking plan and keep an overview of what’s changed, then merge it to your main branch when it’s ready."
                    }), React.createElement(Spacer.make, {
                      height: 32
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: /* [] */0
                            }
                          })
                    }, workspace.settings.protectedMainBranch && role !== "Admin" ? React.createElement(Button.make, {
                            label: "Discard changes",
                            onClick: handleDismiss,
                            size: "large",
                            style: "ghost"
                          }) : React.createElement(Button.make, {
                            label: "Continue editing on main",
                            onClick: (function (param) {
                                Curry._1(onContinue, undefined);
                                Curry._1(onClose, undefined);
                                var arg = schemaBundle.branchId;
                                var arg$1 = schemaBundle.schemaId;
                                AnalyticsUtils.withSchemaBundle((function (param) {
                                        var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
                                          return AnalyticsRe.branchPromptInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14);
                                        };
                                        return function (param) {
                                          var func$1 = Curry._1(func, param);
                                          return function (param) {
                                            var func$2 = Curry._1(func$1, param);
                                            return function (param) {
                                              var func$3 = Curry._1(func$2, param);
                                              return function (param) {
                                                var func$4 = Curry._1(func$3, param);
                                                return function (param) {
                                                  var func$5 = Curry._1(func$4, param);
                                                  return function (param) {
                                                    var func$6 = Curry._1(func$5, param);
                                                    return function (param) {
                                                      var func$7 = Curry._1(func$6, param);
                                                      return function (param) {
                                                        var func$8 = Curry._1(func$7, param);
                                                        return function (param) {
                                                          var func$9 = Curry._1(func$8, param);
                                                          return function (param) {
                                                            return Curry._5(func$9, param, "StayOnMaster", "EditMain", arg, arg$1);
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          };
                                        };
                                      }), schemaBundle);
                              }),
                            size: "large",
                            style: "ghost"
                          }), React.createElement(Spacer.make, {
                          width: 8
                        }), Belt_List.length(openBranches) === 0 ? React.createElement(Button.make, {
                            label: "Create branch",
                            onClick: (function (param) {
                                Curry._1(onClose, undefined);
                                var arg = schemaBundle.branchId;
                                var arg$1 = schemaBundle.schemaId;
                                AnalyticsUtils.withSchemaBundle((function (param) {
                                        var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
                                          return AnalyticsRe.branchPromptInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14);
                                        };
                                        return function (param) {
                                          var func$1 = Curry._1(func, param);
                                          return function (param) {
                                            var func$2 = Curry._1(func$1, param);
                                            return function (param) {
                                              var func$3 = Curry._1(func$2, param);
                                              return function (param) {
                                                var func$4 = Curry._1(func$3, param);
                                                return function (param) {
                                                  var func$5 = Curry._1(func$4, param);
                                                  return function (param) {
                                                    var func$6 = Curry._1(func$5, param);
                                                    return function (param) {
                                                      var func$7 = Curry._1(func$6, param);
                                                      return function (param) {
                                                        var func$8 = Curry._1(func$7, param);
                                                        return function (param) {
                                                          var func$9 = Curry._1(func$8, param);
                                                          return function (param) {
                                                            return Curry._5(func$9, param, "GoToBranch", "EditMain", arg, arg$1);
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          };
                                        };
                                      }), schemaBundle);
                                Curry._1(globalSend, {
                                      TAG: "OpenModal",
                                      _0: {
                                        NAME: "NewBranch",
                                        VAL: [
                                          Curry.__2(onGoToBranch),
                                          ""
                                        ]
                                      }
                                    });
                              }),
                            size: "large"
                          }) : React.createElement(Button.make, {
                            label: "Switch to branch",
                            onClick: (function (param) {
                                Curry._1(setShowBranchPopup, (function (state) {
                                        return !state;
                                      }));
                              }),
                            size: "large",
                            ref: selectBranchButtonRef
                          }), React.createElement(PortalMenu_deprecated.make, {
                          position: "Right",
                          placement: "Start",
                          offset: {
                            top: -8
                          },
                          visible: match[0],
                          onClose: (function (param) {
                              Curry._1(setShowBranchPopup, (function (param) {
                                      return false;
                                    }));
                            }),
                          referenceItemRef: selectBranchButtonRef,
                          children: React.createElement(SelectBranchPopup.make, {
                                canSelectMain: false,
                                onClose: (function (param) {
                                    Curry._1(setShowBranchPopup, (function (param) {
                                            return false;
                                          }));
                                  }),
                                onSelect: (function (selectedBranch) {
                                    if (typeof selectedBranch !== "object") {
                                      return ;
                                    }
                                    if (selectedBranch.NAME === "Create") {
                                      return Curry._1(globalSend, {
                                                  TAG: "OpenModal",
                                                  _0: {
                                                    NAME: "NewBranch",
                                                    VAL: [
                                                      (function (branchId, branchName) {
                                                          Curry._2(onGoToBranch, branchId, branchName);
                                                          Curry._1(onClose, undefined);
                                                          var arg = schemaBundle.branchId;
                                                          var arg$1 = schemaBundle.schemaId;
                                                          AnalyticsUtils.withSchemaBundle((function (param) {
                                                                  var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
                                                                    return AnalyticsRe.branchPromptInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14);
                                                                  };
                                                                  return function (param) {
                                                                    var func$1 = Curry._1(func, param);
                                                                    return function (param) {
                                                                      var func$2 = Curry._1(func$1, param);
                                                                      return function (param) {
                                                                        var func$3 = Curry._1(func$2, param);
                                                                        return function (param) {
                                                                          var func$4 = Curry._1(func$3, param);
                                                                          return function (param) {
                                                                            var func$5 = Curry._1(func$4, param);
                                                                            return function (param) {
                                                                              var func$6 = Curry._1(func$5, param);
                                                                              return function (param) {
                                                                                var func$7 = Curry._1(func$6, param);
                                                                                return function (param) {
                                                                                  var func$8 = Curry._1(func$7, param);
                                                                                  return function (param) {
                                                                                    var func$9 = Curry._1(func$8, param);
                                                                                    return function (param) {
                                                                                      return Curry._5(func$9, param, "GoToBranch", "EditMain", arg, arg$1);
                                                                                    };
                                                                                  };
                                                                                };
                                                                              };
                                                                            };
                                                                          };
                                                                        };
                                                                      };
                                                                    };
                                                                  };
                                                                }), schemaBundle);
                                                        }),
                                                      selectedBranch.VAL
                                                    ]
                                                  }
                                                });
                                    }
                                    var match = selectedBranch.VAL;
                                    var id = match.id;
                                    Curry._2(onGoToBranch, id, match.name);
                                    Curry._1(onClose, undefined);
                                    var match$1 = Router.getStaticRoute();
                                    var link = Router.Link.getSchemaLink(match$1.baseRoute, {
                                          NAME: "branch",
                                          VAL: id
                                        }, Belt_Option.getWithDefault(match$1.schemaRoute, "dashboard"), match$1.drawerItems);
                                    Router.push(undefined, link);
                                    var arg = schemaBundle.branchId;
                                    var arg$1 = schemaBundle.schemaId;
                                    AnalyticsUtils.withSchemaBundle((function (param) {
                                            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
                                              return AnalyticsRe.branchPromptInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14);
                                            };
                                            return function (param) {
                                              var func$1 = Curry._1(func, param);
                                              return function (param) {
                                                var func$2 = Curry._1(func$1, param);
                                                return function (param) {
                                                  var func$3 = Curry._1(func$2, param);
                                                  return function (param) {
                                                    var func$4 = Curry._1(func$3, param);
                                                    return function (param) {
                                                      var func$5 = Curry._1(func$4, param);
                                                      return function (param) {
                                                        var func$6 = Curry._1(func$5, param);
                                                        return function (param) {
                                                          var func$7 = Curry._1(func$6, param);
                                                          return function (param) {
                                                            var func$8 = Curry._1(func$7, param);
                                                            return function (param) {
                                                              var func$9 = Curry._1(func$8, param);
                                                              return function (param) {
                                                                return Curry._5(func$9, param, "GoToBranch", "EditMain", arg, arg$1);
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    };
                                                  };
                                                };
                                              };
                                            };
                                          }), schemaBundle);
                                  }),
                                openBranches: openBranches
                              })
                        }))));
}

var make = MasterEditingModal;

export {
  Style ,
  make ,
}
/* overlay Not a pure module */
