// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Import from "./import/Import.mjs";
import * as Styles from "./styles.mjs";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as PromiseExt from "./externals/PromiseExt.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as DomainStore from "./DomainStore.mjs";
import * as ImportUtils from "./ImportUtils.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as ImportAction from "./import/ImportAction.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as ActionsReducer from "./actionsReducer.mjs";
import * as NewBranchModal from "./NewBranchModal.mjs";
import * as ImportReview__V2 from "./import/ImportReview__V2.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";
import * as ImportReview__V2__Utils from "./import/ImportReview__V2__Utils.mjs";

var contentStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light02),
        tl: {
          hd: Css.width(Css.vw(90)),
          tl: {
            hd: Css.maxHeight(Css.vh(80)),
            tl: {
              hd: Css.boxShadow(Styles.Shadow.standard),
              tl: {
                hd: Css.borderRadius(Css.px(10)),
                tl: {
                  hd: Css.cursor("default"),
                  tl: {
                    hd: Css.overflow("auto"),
                    tl: {
                      hd: Css.marginLeft("auto"),
                      tl: {
                        hd: Css.marginRight("auto"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var closeButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("sticky"),
              tl: {
                hd: Css.top(Css.px(16)),
                tl: {
                  hd: Css.right(Css.px(16)),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.marginLeft("auto"),
                      tl: {
                        hd: Css.marginRight(Css.px(16)),
                        tl: {
                          hd: Css.width(Css.px(32)),
                          tl: {
                            hd: Css.height(Css.px(32)),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.borderRadius(Css.px(16)),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.light07),
                                    tl: {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$short
                                          }, undefined, undefined, "background-color"),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(Styles.Color.light08),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.zIndex(2),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function ImportReviewModal(Props) {
  var currentBranch = Props.currentBranch;
  var importModel = Props.importModel;
  var importStartedLocation = Props.importStartedLocation;
  var model = Props.model;
  var onClose = Props.onClose;
  var openBranches = Props.openBranches;
  var viewerRole = Props.viewerRole;
  var importMethod = Props.importMethod;
  var workspace = WorkspaceContext.use();
  var sendActions = SendActionsContext.use();
  var schemaBundle = SchemaBundleContext.use();
  var mappedGlobalRequirements = Curry._1(ModelStore.Mapped.GlobalRequirements.use, undefined);
  var mappedProperties = ModelStore.Mapped.useProperties();
  var globalSend = GlobalSendContext.use();
  var addToast = Toast.useAddToast();
  var existingDomains = DomainStore.useDomains();
  var viewerId = ViewerContext.use().id;
  React.useEffect((function (param) {
          if (ImportReview__V2__Utils.importModelIsEmpty(importModel)) {
            ImportReview__V2__Utils.handleExceptions({
                  RE_EXN_ID: ImportReview__V2__Utils.EmptyImportException
                }, (function (message, severity) {
                    Curry._1(addToast, {
                          message: message,
                          toastType: severity === "error" ? "Error" : "Info"
                        });
                  }));
            Curry._1(onClose, undefined);
          } else {
            AnalyticsRe.importStarted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_List.length(model.events), Belt_List.length(model.properties), importStartedLocation, schemaBundle.branchId, schemaBundle.schemaId);
          }
        }), []);
  var proceedWithImport = function (selectedBranch, sendActions, schemaBundle, importActions, parsedTrackingPlan, model, issues, newAndUpdatedProperties, onSuccess) {
    var createBranchAsPromise = function (branchName, sendActions, schemaBundle) {
      return new Promise((function (resolve, reject) {
                    NewBranchModal.createBranch(branchName, "Import", sendActions, schemaBundle, (function (error) {
                            reject(error);
                          }), (function (branchId) {
                            resolve(branchId);
                          }), undefined);
                  }));
    };
    $$Promise.$$catch((
                  typeof selectedBranch === "object" ? (
                      selectedBranch.NAME === "Create" ? createBranchAsPromise(selectedBranch.VAL, sendActions, schemaBundle).then(function (branchId) {
                              return Promise.resolve({
                                          NAME: "Branch",
                                          VAL: branchId
                                        });
                            }) : Promise.resolve({
                              NAME: "Branch",
                              VAL: selectedBranch.VAL.id
                            })
                    ) : Promise.resolve("Master")
                ).then(function (writeToBranch) {
                    return new Promise((function (resolve, reject) {
                                  Curry.app(sendActions, [
                                        writeToBranch,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (branch) {
                                            resolve(branch);
                                          }),
                                        (function (error) {
                                            reject(error);
                                          }),
                                        [[
                                            "StartedImport",
                                            {}
                                          ]]
                                      ]);
                                }));
                  }).then(function (branch) {
                  return Import.lastActionOnBranchQuery(workspace.id, NamedBranch.getId(branch)).get().then(function (snapshot) {
                              var lastAction = Belt_Option.mapU(Belt_Array.get(snapshot.docs, 0), (function (doc) {
                                      return FirebaseFetcherHooks.transformAction(doc.data());
                                    }));
                              return [
                                      branch,
                                      lastAction
                                    ];
                            });
                }).then(function (param) {
                var modelWithImport = Belt_Array.reduceU(importActions, model, ActionsReducer.reduce);
                return ImportUtils.snapshotAndWriteImportActions(workspace, param[0], modelWithImport, param[1], parsedTrackingPlan, newAndUpdatedProperties, importActions, sendActions, importStartedLocation, viewerId, onSuccess, schemaBundle, issues, model, addToast).then(function (param) {
                            return Promise.resolve();
                          });
              }), (function (error) {
              console.error(error);
              return Promise.resolve(Curry._1(addToast, {
                              message: "Something went wrong when importing the tracking plan.\n" + PromiseExt.getErrorMessage(error),
                              toastType: "Error",
                              persist: true
                            }));
            })).finally(function (param) {
          Curry._1(onClose, undefined);
        });
  };
  var onReviewAccepted = function (onSuccess, issues, selectedBranch, parsedTrackingPlan) {
    var newAndUpdatedProperties = Belt_Array.concat(parsedTrackingPlan.newProperties, parsedTrackingPlan.updatedProperties);
    var importActions = Belt_List.toArray(ImportAction.buildActions(parsedTrackingPlan, model, importMethod, Curry._1(Domains.toArray, existingDomains)));
    var permissionLevel = GlobalRequirementsUtils.getActionPermissionLevel(mappedGlobalRequirements, mappedProperties, viewerRole, Belt_Array.map(importActions, (function (action) {
                return [
                        action,
                        {}
                      ];
              })));
    if (permissionLevel !== "Allowed") {
      Promise.resolve(Curry._1(globalSend, {
                TAG: "OpenModal",
                _0: GlobalRequirementsUtils.globalRequirementsBlockedModal(viewerRole, permissionLevel, (function (param) {
                        proceedWithImport(selectedBranch, sendActions, schemaBundle, importActions, parsedTrackingPlan, model, issues, newAndUpdatedProperties, onSuccess);
                      }), (function (param) {
                        Curry._1(onClose, undefined);
                      }))
              }));
    } else {
      Promise.resolve(proceedWithImport(selectedBranch, sendActions, schemaBundle, importActions, parsedTrackingPlan, model, issues, newAndUpdatedProperties, onSuccess));
    }
  };
  var containerRef = React.useRef(null);
  var match = React.useState(function (param) {
        
      });
  var setInitialHeight = match[1];
  React.useEffect(function (param) {
        var el = containerRef.current;
        if (!(el == null)) {
          Curry._1(setInitialHeight, (function (param) {
                  return el.clientHeight;
                }));
        }
        
      });
  return React.createElement("div", {
              ref: containerRef,
              className: Curry._1(Css.merge, {
                    hd: contentStyles,
                    tl: {
                      hd: Belt_Option.mapWithDefault(match[0], "", (function (height) {
                              return Curry._1(Css.style, {
                                          hd: Css.minHeight(Css.px(height)),
                                          tl: /* [] */0
                                        });
                            })),
                      tl: /* [] */0
                    }
                  }),
              onClick: (function (prim) {
                  prim.stopPropagation();
                })
            }, React.createElement("button", {
                  "aria-label": "Close",
                  className: closeButtonStyles,
                  onClick: (function (param) {
                      Curry._1(onClose, undefined);
                    })
                }, React.createElement(Icon.make, {
                      type_: "close",
                      size: "large",
                      color: Styles.Color.white
                    })), React.createElement(ImportReview__V2.make, {
                  currentBranch: currentBranch,
                  importStartedLocation: importStartedLocation,
                  onContinue: onReviewAccepted,
                  openBranches: openBranches,
                  parsedTrackingPlan: importModel,
                  viewerRole: viewerRole,
                  importMethod: importMethod
                }));
}

var make = ImportReviewModal;

export {
  contentStyles ,
  closeButtonStyles ,
  make ,
}
/* contentStyles Not a pure module */
