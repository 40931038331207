// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Asset from "./Asset.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as IconX from "./IconX.mjs";
import * as Icons from "./Icons.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "./DateFns.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as StripeUtils from "./StripeUtils.mjs";
import * as TrialRequest from "./TrialRequest.mjs";
import * as React$1 from "motion/react";
import * as StripeBilling from "./StripeBilling.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as SegmentControl from "./SegmentControl.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as IconArrowTopRight from "./IconArrowTopRight.mjs";
import * as IconCheckmarkCircle from "./IconCheckmarkCircle.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function getPlanPrice(planName) {
  switch (planName) {
    case "Empty" :
    case "Scholarship" :
    case "Growth" :
    case "Enterprise" :
    case "EnterpriseV2" :
        return ;
    default:
      return Workspace.Instances.planFromPlanName(planName).basePricePerMonth;
  }
}

function getSeatPrice(planName) {
  switch (planName) {
    case "Empty" :
    case "Scholarship" :
    case "Growth" :
    case "Enterprise" :
    case "EnterpriseV2" :
        return ;
    default:
      return Workspace.Instances.planFromPlanName(planName).costPerExtraSeat;
  }
}

function planStyles(featured) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.px(240)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.border(Css.px(1), "solid", featured ? Styles.Color.light04 : Styles.Color.light02),
                  tl: {
                    hd: Css.borderRadius(Css.px(10)),
                    tl: {
                      hd: Css.marginLeft(featured ? Css.px(-1) : Css.px(0)),
                      tl: {
                        hd: Css.marginRight(featured ? Css.px(-1) : Css.px(0)),
                        tl: {
                          hd: Css.zIndex(featured ? 100 : 0),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var planHeaderStyles = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(12)),
      tl: {
        hd: Css.paddingBottom(Css.px(8)),
        tl: {
          hd: Css.paddingLeft(Css.px(20)),
          tl: {
            hd: Css.paddingRight(Css.px(20)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
              tl: /* [] */0
            }
          }
        }
      }
    });

var planContentStyles = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(20)),
      tl: {
        hd: Css.paddingRight(Css.px(20)),
        tl: {
          hd: Css.paddingBottom(Css.px(16)),
          tl: {
            hd: Css.paddingLeft(Css.px(20)),
            tl: /* [] */0
          }
        }
      }
    });

var linkStyles = Curry._1(Css.style, {
      hd: Css.display("inlineFlex"),
      tl: {
        hd: Css.color(Styles.Color.darkBlue),
        tl: {
          hd: Css.hover({
                hd: Css.color(Styles.Color.darkBlue),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var selectedStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.backgroundColor(Styles.Color.light01),
          tl: {
            hd: Css.width(Css.pct(100.0)),
            tl: {
              hd: Css.paddingLeft(Css.px(12)),
              tl: {
                hd: Css.height(Css.px(40)),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.marginBottom(Css.px(24)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var trialingBoxStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.avoPink, 0.1)),
          tl: {
            hd: Css.width(Css.pct(100.0)),
            tl: {
              hd: Css.height(Css.px(40)),
              tl: {
                hd: Css.paddingLeft(Css.px(12)),
                tl: {
                  hd: Css.paddingRight(Css.px(4)),
                  tl: {
                    hd: Css.marginBottom(Css.px(24)),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.selector(" svg", {
                              hd: Css.flexShrink(0.0),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function getPlanFeatures(plan) {
  switch (plan) {
    case "Free" :
        return [
                "1 publishing integration",
                "3 sources",
                "1 destination",
                "Limited event configuration",
                "Community Support"
              ];
    case "Empty" :
    case "Scholarship" :
    case "Growth" :
        return [];
    case "GrowthV2" :
        return [
                "5 publishing integrations",
                "7d observability lookback",
                "Standard code generation",
                "Approval workflows",
                "Priority Support"
              ];
    case "Starter" :
        return [
                "2 publishing integrations",
                "1d observability lookback",
                "Standard code generation",
                "Branch and communicate",
                "Standard Support"
              ];
    case "FreeV2" :
    case "FreeV3" :
        return [
                "1 publishing integration",
                "Realtime observability",
                "Standard code generation",
                "Branched workflows",
                "Community Support"
              ];
    case "Enterprise" :
    case "EnterpriseV2" :
        return [
                "5+ publishing integrations",
                "30d observability lookback",
                "Custom code generation",
                "SAML SSO",
                "Dedicated Support"
              ];
    default:
      return [
              "5 publishing integrations",
              "7d observability lookback",
              "Standard code generation",
              "Branch and communicate",
              "Standard Support"
            ];
  }
}

function BillingPlans$Plan(Props) {
  var planName = Props.planName;
  var currentPlanName = Props.currentPlanName;
  var onCheckout = Props.onCheckout;
  var onDowngrade = Props.onDowngrade;
  var workspace = WorkspaceContext.use();
  var schemaBundle = SchemaBundleContext.use();
  var globalSend = GlobalSendContext.use();
  var match = TrialRequest.useTrialRequest();
  var trialStatus = match[0];
  var trackUpgrade = function (planName, buttonCopy) {
    AnalyticsRe.subscriptionUpgradeStarted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, AnalyticsUtils.planToAnalyticsPlan(planName), AnalyticsUtils.planToAnalyticsPlanSlug(planName), getPlanPrice(planName), planName === "TeamV2Annual" || planName === "Enterprise" ? "Annual" : "Monthly", buttonCopy, schemaBundle.branchId, schemaBundle.schemaId);
  };
  var handleContactSupport = function (plan, direction) {
    if (Intercom.isAvailable()) {
      return Intercom.showNewMessage("Hey! I'd like to " + direction + " to the " + plan + " plan for my workspace.");
    } else {
      window.location.href = "mailto:sales@avo.app?subject=" + direction + " my Avo workspace plan to " + plan;
      return ;
    }
  };
  var featured = planName === "TeamV2Annual";
  var match$1 = Workspace.Instances.planFromPlanName(planName).usersIncluded;
  var tmp;
  var exit = 0;
  if (match$1 === "Unlimited") {
    tmp = "Starting at 10 editors";
  } else {
    switch (planName) {
      case "TeamV2" :
      case "TeamV2Annual" :
          exit = 1;
          break;
      default:
        tmp = match$1.VAL.toString() + " editors";
    }
  }
  if (exit === 1) {
    tmp = "Starting at " + match$1.VAL.toString() + " editors";
  }
  var match$2 = getSeatPrice(planName);
  var match$3 = getPlanPrice(planName);
  var tmp$1;
  var exit$1 = 0;
  var exit$2 = 0;
  switch (planName) {
    case "TeamV2" :
    case "TeamV2Annual" :
        exit$2 = 2;
        break;
    default:
      exit$1 = 1;
  }
  if (exit$2 === 2) {
    if (match$2 !== undefined) {
      tmp$1 = "$" + match$2.toString() + " ";
    } else {
      exit$1 = 1;
    }
  }
  if (exit$1 === 1) {
    tmp$1 = match$3 !== undefined ? "$" + match$3.toString() + " " : "Custom ";
  }
  var match$4 = getPlanPrice(planName);
  var tmp$2;
  switch (planName) {
    case "TeamV2" :
    case "TeamV2Annual" :
        tmp$2 = "per user per month";
        break;
    default:
      tmp$2 = match$4 !== undefined ? "per month" : null;
  }
  var tmp$3;
  switch (planName) {
    case "TeamV2" :
        tmp$3 = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light10,
                  maxWidth: Css.px(180),
                  children: "billed month-to-month"
                }));
        break;
    case "TeamV2Annual" :
        tmp$3 = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light10,
                  maxWidth: Css.px(180),
                  children: "billed annually, or $" + (Workspace.Instances.planFromPlanName("TeamV2").costPerExtraSeat.toString() + " per user month-to-month")
                }));
        break;
    default:
      tmp$3 = null;
  }
  return React.createElement("article", {
              className: planStyles(featured)
            }, React.createElement("header", {
                  className: planHeaderStyles
                }, React.createElement(Title.make, {
                      children: Workspace.printPlanName(planName),
                      size: "Small"
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light11,
                      children: tmp
                    }), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement($$Text.make, {
                      size: "Huge",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: null
                    }, tmp$1, React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          weight: "Regular",
                          color: Styles.Color.light11,
                          children: tmp$2
                        })), tmp$3, Belt_Option.isSome(onCheckout) ? React.createElement(Spacer.make, {
                        height: 12
                      }) : null, Belt_Option.mapWithDefault(onCheckout, null, (function (onCheckout) {
                        var exit = 0;
                        var planName$1;
                        var planName$2;
                        var planName$3;
                        var exit$1 = 0;
                        var exit$2 = 0;
                        switch (currentPlanName) {
                          case "GrowthV2" :
                              switch (planName) {
                                case "GrowthV2" :
                                    exit = 2;
                                    break;
                                case "Free" :
                                case "FreeV2" :
                                case "FreeV3" :
                                    planName$3 = planName;
                                    exit = 5;
                                    break;
                                case "Enterprise" :
                                case "EnterpriseV2" :
                                    planName$2 = planName;
                                    exit = 4;
                                    break;
                                default:
                                  exit$1 = 6;
                              }
                              break;
                          case "Starter" :
                              switch (planName) {
                                case "Starter" :
                                    exit = 2;
                                    break;
                                case "Free" :
                                case "FreeV2" :
                                case "FreeV3" :
                                    planName$3 = planName;
                                    exit = 5;
                                    break;
                                case "TeamV2" :
                                case "TeamV2Annual" :
                                case "TeamV3" :
                                case "TeamV3Annual" :
                                    exit = 1;
                                    break;
                                case "Enterprise" :
                                case "EnterpriseV2" :
                                    planName$2 = planName;
                                    exit = 4;
                                    break;
                                default:
                                  exit$1 = 6;
                              }
                              break;
                          case "Free" :
                          case "FreeV2" :
                          case "FreeV3" :
                              exit$2 = 7;
                              break;
                          case "TeamV2" :
                          case "TeamV3" :
                              exit$2 = 8;
                              break;
                          case "TeamV2Annual" :
                          case "TeamV3Annual" :
                              exit$2 = 9;
                              break;
                          case "Enterprise" :
                          case "EnterpriseV2" :
                              exit$2 = 10;
                              break;
                          default:
                            exit = 1;
                        }
                        switch (exit$2) {
                          case 7 :
                              var exit$3 = 0;
                              switch (planName) {
                                case "Starter" :
                                    planName$1 = planName;
                                    exit = 3;
                                    break;
                                case "Free" :
                                case "FreeV2" :
                                case "FreeV3" :
                                    exit = 2;
                                    break;
                                case "TeamV2" :
                                case "TeamV2Annual" :
                                case "TeamV3" :
                                case "TeamV3Annual" :
                                    exit$3 = 11;
                                    break;
                                case "Enterprise" :
                                case "EnterpriseV2" :
                                    planName$2 = planName;
                                    exit = 4;
                                    break;
                                default:
                                  exit$1 = 6;
                              }
                              if (exit$3 === 11) {
                                if (Belt_Option.isSome(workspace.trial)) {
                                  return React.createElement("div", {
                                              className: trialingBoxStyles
                                            }, React.createElement(Icons.Timer.make, {
                                                  size: 16,
                                                  color: Styles.Color.avoPinkShade2
                                                }), React.createElement(Spacer.make, {
                                                  width: 12
                                                }), React.createElement($$Text.make, {
                                                  size: "Small",
                                                  color: Styles.Color.avoPinkShade2,
                                                  children: null
                                                }, DateFns.formatDistanceToNow(false, false, Belt_Option.mapWithDefault(workspace.trial, new Date(), (function (trial) {
                                                            return trial.endAt;
                                                          }))) + " left of your trial. ", React.createElement(TextButton.make, {
                                                      onClick: (function (param) {
                                                          trackUpgrade("TeamV2", "Upgrade now");
                                                          Curry._1(onCheckout, undefined);
                                                        }),
                                                      style: "MagentaSecondary",
                                                      children: "Upgrade now"
                                                    })));
                                }
                                if (trialStatus === "Available") {
                                  return React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                                                  label: "Try for free",
                                                  onClick: (function (param) {
                                                      Curry._1(globalSend, {
                                                            TAG: "OpenModal",
                                                            _0: {
                                                              NAME: "BillingPrompt",
                                                              VAL: "TryForFree"
                                                            }
                                                          });
                                                    }),
                                                  size: "large"
                                                }), React.createElement(Spacer.make, {
                                                  height: 6
                                                }), React.createElement($$Text.make, {
                                                  size: "Small",
                                                  textAlign: "center",
                                                  color: Styles.Color.light10,
                                                  children: null
                                                }, "Or ", React.createElement(TextButton.make, {
                                                      onClick: (function (param) {
                                                          trackUpgrade(planName, "Upgrade now");
                                                          Curry._1(onCheckout, undefined);
                                                        }),
                                                      style: "Magenta",
                                                      size: "Small",
                                                      children: "upgrade now"
                                                    })));
                                }
                                planName$1 = planName;
                                exit = 3;
                              }
                              break;
                          case 8 :
                              var exit$4 = 0;
                              switch (planName) {
                                case "Starter" :
                                    exit = 1;
                                    break;
                                case "Free" :
                                case "FreeV2" :
                                case "FreeV3" :
                                    planName$3 = planName;
                                    exit = 5;
                                    break;
                                case "TeamV2" :
                                case "TeamV3" :
                                    exit = 2;
                                    break;
                                case "TeamV2Annual" :
                                case "TeamV3Annual" :
                                    exit$4 = 11;
                                    break;
                                case "Enterprise" :
                                case "EnterpriseV2" :
                                    planName$2 = planName;
                                    exit = 4;
                                    break;
                                default:
                                  exit$1 = 6;
                              }
                              if (exit$4 === 11) {
                                return React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                                                label: "Upgrade to annual",
                                                onClick: (function (param) {
                                                    trackUpgrade(planName, "Upgrade to annual");
                                                    handleContactSupport(Workspace.printPlanName(planName), "upgrade");
                                                  }),
                                                size: "large"
                                              }), React.createElement(Spacer.make, {
                                                height: 24
                                              }));
                              }
                              break;
                          case 9 :
                              switch (planName) {
                                case "Starter" :
                                    exit = 1;
                                    break;
                                case "Free" :
                                case "FreeV2" :
                                case "TeamV2" :
                                case "FreeV3" :
                                case "TeamV3" :
                                    planName$3 = planName;
                                    exit = 5;
                                    break;
                                case "TeamV2Annual" :
                                case "TeamV3Annual" :
                                    exit = 2;
                                    break;
                                case "Enterprise" :
                                case "EnterpriseV2" :
                                    planName$2 = planName;
                                    exit = 4;
                                    break;
                                default:
                                  exit$1 = 6;
                              }
                              break;
                          case 10 :
                              switch (planName) {
                                case "Free" :
                                case "FreeV2" :
                                case "GrowthV2" :
                                case "FreeV3" :
                                    planName$3 = planName;
                                    exit = 5;
                                    break;
                                case "Enterprise" :
                                case "EnterpriseV2" :
                                    exit = 2;
                                    break;
                                default:
                                  exit$1 = 6;
                              }
                              break;
                          
                        }
                        if (exit$1 === 6) {
                          switch (currentPlanName) {
                            case "GrowthV2" :
                            case "Enterprise" :
                            case "EnterpriseV2" :
                                planName$3 = planName;
                                exit = 5;
                                break;
                            default:
                              if (planName !== "GrowthV2") {
                                return null;
                              }
                              planName$2 = planName;
                              exit = 4;
                          }
                        }
                        switch (exit) {
                          case 1 :
                              return React.createElement(Button.make, {
                                          label: "Get a quote",
                                          onClick: (function (param) {
                                              trackUpgrade(planName, "Get a quote");
                                              handleContactSupport(Workspace.printPlanName(planName), "upgrade");
                                            }),
                                          size: "large",
                                          style: "outline"
                                        });
                          case 2 :
                              return React.createElement("div", {
                                          className: selectedStyles
                                        }, React.createElement(IconCheckmarkCircle.make, {
                                              size: 16,
                                              color: Styles.Color.light10
                                            }), React.createElement(Spacer.make, {
                                              width: 8
                                            }), React.createElement($$Text.make, {
                                              size: "Small",
                                              color: Styles.Color.light10,
                                              children: "Your current plan"
                                            }), React.createElement(Spacer.make, {
                                              height: 24
                                            }));
                          case 3 :
                              return React.createElement(Button.make, {
                                          label: "Upgrade",
                                          onClick: (function (param) {
                                              trackUpgrade(planName$1, "Upgrade");
                                              Curry._1(onCheckout, undefined);
                                            }),
                                          size: "large"
                                        });
                          case 4 :
                              return React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                                              label: "Get a quote",
                                              onClick: (function (param) {
                                                  trackUpgrade(planName$2, "Get a quote");
                                                  handleContactSupport(Workspace.printPlanName(planName$2), "upgrade");
                                                }),
                                              size: "large",
                                              style: "outline"
                                            }), React.createElement(Spacer.make, {
                                              height: 24
                                            }));
                          case 5 :
                              return React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                                              label: "Downgrade",
                                              onClick: (function (param) {
                                                  if (onDowngrade !== undefined) {
                                                    var exit = 0;
                                                    switch (currentPlanName) {
                                                      case "GrowthV2" :
                                                      case "Starter" :
                                                      case "TeamV2" :
                                                      case "TeamV3" :
                                                          exit = 2;
                                                          break;
                                                      default:
                                                        
                                                    }
                                                    if (exit === 2) {
                                                      switch (planName$3) {
                                                        case "FreeV2" :
                                                        case "FreeV3" :
                                                            return Curry._1(onDowngrade, planName$3);
                                                        default:
                                                          
                                                      }
                                                    }
                                                    
                                                  }
                                                  AnalyticsRe.subscriptionDowngradeStarted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, AnalyticsUtils.planToAnalyticsPlan(planName$3), AnalyticsUtils.planToAnalyticsPlanSlug(planName$3), getPlanPrice(planName$3), "Monthly", "Downgrade", schemaBundle.branchId, schemaBundle.schemaId);
                                                  handleContactSupport(Workspace.printPlanName(planName$3), "downgrade");
                                                }),
                                              size: "large",
                                              style: "outline"
                                            }), React.createElement(Spacer.make, {
                                              height: 24
                                            }));
                          
                        }
                      }))), React.createElement("div", {
                  className: planContentStyles
                }, Belt_Array.map(getPlanFeatures(planName), (function (feature) {
                        return React.createElement(React.Fragment, {
                                    children: null,
                                    key: feature
                                  }, React.createElement($$Text.make, {
                                        size: "Small",
                                        color: Styles.Color.light11,
                                        children: feature
                                      }), React.createElement(Spacer.make, {
                                        height: 6
                                      }));
                      })), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement("a", {
                      className: linkStyles,
                      href: "https://avo.app/pricing",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          size: "Small",
                          weight: "Semi",
                          children: "Learn more"
                        }), React.createElement(Spacer.make, {
                          display: "inlineBlock",
                          width: 2
                        }), React.createElement(IconArrowTopRight.make, {
                          size: 6,
                          color: Styles.Color.darkBlue
                        }))));
}

var Plan = {
  planStyles: planStyles,
  planHeaderStyles: planHeaderStyles,
  planContentStyles: planContentStyles,
  linkStyles: linkStyles,
  selectedStyles: selectedStyles,
  trialingBoxStyles: trialingBoxStyles,
  getPlanFeatures: getPlanFeatures,
  make: BillingPlans$Plan
};

var checkoutStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(720)),
      tl: {
        hd: Css.paddingTop(Css.px(48)),
        tl: {
          hd: Css.paddingRight(Css.px(120)),
          tl: {
            hd: Css.paddingBottom(Css.px(48)),
            tl: {
              hd: Css.paddingLeft(Css.px(48)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var planStyles$1 = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(10)),
      tl: {
        hd: Css.boxShadow(Styles.Shadow.standard),
        tl: /* [] */0
      }
    });

var contentStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("flexStart"),
        tl: /* [] */0
      }
    });

var checkoutRow = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var checkoutSeparator = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
      tl: /* [] */0
    });

var paymentReceivedStyles = Curry._1(Css.style, {
      hd: Css.marginBottom(Css.px(16)),
      tl: {
        hd: Css.maxWidth(Css.px(400)),
        tl: {
          hd: Css.paddingTop(Css.px(8)),
          tl: {
            hd: Css.paddingRight(Css.px(16)),
            tl: {
              hd: Css.paddingBottom(Css.px(8)),
              tl: {
                hd: Css.paddingLeft(Css.px(16)),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.green),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var paymentReceivedRowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginBottom(Css.px(4)),
          tl: /* [] */0
        }
      }
    });

var switcherBoxStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(16)),
      tl: {
        hd: Css.display("flex"),
        tl: /* [] */0
      }
    });

function BillingPlans$Checkout(Props) {
  var onContactSupport = Props.onContactSupport;
  var workspace = WorkspaceContext.use();
  var match = React.useState(function (param) {
        return "Upgrade";
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function (param) {
        return "Annual";
      });
  var setPeriod = match$1[1];
  var period = match$1[0];
  var callbackPeriod = React.useRef(period);
  React.useEffect((function (param) {
          callbackPeriod.current = period;
        }), [period]);
  var schemaBundle = SchemaBundleContext.use();
  var tmp;
  var exit = 0;
  switch (state) {
    case "Upgrade" :
    case "UpgradeLoading" :
        exit = 1;
        break;
    case "ThankYou" :
        tmp = React.createElement(React$1.motion.div, {
              animate: {
                opacity: 1,
                x: 0
              },
              transition: {
                duration: 0.3
              },
              initial: {
                opacity: 0,
                x: 100
              },
              exit: {
                opacity: 0,
                x: -100
              },
              className: checkoutStyles,
              key: "ThankYou",
              children: null
            }, React.createElement("header", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(16)),
                        tl: {
                          hd: Css.marginBottom(Css.px(8)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(Title.make, {
                      children: "Thank you!",
                      size: "Medium"
                    })), React.createElement("img", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.px(340)),
                        tl: /* [] */0
                      }),
                  src: Asset.getPath("AvoShipIt.png")
                }), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(16)),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: paymentReceivedStyles
                    }, React.createElement("div", {
                          className: paymentReceivedRowStyles
                        }, React.createElement(IconCheckmarkCircle.make, {
                              color: Styles.Color.green
                            }), React.createElement(Spacer.make, {
                              width: 4
                            }), React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.light11,
                              children: "Payment Received!"
                            })), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.light10,
                          children: "Look for a payment from Avo Software Inc. on your card"
                        })), React.createElement($$Text.make, {
                      size: "Large",
                      color: Styles.Color.light10,
                      maxWidth: Css.px(440),
                      children: null
                    }, "We’re excited you’ve decided to upgrade to the ", React.createElement($$Text.make, {
                          element: "Strong",
                          weight: "Semi",
                          children: "Team Plan"
                        }), ". Our team is here to support you in your journey toward better analytics governance. If you have any questions please ", React.createElement(TextButton.make, {
                          onClick: onContactSupport,
                          style: "Blue",
                          size: "Large",
                          children: "reach out"
                        }), ".")));
        break;
    
  }
  if (exit === 1) {
    var tmp$1;
    if (period === "Annual") {
      var price = Workspace.Instances.planFromPlanName("TeamV2Annual").basePricePerMonth * 12.0;
      tmp$1 = "$" + price.toString() + "/year";
    } else {
      var price$1 = Workspace.Instances.planFromPlanName("TeamV2").basePricePerMonth;
      tmp$1 = "$" + price$1.toString() + "/month";
    }
    var tmp$2;
    if (period === "Annual") {
      var price$2 = Workspace.Instances.planFromPlanName("TeamV2Annual").basePricePerMonth * 12.0;
      tmp$2 = "$" + price$2.toString() + "/year";
    } else {
      var price$3 = Workspace.Instances.planFromPlanName("TeamV2").basePricePerMonth;
      tmp$2 = "$" + price$3.toString() + "/month";
    }
    tmp = React.createElement(React$1.motion.div, {
          animate: {
            opacity: 1,
            x: 0
          },
          transition: {
            duration: 0.3
          },
          initial: {
            opacity: 0,
            x: 100
          },
          exit: {
            opacity: 0,
            x: -100
          },
          className: checkoutStyles,
          key: "Upgrade",
          children: null
        }, React.createElement("header", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingLeft(Css.px(16)),
                    tl: {
                      hd: Css.marginBottom(Css.px(24)),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement(Title.make, {
                  children: "Checkout",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.light10,
                  maxWidth: Css.px(440),
                  children: null
                }, "We’re here to help your team scale! If you have any questions please ", React.createElement(TextButton.make, {
                      onClick: onContactSupport,
                      style: "Blue",
                      size: "Large",
                      children: "reach out"
                    }), ".")), React.createElement("div", {
              className: switcherBoxStyles
            }, React.createElement(SegmentControl.make, {
                  options: [
                    [
                      "Annual",
                      "Annual"
                    ],
                    [
                      "Monthly",
                      "Monthly"
                    ]
                  ],
                  onSelect: (function (period) {
                      Curry._1(setPeriod, (function (param) {
                              return period;
                            }));
                    }),
                  selected: period
                })), React.createElement(Spacer.make, {
              height: 20
            }), React.createElement("div", {
              className: contentStyles
            }, React.createElement("div", {
                  className: planStyles$1
                }, React.createElement(BillingPlans$Plan, {
                      planName: period === "Annual" ? "TeamV2Annual" : "TeamV2",
                      currentPlanName: "Free"
                    })), React.createElement(Spacer.make, {
                  width: 48
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: /* [] */0
                      })
                }, React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "Checkout Details"
                    }), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement("div", {
                      className: checkoutRow
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: "Team Plan Base"
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: tmp$1
                        })), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement("div", {
                      className: checkoutSeparator
                    }), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement("div", {
                      className: checkoutRow
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Total"
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.darkBlue,
                          children: tmp$2
                        })), React.createElement(Spacer.make, {
                      height: 12
                    }), React.createElement(StripeBilling.PaymentInput.make, {
                      schemaId: workspace.id,
                      onSubmitStart: (function (param) {
                          Curry._1(setState, (function (param) {
                                  return "UpgradeLoading";
                                }));
                        }),
                      onSubmitError: (function (param) {
                          Curry._1(setState, (function (param) {
                                  return "Upgrade";
                                }));
                        }),
                      onSubmitSuccess: (function (token, promoCode) {
                          AnalyticsRe.subscriptionUpgradeModalInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, AnalyticsUtils.planToAnalyticsPlan(callbackPeriod.current === "Annual" ? "TeamV2Annual" : "TeamV2"), AnalyticsUtils.planToAnalyticsPlanSlug(callbackPeriod.current === "Annual" ? "TeamV2Annual" : "TeamV2"), getPlanPrice(callbackPeriod.current === "Annual" ? "TeamV2Annual" : "TeamV2"), callbackPeriod.current === "Annual" ? "Annual" : "Monthly", "ClickCompleteUpgrade", undefined, undefined, undefined, schemaBundle.branchId, schemaBundle.schemaId);
                          return $$Promise.$$catch(StripeBilling.createTeamV2Subscription(workspace.id, token, callbackPeriod.current === "Annual", promoCode).then(function (result) {
                                          if (result.TAG === "Ok") {
                                            Curry._1(setState, (function (param) {
                                                    return "ThankYou";
                                                  }));
                                            return Promise.resolve();
                                          }
                                          Curry._1(setState, (function (param) {
                                                  return "Upgrade";
                                                }));
                                          window.alert("Could not complete upgrade: " + result._0);
                                          return Promise.resolve();
                                        }), (function (error) {
                                        Curry._1(setState, (function (param) {
                                                return "Upgrade";
                                              }));
                                        console.log("Failed to upgrade", error);
                                        Sentry.captureException(error, {});
                                        window.alert("Something went wrong when upgrading");
                                        return Promise.resolve();
                                      }));
                        }),
                      isLoading: state === "UpgradeLoading",
                      label: "Complete Upgrade"
                    }))));
  }
  return React.createElement(React$1.AnimatePresence, {
              mode: "wait",
              initial: false,
              children: tmp
            });
}

var Checkout = {
  checkoutStyles: checkoutStyles,
  planStyles: planStyles$1,
  contentStyles: contentStyles,
  checkoutRow: checkoutRow,
  checkoutSeparator: checkoutSeparator,
  paymentReceivedStyles: paymentReceivedStyles,
  paymentReceivedRowStyles: paymentReceivedRowStyles,
  switcherBoxStyles: switcherBoxStyles,
  make: BillingPlans$Checkout
};

var rootStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(720)),
      tl: {
        hd: Css.paddingTop(Css.px(48)),
        tl: {
          hd: Css.paddingRight(Css.px(64)),
          tl: {
            hd: Css.paddingBottom(Css.px(48)),
            tl: {
              hd: Css.paddingLeft(Css.px(64)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function BillingPlans$UpdatePayment(Props) {
  var onContactSupport = Props.onContactSupport;
  var subscription = Props.subscription;
  var workspaceId = Props.workspaceId;
  var match = React.useState(function (param) {
        return "Update";
      });
  var setState = match[1];
  var state = match[0];
  var tmp;
  switch (state) {
    case "Update" :
    case "UpdateLoading" :
        tmp = "update";
        break;
    case "ThankYou" :
        tmp = "thankYou";
        break;
    
  }
  var tmp$1;
  var exit = 0;
  switch (state) {
    case "Update" :
    case "UpdateLoading" :
        exit = 1;
        break;
    case "ThankYou" :
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(16)),
                        tl: {
                          hd: Css.marginBottom(Css.px(8)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(Title.make, {
                      children: "Your payment method has been updated!",
                      size: "Medium"
                    })), React.createElement("img", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.px(340)),
                        tl: /* [] */0
                      }),
                  src: Asset.getPath("AvoShipIt.png")
                }), React.createElement(Spacer.make, {
                  height: 40
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(16)),
                        tl: /* [] */0
                      })
                }, React.createElement($$Text.make, {
                      size: "Large",
                      color: Styles.Color.light10,
                      maxWidth: Css.px(440),
                      children: null
                    }, "Our team is here to support you in your journey toward better analytics governance. If you have any questions please ", React.createElement(TextButton.make, {
                          onClick: onContactSupport,
                          style: "Blue",
                          size: "Large",
                          children: "reach out"
                        }), ".")));
        break;
    
  }
  if (exit === 1) {
    tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
              children: "Update Payment Method",
              size: "Medium"
            }), React.createElement(Spacer.make, {
              height: 12
            }), React.createElement($$Text.make, {
              size: "Large",
              color: Styles.Color.light10,
              maxWidth: Css.px(440),
              children: null
            }, "If you have any questions please ", React.createElement(TextButton.make, {
                  onClick: onContactSupport,
                  style: "Blue",
                  size: "Large",
                  children: "reach out"
                }), "."), React.createElement(Spacer.make, {
              height: 60
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.maxWidth(Css.px(360)),
                    tl: /* [] */0
                  })
            }, React.createElement(StripeBilling.PaymentInput.make, {
                  schemaId: workspaceId,
                  onSubmitStart: (function (param) {
                      Curry._1(setState, (function (param) {
                              return "UpdateLoading";
                            }));
                    }),
                  onSubmitError: (function (param) {
                      Curry._1(setState, (function (param) {
                              return "Update";
                            }));
                    }),
                  onSubmitSuccess: (function (token, promoCode) {
                      return $$Promise.$$catch(StripeBilling.updatePayingMethod(workspaceId, token, subscription, promoCode).then(function (response) {
                                      if (response.status === 200) {
                                        Curry._1(setState, (function (param) {
                                                return "ThankYou";
                                              }));
                                        return Promise.resolve();
                                      } else {
                                        Curry._1(setState, (function (param) {
                                                return "Update";
                                              }));
                                        window.alert("Something went wrong. Could not update payment method.");
                                        return Promise.resolve();
                                      }
                                    }), (function (error) {
                                    Curry._1(setState, (function (param) {
                                            return "Update";
                                          }));
                                    return Promise.resolve((console.log(error), undefined));
                                  }));
                    }),
                  isLoading: state === "UpdateLoading",
                  label: "Update Card"
                })));
  }
  return React.createElement(React$1.AnimatePresence, {
              mode: "wait",
              initial: false,
              children: React.createElement(React$1.motion.div, {
                    animate: {
                      opacity: 1,
                      x: 0
                    },
                    transition: {
                      duration: 0.3
                    },
                    initial: {
                      opacity: 0,
                      x: 100
                    },
                    exit: {
                      opacity: 0,
                      x: -100
                    },
                    className: rootStyles,
                    key: tmp,
                    children: tmp$1
                  })
            });
}

var UpdatePayment = {
  rootStyles: rootStyles,
  make: BillingPlans$UpdatePayment
};

var rootStyles$1 = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(320)),
      tl: {
        hd: Css.paddingLeft(Css.px(20)),
        tl: {
          hd: Css.marginTop(Css.px(24)),
          tl: /* [] */0
        }
      }
    });

var rowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: {
              hd: Css.paddingTop(Css.px(8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function BillingPlans$StripeSubscription(Props) {
  var currentPlanName = Props.currentPlanName;
  var subscription = Props.subscription;
  var onUpdate = Props.onUpdate;
  var match = WorkspaceContext.use();
  var workspaceId = match.id;
  var currentPlanItems = subscription.items.data;
  var currentPlanIds = Belt_Array.map(currentPlanItems, (function (item) {
          return item.price.id;
        }));
  var maybeCurrentPlan = StripeUtils.getPrimaryPlanFromPlanIds(currentPlanIds, process.env.NODE_ENV === "development");
  var currentProductId = Belt_Option.map(maybeCurrentPlan, (function (currentPlan) {
          return currentPlan.prod.productId;
        }));
  var match$1 = React.useState(function (param) {
        return "Loading";
      });
  var setCurrentProductName = match$1[1];
  var currentProductName = match$1[0];
  React.useEffect((function (param) {
          Curry._1(setCurrentProductName, (function (param) {
                  return "Loading";
                }));
          Firebase.Auth.unsafeGetCurrentUser(Firebase.app().auth()).getIdToken().then(function (userToken) {
                    return fetch(Firebase.apiUrl + "/productName" + (
                                process.env.NODE_ENV === "development" ? "?dev=true" : ""
                              ), {
                                method: "POST",
                                body: Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                              [
                                                "productId",
                                                Belt_Option.getWithDefault(currentProductId, "")
                                              ],
                                              [
                                                "schemaId",
                                                workspaceId
                                              ]
                                            ]))),
                                headers: Caml_option.some(new Headers([
                                          [
                                            "Accept",
                                            "application/json"
                                          ],
                                          [
                                            "Content-Type",
                                            "application/json"
                                          ],
                                          [
                                            "Authorization",
                                            "Bearer " + userToken
                                          ]
                                        ]))
                              });
                  }).then(function (response) {
                  return response.json().then(function (json) {
                              return Promise.resolve([
                                          response,
                                          json
                                        ]);
                            });
                }).then(function (param) {
                if (param[0].status !== 200) {
                  Curry._1(setCurrentProductName, (function (param) {
                          return {
                                  NAME: "Loaded",
                                  VAL: "Custom"
                                };
                        }));
                } else {
                  var name = Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                              return Json_decode.field("name", Json_decode.string, param);
                            }), param[1]), "Custom");
                  Curry._1(setCurrentProductName, (function (param) {
                          return {
                                  NAME: "Loaded",
                                  VAL: name
                                };
                        }));
                }
                return Promise.resolve();
              });
        }), [currentProductId]);
  var tmp;
  switch (currentPlanName) {
    case "GrowthV2" :
    case "Starter" :
    case "TeamV2" :
    case "TeamV2Annual" :
        tmp = null;
        break;
    default:
      var match$2 = subscription.status;
      var exit = 0;
      switch (match$2) {
        case "active" :
            var planName = typeof currentProductName === "object" ? currentProductName.VAL : "...";
            tmp = React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "Your " + planName + " subscription is active"
                });
            break;
        case "incomplete" :
        case "past_due" :
        case "unpaid" :
            exit = 1;
            break;
        default:
          var planName$1 = typeof currentProductName === "object" ? currentProductName.VAL : "...";
          tmp = React.createElement($$Text.make, {
                size: "Medium",
                weight: "Semi",
                color: Styles.Color.red,
                children: "There has been an unexpected problem with you " + planName$1 + " subscription. Please contact support."
              });
      }
      if (exit === 1) {
        var planName$2 = typeof currentProductName === "object" ? currentProductName.VAL : "...";
        tmp = React.createElement($$Text.make, {
              size: "Medium",
              weight: "Semi",
              color: Styles.Color.red,
              children: "There was a problem with your " + planName$2 + " subscription. Please update your payment method."
            });
      }
      
  }
  return React.createElement("div", {
              className: rootStyles$1
            }, tmp, React.createElement(Spacer.make, {
                  height: 20
                }), React.createElement(Button.make, {
                  label: "Update Payment Method",
                  onClick: onUpdate
                }));
}

var StripeSubscription = {
  rootStyles: rootStyles$1,
  rowStyles: rowStyles,
  make: BillingPlans$StripeSubscription
};

var rootStyles$2 = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(44)),
      tl: {
        hd: Css.paddingRight(Css.px(64)),
        tl: {
          hd: Css.paddingBottom(Css.px(44)),
          tl: {
            hd: Css.paddingLeft(Css.px(44)),
            tl: /* [] */0
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: /* [] */0
    });

var plansStyles = Curry._1(Css.style, {
      hd: Css.width("fitContent"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("flexEnd"),
            tl: {
              hd: Css.borderRadius(Css.px(10)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var slideOverStyles = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.maxWidth(Css.vw(70.0)),
              tl: {
                hd: Css.minWidth(Css.px(320)),
                tl: {
                  hd: Css.overflow("auto"),
                  tl: {
                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.boxShadow(Styles.Shadow.standard),
                        tl: {
                          hd: Css.display("flex"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var slideOverCloseStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.top(Css.px(16)),
                tl: {
                  hd: Css.right(Css.px(16)),
                  tl: {
                    hd: Css.width(Css.px(32)),
                    tl: {
                      hd: Css.height(Css.px(32)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: {
                                hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light10),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light11),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var backdropStyles = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.zIndex(Styles.ZIndex.aboveAll),
                tl: {
                  hd: Css.backgroundColor({
                        NAME: "rgb",
                        VAL: [
                          0,
                          0,
                          0
                        ]
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function plansToDisplay(plan) {
  switch (plan) {
    case "Free" :
        return [
                "Free",
                "TeamV2Annual",
                "Enterprise"
              ];
    case "GrowthV2" :
        return [
                "FreeV2",
                "TeamV2Annual",
                "GrowthV2",
                "Enterprise"
              ];
    case "Starter" :
        return [
                "FreeV2",
                "Starter",
                "TeamV2Annual",
                "Enterprise"
              ];
    default:
      return [
              "FreeV2",
              "TeamV2Annual",
              "Enterprise"
            ];
  }
}

function BillingPlans(Props) {
  var currentPlanName = Props.currentPlanName;
  var payment = Props.payment;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use();
  var match = React.useState(function (param) {
        
      });
  var setSlideOver = match[1];
  var slideOver = match[0];
  var handleContactSupport = function (param) {
    if (Intercom.isAvailable()) {
      return Intercom.showNewMessage("Hey! I have a some questions about using Avo to help my team scale.");
    } else {
      window.location.href = "mailto:support@avo.app";
      return ;
    }
  };
  var handleCheckout = React.useCallback((function (param) {
          Curry._1(setSlideOver, (function (param) {
                  return "Checkout";
                }));
        }), []);
  var handleDowngrade = function (planName) {
    Belt_Option.mapWithDefault(payment, undefined, (function (payment) {
            if (window.confirm("Are you sure you would like to downgrade your subscription?")) {
              $$Promise.$$catch(StripeBilling.cancelSubscription(workspace.id, payment.subscription.id), (function (error) {
                      window.alert("Could not complete automatic downgrading. Please contact support");
                      console.log(error);
                      return Promise.resolve();
                    }));
              return AnalyticsRe.cancelSubscriptionButtonClicked(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, AnalyticsUtils.planToAnalyticsPlan(planName), schemaBundle.branchId, schemaBundle.schemaId);
            }
            
          }));
  };
  var tmp;
  if (slideOver !== undefined) {
    var tmp$1;
    tmp$1 = typeof slideOver !== "object" ? "checkout" : "updatePayment";
    var tmp$2;
    tmp$2 = typeof slideOver !== "object" ? React.createElement(BillingPlans$Checkout, {
            onContactSupport: handleContactSupport
          }) : React.createElement(BillingPlans$UpdatePayment, {
            onContactSupport: handleContactSupport,
            subscription: slideOver._0,
            workspaceId: workspace.id
          });
    tmp = React.createElement(React$1.motion.div, {
          animate: {
            opacity: 1,
            x: 0
          },
          transition: {
            duration: 0.3
          },
          initial: {
            opacity: 0,
            x: 50
          },
          exit: {
            opacity: 0,
            x: 50
          },
          className: slideOverStyles,
          key: tmp$1,
          children: null
        }, React.createElement("button", {
              className: slideOverCloseStyles,
              onClick: (function (param) {
                  Curry._1(setSlideOver, (function (param) {
                          
                        }));
                })
            }, React.createElement(IconX.make, {})), tmp$2);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: rootStyles$2
            }, React.createElement("header", {
                  className: headerStyles
                }, React.createElement(Title.make, {
                      children: "Upgrade Your Tracking Plan",
                      size: "Medium"
                    }), React.createElement(Spacer.make, {
                      height: 12
                    }), React.createElement($$Text.make, {
                      size: "Large",
                      color: Styles.Color.light10,
                      maxWidth: Css.px(440),
                      children: null
                    }, "We’re here to help your team scale! If you have any questions please ", React.createElement(TextButton.make, {
                          onClick: handleContactSupport,
                          style: "Blue",
                          size: "Large",
                          children: "reach out"
                        }), ".")), React.createElement(Spacer.make, {
                  height: 40
                }), React.createElement("div", {
                  className: plansStyles
                }, Belt_Array.mapU(plansToDisplay(currentPlanName), (function (planName) {
                        return React.createElement(BillingPlans$Plan, {
                                    planName: planName,
                                    currentPlanName: currentPlanName,
                                    onCheckout: handleCheckout,
                                    onDowngrade: handleDowngrade,
                                    key: Workspace.printExplicitPlanName(planName)
                                  });
                      }))), payment !== undefined && payment.subscription.status !== "canceled" ? React.createElement(BillingPlans$StripeSubscription, {
                    currentPlanName: currentPlanName,
                    subscription: payment.subscription,
                    onUpdate: (function (param) {
                        Curry._1(setSlideOver, (function (param) {
                                return {
                                        TAG: "UpdatePayment",
                                        _0: payment.subscription
                                      };
                              }));
                      })
                  }) : null, React.createElement(React$1.AnimatePresence, {
                  children: null
                }, slideOver !== undefined ? React.createElement(React$1.motion.div, {
                        animate: {
                          opacity: 0.4
                        },
                        transition: {
                          duration: 0.3
                        },
                        initial: {
                          opacity: 0.0
                        },
                        exit: {
                          opacity: 0.0
                        },
                        className: backdropStyles,
                        key: "backdrop",
                        onClick: (function (param) {
                            Curry._1(setSlideOver, (function (param) {
                                    
                                  }));
                          })
                      }) : null, tmp));
}

var make = BillingPlans;

export {
  getPlanPrice ,
  getSeatPrice ,
  Plan ,
  Checkout ,
  UpdatePayment ,
  StripeSubscription ,
  rootStyles$2 as rootStyles,
  headerStyles ,
  plansStyles ,
  slideOverStyles ,
  slideOverCloseStyles ,
  backdropStyles ,
  plansToDisplay ,
  make ,
}
/* planHeaderStyles Not a pure module */
