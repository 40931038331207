// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as Mantine from "./Mantine.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Timestamp from "./Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as GlobalPill from "./GlobalPill.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as DomainStore from "./DomainStore.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as Core from "@mantine/core";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as BranchBar__Utils from "./BranchBar__Utils.mjs";
import * as BranchStatusPill from "./BranchStatusPill.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as CollaboratorsView from "./CollaboratorsView.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as BranchBar__Dropdown from "./BranchBar__Dropdown.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as BranchChangesContext from "./BranchChangesContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";
import * as BranchStateFirebaseModelUtils from "../../shared/models/BranchStateFirebaseModelUtils.mjs";

function BranchBar$Status(Props) {
  var status = Props.status;
  var openAction = Props.openAction;
  var workspace = WorkspaceContext.use();
  var lastLifecycleAction;
  if (typeof status !== "object") {
    lastLifecycleAction = openAction;
  } else {
    switch (status.TAG) {
      case "Merged" :
      case "Closed" :
          lastLifecycleAction = status._0;
          break;
      default:
        lastLifecycleAction = openAction;
    }
  }
  var creatorUser = FirebaseFetcherHooks.useUser(lastLifecycleAction.createdBy);
  var tmp;
  tmp = typeof status !== "object" || status.TAG !== "Approved" ? 0 : Belt_List.length(status._0);
  var status$1 = BranchStateFirebaseModelUtils.enrichStatusWithApprovals(BranchStateFirebaseModelUtils.fromBranchStatus(status), tmp, workspace);
  return React.createElement(SimpleTooltip.make, {
              tooltip: (
                status$1 === "Closed" ? "Deleted by " : (
                    status$1 === "ChangesRequested" || status$1 === "Approved" ? "Opened by " : (
                        status$1 === "Master" ? "" : (
                            status$1 === "Open" || status$1 === "Draft" ? "Opened by " : (
                                status$1 === "Unknown" ? "Loading..." : (
                                    status$1 === "Merged" ? "Merged by " : "Opened by "
                                  )
                              )
                          )
                      )
                  )
              ) + (
                typeof creatorUser === "object" && creatorUser.NAME === "Success" ? AvoConfig.getUserDisplayName(creatorUser.VAL) : "Member"
              ) + " " + Timestamp.renderTimestamp(lastLifecycleAction.createdAt),
              children: React.createElement(BranchStatusPill.make, {
                    status: status$1
                  })
            });
}

var button = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.height(Css.px(32)),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.paddingTop(Css.px(4)),
                    tl: {
                      hd: Css.paddingRight(Css.px(6)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(4)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(8)),
                          tl: {
                            hd: Css.borderRadius(Css.px(5)),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", "transparent"),
                                tl: {
                                  hd: Css.marginLeft(Css.px(4)),
                                  tl: {
                                    hd: Css.color(Styles.Color.light11),
                                    tl: {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$short
                                          }, undefined, undefined, "all"),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.borderColor(Styles.Color.light04),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function counter(numDomainsImpactedWhichUserIsntMemberOf) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.height(Css.px(16)),
                    tl: {
                      hd: Css.width(Css.px(16)),
                      tl: {
                        hd: Css.borderRadius(Css.px(8)),
                        tl: {
                          hd: Css.backgroundColor(numDomainsImpactedWhichUserIsntMemberOf > 0 ? Styles.Color.avoPink : Styles.Color.light09),
                          tl: {
                            hd: Css.marginRight(Css.px(8)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function BranchBar$Stakeholders(Props) {
  var workspace = WorkspaceContext.use();
  var globalSend = GlobalSendContext.use();
  var domains = DomainStore.useDomains();
  var viewerId = ViewerContext.use().id;
  var memberDomains = DomainStore.useMemberDomains(viewerId);
  var branchChanges = BranchChangesContext.use();
  var schemaBundle = SchemaBundleContext.use();
  var match = Hooks.useDisclosure(false);
  var popupActions = match[1];
  var isOpen = match[0];
  var impacted = BranchBar__Utils.getImpactedEventAndVariantIdsByDomain(BranchBar__Utils.getImpactedEventAndVariantIds(branchChanges.changes), domains);
  var numDomainsImpactedWhichUserIsntMemberOf = Belt_Array.keepMap(impacted, (function (param) {
          var domain = param[0];
          if (!Curry._2(Domains.has, memberDomains, domain.id)) {
            return domain;
          }
          
        })).length;
  var count = impacted.length;
  var impactedStakeholdersAvailability = AvoLimits.ImpactedStakeholders.computeAvailability(workspace);
  var hasImpactedStakeholders = impactedStakeholdersAvailability === "AvailableDuringTrial" || impactedStakeholdersAvailability === "Available";
  var match$1 = React.useState(function (param) {
        if (branchChanges.status === "loaded") {
          return count;
        }
        
      });
  var setLoadedCount = match$1[1];
  var loadedCount = match$1[0];
  React.useEffect((function (param) {
          if (branchChanges.status === "loaded") {
            Curry._1(setLoadedCount, (function (param) {
                    return count;
                  }));
          }
          
        }), [count]);
  var previousLoadedCount = Hooks.usePrevious1(loadedCount);
  React.useEffect((function (param) {
          if (loadedCount !== undefined && previousLoadedCount !== undefined && loadedCount !== previousLoadedCount) {
            AnalyticsRe.impactedStakeholdersUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, count, workspace.id, schemaBundle.branchId);
          }
          
        }), [loadedCount]);
  return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                  width: 8
                }), branchChanges.status === "loading" ? React.createElement(LoadingCircle.make, {
                    color: Styles.Color.dark10
                  }) : null, React.createElement(Mantine.Popover.make, {
                  onChange: (function (param) {
                      Curry._1(popupActions.close, undefined);
                    }),
                  opened: isOpen,
                  position: "bottom-end",
                  children: null
                }, React.createElement(Core.Popover.Target, {
                      children: React.createElement("button", {
                            className: button,
                            onClick: (function (param) {
                                if (hasImpactedStakeholders) {
                                  Curry._1(popupActions.toggle, undefined);
                                } else {
                                  Curry._1(globalSend, {
                                        TAG: "OpenModal",
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "ImpactedStakeholders"
                                        }
                                      });
                                }
                                if (!isOpen) {
                                  return AnalyticsRe.branchBarInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ImpactedStakeholdersOpened", impacted.length, workspace.id, schemaBundle.branchId);
                                }
                                
                              })
                          }, React.createElement("span", {
                                className: counter(numDomainsImpactedWhichUserIsntMemberOf)
                              }, React.createElement($$Text.make, {
                                    element: "Span",
                                    size: "Tiny",
                                    weight: "Semi",
                                    color: Styles.Color.white,
                                    children: String(count)
                                  })), React.createElement($$Text.make, {
                                element: "Span",
                                size: "Small",
                                weight: "Semi",
                                children: "Impacted " + AvoUtils.plural(undefined, false, count, "stakeholder")
                              }), React.createElement(Spacer.make, {
                                width: 4
                              }), impactedStakeholdersAvailability === "AvailableDuringTrial" || impactedStakeholdersAvailability === "Available" ? React.createElement(Icon.make, {
                                  type_: "chevronDown",
                                  size: "medium",
                                  color: Styles.Color.light11
                                }) : React.createElement(Icon.make, {
                                  type_: "lightning",
                                  size: "small",
                                  color: Styles.Color.light10
                                }))
                    }), React.createElement(Mantine.Popover.Dropdown.make, {
                      children: React.createElement(BranchBar__Dropdown.make, {
                            onClose: (function (param) {
                                Curry._1(popupActions.close, undefined);
                              })
                          })
                    })));
}

function getBranchName(branchId, name) {
  if (GlobalRequirementsUtils.isGlobalBranch(branchId)) {
    return GlobalRequirementsUtils.getNormalizedName(branchId);
  } else {
    return name;
  }
}

var root = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.height(Css.px(64)),
          tl: {
            hd: Css.paddingLeft(Css.px(16)),
            tl: {
              hd: Css.paddingRight(Css.px(16)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.zIndex(2),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function BranchBar(Props) {
  var currentBranch = Props.currentBranch;
  var branchStatus = Props.branchStatus;
  var workspace = WorkspaceContext.use();
  var sendActions = SendActionsContext.use();
  var schemaBundle = SchemaBundleContext.use();
  var branchId = NamedBranch.unnamedBranchToId(currentBranch);
  var isGlobalRequirementsBranch = GlobalRequirementsUtils.isGlobalBranch(branchId);
  var openAction = FirebaseFetcherHooks.useBranchOpenAction(workspace.id, branchId, "All", undefined, undefined);
  var branchName;
  if (currentBranch === "Master") {
    branchName = "main";
  } else if (openAction !== undefined) {
    var match = Caml_option.valFromOption(openAction).contents;
    branchName = typeof match === "object" && match.NAME === "OpenBranch" ? getBranchName(NamedBranch.unnamedBranchToId(currentBranch), match.VAL[1]) : getBranchName(NamedBranch.unnamedBranchToId(currentBranch), NamedBranch.unnamedBranchToId(currentBranch));
  } else {
    branchName = "-";
  }
  var branchName$1 = isGlobalRequirementsBranch ? GlobalRequirementsUtils.getNormalizedName(branchId) : branchName;
  var handleDelete = function (param) {
    if (window.confirm("Are you sure you want to delete the " + branchName$1 + " branch?")) {
      Router.Schema.pushSchemaRoute("master", undefined, "dashboard");
      return Curry.app(sendActions, [
                  "Master",
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      AnalyticsRe.branchDeleted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, NamedBranch.getId(branch), schemaBundle.schemaId);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "CloseBranch",
                        VAL: [
                          branchId,
                          branchName$1
                        ]
                      },
                      {
                        branchId: branchId,
                        branchQuery: branchId
                      }
                    ]]
                ]);
    }
    
  };
  var tmp;
  if (currentBranch === "Master") {
    tmp = null;
  } else {
    var tmp$1;
    if (typeof branchStatus !== "object") {
      tmp$1 = null;
    } else {
      switch (branchStatus.TAG) {
        case "Merged" :
        case "Closed" :
            tmp$1 = null;
            break;
        default:
          tmp$1 = React.createElement(ContextMenu.V2.make, {
                withBorder: true,
                position: "bottom-end",
                children: React.createElement(ContextMenu.V2.Item.make, {
                      label: "Delete Branch",
                      onClick: handleDelete,
                      style: "Warning"
                    })
              });
      }
    }
    tmp = React.createElement(React.Fragment, undefined, openAction !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                    width: 16
                  }), React.createElement(BranchBar$Status, {
                    status: branchStatus,
                    openAction: Caml_option.valFromOption(openAction)
                  }), React.createElement(Button.make, {
                    label: "View changes",
                    onClick: (function (param) {
                        Router.Schema.pushSchemaRoute(undefined, undefined, {
                              NAME: "diff",
                              VAL: "index"
                            });
                      }),
                    style: "ghost"
                  })) : null, React.createElement(Spacer.make, {
              width: 8,
              grow: 1.0
            }), React.createElement(BranchBar$Stakeholders, {}), React.createElement(CollaboratorsView.make, {
              branchId: branchId,
              branchStatus: branchStatus
            }), React.createElement(Spacer.make, {
              width: 16
            }), tmp$1);
  }
  return React.createElement("div", {
              className: root
            }, React.createElement(Icon.make, {
                  type_: "branch",
                  size: "medium",
                  color: Styles.Color.light10
                }), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement($$Text.make, {
                  size: "Large",
                  weight: "Semi",
                  singleLine: true,
                  color: Styles.Color.light12,
                  children: branchName$1
                }), isGlobalRequirementsBranch ? React.createElement(GlobalPill.make, {}) : null, tmp);
}

var height = 64;

var make = BranchBar;

export {
  height ,
  make ,
}
/* button Not a pure module */
