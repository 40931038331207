// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BillingStats from "../../shared/models/BillingStats.mjs";

function useBillingStats(schemaId, workspaceMembers) {
  var match = React.useState(function (param) {
        return "Loading";
      });
  var setBillingStats = match[1];
  var workspaceMembersRefetchKey = Belt_List.toArray(Belt_List.map(workspaceMembers, (function (member) {
                return member.role;
              }))).join(",");
  React.useEffect((function (param) {
          $$Promise.$$catch(Firebase.Auth.unsafeGetCurrentUser(Firebase.app().auth()).getIdToken().then(function (userToken) {
                        return fetch(Firebase.apiUrl + "/billing/stats" + (
                                    process.env.NODE_ENV === "development" ? "?dev=true" : ""
                                  ), {
                                    method: "POST",
                                    body: Caml_option.some(JSON.stringify(Js_dict.fromArray([[
                                                    "schemaId",
                                                    schemaId
                                                  ]]))),
                                    headers: Caml_option.some(new Headers([
                                              [
                                                "Accept",
                                                "application/json"
                                              ],
                                              [
                                                "Content-Type",
                                                "application/json"
                                              ],
                                              [
                                                "Authorization",
                                                "Bearer " + userToken
                                              ]
                                            ]))
                                  });
                      }).then(function (response) {
                      return response.json();
                    }).then(function (json) {
                    Curry._1(setBillingStats, (function (param) {
                            return {
                                    NAME: "Loaded",
                                    VAL: BillingStats.decode(json)
                                  };
                          }));
                  }), (function (_error) {
                  return Promise.resolve(Curry._1(setBillingStats, (function (param) {
                                    return "Error";
                                  })));
                }));
        }), [workspaceMembersRefetchKey]);
  return match[0];
}

export {
  useBillingStats ,
}
/* react Not a pure module */
