// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as DiffUtils from "./DiffUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as ActionsReducer from "./actionsReducer.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as MergeConflicts from "./MergeConflicts.mjs";
import * as NewBranchModal from "./NewBranchModal.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as CopyChangesToBranch__Events from "./CopyChangesToBranch__Events.mjs";
import * as CopyChangesToBranch__Sources from "./CopyChangesToBranch__Sources.mjs";
import * as CopyChangesToBranchModal__Hooks from "./CopyChangesToBranchModal__Hooks.mjs";
import * as CopyChangesToBranchModal__Types from "./CopyChangesToBranchModal__Types.mjs";
import * as CopyChangesToBranchModal__Utils from "./CopyChangesToBranchModal__Utils.mjs";
import * as CopyChangesToBranch__Destinations from "./CopyChangesToBranch__Destinations.mjs";
import * as CopyChangesToBranchModal__Categories from "./CopyChangesToBranchModal__Categories.mjs";
import * as CopyChangesToBranchModal__Properties from "./CopyChangesToBranchModal__Properties.mjs";
import * as CopyChangesToBranch__PropertyBundles from "./CopyChangesToBranch__PropertyBundles.mjs";

var buttonsPalette = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("flexEnd"),
        tl: {
          hd: Css.gap(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

function getActionsToSend(sourceBranchId, toBranchId, toBranchName, appliedActions, mainModel, actions) {
  var propertyIds = {
    contents: undefined
  };
  var propertyBundleIds = {
    contents: undefined
  };
  var eventIds = {
    contents: undefined
  };
  var sourceIds = {
    contents: undefined
  };
  var destinationIds = {
    contents: undefined
  };
  var categoryIds = {
    contents: undefined
  };
  Belt_Array.forEach(actions, (function (itemActions) {
          var variant = itemActions.NAME;
          if (variant === "itemNotFound" || variant === "ignoredActions") {
            return ;
          }
          var match = itemActions.VAL[0];
          var variant$1 = match.NAME;
          if (variant$1 === "property" || variant$1 === "eventProperty") {
            var propertyId = match.VAL;
            propertyIds.contents = Belt_Option.mapWithDefault(propertyIds.contents, [propertyId], (function (propertyIds) {
                    return Belt_Array.concat(propertyIds, [propertyId]);
                  }));
            return ;
          }
          if (variant$1 === "propertyBundle" || variant$1 === "eventPropertyBundle") {
            var propertyBundleId = match.VAL;
            propertyBundleIds.contents = Belt_Option.mapWithDefault(propertyBundleIds.contents, [propertyBundleId], (function (propertyBundleIds) {
                    return Belt_Array.concat(propertyBundleIds, [propertyBundleId]);
                  }));
            return ;
          }
          if (variant$1 === "event") {
            var eventId = match.VAL;
            eventIds.contents = Belt_Option.mapWithDefault(eventIds.contents, [eventId], (function (eventIds) {
                    return Belt_Array.concat(eventIds, [eventId]);
                  }));
            return ;
          }
          if (variant$1 === "source" || variant$1 === "eventSource") {
            var sourceId = match.VAL;
            sourceIds.contents = Belt_Option.mapWithDefault(sourceIds.contents, [sourceId], (function (sourceIds) {
                    return Belt_Array.concat(sourceIds, [sourceId]);
                  }));
            return ;
          }
          if (variant$1 === "category") {
            var categoryId = match.VAL;
            categoryIds.contents = Belt_Option.mapWithDefault(categoryIds.contents, [categoryId], (function (categoryIds) {
                    return Belt_Array.concat(categoryIds, [categoryId]);
                  }));
            return ;
          }
          var destinationId = match.VAL;
          destinationIds.contents = Belt_Option.mapWithDefault(destinationIds.contents, [destinationId], (function (destinationIds) {
                  return Belt_Array.concat(destinationIds, [destinationId]);
                }));
        }));
  var tmp = {};
  if (eventIds.contents !== undefined) {
    tmp.eventIds = Caml_option.valFromOption(eventIds.contents);
  }
  if (eventIds.contents !== undefined) {
    tmp.eventsQuery = Caml_option.valFromOption(eventIds.contents);
  }
  if (propertyIds.contents !== undefined) {
    tmp.propertyIds = Caml_option.valFromOption(propertyIds.contents);
  }
  if (propertyIds.contents !== undefined) {
    tmp.propertiesQuery = Caml_option.valFromOption(propertyIds.contents);
  }
  if (categoryIds.contents !== undefined) {
    tmp.goalIds = Caml_option.valFromOption(categoryIds.contents);
  }
  if (categoryIds.contents !== undefined) {
    tmp.goalsQuery = Caml_option.valFromOption(categoryIds.contents);
  }
  if (destinationIds.contents !== undefined) {
    tmp.destinationIds = Caml_option.valFromOption(destinationIds.contents);
  }
  if (destinationIds.contents !== undefined) {
    tmp.destinationsQuery = Caml_option.valFromOption(destinationIds.contents);
  }
  if (sourceIds.contents !== undefined) {
    tmp.sourceIds = Caml_option.valFromOption(sourceIds.contents);
  }
  if (sourceIds.contents !== undefined) {
    tmp.sourcesQuery = Caml_option.valFromOption(sourceIds.contents);
  }
  if (propertyBundleIds.contents !== undefined) {
    tmp.propertyGroupIds = Caml_option.valFromOption(propertyBundleIds.contents);
  }
  if (propertyBundleIds.contents !== undefined) {
    tmp.propertyGroupsQuery = Caml_option.valFromOption(propertyBundleIds.contents);
  }
  return [[
            {
              NAME: "CopyChangesToBranch",
              VAL: [
                sourceBranchId,
                Belt_Option.getWithDefault(ModelUtils.getBranchName(mainModel.openBranches, sourceBranchId), "N/A"),
                toBranchId,
                toBranchName,
                appliedActions
              ]
            },
            tmp
          ]];
}

function CopyChangesToBranchModal__ChangesToCopy$ModelsLoaded(Props) {
  var fromModel = Props.fromModel;
  var toModel = Props.toModel;
  var copyItems = Props.copyItems;
  var onClose = Props.onClose;
  var originBranch = Props.originBranch;
  var destinationBranch = Props.destinationBranch;
  var copyMode = Props.copyMode;
  var onSuccess = Props.onSuccess;
  var pageName = Props.pageName;
  var branchItemSyncOrigin = Props.branchItemSyncOrigin;
  var withCtaOpt = Props.withCta;
  var onStateChange = Props.onStateChange;
  var onExcludedItemsChange = Props.onExcludedItemsChange;
  var parentType = Props.parentType;
  var withCta = withCtaOpt !== undefined ? withCtaOpt : true;
  var schemaBundle = SchemaBundleContext.use();
  var schemaId = schemaBundle.schemaId;
  var currentBranchId = Router.Schema.getBranchId();
  var mainModel = ModelStore.useMainModel();
  var mappedEvents = ModelStore.Mapped.useEvents();
  var mappedProperties = ModelStore.Mapped.useProperties();
  var mappedPropertyBundles = ModelStore.Mapped.usePropertyBundles();
  var sendActions = SendActionsContext.use();
  var addToast = Toast.useAddToast();
  var addDestroyableToast = Toast.useAddToastWithDestroy();
  var match = React.useState(function (param) {
        return false;
      });
  var setIsLoading = match[1];
  var match$1 = React.useState(function (param) {
        return Belt_Map.make(CopyChangesToBranchModal__Types.CopyItem.Comparator);
      });
  var setIgnoredItems = match$1[1];
  var ignoredItems = match$1[0];
  Hooks.useDidUpdate1((function (param) {
          Belt_Option.forEach(onExcludedItemsChange, (function (fn) {
                  Curry._1(fn, ignoredItems);
                }));
        }), [ignoredItems]);
  var itemsActions = CopyChangesToBranchModal__Utils.getItemsActions(toModel, fromModel, Caml_option.some(ignoredItems), copyItems);
  var match$2 = React.useMemo((function (param) {
          return CopyChangesToBranchModal__Utils.resolveCopyItemActions(itemsActions);
        }), [itemsActions]);
  var allActions = match$2.allActions;
  var appliedActions = match$2.appliedActions;
  var toModel$1 = Belt_List.reduce(allActions, fromModel, ActionsReducer.reduce);
  var toModelWithAppliedActions = Belt_List.reduce(appliedActions, fromModel, ActionsReducer.reduce);
  var mergeConflicts = React.useMemo((function (param) {
          return DiffUtils.getMergeConflicts(AvoUtils.actionByObject(appliedActions), toModel$1, fromModel);
        }), [
        appliedActions,
        toModel$1,
        fromModel
      ]);
  React.useEffect((function (param) {
          Belt_Option.forEach(onStateChange, (function (fn) {
                  Curry._3(fn, (function (sourceBranchId, toBranchId, toBranchName) {
                          return getActionsToSend(sourceBranchId, toBranchId, toBranchName, appliedActions, mainModel, itemsActions);
                        }), mergeConflicts, toModel$1);
                }));
        }), [
        appliedActions,
        mergeConflicts,
        toModel$1
      ]);
  var actionsByObjectId = AvoUtils.groupActionsByObjectId(AvoUtils.actionByObject(allActions));
  var appliedActionsByObjectId = AvoUtils.groupActionsByObjectId(AvoUtils.actionByObject(appliedActions));
  var eventActions = Belt_List.keep(appliedActionsByObjectId, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Event";
          } else {
            return false;
          }
        }));
  var allEventActions = Belt_List.keep(actionsByObjectId, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Event";
          } else {
            return false;
          }
        }));
  var sourceActions = Belt_List.keep(actionsByObjectId, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Source";
          } else {
            return false;
          }
        }));
  var propertyBundleActions = Belt_List.keep(actionsByObjectId, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "PropertyGroup";
          } else {
            return false;
          }
        }));
  var onToggleItem = function (item, value) {
    if (value === "ExcludeChanges") {
      return Curry._1(setIgnoredItems, (function (ignoredItems) {
                    return Belt_Map.update(ignoredItems, item, (function (param) {
                                  return "ExcludeChanges";
                                }));
                  }));
    } else if (value === "IncludeChanges") {
      if (Belt_Option.isSome(Belt_Map.get(ignoredItems, item))) {
        return Curry._1(setIgnoredItems, (function (ignoredItems) {
                      return Belt_Map.remove(ignoredItems, item);
                    }));
      } else {
        return ;
      }
    } else {
      return Curry._1(setIgnoredItems, (function (ignoredItems) {
                    return Belt_Map.update(ignoredItems, item, (function (param) {
                                  return "ExcludeItem";
                                }));
                  }));
    }
  };
  var onSuccess$1 = function (toBranchId, toBranchName, param) {
    Curry._1(setIsLoading, (function (param) {
            return false;
          }));
    var match = CopyChangesToBranchModal__Utils.getAnalyticsItem(mappedEvents, mappedProperties, mappedPropertyBundles, copyItems);
    var itemType = match[2];
    var itemName = match[1];
    var itemId = match[0];
    var arg = typeof originBranch === "object" ? originBranch.VAL : currentBranchId;
    var arg$1 = Belt_Option.getWithDefault(ModelUtils.getBranchName(mainModel.openBranches, currentBranchId), "N/A");
    var arg$2 = Belt_List.length(appliedActions);
    var arg$3 = Belt_Map.size(ignoredItems);
    var arg$4 = CopyChangesToBranchModal__Types.CopyItem.getIgnoredAnalyticsTypes(ignoredItems);
    AnalyticsUtils.withSchemaBundle((function (param) {
            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25) {
              return AnalyticsRe.branchItemSyncCompleted(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25);
            };
            return function (param) {
              var func$1 = Curry._1(func, param);
              return function (param) {
                var func$2 = Curry._1(func$1, param);
                return function (param) {
                  var func$3 = Curry._1(func$2, param);
                  return function (param) {
                    var func$4 = Curry._1(func$3, param);
                    return function (param) {
                      var func$5 = Curry._1(func$4, param);
                      return function (param) {
                        var func$6 = Curry._1(func$5, param);
                        return function (param) {
                          var func$7 = Curry._1(func$6, param);
                          return function (param) {
                            var func$8 = Curry._1(func$7, param);
                            return function (param) {
                              var func$9 = Curry._1(func$8, param);
                              return function (param) {
                                return Curry.app(func$9, [
                                            param,
                                            itemId,
                                            itemName,
                                            itemType,
                                            toBranchId,
                                            toBranchName,
                                            arg,
                                            arg$1,
                                            arg$2,
                                            arg$3,
                                            arg$4,
                                            copyMode,
                                            branchItemSyncOrigin,
                                            pageName,
                                            schemaId,
                                            currentBranchId
                                          ]);
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                };
              };
            };
          }), schemaBundle);
    Curry._1(addToast, copyMode === "Push" ? ({
              message: "Changes pushed to " + toBranchName,
              toastType: "Success",
              action: {
                message: React.createElement($$Text.make, {
                      color: Styles.Color.green,
                      children: "Go to branch"
                    }),
                toastCallback: (function (param) {
                    var match = Router.getStaticRoute();
                    var link = Router.Link.getSchemaLink(match.baseRoute, {
                          NAME: "branch",
                          VAL: toBranchId
                        }, Belt_Option.getWithDefault(match.schemaRoute, "dashboard"), match.drawerItems);
                    Router.push(undefined, link);
                  })
              }
            }) : ({
              message: "Changes pulled to this branch",
              toastType: "Success"
            }));
    var resolvedCopyItems = CopyChangesToBranchModal__Utils.resolveCopyItems(copyItems, toModel$1);
    Belt_Option.forEach(onSuccess, (function (onSuccess) {
            Curry._2(onSuccess, sendActions, resolvedCopyItems);
          }));
    Curry._1(onClose, undefined);
  };
  var onError = function (toBranchName, param) {
    Curry._1(setIsLoading, (function (param) {
            return false;
          }));
    Curry._1(addToast, {
          message: "Failed to " + (
            copyMode === "Push" ? "push changes to " + toBranchName : "pull changes to this branch"
          ),
          toastType: "Error"
        });
  };
  var onProceedWithCopy = async function (param) {
    if (destinationBranch === undefined) {
      return ;
    }
    if (typeof destinationBranch !== "object") {
      return ;
    }
    if (destinationBranch.NAME === "Create") {
      var toBranchName = destinationBranch.VAL;
      Curry._1(setIsLoading, (function (param) {
              return true;
            }));
      var createBranchAsPromise = function (branchName, sendActions, schemaBundle) {
        return new Promise((function (resolve, reject) {
                      NewBranchModal.createBranch(branchName, "BranchSync", sendActions, schemaBundle, (function (error) {
                              reject(error);
                            }), (function (branchId) {
                              resolve(branchId);
                            }), undefined);
                    }));
      };
      var destroyToast = Curry._1(addDestroyableToast, {
            message: React.createElement(React.Fragment, undefined, "Creating branch ", React.createElement($$Text.make, {
                      weight: "Semi",
                      children: toBranchName
                    })),
            toastType: "Info"
          });
      try {
        var toBranchId = await createBranchAsPromise(toBranchName, sendActions, schemaBundle);
        Curry._1(destroyToast, undefined);
        var writeToBranch = {
          NAME: "Branch",
          VAL: toBranchId
        };
        return Curry.app(sendActions, [
                    writeToBranch,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    (function (param) {
                        return onSuccess$1(toBranchId, toBranchName, param);
                      }),
                    (function (param) {
                        return onError(toBranchName, param);
                      }),
                    getActionsToSend(currentBranchId, toBranchId, toBranchName, appliedActions, mainModel, itemsActions)
                  ]);
      }
      catch (_e){
        return Curry._1(addToast, {
                    message: "Failed to create branch",
                    toastType: "Error",
                    persist: true
                  });
      }
    } else {
      var match = destinationBranch.VAL;
      var toBranchId$1 = match.id;
      Curry._1(setIsLoading, (function (param) {
              return true;
            }));
      var match$1 = copyMode === "Pull" ? [
          toBranchId$1,
          currentBranchId,
          NamedBranch.getName(NamedBranch.fromUnnamed(mainModel, {
                    NAME: "Branch",
                    VAL: currentBranchId
                  }))
        ] : [
          currentBranchId,
          toBranchId$1,
          match.name
        ];
      var toBranchName$1 = match$1[2];
      var toBranchId$2 = match$1[1];
      return Curry.app(sendActions, [
                  {
                    NAME: "Branch",
                    VAL: toBranchId$2
                  },
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (param) {
                      return onSuccess$1(toBranchId$2, toBranchName$1, param);
                    }),
                  (function (param) {
                      return onError(toBranchName$1, param);
                    }),
                  getActionsToSend(match$1[0], toBranchId$2, toBranchName$1, appliedActions, mainModel, itemsActions)
                ]);
    }
  };
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginTop(Css.px(-16)),
                    tl: /* [] */0
                  })
            }, Caml_obj.notequal(match$2.itemsNotFound, []) ? React.createElement($$Text.make, {
                    children: "Could not find some items to copy"
                  }) : null, appliedActions === /* [] */0 ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.backgroundColor(Styles.Color.light03),
                          tl: {
                            hd: Css.padding(Css.px(24)),
                            tl: {
                              hd: Css.borderRadius(Css.px(8)),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, React.createElement($$Text.make, {
                        textAlign: "center",
                        color: Styles.Color.light10,
                        children: null
                      }, React.createElement($$Text.make, {
                            weight: "Semi",
                            color: Styles.Color.orange,
                            children: "No changes"
                          }), "These changes already exist on the destination branch")) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.gap(Css.px(8)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(CopyChangesToBranch__Events.make, {
                      allEventActions: allEventActions,
                      currentBranchId: currentBranchId,
                      eventActions: eventActions,
                      fromModel: fromModel,
                      toModel: toModel$1,
                      toModelWithAppliedActions: toModelWithAppliedActions
                    }), React.createElement(CopyChangesToBranch__Sources.make, {
                      allEventActions: allEventActions,
                      currentBranchId: currentBranchId,
                      eventActions: eventActions,
                      fromModel: fromModel,
                      ignoredItems: ignoredItems,
                      onToggleItem: onToggleItem,
                      parentType: parentType,
                      sourceActions: sourceActions,
                      toModel: toModel$1,
                      toModelWithAppliedActions: toModelWithAppliedActions
                    }), React.createElement(CopyChangesToBranch__Destinations.make, {
                      actionsByObjectId: actionsByObjectId,
                      allEventActions: allEventActions,
                      eventActions: eventActions,
                      fromModel: fromModel,
                      ignoredItems: ignoredItems,
                      onToggleItem: onToggleItem,
                      parentType: parentType,
                      sourceActions: sourceActions,
                      toModel: toModel$1
                    }), React.createElement(CopyChangesToBranch__PropertyBundles.make, {
                      allEventActions: allEventActions,
                      currentBranchId: currentBranchId,
                      eventActions: eventActions,
                      fromModel: fromModel,
                      ignoredItems: ignoredItems,
                      onToggleItem: onToggleItem,
                      parentType: parentType,
                      propertyBundleActions: propertyBundleActions,
                      toModel: toModel$1
                    }), React.createElement(CopyChangesToBranchModal__Properties.make, {
                      actionsByObjectId: actionsByObjectId,
                      allEventActions: allEventActions,
                      currentBranchId: currentBranchId,
                      eventActions: eventActions,
                      fromModel: fromModel,
                      ignoredItems: ignoredItems,
                      onToggleItem: onToggleItem,
                      parentType: parentType,
                      propertyBundleActions: propertyBundleActions,
                      toModel: toModel$1
                    }), React.createElement(CopyChangesToBranchModal__Categories.make, {
                      actionsByObjectId: actionsByObjectId,
                      allEventActions: allEventActions,
                      currentBranchId: currentBranchId,
                      fromModel: fromModel,
                      ignoredItems: ignoredItems,
                      onToggleItem: onToggleItem,
                      parentType: parentType,
                      toModel: toModel$1,
                      toModelWithAppliedActions: toModelWithAppliedActions
                    })), React.createElement(Spacer.make, {
                  height: 16
                }), Belt_List.length(mergeConflicts) > 0 ? React.createElement(MergeConflicts.make, {
                    mergeConflicts: mergeConflicts,
                    mode: copyMode === "Push" ? "PushChanges" : "PullChanges"
                  }) : null, withCta ? React.createElement("div", {
                    className: buttonsPalette
                  }, React.createElement(Button.make, {
                        label: "Cancel",
                        onClick: (function (param) {
                            Curry._1(onClose, undefined);
                          }),
                        size: "large",
                        style: "ghost"
                      }), React.createElement(Button.make, {
                        disabled: Belt_Option.isNone(destinationBranch) || Belt_List.length(mergeConflicts) > 0 || appliedActions === /* [] */0,
                        label: copyMode === "Push" ? "Push to branch" : "Pull to branch",
                        loading: match[0],
                        onClick: (function (param) {
                            onProceedWithCopy();
                          }),
                        size: "large",
                        style: "primary"
                      })) : null);
}

var ModelsLoaded = {
  getActionsToSend: getActionsToSend,
  make: CopyChangesToBranchModal__ChangesToCopy$ModelsLoaded
};

function CopyChangesToBranchModal__ChangesToCopy(Props) {
  var onClose = Props.onClose;
  var copyItems = Props.copyItems;
  var originBranch = Props.originBranch;
  var destinationBranch = Props.destinationBranch;
  var copyMode = Props.copyMode;
  var branchItemSyncOrigin = Props.branchItemSyncOrigin;
  var pageName = Props.pageName;
  var onSuccess = Props.onSuccess;
  var withCta = Props.withCta;
  var onStateChange = Props.onStateChange;
  var onExcludedItemsChange = Props.onExcludedItemsChange;
  var parentType = Props.parentType;
  var fromAndToModels = CopyChangesToBranchModal__Hooks.useFromAndToModelsForCopy(originBranch, destinationBranch);
  if (typeof fromAndToModels !== "object") {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.backgroundColor(Styles.Color.light03),
                      tl: {
                        hd: Css.padding(Css.px(24)),
                        tl: {
                          hd: Css.borderRadius(Css.px(8)),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(8)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    })
              }, React.createElement(Mantine.Loader.make, {
                    size: 16,
                    color: Styles.Color.avoPink
                  }), React.createElement($$Text.make, {
                    color: Styles.Color.light10,
                    children: "Loading changes to " + (
                      copyMode === "Push" ? "push" : "pull"
                    ) + "..."
                  }));
  }
  var match = fromAndToModels.VAL;
  var tmp = {
    fromModel: match[0],
    toModel: match[1],
    copyItems: copyItems,
    onClose: onClose,
    originBranch: originBranch,
    destinationBranch: destinationBranch,
    copyMode: copyMode,
    pageName: pageName,
    branchItemSyncOrigin: branchItemSyncOrigin,
    parentType: parentType
  };
  if (onSuccess !== undefined) {
    tmp.onSuccess = Caml_option.valFromOption(onSuccess);
  }
  if (withCta !== undefined) {
    tmp.withCta = Caml_option.valFromOption(withCta);
  }
  if (onStateChange !== undefined) {
    tmp.onStateChange = Caml_option.valFromOption(onStateChange);
  }
  if (onExcludedItemsChange !== undefined) {
    tmp.onExcludedItemsChange = Caml_option.valFromOption(onExcludedItemsChange);
  }
  return React.createElement(CopyChangesToBranchModal__ChangesToCopy$ModelsLoaded, tmp);
}

var Utils;

var make = CopyChangesToBranchModal__ChangesToCopy;

export {
  buttonsPalette ,
  Utils ,
  ModelsLoaded ,
  make ,
}
/* buttonsPalette Not a pure module */
