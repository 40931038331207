// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Nanoid from "nanoid";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as StateTypes from "./StateTypes.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Dom_storage from "rescript/lib/es6/dom_storage.js";
import * as DomainStore from "./DomainStore.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as TimeMachine from "./TimeMachine.mjs";
import * as QueryString from "query-string";
import * as ActionsWriter from "./ActionsWriter.mjs";
import * as NewEventModal from "./NewEventModal.mjs";
import * as SandboxBanner from "./SandboxBanner.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as ActionsReducer from "./actionsReducer.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FilterQueryParams from "./FilterQueryParams.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as AdobeExperiencePlatformBreakingChangeUseCase from "./integration/AdobeExperiencePlatformBreakingChangeUseCase.mjs";

var PermissionsException = /* @__PURE__ */Caml_exceptions.create("AvoState.PermissionsException");

var IllegalActionsForGlobalItemsException = /* @__PURE__ */Caml_exceptions.create("AvoState.IllegalActionsForGlobalItemsException");

function computeCurrentModelFromActions(model, actions) {
  return Belt_List.reduceU(actions, model, (function (nextModel, action) {
                return ActionsReducer.reduce(nextModel, action.contents);
              }));
}

function wantsToComment(actions) {
  if (actions.length !== 1) {
    return false;
  }
  var match = actions[0];
  var match$1 = match[0];
  if (typeof match$1 !== "object") {
    return false;
  }
  var variant = match$1.NAME;
  if (variant === "PropertyComment" || variant === "GoalComment" || variant === "IntegrationComment" || variant === "InspectorIssueComment" || variant === "MetricComment" || variant === "PropertyGroupComment" || variant === "EventComment") {
    return true;
  } else {
    return variant === "BranchComment";
  }
}

function wantsToImport(actions) {
  if (actions.length !== 1) {
    return false;
  }
  var match = actions[0];
  var match$1 = match[0];
  if (typeof match$1 !== "object") {
    return match$1 === "StartedImport";
  }
  var variant = match$1.NAME;
  if (variant === "CompletedImport") {
    return true;
  } else {
    return variant === "ImportDeprecated";
  }
}

function wantsToCreateSource(actions) {
  return Belt_Array.every(actions, (function (action) {
                var match = action[0];
                if (typeof match !== "object") {
                  return false;
                }
                var variant = match.NAME;
                if (variant === "CreateSource" || variant === "UpdateSourcePlatformV3") {
                  return true;
                } else {
                  return variant === "UpdateSourceName";
                }
              }));
}

function AvoState(Props) {
  var schemaId = Props.schemaId;
  var model = Props.model;
  var mappedModel = Props.mappedModel;
  var masterModel = Props.masterModel;
  var mappedMasterModel = Props.mappedMasterModel;
  var latestAction = Props.latestAction;
  var latestMasterAction = Props.latestMasterAction;
  var numberOfActions = Props.numberOfActions;
  var numberOfMasterActions = Props.numberOfMasterActions;
  var role = Props.role;
  var member = Props.member;
  var branchStatus = Props.branchStatus;
  var children = Props.children;
  var settingsQuery = Props.settingsQuery;
  var removeSettingsQueryParam = settingsQuery.remove;
  var settingsQueryParamValue = settingsQuery.value;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use();
  var match = ViewerContext.use();
  var userId = match.id;
  var branch = RouterStore.Schema.useBranch();
  var currentBranch = typeof branch === "object" ? ({
        NAME: "Branch",
        VAL: branch.VAL
      }) : "Master";
  var domains = DomainStore.useDomains();
  var match$1 = FilterQueryParams.parseQuery();
  var querySort = match$1[8];
  var filter = member.filter;
  var filters;
  var exit = 0;
  if (filter !== undefined && filter.tags.length !== 0) {
    filters = Belt_Array.mapU(filter.tags, (function (tag) {
            return {
                    NAME: "Tag",
                    VAL: tag
                  };
          }));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    filters = Belt_Array.concatMany([
          Belt_Array.mapU(match$1[0], (function (sourceId) {
                  return {
                          NAME: "Source",
                          VAL: sourceId
                        };
                })),
          Belt_Array.mapU(match$1[1], (function (destinationId) {
                  return {
                          NAME: "Destination",
                          VAL: destinationId
                        };
                })),
          Belt_Array.mapU(match$1[2], (function (tag) {
                  return {
                          NAME: "Tag",
                          VAL: tag
                        };
                })),
          Belt_Array.mapU(match$1[3], (function (propertyGroupId) {
                  return {
                          NAME: "PropertyGroup",
                          VAL: propertyGroupId
                        };
                })),
          Belt_Array.mapU(match$1[4], (function (propertyId) {
                  return {
                          NAME: "Property",
                          VAL: propertyId
                        };
                })),
          Belt_Array.mapU(match$1[5], (function (action) {
                  return {
                          NAME: "Action",
                          VAL: action
                        };
                })),
          Belt_Array.mapU(match$1[6], (function (goalId) {
                  return {
                          NAME: "Category",
                          VAL: goalId
                        };
                })),
          Belt_Array.mapU(match$1[7], (function (domainId) {
                  return {
                          NAME: "Domain",
                          VAL: domainId
                        };
                }))
        ]);
  }
  if (filters.length !== 0 || querySort !== "NoSorting") {
    var currentFilters = AnalyticsUtils.currentFilters(model, filters, querySort);
    AnalyticsRe.filterLinkOpened(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, schemaBundle.branchId, schemaBundle.schemaId);
  }
  var match$2 = React.useReducer((function (state, action) {
          if (typeof action !== "object") {
            if (action === "CloseSlideOver") {
              return {
                      filters: state.filters,
                      lastSeenEventId: state.lastSeenEventId,
                      cwd: state.cwd,
                      showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                      eventSort: state.eventSort,
                      modals: state.modals,
                      masterEditingEnabled: state.masterEditingEnabled,
                      searchEventHandler: state.searchEventHandler,
                      metricsSort: state.metricsSort,
                      writeStatus: state.writeStatus,
                      slideOver: undefined
                    };
            } else {
              return {
                      filters: state.filters,
                      lastSeenEventId: state.lastSeenEventId,
                      cwd: state.cwd,
                      showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                      eventSort: state.eventSort,
                      modals: state.modals,
                      masterEditingEnabled: true,
                      searchEventHandler: state.searchEventHandler,
                      metricsSort: state.metricsSort,
                      writeStatus: state.writeStatus,
                      slideOver: state.slideOver
                    };
            }
          }
          switch (action.TAG) {
            case "UpdateFilters" :
                var action$1 = action._0;
                var updatedFilters;
                if (typeof action$1 !== "object") {
                  switch (action$1) {
                    case "ClearSources" :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Source";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case "ClearTags" :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Tag";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case "ClearDestinations" :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Destination";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case "ClearPropertyGroups" :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "PropertyGroup";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case "ClearProperties" :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Property";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case "ClearActions" :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Action";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case "ClearCategories" :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Category";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case "ClearDomains" :
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                if (typeof filter === "object") {
                                  return filter.NAME !== "Domain";
                                } else {
                                  return true;
                                }
                              }));
                        break;
                    case "Reset" :
                        updatedFilters = [];
                        break;
                    
                  }
                } else {
                  switch (action$1.TAG) {
                    case "SetSource" :
                        var sourceId = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.equal(filter, {
                                            NAME: "Source",
                                            VAL: sourceId
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Source",
                                  VAL: sourceId
                                }]);
                        break;
                    case "RemoveSource" :
                        var sourceId$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (sourceFilterItem) {
                                return Caml_obj.notequal(sourceFilterItem, {
                                            NAME: "Source",
                                            VAL: sourceId$1
                                          });
                              }));
                        break;
                    case "SetTag" :
                        var tag = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.equal(filter, {
                                            NAME: "Tag",
                                            VAL: tag
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Tag",
                                  VAL: tag
                                }]);
                        break;
                    case "RemoveTag" :
                        var tag$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (sourceFilterItem) {
                                return Caml_obj.notequal(sourceFilterItem, {
                                            NAME: "Tag",
                                            VAL: tag$1
                                          });
                              }));
                        break;
                    case "SetDestination" :
                        var destinationId = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.equal(filter, {
                                            NAME: "Destination",
                                            VAL: destinationId
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Destination",
                                  VAL: destinationId
                                }]);
                        break;
                    case "RemoveDestination" :
                        var destinationId$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                return Caml_obj.notequal(filter, {
                                            NAME: "Destination",
                                            VAL: destinationId$1
                                          });
                              }));
                        break;
                    case "SetPropertyGroup" :
                        var propertyGroupId = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.equal(filter, {
                                            NAME: "PropertyGroup",
                                            VAL: propertyGroupId
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "PropertyGroup",
                                  VAL: propertyGroupId
                                }]);
                        break;
                    case "RemovePropertyGroup" :
                        var propertyGroupId$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                return Caml_obj.notequal(filter, {
                                            NAME: "PropertyGroup",
                                            VAL: propertyGroupId$1
                                          });
                              }));
                        break;
                    case "SetProperty" :
                        var propertyId = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.equal(filter, {
                                            NAME: "Property",
                                            VAL: propertyId
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Property",
                                  VAL: propertyId
                                }]);
                        break;
                    case "RemoveProperty" :
                        var propertyId$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                return Caml_obj.notequal(filter, {
                                            NAME: "Property",
                                            VAL: propertyId$1
                                          });
                              }));
                        break;
                    case "SetAction" :
                        var action$2 = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.equal(filter, {
                                            NAME: "Action",
                                            VAL: action$2
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Action",
                                  VAL: action$2
                                }]);
                        break;
                    case "RemoveAction" :
                        var action$3 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                return Caml_obj.notequal(filter, {
                                            NAME: "Action",
                                            VAL: action$3
                                          });
                              }));
                        break;
                    case "SetCategory" :
                        var goalId = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.equal(filter, {
                                            NAME: "Category",
                                            VAL: goalId
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Category",
                                  VAL: goalId
                                }]);
                        break;
                    case "RemoveCategory" :
                        var goalId$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                return Caml_obj.notequal(filter, {
                                            NAME: "Category",
                                            VAL: goalId$1
                                          });
                              }));
                        break;
                    case "SetDomain" :
                        var domainId = action$1._0;
                        updatedFilters = Belt_Array.someU(state.filters, (function (filter) {
                                return Caml_obj.equal(filter, {
                                            NAME: "Domain",
                                            VAL: domainId
                                          });
                              })) ? state.filters : Belt_Array.concat(state.filters, [{
                                  NAME: "Domain",
                                  VAL: domainId
                                }]);
                        break;
                    case "RemoveDomain" :
                        var domainId$1 = action$1._0;
                        updatedFilters = Belt_Array.keepU(state.filters, (function (filter) {
                                return Caml_obj.notequal(filter, {
                                            NAME: "Domain",
                                            VAL: domainId$1
                                          });
                              }));
                        break;
                    
                  }
                }
                var currentFilters = AnalyticsUtils.currentFilters(model, updatedFilters, state.eventSort);
                var tmp;
                if (typeof action$1 !== "object") {
                  switch (action$1) {
                    case "ClearSources" :
                        tmp = "ClearSources";
                        break;
                    case "ClearTags" :
                        tmp = "ClearTags";
                        break;
                    case "ClearDestinations" :
                        tmp = "ClearDestinations";
                        break;
                    case "ClearPropertyGroups" :
                        tmp = "ClearPropertyGroups";
                        break;
                    case "ClearProperties" :
                        tmp = "ClearProperties";
                        break;
                    case "ClearActions" :
                        tmp = "ClearActions";
                        break;
                    case "ClearCategories" :
                        tmp = "ClearCategories";
                        break;
                    case "ClearDomains" :
                        tmp = "ClearDomains";
                        break;
                    case "Reset" :
                        tmp = "Reset";
                        break;
                    
                  }
                } else {
                  switch (action$1.TAG) {
                    case "SetSource" :
                        tmp = "SelectSource";
                        break;
                    case "RemoveSource" :
                        tmp = "DeselectSource";
                        break;
                    case "SetTag" :
                        tmp = "SelectTag";
                        break;
                    case "RemoveTag" :
                        tmp = "DeselectTag";
                        break;
                    case "SetDestination" :
                        tmp = "SelectDestination";
                        break;
                    case "RemoveDestination" :
                        tmp = "DeselectDestination";
                        break;
                    case "SetPropertyGroup" :
                        tmp = "SelectPropertyGroup";
                        break;
                    case "RemovePropertyGroup" :
                        tmp = "DeselectPropertyGroup";
                        break;
                    case "SetProperty" :
                        tmp = "SelectProperty";
                        break;
                    case "RemoveProperty" :
                        tmp = "DeselectProperty";
                        break;
                    case "SetAction" :
                        tmp = "SelectAction";
                        break;
                    case "RemoveAction" :
                        tmp = "DeselectAction";
                        break;
                    case "SetCategory" :
                        tmp = "SelectCategory";
                        break;
                    case "RemoveCategory" :
                        tmp = "DeselectCategory";
                        break;
                    case "SetDomain" :
                        tmp = "SelectDomain";
                        break;
                    case "RemoveDomain" :
                        tmp = "DeselectDomain";
                        break;
                    
                  }
                }
                var tmp$1;
                var exit = 0;
                if (typeof action$1 !== "object") {
                  tmp$1 = "";
                } else {
                  switch (action$1.TAG) {
                    case "SetSource" :
                    case "RemoveSource" :
                        tmp$1 = AvoConfig.getSourceNameById(model, action$1._0);
                        break;
                    case "SetDestination" :
                    case "RemoveDestination" :
                        tmp$1 = ModelUtils.getDestinationNameById(model, action$1._0);
                        break;
                    case "SetPropertyGroup" :
                    case "RemovePropertyGroup" :
                        exit = 1;
                        break;
                    case "SetProperty" :
                    case "RemoveProperty" :
                        tmp$1 = Belt_Option.getWithDefault(ModelUtils.getPropertyNameById(action$1._0, model), "");
                        break;
                    case "SetTag" :
                    case "RemoveTag" :
                    case "SetAction" :
                    case "RemoveAction" :
                        tmp$1 = action$1._0;
                        break;
                    case "SetCategory" :
                    case "RemoveCategory" :
                        exit = 2;
                        break;
                    case "SetDomain" :
                    case "RemoveDomain" :
                        exit = 3;
                        break;
                    
                  }
                }
                switch (exit) {
                  case 1 :
                      tmp$1 = Belt_Option.mapWithDefaultU(ModelUtils.getPropertyBundleById(action$1._0, model), "", (function (param) {
                              return param.name;
                            }));
                      break;
                  case 2 :
                      tmp$1 = Belt_Option.mapWithDefaultU(ModelUtils.getGoalById(action$1._0, model), "", (function (goal) {
                              return goal.name;
                            }));
                      break;
                  case 3 :
                      tmp$1 = Belt_Option.mapWithDefaultU(Curry._2(Domains.get, domains, action$1._0), "", (function (domain) {
                              return domain.name;
                            }));
                      break;
                  
                }
                AnalyticsRe.eventsFilterUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, action._1, tmp, tmp$1, schemaBundle.branchId, schemaBundle.schemaId);
                return {
                        filters: updatedFilters,
                        lastSeenEventId: state.lastSeenEventId,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: state.eventSort,
                        modals: state.modals,
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: state.metricsSort,
                        writeStatus: state.writeStatus,
                        slideOver: state.slideOver
                      };
            case "SetLastSeenEventId" :
                return {
                        filters: state.filters,
                        lastSeenEventId: action._0,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: state.eventSort,
                        modals: state.modals,
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: state.metricsSort,
                        writeStatus: state.writeStatus,
                        slideOver: state.slideOver
                      };
            case "SortEvents" :
                return {
                        filters: state.filters,
                        lastSeenEventId: state.lastSeenEventId,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: action._0,
                        modals: state.modals,
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: state.metricsSort,
                        writeStatus: state.writeStatus,
                        slideOver: state.slideOver
                      };
            case "SortMetrics" :
                return {
                        filters: state.filters,
                        lastSeenEventId: state.lastSeenEventId,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: state.eventSort,
                        modals: state.modals,
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: action._0,
                        writeStatus: state.writeStatus,
                        slideOver: state.slideOver
                      };
            case "OpenModal" :
                return {
                        filters: state.filters,
                        lastSeenEventId: state.lastSeenEventId,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: state.eventSort,
                        modals: Belt_List.add(state.modals, {
                              id: Nanoid.nanoid(),
                              modal: action._0
                            }),
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: state.metricsSort,
                        writeStatus: state.writeStatus,
                        slideOver: state.slideOver
                      };
            case "CloseModal" :
                var maybeModalId = action._0;
                return {
                        filters: state.filters,
                        lastSeenEventId: state.lastSeenEventId,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: state.eventSort,
                        modals: maybeModalId !== undefined ? Belt_List.keep(state.modals, (function (modal) {
                                  return modal.id !== maybeModalId;
                                })) : Belt_Option.getWithDefault(Belt_List.tail(state.modals), /* [] */0),
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: state.metricsSort,
                        writeStatus: state.writeStatus,
                        slideOver: state.slideOver
                      };
            case "OpenSlideOver" :
                return {
                        filters: state.filters,
                        lastSeenEventId: state.lastSeenEventId,
                        cwd: state.cwd,
                        showCodegenSourcePickerModal: state.showCodegenSourcePickerModal,
                        eventSort: state.eventSort,
                        modals: state.modals,
                        masterEditingEnabled: state.masterEditingEnabled,
                        searchEventHandler: state.searchEventHandler,
                        metricsSort: state.metricsSort,
                        writeStatus: state.writeStatus,
                        slideOver: action._0
                      };
            
          }
        }), {
        filters: filters,
        lastSeenEventId: undefined,
        cwd: undefined,
        showCodegenSourcePickerModal: undefined,
        eventSort: querySort,
        modals: /* [] */0,
        masterEditingEnabled: false,
        searchEventHandler: {
          contents: undefined
        },
        metricsSort: "NoSorting",
        writeStatus: "Initial",
        slideOver: undefined
      });
  var send = match$2[1];
  var state = match$2[0];
  var hasWelcomedSandboxUser = React.useRef(false);
  var match$3 = React.useState(function (param) {
        return 0;
      });
  var setNumberOfActionsInSandbox = match$3[1];
  var numberOfActionsInSandbox = match$3[0];
  React.useEffect((function (param) {
          var localStorageSandboxWelcomeStateKey = "AvoSandboxWelcomeModal";
          var hasSeenSandboxWelcome = Dom_storage.getItem(localStorageSandboxWelcomeStateKey, localStorage);
          if (workspace.isSandbox && hasWelcomedSandboxUser.current === false && Belt_Option.isNone(hasSeenSandboxWelcome)) {
            Curry._1(send, {
                  TAG: "OpenModal",
                  _0: "SandboxWelcome"
                });
            Dom_storage.setItem(localStorageSandboxWelcomeStateKey, "seen", localStorage);
            hasWelcomedSandboxUser.current = true;
          }
          
        }), []);
  var lastSandboxProgressPrompt = React.useRef(0);
  React.useEffect((function (param) {
          var isAnonymousUser = SandboxBanner.isAnonymousUser();
          if (numberOfActionsInSandbox >= 10 && lastSandboxProgressPrompt.current < 10) {
            AnalyticsRe.sandboxModalSeen(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ProgressModal", isAnonymousUser ? "Anonymous" : "Authenticated");
            Curry._1(send, {
                  TAG: "OpenModal",
                  _0: {
                    NAME: "ComposableModal",
                    VAL: [
                      {
                        NAME: "string",
                        VAL: "You're making great progress! Ready for the next step?"
                      },
                      {
                        NAME: "component",
                        VAL: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                  color: Styles.Color.light10,
                                  children: null
                                }, "Are you ready to uncover your data quality issues, systematically resolve them, and prevent future errors moving forward?", React.createElement(Spacer.make, {
                                      height: 16
                                    }), isAnonymousUser ? "Set up a free workspace in Avo and start your journey to good (or dare we say \"great\") data." : "Head to your Avo workspace to start your journey to good (or dare we say “great”) data."))
                      },
                      [
                        {
                          NAME: "secondary",
                          VAL: {
                            label: "Keep exploring",
                            onClick: (function (closeModal) {
                                AnalyticsRe.sandboxInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "KeepExploring", "ProgressModal", isAnonymousUser ? "Anonymous" : "Authenticated", schemaBundle.schemaId);
                                Curry._1(closeModal, undefined);
                              })
                          }
                        },
                        {
                          NAME: "primary",
                          VAL: {
                            label: isAnonymousUser ? "Create Avo workspace" : "Go to my workspace",
                            onClick: (function (closeModal) {
                                if (isAnonymousUser) {
                                  AnalyticsRe.sandboxInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "CreateWorkspace", "ProgressModal", isAnonymousUser ? "Anonymous" : "Authenticated", schemaBundle.schemaId);
                                  Router.push(undefined, "/onboarding");
                                } else {
                                  AnalyticsRe.sandboxInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "GoToMyWorkspace", "ProgressModal", isAnonymousUser ? "Anonymous" : "Authenticated", schemaBundle.schemaId);
                                  Router.push(undefined, "/schemas/default");
                                }
                                Curry._1(closeModal, undefined);
                              })
                          }
                        }
                      ],
                      500
                    ]
                  }
                });
            lastSandboxProgressPrompt.current = numberOfActionsInSandbox;
          }
          
        }), [numberOfActionsInSandbox]);
  var viewersCanComment = AvoLimits.ViewersCanComment.isAvailable(workspace);
  var match$4 = TimeMachine.use();
  var whenAmI = match$4.whenAmI;
  var mappedGlobalRequirements = Curry._1(ModelStore.Mapped.GlobalRequirements.use, undefined);
  var mappedProperties = ModelStore.Mapped.useProperties();
  var modelStoreIsInitialized = ModelStore.useModelIsInitialized();
  var mappedGlobalRequirements$1 = modelStoreIsInitialized ? mappedGlobalRequirements : Belt_Option.map(model.globalRequirements, TrackingPlanMappedModel.GlobalRequirements.fromOld);
  var mappedProperties$1 = modelStoreIsInitialized ? mappedProperties : Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, model.properties);
  var sendActions = function (masterEditingEnabled, send, writeToBranchOpt, auditOpt, batch, batchSize, skipMasterEditingModalOpt, isCreateDemoBranchAutoActionOpt, onWriteStart, onSuccess, onError, actions) {
    var writeToBranch = writeToBranchOpt !== undefined ? writeToBranchOpt : currentBranch;
    var audit = auditOpt !== undefined ? auditOpt : false;
    var skipMasterEditingModal = skipMasterEditingModalOpt !== undefined ? skipMasterEditingModalOpt : false;
    var isCreateDemoBranchAutoAction = isCreateDemoBranchAutoActionOpt !== undefined ? isCreateDemoBranchAutoActionOpt : false;
    if (workspace.isSandbox) {
      Curry._1(setNumberOfActionsInSandbox, (function (prev) {
              return prev + actions.length | 0;
            }));
    }
    var writeActions = function (toBranchOpt, actionsOpt, param) {
      var toBranch = toBranchOpt !== undefined ? toBranchOpt : NamedBranch.fromUnnamed(masterModel, writeToBranch);
      var actions$1 = actionsOpt !== undefined ? actionsOpt : actions;
      Belt_Option.forEach(onWriteStart, (function (cb) {
              Curry._1(cb, toBranch);
            }));
      $$Promise.$$catch(ActionsWriter.writeToFirebase(userId, schemaId, currentBranch, NamedBranch.toUnnamed(toBranch), isCreateDemoBranchAutoAction, numberOfActions, numberOfMasterActions, latestAction, latestMasterAction, model, mappedModel, masterModel, mappedMasterModel, audit, batch, batchSize, actions$1).then(function (param) {
                Belt_Option.forEach(onSuccess, (function (cb) {
                        Curry._1(cb, toBranch);
                      }));
                return Promise.resolve();
              }), (function (e) {
              Belt_Option.forEach(onError, (function (cb) {
                      Curry._1(cb, e);
                    }));
              return Promise.reject(e);
            }));
    };
    var workspaceLimits = AvoLimits.computeLimits(workspace, model);
    var match = AvoLimits.User.entityStatus(workspaceLimits, userId);
    var protectedMainBranch = workspace.settings.protectedMainBranch && role !== "Admin";
    var showMasterEditingModal = function (param) {
      Curry._1(send, {
            TAG: "OpenModal",
            _0: {
              NAME: "MasterEditing",
              VAL: [
                (function (param) {
                    Curry._1(send, "EditMaster");
                    writeActions(undefined, undefined, undefined);
                  }),
                (function (branchId, branchName) {
                    writeActions({
                          TAG: "Branch",
                          _0: branchId,
                          _1: branchName
                        }, undefined, undefined);
                  })
              ]
            }
          });
    };
    var showNoPermissionsAlert = function (param) {
      Curry._1(send, {
            TAG: "OpenModal",
            _0: {
              NAME: "AlertModal",
              VAL: [
                "Workspace permissions",
                "You don't have permission to edit this workspace.",
                "OK",
                (function (param) {
                    
                  })
              ]
            }
          });
    };
    var showDontChangeHistoryAlert = function (param) {
      Curry._1(send, {
            TAG: "OpenModal",
            _0: {
              NAME: "AlertModal",
              VAL: [
                "Space-time continuum change rejected",
                "Changing the past can have disasterous consequences for the future. Please don't do that.",
                "OK",
                (function (param) {
                    
                  })
              ]
            }
          });
    };
    var actionPermissionLevel = GlobalRequirementsUtils.getActionPermissionLevel(mappedGlobalRequirements$1, mappedProperties$1, role, actions);
    var match$1 = masterEditingEnabled || skipMasterEditingModal;
    var exit = 0;
    if (whenAmI !== "Now") {
      showDontChangeHistoryAlert();
      return Belt_Option.forEach(onError, (function (cb) {
                    Curry._1(cb, {
                          RE_EXN_ID: PermissionsException
                        });
                  }));
    }
    if (match[0] === "Unavailable") {
      return Curry._1(send, {
                  TAG: "OpenModal",
                  _0: {
                    NAME: "BillingPrompt",
                    VAL: "EditingDisabled"
                  }
                });
    }
    if (actionPermissionLevel === "AdminOnly" || actionPermissionLevel === "Blocked") {
      Belt_Option.forEach(onError, (function (cb) {
              Curry._1(cb, {
                    RE_EXN_ID: IllegalActionsForGlobalItemsException
                  });
            }));
      return Curry._1(send, {
                  TAG: "OpenModal",
                  _0: {
                    NAME: "ComposableModal",
                    VAL: [
                      {
                        NAME: "string",
                        VAL: "Global items not editable"
                      },
                      {
                        NAME: "string",
                        VAL: "These changes affect global requirements which aren’t allowed. The requested changes will need to be applied from the owner of the global requirements." + (
                          role === "Admin" ? " You can proceed with overriding global requirements as an admin in this workspace." : ""
                        )
                      },
                      Belt_Array.concat(actionPermissionLevel === "AdminOnly" ? [{
                                NAME: "secondary",
                                VAL: {
                                  label: "Override as admin",
                                  onClick: (function (closeModal) {
                                      Curry._1(closeModal, undefined);
                                      writeActions(undefined, undefined, undefined);
                                    })
                                }
                              }] : [], [{
                              NAME: "primary",
                              VAL: {
                                label: "I understand",
                                onClick: (function (closeModal) {
                                    Curry._1(closeModal, undefined);
                                  })
                              }
                            }]),
                      undefined
                    ]
                  }
                });
    }
    if (AdobeExperiencePlatformBreakingChangeUseCase.isBreakingChange(actions, model)) {
      return Curry._1(send, {
                  TAG: "OpenModal",
                  _0: {
                    NAME: "BreakingChange",
                    VAL: [
                      actions,
                      (function (param) {
                          writeActions(undefined, undefined, undefined);
                        })
                    ]
                  }
                });
    }
    if (wantsToComment(actions) && RoleUtils.canComment(role, viewersCanComment)) {
      return writeActions(undefined, undefined, undefined);
    }
    if (typeof branchStatus !== "object") {
      exit = 2;
    } else {
      switch (branchStatus.TAG) {
        case "Merged" :
        case "Closed" :
            return Curry._1(send, {
                        TAG: "OpenModal",
                        _0: {
                          NAME: "LockedBranch",
                          VAL: branchStatus
                        }
                      });
        default:
          exit = 2;
      }
    }
    if (exit === 2 && writeToBranch === "Master") {
      if (ActionsWriter.wantsToWriteBranchLifecycleActionToMain(actions) && RoleUtils.canEdit(role)) {
        return writeActions(undefined, undefined, undefined);
      }
      if (wantsToImport(actions) && RoleUtils.canEdit(role)) {
        return writeActions(undefined, undefined, undefined);
      }
      if (wantsToComment(actions) && RoleUtils.canComment(role, viewersCanComment)) {
        return writeActions(undefined, undefined, undefined);
      }
      if (protectedMainBranch) {
        if (RoleUtils.canEdit(role)) {
          return showMasterEditingModal();
        }
        
      } else {
        if (RoleUtils.canEdit(role) && wantsToCreateSource(actions)) {
          return writeActions(undefined, undefined, undefined);
        }
        if (!match$1 && RoleUtils.canEdit(role)) {
          return showMasterEditingModal();
        }
        
      }
    }
    if (RoleUtils.canEdit(role)) {
      return writeActions(undefined, undefined, undefined);
    } else {
      showNoPermissionsAlert();
      return Belt_Option.forEach(onError, (function (cb) {
                    Curry._1(cb, {
                          RE_EXN_ID: PermissionsException
                        });
                  }));
    }
  };
  var prevEventsSort = Hooks.useRetained(state.eventSort);
  React.useEffect((function (param) {
          if (state.eventSort !== prevEventsSort) {
            AnalyticsRe.eventListSorted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, prevEventsSort, state.eventSort, schemaBundle.branchId, schemaBundle.schemaId);
          }
          
        }), [state.eventSort]);
  React.useEffect((function (param) {
          if (settingsQueryParamValue !== undefined) {
            switch (settingsQueryParamValue) {
              case "domains" :
                  Curry._1(send, {
                        TAG: "OpenModal",
                        _0: {
                          NAME: "Settings",
                          VAL: "Domains"
                        }
                      });
                  break;
              case "danger-zone" :
              case "permissions" :
                  Curry._1(send, {
                        TAG: "OpenModal",
                        _0: {
                          NAME: "Settings",
                          VAL: "Permissions"
                        }
                      });
                  break;
              case "service-accounts" :
                  Curry._1(send, {
                        TAG: "OpenModal",
                        _0: {
                          NAME: "Settings",
                          VAL: "ServiceAccounts"
                        }
                      });
                  break;
              case "slack-integration" :
                  Curry._1(send, {
                        TAG: "OpenModal",
                        _0: {
                          NAME: "Settings",
                          VAL: "Notifications"
                        }
                      });
                  break;
              default:
                Curry._1(send, {
                      TAG: "OpenModal",
                      _0: {
                        NAME: "Settings",
                        VAL: undefined
                      }
                    });
            }
          }
          if (settingsQueryParamValue !== undefined) {
            Curry._1(removeSettingsQueryParam, undefined);
          }
          
        }), [settingsQueryParamValue]);
  React.useEffect((function (param) {
          var query = Router.getSearch().replace("?", "");
          var maybeNewQuery = QueryString.parse(query)._new;
          if (!(maybeNewQuery == null)) {
            switch (maybeNewQuery) {
              case "branch" :
                  Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                  Curry._1(send, {
                        TAG: "OpenModal",
                        _0: {
                          NAME: "NewBranch",
                          VAL: [
                            undefined,
                            ""
                          ]
                        }
                      });
                  break;
              case "event" :
                  Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                  NewEventModal.openNewEventModal("NewQueryParameter", undefined, send);
                  break;
              case "eventWithMore" :
                  NewEventModal.openNewEventModal("NewQueryParameter", true, send);
                  break;
              case "metric" :
                  Router.Schema.pushSchemaRoute(undefined, undefined, "metrics");
                  var metricId = Nanoid.nanoid();
                  sendActions(state.masterEditingEnabled, send, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (branch) {
                          var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                          Router.Schema.pushDrawerItem(undefined, undefined, {
                                NAME: "metric",
                                VAL: [
                                  metricId,
                                  undefined,
                                  false
                                ]
                              });
                          AnalyticsRe.metricInitiated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, metricId, "", "Metrics", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                        }), undefined, [[
                          {
                            NAME: "AddMetric",
                            VAL: metricId
                          },
                          {
                            metricId: metricId,
                            metricQuery: metricId
                          }
                        ]]);
                  break;
              case "property" :
                  Router.Schema.pushSchemaRoute(undefined, undefined, "properties");
                  Curry._1(send, {
                        TAG: "OpenModal",
                        _0: {
                          NAME: "NewProperty",
                          VAL: [
                            "",
                            "EventProperty",
                            undefined,
                            undefined,
                            "NewQueryParameter"
                          ]
                        }
                      });
                  break;
              default:
                
            }
          }
          
        }), []);
  var renderModel = React.useMemo((function (param) {
          return AvoModel.enrichModel(AvoModel.softEnrichModel(model, AvoConfig.getValidSources(model.sources, model)), AvoConfig.getValidSources(model.sources, model));
        }), [model]);
  var partial_arg = state.masterEditingEnabled;
  var sendActions$1 = function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9) {
    return sendActions(partial_arg, send, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9);
  };
  return React.createElement(GlobalSendContext.make, {
              globalSend: send,
              children: React.createElement(GlobalStateContext.make, {
                    globalState: state,
                    children: React.createElement(SendActionsContext.make, {
                          sendActions: sendActions$1,
                          children: Curry._1(children, renderModel)
                        })
                  })
            });
}

var eventSortToJs = StateTypes.eventSortToJs;

var eventSortFromJs = StateTypes.eventSortFromJs;

var metricsSortToJs = StateTypes.metricsSortToJs;

var metricsSortFromJs = StateTypes.metricsSortFromJs;

var metricsSortOptions = StateTypes.metricsSortOptions;

var filterToString = StateTypes.filterToString;

var make = AvoState;

export {
  eventSortToJs ,
  eventSortFromJs ,
  metricsSortToJs ,
  metricsSortFromJs ,
  metricsSortOptions ,
  filterToString ,
  PermissionsException ,
  IllegalActionsForGlobalItemsException ,
  computeCurrentModelFromActions ,
  wantsToComment ,
  wantsToImport ,
  wantsToCreateSource ,
  make ,
}
/* Text Not a pure module */
