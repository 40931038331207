// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as CopyChangesToBranchModal__Types from "./CopyChangesToBranchModal__Types.mjs";

var dropdown_0 = Css.borderWidth(Css.px(0));

var dropdown_1 = {
  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                NAME: "num",
                VAL: 0.10
              }))),
  tl: {
    hd: Css.marginTop(Css.px(-8)),
    tl: {
      hd: Css.overflow("hidden"),
      tl: {
        hd: Css.zIndex(1),
        tl: {
          hd: Css.height("maxContent"),
          tl: {
            hd: Css.minWidth(Css.px(220)),
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var dropdown = {
  hd: dropdown_0,
  tl: dropdown_1
};

var input_0 = Css.fontFamily(Styles.FontFamily.inter);

var input_1 = {
  hd: Css.fontWeight(Styles.FontWeight.semi),
  tl: {
    hd: Css.fontSize(Styles.FontSize.small),
    tl: {
      hd: Css.borderColor(Styles.Color.light04),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.textOverflow("ellipsis"),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: Styles.Duration.$$short
                }, undefined, undefined, "background-color"),
            tl: {
              hd: Css.selector(" + div", {
                    hd: Icon.color(Styles.Color.light09),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.color(Styles.Color.light11),
                tl: {
                  hd: Css.unsafe("fieldSizing", "content"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.light12),
                          tl: {
                            hd: Css.borderColor(Styles.Color.light07),
                            tl: {
                              hd: Css.selector(" + div", {
                                    hd: Icon.color(Styles.Color.light11),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var input = {
  hd: input_0,
  tl: input_1
};

var itemsWrapper_0 = Css.padding("zero");

var itemsWrapper_1 = {
  hd: Css.margin("zero"),
  tl: /* [] */0
};

var itemsWrapper = {
  hd: itemsWrapper_0,
  tl: itemsWrapper_1
};

var item_0 = Css.display("flex");

var item_1 = {
  hd: Css.alignItems("center"),
  tl: {
    hd: Css.margin("zero"),
    tl: {
      hd: Css.marginBottom(Css.px(1)),
      tl: {
        hd: Css.borderRadius("zero"),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.fontSize(Styles.FontSize.small),
            tl: {
              hd: Css.transitions({
                    hd: Css_Legacy_Core.Transition.shorthand({
                          NAME: "ms",
                          VAL: Styles.Duration.$$short
                        }, undefined, undefined, "background-color"),
                    tl: {
                      hd: Css_Legacy_Core.Transition.shorthand({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "color"),
                      tl: /* [] */0
                    }
                  }),
              tl: {
                hd: Css.hover({
                      hd: Css.backgroundColor(Styles.Color.light02),
                      tl: {
                        hd: Css.selector(" .avo-select-item-icon", {
                              hd: Icon.color(Styles.Color.light10),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    }
  }
};

var item = {
  hd: item_0,
  tl: item_1
};

var itemSelected_0 = Css.backgroundColor(Styles.Color.white);

var itemSelected_1 = {
  hd: Css.position("relative"),
  tl: {
    hd: Css.selector(" .avo-select-item-icon", {
          hd: Icon.color(Styles.Color.light10),
          tl: /* [] */0
        }),
    tl: {
      hd: Css.color(Styles.Color.light12),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.hover({
                hd: Css.backgroundColor(Styles.Color.light02),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    }
  }
};

var itemSelected = {
  hd: itemSelected_0,
  tl: itemSelected_1
};

var iconWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: /* [] */0
        }
      }
    });

var icon = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var Style = {
  dropdown: dropdown,
  input: input,
  itemsWrapper: itemsWrapper,
  item: item,
  itemSelected: itemSelected,
  iconWrapper: iconWrapper,
  icon: icon
};

function CopyChangesToBranchModal__IncludeItemDropdown$ItemComponent(Props) {
  var item = Props.item;
  var description = item.description;
  var icon = item.icon;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement("div", undefined, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      maxWidth: Css.pct(100.0),
                      children: item.label
                    }), description !== undefined ? React.createElement("div", {
                        className: "avo-select-item-description"
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Medium",
                            color: Styles.Color.light08,
                            withWordBreak: true,
                            children: description
                          })) : null), icon !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        width: 8,
                        grow: 1.0
                      }), icon) : null);
}

var ItemComponent = Mantine.Select.Item({
      make: CopyChangesToBranchModal__IncludeItemDropdown$ItemComponent
    });

function getValues(allowExcludeItem, allowExcludeChanges) {
  return Belt_Array.concatMany([
              ["IncludeChanges"],
              allowExcludeChanges ? ["ExcludeChanges"] : [],
              allowExcludeItem ? ["ExcludeItem"] : []
            ]);
}

function toString(allowExcludeChanges, option, itemName) {
  if (option === "ExcludeChanges") {
    return "Exclude changes";
  } else if (option === "IncludeChanges") {
    if (allowExcludeChanges) {
      return "Include changes";
    } else {
      return "Include " + itemName;
    }
  } else {
    return "Exclude " + itemName;
  }
}

function toMantineOption(allowExcludeChanges, option, itemName, parentType, value) {
  if (option === "ExcludeChanges") {
    return {
            label: toString(allowExcludeChanges, option, itemName),
            value: option,
            description: "Keep " + itemName + " on " + CopyChangesToBranchModal__Types.ParentType.toString(parentType) + " but exclude " + itemName + " changes",
            icon: option === value ? React.createElement(Icon.make, {
                    type_: "checkmark",
                    size: "small",
                    color: Styles.Color.darkGreen
                  }) : null
          };
  } else if (option === "IncludeChanges") {
    return {
            label: toString(allowExcludeChanges, option, itemName),
            value: option,
            description: allowExcludeChanges ? "Keep " + itemName + " on " + CopyChangesToBranchModal__Types.ParentType.toString(parentType) + " and include " + itemName + " changes" : "Include " + itemName + " on " + CopyChangesToBranchModal__Types.ParentType.toString(parentType),
            icon: option === value ? React.createElement(Icon.make, {
                    type_: "checkmark",
                    size: "small",
                    color: Styles.Color.darkGreen
                  }) : null
          };
  } else {
    return {
            label: toString(allowExcludeChanges, option, itemName),
            value: option,
            description: "Exclude " + itemName + " on " + CopyChangesToBranchModal__Types.ParentType.toString(parentType),
            icon: option === value ? React.createElement(Icon.make, {
                    type_: "checkmark",
                    size: "small",
                    color: Styles.Color.darkGreen
                  }) : null
          };
  }
}

var Value = {
  getValues: getValues,
  toString: toString,
  toMantineOption: toMantineOption
};

function CopyChangesToBranchModal__IncludeItemDropdown(Props) {
  var onChange = Props.onChange;
  var value = Props.value;
  var allowExcludeItem = Props.allowExcludeItem;
  var allowExcludeChanges = Props.allowExcludeChanges;
  var itemName = Props.itemName;
  var parentType = Props.parentType;
  var options = getValues(allowExcludeItem || value === "ExcludeItem", allowExcludeChanges || value === "ExcludeChanges");
  return React.createElement(Mantine.Select.make, {
              data: Belt_Array.map(options, (function (option) {
                      return toMantineOption(allowExcludeChanges, option, itemName, parentType, value);
                    })),
              dropdownStyles: dropdown,
              inputStyles: input,
              itemComponent: ItemComponent.make,
              itemStyles: item,
              itemSelectedStyles: itemSelected,
              itemsWrapperStyles: itemsWrapper,
              onChange: (function (value) {
                  Belt_Option.forEach(value, onChange);
                }),
              rightSection: React.createElement(Icon.make, {
                    type_: "chevronDown",
                    size: "small"
                  }),
              rightSectionWidth: 36,
              value: value,
              withinPortal: true,
              zIndex: Styles.ZIndex.aboveAll
            });
}

var make = CopyChangesToBranchModal__IncludeItemDropdown;

export {
  Style ,
  ItemComponent ,
  Value ,
  make ,
}
/* dropdown Not a pure module */
