// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Css_AtomicTypes from "bs-css/src/Css_AtomicTypes.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function ZoomableImage(Props) {
  var src = Props.src;
  var alt = Props.alt;
  var match = React.useState(function (param) {
        return false;
      });
  var setIsZoomed = match[1];
  var isZoomed = match[0];
  var match$1 = React.useState(function (param) {
        return false;
      });
  var setIsExiting = match$1[1];
  var isExiting = match$1[0];
  var imageRef = React.useRef(null);
  var toggleZoom = function (param) {
    if (isZoomed) {
      Curry._1(setIsExiting, (function (param) {
              return true;
            }));
      setTimeout((function (param) {
              Curry._1(setIsExiting, (function (param) {
                      return false;
                    }));
              Curry._1(setIsZoomed, (function (param) {
                      return false;
                    }));
            }), 300);
      return ;
    } else {
      return Curry._1(setIsZoomed, (function (param) {
                    return true;
                  }));
    }
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.cursor("pointer"),
                          tl: {
                            hd: Css.width(Css.px(390)),
                            tl: {
                              hd: Css.overflow("hidden"),
                              tl: {
                                hd: Css.borderRadius(Css.px(6)),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.cursor("zoomIn"),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }),
                  onClick: (function (param) {
                      toggleZoom();
                    })
                }, React.createElement("img", {
                      ref: imageRef,
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.px(390)),
                            tl: {
                              hd: Css.objectFit("cover"),
                              tl: /* [] */0
                            }
                          }),
                      alt: alt,
                      src: src
                    })), isZoomed || isExiting ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("fixed"),
                          tl: {
                            hd: Css.top(Css.zero),
                            tl: {
                              hd: Css.left(Css.zero),
                              tl: {
                                hd: Css.right(Css.zero),
                                tl: {
                                  hd: Css.bottom(Css.zero),
                                  tl: {
                                    hd: Css.zIndex(1000),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.justifyContent("center"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.cursor("zoomOut"),
                                            tl: {
                                              hd: Css.backgroundColor(Css.rgba(0, 0, 0, {
                                                        NAME: "percent",
                                                        VAL: 0.7
                                                      })),
                                              tl: {
                                                hd: Css.animationName(Curry._1(Css.keyframes, {
                                                          hd: [
                                                            0,
                                                            {
                                                              hd: Css.opacity(isExiting ? 1.0 : 0.0),
                                                              tl: /* [] */0
                                                            }
                                                          ],
                                                          tl: {
                                                            hd: [
                                                              100,
                                                              {
                                                                hd: Css.opacity(isExiting ? 0.0 : 1.0),
                                                                tl: /* [] */0
                                                              }
                                                            ],
                                                            tl: /* [] */0
                                                          }
                                                        })),
                                                tl: {
                                                  hd: Css.animationDuration(Css_AtomicTypes.Time.ms(200)),
                                                  tl: {
                                                    hd: Css.animationTimingFunction("easeOut"),
                                                    tl: {
                                                      hd: Css.animationFillMode("forwards"),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }),
                    onClick: (function (param) {
                        toggleZoom();
                      })
                  }, React.createElement("img", {
                        className: Curry._1(Css.style, {
                              hd: Css.maxWidth(Css.pct(60.0)),
                              tl: {
                                hd: Css.maxHeight(Css.pct(60.0)),
                                tl: {
                                  hd: Css.objectFit("contain"),
                                  tl: {
                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(0), Css.px(30), undefined, undefined, Css.rgba(0, 0, 0, {
                                                  NAME: "percent",
                                                  VAL: 0.5
                                                }))),
                                    tl: {
                                      hd: Css.borderRadius(Css.px(4)),
                                      tl: {
                                        hd: Css.animationName(Curry._1(Css.keyframes, {
                                                  hd: [
                                                    0,
                                                    {
                                                      hd: Css.transforms({
                                                            hd: Css.scale(isExiting ? 1.0 : 0.5, isExiting ? 1.0 : 0.5),
                                                            tl: {
                                                              hd: Css.translateY(Css.px(isExiting ? 0 : 20)),
                                                              tl: /* [] */0
                                                            }
                                                          }),
                                                      tl: {
                                                        hd: Css.opacity(isExiting ? 1.0 : 0.0),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  ],
                                                  tl: {
                                                    hd: [
                                                      100,
                                                      {
                                                        hd: Css.transforms({
                                                              hd: Css.scale(isExiting ? 0.5 : 1.0, isExiting ? 0.5 : 1.0),
                                                              tl: {
                                                                hd: Css.translateY(Css.px(isExiting ? 20 : 0)),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        tl: {
                                                          hd: Css.opacity(isExiting ? 0.0 : 1.0),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    ],
                                                    tl: /* [] */0
                                                  }
                                                })),
                                        tl: {
                                          hd: Css.animationDuration(Css_AtomicTypes.Time.ms(300)),
                                          tl: {
                                            hd: Css.animationTimingFunction("easeOut"),
                                            tl: {
                                              hd: Css.animationFillMode("forwards"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        alt: alt,
                        src: src
                      })) : null);
}

var make = ZoomableImage;

export {
  make ,
}
/* Css Not a pure module */
