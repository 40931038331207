// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Input from "./Input.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Styles from "./styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as StripeModels from "../../shared/models/StripeModels.mjs";

function fetchCoupon(schemaId, value) {
  return Firebase.Auth.unsafeGetCurrentUser(Firebase.app().auth()).getIdToken().then(function (userToken) {
                  return fetch(Firebase.apiUrl + "/coupon" + (
                              process.env.NODE_ENV === "development" ? "?dev=true" : ""
                            ), {
                              method: "POST",
                              body: Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                            [
                                              "coupon",
                                              value
                                            ],
                                            [
                                              "schemaId",
                                              schemaId
                                            ]
                                          ]))),
                              headers: Caml_option.some(new Headers([
                                        [
                                          "Accept",
                                          "application/json"
                                        ],
                                        [
                                          "Content-Type",
                                          "application/json"
                                        ],
                                        [
                                          "Authorization",
                                          "Bearer " + userToken
                                        ]
                                      ]))
                            });
                }).then(function (prim) {
                return prim.json();
              }).then(function (json) {
              return Promise.resolve(Json_decode.oneOf({
                              hd: (function (json) {
                                  return {
                                          TAG: "Ok",
                                          _0: StripeModels.PromotionCode.decode(json)
                                        };
                                }),
                              tl: {
                                hd: (function (json) {
                                    return {
                                            TAG: "Error",
                                            _0: {
                                              valid: Json_decode.field("valid", Json_decode.bool, json),
                                              error: Json_decode.field("error", Json_decode.string, json)
                                            }
                                          };
                                  }),
                                tl: /* [] */0
                              }
                            }, json));
            });
}

function StripeCouponInput(Props) {
  var schemaId = Props.schemaId;
  var onValidPromoCode = Props.onValidPromoCode;
  var match = React.useState(function (param) {
        return "Collapsed";
      });
  var setStatus = match[1];
  var status = match[0];
  var match$1 = React.useState(function (param) {
        return "";
      });
  var setValue = match$1[1];
  var value = match$1[0];
  var handleApplyCoupon = function (param) {
    Curry._1(setStatus, (function (param) {
            return "Loading";
          }));
    $$Promise.$$catch(fetchCoupon(schemaId, value).then(function (promoCodeResult) {
              if (promoCodeResult.TAG === "Ok") {
                var match = promoCodeResult._0;
                var coupon = match.coupon;
                var amount = coupon.amount_off;
                var id = match.id;
                var percent = coupon.percent_off;
                if (percent !== undefined) {
                  Curry._1(setStatus, (function (param) {
                          return {
                                  TAG: "Valid",
                                  _0: coupon.name,
                                  _1: {
                                    NAME: "Percent",
                                    VAL: percent
                                  }
                                };
                        }));
                  Curry._1(onValidPromoCode, id);
                } else if (amount !== undefined) {
                  Curry._1(setStatus, (function (param) {
                          return {
                                  TAG: "Valid",
                                  _0: coupon.name,
                                  _1: {
                                    NAME: "Amount",
                                    VAL: amount
                                  }
                                };
                        }));
                  Curry._1(onValidPromoCode, id);
                } else {
                  Curry._1(setStatus, (function (param) {
                          return "Invalid";
                        }));
                }
              } else {
                Curry._1(setStatus, (function (param) {
                        return "Invalid";
                      }));
                console.error("coupon error:", promoCodeResult._0.error);
              }
              return Promise.resolve();
            }), (function (error) {
            Curry._1(setStatus, (function (param) {
                    return "Invalid";
                  }));
            console.error("unexpected coupon error:", error);
            return Promise.resolve();
          }));
  };
  if (typeof status !== "object" && status === "Collapsed") {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("flexEnd"),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("button", {
                    className: Curry._1(Css.merge, {
                          hd: Curry._1(Css.style, Styles.button),
                          tl: {
                            hd: Curry._1(Css.style, {
                                  hd: Css.color(Styles.Color.darkBlue),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.textAlign("right"),
                                        tl: {
                                          hd: Css.padding4(Css.px(2), Css.px(0), Css.px(8), Css.px(0)),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.color(Styles.Color.deepBlue),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }),
                    onClick: (function (param) {
                        Curry._1(setStatus, (function (param) {
                                return "Expanded";
                              }));
                      })
                  }, "+ add coupon"));
  }
  var tmp;
  if (typeof status !== "object") {
    tmp = status === "Invalid" ? React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.small),
                    tl: {
                      hd: Css.color(Styles.Color.red),
                      tl: {
                        hd: Css.textAlign("right"),
                        tl: /* [] */0
                      }
                    }
                  }
                })
          }, "Invalid coupon") : null;
  } else {
    var discount = status._1;
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.backgroundColor(Styles.Color.lightGreen),
                tl: {
                  hd: Css.color(Styles.Color.green),
                  tl: {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.fontSize(Styles.FontSize.regular),
                      tl: {
                        hd: Css.padding2(Css.px(15), Css.px(20)),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.textAlign("center"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              })
        }, status._0 + ": " + (
          discount.NAME === "Amount" ? "$" + String(discount.VAL) + " OFF" : String(discount.VAL) + "% OFF"
        ));
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("flexEnd"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginRight(Css.px(5)),
                            tl: {
                              hd: Css.maxWidth(Css.px(250)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(Input.make, {
                          value: value,
                          onChange: (function (value) {
                              Curry._1(setValue, (function (param) {
                                      return value;
                                    }));
                            }),
                          autoFocus: true,
                          onEnter: (function ($$event) {
                              $$event.preventDefault();
                              handleApplyCoupon();
                            }),
                          placeholder: "Coupon code",
                          className: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: /* [] */0
                          },
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                            })
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.height(Css.pct(100)),
                            tl: {
                              hd: Css.maxWidth(Css.px(100)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(Button.make, {
                          label: "Apply",
                          loading: status === "Loading",
                          onClick: (function ($$event) {
                              $$event.preventDefault();
                              handleApplyCoupon();
                            })
                        }))), tmp);
}

var make = StripeCouponInput;

export {
  fetchCoupon ,
  make ,
}
/* Css Not a pure module */
