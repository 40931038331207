// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as CmdK from "./CmdK.mjs";
import * as Json from "@glennsl/bs-json/src/Json.mjs";
import * as $$Text from "./Text.mjs";
import * as Kbar from "kbar";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Icons from "./Icons.mjs";
import * as Input from "./Input.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Parser from "../../model/src/Parser.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Mantine from "./Mantine.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Textarea from "./Textarea.mjs";
import * as Accordion from "./Accordion.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as StateTypes from "./StateTypes.mjs";
import * as TextButton from "./TextButton.mjs";
import Downloadjs from "downloadjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Dom_storage from "rescript/lib/es6/dom_storage.js";
import * as DomainStore from "./DomainStore.mjs";
import * as FeatureFlag from "../../shared/FeatureFlag.mjs";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as TimeMachine from "./TimeMachine.mjs";
import * as ActivityItem from "./ActivityItem.mjs";
import * as ExportFormat from "../../shared/models/ExportFormat.mjs";
import * as Core from "@mantine/core";
import * as IconCheckmark from "./IconCheckmark.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as ModelGenerator from "./ModelGenerator.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as IntegrationHeader from "./integration/IntegrationHeader.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as AdobeFieldGroupIdUseCase from "../../api/functions/src/publishing/adobeApi/AdobeFieldGroupIdUseCase.mjs";

function decodeValue(json) {
  return {
          key: Json_decode.field("key", Json_decode.string, json),
          value: Pervasives.bool_of_string(Json_decode.field("value", Json_decode.string, json))
        };
}

function encodeValue(value) {
  return Json_encode.object_({
              hd: [
                "key",
                value.key
              ],
              tl: {
                hd: [
                  "value",
                  Pervasives.string_of_bool(value.value)
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return Json_decode.array(decodeValue, json);
}

function encode(values) {
  return Json_encode.array(encodeValue, values);
}

var PersistedFeatureFlag = {
  decodeValue: decodeValue,
  encodeValue: encodeValue,
  decode: decode,
  encode: encode
};

function getBoolValue(key) {
  return Belt_Option.mapWithDefault(Dom_storage.getItem(key, localStorage), false, Pervasives.bool_of_string);
}

function setBoolValue(key, value) {
  Dom_storage.setItem(key, Pervasives.string_of_bool(value), localStorage);
}

function getWorkspacePersistLocalStorageKey(schemaId) {
  return "featureFlagsShouldPersistForWorkspace-" + schemaId;
}

function getActiveFeatureFlagsStorageKey(schemaId) {
  return "activeFeatureFlagValues-" + schemaId;
}

var getWorkspacePersistForFeatureFlags = getBoolValue;

function useWorkspacePersistForFeatureFlags(param) {
  var match = WorkspaceContext.use();
  var id = match.id;
  var key = getWorkspacePersistLocalStorageKey(id);
  var match$1 = React.useState(function (param) {
        return getBoolValue(key);
      });
  var setIsPersisting = match$1[1];
  var isPersisting = match$1[0];
  React.useEffect((function (param) {
          if (!isPersisting) {
            Dom_storage.removeItem(getActiveFeatureFlagsStorageKey(getActiveFeatureFlagsStorageKey(id)), localStorage);
          }
          
        }), [isPersisting]);
  var handleChange = React.useCallback((function (value) {
          Curry._1(setIsPersisting, (function (param) {
                  return value;
                }));
          setBoolValue(key, value);
        }), [
        setBoolValue,
        key
      ]);
  return [
          isPersisting,
          handleChange
        ];
}

function getPersistedFeatureFlags(localStorageKey) {
  return Belt_Option.mapWithDefault(Dom_storage.getItem(localStorageKey, localStorage), [], (function (str) {
                return Json_decode.array(decodeValue, JSON.parse(str));
              }));
}

function setPersistedFeatureFlag(localStorageKey, value) {
  var currentValues = getPersistedFeatureFlags(localStorageKey);
  var newValues = Belt_Array.concat(Belt_Array.keep(currentValues, (function (param) {
              return param.key !== value.key;
            })), [value]);
  Dom_storage.setItem(localStorageKey, JSON.stringify(Json_encode.array(encodeValue, newValues)), localStorage);
}

function removePersistedFeatureFlag(localStorageKey, value) {
  var currentValues = getPersistedFeatureFlags(localStorageKey);
  var newValues = Belt_Array.keep(currentValues, (function (param) {
          return param.key !== value.key;
        }));
  Dom_storage.setItem(localStorageKey, JSON.stringify(Json_encode.array(encodeValue, newValues)), localStorage);
}

var FeatureFlagLocalStorage = {
  getBoolValue: getBoolValue,
  setBoolValue: setBoolValue,
  getWorkspacePersistLocalStorageKey: getWorkspacePersistLocalStorageKey,
  getActiveFeatureFlagsStorageKey: getActiveFeatureFlagsStorageKey,
  getWorkspacePersistForFeatureFlags: getWorkspacePersistForFeatureFlags,
  useWorkspacePersistForFeatureFlags: useWorkspacePersistForFeatureFlags,
  getPersistedFeatureFlags: getPersistedFeatureFlags,
  setPersistedFeatureFlag: setPersistedFeatureFlag,
  removePersistedFeatureFlag: removePersistedFeatureFlag
};

function getFeatureFlagSetting(featureFlag, featureFlagSettings) {
  return Js_dict.get(featureFlagSettings, FeatureFlag.featureFlagToString(featureFlag));
}

function AvoAdmin$FeatureFlags(Props) {
  var schemaId = WorkspaceContext.use().id;
  var match = AppFeatureFlag.use();
  var removeAllLocalOverrides = match.removeAllLocalOverrides;
  var removeLocalOverride = match.removeLocalOverride;
  var setLocalOverride = match.setLocalOverride;
  var localOverrides = match.localOverrides;
  var match$1 = useWorkspacePersistForFeatureFlags();
  var activeFeatureFlagsStorageKey = getActiveFeatureFlagsStorageKey(schemaId);
  Hooks.useDidUpdate1((function (param) {
          Curry._1(removeAllLocalOverrides, undefined);
        }), [schemaId]);
  var overridesEmpty = Caml_obj.equal(localOverrides, {});
  return React.createElement("section", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(8)),
                    tl: /* [] */0
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.gap(Css.px(8)),
                          tl: {
                            hd: Css.marginTop(Css.px(8)),
                            tl: {
                              hd: Css.marginBottom(Css.px(8)),
                              tl: {
                                hd: Css.marginLeft(Css.px(22)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Persist for workspace"
                    }), React.createElement(Switch.make, {
                      value: match$1[0],
                      onChange: match$1[1],
                      size: "Small"
                    })), React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      Curry._1(removeAllLocalOverrides, undefined);
                    }),
                  size: "Small",
                  disabled: overridesEmpty,
                  children: "Reset (" + String(Js_dict.entries(localOverrides).length) + ") local override" + (
                    Js_dict.values(localOverrides).length === 1 ? "" : "s"
                  )
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("ul", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding("zero"),
                        tl: {
                          hd: Css.margin("zero"),
                          tl: /* [] */0
                        }
                      })
                }, Belt_Array.map(match.globalFeatureFlags, (function (globalFeatureFlag) {
                        var localOverride = Js_dict.get(localOverrides, FeatureFlag.featureFlagToString(globalFeatureFlag.featureFlag));
                        return React.createElement("li", {
                                    key: FeatureFlag.featureFlagToString(globalFeatureFlag.featureFlag),
                                    className: Curry._1(Css.style, {
                                          hd: Css.listStyleType("none"),
                                          tl: {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.gap(Css.px(8)),
                                                tl: {
                                                  hd: Css.marginBottom(Css.px(4)),
                                                  tl: {
                                                    hd: Css.lastOfType({
                                                          hd: Css.marginBottom(Css.px(4)),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: {
                                                      hd: Css.hover({
                                                            hd: Css.backgroundColor(Styles.Color.light02),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, globalFeatureFlag.enabled ? React.createElement(IconCheckmark.make, {
                                          size: 10,
                                          color: Styles.Color.green
                                        }) : React.createElement(Spacer.make, {
                                          width: 10
                                        }), React.createElement($$Text.make, {
                                        size: "Small",
                                        weight: globalFeatureFlag.enabled ? "Semi" : "Regular",
                                        singleLine: true,
                                        children: FeatureFlag.featureFlagToString(globalFeatureFlag.featureFlag)
                                      }), React.createElement(Spacer.make, {
                                        grow: 1.0
                                      }), React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.opacity(Belt_Option.isSome(localOverride) ? 1.0 : 0.0),
                                              tl: {
                                                hd: Css.transition({
                                                      NAME: "ms",
                                                      VAL: 200.0
                                                    }, {
                                                      NAME: "ms",
                                                      VAL: 400.0
                                                    }, undefined, "opacity"),
                                                tl: {
                                                  hd: Css.width(Css.px(8)),
                                                  tl: {
                                                    hd: Css.height(Css.px(8)),
                                                    tl: {
                                                      hd: Css.borderRadius(Css.px(4)),
                                                      tl: {
                                                        hd: Css.backgroundColor(Styles.Color.avoPink),
                                                        tl: {
                                                          hd: Css.marginRight(Css.px(2)),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            })
                                      }), React.createElement("div", {
                                        className: Curry._1(Css.merge, {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.selector(" button", {
                                                          hd: Css.transition({
                                                                NAME: "ms",
                                                                VAL: 200.0
                                                              }, {
                                                                NAME: "ms",
                                                                VAL: 400.0
                                                              }, undefined, "background-color"),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }),
                                              tl: {
                                                hd: localOverride !== undefined ? (
                                                    localOverride.enabled ? Curry._1(Css.style, {
                                                            hd: Css.selector(" button", {
                                                                  hd: Css.backgroundColor(Styles.Color.avoPink),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: /* [] */0
                                                          }) : Curry._1(Css.style, {
                                                            hd: Css.selector(" button", {
                                                                  hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.avoPink, 0.1)),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: /* [] */0
                                                          })
                                                  ) : Curry._1(Css.style, /* [] */0),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, React.createElement(Switch.make, {
                                            value: localOverride !== undefined ? localOverride.enabled : globalFeatureFlag.enabled,
                                            onChange: (function (param) {
                                                var localOverride = Js_dict.get(localOverrides, FeatureFlag.featureFlagToString(globalFeatureFlag.featureFlag));
                                                if (localOverride !== undefined) {
                                                  Curry._1(removeLocalOverride, globalFeatureFlag.featureFlag);
                                                  return removePersistedFeatureFlag(activeFeatureFlagsStorageKey, {
                                                              key: FeatureFlag.featureFlagToJs(globalFeatureFlag.featureFlag),
                                                              value: param
                                                            });
                                                } else {
                                                  Curry._1(setLocalOverride, {
                                                        featureFlag: globalFeatureFlag.featureFlag,
                                                        enabled: param
                                                      });
                                                  return setPersistedFeatureFlag(activeFeatureFlagsStorageKey, {
                                                              key: FeatureFlag.featureFlagToJs(globalFeatureFlag.featureFlag),
                                                              value: param
                                                            });
                                                }
                                              }),
                                            faded: Belt_Option.isNone(localOverride),
                                            size: "Small"
                                          })));
                      }))));
}

var FeatureFlags = {
  getFeatureFlagSetting: getFeatureFlagSetting,
  make: AvoAdmin$FeatureFlags
};

function AvoAdmin$TimeMachine(Props) {
  var globalSend = Props.globalSend;
  var match = TimeMachine.use();
  var travelTo = match.travelTo;
  var whenAmI = match.whenAmI;
  var match$1 = React.useState(function (param) {
        if (typeof whenAmI === "object") {
          return whenAmI.VAL.toISOString();
        } else {
          return new Date().toISOString();
        }
      });
  var setTimeTo = match$1[1];
  var timeTo = match$1[0];
  return React.createElement(React.Fragment, undefined, React.createElement("form", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(8)),
                        tl: /* [] */0
                      }),
                  onSubmit: (function (e) {
                      e.preventDefault();
                      var travelToDate = new Date(timeTo);
                      if (Caml_obj.greaterthan(travelToDate, new Date())) {
                        return Curry._1(globalSend, {
                                    TAG: "OpenModal",
                                    _0: {
                                      NAME: "AlertModal",
                                      VAL: [
                                        "Traveling to the future is limited.",
                                        "For now you can only travel to the future with the 1 sec/sec speed. If you need to travel to the future faster, please contact the Avo team.",
                                        "OK",
                                        (function (param) {
                                            
                                          })
                                      ]
                                    }
                                  });
                      } else {
                        return Curry._1(travelTo, {
                                    NAME: "Then",
                                    VAL: new Date(timeTo)
                                  });
                      }
                    })
                }, React.createElement(Input.make, {
                      label: "Travel To",
                      value: timeTo,
                      onChange: (function (value) {
                          Curry._1(setTimeTo, (function (param) {
                                  return value;
                                }));
                        }),
                      required: true,
                      type_: "default"
                    }), React.createElement(Button.make, {
                      label: "Travel",
                      size: "large"
                    })), React.createElement("section", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(8)),
                        tl: /* [] */0
                      })
                }, React.createElement(Button.make, {
                      disabled: whenAmI === "Now",
                      label: "Back to the future",
                      onClick: (function (param) {
                          Curry._1(travelTo, "Now");
                        }),
                      size: "large"
                    })));
}

var TimeMachine$1 = {
  make: AvoAdmin$TimeMachine
};

function AvoAdmin$ModelGenerator(Props) {
  var match = React.useState(function (param) {
        return 100;
      });
  var setNumEvents = match[1];
  var numEvents = match[0];
  var match$1 = React.useState(function (param) {
        return 100;
      });
  var setNumProperties = match$1[1];
  var numProperties = match$1[0];
  var eventsToDomains = DomainStore.useDomainEvents();
  return React.createElement("form", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(8)),
                    tl: /* [] */0
                  }),
              onSubmit: (function (e) {
                  e.preventDefault();
                  var model = ModelGenerator.generateModel(numEvents, numProperties);
                  ModelGenerator.handleExport(model, eventsToDomains);
                })
            }, React.createElement(Input.make, {
                  label: "Number of events",
                  value: String(numEvents),
                  onChange: (function (value) {
                      Curry._1(setNumEvents, (function (param) {
                              return value;
                            }));
                    }),
                  required: true,
                  type_: "number"
                }), React.createElement(Input.make, {
                  label: "Number of properties",
                  value: String(numProperties),
                  onChange: (function (value) {
                      Curry._1(setNumProperties, (function (param) {
                              return value;
                            }));
                    }),
                  required: true,
                  type_: "number"
                }), React.createElement(Button.make, {
                  label: "Generate and download",
                  size: "large"
                }));
}

var ModelGenerator$1 = {
  make: AvoAdmin$ModelGenerator
};

function AvoAdmin$ModelPrinter(Props) {
  var model = ModelStore.useModel();
  return React.createElement(Button.make, {
              label: "Print model",
              onClick: (function (param) {
                  var json = JSON.stringify(Printer.printModel(model));
                  var match = ExportFormat.toHeader("AvoJson");
                  var mimeType = match[0];
                  Downloadjs(new Blob([json], {
                            encoding: "UTF-8",
                            type: mimeType
                          }), "AvoTrackingPlan." + match[1], mimeType);
                }),
              size: "large"
            });
}

var ModelPrinter = {
  make: AvoAdmin$ModelPrinter
};

function AvoAdmin$ManualActionWriter(Props) {
  var globalSend = Props.globalSend;
  var workspace = WorkspaceContext.use();
  var schemaBundle = SchemaBundleContext.use();
  var addToast = Toast.useAddToast();
  var sendActions = SendActionsContext.use();
  var match = React.useState(function (param) {
        return "";
      });
  var setActionInputValue = match[1];
  var actionInputValue = match[0];
  var match$1 = React.useState(function (param) {
        return false;
      });
  var setLoading = match$1[1];
  return React.createElement("section", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(8)),
                    tl: {
                      hd: Css.margin2(Css.px(10), Css.px(0)),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(10), Css.px(15)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.paleOrange),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.marginBottom(Css.px(10)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Tiny",
                      weight: "Semi",
                      color: Styles.Color.deepOrange,
                      children: "The manual action writer gives you a direct access to writing actions to a workspace. None of the actions you write here go through any audit checks to ensure illegal states are not produced. Make sure the actions you're writing are safe and won't introduce illegal states in the workspace (e.g. events or properties with the same name)."
                    })), React.createElement("form", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(8)),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: {
                            hd: Css.marginBottom(Css.px(10)),
                            tl: /* [] */0
                          }
                        }
                      }),
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      var maybeJson;
                      try {
                        maybeJson = Json.parseOrRaise(actionInputValue);
                      }
                      catch (raw_error){
                        var error = Caml_js_exceptions.internalToOCamlException(raw_error);
                        console.error("Could not parse JSON", error);
                        maybeJson = undefined;
                      }
                      var maybeActions = Belt_Option.flatMap(maybeJson, (function (json) {
                              var isSingleAction;
                              try {
                                Belt_Option.map(maybeJson, Parser.parseAction);
                                isSingleAction = true;
                              }
                              catch (exn){
                                isSingleAction = false;
                              }
                              try {
                                if (isSingleAction) {
                                  return {
                                          hd: Parser.parseAction(json),
                                          tl: /* [] */0
                                        };
                                } else {
                                  return Belt_List.fromArray(Json_decode.array(Parser.parseAction, json));
                                }
                              }
                              catch (raw_error){
                                var error = Caml_js_exceptions.internalToOCamlException(raw_error);
                                console.error("Could not parse actions", error);
                                return ;
                              }
                            }));
                      if (maybeActions !== undefined) {
                        var actionsWithContext = Belt_List.toArray(Belt_List.map(AvoUtils.actionByObject(maybeActions), (function (param) {
                                    return [
                                            param[1],
                                            ActivityItem.getActionContext(param[0])
                                          ];
                                  })));
                        var numActionsString = String(Belt_List.length(maybeActions));
                        return Curry._1(globalSend, {
                                    TAG: "OpenModal",
                                    _0: {
                                      NAME: "ConfirmModal",
                                      VAL: [
                                        "Are you sure you want to write actions?",
                                        "You are about to write " + numActionsString + " actions to the \"" + schemaBundle.branchName + "\" branch in the \"" + workspace.name + "\" workspace?",
                                        "Write " + numActionsString + " actions",
                                        (function (param) {
                                            Curry._1(setLoading, (function (param) {
                                                    return true;
                                                  }));
                                            Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (param) {
                                                      Curry._1(setLoading, (function (param) {
                                                              return false;
                                                            }));
                                                      Curry._1(setActionInputValue, (function (param) {
                                                              return "";
                                                            }));
                                                      Curry._1(addToast, {
                                                            message: React.createElement($$Text.make, {
                                                                  size: "Small",
                                                                  weight: "Medium",
                                                                  singleLine: true,
                                                                  color: Styles.Color.green,
                                                                  children: "Sucessfully wrote " + numActionsString + " actions"
                                                                }),
                                                            toastType: "Success",
                                                            persist: false
                                                          });
                                                    }),
                                                  (function (error) {
                                                      Curry._1(setLoading, (function (param) {
                                                              return false;
                                                            }));
                                                      console.error("Could not write actions", error);
                                                      window.alert("Could not write actions. Check your console for error details.");
                                                    }),
                                                  actionsWithContext
                                                ]);
                                          }),
                                        (function (param) {
                                            
                                          })
                                      ]
                                    }
                                  });
                      }
                      window.alert("Could not parse actions. Check your console for error details.");
                    })
                }, React.createElement(Textarea.make, {
                      value: actionInputValue,
                      placeholder: "[\n    {\n        \"AddPropertyRef\": {\n            \"eventId\": \"<event-id>\",\n            \"propertyId\": \"<property-id>\"\n        }\n    },\n    ...\n]",
                      onChange: (function (value) {
                          Curry._1(setActionInputValue, (function (param) {
                                  return value;
                                }));
                        }),
                      classNameOverwrites: {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.minHeight(Css.px(200)),
                          tl: {
                            hd: Css.fontSize(Css.px(11)),
                            tl: {
                              hd: Css.fontFamily(Styles.FontFamily.polar),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement(Button.make, {
                      label: "Write actions",
                      loading: match$1[0],
                      size: "small"
                    })));
}

var ManualActionWriter = {
  make: AvoAdmin$ManualActionWriter
};

var _map = {"importModalFromInspector":"importModalFromInspector","legacyImportModal":"legacyImportModal"};

function modalToJs(param) {
  return param;
}

function modalFromJs(param) {
  return _map[param];
}

function getModalLabel(modal) {
  return Case.to_("Title", modal);
}

function getModal(modal) {
  if (modal === "importModalFromInspector") {
    return {
            NAME: "ImportSuccess",
            VAL: {
              eventCount: 666,
              propertyCount: 666,
              sourceCount: 666,
              resolvedIssuesCount: 666,
              importOrigin: "InspectorIssueDetails",
              newStakeholderDomainCount: 666
            }
          };
  } else {
    return {
            NAME: "ImportSuccess",
            VAL: {
              eventCount: 666,
              propertyCount: 666,
              sourceCount: 666,
              resolvedIssuesCount: 666,
              importOrigin: "CmdPalette",
              newStakeholderDomainCount: 666
            }
          };
  }
}

function AvoAdmin$OpenModals(Props) {
  var openModal = GlobalSendContext.useOpenModal();
  var modals = [
    "importModalFromInspector",
    "legacyImportModal"
  ];
  var match = React.useState(function (param) {
        return Belt_Option.map(Belt_Array.get(modals, 0), getModal);
      });
  var setChosenModal = match[1];
  var chosenModal = match[0];
  var options = Belt_Array.map(modals, (function (modal) {
          return [
                  {
                    NAME: "Label",
                    VAL: Case.to_("Title", modal)
                  },
                  modal
                ];
        }));
  var value = Belt_Option.map(Belt_Array.get(modals, 0), (function (modal) {
          return modal;
        }));
  if (value !== undefined) {
    return React.createElement("div", undefined, React.createElement(Select.make, {
                    onSelect: (function (value) {
                        var modal = _map[value];
                        if (modal !== undefined) {
                          return Curry._1(setChosenModal, (function (param) {
                                        return getModal(modal);
                                      }));
                        }
                        
                      }),
                    options: options,
                    value: value
                  }), React.createElement(Spacer.make, {
                    width: 16
                  }), React.createElement(Button.make, {
                    disabled: Belt_Option.isNone(chosenModal),
                    label: "Open modal",
                    onClick: (function (param) {
                        Belt_Option.forEach(chosenModal, Curry.__1(openModal));
                      })
                  }));
  } else {
    return "Ooops, no modals available... Check code 🤖";
  }
}

var OpenModals = {
  modalToJs: modalToJs,
  modalFromJs: modalFromJs,
  getModalValue: modalToJs,
  getModalLabel: getModalLabel,
  getModal: getModal,
  make: AvoAdmin$OpenModals
};

function AvoAdmin$CaseConverter(Props) {
  var model = ModelStore.Mapped.use();
  var match = React.useState(function (param) {
        return "Title";
      });
  var setCase = match[1];
  var $$case = match[0];
  var caseOptions = Belt_Array.map(Case.casingOptions, (function ($$case) {
          return [
                  {
                    NAME: "Label",
                    VAL: Case.tToJs($$case)
                  },
                  Case.tToJs($$case)
                ];
        }));
  var __x = Curry._2(TrackingPlanMappedModel.Events.keepMap, model.events, (function ($$event) {
          var casedName = Case.to_($$case, $$event.name);
          if ($$event.name !== casedName) {
            return {
                    NAME: "UpdateEventName",
                    VAL: [
                      $$event.id,
                      casedName
                    ]
                  };
          }
          
        }));
  var eventNameActions = JSON.stringify(Json_encode.array(Printer.printAction, __x), null, 2);
  var __x$1 = Curry._2(TrackingPlanMappedModel.Properties.keepMap, model.properties, (function (property) {
          var casedName = Case.to_($$case, property.name);
          if (property.name !== casedName) {
            return {
                    NAME: "UpdatePropertyName",
                    VAL: [
                      property.id,
                      casedName
                    ]
                  };
          }
          
        }));
  var propertyNameActions = JSON.stringify(Json_encode.array(Printer.printAction, __x$1), null, 2);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(15)),
                    tl: /* [] */0
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Medium",
                      children: "Convert to: "
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(Select.make, {
                      onSelect: (function (value) {
                          Belt_Option.forEach(Case.tFromJs(value), (function ($$case) {
                                  Curry._1(setCase, (function (param) {
                                          return $$case;
                                        }));
                                }));
                        }),
                      options: caseOptions,
                      value: Case.tToJs($$case)
                    })), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Medium",
                  children: "Actions to convert all event names:"
                }), React.createElement(Textarea.make, {
                  value: eventNameActions,
                  onChange: (function (param) {
                      
                    }),
                  size: "Small",
                  classNameOverwrites: {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.minHeight(Css.px(200)),
                      tl: {
                        hd: Css.fontWeight(Styles.FontWeight.regular),
                        tl: /* [] */0
                      }
                    }
                  },
                  disabled: true
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Medium",
                  children: "Actions to convert all property names:"
                }), React.createElement(Textarea.make, {
                  value: propertyNameActions,
                  onChange: (function (param) {
                      
                    }),
                  size: "Small",
                  classNameOverwrites: {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.minHeight(Css.px(200)),
                      tl: {
                        hd: Css.fontWeight(Styles.FontWeight.regular),
                        tl: /* [] */0
                      }
                    }
                  },
                  disabled: true
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Small",
                  children: "Copy the actions above and paste them in the Manual Action Writer to apply the case conversion to the branch you're currently on."
                }));
}

var CaseConverter = {
  make: AvoAdmin$CaseConverter
};

function AvoAdmin$DeleteAdobeFieldGroups(Props) {
  var match = React.useState(function (param) {
        return "xWG72MoVF4IZHgj1nIWf8";
      });
  var setFieldGroupId = match[1];
  var fieldGroupId = match[0];
  var match$1 = React.useState(function (param) {
        return "eyJhbGciOiJSUzI1NiIsIng1dSI6Imltc19uYTEta2V5LWF0LTEuY2VyIiwia2lkIjoiaW1zX25hMS1rZXktYXQtMSIsIml0dCI6ImF0In0.eyJpZCI6IjE3NDI1NTk4OTUzODBfNTM5NDU5NjctZmVmOS00ZmVhLWIwNTktODQ4YWM3OGJjYjMxX3VlMSIsIm9yZyI6IjE3QzYxRTFFNjc4OTEzMUUwQTQ5NUU5OEBBZG9iZU9yZyIsInR5cGUiOiJhY2Nlc3NfdG9rZW4iLCJjbGllbnRfaWQiOiJjY2NmZWQzMWZiMWQ0NWQwYTQ0ZTMwZjgzMzA5ZDVjZSIsInVzZXJfaWQiOiJEMThFMUVFNDY3QTM2RDY4MEE0OTVFOTlAdGVjaGFjY3QuYWRvYmUuY29tIiwiYXMiOiJpbXMtbmExIiwiYWFfaWQiOiJEMThFMUVFNDY3QTM2RDY4MEE0OTVFOTlAdGVjaGFjY3QuYWRvYmUuY29tIiwiY3RwIjozLCJtb2kiOiI0ZGY2NjZlMiIsImV4cGlyZXNfaW4iOiI4NjQwMDAwMCIsInNjb3BlIjoiYWRkaXRpb25hbF9pbmZvLmpvYl9mdW5jdGlvbixvcGVuaWQsc2Vzc2lvbixBZG9iZUlELGFkZGl0aW9uYWxfaW5mby5yb2xlcyxyZWFkX29yZ2FuaXphdGlvbnMsYWRkaXRpb25hbF9pbmZvLnByb2plY3RlZFByb2R1Y3RDb250ZXh0IiwiY3JlYXRlZF9hdCI6IjE3NDI1NTk4OTUzODAifQ.D-q_cvQvlYDkeoXm27IKuL-K_851lkwTVAoYpt6feNRSs29EEShh8fgk3g7K2S4T-W8T9D8_KA1W6oM9ovdtUNCVk2rAymz_lkofH2Kqi3gKW0_p8d3B4QNxvhJcERFp6FCiOSJew8P-9jDOI-2qBzyOv53ZHhZt0PmCM8pDfaI0WWqfb8T-PHTcQsgm6-a_2OLHlUfBe-8RZHZVYf9bDpEOZO872IXZrrSA9Mp_yx0OscTXkmsL3PvCxKjYcGP8rbyWvFAcaSc5KmQvMO4DgAIRRvzxmhnCx01WfQihP6qDbSuQeozzs69gXMakCqe_t2wyUFGj96nuoDUkEkqjyw";
      });
  var setAccessToken = match$1[1];
  var accessToken = match$1[0];
  var match$2 = React.useState(function (param) {
        return "";
      });
  var setResult = match$2[1];
  var match$3 = React.useState(function (param) {
        return false;
      });
  var setIsLoading = match$3[1];
  var workspace = WorkspaceContext.use();
  var worksapceId = workspace.id;
  var deleteFieldGroup = function (versionOpt, deletedVersionsOpt, apiKey, orgId, accessToken, altId) {
    var version = versionOpt !== undefined ? versionOpt : 1;
    var deletedVersions = deletedVersionsOpt !== undefined ? deletedVersionsOpt : [];
    var currentAltId = version === 1 ? altId : altId + "_v" + String(version);
    var path = "/data/foundation/schemaregistry/tenant/fieldgroups/" + encodeURIComponent(currentAltId);
    var url = "https://platform.adobe.io" + path;
    var headers = new Headers([
          [
            "Authorization",
            "Bearer " + accessToken
          ],
          [
            "x-api-key",
            apiKey
          ],
          [
            "x-gw-ims-org-id",
            orgId
          ],
          [
            "Accept",
            "application/vnd.adobe.xed-full+json"
          ],
          [
            "Content-Type",
            "application/json"
          ]
        ]);
    return $$Promise.$$catch(fetch(url, {
                      method: "DELETE",
                      headers: Caml_option.some(headers),
                      credentials: "omit"
                    }).then(function (response) {
                    if (response.ok) {
                      var updatedDeletedVersions = Belt_Array.concat(deletedVersions, [currentAltId]);
                      return deleteFieldGroup(version + 1 | 0, updatedDeletedVersions, apiKey, orgId, accessToken, altId);
                    }
                    if (deletedVersions.length !== 0) {
                      return Promise.resolve({
                                  TAG: "Ok",
                                  _0: deletedVersions
                                });
                    }
                    var status = response.status;
                    return response.text().then(function (errorText) {
                                return Promise.resolve({
                                            TAG: "Error",
                                            _0: "Failed to delete field group " + altId + ": Status " + String(status) + ", " + errorText
                                          });
                              });
                  }), (function (error) {
                  var jsExn = Caml_js_exceptions.as_js_exn(error);
                  var errorMsg;
                  if (jsExn !== undefined) {
                    var msg = Caml_option.valFromOption(jsExn).message;
                    errorMsg = msg !== undefined ? msg : "Unknown error";
                  } else {
                    errorMsg = "Unknown error";
                  }
                  if (deletedVersions.length !== 0) {
                    return Promise.resolve({
                                TAG: "Ok",
                                _0: deletedVersions
                              });
                  } else {
                    return Promise.resolve({
                                TAG: "Error",
                                _0: "Error deleting field group " + altId + ": " + errorMsg
                              });
                  }
                }));
  };
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(15)),
                    tl: /* [] */0
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Medium",
                  children: "Enter comma separated Property Bundle IDs to delete:"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement(Input.make, {
                  label: "Property Bundle IDs",
                  value: fieldGroupId,
                  onChange: (function (value) {
                      Curry._1(setFieldGroupId, (function (param) {
                              return value;
                            }));
                    }),
                  type_: "default"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Medium",
                  children: "Enter Access Token:"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement(Input.make, {
                  label: "Access Token",
                  value: accessToken,
                  onChange: (function (value) {
                      Curry._1(setAccessToken, (function (param) {
                              return value;
                            }));
                    }),
                  type_: "default"
                }), React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement("div", undefined, React.createElement($$Text.make, {
                      size: "Tiny",
                      color: Styles.Color.light08,
                      children: null
                    }, "Get a 24-hour valid token from ", React.createElement("a", {
                          className: Curry._1(Css.style, {
                                hd: Css.color(Styles.Color.blue),
                                tl: {
                                  hd: Css.textDecoration("underline"),
                                  tl: /* [] */0
                                }
                              }),
                          href: "https://developer.adobe.com/console/projects/3540547/4566206088345335685/workspaces/4566206088345356288/credentials/733666/details/oauthservertoserver",
                          rel: "noopener noreferrer",
                          target: "_blank"
                        }, "Adobe Developer Console"))), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement(Button.make, {
                  label: "Delete Field Group",
                  loading: match$3[0],
                  onClick: (function (param) {
                      Curry._1(setIsLoading, (function (param) {
                              return true;
                            }));
                      Curry._1(setResult, (function (param) {
                              return "Starting deletion process...";
                            }));
                      var fieldGroupIds = Belt_Array.keep(Belt_Array.map(fieldGroupId.split(","), (function (id) {
                                  return id.trim();
                                })), (function (id) {
                              return id !== "";
                            }));
                      if (fieldGroupIds.length === 0) {
                        Curry._1(setResult, (function (param) {
                                return "No valid field group IDs provided";
                              }));
                        return Curry._1(setIsLoading, (function (param) {
                                      return false;
                                    }));
                      } else {
                        $$Promise.$$catch(Promise.resolve("avosoftwwaretpp").then(function (tenantId) {
                                        return Promise.all(Belt_Array.map(fieldGroupIds, (function (baseId) {
                                                          var altId = AdobeFieldGroupIdUseCase.toAltId(tenantId, baseId, 1);
                                                          Curry._1(setResult, (function (prev) {
                                                                  return prev + "\nDeleting field group with altId: " + altId + "...";
                                                                }));
                                                          var match = altId.split("_v");
                                                          var match$1;
                                                          if (match.length !== 2) {
                                                            match$1 = [
                                                              altId,
                                                              1
                                                            ];
                                                          } else {
                                                            var baseId$1 = match[0];
                                                            var versionStr = match[1];
                                                            var v = Belt_Int.fromString(versionStr);
                                                            match$1 = v !== undefined ? [
                                                                baseId$1,
                                                                v
                                                              ] : [
                                                                altId,
                                                                1
                                                              ];
                                                          }
                                                          return deleteFieldGroup(match$1[1], [], "cccfed31fb1d45d0a44e30f83309d5ce", "17C61E1E6789131E0A495E98@AdobeOrg", accessToken, match$1[0]);
                                                        })));
                                      }).then(function (results) {
                                      var match = Belt_Array.reduce(results, [
                                            [],
                                            []
                                          ], (function (param, result) {
                                              var failures = param[1];
                                              var successes = param[0];
                                              if (result.TAG === "Ok") {
                                                return [
                                                        Belt_Array.concat(successes, result._0),
                                                        failures
                                                      ];
                                              } else {
                                                return [
                                                        successes,
                                                        Belt_Array.concat(failures, [result._0])
                                                      ];
                                              }
                                            }));
                                      var failureResults = match[1];
                                      var successResults = match[0];
                                      var successCount = successResults.length;
                                      var failureCount = failureResults.length;
                                      var resultText = "\n\nCompleted with " + String(successCount) + " successful " + (
                                        successCount === 1 ? "deletion" : "deletions"
                                      ) + " and " + String(failureCount) + " " + (
                                        failureCount === 1 ? "failure" : "failures"
                                      ) + ".";
                                      return Promise.resolve((
                                                  successCount > 0 ? resultText + "\n\nSuccessfully deleted: \n" + Belt_Array.joinWith(successResults, "\n", (function (altId) {
                                                            return "- " + altId;
                                                          })) : resultText
                                                ) + (
                                                  failureCount > 0 ? "\n\nFailed to delete: \n" + Belt_Array.joinWith(failureResults, "\n", (function (errorMsg) {
                                                            return "- " + errorMsg;
                                                          })) : ""
                                                ));
                                    }).then(function (results) {
                                    if (worksapceId === "HzwSGlVtE8lsAfKrnIEp") {
                                      return IntegrationHeader.publishIntegration("HzwSGlVtE8lsAfKrnIEp", "master", "1p-fQ7wdzBQ5cGhLIPjvE", undefined).then(function (_response) {
                                                  return Promise.resolve(results + "\n\nPublished main branch to AEP");
                                                });
                                    } else {
                                      return Promise.resolve(results);
                                    }
                                  }).then(function (finalResult) {
                                  Curry._1(setResult, (function (prev) {
                                          return prev + finalResult;
                                        }));
                                  Curry._1(setIsLoading, (function (param) {
                                          return false;
                                        }));
                                }), (function (error) {
                                var jsExn = Caml_js_exceptions.as_js_exn(error);
                                var errorMessage;
                                if (jsExn !== undefined) {
                                  var msg = Caml_option.valFromOption(jsExn).message;
                                  errorMessage = msg !== undefined ? msg : "Unknown error occurred";
                                } else {
                                  errorMessage = "Unknown error occurred";
                                }
                                console.error("Error in deletion process", error);
                                Curry._1(setResult, (function (param) {
                                        return "Error: " + errorMessage;
                                      }));
                                Curry._1(setIsLoading, (function (param) {
                                        return false;
                                      }));
                                return Promise.resolve();
                              }));
                        return ;
                      }
                    }),
                  size: "small"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Medium",
                  children: "Result:"
                }), React.createElement(Textarea.make, {
                  value: match$2[0],
                  onChange: (function (param) {
                      
                    }),
                  size: "Small",
                  classNameOverwrites: {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.minHeight(Css.px(100)),
                      tl: {
                        hd: Css.fontWeight(Styles.FontWeight.regular),
                        tl: /* [] */0
                      }
                    }
                  },
                  disabled: true
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(10), Css.px(15)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.paleOrange),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Tiny",
                      weight: "Semi",
                      color: Styles.Color.deepOrange,
                      children: "Warning: This operation permanently deletes Adobe Experience Platform field groups. Make sure you have proper authorization and have selected the correct field groups to delete. This action cannot be undone."
                    })));
}

var DeleteAdobeFieldGroups = {
  make: AvoAdmin$DeleteAdobeFieldGroups
};

function AvoAdmin$Menu(Props) {
  var globalSend = Props.globalSend;
  return React.createElement(Accordion.make, {
              children: null
            }, React.createElement(Accordion.Item.make, {
                  children: null
                }, React.createElement(Accordion.ItemHeader.make, {
                      title: {
                        NAME: "String",
                        VAL: "Feature flags"
                      },
                      subtitle: {
                        NAME: "String",
                        VAL: "Use this section to locally override feature flags. This will not have any effect on the workspace outside your local environment. Any overrides will be reset when the app is refreshed."
                      }
                    }), React.createElement(Accordion.ItemCollapsible.make, {
                      children: React.createElement(AvoAdmin$FeatureFlags, {})
                    })), React.createElement(Accordion.Item.make, {
                  children: null
                }, React.createElement(Accordion.ItemHeader.make, {
                      title: {
                        NAME: "String",
                        VAL: "Model generator"
                      },
                      subtitle: {
                        NAME: "String",
                        VAL: "Use this section to generate a random model with a size of your choice."
                      }
                    }), React.createElement(Accordion.ItemCollapsible.make, {
                      children: React.createElement(AvoAdmin$ModelGenerator, {})
                    })), React.createElement(Accordion.Item.make, {
                  children: null
                }, React.createElement(Accordion.ItemHeader.make, {
                      title: {
                        NAME: "String",
                        VAL: "Model Printer"
                      },
                      subtitle: {
                        NAME: "String",
                        VAL: "Prints the model as it is right now in Json form and downloads it."
                      }
                    }), React.createElement(Accordion.ItemCollapsible.make, {
                      children: React.createElement(AvoAdmin$ModelPrinter, {})
                    })), React.createElement(Accordion.Item.make, {
                  children: null
                }, React.createElement(Accordion.ItemHeader.make, {
                      title: {
                        NAME: "String",
                        VAL: "Manual Action Writer"
                      },
                      subtitle: {
                        NAME: "String",
                        VAL: "Use this to manually write JSON actions to the branch you're on."
                      }
                    }), React.createElement(Accordion.ItemCollapsible.make, {
                      children: React.createElement(AvoAdmin$ManualActionWriter, {
                            globalSend: globalSend
                          })
                    })), React.createElement(Accordion.Item.make, {
                  children: null
                }, React.createElement(Accordion.ItemHeader.make, {
                      title: {
                        NAME: "String",
                        VAL: "Time Machine"
                      },
                      subtitle: {
                        NAME: "String",
                        VAL: "Use this section to travel to the past and back to the future."
                      }
                    }), React.createElement(Accordion.ItemCollapsible.make, {
                      children: React.createElement(AvoAdmin$TimeMachine, {
                            globalSend: globalSend
                          })
                    })), React.createElement(Accordion.Item.make, {
                  children: null
                }, React.createElement(Accordion.ItemHeader.make, {
                      title: {
                        NAME: "String",
                        VAL: "Modals"
                      },
                      subtitle: {
                        NAME: "String",
                        VAL: "Opens up instances of modals in the UI"
                      }
                    }), React.createElement(Accordion.ItemCollapsible.make, {
                      children: React.createElement(AvoAdmin$OpenModals, {})
                    })), React.createElement(Accordion.Item.make, {
                  children: null
                }, React.createElement(Accordion.ItemHeader.make, {
                      title: {
                        NAME: "String",
                        VAL: "Case Converter"
                      },
                      subtitle: {
                        NAME: "String",
                        VAL: "Generates actions to convert case of event and property names"
                      }
                    }), React.createElement(Accordion.ItemCollapsible.make, {
                      children: React.createElement(AvoAdmin$CaseConverter, {})
                    })), React.createElement(Accordion.Item.make, {
                  children: null
                }, React.createElement(Accordion.ItemHeader.make, {
                      title: {
                        NAME: "String",
                        VAL: "Delete Adobe Field Groups"
                      },
                      subtitle: {
                        NAME: "String",
                        VAL: "Test functionality for deleting Adobe Experience Platform field groups"
                      }
                    }), React.createElement(Accordion.ItemCollapsible.make, {
                      children: React.createElement(AvoAdmin$DeleteAdobeFieldGroups, {})
                    })));
}

var Menu = {
  make: AvoAdmin$Menu
};

var localStorageKey = "avoSecretMenu";

function AvoAdmin(Props) {
  var globalSend = GlobalSendContext.use();
  var schemaId = WorkspaceContext.use().id;
  var shouldShow = Belt_Option.mapWithDefault(Dom_storage.getItem(localStorageKey, localStorage), false, Pervasives.bool_of_string);
  var match = React.useState(function (param) {
        return shouldShow;
      });
  var setShowSecretMenu = match[1];
  var showSecretMenu = match[0];
  var isSuperAdmin = FirebaseFetcherHooks.isSuperUser();
  var match$1 = AppFeatureFlag.use();
  var setLocalOverride = match$1.setLocalOverride;
  var localOverrides = match$1.localOverrides;
  var workspacePersistLocalStorageKey = getWorkspacePersistLocalStorageKey(schemaId);
  var activeFeatureFlagsStorageKey = getActiveFeatureFlagsStorageKey(schemaId);
  React.useEffect((function (param) {
          if (getBoolValue(workspacePersistLocalStorageKey) && shouldShow) {
            var persistedFeatureFlagsForWorkspace = getPersistedFeatureFlags(activeFeatureFlagsStorageKey);
            Belt_Array.forEach(Belt_Array.keepMap(persistedFeatureFlagsForWorkspace, (function (param) {
                        var value = param.value;
                        return Belt_Option.map(FeatureFlag.featureFlagFromJs(param.key), (function (featureFlag) {
                                      return {
                                              featureFlag: featureFlag,
                                              enabled: value
                                            };
                                    }));
                      })), setLocalOverride);
          }
          
        }), []);
  var setSecretMenuGlobalVisibility = function (showMenu) {
    Dom_storage.setItem(localStorageKey, Pervasives.string_of_bool(showMenu), localStorage);
    Curry._1(setShowSecretMenu, (function (param) {
            return showMenu;
          }));
  };
  Kbar.useRegisterActions(isSuperAdmin ? [Curry.app(CmdK.Action.t, [
                "secret_menu_visibility",
                {
                  TAG: "RootItem",
                  _0: (
                    showSecretMenu ? "Hide" : "Show"
                  ) + " Avo Secret Menu"
                },
                undefined,
                undefined,
                undefined,
                (function (param) {
                    return React.createElement("span", {
                                className: Curry._1(Css.style, {
                                      hd: Css.width(Css.px(18)),
                                      tl: {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("center"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement(Icons.AvoLogo.make, {
                                    width: 16
                                  }));
                  }),
                (function (param) {
                    setSecretMenuGlobalVisibility(!showSecretMenu);
                  }),
                undefined,
                undefined
              ])] : [], [
        isSuperAdmin,
        showSecretMenu
      ]);
  if (isSuperAdmin && showSecretMenu) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.position("absolute"),
                      tl: {
                        hd: Css.top(Css.px(16)),
                        tl: {
                          hd: Css.right(Css.px(56)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement(Mantine.Popover.make, {
                    dropdownStyles: {
                      hd: Css.transform({
                            NAME: "translateY",
                            VAL: Css.px(3)
                          }),
                      tl: /* [] */0
                    },
                    offset: 0,
                    position: "right-start",
                    children: null
                  }, React.createElement(Core.Popover.Target, {
                        children: React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.buttonReset),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.padding(Css.px(8)),
                                            tl: {
                                              hd: Css.hover({
                                                    hd: Css.filter({
                                                          hd: {
                                                            NAME: "dropShadow",
                                                            VAL: [
                                                              Css.px(0),
                                                              Css.px(0),
                                                              Css.px(4),
                                                              Styles.Color.avoPink
                                                            ]
                                                          },
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }),
                                              tl: {
                                                hd: Css.transition({
                                                      NAME: "ms",
                                                      VAL: 200.0
                                                    }, undefined, undefined, "filter"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement(Icons.Flag.make, {
                                  size: 16,
                                  color: Object.keys(localOverrides).length !== 0 ? Styles.Color.setAlpha(Styles.Color.avoPink, 0.7) : Styles.Color.light08
                                }))
                      }), React.createElement(Mantine.Popover.Dropdown.make, {
                        children: React.createElement("aside", {
                              className: Curry._1(Css.style, {
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.white),
                                      tl: {
                                        hd: Css.overflow("hidden"),
                                        tl: {
                                          hd: Css.width(Css.px(500)),
                                          tl: {
                                            hd: Css.boxShadow(Styles.Shadow.subtle),
                                            tl: {
                                              hd: Css.maxHeight({
                                                    NAME: "subtract",
                                                    VAL: [
                                                      Css.vh(100.0),
                                                      Css.px(32)
                                                    ]
                                                  }),
                                              tl: {
                                                hd: Css.overflow("scroll"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement("header", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.marginTop(Css.px(16)),
                                            tl: {
                                              hd: Css.padding(Css.px(16)),
                                              tl: {
                                                hd: Css.paddingBottom(Css.px(0)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Large",
                                      weight: "Semi",
                                      children: "Secret Administration 🤫"
                                    }), React.createElement(Spacer.make, {
                                      grow: 1.0
                                    }), React.createElement(TextButton.make, {
                                      onClick: (function (param) {
                                          Curry._1(globalSend, {
                                                TAG: "OpenModal",
                                                _0: {
                                                  NAME: "ConfirmModal",
                                                  VAL: [
                                                    "Are you sure you want to hide the menu?",
                                                    (
                                                      Object.keys(localOverrides).length !== 0 ? "You've overridden some feature flags which will persist until you refresh. " : ""
                                                    ) + "You can always re-enable the secret menu from cmd+k",
                                                    "Hide it",
                                                    (function (param) {
                                                        setSecretMenuGlobalVisibility(false);
                                                      }),
                                                    (function (param) {
                                                        
                                                      })
                                                  ]
                                                }
                                              });
                                        }),
                                      size: "Small",
                                      children: "Hide me"
                                    })), React.createElement("section", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.padding(Css.px(16)),
                                        tl: {
                                          hd: Css.marginBottom(Css.px(16)),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.width(Css.pct(80.0)),
                                            tl: /* [] */0
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Medium",
                                          color: Styles.Color.light10,
                                          children: "This part is only visible for super admins so rest assured this menu will not be available for anyone else."
                                        })), React.createElement(Spacer.make, {
                                      height: 32
                                    }), React.createElement(AvoAdmin$Menu, {
                                      globalSend: globalSend
                                    })))
                      })));
  } else {
    return null;
  }
}

var eventSortToJs = StateTypes.eventSortToJs;

var eventSortFromJs = StateTypes.eventSortFromJs;

var metricsSortToJs = StateTypes.metricsSortToJs;

var metricsSortFromJs = StateTypes.metricsSortFromJs;

var metricsSortOptions = StateTypes.metricsSortOptions;

var filterToString = StateTypes.filterToString;

var make = AvoAdmin;

export {
  eventSortToJs ,
  eventSortFromJs ,
  metricsSortToJs ,
  metricsSortFromJs ,
  metricsSortOptions ,
  filterToString ,
  PersistedFeatureFlag ,
  FeatureFlagLocalStorage ,
  FeatureFlags ,
  TimeMachine$1 as TimeMachine,
  ModelGenerator$1 as ModelGenerator,
  ModelPrinter ,
  ManualActionWriter ,
  OpenModals ,
  CaseConverter ,
  DeleteAdobeFieldGroups ,
  Menu ,
  localStorageKey ,
  make ,
}
/* Css Not a pure module */
