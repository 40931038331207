// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Nanoid from "nanoid";
import * as AvoModel from "./avoModel.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Property from "./Property.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as PlanLightning from "./PlanLightning.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as NewPropertyModal from "./NewPropertyModal.mjs";
import * as StateFilterUtils from "./StateFilterUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as NamespaceIndexStore from "./namespaceIndex/NamespaceIndexStore.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as AutoFillCasingDetail from "./AutoFillCasingDetail.mjs";
import * as PropertyAutofillInput from "./PropertyAutofillInput.mjs";
import * as AutoFillInputCreateItem from "./AutoFillInputCreateItem.mjs";

var rowHoverStyle_0 = Css.backgroundColor(Styles.Color.light02);

var rowHoverStyle = {
  hd: rowHoverStyle_0,
  tl: /* [] */0
};

var rowActiveStyle_0 = Css.backgroundColor(Styles.Color.light02);

var rowActiveStyle_1 = {
  hd: Css.color(Styles.Color.darkBlue),
  tl: {
    hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
    tl: /* [] */0
  }
};

var rowActiveStyle = {
  hd: rowActiveStyle_0,
  tl: rowActiveStyle_1
};

function getAllOptions(options) {
  return Belt_Array.concatMany(Belt_Array.mapU(options, (function (param) {
                    return param[1];
                  })));
}

function queryProperties(options, currentProperties, currentGroups, propertyType, isGroupProperty, groupTypes, propertyNamespaceIndex, query) {
  var options$1 = Belt_Array.mapU(options, (function (param) {
          return [
                  param[0],
                  Belt_Array.keepU(param[1], (function (option) {
                          if (option.NAME === "Bundle") {
                            var group = option.VAL;
                            return !Belt_Array.someU(currentGroups, (function (item) {
                                          return item.id === group.id;
                                        }));
                          }
                          var property = option.VAL;
                          return !Belt_Array.some(currentProperties, (function (prop) {
                                        if (prop.id === property.id) {
                                          return true;
                                        } else {
                                          return prop.name === property.name;
                                        }
                                      }));
                        }))
                ];
        }));
  var results = Belt_Array.map(options$1, (function (param) {
          var options = param[1];
          var filteredItems = Fuzzaldrin.filter(Belt_Array.map(Belt_Array.keep(options, (function (option) {
                          if (propertyType !== undefined) {
                            if (option.NAME === "Bundle") {
                              return "EventProperty" === propertyType;
                            } else {
                              return Caml_obj.equal(option.VAL.sendAs, propertyType);
                            }
                          } else {
                            return true;
                          }
                        })), (function (x) {
                      if (x.NAME === "Bundle") {
                        var group = x.VAL;
                        return {
                                type_: "group",
                                id: group.id,
                                name: group.name
                              };
                      }
                      var property = x.VAL;
                      return {
                              type_: "property",
                              id: property.id,
                              name: property.name
                            };
                    })), query, {
                key: "name",
                maxResults: 100
              });
          return [
                  param[0],
                  Belt_Array.map(Belt_Array.keepMap(filteredItems, (function (item) {
                              var match = item.type_;
                              switch (match) {
                                case "group" :
                                    return Belt_Array.getBy(options, (function (x) {
                                                  if (x.NAME === "Bundle") {
                                                    return item.id === x.VAL.id;
                                                  } else {
                                                    return false;
                                                  }
                                                }));
                                case "property" :
                                    return Belt_Array.getBy(options, (function (x) {
                                                  if (x.NAME === "Bundle") {
                                                    return false;
                                                  } else {
                                                    return item.id === x.VAL.id;
                                                  }
                                                }));
                                default:
                                  return ;
                              }
                            })), (function (item) {
                          return {
                                  NAME: "Item",
                                  VAL: item
                                };
                        }))
                ];
        }));
  var alreadyDefined = Belt_Option.isSome(Belt_Array.getBy(getAllOptions(options$1), (function (x) {
              if (x.NAME === "Bundle") {
                return false;
              } else {
                return Case.camel(x.VAL.name) === Case.camel(query);
              }
            })));
  var nameConflict = Belt_Array.getBy(getAllOptions(options$1), (function (x) {
          if (x.NAME === "Bundle") {
            return false;
          }
          var property = x.VAL;
          var match = property.sendAs;
          var match$1 = Case.camel(property.name) === Case.camel(query);
          if (propertyType !== undefined && typeof propertyType !== "object") {
            switch (propertyType) {
              case "SystemProperty" :
                  if (typeof match === "object") {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  if (match !== "SystemProperty") {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  break;
              case "EventProperty" :
                  if (typeof match === "object") {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  if (match !== "EventProperty") {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  break;
              case "UserProperty" :
                  if (typeof match === "object") {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  if (match !== "UserProperty") {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  break;
              
            }
          }
          if (typeof match !== "object" && match$1 && isGroupProperty) {
            return true;
          } else {
            return false;
          }
        }));
  var alreadyAdded = Belt_Array.getBy(currentProperties, (function (prop) {
          return Case.camel(prop.name) === Case.camel(query);
        }));
  var namespaceIndexResults = Belt_SetString.fromArray(Fuzzaldrin.filter(Belt_MapString.keysToArray(propertyNamespaceIndex), query, {
            maxResults: 5
          }));
  var filteredNamespaceIndex = Belt_Array.map(Belt_MapString.toArray(Belt_MapString.keep(propertyNamespaceIndex, (function (propertyName, param) {
                  return Belt_SetString.has(namespaceIndexResults, propertyName);
                }))), (function (item) {
          return {
                  NAME: "ItemFromOtherBranch",
                  VAL: item
                };
        }));
  var otherNameSpaceResults = Caml_obj.equal(filteredNamespaceIndex, []) ? [] : [[
        "Found on other branches",
        filteredNamespaceIndex
      ]];
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if (alreadyDefined || nameConflict !== undefined) {
    exit$1 = 2;
  } else if (alreadyAdded !== undefined) {
    exit = 1;
  } else {
    tmp = query !== "" && isGroupProperty ? Belt_Array.map(groupTypes, (function (param) {
              return {
                      NAME: "CreateNewWithGrouping",
                      VAL: [
                        query,
                        param.id
                      ]
                    };
            })) : (
        query !== "" ? [{
              NAME: "CreateNew",
              VAL: query
            }] : []
      );
  }
  if (exit$1 === 2) {
    if (nameConflict !== undefined) {
      tmp = [{
          NAME: "Message",
          VAL: {
            NAME: "AlreadyExists",
            VAL: [
              query,
              nameConflict
            ]
          }
        }];
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp = alreadyAdded !== undefined ? [{
          NAME: "Message",
          VAL: {
            NAME: "AlreadyAdded",
            VAL: [
              query,
              alreadyAdded
            ]
          }
        }] : [];
  }
  return Belt_Array.concatMany([
              results,
              otherNameSpaceResults,
              [[
                  undefined,
                  tmp
                ]]
            ]);
}

function PropertyInput(Props) {
  var propertyType = Props.propertyType;
  var isGroupPropertyOpt = Props.isGroupProperty;
  var currentPropertiesOpt = Props.currentProperties;
  var currentGroupsOpt = Props.currentGroups;
  var eventName = Props.eventName;
  var eventId = Props.eventId;
  var options = Props.options;
  var onSelect = Props.onSelect;
  var canCreateOpt = Props.canCreate;
  var extraCreateActions = Props.extraCreateActions;
  var onCreateStartOpt = Props.onCreateStart;
  var onCreateSuccessOpt = Props.onCreateSuccess;
  var currentFilters = Props.currentFilters;
  var getIndexOfValue = Props.getIndexOfValue;
  var renderButton = Props.renderButton;
  var keepExpandedOpt = Props.keepExpanded;
  var parent = Props.parent;
  var sendActions = Props.sendActions;
  var filters = Props.filters;
  var propertyLocation = Props.propertyLocation;
  var isGroupProperty = isGroupPropertyOpt !== undefined ? isGroupPropertyOpt : false;
  var currentProperties = currentPropertiesOpt !== undefined ? currentPropertiesOpt : /* [] */0;
  var currentGroups = currentGroupsOpt !== undefined ? currentGroupsOpt : [];
  var canCreate = canCreateOpt !== undefined ? canCreateOpt : false;
  var onCreateStart = onCreateStartOpt !== undefined ? onCreateStartOpt : (function (param) {
        
      });
  var onCreateSuccess = onCreateSuccessOpt !== undefined ? onCreateSuccessOpt : (function (_id, _name, _branch) {
        
      });
  var keepExpanded = keepExpandedOpt !== undefined ? keepExpandedOpt : false;
  var model = ModelStore.useModel();
  var modelMapped = ModelStore.Mapped.use();
  var schemaBundle = SchemaBundleContext.use();
  var mainModel = ModelStore.Mapped.useMainModel();
  var globalSend = GlobalSendContext.use();
  var addToast = Toast.useAddToast();
  var match = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match.expectedPropertyCase;
  var hasAnyValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("AnyValues");
  var hasLongValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("LongValues");
  var match$1 = GlobalStateContext.use();
  var match$2 = React.useState(function (param) {
        return true;
      });
  var setAutoCorrect = match$2[1];
  var autoCorrect = match$2[0];
  var workspace = WorkspaceContext.use();
  var pushPullBetweenBranchesAvailability = AvoLimits.PushPullBetweenBranches.computeAvailability(workspace);
  var canDisableAutoCorrect = !Curry._1(ValidationConfigV2.Rule.isEnforced, match.config.properties.case);
  var sourceFilterNames = StateFilterUtils.getSourceFilterNames__mapped(match$1.filters, modelMapped.sources);
  var currentProperties$1 = React.useMemo((function (param) {
          return Belt_List.toArray(AvoModel.resolveProperties(model, currentProperties));
        }), [
        model.properties,
        currentProperties
      ]);
  var propertyNamespaceIndex;
  if (propertyType !== undefined) {
    if (typeof propertyType !== "object") {
      switch (propertyType) {
        case "SystemProperty" :
            propertyNamespaceIndex = Curry._1(NamespaceIndexStore.SystemProperties.use, undefined).items;
            break;
        case "EventProperty" :
            propertyNamespaceIndex = Curry._1(NamespaceIndexStore.EventProperties.use, undefined).items;
            break;
        case "UserProperty" :
            propertyNamespaceIndex = Curry._1(NamespaceIndexStore.UserProperties.use, undefined).items;
            break;
        
      }
    } else {
      propertyNamespaceIndex = Curry._1(NamespaceIndexStore.GroupProperties.use, undefined).items;
    }
  } else {
    propertyNamespaceIndex = Curry._1(NamespaceIndexStore.EventProperties.use, undefined).items;
  }
  var handleAddProperty = function (item, query, sendActions) {
    if (item.NAME === "Bundle") {
      return Curry._3(onSelect, {
                  NAME: "Bundle",
                  VAL: item.VAL
                }, query, sendActions);
    }
    var property = item.VAL;
    var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup(model, property);
    Curry._3(onSelect, {
          NAME: "Property",
          VAL: property
        }, NameUtils.getCorrectCase(expectedPropertyCase, query), sendActions);
    AnalyticsRe.propertyAdded(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_Option.getWithDefault(eventId, "N/A"), Belt_Option.getWithDefault(eventName, "N/A"), propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, property.name.length, "Existing", NameUtils.getCorrectCase("Sentence", property.name).split(" ").length, query.length, "EventDetails", schemaBundle.branchId, schemaBundle.schemaId);
  };
  var tmp;
  if (propertyType !== undefined) {
    if (typeof propertyType !== "object") {
      switch (propertyType) {
        case "SystemProperty" :
            tmp = "Add System Property...";
            break;
        case "EventProperty" :
            tmp = "Add Event Property...";
            break;
        case "UserProperty" :
            tmp = "Add User Property...";
            break;
        
      }
    } else {
      tmp = "Add Group Property...";
    }
  } else {
    tmp = "Add Property...";
  }
  var partial_arg = model.groupTypes;
  var tmp$1 = {
    onSelect: (function (item, query, param) {
        handleAddProperty(item, query, sendActions);
      }),
    onCreate: (function (name, maybeGroupTypeId) {
        var sendAs = maybeGroupTypeId !== undefined ? (
            isGroupProperty ? ({
                  TAG: "GroupProperty",
                  _0: maybeGroupTypeId
                }) : (
                propertyType !== undefined ? propertyType : "EventProperty"
              )
          ) : (
            propertyType !== undefined ? propertyType : "EventProperty"
          );
        var name$1 = autoCorrect ? NameUtils.getCorrectCase(expectedPropertyCase, name) : name;
        var isValid = autoCorrect ? NewPropertyModal.isValid(name$1, expectedPropertyCase, modelMapped) : NewPropertyModal.isValid(name$1, Case.of_(name$1), modelMapped);
        if (!isValid) {
          return Pervasives.failwith("Can't create property from autofillinput with invalid name");
        }
        var propertyId = Nanoid.nanoid();
        var correctCase = NameUtils.isCorrectCase(expectedPropertyCase, name$1);
        Curry._1(onCreateStart, undefined);
        NewPropertyModal.createProperty(propertyId, parent, sendActions, sendAs, name$1, Case.of_(name$1), correctCase, addToast, schemaBundle, filters, sourceFilterNames, propertyLocation, model, onCreateSuccess, extraCreateActions, currentFilters, undefined);
      }),
    onPull: (function (param, items, query, param$1) {
        var pageName = propertyLocation === "PropertyDetails" ? "Property" : (
            propertyLocation === "EventDetails" ? "Event" : (
                propertyLocation === "PropertyGroupDetails" ? "PropertyGroup" : (
                    propertyLocation === "MetricDetails" ? "Metric" : Pervasives.failwith("Unsupported property location for pageName: " + AnalyticsRe.propertyLocationToJs(propertyLocation))
                  )
              )
          );
        var branchItemSyncOrigin = propertyLocation === "PropertyDetails" ? "PropertyDetails" : (
            propertyLocation === "EventDetails" ? "EventDetails" : (
                propertyLocation === "PropertyGroupDetails" ? "PropertyBundleDetails" : (
                    propertyLocation === "MetricDetails" ? "MetricDetails" : Pervasives.failwith("Unsupported property location for branchItemSyncOrigin: " + AnalyticsRe.propertyLocationToJs(propertyLocation))
                  )
              )
          );
        Curry._1(globalSend, {
              TAG: "OpenModal",
              _0: {
                NAME: "PullChanges",
                VAL: [
                  items,
                  (function (sendActions, resolvedCopyItems) {
                      var maybeProperty = Belt_Array.get(Belt_Array.keepMap(resolvedCopyItems, (function (item) {
                                  if (typeof item !== "object") {
                                    return ;
                                  }
                                  var variant = item.NAME;
                                  if (variant === "property" || variant === "eventProperty") {
                                    return item.VAL;
                                  }
                                  
                                })), 0);
                      if (maybeProperty !== undefined) {
                        return handleAddProperty({
                                    NAME: "Property",
                                    VAL: maybeProperty
                                  }, query, sendActions);
                      } else {
                        return Curry._1(globalSend, {
                                    TAG: "OpenModal",
                                    _0: {
                                      NAME: "AlertModal",
                                      VAL: [
                                        "Could not add property to event",
                                        "Please try adding the property again.",
                                        "Ok",
                                        (function (param) {
                                            
                                          })
                                      ]
                                    }
                                  });
                      }
                    }),
                  pageName,
                  branchItemSyncOrigin
                ]
              }
            });
      }),
    placeholder: tmp,
    search: (function (param) {
        return queryProperties(options, currentProperties$1, currentGroups, propertyType, isGroupProperty, partial_arg, propertyNamespaceIndex, param);
      }),
    renderItem: (function (item, isActive, isHoverable) {
        var variant = item.NAME;
        if (variant === "Message") {
          var message = item.VAL;
          var tmp;
          if (message.NAME === "AlreadyExists") {
            var match = message.VAL[1];
            if (match.NAME === "Bundle") {
              tmp = "\"" + match.VAL.name + "\" already exists as a property bundle.";
            } else {
              var property = match.VAL;
              var groupTypeId = property.sendAs;
              var type_;
              if (typeof groupTypeId !== "object") {
                switch (groupTypeId) {
                  case "SystemProperty" :
                      type_ = "a system";
                      break;
                  case "EventProperty" :
                      type_ = "an event";
                      break;
                  case "UserProperty" :
                      type_ = "an user";
                      break;
                  
                }
              } else {
                var groupTypeName = ModelUtils.getGroupTypeName(groupTypeId._0, model);
                type_ = groupTypeName !== undefined ? "a " + groupTypeName + " group" : "a group";
              }
              tmp = "\"" + property.name + "\" already exists as " + type_ + " property.";
            }
          } else {
            var match$1 = message.VAL;
            var property$1 = match$1[1];
            if (match$1[0] === property$1.name) {
              var groupTypeId$1 = property$1.sendAs;
              var type_$1;
              if (typeof groupTypeId$1 !== "object") {
                switch (groupTypeId$1) {
                  case "SystemProperty" :
                      type_$1 = "system";
                      break;
                  case "EventProperty" :
                      type_$1 = "event";
                      break;
                  case "UserProperty" :
                      type_$1 = "user";
                      break;
                  
                }
              } else {
                var groupTypeName$1 = ModelUtils.getGroupTypeName(groupTypeId$1._0, model);
                type_$1 = groupTypeName$1 !== undefined ? groupTypeName$1 + " group" : "group";
              }
              tmp = "The " + type_$1 + " property \"" + property$1.name + "\" has already been added.";
            } else {
              var groupTypeId$2 = property$1.sendAs;
              var type_$2;
              if (typeof groupTypeId$2 !== "object") {
                switch (groupTypeId$2) {
                  case "SystemProperty" :
                      type_$2 = "system";
                      break;
                  case "EventProperty" :
                      type_$2 = "event";
                      break;
                  case "UserProperty" :
                      type_$2 = "user";
                      break;
                  
                }
              } else {
                var groupTypeName$2 = ModelUtils.getGroupTypeName(groupTypeId$2._0, model);
                type_$2 = groupTypeName$2 !== undefined ? groupTypeName$2 + " group" : "group";
              }
              tmp = "The " + type_$2 + " property \"" + property$1.name + "\" has already been added.";
            }
          }
          return React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding(Css.px(16)),
                            tl: /* [] */0
                          })
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          children: tmp
                        }));
        }
        if (variant === "CreateNew") {
          if (canCreate) {
            return React.createElement(AutoFillInputCreateItem.make, {
                        name: item.VAL,
                        autoCorrect: autoCorrect,
                        correctCasing: expectedPropertyCase,
                        isActive: isActive,
                        isHoverable: isHoverable
                      });
          } else {
            return null;
          }
        }
        if (variant === "CreateNewWithGrouping") {
          if (!canCreate) {
            return null;
          }
          var match$2 = item.VAL;
          return React.createElement("div", {
                      className: Curry._1(Css.merge, {
                            hd: Curry._1(Css.style, Styles.button),
                            tl: {
                              hd: Curry._1(Css.style, Belt_List.concat(isActive ? rowActiveStyle : (
                                          isHoverable ? ({
                                                hd: Css.hover(rowHoverStyle),
                                                tl: /* [] */0
                                              }) : /* [] */0
                                        ), {
                                        hd: Css.padding(Css.px(16)),
                                        tl: /* [] */0
                                      })),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Tiny",
                          weight: "Regular",
                          color: Styles.Color.light12,
                          children: "\"" + match$2[0] + "\" not found"
                        }), React.createElement(Spacer.make, {
                          height: 2
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          children: "Create New " + Belt_Option.getWithDefault(ModelUtils.getGroupTypeName(match$2[1], model), "") + " Property"
                        }));
        }
        if (variant === "Item") {
          var match$3 = item.VAL;
          if (match$3.NAME === "Bundle") {
            var group = match$3.VAL;
            return React.createElement("div", {
                        className: Curry._1(Css.style, Belt_List.concat({
                                  hd: Css.padding(Css.px(12)),
                                  tl: {
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.border(Css.px(1), "solid", Styles.Color.light02),
                                      tl: {
                                        hd: Css.margin2(Css.px(4), Css.px(8)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }, isActive ? rowActiveStyle : (
                                    isHoverable ? ({
                                          hd: Css.hover(rowHoverStyle),
                                          tl: /* [] */0
                                        }) : /* [] */0
                                  )))
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("row"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.flexWrap("wrap"),
                                        tl: {
                                          hd: Css.color(isActive ? Styles.Color.darkBlue : Styles.Color.light12),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                children: group.name
                              }), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement($$Text.make, {
                                size: "Tiny",
                                weight: "Semi",
                                color: isActive ? Styles.Color.darkBlue : Styles.Color.light08,
                                children: "(" + String(Belt_List.length(group.properties)) + " Properties)"
                              })), group.description === "" ? null : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                  height: 2
                                }), React.createElement($$Text.make, {
                                  size: "Small",
                                  singleLine: true,
                                  children: group.description
                                })));
          }
          var item$1 = match$3.VAL;
          return React.createElement("div", {
                      className: Curry._1(Css.style, Belt_List.concat({
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.flexDirection("row"),
                                  tl: {
                                    hd: Css.alignItems("baseline"),
                                    tl: {
                                      hd: Css.flexWrap("wrap"),
                                      tl: {
                                        hd: Css.padding(Css.px(16)),
                                        tl: {
                                          hd: Css.border(Css.px(1), "solid", "transparent"),
                                          tl: {
                                            hd: Css.borderBottom(Css.px(1), "solid", isActive ? Styles.Color.darkBlue : Styles.Color.light04),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }, isActive ? rowActiveStyle : (
                                  isHoverable ? ({
                                        hd: Css.hover(rowHoverStyle),
                                        tl: /* [] */0
                                      }) : /* [] */0
                                )))
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          children: item$1.name
                        }), Belt_Option.mapWithDefault(item$1.uniqueName, null, (function (uniqueName) {
                            return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                            width: 4
                                          }), React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            color: Styles.Color.light08,
                                            children: "(" + uniqueName + ")"
                                          }));
                          })), React.createElement(Spacer.make, {
                          width: 4
                        }));
        }
        var match$4 = item.VAL;
        var branchIds = Belt_SetString.fromArray(Belt_Array.map(match$4[1], (function (param) {
                    return param.branchId;
                  })));
        var match$5 = Belt_SetString.toArray(branchIds);
        var tmp$1;
        if (match$5.length !== 1) {
          tmp$1 = AvoUtils.plural("other branches", undefined, Belt_SetString.size(branchIds), "other branch");
        } else {
          var branchId = match$5[0];
          tmp$1 = NamedBranch.getName(NamedBranch.fromUnnamed__mapped(mainModel, {
                    NAME: "Branch",
                    VAL: branchId
                  }));
        }
        return React.createElement("div", {
                    className: Curry._1(Css.merge, {
                          hd: Curry._1(Css.style, {
                                hd: Css.padding(Css.px(16)),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", "transparent"),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), Css.solid, isActive ? Styles.Color.darkBlue : Styles.Color.light04),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          tl: {
                            hd: Curry._1(Css.style, isActive ? rowActiveStyle : (
                                    isHoverable ? ({
                                          hd: Css.hover(rowHoverStyle),
                                          tl: /* [] */0
                                        }) : /* [] */0
                                  )),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        children: match$4[0]
                      }), React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement($$Text.make, {
                        element: "Span",
                        size: "Tiny",
                        color: Styles.Color.light12,
                        children: React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, "Pull from ", React.createElement(Spacer.make, {
                                  width: 4
                                }), React.createElement(Icon.make, {
                                  type_: "branch",
                                  size: {
                                    NAME: "int",
                                    VAL: 12
                                  },
                                  color: Styles.Color.light11
                                }), React.createElement(Spacer.make, {
                                  width: 2
                                }), React.createElement($$Text.make, {
                                  element: "Span",
                                  weight: "Semi",
                                  color: Styles.Color.light11,
                                  children: tmp$1
                                }), pushPullBetweenBranchesAvailability === "Available" ? null : (
                                pushPullBetweenBranchesAvailability === "AvailableDuringTrial" ? React.createElement("span", {
                                        className: Curry._1(Css.style, {
                                              hd: Css_Legacy_Core.SVG.fill("transparent"),
                                              tl: {
                                                hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light11),
                                                tl: {
                                                  hd: Css.marginLeft(Css.px(4)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, React.createElement(PlanLightning.make, {
                                            tooltipText: "Pull properties from other branches is available during your trial"
                                          })) : React.createElement("span", {
                                        className: Curry._1(Css.style, {
                                              hd: Css_Legacy_Core.SVG.fill("transparent"),
                                              tl: {
                                                hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light11),
                                                tl: {
                                                  hd: Css.marginLeft(Css.px(4)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, React.createElement(PlanLightning.make, {
                                            tooltipText: "Upgrade your plan to pull properties from other branches"
                                          }))
                              ))
                      }));
      }),
    renderDetails: (function (item) {
        var variant = item.NAME;
        var tmp;
        if (variant === "Message") {
          tmp = item.VAL.NAME === "AlreadyExists" ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "Why can't different types of properties have same or similar names in Avo?"
                    }), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light12,
                      children: "In some analytics platforms and databases it can be very confusing to have different types of properties with the same or similar name. Therefor Avo is preventing you from creating multiple properties with same or similar names."
                    }), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light12,
                      children: "If you truly need to have properties with the same name, you can use the name-mapping feature to map two properties to the same name."
                    })) : null;
        } else if (variant === "CreateNew") {
          var label = item.VAL;
          tmp = NameUtils.isCorrectCase(expectedPropertyCase, label) ? null : React.createElement(AutoFillCasingDetail.make, {
                  autoCorrect: autoCorrect,
                  toggleAutoCorrect: (function (param) {
                      Curry._1(setAutoCorrect, (function (v) {
                              return !v;
                            }));
                    }),
                  canDisableAutoCorrect: canDisableAutoCorrect,
                  expectedCase: expectedPropertyCase,
                  name: label,
                  kind: "Property"
                });
        } else if (variant === "CreateNewWithGrouping") {
          var match = item.VAL;
          var groupTypeId = match[1];
          tmp = React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: {
                            hd: Css.height(Css.pct(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    })
              }, React.createElement("div", undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width("fitContent"),
                              tl: /* [] */0
                            })
                      }, React.createElement(Property.GroupTypePill.make, {
                            groupTypeName: Belt_Option.getWithDefault(ModelUtils.getGroupTypeName(groupTypeId, model), groupTypeId)
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.regular),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.small),
                                tl: {
                                  hd: Css.padding2(Css.px(5), Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, "Create New Property \"" + match[0] + "\"")));
        } else if (variant === "Item") {
          var match$1 = item.VAL;
          if (match$1.NAME === "Bundle") {
            tmp = null;
          } else {
            var item$1 = match$1.VAL;
            var usageCount = Belt_List.length(ModelUtils.eventsSendingProperty(model, undefined, item$1.id));
            tmp = React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: {
                              hd: Css.height(Css.pct(100)),
                              tl: {
                                hd: Css.color(Styles.Color.light12),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", undefined, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          children: item$1.name
                        }), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Regular",
                          children: PropertyUtils.printPropertyType(item$1, undefined, model, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, undefined, undefined)
                        }), React.createElement(Spacer.make, {
                          height: 8
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Regular",
                          color: Styles.Color.light10,
                          children: item$1.description
                        })), React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Regular",
                      color: Styles.Color.light11,
                      children: usageCount !== 0 ? (
                          usageCount !== 1 ? "Used in " + String(usageCount) + " Events" : "Used in 1 Event"
                        ) : "Never used"
                    }));
          }
        } else {
          var match$2 = item.VAL;
          tmp = React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: {
                            hd: Css.height(Css.pct(100)),
                            tl: {
                              hd: Css.color(Styles.Color.light12),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    })
              }, React.createElement("div", undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Semi",
                        children: match$2[0]
                      }), React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "This property does not exist on this branch."
                      })), React.createElement("div", undefined, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Regular",
                        color: Styles.Color.light11,
                        children: "This property was found on:"
                      }), Belt_Array.map(match$2[1], (function (item) {
                          var branchName = NamedBranch.getName(NamedBranch.fromUnnamed__mapped(mainModel, {
                                    NAME: "Branch",
                                    VAL: item.branchId
                                  }));
                          return React.createElement("div", {
                                      key: "found-on-branch-" + item.branchId,
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.paddingTop(Css.px(4)),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement(Icon.make, {
                                          type_: "branch",
                                          size: {
                                            NAME: "int",
                                            VAL: 12
                                          },
                                          color: Styles.Color.light11
                                        }), React.createElement(Spacer.make, {
                                          width: 4
                                        }), React.createElement($$Text.make, {
                                          size: "Tiny",
                                          weight: "Semi",
                                          color: Styles.Color.light11,
                                          children: branchName
                                        }));
                        }))));
        }
        return React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding(Css.px(15)),
                          tl: {
                            hd: Css.height(Css.pct(100)),
                            tl: /* [] */0
                          }
                        })
                  }, tmp);
      }),
    refreshKey: [
      currentProperties$1,
      currentGroups,
      getAllOptions(options)
    ],
    keepExpanded: keepExpanded,
    onClose: (function (param) {
        Curry._1(setAutoCorrect, (function (param) {
                return true;
              }));
      })
  };
  if (getIndexOfValue !== undefined) {
    tmp$1.getIndexOfValue = Caml_option.valFromOption(getIndexOfValue);
  }
  if (renderButton !== undefined) {
    tmp$1.renderButton = Caml_option.valFromOption(renderButton);
  }
  return React.createElement(PropertyAutofillInput.make, tmp$1);
}

var make = PropertyInput;

export {
  rowHoverStyle ,
  rowActiveStyle ,
  getAllOptions ,
  queryProperties ,
  make ,
}
/* rowHoverStyle Not a pure module */
