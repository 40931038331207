// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as React$1 from "motion/react";

var defaultStyles = Curry._1(Css.style, {
      hd: Css.boxShadow(Styles.Shadow.subtle),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: /* [] */0
      }
    });

function PortalMenuAnimator(Props) {
  var key_ = Props.key_;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : defaultStyles;
  return React.createElement(React$1.motion.div, {
              animate: "open",
              transition: {
                duration: 0.1
              },
              initial: "closed",
              exit: "closed",
              variants: {
                open: {
                  opacity: 1
                },
                closed: {
                  opacity: 0
                }
              },
              className: className,
              key: key_,
              children: children
            });
}

var make = PortalMenuAnimator;

export {
  defaultStyles ,
  make ,
}
/* defaultStyles Not a pure module */
