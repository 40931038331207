// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Modal from "./Modal.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PlatformIcon from "./PlatformIcon.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as ImplementationStatusContext from "./ImplementationStatusContext.mjs";

var content = Curry._1(Css.style, {
      hd: Css.padding(Css.px(8)),
      tl: {
        hd: Css.paddingTop(Css.px(16)),
        tl: {
          hd: Css.paddingBottom(Css.px(16)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.flexDirection("column"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var sourceButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding(Css.px(8)),
              tl: {
                hd: Css.borderRadius(Css.px(5)),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$default
                      }, undefined, undefined, "background-color"),
                  tl: {
                    hd: Css.color(Styles.Color.light11),
                    tl: {
                      hd: Css.enabled({
                            hd: Css.hover({
                                  hd: Css.backgroundColor(Styles.Color.light04),
                                  tl: {
                                    hd: Css.color(Styles.Color.light12),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.disabled({
                              hd: Css.cursor("default"),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("row"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var newIcon = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.width(Css.px(32)),
        tl: {
          hd: Css.height(Css.px(32)),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Style = {
  content: content,
  sourceButton: sourceButton,
  newIcon: newIcon
};

function SourceSetupModal(Props) {
  var onClose = Props.onClose;
  var model = Props.model;
  var tabAfterChoosing = Props.tabAfterChoosing;
  var fromSchemaRoute = Props.fromSchemaRoute;
  var globalSend = GlobalSendContext.use();
  var match = React.useContext(ImplementationStatusContext.context).codegen;
  var implementationSources = match[2];
  var sources = Belt_List.toArray(Belt_List.keep(model.sources, (function (source) {
              return !Belt_Option.mapWithDefault(Js_dict.get(implementationSources, source.id), false, (function (invocations) {
                            return !Belt_MapString.isEmpty(invocations);
                          }));
            })));
  var handleContinue = function (choice, param) {
    Curry._1(onClose, undefined);
    if (typeof choice === "object") {
      return Router.Schema.pushDrawerItem(undefined, undefined, {
                  NAME: "source",
                  VAL: [
                    choice.VAL,
                    tabAfterChoosing
                  ]
                });
    } else {
      return Curry._1(globalSend, {
                  TAG: "OpenModal",
                  _0: {
                    NAME: "CreateSource",
                    VAL: tabAfterChoosing
                  }
                });
    }
  };
  var tmp;
  var exit = 0;
  var schemaRoute;
  if (fromSchemaRoute !== undefined) {
    if (typeof fromSchemaRoute === "object") {
      if (fromSchemaRoute.NAME === "inspector") {
        schemaRoute = fromSchemaRoute;
        exit = 1;
      } else {
        tmp = undefined;
      }
    } else if (fromSchemaRoute === "implement") {
      schemaRoute = fromSchemaRoute;
      exit = 1;
    } else {
      tmp = undefined;
    }
  } else {
    tmp = undefined;
  }
  if (exit === 1) {
    tmp = Caml_option.some(React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingTop(Css.px(4)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(6)),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  children: "Select or create a source to connect to " + (
                    typeof schemaRoute === "object" ? "Inspector" : "Codegen"
                  )
                })));
  }
  return React.createElement(Modal.make, {
              title: "Select data source",
              description: tmp,
              onClose: onClose,
              children: React.createElement("div", {
                    className: content
                  }, Belt_Array.map(sources, (function (source) {
                          var id = source.id;
                          var partial_arg = {
                            NAME: "source",
                            VAL: id
                          };
                          return React.createElement("button", {
                                      key: id,
                                      className: sourceButton,
                                      onClick: (function (param) {
                                          return handleContinue(partial_arg, param);
                                        })
                                    }, React.createElement(PlatformIcon.make, {
                                          platform: source.platform,
                                          sizeWithBorder: 32,
                                          backgroundColor: Styles.Color.white,
                                          borderColor: Styles.Color.light07
                                        }), React.createElement(Spacer.make, {
                                          width: 12
                                        }), React.createElement($$Text.make, {
                                          size: "Medium",
                                          lines: 1,
                                          children: Belt_Option.getWithDefault(source.name, "Untitled Source")
                                        }));
                        })), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement("button", {
                        className: sourceButton,
                        onClick: (function (param) {
                            return handleContinue("new", param);
                          })
                      }, React.createElement("div", {
                            className: newIcon
                          }, React.createElement(Icon.make, {
                                type_: "plus",
                                size: "large",
                                color: Styles.Color.deepBlue
                              })), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Medium",
                            color: Styles.Color.deepBlue,
                            children: "Create new source"
                          })))
            });
}

var make = SourceSetupModal;

export {
  Style ,
  make ,
}
/* content Not a pure module */
