// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";

var contentContainerStyle = Curry._1(Css.style, {
      hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: /* [] */0
      }
    });

var allSourcesRowStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding2(Css.px(10), Css.px(20)),
            tl: {
              hd: Css.fontSize(Styles.FontSize.small),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var sourceRowStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding2(Css.px(10), Css.px(20)),
            tl: {
              hd: Css.fontSize(Styles.FontSize.small),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.regular),
                tl: {
                  hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function PropertyModalSources(Props) {
  var model = Props.model;
  var property = Props.property;
  var sendActions = Props.sendActions;
  var match = React.useState(function (param) {
        return ModelUtils.propertyExcludedSources(property, model) !== /* [] */0;
      });
  var setIsListExpanded = match[1];
  var isListExpanded = match[0];
  React.useEffect((function (param) {
          if (ModelUtils.propertyExcludedSources(property, model) === /* [] */0) {
            Curry._1(setIsListExpanded, (function (param) {
                    return false;
                  }));
          }
          
        }), [ModelUtils.propertyExcludedSources(property, model)]);
  var propertySources = Belt_List.keepMap(Belt_List.reverse(BeltListExtensions.dedupeString(ModelUtils.getIncludedSourceIds(BeltListExtensions.flatMapU(ModelUtils.eventsSendingProperty(model, undefined, property.id), (function ($$event) {
                          return $$event.includeSources;
                        }))))), (function (sourceId) {
          return ModelUtils.getSourceById(sourceId, model);
        }));
  var context = {
    propertyId: property.id,
    propertyQuery: property.id
  };
  var match$1 = ModelUtils.propertyExcludedSources(property, model);
  var tmp;
  var exit = 0;
  if (isListExpanded || match$1) {
    exit = 1;
  } else {
    tmp = null;
  }
  if (exit === 1) {
    tmp = Belt_List.toArray(Belt_List.mapU(propertySources, (function (source) {
                return React.createElement("div", {
                            className: sourceRowStyle
                          }, React.createElement("div", undefined, AvoConfig.getSourceName(source)), React.createElement("input", {
                                checked: !Belt_List.someU(match$1, (function (sourceId) {
                                        return sourceId === source.id;
                                      })),
                                type: "checkbox",
                                onChange: (function ($$event) {
                                    var isActive = $$event.target.checked;
                                    Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          [[
                                              {
                                                NAME: "TogglePropertyExcludedSource",
                                                VAL: [
                                                  property.id,
                                                  source.id,
                                                  isActive
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                                  })
                              }));
              })));
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: contentContainerStyle
                }, React.createElement("div", {
                      className: allSourcesRowStyle
                    }, React.createElement("div", undefined, "Property sent to all sources"), React.createElement("input", {
                          checked: !isListExpanded,
                          type: "checkbox",
                          onChange: (function ($$event) {
                              var isActive = $$event.target.checked;
                              var match = ModelUtils.propertyExcludedSources(property, model);
                              if (isActive && match) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "ClearPropertyExcludedSources",
                                            VAL: property.id
                                          },
                                          context
                                        ]]
                                    ]);
                              }
                              Curry._1(setIsListExpanded, (function (param) {
                                      return !isActive;
                                    }));
                            })
                        })), tmp));
}

var make = PropertyModalSources;

export {
  contentContainerStyle ,
  allSourcesRowStyle ,
  sourceRowStyle ,
  make ,
}
/* contentContainerStyle Not a pure module */
