// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as TagsSelect from "./TagsSelect.mjs";
import * as ActionUtils from "../../model/src/ActionUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as ProfilePhoto from "./ProfilePhoto.mjs";
import * as App from "firebase/app";
import * as PlanLightning from "./PlanLightning.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as MembersInviteFormUtils from "./MembersInviteFormUtils.mjs";

function removeMember(schemaId, member, memberName, memberEmail, viewerId, schemaBundle, globalSend) {
  return Curry._1(globalSend, {
              TAG: "OpenModal",
              _0: {
                NAME: "AreYouSureModal",
                VAL: [
                  "Remove Member?",
                  "Are you sure you want to remove " + memberName + " from this workspace?",
                  "Remove Member",
                  (function (param) {
                      var memberId = member.id;
                      var role = member.role;
                      var aclRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("acls").doc(memberId);
                      var memberSchemaRef = Firebase.app().firestore().collection("users").doc(memberId).collection("schemas").doc(schemaId);
                      var removeUserBatch = Firebase.app().firestore().batch();
                      removeUserBatch.delete(aclRef);
                      removeUserBatch.delete(memberSchemaRef);
                      var actionRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("actions").doc();
                      var action_1 = [
                        memberId,
                        memberEmail,
                        role
                      ];
                      var action = {
                        NAME: "RemoveMember",
                        VAL: action_1
                      };
                      removeUserBatch.set(actionRef, {
                            id: actionRef.id,
                            contentsJson: Printer.printAction(action),
                            createdAt: App.default.firestore.FieldValue.serverTimestamp(),
                            createdBy: viewerId,
                            branchId: "master",
                            audit: true,
                            actionImpactLevel: ActionUtils.getActionImpactLevelFromAction(action),
                            order: 0
                          });
                      removeUserBatch.commit().then(function (param) {
                            AnalyticsRe.memberRemoved(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_Option.getExn(AnalyticsRe.roleFromJs(member.role)), memberId, schemaBundle.branchId, schemaBundle.schemaId);
                          });
                    }),
                  (function (param) {
                      
                    })
                ]
              }
            });
}

function updateMemberRole(schemaId, member, newRole, memberEmail, viewerId, schemaBundle) {
  var memberId = member.id;
  var currentRole = member.role;
  var batch = Firebase.app().firestore().batch();
  var aclRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("acls").doc(memberId);
  batch.set(aclRef, {
        role: newRole
      }, {"merge": true});
  var actionRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("actions").doc();
  var action_1 = [
    memberId,
    memberEmail,
    currentRole,
    newRole
  ];
  var action = {
    NAME: "UpdateMemberRole",
    VAL: action_1
  };
  batch.set(actionRef, {
        id: actionRef.id,
        contentsJson: Printer.printAction(action),
        createdAt: App.default.firestore.FieldValue.serverTimestamp(),
        createdBy: viewerId,
        branchId: "master",
        audit: true,
        actionImpactLevel: ActionUtils.getActionImpactLevelFromAction(action),
        order: 0
      });
  batch.commit().then(function (param) {
        var match = AnalyticsRe.fromRoleFromJs(currentRole);
        var match$1 = AnalyticsRe.toRoleFromJs(newRole);
        if (match !== undefined && match$1 !== undefined) {
          AnalyticsRe.memberRoleChanged(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, match, memberId, match$1, schemaBundle.branchId, schemaBundle.schemaId);
        }
        return Promise.resolve();
      });
}

function updateUserFilter(schemaId, member, filter) {
  var userId = member.id;
  var aclRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("acls").doc(userId);
  var tmp = {
    id: member.id,
    role: member.role,
    type_: member.type_,
    createdAt: App.default.firestore.FieldValue.serverTimestamp()
  };
  var tmp$1 = Caml_option.undefined_to_opt(member.jobFunction);
  if (tmp$1 !== undefined) {
    tmp.jobFunction = Caml_option.valFromOption(tmp$1);
  }
  if (filter !== undefined) {
    tmp.filter = Caml_option.valFromOption(filter);
  }
  aclRef.set(tmp);
}

function MembersRow(Props) {
  var model = Props.model;
  var member = Props.member;
  var workspaceLimits = Props.workspaceLimits;
  var fetchedUser = Props.user;
  var viewerRole = Props.viewerRole;
  var viewerId = Props.viewerId;
  var billingStats = Props.billingStats;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = Workspace.User.getUsedSlots(workspace);
  var userCount = match[0];
  var schemaBundle = SchemaBundleContext.use();
  var commentOnlyFreeSlots = Workspace.User.commentOnlyFreeSlots(workspace);
  var globalSend = GlobalSendContext.use();
  var hasCollaboration = AvoLimits.Collaboration.isAvailable(workspace);
  var hasTags = ModelUtils.getAllTags(model.events) !== /* [] */0;
  var match$1 = AvoLimits.Editors.computeAvailability(workspace);
  var editorsAvailable = match$1 === "AvailableDuringTrial" || match$1 === "Available";
  var hasCodegenAccessRole = AppFeatureFlag.useFeatureFlag("CodegenAccessRole");
  if (typeof fetchedUser !== "object") {
    if (fetchedUser === "NotFound") {
      return "Could not load user.";
    } else {
      return React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(73)),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                            tl: /* [] */0
                          }
                        }
                      })
                });
    }
  }
  var user = fetchedUser.VAL;
  var match$2 = AvoLimits.User.entityStatus(workspaceLimits, user.id);
  var userAvailableOnPlan = match$2[1];
  var userAvailability = match$2[0];
  var disabled = userAvailability === "Unavailable";
  var isAvoTeamMember = Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(user.email), false, (function (email) {
          return email.endsWith("@avo.sh");
        }));
  var match$3 = user.name;
  var match$4 = user.email;
  var tmp;
  if (viewerRole === "Admin") {
    var availableRoles = RoleUtils.availableRoles(hasCodegenAccessRole, workspace);
    var currentRole = RoleUtils.fromString(member.role);
    var availableRolesWithCurrentRole = currentRole !== undefined && !Belt_Array.some(availableRoles, (function (availableRole) {
            return availableRole === currentRole;
          })) ? Belt_Array.concat(availableRoles, [currentRole]) : availableRoles;
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Select.make, {
              disabled: member.id === viewerId,
              onSelect: (function (value) {
                  var role = Models.Role.tFromJs(value);
                  if (role === undefined) {
                    return Pervasives.failwith("invalid role option");
                  }
                  var adminAndEditorAvailability = AvoLimits.User.createActionStatus(AvoLimits.User.computeEntityNumericLimits(workspace, model));
                  var addCommenterStatus = AvoLimits.Commenters.createActionStatus(AvoLimits.Commenters.computeEntityNumericLimits(workspace, model));
                  Belt_Option.forEach(Caml_option.nullable_to_opt(user.email), (function (email) {
                          MembersInviteFormUtils.handleSubmit(workspace, adminAndEditorAvailability, addCommenterStatus, userCount, role, billingStats, globalSend, email, (function (param) {
                                  updateMemberRole(workspace.id, member, value, email, viewerId, schemaBundle);
                                }));
                        }));
                }),
              options: Belt_Array.map(availableRolesWithCurrentRole, (function (role) {
                      var optionIsSelected = member.role === Models.Role.tToJs(role);
                      var match = AvoLimits.User.createActionStatus(workspaceLimits);
                      var userMayHaveSeatOnPlan = match === "AvailableDuringTrial" || match === "Available";
                      var seatTakenAnyway = AvoLimits.User.entityStatus(workspaceLimits, user.id)[0] === "Available";
                      var seatAvailableAsViewer = seatTakenAnyway || !workspace.plan.countsViewersAsUsers;
                      var match$1 = AvoLimits.Editors.computeAvailability(workspace);
                      var nextSeatRequiresExtraBilling = !(match$1 === "AvailableDuringTrial" || match$1 === "Available");
                      var match$2 = AnalyticsRe.roleFromJs(member.role);
                      var isAvailable;
                      if (optionIsSelected || match$2 === undefined) {
                        isAvailable = true;
                      } else {
                        var exit = 0;
                        if (role === "BillingOnly") {
                          isAvailable = true;
                        } else if (role === "Viewer") {
                          isAvailable = seatAvailableAsViewer;
                        } else if (role === "CommentOnly") {
                          if (hasCollaboration) {
                            if (commentOnlyFreeSlots > 0) {
                              isAvailable = true;
                            } else {
                              exit = 1;
                            }
                          } else {
                            isAvailable = false;
                          }
                        } else {
                          exit = 1;
                        }
                        if (exit === 1) {
                          isAvailable = match$2 === "CommentOnly" || match$2 === "Viewer" ? userMayHaveSeatOnPlan && !nextSeatRequiresExtraBilling : (
                              role === "Editor" && !editorsAvailable ? false : seatTakenAnyway
                            );
                        }
                        
                      }
                      return [
                              {
                                NAME: "Label",
                                VAL: RoleUtils.getLabel(role, AvoLimits.ViewersCanComment.isAvailable(workspace)) + (
                                  isAvailable ? "" : " ⚡️"
                                )
                              },
                              Models.Role.tToJs(role)
                            ];
                    })),
              value: member.role
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.px(8)),
                    tl: /* [] */0
                  })
            }), member.id === viewerId && !isAvoTeamMember ? React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.minWidth(Css.px(26)),
                      tl: /* [] */0
                    })
              }, React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light10,
                    children: "You"
                  })) : React.createElement(ContextMenu.make, {
                options: [{
                    NAME: "Option",
                    VAL: {
                      label: "Remove Member",
                      onClick: (function (param) {
                          Belt_Option.forEach(Caml_option.nullable_to_opt(user.email), (function (email) {
                                  removeMember(workspace.id, member, Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), email), email, viewerId, schemaBundle, globalSend);
                                }));
                        })
                    }
                  }]
              }));
  } else {
    var match$5 = member.id;
    var exit = 0;
    if (isAvoTeamMember && match$5 === viewerId) {
      tmp = React.createElement(ContextMenu.make, {
            options: [{
                NAME: "Option",
                VAL: {
                  label: "Remove Member",
                  onClick: (function (param) {
                      Belt_Option.forEach(Caml_option.nullable_to_opt(user.email), (function (email) {
                              removeMember(workspace.id, member, Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), email), email, viewerId, schemaBundle, globalSend);
                            }));
                    })
                }
              }]
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = match$5 === viewerId ? React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.light10,
              children: member.role + " (You)"
            }) : React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.light10,
              children: member.role
            });
    }
    
  }
  var match$6 = Models.Role.tFromJs(member.role);
  var tmp$1;
  if (match$6 !== undefined && match$6 === "CommentOnly" && (hasTags || Belt_Option.isSome(Caml_option.undefined_to_opt(member.filter)))) {
    var filter = member.filter;
    tmp$1 = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.small),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: {
                        hd: Css.fontWeight(Styles.FontWeight.semi),
                        tl: {
                          hd: Css.paddingTop(Css.px(2)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(48)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              })
        }, React.createElement("input", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(0), Css.px(15)),
                    tl: {
                      hd: Css.marginRight(Css.px(10)),
                      tl: /* [] */0
                    }
                  }),
              checked: Belt_Option.isSome(Caml_option.undefined_to_opt(member.filter)),
              type: "checkbox",
              onChange: (function (domEvent) {
                  var isChecked = domEvent.target.checked;
                  updateUserFilter(workspace.id, member, isChecked ? ({
                            tags: []
                          }) : undefined);
                })
            }), filter !== undefined ? React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    })
              }, "Limited to tags:", React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginLeft(Css.px(5)),
                          tl: /* [] */0
                        })
                  }, React.createElement(TagsSelect.make, {
                        events: model.events,
                        selectedTags: Belt_List.fromArray(filter.tags),
                        onSelect: (function (tag) {
                            var filter = member.filter;
                            var nextFilters = filter !== undefined ? ({
                                  tags: Belt_Array.concat(filter.tags, [tag])
                                }) : ({
                                  tags: [tag]
                                });
                            updateUserFilter(workspace.id, member, nextFilters);
                          }),
                        onRemove: (function (tag) {
                            var filter = member.filter;
                            var nextFilters = filter !== undefined ? ({
                                  tags: Belt_Array.keep(filter.tags, (function (item) {
                                          return item !== tag;
                                        }))
                                }) : undefined;
                            updateUserFilter(workspace.id, member, nextFilters);
                          })
                      }))) : "Limit to tags");
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingTop(Css.px(16)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(16)),
                      tl: {
                        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: {
                              hd: Css.flexGrow(1.0),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.opacity(disabled ? 0.4 : 1),
                            tl: /* [] */0
                          })
                    }, React.createElement(ProfilePhoto.make, {
                          user: user,
                          size: 40
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("column"),
                              tl: {
                                hd: Css.marginLeft(Css.px(12)),
                                tl: {
                                  hd: Css.flexGrow(1.0),
                                  tl: {
                                    hd: Css.opacity(disabled ? 0.4 : 1),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("baseline"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              children: AvoConfig.getUserDisplayName(user)
                            }), isAvoTeamMember ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    width: 6
                                  }), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginTop(Css.px(-3)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(Icons.AvoLogo.make, {
                                        width: 22,
                                        fill: Styles.Color.light12
                                      }))) : null, viewerId === user.id && viewerRole !== "Admin" ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    width: 6
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    color: Styles.Color.light10,
                                    children: "(You)"
                                  })) : null), !(match$3 == null) && !(match$4 == null) ? React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light08,
                            children: match$4
                          }) : null), userAvailability === "Available" ? null : (
                    userAvailability === "AvailableDuringTrial" ? React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.margin2(Css.px(0), Css.px(10)),
                                  tl: {
                                    hd: Css_Legacy_Core.SVG.fill("transparent"),
                                    tl: {
                                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light08),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(PlanLightning.make, {
                                tooltipText: "Available during trial and on the " + (Belt_Option.mapWithDefault(userAvailableOnPlan, "", (function (plan) {
                                          return Workspace.printPlanName(plan.name);
                                        })) + " plan")
                              })) : React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.margin2(Css.px(0), Css.px(10)),
                                  tl: {
                                    hd: Css_Legacy_Core.SVG.fill("transparent"),
                                    tl: {
                                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light08),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(PlanLightning.make, {
                                tooltipText: "Available on the " + (Belt_Option.mapWithDefault(userAvailableOnPlan, "", (function (plan) {
                                          return Workspace.printPlanName(plan.name);
                                        })) + " plan")
                              }))
                  ), tmp), tmp$1);
}

var make = MembersRow;

export {
  removeMember ,
  updateMemberRole ,
  updateUserFilter ,
  make ,
}
/* Css Not a pure module */
