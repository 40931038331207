// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(status) {
  var tmp;
  if (typeof status !== "object") {
    tmp = {
      hd: [
        "type",
        "NeverSeenEvent"
      ],
      tl: /* [] */0
    };
  } else {
    switch (status.TAG) {
      case "AlwaysSeenCorrectlyLatestVersion" :
          tmp = {
            hd: [
              "type",
              "AlwaysSeenCorrectlyLatestVersion"
            ],
            tl: {
              hd: [
                "correctCount",
                status._0
              ],
              tl: {
                hd: [
                  "totalCount",
                  status._1
                ],
                tl: {
                  hd: [
                    "lastSeenCorrectly",
                    Json_encode.date(status._2)
                  ],
                  tl: /* [] */0
                }
              }
            }
          };
          break;
      case "SometimesSeenCorrectlyLatestVersion" :
          tmp = {
            hd: [
              "type",
              "SometimesSeenCorrectlyLatestVersion"
            ],
            tl: {
              hd: [
                "correctCount",
                status._0
              ],
              tl: {
                hd: [
                  "totalCount",
                  status._1
                ],
                tl: {
                  hd: [
                    "lastSeenCorrectly",
                    Json_encode.date(status._2)
                  ],
                  tl: {
                    hd: [
                      "lastSeenIncorrectly",
                      Json_encode.date(status._3)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            }
          };
          break;
      case "NeverSeenCorrectlyLatestVersion" :
          tmp = {
            hd: [
              "type",
              "NeverSeenCorrectlyLatestVersion"
            ],
            tl: {
              hd: [
                "correctCount",
                status._0
              ],
              tl: {
                hd: [
                  "totalCount",
                  status._1
                ],
                tl: {
                  hd: [
                    "lastSeenIncorrectly",
                    Json_encode.date(status._2)
                  ],
                  tl: /* [] */0
                }
              }
            }
          };
          break;
      case "AlwaysSeenCorrectlyInOlderVersion" :
          tmp = {
            hd: [
              "type",
              "AlwaysSeenCorrectlyInOlderVersion"
            ],
            tl: {
              hd: [
                "correctCount",
                status._0
              ],
              tl: {
                hd: [
                  "totalCount",
                  status._1
                ],
                tl: {
                  hd: [
                    "version",
                    status._2
                  ],
                  tl: {
                    hd: [
                      "lastSeenCorrectly",
                      Json_encode.date(status._3)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            }
          };
          break;
      case "SometimesSeenCorrectlyInOlderVersion" :
          tmp = {
            hd: [
              "type",
              "SometimesSeenCorrectlyInOlderVersion"
            ],
            tl: {
              hd: [
                "correctCount",
                status._0
              ],
              tl: {
                hd: [
                  "totalCount",
                  status._1
                ],
                tl: {
                  hd: [
                    "version",
                    status._2
                  ],
                  tl: {
                    hd: [
                      "lastSeenCorrectly",
                      Json_encode.date(status._3)
                    ],
                    tl: {
                      hd: [
                        "lastSeenIncorrectly",
                        Json_encode.date(status._4)
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          };
          break;
      case "NeverSeenCorrectlyInOlderVersion" :
          tmp = {
            hd: [
              "type",
              "NeverSeenCorrectlyInOlderVersion"
            ],
            tl: {
              hd: [
                "correctCount",
                status._0
              ],
              tl: {
                hd: [
                  "totalCount",
                  status._1
                ],
                tl: {
                  hd: [
                    "version",
                    status._2
                  ],
                  tl: {
                    hd: [
                      "lastSeenIncorrectly",
                      Json_encode.date(status._3)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            }
          };
          break;
      
    }
  }
  return Json_encode.object_(tmp);
}

function decode(json) {
  var match = Json_decode.field("type", Json_decode.string, json);
  switch (match) {
    case "AlwaysSeenCorrectlyInOlderVersion" :
        return {
                TAG: "AlwaysSeenCorrectlyInOlderVersion",
                _0: Json_decode.field("correctCount", Json_decode.$$int, json),
                _1: Json_decode.field("totalCount", Json_decode.$$int, json),
                _2: Json_decode.field("version", Json_decode.string, json),
                _3: new Date(Json_decode.field("lastSeenCorrectly", Json_decode.string, json))
              };
    case "AlwaysSeenCorrectlyLatestVersion" :
        return {
                TAG: "AlwaysSeenCorrectlyLatestVersion",
                _0: Json_decode.field("correctCount", Json_decode.$$int, json),
                _1: Json_decode.field("totalCount", Json_decode.$$int, json),
                _2: new Date(Json_decode.field("lastSeenCorrectly", Json_decode.string, json))
              };
    case "NeverSeenCorrectlyInOlderVersion" :
        return {
                TAG: "NeverSeenCorrectlyInOlderVersion",
                _0: Json_decode.field("correctCount", Json_decode.$$int, json),
                _1: Json_decode.field("totalCount", Json_decode.$$int, json),
                _2: Json_decode.field("version", Json_decode.string, json),
                _3: new Date(Json_decode.field("lastSeenIncorrectly", Json_decode.string, json))
              };
    case "NeverSeenCorrectlyLatestVersion" :
        return {
                TAG: "NeverSeenCorrectlyLatestVersion",
                _0: Json_decode.field("correctCount", Json_decode.$$int, json),
                _1: Json_decode.field("totalCount", Json_decode.$$int, json),
                _2: new Date(Json_decode.field("lastSeenIncorrectly", Json_decode.string, json))
              };
    case "NeverSeenEvent" :
        return "NeverSeenEvent";
    case "SometimesSeenCorrectlyInOlderVersion" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: Json_decode.field("correctCount", Json_decode.$$int, json),
                _1: Json_decode.field("totalCount", Json_decode.$$int, json),
                _2: Json_decode.field("version", Json_decode.string, json),
                _3: new Date(Json_decode.field("lastSeenCorrectly", Json_decode.string, json)),
                _4: new Date(Json_decode.field("lastSeenIncorrectly", Json_decode.string, json))
              };
    case "SometimesSeenCorrectlyLatestVersion" :
        return {
                TAG: "SometimesSeenCorrectlyLatestVersion",
                _0: Json_decode.field("correctCount", Json_decode.$$int, json),
                _1: Json_decode.field("totalCount", Json_decode.$$int, json),
                _2: new Date(Json_decode.field("lastSeenCorrectly", Json_decode.string, json)),
                _3: new Date(Json_decode.field("lastSeenIncorrectly", Json_decode.string, json))
              };
    default:
      console.error("Failed to decode Event Status Json", json);
      return "NeverSeenEvent";
  }
}

export {
  encode ,
  decode ,
}
/* No side effect */
