// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Timestamp from "./Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as Collaborators from "./Collaborators.mjs";
import * as IconLightning from "./IconLightning.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as AppVersionsStore from "./AppVersionsStore.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as BranchActionBox__Components from "./BranchActionBox__Components.mjs";

var upgradeModal = {
  NAME: "BillingPrompt",
  VAL: "BranchApprovalWorkflows"
};

var contentContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.gap(Css.px(16)),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  contentContainer: contentContainer
};

function BranchActionBox__NoApprovalWorkflow$OpenState(Props) {
  var actionDiff = Props.actionDiff;
  var actions = Props.actions;
  var approvalWorkflow = Props.approvalWorkflow;
  var branchCreatorId = Props.branchCreatorId;
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchName = Props.branchName;
  var branchDiffStatus = Props.branchDiffStatus;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var collaborators = Props.collaborators;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var mergeConflicts = Props.mergeConflicts;
  var role = Props.role;
  var sourceUpdates = Props.sourceUpdates;
  var openModal = GlobalSendContext.useOpenModal();
  return React.createElement(BranchActionBox__Components.Container.make, {
              children: null
            }, React.createElement(BranchActionBox__Components.MainContent.make, {
                  icon: "pencil",
                  children: React.createElement("div", {
                        className: contentContainer
                      }, React.createElement("div", undefined, React.createElement($$Text.make, {
                                size: "Large",
                                weight: "Semi",
                                color: Styles.Color.light12,
                                children: "Done making changes?"
                              }), React.createElement($$Text.make, {
                                size: "Medium",
                                color: Styles.Color.light11,
                                children: "Merge this branch into main once you’ve finished drafting your changes"
                              })))
                }), React.createElement(BranchActionBox__Components.MergeButtonSection.make, {
                  actionDiff: actionDiff,
                  actions: actions,
                  approvalWorkflow: approvalWorkflow,
                  blockBranchWithEnforcement: undefined,
                  branchCreatorId: branchCreatorId,
                  branchDiffStatus: branchDiffStatus,
                  branchId: branchId,
                  branchModel: branchModel,
                  branchModelWithPulledMaster: branchModelWithPulledMaster,
                  branchName: branchName,
                  branchStatus: branchStatus,
                  changes: changes,
                  collaborators: collaborators,
                  masterModel: masterModel,
                  masterModelBeforeBranch: masterModelBeforeBranch,
                  mergeConflicts: mergeConflicts,
                  role: role,
                  sourceUpdates: sourceUpdates,
                  masterModelLatestAction: masterModelLatestAction
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(10), Css.px(20)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(24)),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.light03),
                                tl: {
                                  hd: Css.borderTop(Css.px(1), Css.solid, Styles.Color.light06),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(IconLightning.make, {
                      size: 16
                    }), React.createElement(Spacer.make, {
                      width: 20
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: null
                    }, "Want a better branch workflow? Unlock review requests and approvals by upgrading to Team or Enterprise. ", React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              Curry._1(openModal, upgradeModal);
                            }),
                          style: "Blue",
                          children: "Learn more"
                        }))));
}

var OpenState = {
  make: BranchActionBox__NoApprovalWorkflow$OpenState
};

function BranchActionBox__NoApprovalWorkflow$ClosedState(Props) {
  var action = Props.action;
  var title = Props.title;
  var actionLabel = Props.actionLabel;
  var userId = action.createdBy;
  var user = FirebaseFetcherHooks.useUser(userId);
  return React.createElement(BranchActionBox__Components.Container.make, {
              children: React.createElement(BranchActionBox__Components.MainContent.make, {
                    children: null
                  }, React.createElement($$Text.make, {
                        weight: "Semi",
                        children: title
                      }), React.createElement($$Text.make, {
                        element: "Span",
                        size: "Small",
                        weight: "Regular",
                        color: Styles.Color.light10,
                        children: null
                      }, actionLabel, " ", React.createElement($$Text.make, {
                            element: "Span",
                            weight: "Semi",
                            children: React.createElement(Timestamp.make, {
                                  date: action.createdAt
                                })
                          }), typeof user === "object" ? React.createElement(React.Fragment, undefined, " by ", React.createElement($$Text.make, {
                                  element: "Span",
                                  weight: "Semi",
                                  children: AvoConfig.getUserDisplayName(user.VAL)
                                })) : null))
            });
}

var ClosedState = {
  make: BranchActionBox__NoApprovalWorkflow$ClosedState
};

function BranchActionBox__NoApprovalWorkflow$MergedState(Props) {
  var action = Props.action;
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var schemaBundle = SchemaBundleContext.use();
  var match = AppVersionsStore.use(undefined, 2, undefined);
  var breakingChanges = Hooks.useBreakingChangesReport(changes);
  var match$1 = FirebaseFetcherHooks.useValidationIntents(schemaBundle.schemaId, branchId);
  var sourceIdsWithVersionsSet = Belt_SetString.fromArray(Belt_MapString.keysToArray(match[0]));
  var sourceIds = breakingChanges.breakingSources;
  var hasSourcesToConfig = Belt_Array.some(sourceIds, (function (sourceId) {
          return Belt_SetString.has(sourceIdsWithVersionsSet, sourceId);
        }));
  var showVersionValidation = breakingChanges.breakingSources.length !== 0 && hasSourcesToConfig;
  return React.createElement(React.Fragment, undefined, React.createElement(BranchActionBox__NoApprovalWorkflow$ClosedState, {
                  action: action,
                  title: "Branch has been merged",
                  actionLabel: branchName + " was merged into main"
                }), showVersionValidation ? React.createElement(BranchActionBox__Components.InspectorValidation.make, {
                    branchStatus: branchStatus,
                    branchModel: branchModel,
                    breakingChanges: breakingChanges,
                    validationIntent: match$1[0]
                  }) : null);
}

var MergedState = {
  make: BranchActionBox__NoApprovalWorkflow$MergedState
};

function BranchActionBox__NoApprovalWorkflow(Props) {
  var actionDiff = Props.actionDiff;
  var actions = Props.actions;
  var branchCreatorId = Props.branchCreatorId;
  var branchDiffStatus = Props.branchDiffStatus;
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var mergeConflicts = Props.mergeConflicts;
  var role = Props.role;
  var sourceUpdates = Props.sourceUpdates;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var collaborators = Collaborators.useCollaborators(branchId);
  if (typeof branchStatus !== "object") {
    if (branchStatus === "Master") {
      return null;
    } else {
      return React.createElement($$Text.make, {
                  children: "Loading"
                });
    }
  }
  switch (branchStatus.TAG) {
    case "Merged" :
        return React.createElement(BranchActionBox__NoApprovalWorkflow$MergedState, {
                    action: branchStatus._0,
                    branchId: branchId,
                    branchModel: branchModel,
                    branchName: branchName,
                    branchStatus: branchStatus,
                    changes: changes
                  });
    case "Closed" :
        return React.createElement(BranchActionBox__NoApprovalWorkflow$ClosedState, {
                    action: branchStatus._0,
                    title: "Branch has been deleted",
                    actionLabel: branchName + " was was deleted"
                  });
    default:
      return React.createElement(BranchActionBox__NoApprovalWorkflow$OpenState, {
                  actionDiff: actionDiff,
                  actions: actions,
                  approvalWorkflow: "Unavailable",
                  branchCreatorId: branchCreatorId,
                  branchId: branchId,
                  branchModel: branchModel,
                  branchModelWithPulledMaster: branchModelWithPulledMaster,
                  branchName: branchName,
                  branchDiffStatus: branchDiffStatus,
                  branchStatus: branchStatus,
                  changes: changes,
                  collaborators: collaborators,
                  masterModel: masterModel,
                  masterModelBeforeBranch: masterModelBeforeBranch,
                  masterModelLatestAction: masterModelLatestAction,
                  mergeConflicts: mergeConflicts,
                  role: role,
                  sourceUpdates: sourceUpdates
                });
  }
}

var make = BranchActionBox__NoApprovalWorkflow;

export {
  upgradeModal ,
  Style ,
  OpenState ,
  ClosedState ,
  MergedState ,
  make ,
}
/* contentContainer Not a pure module */
