// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Parser from "./Parser.mjs";
import * as Printer from "./Printer.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TrackingPlanMappedModel from "./TrackingPlanMappedModel.mjs";

function MakeArrayCopyFromConfig(Config, Module) {
  var copy = function (a) {
    return Curry._1(Config.parse, Curry._1(Config.print, a));
  };
  var toMapped = function (items) {
    if (items.length === 0) {
      return Module.empty;
    } else {
      return Curry._1(Module.fromArray, Belt_Array.keepMapU(items, (function (item) {
                        return Curry._1(Config.select, Curry._1(Config.parse, Curry._1(Config.print, item)));
                      })));
    }
  };
  return {
          copy: copy,
          toMapped: toMapped
        };
}

function MakeListCopyFromConfig(Config, Module) {
  var copy = function (a) {
    return Curry._1(Config.parse, Curry._1(Config.print, a));
  };
  var toMapped = function (items) {
    if (items.length === 0) {
      return Module.empty;
    } else {
      return Curry._1(Module.fromArray, Belt_Array.keepMapU(items, (function (item) {
                        return Curry._1(Config.select, Curry._1(Config.parse, Curry._1(Config.print, item)));
                      })));
    }
  };
  var M = {
    copy: copy,
    toMapped: toMapped
  };
  var toMapped$1 = function (items) {
    return Curry._1(toMapped, Belt_List.toArray(items));
  };
  return {
          M: M,
          toMapped: toMapped$1
        };
}

function select(a) {
  return Caml_option.some(a);
}

var Events = {
  select: select,
  print: Printer.printEvent,
  parse: Parser.parseEvent
};

function select$1(property) {
  if (property.TAG === "PropertyRef") {
    return ;
  } else {
    return property._0;
  }
}

var Properties = {
  select: select$1,
  print: Printer.printProperty,
  parse: Parser.parseProperty
};

var PropertyBundles = {
  select: select,
  print: Printer.printPropertyBundle,
  parse: Parser.parsePropertyGroup
};

var Sources = {
  select: select,
  print: Printer.printSource,
  parse: Parser.parseSource
};

var Destinations = {
  select: select,
  print: Printer.printDestination,
  parse: Parser.parseDestination
};

var Categories = {
  select: select,
  print: Printer.printGoal,
  parse: Parser.parseGoal
};

var Metrics = {
  select: select,
  print: Printer.printMetric,
  parse: Parser.parseMetric
};

var GroupTypes = {
  select: select,
  print: Printer.printGroupType,
  parse: Parser.parseGroupType
};

var EventVariants = {
  select: select,
  print: Printer.printEventVariant,
  parse: Parser.parseEventVariant
};

var Integrations = {
  select: select,
  print: Printer.printIntegration,
  parse: Parser.parseIntegration
};

var Archive = {
  select: select,
  print: Printer.printArchive,
  parse: Parser.parseArchive
};

var C = {
  select: select,
  Events: Events,
  Properties: Properties,
  PropertyBundles: PropertyBundles,
  Sources: Sources,
  Destinations: Destinations,
  Categories: Categories,
  Metrics: Metrics,
  GroupTypes: GroupTypes,
  EventVariants: EventVariants,
  Integrations: Integrations,
  Archive: Archive
};

var Module_empty = TrackingPlanMappedModel.Events.empty;

var Module_fromArray = TrackingPlanMappedModel.Events.fromArray;

function copy(a) {
  return Parser.parseEvent(Printer.printEvent(a));
}

function toMapped(items) {
  if (items.length === 0) {
    return Module_empty;
  } else {
    return Curry._1(Module_fromArray, Belt_Array.keepMapU(items, (function (item) {
                      return select(Parser.parseEvent(Printer.printEvent(item)));
                    })));
  }
}

var M = {
  copy: copy,
  toMapped: toMapped
};

function toMapped$1(items) {
  return Curry._1(toMapped, Belt_List.toArray(items));
}

var toMapped$2 = toMapped$1;

var Events_M = M;

var Events$1 = {
  M: Events_M,
  toMapped: toMapped$2
};

var Module_empty$1 = TrackingPlanMappedModel.Properties.empty;

var Module_fromArray$1 = TrackingPlanMappedModel.Properties.fromArray;

function copy$1(a) {
  return Parser.parseProperty(Printer.printProperty(a));
}

function toMapped$3(items) {
  if (items.length === 0) {
    return Module_empty$1;
  } else {
    return Curry._1(Module_fromArray$1, Belt_Array.keepMapU(items, (function (item) {
                      return select$1(Parser.parseProperty(Printer.printProperty(item)));
                    })));
  }
}

var M$1 = {
  copy: copy$1,
  toMapped: toMapped$3
};

function toMapped$4(items) {
  return Curry._1(toMapped$3, Belt_List.toArray(items));
}

var toMapped$5 = toMapped$4;

var Properties_M = M$1;

var Properties$1 = {
  M: Properties_M,
  toMapped: toMapped$5
};

var Module_empty$2 = TrackingPlanMappedModel.PropertyBundles.empty;

var Module_fromArray$2 = TrackingPlanMappedModel.PropertyBundles.fromArray;

function copy$2(a) {
  return Parser.parsePropertyGroup(Printer.printPropertyBundle(a));
}

function toMapped$6(items) {
  if (items.length === 0) {
    return Module_empty$2;
  } else {
    return Curry._1(Module_fromArray$2, Belt_Array.keepMapU(items, (function (item) {
                      return select(Parser.parsePropertyGroup(Printer.printPropertyBundle(item)));
                    })));
  }
}

var M$2 = {
  copy: copy$2,
  toMapped: toMapped$6
};

function toMapped$7(items) {
  return Curry._1(toMapped$6, Belt_List.toArray(items));
}

var toMapped$8 = toMapped$7;

var PropertyBundles_M = M$2;

var PropertyBundles$1 = {
  M: PropertyBundles_M,
  toMapped: toMapped$8
};

var Module_empty$3 = TrackingPlanMappedModel.Sources.empty;

var Module_fromArray$3 = TrackingPlanMappedModel.Sources.fromArray;

function copy$3(a) {
  return Parser.parseSource(Printer.printSource(a));
}

function toMapped$9(items) {
  if (items.length === 0) {
    return Module_empty$3;
  } else {
    return Curry._1(Module_fromArray$3, Belt_Array.keepMapU(items, (function (item) {
                      return select(Parser.parseSource(Printer.printSource(item)));
                    })));
  }
}

var M$3 = {
  copy: copy$3,
  toMapped: toMapped$9
};

function toMapped$10(items) {
  return Curry._1(toMapped$9, Belt_List.toArray(items));
}

var toMapped$11 = toMapped$10;

var Sources_M = M$3;

var Sources$1 = {
  M: Sources_M,
  toMapped: toMapped$11
};

var Module_empty$4 = TrackingPlanMappedModel.Destinations.empty;

var Module_fromArray$4 = TrackingPlanMappedModel.Destinations.fromArray;

function copy$4(a) {
  return Parser.parseDestination(Printer.printDestination(a));
}

function toMapped$12(items) {
  if (items.length === 0) {
    return Module_empty$4;
  } else {
    return Curry._1(Module_fromArray$4, Belt_Array.keepMapU(items, (function (item) {
                      return select(Parser.parseDestination(Printer.printDestination(item)));
                    })));
  }
}

var M$4 = {
  copy: copy$4,
  toMapped: toMapped$12
};

function toMapped$13(items) {
  return Curry._1(toMapped$12, Belt_List.toArray(items));
}

var toMapped$14 = toMapped$13;

var Destinations_M = M$4;

var Destinations$1 = {
  M: Destinations_M,
  toMapped: toMapped$14
};

var Module_empty$5 = TrackingPlanMappedModel.Categories.empty;

var Module_fromArray$5 = TrackingPlanMappedModel.Categories.fromArray;

function copy$5(a) {
  return Parser.parseGoal(Printer.printGoal(a));
}

function toMapped$15(items) {
  if (items.length === 0) {
    return Module_empty$5;
  } else {
    return Curry._1(Module_fromArray$5, Belt_Array.keepMapU(items, (function (item) {
                      return select(Parser.parseGoal(Printer.printGoal(item)));
                    })));
  }
}

var M$5 = {
  copy: copy$5,
  toMapped: toMapped$15
};

function toMapped$16(items) {
  return Curry._1(toMapped$15, Belt_List.toArray(items));
}

var toMapped$17 = toMapped$16;

var Categories_M = M$5;

var Categories$1 = {
  M: Categories_M,
  toMapped: toMapped$17
};

var Module_empty$6 = TrackingPlanMappedModel.Metrics.empty;

var Module_fromArray$6 = TrackingPlanMappedModel.Metrics.fromArray;

function copy$6(a) {
  return Parser.parseMetric(Printer.printMetric(a));
}

function toMapped$18(items) {
  if (items.length === 0) {
    return Module_empty$6;
  } else {
    return Curry._1(Module_fromArray$6, Belt_Array.keepMapU(items, (function (item) {
                      return select(Parser.parseMetric(Printer.printMetric(item)));
                    })));
  }
}

var M$6 = {
  copy: copy$6,
  toMapped: toMapped$18
};

function toMapped$19(items) {
  return Curry._1(toMapped$18, Belt_List.toArray(items));
}

var toMapped$20 = toMapped$19;

var Metrics_M = M$6;

var Metrics$1 = {
  M: Metrics_M,
  toMapped: toMapped$20
};

var Module_empty$7 = TrackingPlanMappedModel.GroupTypes.empty;

var Module_fromArray$7 = TrackingPlanMappedModel.GroupTypes.fromArray;

function copy$7(a) {
  return Parser.parseGroupType(Printer.printGroupType(a));
}

function toMapped$21(items) {
  if (items.length === 0) {
    return Module_empty$7;
  } else {
    return Curry._1(Module_fromArray$7, Belt_Array.keepMapU(items, (function (item) {
                      return select(Parser.parseGroupType(Printer.printGroupType(item)));
                    })));
  }
}

var toMapped$22 = toMapped$21;

var GroupTypes_copy = copy$7;

var GroupTypes$1 = {
  copy: GroupTypes_copy,
  toMapped: toMapped$22
};

var Module_empty$8 = TrackingPlanMappedModel.EventVariants.empty;

var Module_fromArray$8 = TrackingPlanMappedModel.EventVariants.fromArray;

function copy$8(a) {
  return Parser.parseEventVariant(Printer.printEventVariant(a));
}

function toMapped$23(items) {
  if (items.length === 0) {
    return Module_empty$8;
  } else {
    return Curry._1(Module_fromArray$8, Belt_Array.keepMapU(items, (function (item) {
                      return select(Parser.parseEventVariant(Printer.printEventVariant(item)));
                    })));
  }
}

var toMapped$24 = toMapped$23;

var EventVariants_copy = copy$8;

var EventVariants$1 = {
  copy: EventVariants_copy,
  toMapped: toMapped$24
};

function toMapped$25(archive) {
  return {
          categories: Curry._1(toMapped$17, archive.goals),
          destinations: Curry._1(toMapped$14, archive.destinations),
          events: Curry._1(toMapped$2, archive.events),
          groupTypes: Curry._1(toMapped$22, archive.groupTypes),
          metrics: Curry._1(toMapped$20, archive.metrics),
          properties: Curry._1(toMapped$5, archive.properties),
          propertyBundles: Curry._1(toMapped$8, archive.propertyBundles),
          sources: Curry._1(toMapped$11, archive.sources),
          eventVariants: Curry._1(toMapped$24, archive.eventVariants)
        };
}

var Archive$1 = {
  toMapped: toMapped$25
};

function toMapped$26(migrations) {
  if (migrations === /* [] */0) {
    return [];
  } else {
    return Belt_List.toArray(Parser.parseMigrations(Printer.printMigrations(migrations)));
  }
}

var Migrations = {
  toMapped: toMapped$26
};

function toMapped$27(rules) {
  if (rules === /* [] */0) {
    return [];
  } else {
    return Belt_Array.mapU(Belt_Array.mapU(Belt_List.toArray(rules), Printer.printRule), Parser.parseRule);
  }
}

var Rules = {
  toMapped: toMapped$27
};

function toMapped$28(types) {
  if (types === /* [] */0) {
    return [];
  } else {
    return Belt_Array.mapU(Belt_Array.mapU(Belt_List.toArray(types), Printer.printType), Parser.parseType);
  }
}

var Types = {
  toMapped: toMapped$28
};

var Module_empty$9 = TrackingPlanMappedModel.Integrations.empty;

var Module_fromArray$9 = TrackingPlanMappedModel.Integrations.fromArray;

function copy$9(a) {
  return Parser.parseIntegration(Printer.printIntegration(a));
}

function toMapped$29(items) {
  if (items.length === 0) {
    return Module_empty$9;
  } else {
    return Curry._1(Module_fromArray$9, Belt_Array.keepMapU(items, (function (item) {
                      return select(Parser.parseIntegration(Printer.printIntegration(item)));
                    })));
  }
}

var M$7 = {
  copy: copy$9,
  toMapped: toMapped$29
};

function toMapped$30(items) {
  return Curry._1(toMapped$29, Belt_List.toArray(items));
}

var toMapped$31 = toMapped$30;

var Integrations_M = M$7;

var Integrations$1 = {
  M: Integrations_M,
  toMapped: toMapped$31
};

function toMapped$32(openBranches) {
  if (openBranches === /* [] */0) {
    return TrackingPlanMappedModel.OpenBranches.empty;
  } else {
    return Curry._1(TrackingPlanMappedModel.OpenBranches.fromList, Parser.parseBranches(Printer.printBranches(openBranches)));
  }
}

var OpenBranches = {
  toMapped: toMapped$32
};

function toMapped$33(globalRequirements) {
  return Belt_Option.mapU(globalRequirements, (function (globalRequirements) {
                return {
                        metadata: Parser.parseGlobalRequirementsMetadata(Printer.printGlobalRequirementsMetadata(globalRequirements.metadata)),
                        events: Curry._1(toMapped$2, globalRequirements.events),
                        properties: Curry._1(toMapped$5, globalRequirements.properties),
                        propertyBundles: Curry._1(toMapped$8, globalRequirements.propertyBundles),
                        categories: Curry._1(toMapped$17, globalRequirements.goals),
                        groupTypes: Curry._1(toMapped$22, globalRequirements.groupTypes),
                        rules: toMapped$27(globalRequirements.rules)
                      };
              }));
}

var GlobalRequirements = {
  toMapped: toMapped$33
};

function toMapped$34(model) {
  var match = model.branchPointer;
  return {
          archive: toMapped$25(model.archive),
          branchPointer: match !== undefined ? [
              match[0],
              match[1]
            ] : undefined,
          migrations: toMapped$26(model.migrations),
          rules: toMapped$27(model.rules),
          types: toMapped$28(model.types),
          categories: Curry._1(toMapped$17, model.goals),
          destinations: Curry._1(toMapped$14, model.destinations),
          events: Curry._1(toMapped$2, model.events),
          groupTypes: Curry._1(toMapped$22, model.groupTypes),
          integrations: Curry._1(toMapped$31, model.integrations),
          metrics: Curry._1(toMapped$20, model.metrics),
          openBranches: toMapped$32(model.openBranches),
          properties: Curry._1(toMapped$5, model.properties),
          propertyBundles: Curry._1(toMapped$8, model.propertyBundles),
          sources: Curry._1(toMapped$11, model.sources),
          globalRequirements: toMapped$33(model.globalRequirements)
        };
}

export {
  MakeArrayCopyFromConfig ,
  MakeListCopyFromConfig ,
  C ,
  Events$1 as Events,
  Properties$1 as Properties,
  PropertyBundles$1 as PropertyBundles,
  Sources$1 as Sources,
  Destinations$1 as Destinations,
  Categories$1 as Categories,
  Metrics$1 as Metrics,
  GroupTypes$1 as GroupTypes,
  EventVariants$1 as EventVariants,
  Archive$1 as Archive,
  Migrations ,
  Rules ,
  Types ,
  Integrations$1 as Integrations,
  OpenBranches ,
  GlobalRequirements ,
  toMapped$34 as toMapped,
}
/* Parser Not a pure module */
