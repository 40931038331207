// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Accordion from "./Accordion.mjs";

function DiffCollapseButton(Props) {
  var toggle = Props.toggle;
  var isOpen = Props.isOpen;
  var changeType = Props.changeType;
  return React.createElement("button", {
              className: Curry._1(Css.merge, {
                    hd: Curry._1(Css.style, Styles.buttonReset),
                    tl: /* [] */0
                  }),
              onClick: (function (e) {
                  e.stopPropagation();
                  Curry._1(toggle, undefined);
                })
            }, React.createElement(Accordion.OpeningIcon.make, {
                  color: changeType === "Create" ? Styles.Color.darkGreen : (
                      changeType === "Update" ? Styles.Color.light06 : Styles.Color.darkRed
                    ),
                  size: "large",
                  isOpen: isOpen
                }));
}

var make = DiffCollapseButton;

export {
  make ,
}
/* Css Not a pure module */
