// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as Markdown from "./Markdown.mjs";
import * as Tooltips from "./markdown/tooltips/tooltips.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";

function CollapsedAutoProperties(Props) {
  var properties = Props.properties;
  var fromItem = Props.fromItem;
  var children = Props.children;
  var match = React.useState(function (param) {
        return true;
      });
  if (properties === /* [] */0) {
    return null;
  }
  var setIsCollapsed = match[1];
  var isCollapsed = match[0];
  return React.createElement("div", undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement("button", {
                      className: Curry._1(Css.merge, {
                            hd: Curry._1(Css.style, Styles.button),
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: {
                                        hd: Css.color(Styles.Color.darkBlue),
                                        tl: {
                                          hd: Css.padding2(Css.px(8), Css.px(0)),
                                          tl: {
                                            hd: Css.textAlign("left"),
                                            tl: {
                                              hd: Css.marginRight(Css.px(10)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      onClick: (function (param) {
                          Curry._1(setIsCollapsed, (function (isCollapsed) {
                                  return !isCollapsed;
                                }));
                        })
                    }, isCollapsed ? "See " + String(Belt_List.length(properties)) + " automatic properties" : "Hide " + String(Belt_List.length(properties)) + " automatic properties"), React.createElement(Tooltip.WithAnalytics.make, {
                      fromItem: fromItem,
                      title: "Automatic Properties",
                      children: React.createElement(Tooltip.Content.make, {
                            children: React.createElement(Markdown.make, {
                                  source: Tooltips.automaticProperties
                                })
                          })
                    })), isCollapsed ? null : children);
}

var make = CollapsedAutoProperties;

export {
  make ,
}
/* Css Not a pure module */
