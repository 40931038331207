// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Markdown from "./Markdown.mjs";
import * as Tooltips from "./markdown/tooltips/tooltips.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as PlanLightning from "./PlanLightning.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as PropertyAbsenceViewHelpers from "./PropertyAbsenceViewHelpers.mjs";

function compare(a, b) {
  var value = a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
  if (value < 0.0) {
    return -1;
  } else if (value > 0.0) {
    return 1;
  } else {
    return 0;
  }
}

var containerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding4(Css.px(12), Css.px(16), Css.px(12), Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderTopWidth(Css.px(0)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var pillStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding2(Css.px(4), Css.px(8)),
              tl: {
                hd: Css.borderRadius(Css.px(16)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light08),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light08),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "background-color"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.backgroundColor(Styles.Color.light10),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var buttonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.padding2(Css.px(4), Css.px(8)),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "border-color"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.borderColor(Styles.Color.light08),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function PropertyDetailsEvents$RestRow(Props) {
  var partialItemsCount = Props.partialItemsCount;
  var totalItemsCount = Props.totalItemsCount;
  var showAllItems = Props.showAllItems;
  var itemLabel = Props.itemLabel;
  var onClick = Props.onClick;
  var count = showAllItems ? totalItemsCount : partialItemsCount;
  return React.createElement("div", {
              className: containerStyles
            }, React.createElement("button", {
                  className: pillStyles,
                  onClick: onClick
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light01,
                      children: String(count) + (
                        showAllItems ? "" : " more"
                      ) + " " + itemLabel + (
                        count > 1 ? "s" : ""
                      )
                    })), React.createElement("button", {
                  className: buttonStyles,
                  onClick: onClick
                }, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: (
                        showAllItems ? "Show fewer" : "Show all"
                      ) + " " + itemLabel + "s"
                    })));
}

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding4(Css.px(12), Css.px(12), Css.px(12), Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderTopRightRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.borderTopLeftRadius(Styles.Border.radius),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var nameRowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding4(Css.px(12), Css.px(16), Css.px(12), Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderTopWidth(Css.px(0)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var listItemStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.gap(Css.px(4)),
            tl: {
              hd: Css.padding4(Css.px(8), Css.px(8), Css.px(8), Css.px(16)),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderTopWidth(Css.px(0)),
                  tl: {
                    hd: Css.selector(":nth-last-child(2)", {
                          hd: Css.borderBottomRightRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                            tl: /* [] */0
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var listItemConfigStyles = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(200)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var planLightningStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css_Legacy_Core.SVG.fill("transparent"),
        tl: {
          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
          tl: {
            hd: Css.hover({
                  hd: Css_Legacy_Core.SVG.stroke(Styles.Color.darkBlue),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var linkStyles = Curry._1(Css.style, {
      hd: Css.display("block"),
      tl: {
        hd: Css.flexGrow(1.0),
        tl: {
          hd: Css.flexShrink(1.0),
          tl: {
            hd: Css.minWidth(Css.px(1)),
            tl: {
              hd: Css.wordBreak("breakAll"),
              tl: {
                hd: Css.color(Styles.Color.light10),
                tl: {
                  hd: Css.hover({
                        hd: Css.color(Styles.Color.darkBlue),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.padding2(Css.px(8), Css.px(0)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function tableBoxStyles(useBottomRadius) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.pct(100.0)),
              tl: {
                hd: Css.overflow("auto"),
                tl: {
                  hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                  tl: {
                    hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
                    tl: {
                      hd: Css.borderBottomLeftRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                      tl: {
                        hd: Css.borderBottomRightRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function tableStyles(useBottomRadius) {
  return Curry._1(Css.style, {
              hd: Css.borderSpacing(Css.px(0)),
              tl: {
                hd: Css.minWidth(Css.pct(100.0)),
                tl: {
                  hd: Css.selector(" tbody tr:last-child td:first-child", {
                        hd: Css.borderBottomLeftRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.selector(" tbody tr:last-child td:last-child", {
                          hd: Css.borderBottomRightRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var tableHeaderCellStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(16)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light02),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.selector(":first-child", {
                    hd: Css.position("sticky"),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: /* [] */0
                    }
                  }),
              tl: {
                hd: Css.selector(":last-child", {
                      hd: Css.borderRightWidth(Css.px(0)),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.selector(":not(:first-child)", {
                        hd: Css.textAlign("center"),
                        tl: {
                          hd: Css.verticalAlign("middle"),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var tableNameCellStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(16)),
      tl: {
        hd: Css.borderLeftWidth(Css.px(0)),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.position("sticky"),
              tl: {
                hd: Css.left(Css.px(0)),
                tl: {
                  hd: Css.background(Styles.Color.white),
                  tl: {
                    hd: Css.zIndex(1),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var tableSelectCellStyles = Curry._1(Css.style, {
      hd: Css.selector(":last-child", {
            hd: Css.borderRightWidth(Css.px(0)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.padding(Css.px(4)),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.textAlign("center"),
              tl: {
                hd: Css.verticalAlign("middle"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function useModeMetadata(events, sourcesList) {
  return React.useMemo((function (param) {
                var eventSourcesMap = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU(events, (function ($$event) {
                                return [
                                        $$event.id,
                                        AvoConfig.determineSendingToSources(sourcesList, $$event)
                                      ];
                              }))));
                var propertyIsNotSent = Belt_MapString.everyU(eventSourcesMap, (function (_eventId, sourcesList) {
                        return sourcesList === "None";
                      }));
                var propertyIsAlwaysSometimesSent = Belt_MapString.everyU(eventSourcesMap, (function (_eventId, sourcesList) {
                        return sourcesList !== "None";
                      }));
                var includeDependingOnSource = !propertyIsNotSent && Belt_List.length(sourcesList) > 1 && Belt_MapString.someU(eventSourcesMap, (function (_eventId, sourcesList) {
                        if (typeof sourcesList === "object") {
                          return Belt_List.length(sourcesList.VAL) > 1;
                        } else {
                          return sourcesList !== "None";
                        }
                      }));
                return [
                        eventSourcesMap,
                        propertyIsNotSent,
                        propertyIsAlwaysSometimesSent,
                        includeDependingOnSource
                      ];
              }), [
              events,
              sourcesList
            ]);
}

function PropertyDetailsEvents(Props) {
  var viewerCanEdit = Props.viewerCanEdit;
  var property = Props.property;
  var events = Props.events;
  var model = ModelStore.Mapped.use();
  var sendActions = SendActionsContext.use();
  var schemaBundle = SchemaBundleContext.use();
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use();
  var sourcesList = Belt_List.fromArray(Curry._1(TrackingPlanMappedModel.Sources.toArray, model.sources));
  var match = AvoLimits.PropertyAbsenceByEventAndSource.computeAvailability(workspace);
  var available = match === "AvailableDuringTrial" || match === "Available";
  var initialMode = PropertyAbsenceViewHelpers.getDefaultModeForAbsence(events, sourcesList, property.absence);
  var match$1 = React.useState(function (param) {
        return initialMode;
      });
  var pickMode = match$1[1];
  var pickedMode = match$1[0];
  var match$2 = React.useState(function (param) {
        return initialMode;
      });
  var setComputedMode = match$2[1];
  var computedMode = match$2[0];
  React.useEffect((function (param) {
          Curry._1(setComputedMode, (function (param) {
                  var modelMode = PropertyAbsenceViewHelpers.getDefaultModeForAbsence(events, sourcesList, property.absence);
                  switch (pickedMode) {
                    case "Always" :
                        switch (modelMode) {
                          case "Always" :
                              return "Always";
                          case "SometimesAll" :
                              return "SometimesAll";
                          case "SometimesByEvent" :
                              return "SometimesByEvent";
                          case "BySource" :
                              return "BySource";
                          
                        }
                    case "SometimesAll" :
                        switch (modelMode) {
                          case "Always" :
                              return "Always";
                          case "SometimesAll" :
                              return "SometimesAll";
                          case "SometimesByEvent" :
                              return "SometimesByEvent";
                          case "BySource" :
                              return "BySource";
                          
                        }
                    case "SometimesByEvent" :
                        switch (modelMode) {
                          case "Always" :
                              return "Always";
                          case "SometimesAll" :
                              return "SometimesAll";
                          case "SometimesByEvent" :
                              return "SometimesByEvent";
                          case "BySource" :
                              return "BySource";
                          
                        }
                    case "BySource" :
                        return "BySource";
                    
                  }
                }));
        }), [
        events,
        sourcesList,
        property.absence,
        pickedMode
      ]);
  var match$3 = React.useState(function (param) {
        return false;
      });
  var setShowAllEvents = match$3[1];
  var showAllEvents = match$3[0];
  var match$4 = useModeMetadata(events, sourcesList);
  var includeDependingOnSource = match$4[3];
  var propertyIsNotSent = match$4[1];
  var eventSourcesMap = match$4[0];
  var eventsToPinnedValuesMap = React.useMemo((function (param) {
          return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.keepMap(events, (function ($$event) {
                                var pinnedValues = PropertyUtils.getPinnedValues_mapped(undefined, property.id, $$event.id, false, model, undefined);
                                if (pinnedValues === /* [] */0 || property.type_ === "object") {
                                  return ;
                                } else {
                                  return [
                                          $$event.id,
                                          pinnedValues
                                        ];
                                }
                              }))));
        }), [events]);
  var tmp;
  if (match$4[2]) {
    tmp = null;
  } else {
    var tmp$1 = {
      size: "Tiny",
      color: propertyIsNotSent ? Styles.Color.darkOrange : Styles.Color.light11,
      children: propertyIsNotSent ? "Not sent in any event" : "Not sent in " + AvoUtils.plural(undefined, undefined, Belt_List.length(events) - Belt_MapString.size(Belt_MapString.keepU(eventSourcesMap, (function (_eventId, sourcesList) {
                        return sourcesList !== "None";
                      }))) | 0, "event")
    };
    var tmp$2 = propertyIsNotSent ? "Semi" : undefined;
    if (tmp$2 !== undefined) {
      tmp$1.weight = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement($$Text.make, tmp$1);
  }
  var tmp$3;
  if (propertyIsNotSent) {
    tmp$3 = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.gap(Css.px(0)),
                    tl: /* [] */0
                  }
                }
              })
        }, React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.light11,
              children: "Property is not sent in any event"
            }), React.createElement(Tooltip.make, {
              size: "small",
              title: "Property is not sent in any event",
              children: React.createElement(Tooltip.Content.make, {
                    children: React.createElement(Markdown.make, {
                          source: "Although the property is attached to events those events aren’t sent from any source. To be able to configure the property presence, any event which the property is attached to needs to be sent from at least one source."
                        })
                  })
            }));
  } else {
    var tmp$4;
    tmp$4 = computedMode === "SometimesByEvent" && Belt_List.length(events) === 1 ? PropertyAbsenceViewHelpers.getModeString("SometimesAll") : PropertyAbsenceViewHelpers.getModeString(computedMode);
    tmp$3 = React.createElement(Select.make, {
          disabled: !viewerCanEdit,
          onSelect: (function (modeString) {
              var newMode = PropertyAbsenceViewHelpers.parseModeString(modeString);
              if (!available && newMode === "BySource") {
                return Curry._1(globalSend, {
                            TAG: "OpenModal",
                            _0: {
                              NAME: "BillingPrompt",
                              VAL: "ConfigureEventsPerPlatform"
                            }
                          });
              }
              var absenceDefaultMode = PropertyAbsenceViewHelpers.getDefaultModeForAbsence(events, sourcesList, property.absence);
              var confirmationCopy = "Switching to " + (PropertyAbsenceViewHelpers.getModeString(newMode) + " will cause your config to change. Are you sure you want to proceed?");
              var stateMatrix = PropertyAbsenceViewHelpers.getFullStateMatrix(events, sourcesList, property.absence);
              var showConfirm = !PropertyAbsenceViewHelpers.emptyOrEverythingSometimes(stateMatrix) && PropertyAbsenceViewHelpers.getMoreComplex(absenceDefaultMode, newMode) !== newMode;
              var shouldProceed = showConfirm ? window.confirm(confirmationCopy) : true;
              if (!shouldProceed) {
                return ;
              }
              var exit = 0;
              switch (computedMode) {
                case "Always" :
                    switch (newMode) {
                      case "SometimesAll" :
                          exit = 1;
                          break;
                      case "SometimesByEvent" :
                          exit = 2;
                          break;
                      case "Always" :
                      case "BySource" :
                          break;
                      
                    }
                    break;
                case "SometimesAll" :
                    switch (newMode) {
                      case "Always" :
                          exit = 3;
                          break;
                      case "SometimesByEvent" :
                          exit = 2;
                          break;
                      case "SometimesAll" :
                      case "BySource" :
                          break;
                      
                    }
                    break;
                case "SometimesByEvent" :
                    switch (newMode) {
                      case "Always" :
                          exit = 3;
                          break;
                      case "SometimesAll" :
                          exit = 1;
                          break;
                      case "SometimesByEvent" :
                      case "BySource" :
                          break;
                      
                    }
                    break;
                case "BySource" :
                    switch (newMode) {
                      case "Always" :
                          if (!Caml_obj.equal(property.absence, "AlwaysSent")) {
                            exit = 3;
                          }
                          break;
                      case "SometimesAll" :
                          var match = property.absence;
                          var tmp;
                          tmp = match !== undefined && !(typeof match !== "object" || match.TAG !== "SometimesSent") ? true : false;
                          if (!tmp) {
                            exit = 1;
                          }
                          break;
                      case "SometimesByEvent" :
                          exit = 2;
                          break;
                      case "BySource" :
                          break;
                      
                    }
                    break;
                
              }
              switch (exit) {
                case 1 :
                    var id = property.id;
                    Curry.app(sendActions, [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          (function (branch) {
                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                              var property$1 = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                              AnalyticsRe.propertyConfigured(property$1.propertyId, Belt_Option.getWithDefault(property$1.propertyName, ""), property$1.namingConvention, property$1.propertyType, property$1.correctCase, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Presence", "UpdatePropertyPresenceGlobally", undefined, PropertyAbsenceViewHelpers.analyticsPresenceTypeBefore(computedMode), undefined, "SometimesSent", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                            }),
                          undefined,
                          [[
                              {
                                NAME: "UpdatePropertyAbsence",
                                VAL: [
                                  id,
                                  "All",
                                  {
                                    TAG: "SometimesSent",
                                    _0: ""
                                  }
                                ]
                              },
                              {
                                propertyId: id,
                                propertyQuery: id
                              }
                            ]]
                        ]);
                    break;
                case 2 :
                    var id$1 = property.id;
                    var absence = {
                      TAG: "SometimesSent",
                      _0: ""
                    };
                    var $$event = Curry._2(TrackingPlanMappedModel.Events.getAtIndex, model.events, 0);
                    if ($$event !== undefined) {
                      var actions = [
                        [
                          {
                            NAME: "UpdatePropertyAbsence",
                            VAL: [
                              id$1,
                              "All",
                              absence
                            ]
                          },
                          {
                            propertyId: id$1,
                            propertyQuery: id$1
                          }
                        ],
                        [
                          {
                            NAME: "UpdatePropertyAbsence",
                            VAL: [
                              id$1,
                              {
                                TAG: "Event",
                                _0: $$event.id
                              },
                              absence
                            ]
                          },
                          {
                            propertyId: id$1,
                            propertyQuery: id$1
                          }
                        ]
                      ];
                      Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            (function (branch) {
                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                                AnalyticsRe.propertyConfigured(propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Presence", "UpdatePropertyPresenceGlobally", undefined, PropertyAbsenceViewHelpers.analyticsPresenceTypeBefore(computedMode), undefined, "SometimesSentByEvent", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                              }),
                            undefined,
                            actions
                          ]);
                    }
                    break;
                case 3 :
                    var id$2 = property.id;
                    Curry.app(sendActions, [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          (function (branch) {
                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                              var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                              AnalyticsRe.propertyConfigured(propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Presence", "UpdatePropertyPresenceGlobally", undefined, PropertyAbsenceViewHelpers.analyticsPresenceTypeBefore(computedMode), undefined, "AlwaysSent", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                            }),
                          undefined,
                          [[
                              {
                                NAME: "UpdatePropertyAbsence",
                                VAL: [
                                  id$2,
                                  "All",
                                  undefined
                                ]
                              },
                              {
                                propertyId: id$2,
                                propertyQuery: id$2
                              }
                            ]]
                        ]);
                    break;
                
              }
              Curry._1(pickMode, (function (param) {
                      return newMode;
                    }));
            }),
          options: Belt_Array.map(PropertyAbsenceViewHelpers.modes, (function (mode) {
                  switch (mode) {
                    case "Always" :
                    case "SometimesAll" :
                        break;
                    case "SometimesByEvent" :
                        if (Belt_List.length(events) === 1) {
                          return [
                                  {
                                    NAME: "Disabled",
                                    VAL: PropertyAbsenceViewHelpers.getModeString("SometimesByEvent")
                                  },
                                  PropertyAbsenceViewHelpers.getModeString("SometimesByEvent")
                                ];
                        }
                        break;
                    case "BySource" :
                        if (includeDependingOnSource) {
                          return [
                                  {
                                    NAME: "Label",
                                    VAL: (
                                      available ? "" : "⚡️ "
                                    ) + PropertyAbsenceViewHelpers.getModeString("BySource")
                                  },
                                  PropertyAbsenceViewHelpers.getModeString("BySource")
                                ];
                        } else {
                          return [
                                  {
                                    NAME: "Disabled",
                                    VAL: (
                                      available ? "" : "⚡️ "
                                    ) + PropertyAbsenceViewHelpers.getModeString("BySource")
                                  },
                                  PropertyAbsenceViewHelpers.getModeString("BySource")
                                ];
                        }
                    
                  }
                  return [
                          {
                            NAME: "Label",
                            VAL: PropertyAbsenceViewHelpers.getModeString(mode)
                          },
                          PropertyAbsenceViewHelpers.getModeString(mode)
                        ];
                })),
          value: tmp$4
        });
  }
  var tmp$5;
  if (computedMode === "BySource") {
    var allEventSources = PropertyAbsenceViewHelpers.getAllEventSources(sourcesList, events);
    var stateMatrix = PropertyAbsenceViewHelpers.getFullStateMatrix(events, sourcesList, property.absence);
    var allEvents = Belt_List.sortU(Belt_List.keepMapU(Belt_MapString.toList(stateMatrix), (function (param) {
                var sourceMap = param[1];
                var eventId = param[0];
                return Belt_Option.map(Belt_List.getBy(events, (function ($$event) {
                                  return $$event.id === eventId;
                                })), (function ($$event) {
                              return [
                                      $$event,
                                      sourceMap
                                    ];
                            }));
              })), (function (param, param$1) {
            return compare(param[0].name, param$1[0].name);
          }));
    var allEventsLength = Belt_List.length(allEvents);
    var mayCollapse = allEventsLength > 15;
    var eventsToTake = mayCollapse ? 5 : allEventsLength;
    var maybePartialEvents = mayCollapse ? Belt_List.take(allEvents, eventsToTake) : undefined;
    tmp$5 = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: tableBoxStyles(!mayCollapse)
            }, React.createElement("table", {
                  className: tableStyles(!mayCollapse)
                }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("td", {
                              className: tableHeaderCellStyles
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.light11,
                                  children: "Event Name"
                                })), Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Belt_List.head(events), (function ($$event) {
                                        return Belt_MapString.get(stateMatrix, $$event.id);
                                      })), (function (sourceIdMap) {
                                    return Belt_Array.map(Belt_Array.keepMap(Belt_MapString.keysToArray(sourceIdMap), (function (sourceId) {
                                                      return Belt_List.getBy(allEventSources, (function (source) {
                                                                    return source.id === sourceId;
                                                                  }));
                                                    })), (function (source) {
                                                  return React.createElement("td", {
                                                              className: tableHeaderCellStyles
                                                            }, React.createElement(SimpleTooltip.make, {
                                                                  delayIn: 0,
                                                                  position: "top",
                                                                  tooltip: Belt_Option.getWithDefault(source.name, "Untitled"),
                                                                  children: React.createElement($$Text.make, {
                                                                        size: "Small",
                                                                        weight: "Semi",
                                                                        singleLine: true,
                                                                        color: Styles.Color.light11,
                                                                        maxWidth: Css.px(120),
                                                                        title: Belt_Option.getWithDefault(source.name, "Untitled"),
                                                                        withWordBreak: false,
                                                                        children: Belt_Option.getWithDefault(source.name, "Untitled")
                                                                      })
                                                                }));
                                                }));
                                  })), null))), React.createElement("tbody", undefined, Belt_List.toArray(Belt_List.mapU(maybePartialEvents !== undefined && !showAllEvents ? maybePartialEvents : allEvents, (function (param) {
                                var $$event = param[0];
                                var pinnedValues = Belt_Option.getWithDefault(Belt_MapString.get(eventsToPinnedValuesMap, $$event.id), /* [] */0);
                                return React.createElement("tr", undefined, React.createElement("td", {
                                                className: tableNameCellStyles
                                              }, React.createElement(Link.make, {
                                                    path: Router.Link.addDrawerItem(undefined, {
                                                          NAME: "event",
                                                          VAL: [
                                                            $$event.id,
                                                            undefined,
                                                            undefined,
                                                            false
                                                          ]
                                                        }),
                                                    className: linkStyles,
                                                    children: React.createElement($$Text.make, {
                                                          size: "Small",
                                                          weight: "Semi",
                                                          singleLine: true,
                                                          color: Styles.Color.light12,
                                                          maxWidth: Css.px(250),
                                                          title: $$event.name,
                                                          withWordBreak: false,
                                                          children: $$event.name
                                                        })
                                                  })), Belt_MapString.valuesToArray(Belt_MapString.mapWithKey(param[1], (function (sourceId, state) {
                                                      var tmp;
                                                      var exit = 0;
                                                      if (pinnedValues && state !== "Unavailable") {
                                                        tmp = React.createElement($$Text.make, {
                                                              size: "Small",
                                                              singleLine: true,
                                                              color: Styles.Color.light10,
                                                              maxWidth: Css.px(200),
                                                              title: "Pinned to " + Belt_List.toArray(pinnedValues).join(", "),
                                                              withWordBreak: false,
                                                              children: "Pinned"
                                                            });
                                                      } else {
                                                        exit = 1;
                                                      }
                                                      if (exit === 1) {
                                                        tmp = React.createElement(Select.make, {
                                                              disabled: state === "Unavailable" || !available,
                                                              onSelect: (function (newState) {
                                                                  var eventId = $$event.id;
                                                                  var state = PropertyAbsenceViewHelpers.parseEventAndSourceStateString(newState);
                                                                  var tmp;
                                                                  switch (state) {
                                                                    case "Unavailable" :
                                                                    case "Never" :
                                                                        tmp = "NeverSent";
                                                                        break;
                                                                    case "Sometimes" :
                                                                        tmp = {
                                                                          TAG: "SometimesSent",
                                                                          _0: ""
                                                                        };
                                                                        break;
                                                                    case "Always" :
                                                                        tmp = undefined;
                                                                        break;
                                                                    
                                                                  }
                                                                  Curry.app(sendActions, [
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        (function (branch) {
                                                                            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                            var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                                                                            var match = property.absence;
                                                                            var tmp;
                                                                            tmp = match !== undefined && typeof match === "object" ? (
                                                                                match.TAG === "SometimesSent" ? "SometimesSent" : "DependingOnSource"
                                                                              ) : "AlwaysSent";
                                                                            AnalyticsRe.propertyConfigured(propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Presence", "UpdatePropertyPresenceByEventAndSource", undefined, tmp, undefined, "DependingOnSource", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                                          }),
                                                                        undefined,
                                                                        [[
                                                                            {
                                                                              NAME: "UpdatePropertyAbsence",
                                                                              VAL: [
                                                                                property.id,
                                                                                {
                                                                                  TAG: "EventSource",
                                                                                  _0: eventId,
                                                                                  _1: sourceId
                                                                                },
                                                                                tmp
                                                                              ]
                                                                            },
                                                                            {
                                                                              eventId: eventId,
                                                                              eventQuery: eventId,
                                                                              propertyId: property.id,
                                                                              propertyQuery: property.id
                                                                            }
                                                                          ]]
                                                                      ]);
                                                                }),
                                                              options: Belt_Array.map(state === "Unavailable" ? ["Unavailable"] : [
                                                                      "Never",
                                                                      "Sometimes",
                                                                      "Always"
                                                                    ], (function (mode) {
                                                                      return [
                                                                              {
                                                                                NAME: "Label",
                                                                                VAL: PropertyAbsenceViewHelpers.getEventAndSourceStateString(mode)
                                                                              },
                                                                              PropertyAbsenceViewHelpers.getEventAndSourceStateString(mode)
                                                                            ];
                                                                    })),
                                                              value: PropertyAbsenceViewHelpers.getEventAndSourceStateString(state)
                                                            });
                                                      }
                                                      return React.createElement("td", {
                                                                  className: tableSelectCellStyles
                                                                }, tmp);
                                                    }))));
                              })))))), maybePartialEvents !== undefined ? React.createElement(PropertyDetailsEvents$RestRow, {
                partialItemsCount: allEventsLength - Belt_List.length(maybePartialEvents) | 0,
                totalItemsCount: allEventsLength,
                showAllItems: showAllEvents,
                itemLabel: "event",
                onClick: (function (param) {
                    Curry._1(setShowAllEvents, (function (previous) {
                            return !previous;
                          }));
                  })
              }) : null);
  } else {
    var allEvents$1 = Belt_List.sortU(events, (function (a, b) {
            return compare(a.name, b.name);
          }));
    var allEventsLength$1 = Belt_List.length(allEvents$1);
    var mayCollapse$1 = allEventsLength$1 > 15;
    var eventsToTake$1 = mayCollapse$1 ? 5 : allEventsLength$1;
    var maybePartialEvents$1 = mayCollapse$1 ? Belt_List.take(allEvents$1, eventsToTake$1) : undefined;
    tmp$5 = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: nameRowStyles
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: "Event name"
                })), Belt_Array.mapU(Belt_List.toArray(maybePartialEvents$1 !== undefined && !showAllEvents ? maybePartialEvents$1 : allEvents$1), (function ($$event) {
                var pinnedValues = Belt_Option.getWithDefault(Belt_MapString.get(eventsToPinnedValuesMap, $$event.id), /* [] */0);
                var tmp;
                if (pinnedValues === /* [] */0) {
                  var match = Belt_MapString.get(eventSourcesMap, $$event.id);
                  var eventSources = match !== undefined ? (
                      typeof match === "object" ? match.VAL : (
                          match === "None" ? /* [] */0 : sourcesList
                        )
                    ) : /* [] */0;
                  var value;
                  if (eventSources === /* [] */0) {
                    value = "Unavailable";
                  } else {
                    var match$1 = property.absence;
                    if (match$1 !== undefined && typeof match$1 === "object") {
                      if (match$1.TAG === "SometimesSent") {
                        value = "Sometimes";
                      } else {
                        var match$2 = Belt_MapString.get(match$1._0, $$event.id);
                        if (match$2 !== undefined) {
                          if (match$2.TAG === "AllSources") {
                            var tmp$1 = match$2._0;
                            value = typeof tmp$1 !== "object" ? "Never" : "Sometimes";
                          } else {
                            var sourceAbsences = match$2._0;
                            var states = Belt_List.map(eventSources, (function (source) {
                                    var match = Belt_MapString.get(sourceAbsences, source.id);
                                    if (match !== undefined) {
                                      if (typeof match !== "object") {
                                        return "Never";
                                      } else {
                                        return "Sometimes";
                                      }
                                    } else {
                                      return "Always";
                                    }
                                  }));
                            value = Belt_List.every(states, (function (state) {
                                    return state === "Always";
                                  })) ? "Always" : (
                                Belt_List.every(states, (function (state) {
                                        return state === "Sometimes";
                                      })) ? "Sometimes" : (
                                    Belt_List.every(states, (function (state) {
                                            return state === "Never";
                                          })) ? "Never" : Pervasives.failwith("Absence cannot be Mixed when the mode is Sometimes")
                                  )
                              );
                          }
                        } else {
                          value = "Always";
                        }
                      }
                    } else {
                      value = "Always";
                    }
                  }
                  tmp = React.createElement(Select.make, {
                        disabled: !available || !viewerCanEdit || value === "Unavailable",
                        onSelect: (function (newState) {
                            var eventId = $$event.id;
                            var state = PropertyAbsenceViewHelpers.parseEventAndSourceStateString(newState);
                            var tmp;
                            switch (state) {
                              case "Never" :
                                  tmp = "NeverSent";
                                  break;
                              case "Sometimes" :
                                  tmp = {
                                    TAG: "SometimesSent",
                                    _0: ""
                                  };
                                  break;
                              case "Unavailable" :
                              case "Always" :
                                  tmp = undefined;
                                  break;
                              
                            }
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                      var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                                      var match = property.absence;
                                      var tmp;
                                      tmp = match !== undefined && typeof match === "object" ? (
                                          match.TAG === "SometimesSent" ? "SometimesSent" : "DependingOnSource"
                                        ) : "AlwaysSent";
                                      AnalyticsRe.propertyConfigured(propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Presence", "UpdatePropertyPresenceByEvent", undefined, tmp, undefined, "DependingOnSource", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schema.schemaId);
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdatePropertyAbsence",
                                        VAL: [
                                          property.id,
                                          {
                                            TAG: "Event",
                                            _0: eventId
                                          },
                                          tmp
                                        ]
                                      },
                                      {
                                        eventId: eventId,
                                        eventQuery: eventId,
                                        propertyId: property.id,
                                        propertyQuery: property.id
                                      }
                                    ]]
                                ]);
                            if (state !== "Always") {
                              return ;
                            }
                            var stateMatrix = PropertyAbsenceViewHelpers.getFullStateMatrix(events, sourcesList, property.absence);
                            var simplestModeAfterChange = PropertyAbsenceViewHelpers.getSimplestModeForStateMatrix(Belt_MapString.remove(stateMatrix, eventId), property.absence);
                            if (simplestModeAfterChange === "Always") {
                              return Curry._1(pickMode, (function (param) {
                                            return "Always";
                                          }));
                            }
                            
                          }),
                        options: value === "Unavailable" ? [[
                              {
                                NAME: "Label",
                                VAL: "Never sent"
                              },
                              PropertyAbsenceViewHelpers.getEventAndSourceStateString("Unavailable")
                            ]] : Belt_Array.concat([
                                [
                                  {
                                    NAME: "Label",
                                    VAL: "Always sent"
                                  },
                                  PropertyAbsenceViewHelpers.getEventAndSourceStateString("Always")
                                ],
                                [
                                  {
                                    NAME: "Label",
                                    VAL: "Sometimes sent"
                                  },
                                  PropertyAbsenceViewHelpers.getEventAndSourceStateString("Sometimes")
                                ]
                              ], value === "Never" ? [[
                                    {
                                      NAME: "Label",
                                      VAL: "Never sent"
                                    },
                                    PropertyAbsenceViewHelpers.getEventAndSourceStateString("Never")
                                  ]] : []),
                        value: PropertyAbsenceViewHelpers.getEventAndSourceStateString(value)
                      });
                } else {
                  tmp = React.createElement($$Text.make, {
                        size: "Small",
                        singleLine: true,
                        color: Styles.Color.light10,
                        maxWidth: Css.px(200),
                        title: "Pinned to " + Belt_List.toArray(pinnedValues).join(", "),
                        withWordBreak: false,
                        children: null
                      }, "Pinned to ", Belt_Array.map(Belt_List.toArray(pinnedValues), (function (prim) {
                              return prim;
                            })));
                }
                var tmp$2;
                if (viewerCanEdit) {
                  var tmp$3;
                  if (property.sendAs === "SystemProperty") {
                    tmp$3 = [];
                  } else {
                    var tmp$4;
                    if (pinnedValues === /* [] */0) {
                      tmp$4 = "Pin Property Value";
                    } else {
                      var match$3 = AvoLimits.PinnedProperties.computeAvailability(workspace);
                      tmp$4 = "Update Pinned Value" + (
                        match$3 === "Available" ? "" : " ⚡️"
                      );
                    }
                    tmp$3 = [{
                        NAME: "Option",
                        VAL: {
                          label: tmp$4,
                          onClick: (function (param) {
                              var match = AvoLimits.PinnedProperties.computeAvailability(workspace);
                              Curry._1(globalSend, {
                                    TAG: "OpenModal",
                                    _0: match === "AvailableDuringTrial" || match === "Available" ? ({
                                          NAME: "PinPropertyValue",
                                          VAL: [
                                            $$event.id,
                                            property.id,
                                            undefined
                                          ]
                                        }) : ({
                                          NAME: "BillingPrompt",
                                          VAL: "PinnedProperties"
                                        })
                                  });
                            })
                        }
                      }];
                  }
                  tmp$2 = React.createElement(ContextMenu.make, {
                        options: Belt_Array.concatMany([
                              [{
                                  NAME: "WarningOption",
                                  VAL: {
                                    label: "Remove property from event",
                                    onClick: (function (param) {
                                        var eventId = $$event.id;
                                        var eventName = $$event.name;
                                        if (window.confirm("Are you sure you want to stop sending this property with the " + eventName + " event?")) {
                                          return Curry.app(sendActions, [
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      (function (branch) {
                                                          var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                          var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                                                          AnalyticsRe.propertyRemoved(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, eventId, eventName, "PropertyDetails", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                        }),
                                                      undefined,
                                                      [[
                                                          {
                                                            NAME: "RemovePropertyRefV3",
                                                            VAL: [
                                                              eventId,
                                                              property.id
                                                            ]
                                                          },
                                                          {
                                                            eventId: eventId,
                                                            eventQuery: eventId,
                                                            propertyId: property.id,
                                                            propertyQuery: property.id
                                                          }
                                                        ]]
                                                    ]);
                                        }
                                        
                                      })
                                  }
                                }],
                              tmp$3
                            ])
                      });
                } else {
                  tmp$2 = null;
                }
                return React.createElement("div", {
                            key: $$event.id,
                            className: listItemStyles
                          }, React.createElement("div", undefined, React.createElement(Link.make, {
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "event",
                                          VAL: [
                                            $$event.id,
                                            undefined,
                                            undefined,
                                            false
                                          ]
                                        }),
                                    className: linkStyles,
                                    children: React.createElement($$Text.make, {
                                          size: "Medium",
                                          weight: "Semi",
                                          singleLine: true,
                                          color: Styles.Color.light12,
                                          maxWidth: Css.px(380),
                                          withWordBreak: false,
                                          children: $$event.name
                                        })
                                  }), Belt_List.keepU($$event.includeSources, (function (param) {
                                      return Curry._2(TrackingPlanMappedModel.Sources.has, model.sources, param.id);
                                    })) === /* [] */0 ? React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.marginTop(Css.px(-6)),
                                            tl: /* [] */0
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          weight: "Semi",
                                          color: Styles.Color.darkOrange,
                                          children: "Event is not sent from a source"
                                        })) : null), React.createElement("div", {
                                className: listItemConfigStyles
                              }, available ? null : React.createElement("div", {
                                      className: planLightningStyles
                                    }, React.createElement(PlanLightning.make, {
                                          tooltipText: "Requires Plan Upgrade"
                                        })), tmp, React.createElement(Spacer.make, {
                                    width: 4
                                  }), tmp$2));
              })), maybePartialEvents$1 !== undefined ? React.createElement(PropertyDetailsEvents$RestRow, {
                partialItemsCount: allEventsLength$1 - Belt_List.length(maybePartialEvents$1) | 0,
                totalItemsCount: allEventsLength$1,
                showAllItems: showAllEvents,
                itemLabel: "event",
                onClick: (function (param) {
                    Curry._1(setShowAllEvents, (function (previous) {
                            return !previous;
                          }));
                  })
              }) : null);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: headerStyles
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("column"),
                              tl: {
                                hd: Css.gap(Css.px(0)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement($$Text.make, {
                              element: "H1",
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.light11,
                              children: "Attached to " + AvoUtils.plural(undefined, undefined, Belt_List.length(events), "event")
                            }), React.createElement(Tooltip.WithAnalytics.make, {
                              fromItem: {
                                itemId: property.id,
                                itemName: property.name,
                                itemType: "Property"
                              },
                              title: "Sending the Property to Events",
                              children: React.createElement(Tooltip.Content.make, {
                                    children: React.createElement(Markdown.make, {
                                          source: Tooltips.sentInEvents
                                        })
                                  })
                            })), tmp), React.createElement(Spacer.make, {
                      width: 8
                    }), tmp$3), tmp$5);
}

var make = PropertyDetailsEvents;

export {
  make ,
}
/* containerStyles Not a pure module */
