// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Changes from "../../shared/Changes.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as FlattenVariantsUseCase from "../../model/src/variants/flattenVariantsUseCase.mjs";

function useChanges(fromModel, toModel, onWorkerError) {
  var match = React.useReducer((function (state, action) {
          if (typeof action === "object") {
            return {
                    changes: action.VAL,
                    status: "loaded"
                  };
          } else {
            return {
                    changes: state.changes,
                    status: "loading"
                  };
          }
        }), {
        changes: Changes.empty,
        status: "loading"
      });
  var reduceChangeState = match[1];
  var fromModelWithFlattenedEventVariants = React.useMemo((function (param) {
          return Belt_Option.map(fromModel, FlattenVariantsUseCase.flatten);
        }), [fromModel]);
  var toModelWithFlattenedEventVariants = React.useMemo((function (param) {
          return Belt_Option.map(toModel, FlattenVariantsUseCase.flatten);
        }), [toModel]);
  React.useEffect((function (param) {
          if (fromModelWithFlattenedEventVariants !== undefined && toModelWithFlattenedEventVariants !== undefined) {
            Curry._1(reduceChangeState, "loading");
            $$Promise.$$catch(Changes.getChanges(fromModelWithFlattenedEventVariants, toModelWithFlattenedEventVariants).then(function (changes) {
                      if (typeof changes === "object") {
                        return Curry._1(reduceChangeState, {
                                    NAME: "loaded",
                                    VAL: changes.VAL
                                  });
                      }
                      
                    }), (function (error) {
                    Curry._1(onWorkerError, error);
                    return Promise.resolve();
                  }));
          }
          
        }), [
        fromModelWithFlattenedEventVariants,
        toModelWithFlattenedEventVariants
      ]);
  return match[0];
}

export {
  useChanges ,
}
/* react Not a pure module */
