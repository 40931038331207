// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Quill from "./externals/quill.mjs";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as DidMount from "./DidMount.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import ReactQuill from "react-quill";
import * as ProfilePhoto from "./ProfilePhoto.mjs";
import * as IconEventType from "./IconEventType.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as Server from "react-dom/server";
import QuillDeltaToHtml from "quill-delta-to-html";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

import 'react-quill/dist/quill.snow.css'
;

import '../../quill-mention/src/quill.mention.css'
;

Curry._2(Css.$$global, " .quill", {
      hd: Css.flex({
            NAME: "num",
            VAL: 1.0
          }),
      tl: {
        hd: Css.borderWidth(Css.px(0)),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: {
            hd: Css.height(Css.pct(100)),
            tl: {
              hd: Css.overflow("visible"),
              tl: /* [] */0
            }
          }
        }
      }
    });

Curry._2(Css.$$global, " .ql-container.ql-snow", {
      hd: Css.borderWidth(Css.px(0)),
      tl: {
        hd: Css.height(Css.pct(100)),
        tl: /* [] */0
      }
    });

Curry._2(Css.$$global, " .ql-snow .ql-stroke", {
      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-snow.ql-toolbar button:hover .ql-stroke", {
      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.darkBlue),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-snow.ql-toolbar button.ql-active .ql-stroke", {
      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.deepBlue),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-snow .ql-fill", {
      hd: Css_Legacy_Core.SVG.fill(Styles.Color.light07),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-snow.ql-toolbar button:hover .ql-fill", {
      hd: Css_Legacy_Core.SVG.fill(Styles.Color.darkBlue),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-snow.ql-toolbar button.ql-active .ql-fill", {
      hd: Css_Legacy_Core.SVG.fill(Styles.Color.deepBlue),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-snow.ql-toolbar:after", {
      hd: Css.display("none"),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-mention-list-container", {
      hd: Css.zIndex(Styles.ZIndex.aboveAll),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.border(Css.px(1), "solid", Styles.Color.light02),
          tl: {
            hd: Css.boxShadow(Styles.Shadow.box),
            tl: {
              hd: Css.overflow("hidden"),
              tl: /* [] */0
            }
          }
        }
      }
    });

Curry._2(Css.$$global, " .ql-mention-list-item", {
      hd: Css.padding2(Css.px(0), Css.px(5)),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-mention-list-item.selected", {
      hd: Css.backgroundColor(Styles.Color.lightBlue),
      tl: /* [] */0
    });

Curry._2(Css.$$global, " .ql-tooltip", {
      hd: Css.zIndex(Styles.ZIndex.aboveAll),
      tl: /* [] */0
    });

function focusInput(inputRef) {
  var inputRef$1 = inputRef.current;
  if (inputRef$1 == null) {
    console.log("no ref");
  } else {
    inputRef$1.focus();
  }
}

function isEmpty(delta) {
  if (new QuillDeltaToHtml(delta.ops).convert() === "") {
    return true;
  } else {
    return new QuillDeltaToHtml(delta.ops).convert() === "<p><br/></p>";
  }
}

function QuillEditor(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var onSubmit = Props.onSubmit;
  var users = Props.users;
  var schemaId = Props.schemaId;
  var availability = Props.availability;
  var onNeedsUpgrade = Props.onNeedsUpgrade;
  var mini = Props.mini;
  var id = Props.id;
  var autoFocusOpt = Props.autoFocus;
  var placeholder = Props.placeholder;
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var events = ModelStore.Mapped.useEvents();
  var match = React.useState(function (param) {
        return false;
      });
  var setFocus = match[1];
  var hasFocus = match[0];
  var match$1 = React.useState(function (param) {
        return !autoFocus;
      });
  var setAutoFocused = match$1[1];
  var autoFocused = match$1[0];
  var inputRef = React.useRef(null);
  var bottomRef = React.useRef(null);
  var match$2 = React.useState(function (param) {
        return {
                source: (function (searchTerm, renderResults) {
                    Curry._2(renderResults, Fuzzaldrin.filter(Belt_Array.concat(Belt_Array.map(users, (function (user) {
                                        var match = user.name;
                                        var match$1 = user.email;
                                        return {
                                                id: user.id,
                                                schemaId: schemaId,
                                                value: (match == null) ? (
                                                    (match$1 == null) ? AvoConfig.anonymousUserDisplayName : match$1
                                                  ) : (
                                                    (match$1 == null) ? match : match + " (" + match$1 + ")"
                                                  ),
                                                type: "user"
                                              };
                                      })), Curry._2(TrackingPlanMappedModel.Events.mapToArray, events, (function ($$event) {
                                        return {
                                                id: $$event.id,
                                                schemaId: schemaId,
                                                value: $$event.name,
                                                type: "event"
                                              };
                                      }))), searchTerm, {
                              key: "value",
                              maxResults: 10
                            }), searchTerm);
                  }),
                renderItem: (function (item) {
                    var match = item.type;
                    var tmp;
                    switch (match) {
                      case "event" :
                          tmp = React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.padding2(Css.px(0), Css.px(8)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(IconEventType.make, {})), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginLeft(Css.px(3)),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.regular),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.regular),
                                              tl: {
                                                hd: Css.textOverflow("ellipsis"),
                                                tl: {
                                                  hd: Css.whiteSpace("nowrap"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, item.value));
                          break;
                      case "user" :
                          var user = Belt_Array.get(Belt_Array.keep(users, (function (user) {
                                      return user.id === item.id;
                                    })), 0);
                          tmp = React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: /* [] */0
                                      }
                                    })
                              }, user !== undefined ? React.createElement(ProfilePhoto.make, {
                                      user: user
                                    }) : null, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginLeft(Css.px(5)),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.regular),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.regular),
                                              tl: {
                                                hd: Css.textOverflow("ellipsis"),
                                                tl: {
                                                  hd: Css.whiteSpace("nowrap"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, item.value));
                          break;
                      default:
                        tmp = null;
                    }
                    return Server.renderToString(React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.maxWidth(Css.pct(100)),
                                          tl: {
                                            hd: Css.overflow("hidden"),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, tmp));
                  })
              };
      });
  React.useEffect((function (param) {
          var match = inputRef.current;
          if (!(match == null) && !autoFocused) {
            focusInput(inputRef);
            Curry._1(setAutoFocused, (function (param) {
                    return true;
                  }));
          }
          
        }), [inputRef]);
  var collapsed = !hasFocus && isEmpty(value);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  ref: bottomRef,
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.bottom(Css.px(0)),
                          tl: {
                            hd: Css.width(Css.px(0)),
                            tl: {
                              hd: Css.height(Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.pct(100)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                tl: {
                                  hd: Css.overflow("hidden"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }),
                  id: "editor-container"
                }, React.createElement(ReactQuill, {
                      onChange: (function (_html, _delta, _source, editor) {
                          Curry._1(onChange, editor.getContents());
                        }),
                      value: value,
                      modules: {
                        toolbar: {
                          container: "#toolbar-container-" + id
                        },
                        magicUrl: true,
                        mention: match$2[0]
                      },
                      onFocus: (function () {
                          Curry._1(setFocus, (function (param) {
                                  return true;
                                }));
                        }),
                      onBlur: (function () {
                          Curry._1(setFocus, (function (param) {
                                  return false;
                                }));
                        }),
                      placeholder: Belt_Option.getWithDefault(placeholder, "Write a comment..."),
                      readOnly: !(availability === "AvailableDuringTrial" || availability === "Available"),
                      onKeyDown: (function ($$event) {
                          var match = $$event.metaKey;
                          var match$1 = $$event.key;
                          if (match && match$1 === "Enter") {
                            return Curry._1(onSubmit, undefined);
                          }
                          
                        }),
                      ref: inputRef
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.height(Css.px(collapsed ? 0 : 45)),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "all"),
                              tl: /* [] */0
                            }
                          })
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.right(Css.px(0)),
                          tl: {
                            hd: Css.bottom(Css.px(0)),
                            tl: {
                              hd: Css.left(Css.px(0)),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.justifyContent("spaceBetween"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.padding(Css.px(0)),
                                      tl: {
                                        hd: Css.margin(Css.px(0)),
                                        tl: {
                                          hd: Css.important(Css.borderWidth(Css.px(0))),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  id: "toolbar-container-" + id
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.opacity(collapsed ? 0.0 : 1.0),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "all"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("button", {
                          className: "ql-bold"
                        }), React.createElement("button", {
                          className: "ql-italic"
                        }), React.createElement("button", {
                          className: "ql-underline"
                        }), React.createElement("button", {
                          className: "ql-list",
                          value: "ordered"
                        }), React.createElement("button", {
                          className: "ql-list",
                          value: "bullet"
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.opacity(collapsed ? 0.0 : 1.0),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "all"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("button", {
                          className: Curry._1(Css.merge, {
                                hd: Curry._1(Css.style, Belt_List.map(Styles.buttonReset, Css.important)),
                                tl: {
                                  hd: Curry._1(Css.style, Belt_List.map({
                                            hd: Css.borderRadius(Styles.Border.radius),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.avoPink),
                                              tl: {
                                                hd: Css.color(Styles.Color.white),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css.backgroundColor(Styles.Color.avoPinkShade2),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: {
                                                    hd: Css.paddingTop(Css.px(6)),
                                                    tl: {
                                                      hd: Css.paddingRight(Css.px(8)),
                                                      tl: {
                                                        hd: Css.paddingBottom(Css.px(6)),
                                                        tl: {
                                                          hd: Css.paddingLeft(Css.px(8)),
                                                          tl: {
                                                            hd: Css.fontSize(Css.px(14)),
                                                            tl: {
                                                              hd: Css.height("auto"),
                                                              tl: {
                                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                tl: {
                                                                  hd: Css.marginRight(Css.px(72)),
                                                                  tl: {
                                                                    hd: Css.width("auto"),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }, Css.important)),
                                  tl: /* [] */0
                                }
                              }),
                          onClick: (function (param) {
                              if (availability === "AvailableDuringTrial" || availability === "Available") {
                                return Curry._1(onSubmit, undefined);
                              } else {
                                return Curry._1(onNeedsUpgrade, undefined);
                              }
                            })
                        }, availability === "Available" ? null : React.createElement(Icon.make, {
                                type_: "lightning",
                                size: "small"
                              }), "Comment"))), hasFocus ? React.createElement(DidMount.make, {
                    didMount: (function (param) {
                        if (!mini) {
                          setTimeout((function (param) {
                                  var bottomRef$1 = bottomRef.current;
                                  if (!(bottomRef$1 == null)) {
                                    bottomRef$1.scrollIntoView({
                                          block: "end",
                                          inline: "nearest",
                                          behavior: "smooth"
                                        });
                                    return ;
                                  }
                                  
                                }), Styles.Duration.$$default | 0);
                          return ;
                        }
                        
                      })
                  }) : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(0)),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: {
                                    hd: Css.backgroundColor("transparent"),
                                    tl: {
                                      hd: Css.cursor("text"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }),
                    onClick: (function (param) {
                        focusInput(inputRef);
                        if (availability === "Unavailable") {
                          return Curry._1(onNeedsUpgrade, undefined);
                        }
                        
                      })
                  }));
}

var make = QuillEditor;

export {
  focusInput ,
  isEmpty ,
  make ,
}
/*  Not a pure module */
