// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as ContextMenu from "./ContextMenu.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as PortalMenu_deprecated from "./PortalMenu_deprecated.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";

var wrapper = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: {
        hd: Css.paddingRight(Css.px(12)),
        tl: {
          hd: Css.flexShrink(0.0),
          tl: /* [] */0
        }
      }
    });

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var pillWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.flexWrap("wrap"),
          tl: {
            hd: Css.marginTop(Css.px(4)),
            tl: {
              hd: Css.marginLeft(Css.px(-2)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function pillStyle(fill) {
  return Curry._1(Css.style, {
              hd: Css.padding2(Css.px(2), Css.px(8)),
              tl: {
                hd: Css.borderRadius(Css.px(16)),
                tl: {
                  hd: Css.backgroundColor(fill ? Styles.Color.light04 : Styles.Color.white),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.width("fitContent"),
                            tl: {
                              hd: Css.marginRight(Css.px(4)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var listStyles = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(16)),
      tl: {
        hd: Css.paddingBottom(Css.px(16)),
        tl: {
          hd: Css.padding("zero"),
          tl: {
            hd: Css.margin("zero"),
            tl: {
              hd: Css.listStyleType("none"),
              tl: {
                hd: Css.textIndent("zero"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var listItemStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.height(Css.px(30)),
          tl: {
            hd: Css.userSelect("none"),
            tl: {
              hd: Css.paddingLeft(Css.px(14)),
              tl: {
                hd: Css.paddingRight(Css.px(16)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var portalWrapper = Curry._1(Css.style, {
      hd: Css.width(Css.px(270)),
      tl: {
        hd: Css.boxShadow(Styles.Shadow.subtle),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: /* [] */0
          }
        }
      }
    });

var portalHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.paddingLeft(Css.px(16)),
          tl: {
            hd: Css.padding(Css.px(16)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
              tl: /* [] */0
            }
          }
        }
      }
    });

var S = {
  listItemHeight: 30,
  wrapper: wrapper,
  header: header,
  pillWrapper: pillWrapper,
  pillStyle: pillStyle,
  listStyles: listStyles,
  listItemStyles: listItemStyles,
  portalWrapper: portalWrapper,
  portalHeader: portalHeader
};

function TriggerModalSources(Props) {
  var currentTrigger = Props.currentTrigger;
  var $$event = Props.event;
  var inDraftMode = Props.inDraftMode;
  var modelSources = Props.modelSources;
  var onRemove = Props.onRemove;
  var onUpdate = Props.onUpdate;
  var sources = Props.sources;
  var viewerCanEdit = Props.viewerCanEdit;
  var buttonRef = React.useRef(null);
  var match = React.useState(function (param) {
        return false;
      });
  var setShowEdit = match[1];
  var eventSources = AvoConfig.determineSendingToSources(modelSources, $$event);
  var sourceChoices = typeof eventSources === "object" ? eventSources.VAL : (
      eventSources === "None" ? /* [] */0 : modelSources
    );
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace();
  var tmp;
  tmp = typeof sources !== "object" ? React.createElement("span", {
          key: "source-independent",
          className: pillStyle(false)
        }, React.createElement($$Text.make, {
              element: "Span",
              size: "Small",
              weight: "Semi",
              color: Styles.Color.light08,
              children: (
                isGlobalWorkspace ? "Workspace" : "Source"
              ) + " Independent"
            })) : Belt_Array.map(Belt_Array.keepMap(sources._0, (function (sourceId) {
                return Belt_List.getBy(modelSources, (function (modelSource) {
                              return modelSource.id === sourceId;
                            }));
              })), (function (source) {
            return React.createElement("span", {
                        key: source.id,
                        className: pillStyle(true)
                      }, React.createElement($$Text.make, {
                            element: "Span",
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light12,
                            children: AvoConfig.getSourceName(source)
                          }));
          }));
  return React.createElement("div", {
              className: wrapper
            }, React.createElement("div", {
                  className: header
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light10,
                      children: isGlobalWorkspace ? "Workspaces" : "Sources"
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), viewerCanEdit ? React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            Curry._1(setShowEdit, (function (state) {
                                    return !state;
                                  }));
                          }),
                        style: "Blue",
                        size: "Small",
                        children: "Edit",
                        ref: buttonRef
                      }) : null, React.createElement(Spacer.make, {
                      width: 4,
                      grow: 1.0
                    }), inDraftMode ? null : React.createElement(ContextMenu.make, {
                        withBorder: true,
                        options: Belt_Array.concat([{
                                NAME: "Option",
                                VAL: {
                                  label: "Copy Link",
                                  onClick: (function (param) {
                                      CopyTextToClipboard(Router.Link.getSimple());
                                    })
                                }
                              }], viewerCanEdit ? [{
                                  NAME: "WarningOption",
                                  VAL: {
                                    label: "Remove Trigger",
                                    onClick: (function (param) {
                                        Curry._1(onRemove, currentTrigger);
                                      })
                                  }
                                }] : [])
                      })), React.createElement(PortalMenu_deprecated.make, {
                  position: "Bottom",
                  visible: match[0],
                  onClose: (function (param) {
                      Curry._1(setShowEdit, (function (param) {
                              return false;
                            }));
                    }),
                  referenceItemRef: buttonRef,
                  children: React.createElement("aside", {
                        className: portalWrapper
                      }, React.createElement("header", {
                            className: portalHeader
                          }, React.createElement($$Text.make, {
                                element: "H1",
                                size: "Medium",
                                weight: "Medium",
                                color: Styles.Color.light12,
                                children: isGlobalWorkspace ? "Workspaces" : "Sources"
                              }), React.createElement(Spacer.make, {
                                height: 8
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.light10,
                                children: "Choose the " + (
                                  isGlobalWorkspace ? "workspaces" : "sources"
                                ) + " which are appropriate for this trigger"
                              })), React.createElement("ul", {
                            className: listStyles
                          }, sourceChoices === /* [] */0 ? React.createElement("div", {
                                  className: listItemStyles
                                }, React.createElement($$Text.make, {
                                      size: "Medium",
                                      children: "⚠️"
                                    }), React.createElement(Spacer.make, {
                                      width: 8
                                    }), React.createElement($$Text.make, {
                                      size: "Small",
                                      color: Styles.Color.light10,
                                      children: "Add " + (
                                        isGlobalWorkspace ? "workspaces" : "sources"
                                      ) + " to your event to configure them for this trigger"
                                    })) : null, Belt_List.toArray(Belt_List.mapU(sourceChoices, (function (source) {
                                      var tmp;
                                      tmp = typeof sources !== "object" ? false : Belt_Array.some(sources._0, (function (sourceId) {
                                                return sourceId === source.id;
                                              }));
                                      return React.createElement("li", {
                                                  key: source.id,
                                                  className: listItemStyles
                                                }, React.createElement($$Text.make, {
                                                      size: "Medium",
                                                      weight: "Medium",
                                                      singleLine: true,
                                                      color: Styles.Color.light10,
                                                      children: AvoConfig.getSourceName(source)
                                                    }), React.createElement(Spacer.make, {
                                                      width: 8,
                                                      grow: 1.0
                                                    }), React.createElement(Switch.make, {
                                                      value: tmp,
                                                      onChange: (function (value) {
                                                          Curry._2(onUpdate, source.id, value);
                                                        }),
                                                      size: "Small"
                                                    }));
                                    })))))
                }), React.createElement("div", {
                  className: pillWrapper
                }, tmp));
}

var make = TriggerModalSources;

export {
  S ,
  make ,
}
/* wrapper Not a pure module */
