// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SheetTypes from "./SheetTypes.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import ReactDraggable from "react-draggable";
import * as OrganizationContext from "./OrganizationContext.mjs";

var sheetHeaderStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.width(Css.pct(100.0)),
            tl: {
              hd: Css.overflow("hidden"),
              tl: {
                hd: Css.height(Css.px(40)),
                tl: {
                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.unsafe("scrollbarWidth", "none"),
                      tl: {
                        hd: Css.selector("::-webkit-scrollbar", {
                              hd: Css.width(Css.px(0)),
                              tl: {
                                hd: Css.height(Css.px(0)),
                                tl: /* [] */0
                              }
                            }),
                        tl: {
                          hd: Css.hover({
                                hd: Css.selector(" span", {
                                      hd: Css.opacity(1),
                                      tl: {
                                        hd: Css.pointerEvents("auto"),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function sheetHeaderStickyStyles(customWidth, groupedByCategory) {
  return Curry._1(Css.style, {
              hd: Css.zIndex(1),
              tl: {
                hd: Css.textTransform("uppercase"),
                tl: {
                  hd: Css.position("sticky"),
                  tl: {
                    hd: Css.top(Css.px(0)),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.flexShrink(0.0),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.height(Css.pct(100)),
                              tl: {
                                hd: Css.width(Css.px(groupedByCategory ? customWidth + 16 | 0 : customWidth)),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(groupedByCategory ? 52 : 36)),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.white),
                                    tl: {
                                      hd: Css.marginRight(Css.px(-1)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function sheetHeaderTitleStyles(customWidth) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.paddingLeft(Css.px(12)),
                  tl: {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(customWidth)),
                      tl: {
                        hd: Css.selector(":nth-child(2)", {
                              hd: Css.width(Css.px(customWidth + 1 | 0)),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.flexShrink(0.0),
                          tl: {
                            hd: Css.height(Css.pct(100)),
                            tl: {
                              hd: Css.textTransform("uppercase"),
                              tl: {
                                hd: Css.lastOfType({
                                      hd: Css.width(Css.pct(100)),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var defaultClassName = Curry._1(Css.style, {
      hd: Css.hover({
            hd: Css.cursor("colResize"),
            tl: /* [] */0
          }),
      tl: /* [] */0
    });

var columnDragger = Curry._1(Css.style, {
      hd: Css.height(Css.pct(100)),
      tl: {
        hd: Css.width(Css.px(7)),
        tl: {
          hd: Css.marginRight(Css.px(-3)),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.selector(" > span", {
                      hd: Css.backgroundColor(Styles.Color.light04),
                      tl: {
                        hd: Css.width(Css.px(1)),
                        tl: /* [] */0
                      }
                    }),
                tl: {
                  hd: Css.hover({
                        hd: Css.selector(" > span", {
                              hd: Css.backgroundColor(Styles.Color.darkBlue),
                              tl: {
                                hd: Css.width(Css.px(3)),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var rulerStyles = Curry._1(Css.style, {
      hd: Css.zIndex(5),
      tl: {
        hd: Css.position("fixed"),
        tl: {
          hd: Css.top(Css.px(-9999)),
          tl: {
            hd: Css.left(Css.px(-9999)),
            tl: {
              hd: Css.height(Css.vh(100)),
              tl: {
                hd: Css.width(Css.px(3)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.darkBlue),
                  tl: {
                    hd: Css.pointerEvents("none"),
                    tl: {
                      hd: Css.userSelect("none"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Style = {
  sheetHeaderStyles: sheetHeaderStyles,
  sheetHeaderStickyStyles: sheetHeaderStickyStyles,
  sheetHeaderTitleStyles: sheetHeaderTitleStyles,
  defaultClassName: defaultClassName,
  columnDragger: columnDragger,
  rulerStyles: rulerStyles
};

var make = React.forwardRef(function (Props, ref_) {
      var columns = Props.columns;
      var setColumns = Props.setColumns;
      var view = Props.view;
      var onWidthChange = Props.onWidthChange;
      var match = React.useState(function (param) {
            
          });
      var setColumnBeingDragged = match[1];
      var columnBeingDragged = match[0];
      var draggerRefs = Js_dict.fromArray(Belt_Array.map(columns, (function (c) {
                  return [
                          SheetTypes.columnTypeToJs(c.columnType),
                          React.useRef(null)
                        ];
                })));
      var rulerRef = React.useRef(null);
      var handleRulerMove = function (param) {
        var match = rulerRef.current;
        var match$1 = Belt_Option.flatMap(columnBeingDragged, (function (columnBeingDragged) {
                return Caml_option.nullable_to_opt(draggerRefs[SheetTypes.columnTypeToJs(columnBeingDragged.columnType)].current);
              }));
        if (match == null) {
          return ;
        }
        if (match$1 === undefined) {
          return ;
        }
        var boundingRect = Caml_option.valFromOption(match$1).getBoundingClientRect();
        var left = boundingRect.left + 2 | 0;
        match.style.top = String(0) + "px";
        match.style.left = String(left) + "px";
      };
      React.useEffect((function (param) {
              if (columnBeingDragged !== undefined) {
                window.addEventListener("mousemove", handleRulerMove);
              } else {
                window.removeEventListener("mousemove", handleRulerMove);
                var rulerElement = rulerRef.current;
                if (!(rulerElement == null)) {
                  rulerElement.style.top = String(-9999) + "px";
                  rulerElement.style.left = String(-9999) + "px";
                }
                
              }
              return (function (param) {
                        window.removeEventListener("mousemove", handleRulerMove);
                      });
            }), [columnBeingDragged]);
      var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace();
      var tmp = {
        className: sheetHeaderStyles
      };
      var tmp$1 = Belt_Option.map((ref_ == null) ? undefined : Caml_option.some(ref_), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      return React.createElement(React.Fragment, undefined, React.createElement("div", tmp, Belt_Array.mapWithIndexU(columns, (function (index, column) {
                            if (!column.visible) {
                              return null;
                            }
                            var tmp;
                            if (index < (columns.length - 1 | 0) && column.visible) {
                              var tmp$1 = {
                                axis: "x",
                                onStart: (function (param, param$1) {
                                    Curry._1(setColumnBeingDragged, (function (param) {
                                            return column;
                                          }));
                                  }),
                                onStop: (function (param, param$1) {
                                    var x = param$1.x;
                                    var newColumns = Belt_Array.mapWithIndexU(columns, (function (columnIndex, column) {
                                            if (columnIndex === index) {
                                              return {
                                                      columnType: column.columnType,
                                                      width: column.width + x | 0,
                                                      order: column.order,
                                                      visible: column.visible
                                                    };
                                            } else {
                                              return column;
                                            }
                                          }));
                                    if (x !== 0) {
                                      Curry._3(onWidthChange, (column.width + x | 0) > column.width ? "Increase" : "Decrease", newColumns, column.columnType);
                                    }
                                    Curry._1(setColumns, (function (param) {
                                            return newColumns;
                                          }));
                                    param$1.node.style.transform = "translate(0px, 0px)";
                                    return Curry._1(setColumnBeingDragged, (function (param) {
                                                  
                                                }));
                                  }),
                                zIndex: 3,
                                defaultClassName: defaultClassName,
                                bounds: {
                                  left: 60 - column.width | 0,
                                  top: 0,
                                  right: 600 - column.width | 0,
                                  bottom: 0
                                },
                                children: React.createElement("span", {
                                      ref: (function (r) {
                                          draggerRefs[SheetTypes.columnTypeToJs(column.columnType)].current = r;
                                        }),
                                      className: columnDragger
                                    }, React.createElement("span", undefined))
                              };
                              var tmp$2 = Belt_Option.isSome(columnBeingDragged) ? undefined : ({
                                    x: 0,
                                    y: undefined
                                  });
                              if (tmp$2 !== undefined) {
                                tmp$1.position = Caml_option.valFromOption(tmp$2);
                              }
                              tmp = React.createElement(ReactDraggable, tmp$1);
                            } else {
                              tmp = null;
                            }
                            return React.createElement("div", {
                                        key: SheetTypes.columnTypeToJs(column.columnType),
                                        className: index === 0 ? sheetHeaderStickyStyles(column.width, view === "ByCategory") : sheetHeaderTitleStyles(column.width)
                                      }, React.createElement($$Text.make, {
                                            size: "Tiny",
                                            weight: "Semi",
                                            color: Styles.Color.light10,
                                            children: SheetTypes.columnTypeToString(isGlobalWorkspace, column.columnType)
                                          }), React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.flexGrow(1.0),
                                                  tl: /* [] */0
                                                })
                                          }), tmp);
                          }))), React.createElement("div", {
                      ref: rulerRef,
                      className: rulerStyles
                    }));
    });

var columnsHeaderHeight = 40;

export {
  columnsHeaderHeight ,
  Style ,
  make ,
}
/* sheetHeaderStyles Not a pure module */
