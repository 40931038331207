// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as CmdK from "./CmdK.mjs";
import * as Kbar from "kbar";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Metric from "./Metric.mjs";
import * as Portal from "./Portal.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as $$Window from "./externals/window.mjs";
import * as Category from "./Category.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Destination from "./Destination.mjs";
import * as DrawerUtils from "./DrawerUtils.mjs";
import * as Integration from "./integration/Integration.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as DrawerHeader from "./detailviews/DrawerHeader.mjs";
import * as EventDetails from "./detailviews/EventDetails.mjs";
import * as EventVariant from "./EventVariant.mjs";
import * as SourceConfig from "./SourceConfig.mjs";
import * as React$1 from "motion/react";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as PropertyDetails from "./detailviews/PropertyDetails.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as CodegenSourceDetails from "./CodegenSourceDetails.mjs";
import * as PropertyBundleDetails from "./detailviews/PropertyBundleDetails.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as FlattenVariantsUseCase from "../../model/src/variants/flattenVariantsUseCase.mjs";
import * as TriggerFullscreenModal from "./TriggerFullscreenModal.mjs";
import * as InspectorIssueDetailsV2 from "./InspectorIssueDetailsV2.mjs";
import * as DrawerContainer__BranchPreview from "./DrawerContainer__BranchPreview.mjs";
import * as DrawerContainer__EventNotFound from "./DrawerContainer__EventNotFound.mjs";

function DrawerContainer$ItemNotFound(Props) {
  var kind = Props.kind;
  return React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
                  kind: kind,
                  children: "Not Found"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.pct(100.0)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, "Oh noes, this item wasn’t found"));
}

function slideOverStyles(topOffset) {
  return Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.top(Css.px(topOffset)),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(0)),
                    tl: {
                      hd: Css.width(Css.px(700)),
                      tl: {
                        hd: Css.zIndex(1),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.white),
                          tl: {
                            hd: Css.boxShadow(Styles.Shadow.standard),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function useAnalyticsForDrawerContainer(topDrawerItem, model) {
  var schemaBundle = SchemaBundleContext.use();
  React.useEffect((function (param) {
          var getMaybeAnalyticsItem = function (_topDrawerItem) {
            while(true) {
              var topDrawerItem = _topDrawerItem;
              if (topDrawerItem === undefined) {
                return ;
              }
              var variant = topDrawerItem.NAME;
              if (variant === "propertyGroup") {
                var itemId = topDrawerItem.VAL[0];
                return [
                        itemId,
                        "PropertyGroup",
                        Belt_Option.map(ModelUtils.getPropertyBundleById(itemId, model), (function (param) {
                                return param.name;
                              }))
                      ];
              }
              if (variant === "codegenSource") {
                var itemId$1 = topDrawerItem.VAL;
                return [
                        itemId$1,
                        "CodegenSource",
                        Belt_Option.map(ModelUtils.getSourceById(itemId$1, model), AvoConfig.getSourceName)
                      ];
              }
              if (variant === "eventVariant") {
                var variantIdentifier = topDrawerItem.VAL[0];
                return [
                        variantIdentifier.variantId,
                        "EventVariant",
                        Belt_Option.map(ModelUtils.getEventVariantByIdWithArchive(model, variantIdentifier), (function (param) {
                                return param.nameSuffix;
                              }))
                      ];
              }
              if (variant === "event") {
                var itemId$2 = topDrawerItem.VAL[0];
                return [
                        itemId$2,
                        "Event",
                        Belt_Option.map(ModelUtils.getEventById(itemId$2, model), (function (param) {
                                return param.name;
                              }))
                      ];
              }
              if (variant === "inspectorIssue") {
                return [
                        topDrawerItem.VAL[0],
                        "InspectorIssue",
                        undefined
                      ];
              }
              if (variant === "metric") {
                var itemId$3 = topDrawerItem.VAL[0];
                return [
                        itemId$3,
                        "Metric",
                        Belt_Option.map(ModelUtils.getMetricById(itemId$3, model), (function (param) {
                                return param.name;
                              }))
                      ];
              }
              if (variant === "destination") {
                var itemId$4 = topDrawerItem.VAL;
                return [
                        itemId$4,
                        "Destination",
                        Belt_Option.map(ModelUtils.getDestinationById(itemId$4, model), ModelUtils.getDestinationName)
                      ];
              }
              if (variant === "integration") {
                var itemId$5 = topDrawerItem.VAL[0];
                return [
                        itemId$5,
                        "Integration",
                        Belt_Option.map(ModelUtils.getIntegrationById(itemId$5, model), AvoConfig.getIntegrationName)
                      ];
              }
              if (variant === "property") {
                var itemId$6 = topDrawerItem.VAL[0];
                return [
                        itemId$6,
                        "Property",
                        Belt_Option.map(ModelUtils.resolvePropertyById(itemId$6, model), (function (param) {
                                return param.name;
                              }))
                      ];
              }
              if (variant === "category") {
                var itemId$7 = topDrawerItem.VAL[0];
                return [
                        itemId$7,
                        "Goal",
                        Belt_Option.map(ModelUtils.getGoalById(itemId$7, model), (function (param) {
                                return param.name;
                              }))
                      ];
              }
              if (variant === "source") {
                var itemId$8 = topDrawerItem.VAL[0];
                return [
                        itemId$8,
                        "Source",
                        Belt_Option.map(ModelUtils.getSourceById(itemId$8, model), AvoConfig.getSourceName)
                      ];
              }
              _topDrawerItem = topDrawerItem.VAL[1];
              continue ;
            };
          };
          var match = getMaybeAnalyticsItem(topDrawerItem);
          if (match !== undefined) {
            var itemName = match[2];
            var itemType = match[1];
            var itemId = match[0];
            var exit = 0;
            if (topDrawerItem !== undefined && typeof topDrawerItem === "object" && topDrawerItem.NAME === "branchPreview") {
              var branchId = topDrawerItem.VAL[0];
              var partial_arg = Belt_Option.getWithDefault(itemName, "");
              var func = function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13) {
                return AnalyticsRe.itemDetailsOpenedBranchPreview(itemId, partial_arg, itemType, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13);
              };
              var arg = NamedBranch.getName(NamedBranch.fromUnnamed(model, {
                        NAME: "Branch",
                        VAL: branchId
                      }));
              var arg$1 = schemaBundle.schemaId;
              AnalyticsUtils.withSchemaBundle((function (param) {
                      var func$1 = Curry._1(func, param);
                      return function (param) {
                        var func$2 = Curry._1(func$1, param);
                        return function (param) {
                          var func$3 = Curry._1(func$2, param);
                          return function (param) {
                            var func$4 = Curry._1(func$3, param);
                            return function (param) {
                              var func$5 = Curry._1(func$4, param);
                              return function (param) {
                                var func$6 = Curry._1(func$5, param);
                                return function (param) {
                                  var func$7 = Curry._1(func$6, param);
                                  return function (param) {
                                    var func$8 = Curry._1(func$7, param);
                                    return function (param) {
                                      var func$9 = Curry._1(func$8, param);
                                      return function (param) {
                                        var func$10 = Curry._1(func$9, param);
                                        return function (param) {
                                          return Curry._4(func$10, param, branchId, arg, arg$1);
                                        };
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          };
                        };
                      };
                    }), schemaBundle);
            } else {
              exit = 1;
            }
            if (exit === 1) {
              var partial_arg$1 = Belt_Option.getWithDefault(itemName, "");
              var func$1 = function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11) {
                return AnalyticsRe.itemDetailsOpened(itemId, partial_arg$1, itemType, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11);
              };
              var arg$2 = schemaBundle.schemaId;
              AnalyticsUtils.withSchemaBundle((function (param) {
                      var func$2 = Curry._1(func$1, param);
                      return function (param) {
                        var func$3 = Curry._1(func$2, param);
                        return function (param) {
                          var func$4 = Curry._1(func$3, param);
                          return function (param) {
                            var func$5 = Curry._1(func$4, param);
                            return function (param) {
                              var func$6 = Curry._1(func$5, param);
                              return function (param) {
                                var func$7 = Curry._1(func$6, param);
                                return function (param) {
                                  var func$8 = Curry._1(func$7, param);
                                  return function (param) {
                                    var func$9 = Curry._1(func$8, param);
                                    return function (param) {
                                      var func$10 = Curry._1(func$9, param);
                                      return function (param) {
                                        var func$11 = Curry._1(func$10, param);
                                        return function (param) {
                                          return Curry._2(func$11, param, arg$2);
                                        };
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          };
                        };
                      };
                    }), schemaBundle);
            }
            
          }
          
        }), [topDrawerItem]);
}

function DrawerContainer(Props) {
  var branchStatus = Props.branchStatus;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var masterModel = Props.masterModel;
  var member = Props.member;
  var model = Props.model;
  var renderModel = Props.renderModel;
  var role = Props.role;
  var schema = Props.schema;
  var match = RouterStore.Schema.useSlices(function (param) {
        return [
                param.branch,
                param.drawerItems,
                param.schemaRoute
              ];
      });
  var drawerItems = match[1];
  var branch = match[0];
  var match$1;
  if (drawerItems) {
    var otherDrawerItems = drawerItems.tl;
    var topDrawerItem = drawerItems.hd;
    match$1 = otherDrawerItems ? [
        topDrawerItem,
        otherDrawerItems
      ] : [
        topDrawerItem,
        /* [] */0
      ];
  } else {
    match$1 = [
      undefined,
      /* [] */0
    ];
  }
  var otherDrawerItems$1 = match$1[1];
  var topDrawerItem$1 = match$1[0];
  var currentBranch;
  if (typeof branch === "object" && branch.NAME === "branch") {
    var branchId = branch.VAL;
    currentBranch = branchId !== "master" ? ({
          NAME: "Branch",
          VAL: branchId
        }) : "Master";
  } else {
    currentBranch = "Master";
  }
  var disableHotKeys = Belt_Option.mapWithDefault(topDrawerItem$1, false, (function (item) {
          if (typeof item !== "object") {
            return false;
          }
          var variant = item.NAME;
          if (variant === "eventVariant") {
            return item.VAL[1] !== undefined;
          } else if (variant === "event") {
            return item.VAL[2] !== undefined;
          } else {
            return false;
          }
        }));
  var workspace = WorkspaceContext.use();
  useAnalyticsForDrawerContainer(topDrawerItem$1, model);
  Kbar.useRegisterActions(Belt_List.length(drawerItems) > 0 && !disableHotKeys ? [Curry.app(CmdK.Action.t, [
                "drawer_close",
                {
                  TAG: "RootItem",
                  _0: "Close Drawer"
                },
                1.1,
                ["escape"],
                undefined,
                undefined,
                (function (param) {
                    var closeButtonElement = $$Window.$$Document.getElementById("drawer-container-close");
                    Belt_Option.forEach(closeButtonElement, (function (prim) {
                            prim.click();
                          }));
                  }),
                undefined,
                undefined
              ])] : [], [
        drawerItems,
        disableHotKeys
      ]);
  Kbar.useRegisterActions(Belt_List.length(drawerItems) > 1 && !disableHotKeys ? [Curry.app(CmdK.Action.t, [
                "drawer_prev",
                {
                  TAG: "RootItem",
                  _0: "Go to previous drawer item"
                },
                1.1,
                ["arrowleft"],
                undefined,
                undefined,
                (function (param) {
                    Router.Schema.popDrawerItem(undefined, undefined);
                  }),
                undefined,
                undefined
              ])] : [], [
        drawerItems,
        disableHotKeys
      ]);
  var branchBarVisible = RouterStore.useBranchBarVisible();
  var topOffset = DrawerUtils.getTopOffset(workspace, branchBarVisible, match[2]);
  var tmp;
  if (topDrawerItem$1 !== undefined && typeof topDrawerItem$1 === "object") {
    var variant = topDrawerItem$1.NAME;
    if (variant === "eventVariant") {
      var match$2 = topDrawerItem$1.VAL;
      var triggerId = match$2[1];
      if (triggerId !== undefined) {
        var variantIdentifier = match$2[0];
        var baseEvent = ModelUtils.getEventById(variantIdentifier.baseEventId, model);
        var eventVariant = ModelUtils.getEventVariantById(model, variantIdentifier);
        var triggerExists = Belt_Option.flatMap(eventVariant, (function (eventVariant) {
                return Belt_Array.getBy(eventVariant.triggers, (function (param) {
                              return param.id === triggerId;
                            }));
              })) !== undefined || triggerId === "new";
        if (baseEvent !== undefined && eventVariant !== undefined) {
          if (triggerExists) {
            var flattenedVariantModel = FlattenVariantsUseCase.flattenOneVariant(model, baseEvent, eventVariant);
            var flattenedVariantEvent = Belt_Option.getExn(Belt_List.getByU(flattenedVariantModel.events, (function ($$event) {
                        return $$event.id === VariantEventIdUseCase.variantEventId(variantIdentifier);
                      })));
            tmp = React.createElement(TriggerFullscreenModal.make, {
                  currentBranch: currentBranch,
                  event: flattenedVariantEvent,
                  variantIdentifier: variantIdentifier,
                  eventVariant: eventVariant,
                  triggerId: triggerId,
                  modelSources: flattenedVariantModel.sources,
                  numEvents: Belt_List.length(flattenedVariantModel.events),
                  onClose: (function (param) {
                      Router.Schema.swapTopDrawerItem({
                            NAME: "eventVariant",
                            VAL: [
                              variantIdentifier,
                              undefined
                            ]
                          });
                    }),
                  viewerCanEdit: role === "Admin" || role === "Editor"
                });
          } else {
            Router.Schema.swapTopDrawerItem({
                  NAME: "eventVariant",
                  VAL: [
                    variantIdentifier,
                    undefined
                  ]
                });
            tmp = null;
          }
        } else {
          Router.Schema.popDrawerItem(undefined, undefined);
          tmp = null;
        }
      } else {
        tmp = null;
      }
    } else if (variant === "event") {
      var match$3 = topDrawerItem$1.VAL;
      var triggerId$1 = match$3[2];
      if (triggerId$1 !== undefined) {
        var commentId = match$3[1];
        var eventId = match$3[0];
        var $$event = ModelUtils.getEventById(eventId, model);
        var triggerExists$1 = Belt_Option.flatMap($$event, (function ($$event) {
                return Belt_Array.getBy($$event.triggers, (function (param) {
                              return param.id === triggerId$1;
                            }));
              })) !== undefined || triggerId$1 === "new";
        if ($$event !== undefined) {
          if (triggerExists$1) {
            tmp = React.createElement(TriggerFullscreenModal.make, {
                  currentBranch: currentBranch,
                  event: $$event,
                  triggerId: triggerId$1,
                  modelSources: model.sources,
                  numEvents: Belt_List.length(model.events),
                  onClose: (function (param) {
                      Router.Schema.swapTopDrawerItem({
                            NAME: "event",
                            VAL: [
                              eventId,
                              commentId,
                              undefined,
                              false
                            ]
                          });
                    }),
                  viewerCanEdit: role === "Admin" || role === "Editor"
                });
          } else {
            Router.Schema.swapTopDrawerItem({
                  NAME: "event",
                  VAL: [
                    eventId,
                    commentId,
                    undefined,
                    false
                  ]
                });
            tmp = null;
          }
        } else {
          Router.Schema.popDrawerItem(undefined, undefined);
          tmp = null;
        }
      } else {
        tmp = null;
      }
    } else {
      tmp = null;
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Portal.make, {
                  children: React.createElement(React$1.AnimatePresence, {
                        children: Belt_Option.isSome(topDrawerItem$1) ? React.createElement(React$1.motion.div, {
                                id: "drawer-container",
                                animate: {
                                  opacity: 1,
                                  x: 0
                                },
                                transition: {
                                  duration: 0.3
                                },
                                initial: {
                                  opacity: 0,
                                  x: 50
                                },
                                exit: {
                                  opacity: 0,
                                  x: 50
                                },
                                className: slideOverStyles(topOffset),
                                key: "slideOver",
                                children: Belt_Option.mapWithDefault(topDrawerItem$1, React.createElement(DrawerContainer$ItemNotFound, {
                                          kind: "Item"
                                        }), (function (drawerItem) {
                                        var variant = drawerItem.NAME;
                                        if (variant === "propertyGroup") {
                                          var match = drawerItem.VAL;
                                          var goToCommentId = match[1];
                                          var bundleId = match[0];
                                          return Belt_Option.mapWithDefault(ModelUtils.getPropertyBundleById(bundleId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Property Bundle"
                                                        }), (function (param) {
                                                        return React.createElement(PropertyBundleDetails.make, {
                                                                    currentBranch: currentBranch,
                                                                    currentFilters: currentFilters,
                                                                    filters: filters,
                                                                    event: Belt_Option.flatMapU(Belt_List.get(otherDrawerItems$1, 0), (function (nextItem) {
                                                                            if (typeof nextItem === "object" && nextItem.NAME === "event") {
                                                                              return ModelUtils.getEventById(nextItem.VAL[0], model);
                                                                            }
                                                                            
                                                                          })),
                                                                    goToCommentId: goToCommentId,
                                                                    bundleId: bundleId,
                                                                    member: member,
                                                                    openBranches: masterModel.openBranches,
                                                                    role: role,
                                                                    schema: schema
                                                                  });
                                                      }));
                                        }
                                        if (variant === "codegenSource") {
                                          return React.createElement(CodegenSourceDetails.make, {
                                                      model: renderModel,
                                                      sourceId: drawerItem.VAL,
                                                      rawModel: model,
                                                      role: role
                                                    });
                                        }
                                        if (variant === "eventVariant") {
                                          var variantIdentifier = drawerItem.VAL[0];
                                          var $$event = ModelUtils.getEventById(variantIdentifier.baseEventId, model);
                                          if ($$event === undefined) {
                                            return React.createElement(DrawerContainer$ItemNotFound, {
                                                        kind: "Event Variant"
                                                      });
                                          }
                                          var variant$1 = Belt_Array.getBy($$event.variants, (function (variant) {
                                                  return variant.id === variantIdentifier.variantId;
                                                }));
                                          if (variant$1 !== undefined) {
                                            return React.createElement(EventVariant.make, {
                                                        baseEvent: $$event,
                                                        variant: variant$1,
                                                        viewerCanEdit: RoleUtils.canEdit(role),
                                                        model: model,
                                                        currentFilters: currentFilters,
                                                        currentBranch: currentBranch,
                                                        openBranches: masterModel.openBranches,
                                                        filters: filters,
                                                        branchStatus: branchStatus,
                                                        role: role
                                                      });
                                          } else {
                                            return React.createElement(DrawerContainer$ItemNotFound, {
                                                        kind: "Event Variant"
                                                      });
                                          }
                                        }
                                        if (variant === "event") {
                                          var match$1 = drawerItem.VAL;
                                          var isFullscreen = match$1[3];
                                          var eventId = match$1[0];
                                          return Belt_Option.mapWithDefault(ModelUtils.getEventById(eventId, renderModel), React.createElement(DrawerContainer__EventNotFound.make, {
                                                          eventId: eventId
                                                        }), (function ($$event) {
                                                        return React.createElement(EventDetails.make, {
                                                                    branchStatus: branchStatus,
                                                                    event: $$event,
                                                                    schema: schema,
                                                                    currentBranch: currentBranch,
                                                                    openBranches: masterModel.openBranches,
                                                                    currentFilters: currentFilters,
                                                                    filters: filters,
                                                                    role: role,
                                                                    isFullscreen: isFullscreen,
                                                                    model: renderModel
                                                                  });
                                                      }));
                                        }
                                        if (variant === "inspectorIssue") {
                                          var match$2 = drawerItem.VAL;
                                          var id = match$2[0];
                                          var tmp = {
                                            id: id,
                                            openBranches: masterModel.openBranches,
                                            viewerRole: role,
                                            key: id
                                          };
                                          var tmp$1 = match$2[1];
                                          if (tmp$1 !== undefined) {
                                            tmp.goToCommentId = Caml_option.valFromOption(tmp$1);
                                          }
                                          return React.createElement(InspectorIssueDetailsV2.make, tmp);
                                        }
                                        if (variant === "metric") {
                                          var match$3 = drawerItem.VAL;
                                          var isFullscreen$1 = match$3[2];
                                          var maybeCommentId = match$3[1];
                                          return Belt_Option.mapWithDefault(ModelUtils.getMetricById(match$3[0], model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Metric"
                                                        }), (function (metric) {
                                                        return React.createElement(Metric.make, {
                                                                    currentBranch: currentBranch,
                                                                    currentFilters: currentFilters,
                                                                    filters: filters,
                                                                    goToCommentId: maybeCommentId,
                                                                    metric: metric,
                                                                    model: model,
                                                                    openBranches: masterModel.openBranches,
                                                                    role: role,
                                                                    schema: schema,
                                                                    isFullscreen: isFullscreen$1,
                                                                    key: metric.id
                                                                  });
                                                      }));
                                        }
                                        if (variant === "destination") {
                                          var destinationId = drawerItem.VAL;
                                          return Belt_Option.mapWithDefault(ModelUtils.getDestinationById(destinationId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Destination"
                                                        }), (function (param) {
                                                        return React.createElement(Destination.make, {
                                                                    destinationId: destinationId,
                                                                    model: model
                                                                  });
                                                      }));
                                        }
                                        if (variant === "integration") {
                                          var match$4 = drawerItem.VAL;
                                          var goToCommentId$1 = match$4[1];
                                          var integrationId = match$4[0];
                                          return Belt_Option.mapWithDefault(ModelUtils.getIntegrationById(integrationId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Integration"
                                                        }), (function (param) {
                                                        return React.createElement(Integration.make, {
                                                                    integrationId: integrationId,
                                                                    goToCommentId: goToCommentId$1,
                                                                    integrations: renderModel.integrations,
                                                                    openBranches: masterModel.openBranches,
                                                                    currentBranch: currentBranch,
                                                                    model: model,
                                                                    role: role,
                                                                    key: integrationId
                                                                  });
                                                      }));
                                        }
                                        if (variant === "property") {
                                          var match$5 = drawerItem.VAL;
                                          var maybeCommentId$1 = match$5[1];
                                          var propertyId = match$5[0];
                                          return Belt_Option.mapWithDefault(ModelUtils.getPropertyById(propertyId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Property"
                                                        }), (function (param) {
                                                        return React.createElement(PropertyDetails.make, {
                                                                    currentBranch: currentBranch,
                                                                    currentFilters: currentFilters,
                                                                    filters: filters,
                                                                    eventId: undefined,
                                                                    goToCommentId: maybeCommentId$1,
                                                                    member: member,
                                                                    model: renderModel,
                                                                    openBranches: masterModel.openBranches,
                                                                    propertyId: propertyId,
                                                                    role: role,
                                                                    schema: schema
                                                                  });
                                                      }));
                                        }
                                        if (variant === "category") {
                                          var match$6 = drawerItem.VAL;
                                          var maybeCommentId$2 = match$6[1];
                                          return Belt_Option.mapWithDefault(ModelUtils.getGoalById(match$6[0], model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Category"
                                                        }), (function (category) {
                                                        return React.createElement(Category.make, {
                                                                    category: category,
                                                                    currentBranch: currentBranch,
                                                                    goToCommentId: maybeCommentId$2,
                                                                    openBranches: masterModel.openBranches,
                                                                    model: model,
                                                                    role: role,
                                                                    currentFilters: currentFilters,
                                                                    key: category.id
                                                                  });
                                                      }));
                                        }
                                        if (variant === "source") {
                                          var match$7 = drawerItem.VAL;
                                          var tab = match$7[1];
                                          var sourceId = match$7[0];
                                          return Belt_Option.mapWithDefault(ModelUtils.getSourceById(sourceId, model), React.createElement(DrawerContainer$ItemNotFound, {
                                                          kind: "Source"
                                                        }), (function (param) {
                                                        return React.createElement(SourceConfig.make, {
                                                                    currentBranch: currentBranch,
                                                                    tab: tab,
                                                                    model: renderModel,
                                                                    mainModel: masterModel,
                                                                    sourceId: sourceId,
                                                                    viewerRole: role
                                                                  });
                                                      }));
                                        }
                                        var match$8 = drawerItem.VAL;
                                        return React.createElement(DrawerContainer__BranchPreview.make, {
                                                    schema: schema,
                                                    branchId: match$8[0],
                                                    previewItem: match$8[1],
                                                    currentFilters: currentFilters,
                                                    filters: filters
                                                  });
                                      }))
                              }) : null
                      })
                }), tmp);
}

var drawerWidth = 700;

var make = DrawerContainer;

export {
  drawerWidth ,
  make ,
}
/* Css Not a pure module */
