// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as FileView from "./FileView.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function fetchAvoFile(schemaId, branchId, source) {
  var auth = Firebase.getAuth();
  return Firebase.Auth.unsafeGetCurrentUser(auth).getIdToken().then(function (token) {
                return fetch(Firebase.apiUrl + "/c/v1/pull", {
                            method: "POST",
                            body: Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                          [
                                            "schemaId",
                                            schemaId
                                          ],
                                          [
                                            "branchId",
                                            branchId
                                          ],
                                          [
                                            "sources",
                                            [Js_dict.fromArray([
                                                    [
                                                      "id",
                                                      source.id
                                                    ],
                                                    [
                                                      "path",
                                                      AvoConfig.filenameHint(source.language) + ("." + AvoConfig.languageExtensionHint(source.language))
                                                    ]
                                                  ])]
                                          ],
                                          [
                                            "requestClient",
                                            "web"
                                          ]
                                        ]))),
                            headers: Caml_option.some(new Headers([
                                      [
                                        "Accept",
                                        "application/json"
                                      ],
                                      [
                                        "Content-Type",
                                        "application/json"
                                      ],
                                      [
                                        "Authorization",
                                        "Bearer " + token
                                      ]
                                    ]))
                          });
              }).then(function (res) {
              return res.json();
            });
}

function ImplementFile(Props) {
  var currentBranch = Props.currentBranch;
  var source = Props.source;
  var match = React.useState(function (param) {
        return "Initial";
      });
  var setStatus = match[1];
  var status = match[0];
  var workspace = WorkspaceContext.use();
  var member = WorkspaceContext.useMember();
  var handleExpandImplementFile = function (param) {
    var codegenAvailability = member !== undefined ? AvoLimits.CodegenAvailability.canCodegen(workspace, Caml_option.valFromOption(member)) : (
        FirebaseFetcherHooks.isSuperUser() ? "Available" : "Unavailable"
      );
    if (typeof codegenAvailability !== "object" && codegenAvailability === "Available") {
      Curry._1(setStatus, (function (param) {
              return "Loading";
            }));
      $$Promise.$$catch(fetchAvoFile(workspace.id, NamedBranch.unnamedBranchToId(currentBranch), source).then(function (response) {
                var error = response.error;
                if (error !== undefined) {
                  Curry._1(setStatus, (function (param) {
                          return {
                                  TAG: "Error",
                                  _0: error
                                };
                        }));
                } else {
                  Curry._1(setStatus, (function (param) {
                          return {
                                  TAG: "Success",
                                  _0: Belt_Array.concatMany(Belt_Array.map(response.sources, (function (source) {
                                              var code = source.code;
                                              return Belt_Array.map(code, (function (code) {
                                                            return [
                                                                    code.path,
                                                                    code.content
                                                                  ];
                                                          }));
                                            }))),
                                  _1: Belt_Option.getWithDefault(response.warnings, [])
                                };
                        }));
                }
                return Promise.resolve();
              }), (function (param) {
              return Promise.resolve(Curry._1(setStatus, (function (param) {
                                return {
                                        TAG: "Error",
                                        _0: "unknown error, please contact support."
                                      };
                              })));
            }));
      return ;
    }
    Belt_Option.forEach(AvoLimits.CodegenAvailability.getCanCodegenErrorMessage(workspace, codegenAvailability), (function (errorMessage) {
            Curry._1(setStatus, (function (param) {
                    return {
                            TAG: "Error",
                            _0: errorMessage
                          };
                  }));
          }));
  };
  if (typeof status !== "object") {
    if (status === "Initial") {
      return React.createElement(FileView.make, {
                  filename: "Click to generate your Avo file",
                  code: "",
                  loading: true,
                  onExpand: (function (param) {
                      handleExpandImplementFile();
                    })
                });
    } else {
      return React.createElement(FileView.make, {
                  filename: "Generating your Avo file...",
                  code: "",
                  loading: true
                });
    }
  }
  if (status.TAG === "Error") {
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.marginBottom(Css.px(10)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.lightRed),
                              tl: {
                                hd: Css.padding(Css.px(15)),
                                tl: {
                                  hd: Css.borderRadius(Styles.Border.radius),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.bold),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.medium),
                                tl: {
                                  hd: Css.margin(Css.px(4)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, "Error:"), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.margin(Css.px(4)),
                              tl: /* [] */0
                            })
                      }, status._0)), React.createElement(FileView.make, {
                    filename: "Click to try generating your Avo file again",
                    code: "",
                    loading: true,
                    onExpand: (function (param) {
                        handleExpandImplementFile();
                      })
                  }));
  }
  var warnings = status._1;
  return React.createElement(React.Fragment, undefined, warnings.length !== 0 ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("block"),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                tl: /* [] */0
                              }
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.margin(Css.px(10)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.orange, 0.1)),
                                  tl: {
                                    hd: Css.padding(Css.px(15)),
                                    tl: {
                                      hd: Css.borderRadius(Styles.Border.radius),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.fontWeight(Styles.FontWeight.bold),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.medium),
                                    tl: {
                                      hd: Css.margin(Css.px(4)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, warnings.length === 1 ? "Warning:" : "Warnings:"), Belt_Array.map(warnings, (function (warning) {
                              return React.createElement("div", {
                                          key: warning,
                                          className: Curry._1(Css.style, {
                                                hd: Css.margin(Css.px(4)),
                                                tl: /* [] */0
                                              })
                                        }, warning);
                            })))) : null, Belt_Option.mapWithDefault(Belt_Array.get(status._0, 0), null, (function (param) {
                    return React.createElement(FileView.make, {
                                filename: param[0],
                                code: param[1],
                                startExpanded: true
                              });
                  })));
}

var make = ImplementFile;

export {
  fetchAvoFile ,
  make ,
}
/* Css Not a pure module */
