// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Icon from "./Icon.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Models from "./Models.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Accordion from "./Accordion.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconError from "./IconError.mjs";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconChevron from "./IconChevron.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as DiffComments from "./DiffComments.mjs";
import * as React$1 from "motion/react";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as IconCheckmark from "./IconCheckmark.mjs";
import * as PlanLightning from "./PlanLightning.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as DiffComponents from "./DiffComponents.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as DiscrepancyUtils from "./DiscrepancyUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as BranchDiscrepancies from "./BranchDiscrepancies.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as GetEventNamespaceUseCase from "./getEventNamespaceUseCase.mjs";
import * as BranchImplementationUtils from "./branchImplementation/BranchImplementationUtils.mjs";
import * as CodeChangesAuditAccordionItem from "./CodeChangesAuditAccordionItem.mjs";

var headerContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.width(Css.pct(100.0)),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.position("sticky"),
            tl: {
              hd: Css.top(Css.px(0)),
              tl: {
                hd: Css.marginRight(Css.px((DiffComments.commentsWidth + 20 | 0) - 1 | 0)),
                tl: {
                  hd: Css.marginLeft(Css.px(-1)),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.large),
                    tl: {
                      hd: Css.color(Styles.Color.light11),
                      tl: {
                        hd: Css.fontWeight(Styles.FontWeight.bold),
                        tl: {
                          hd: Css.padding2(Css.px(8), Css.px(20)),
                          tl: {
                            hd: Css.maxWidth(DiffComponents.maxWidthPx),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light01, 0.5)),
                              tl: {
                                hd: Css.zIndex(1),
                                tl: {
                                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light01),
                                  tl: {
                                    hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light01),
                                    tl: {
                                      hd: Css.backdropFilter({
                                            hd: {
                                              NAME: "blur",
                                              VAL: Css.px(10)
                                            },
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var showIssuesOnly = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gap(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

var frame = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: /* [] */0
    });

function expandButton(expanded) {
  return Curry._1(Css.style, {
              hd: Css.cursor("pointer"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.width(Css.px(24)),
                      tl: {
                        hd: Css.height(Css.px(24)),
                        tl: {
                          hd: Css.borderRadius(Css.pct(100)),
                          tl: {
                            hd: Css.transitions({
                                  hd: Css_Legacy_Core.Transition.shorthand({
                                        NAME: "ms",
                                        VAL: Styles.Duration.$$default
                                      }, undefined, undefined, "background-color"),
                                  tl: {
                                    hd: Css_Legacy_Core.Transition.shorthand({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$short
                                        }, undefined, undefined, "transform"),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: {
                              hd: Icon.color(Styles.Color.light10),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.1)),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: expanded ? Css.transform({
                                          NAME: "rotateZ",
                                          VAL: Css.deg(90.0)
                                        }) : Styles.emptyStyle,
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var issueCategoryContainer = Curry._1(Css.style, {
      hd: Css.overflowX("hidden"),
      tl: /* [] */0
    });

function issueCategory(shouldHover) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("row"),
                      tl: {
                        hd: Css.gap(Css.px(14)),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.padding(Css.px(16)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(16)),
                              tl: {
                                hd: Css.paddingRight(Css.px(16)),
                                tl: {
                                  hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
                                  tl: {
                                    hd: Css.overflowX("hidden"),
                                    tl: {
                                      hd: Css.selector("p", {
                                            hd: Css.position("relative"),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: shouldHover ? Curry._1(Css.style, {
                        hd: Css.hover({
                              hd: Css.backgroundColor(Styles.Color.light01),
                              tl: {
                                hd: Css.cursor("pointer"),
                                tl: {
                                  hd: Css.selector(" ~ div", {
                                        hd: Css.backgroundColor(Styles.Color.light01),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: /* [] */0
                      }) : Curry._1(Css.style, /* [] */0),
                tl: /* [] */0
              }
            });
}

var disabledIssues = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.gap(Css.px(8)),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.padding(Css.px(16)),
              tl: {
                hd: Css.paddingLeft(Css.px(32)),
                tl: {
                  hd: Css.paddingRight(Css.px(20)),
                  tl: {
                    hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
                    tl: {
                      hd: Css.overflowX("hidden"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var issues = Curry._1(Css.style, {
      hd: Css.overflowX("hidden"),
      tl: /* [] */0
    });

var issue = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.paddingLeft(Css.px(56)),
            tl: {
              hd: Css.gap(Css.px(4)),
              tl: {
                hd: Css.paddingTop(Css.px(8)),
                tl: {
                  hd: Css.firstOfType({
                        hd: Css.paddingTop(Css.px(0)),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.lastOfType({
                          hd: Css.paddingBottom(Css.px(20)),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var issueLink = Curry._1(Css.style, {
      hd: Css.selector(" p", {
            hd: Css.display("flex"),
            tl: {
              hd: Css.flexDirection("row"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.hover({
                        hd: Css.color(Styles.Color.darkBlue),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }),
      tl: /* [] */0
    });

var planLightning = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.fill("transparent"),
      tl: {
        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light10),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.marginTop(Css.px(2)),
            tl: /* [] */0
          }
        }
      }
    });

var chevronRight = Curry._1(Css.style, {
      hd: Css.display("inlineBlock"),
      tl: {
        hd: Css.width(Css.px(6)),
        tl: {
          hd: Css.height(Css.px(4)),
          tl: {
            hd: Css.transforms({
                  hd: {
                    NAME: "rotate",
                    VAL: {
                      NAME: "deg",
                      VAL: -90.0
                    }
                  },
                  tl: {
                    hd: {
                      NAME: "translateY",
                      VAL: Css.px(-4)
                    },
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var description = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: /* [] */0
      }
    });

var suggestionButton = Curry._1(Css.style, {
      hd: Css.width("fitContent"),
      tl: /* [] */0
    });

var autoCorrectButton = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(48)),
      tl: /* [] */0
    });

var branchAuditSettingsContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gap(Css.px(4)),
          tl: /* [] */0
        }
      }
    });

var branchAuditSettings = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.transition({
                      NAME: "ms",
                      VAL: Styles.Duration.$$long
                    }, undefined, undefined, "path"),
                tl: {
                  hd: Icon.color(Styles.Color.light11),
                  tl: {
                    hd: Css.borderRadius(Css.px(5)),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", "transparent"),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$short
                            }, undefined, undefined, "all"),
                        tl: {
                          hd: Css.hover({
                                hd: Icon.color(Styles.Color.deepOrange),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", Styles.Color.paleOrange),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.paleOrange),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          tl: {
                            hd: Css.padding(Css.px(4)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function getContainerSize(size) {
  if (size === "Small") {
    return 16;
  } else {
    return 24;
  }
}

function iconStyles(customBackgroundColor, size) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.px(getContainerSize(size))),
              tl: {
                hd: Css.height(Css.px(getContainerSize(size))),
                tl: {
                  hd: Css.backgroundColor(customBackgroundColor),
                  tl: {
                    hd: Css.borderRadius(Css.pct(100.0)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.paddingBottom(Css.px(size === "Small" ? 1 : 2)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function BranchAudit$IssueIcon(Props) {
  var icon = Props.icon;
  var size = Props.size;
  var iconSize = React.useMemo((function (param) {
          if (icon === "NoIssues") {
            if (size === "Small") {
              return 10;
            } else {
              return 18;
            }
          } else if (size === "Small") {
            return 14;
          } else {
            return 20;
          }
        }), [
        icon,
        size
      ]);
  return React.createElement("span", {
              className: iconStyles("transparent", size)
            }, icon === "NoIssues" ? React.createElement(IconCheckmark.make, {
                    size: iconSize,
                    color: Styles.Color.green
                  }) : (
                icon === "Issues" ? React.createElement(IconError.make, {
                        size: iconSize,
                        color: Styles.Color.orange
                      }) : React.createElement(IconError.make, {
                        size: iconSize,
                        color: Styles.Color.red
                      })
              ));
}

function issueCategoryToString(issueCategory) {
  var variant = issueCategory.NAME;
  if (variant === "SameEventName") {
    return "SameEventName";
  } else if (variant === "SamePropertyName") {
    return "SamePropertyName";
  } else if (variant === "PropertiesWithAnyType") {
    return "PropertiesWithAnyType";
  } else if (variant === "EventsWithoutDescription") {
    return "EventsWithoutDescription";
  } else if (variant === "EventsWithoutExpectedValues") {
    return "EventsWithoutExpectedValues";
  } else if (variant === "EventsWithUnexpectedCase") {
    return "EventsWithUnexpectedCase";
  } else if (variant === "PropertyConflictOnEvent") {
    return "PropertyConflictOnEvent";
  } else if (variant === "PropertiesWithoutDescription") {
    return "PropertiesWithoutDescription";
  } else {
    return "PropertiesWithUnexpectedCase";
  }
}

function issueCategoryToAnalyticsType(issueCategory) {
  var variant = issueCategory.NAME;
  if (variant === "SameEventName") {
    return "SameNameEvents";
  } else if (variant === "SamePropertyName") {
    return "SameNameProperty";
  } else if (variant === "PropertiesWithAnyType") {
    return "PropertyTypeNotDefined";
  } else if (variant === "EventsWithoutDescription") {
    return "EventMissingDescription";
  } else if (variant === "EventsWithoutExpectedValues") {
    return "EventAdvancedNamingConventionDiscrepancy";
  } else if (variant === "EventsWithUnexpectedCase") {
    return "EventCommonCasingDiscrepancy";
  } else if (variant === "PropertyConflictOnEvent") {
    return "PropertyConflicts";
  } else if (variant === "PropertiesWithoutDescription") {
    return "PropertyMissingDescription";
  } else {
    return "PropertyCommonCasingDiscrepancy";
  }
}

function issueCategoryToDescription(expectedEventCase, expectedPropertyCase, issueCategory) {
  var variant = issueCategory.NAME;
  if (variant === "SameEventName") {
    var issueCount = Belt_List.length(issueCategory.VAL[2]);
    if (issueCount === 0) {
      return "All events have a unique name";
    } else {
      return String(issueCount) + " event" + (
              issueCount === 1 ? "" : "s"
            ) + " " + (
              issueCount === 1 ? "has" : "have"
            ) + " same or " + (
              issueCount === 1 ? "a similar name" : "similar names"
            );
    }
  }
  if (variant === "SamePropertyName") {
    var issueCount$1 = Belt_List.length(issueCategory.VAL[2]);
    if (issueCount$1 === 0) {
      return "All properties have a unique name";
    } else {
      return String(issueCount$1) + " propert" + (
              issueCount$1 === 1 ? "y" : "ies"
            ) + " " + (
              issueCount$1 === 1 ? "has" : "have"
            ) + " same or " + (
              issueCount$1 === 1 ? "a similar name" : "similar names"
            );
    }
  }
  if (variant === "PropertiesWithAnyType") {
    var issueCount$2 = Belt_List.length(issueCategory.VAL[2]);
    if (issueCount$2 === 0) {
      return "All properties have a defined type";
    } else {
      return String(issueCount$2) + " propert" + (
              issueCount$2 === 1 ? "y" : "ies"
            ) + " " + (
              issueCount$2 === 1 ? "do not" : "don't"
            ) + " have a defined type";
    }
  }
  if (variant === "EventsWithoutDescription") {
    var issueCount$3 = Belt_List.length(issueCategory.VAL[2]);
    if (issueCount$3 === 0) {
      return "All events have a description";
    } else {
      return String(issueCount$3) + " event" + (
              issueCount$3 === 1 ? "" : "s"
            ) + " " + (
              issueCount$3 === 1 ? "does" : "do"
            ) + " not have a description";
    }
  }
  if (variant === "EventsWithoutExpectedValues") {
    var issueCount$4 = Belt_List.length(issueCategory.VAL[2]);
    if (issueCount$4 === 0) {
      return "All events comply with the event naming conventions";
    } else {
      return AvoUtils.plural(undefined, undefined, issueCount$4, "event") + " " + AvoUtils.plural("have", false, issueCount$4, "has") + " event naming conventions conflicts";
    }
  }
  if (variant === "EventsWithUnexpectedCase") {
    var issueCount$5 = Belt_List.length(issueCategory.VAL[2]);
    if (issueCount$5 === 0) {
      return "All events are in " + Case.tToJs(expectedEventCase) + " case";
    } else {
      return String(issueCount$5) + " event" + (
              issueCount$5 === 1 ? "" : "s"
            ) + " " + (
              issueCount$5 === 1 ? "is" : "are"
            ) + " not in " + Case.tToJs(expectedEventCase) + " case";
    }
  }
  if (variant === "PropertyConflictOnEvent") {
    var issueCount$6 = Belt_List.length(issueCategory.VAL[2]);
    if (issueCount$6 === 0) {
      return "No conflicting properties";
    } else {
      return String(issueCount$6) + " event" + (
              issueCount$6 === 1 ? " has" : "s have"
            ) + " conflicting properties";
    }
  }
  if (variant === "PropertiesWithoutDescription") {
    var issueCount$7 = Belt_List.length(issueCategory.VAL[2]);
    if (issueCount$7 === 0) {
      return "All properties have a description";
    } else {
      return String(issueCount$7) + " propert" + (
              issueCount$7 === 1 ? "y" : "ies"
            ) + " " + (
              issueCount$7 === 1 ? "does" : "do"
            ) + " not have a description";
    }
  }
  var issueCount$8 = Belt_List.length(issueCategory.VAL[2]);
  if (issueCount$8 === 0) {
    return "All properties are in " + Case.tToJs(expectedPropertyCase) + " case";
  } else {
    return String(issueCount$8) + " propert" + (
            issueCount$8 === 1 ? "y" : "ies"
          ) + " " + (
            issueCount$8 === 1 ? "is" : "are"
          ) + " not in " + Case.tToJs(expectedPropertyCase) + " case";
  }
}

function getIssueFromId(issuesById, issueCategory, id) {
  return Belt_Option.flatMap(Js_dict.get(issuesById, id), (function (issueItems) {
                return Belt_Array.getByU(issueItems, (function (issueItem) {
                              var b = issueItem.issue;
                              var variant = issueCategory.NAME;
                              if (variant === "SameEventName") {
                                if (typeof b === "object") {
                                  return b.NAME === "SameEventName";
                                } else {
                                  return false;
                                }
                              } else if (variant === "SamePropertyName") {
                                if (typeof b === "object") {
                                  return b.NAME === "SamePropertyName";
                                } else {
                                  return false;
                                }
                              } else if (variant === "PropertiesWithAnyType") {
                                if (typeof b === "object") {
                                  return b.NAME === "PropertiesWithAnyType";
                                } else {
                                  return false;
                                }
                              } else if (variant === "EventsWithoutDescription") {
                                if (typeof b === "object") {
                                  return b.NAME === "EventsWithoutDescription";
                                } else {
                                  return false;
                                }
                              } else if (variant === "EventsWithoutExpectedValues") {
                                if (typeof b === "object") {
                                  return b.NAME === "EventsWithoutExpectedValues";
                                } else {
                                  return false;
                                }
                              } else if (variant === "EventsWithUnexpectedCase") {
                                if (typeof b === "object") {
                                  return b.NAME === "EventsWithUnexpectedCase";
                                } else {
                                  return false;
                                }
                              } else if (variant === "PropertyConflictOnEvent") {
                                if (typeof b === "object") {
                                  return b.NAME === "PropertyConflictOnEvent";
                                } else {
                                  return false;
                                }
                              } else if (variant === "PropertiesWithoutDescription") {
                                if (typeof b === "object") {
                                  return b.NAME === "PropertiesWithoutDescription";
                                } else {
                                  return false;
                                }
                              } else if (typeof b === "object") {
                                return b.NAME === "PropertiesWithUnexpectedCase";
                              } else {
                                return false;
                              }
                            }));
              }));
}

function getIsIssueCategoryEnabled(issue) {
  return issue.VAL[0];
}

function BranchAudit$IssueCategory(Props) {
  var branchStatus = Props.branchStatus;
  var existingEventNames = Props.existingEventNames;
  var existingPropertyNames = Props.existingPropertyNames;
  var expectedEventCase = Props.expectedEventCase;
  var expectedPropertyCase = Props.expectedPropertyCase;
  var issuesById = Props.issuesById;
  var issueCategory$1 = Props.issueCategory;
  var match = React.useContext(DiscrepancyContext.context);
  var numSchemaIssues = match.warningCount;
  var config = match.config;
  var schemaBundle = SchemaBundleContext.use();
  var addToast = Toast.useAddToast();
  var sendActions = SendActionsContext.use();
  var globalSend = GlobalSendContext.use();
  var hasSimpleNameCasing = React.useMemo((function (param) {
          var match = ValidationConfigV2.EventsV2.getCase(config.events);
          if (typeof match === "object") {
            return true;
          } else {
            return false;
          }
        }), [config.events.nameComponents]);
  var hasValueChecking = ValidationConfigV2.EventsV2.hasValueChecking(config.events);
  var issueCategoryWarningsCount = React.useMemo((function (param) {
          return DiscrepancyUtils.getWarningsCount(hasSimpleNameCasing, hasValueChecking, issueCategory$1);
        }), [issueCategory$1]);
  var issueCategoryErrorCount = React.useMemo((function (param) {
          return DiscrepancyUtils.getErrorCount(hasSimpleNameCasing, hasValueChecking, issueCategory$1);
        }), [issueCategory$1]);
  var issues$1 = React.useMemo((function (param) {
          var variant = issueCategory$1.NAME;
          if (variant === "PropertyConflictOnEvent" || variant === "EventsWithUnexpectedCase" || variant === "EventsWithoutDescription" || variant === "SameEventName") {
            return Belt_Array.keepMap(Belt_List.toArray(issueCategory$1.VAL[2]), (function (param) {
                          return getIssueFromId(issuesById, issueCategory$1, param.id);
                        }));
          } else if (variant === "EventsWithoutExpectedValues") {
            return Belt_Array.keepMapU(Belt_List.toArray(issueCategory$1.VAL[2]), (function (param) {
                          return getIssueFromId(issuesById, issueCategory$1, param[0].id);
                        }));
          } else {
            return Belt_Array.keepMap(Belt_List.toArray(issueCategory$1.VAL[2]), (function (param) {
                          return getIssueFromId(issuesById, issueCategory$1, param.id);
                        }));
          }
        }), [issueCategory$1]);
  var enabled = React.useMemo((function (param) {
          return issueCategory$1.VAL[0];
        }), [issueCategory$1]);
  var match$1 = React.useState(function (param) {
        return false;
      });
  var setIssuesExpanded = match$1[1];
  var issuesExpanded = match$1[0];
  var branchIsMerged;
  branchIsMerged = typeof branchStatus !== "object" || branchStatus.TAG !== "Merged" ? false : true;
  var autoCorrectsOnOffer = React.useMemo((function (param) {
          if (typeof issueCategory$1 !== "object") {
            return ;
          }
          var variant = issueCategory$1.NAME;
          if (variant === "PropertiesWithUnexpectedCase") {
            var match = issueCategory$1.VAL;
            if (!match[0]) {
              return ;
            }
            var autoCorrects = Belt_List.keepMap(match[2], (function (property) {
                    var normalizedName = Case.to_("Camel", property.name);
                    var match = Js_dict.get(existingPropertyNames, normalizedName);
                    if (match !== undefined && match && !(match.tl || match.hd !== property.id)) {
                      return [
                              {
                                NAME: "UpdatePropertyName",
                                VAL: [
                                  property.id,
                                  Case.to_(expectedPropertyCase, property.name)
                                ]
                              },
                              {
                                propertyId: property.id,
                                propertyQuery: property.id
                              }
                            ];
                    }
                    
                  }));
            if (autoCorrects === /* [] */0) {
              return ;
            } else {
              return autoCorrects;
            }
          }
          if (variant !== "EventsWithUnexpectedCase") {
            return ;
          }
          var match$1 = issueCategory$1.VAL;
          if (!match$1[0]) {
            return ;
          }
          var autoCorrects$1 = Belt_List.keepMap(match$1[2], (function ($$event) {
                  var normalizedName = Case.to_("Camel", $$event.name);
                  var match = Js_dict.get(existingEventNames, normalizedName);
                  if (match !== undefined && match && !(match.tl || match.hd !== $$event.id)) {
                    return [
                            {
                              NAME: "UpdateEventName",
                              VAL: [
                                $$event.id,
                                Case.to_(expectedEventCase, $$event.name)
                              ]
                            },
                            {
                              eventId: $$event.id,
                              eventQuery: $$event.id
                            }
                          ];
                  }
                  
                }));
          if (autoCorrects$1 === /* [] */0) {
            return ;
          } else {
            return autoCorrects$1;
          }
        }), [
        branchIsMerged,
        issueCategory$1,
        existingEventNames,
        expectedEventCase,
        existingPropertyNames,
        expectedPropertyCase
      ]);
  Hooks.useDidUpdate1((function (param) {
          if (issuesExpanded) {
            AnalyticsRe.issueExpanded(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, numSchemaIssues, issueCategoryToAnalyticsType(issueCategory$1), "BranchReview", issueCategoryWarningsCount, Belt_Option.isSome(autoCorrectsOnOffer), schemaBundle.branchId, schemaBundle.schemaId);
          }
          
        }), [issuesExpanded]);
  if (typeof issueCategory$1 === "object") {
    var variant = issueCategory$1.NAME;
    if (variant === "EventsWithoutExpectedValues") {
      if (!hasValueChecking) {
        return null;
      }
      
    } else if (variant === "EventsWithUnexpectedCase" && !hasSimpleNameCasing) {
      return null;
    }
    
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: issueCategory(issueCategoryWarningsCount > 0),
                  onClick: (function (param) {
                      Curry._1(setIssuesExpanded, (function (state) {
                              if (state) {
                                return false;
                              } else {
                                return enabled;
                              }
                            }));
                    })
                }, React.createElement(BranchAudit$IssueIcon, {
                      icon: issueCategoryErrorCount > 0 ? "Errors" : (
                          issueCategoryWarningsCount > 0 ? "Issues" : "NoIssues"
                        ),
                      size: "Small"
                    }), React.createElement("div", {
                      className: description
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: issueCategoryWarningsCount > 0 ? "Semi" : "Regular",
                          color: enabled ? Styles.Color.light12 : Styles.Color.light08,
                          children: issueCategoryToDescription(expectedEventCase, expectedPropertyCase, issueCategory$1)
                        }), issueCategoryErrorCount > 0 ? React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.darkRed,
                            children: ValidationConfigV2.PointOfBlock.toBlockingLabel(config.pointOfBlock)
                          }) : null), React.createElement(Spacer.make, {
                      grow: 1.0
                    }), issueCategoryWarningsCount > 0 ? React.createElement("span", {
                        className: expandButton(issuesExpanded)
                      }, React.createElement(Icon.make, {
                            type_: "chevronRight",
                            size: "small"
                          })) : null), React.createElement(React$1.AnimatePresence, {
                  children: issuesExpanded ? React.createElement(React$1.motion.div, {
                          animate: {
                            opacity: 1.0,
                            height: "auto"
                          },
                          transition: {
                            duration: 0.2
                          },
                          initial: {
                            opacity: 0.0,
                            height: "0px"
                          },
                          exit: {
                            opacity: 0.0,
                            height: "0px"
                          },
                          className: issues,
                          children: null
                        }, Belt_Option.mapWithDefault(autoCorrectsOnOffer, null, (function (autoCorrects) {
                                if (Belt_List.length(autoCorrects) === 1) {
                                  return null;
                                } else {
                                  return React.createElement("div", {
                                              className: autoCorrectButton
                                            }, React.createElement(Button.make, {
                                                  disabled: branchIsMerged,
                                                  label: "Auto-correct all",
                                                  onClick: (function (param) {
                                                      Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            (function (branch) {
                                                                if (issues$1.length === Belt_List.length(autoCorrects)) {
                                                                  Curry._1(addToast, {
                                                                        message: "Successfully corrected all issues",
                                                                        toastType: "Success"
                                                                      });
                                                                } else {
                                                                  Curry._1(globalSend, {
                                                                        TAG: "OpenModal",
                                                                        _0: {
                                                                          NAME: "AlertModal",
                                                                          VAL: [
                                                                            "Could not correct all issues",
                                                                            String(Belt_List.length(autoCorrects) - issues$1.length | 0) + " remain to be corrected and will have to be resolved manually",
                                                                            "OK",
                                                                            (function (param) {
                                                                                
                                                                              })
                                                                          ]
                                                                        }
                                                                      });
                                                                }
                                                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                AnalyticsRe.auditIssueInteracted(undefined, undefined, undefined, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "BranchReview", "FixAllIssues", issueCategoryToAnalyticsType(issueCategory$1), schemaBundle$1.schemaId, NamedBranch.getId(branch));
                                                              }),
                                                            undefined,
                                                            Belt_List.toArray(autoCorrects)
                                                          ]);
                                                    })
                                                }));
                                }
                              })), Belt_Array.map(issues$1, (function (item) {
                                var match = item.type_;
                                var match$1 = item.suggestion;
                                var match$2 = item.suggestionCallback;
                                return React.createElement("div", {
                                            key: item.id,
                                            className: issue
                                          }, React.createElement(Link.make, {
                                                path: Router.Link.addDrawerItem(undefined, match === "Property" ? ({
                                                          NAME: "property",
                                                          VAL: [
                                                            item.id,
                                                            undefined
                                                          ]
                                                        }) : ({
                                                          NAME: "event",
                                                          VAL: [
                                                            item.id,
                                                            undefined,
                                                            undefined,
                                                            false
                                                          ]
                                                        })),
                                                className: issueLink,
                                                onClick: (function (e) {
                                                    e.stopPropagation();
                                                    AnalyticsRe.auditIssueInteracted(item.id, item.name, item.type_, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "BranchReview", "OpenIssueItem", issueCategoryToAnalyticsType(issueCategory$1), schemaBundle.schemaId, schemaBundle.branchId);
                                                  }),
                                                children: React.createElement($$Text.make, {
                                                      size: "Small",
                                                      weight: "Semi",
                                                      color: Styles.Color.light12,
                                                      children: null
                                                    }, item.name, React.createElement("div", {
                                                          className: chevronRight
                                                        }, React.createElement(IconChevron.make, {
                                                              size: 6,
                                                              color: Styles.Color.light12
                                                            })))
                                              }), match$1 !== undefined ? (
                                              match$2 !== undefined ? React.createElement("div", {
                                                      className: suggestionButton
                                                    }, React.createElement(TextButton.make, {
                                                          onClick: (function (param) {
                                                              Curry._2(match$2, sendActions, addToast);
                                                              AnalyticsRe.auditIssueInteracted(item.id, item.name, item.type_, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "BranchReview", "FixIssue", issueCategoryToAnalyticsType(issueCategory$1), schemaBundle.schemaId, schemaBundle.branchId);
                                                            }),
                                                          style: "Blue",
                                                          size: "Small",
                                                          disabled: branchIsMerged,
                                                          children: match$1
                                                        })) : React.createElement($$Text.make, {
                                                      size: "Small",
                                                      color: Styles.Color.light11,
                                                      children: match$1
                                                    })
                                            ) : null);
                              }))) : null
                }));
}

function BranchAudit$DisabledIssues(Props) {
  var issueCategories = Props.issueCategories;
  var globalSend = GlobalSendContext.use();
  var disabledIssuesCount = React.useMemo((function (param) {
          return Belt_Array.keep(issueCategories, (function (issue) {
                        return !issue.VAL[0];
                      })).length;
        }), [issueCategories]);
  if (disabledIssuesCount > 0) {
    return React.createElement("div", {
                className: disabledIssues
              }, React.createElement(Spacer.make, {
                    width: 16
                  }), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    color: Styles.Color.light08,
                    children: String(disabledIssuesCount) + " disabled audit check" + (
                      disabledIssuesCount === 1 ? "" : "s"
                    )
                  }), React.createElement(Spacer.make, {
                    width: 8
                  }), React.createElement(Button.make, {
                    icon: "settings",
                    onClick: (function (param) {
                        Curry._1(globalSend, {
                              TAG: "OpenSlideOver",
                              _0: {
                                NAME: "DiscrepanciesConfig",
                                VAL: undefined
                              }
                            });
                      }),
                    style: "ghost"
                  }));
  } else {
    return null;
  }
}

function BranchAudit$DiscrepancyAuditAccordionItem(Props) {
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchStatus = Props.branchStatus;
  var user = Props.user;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use();
  var match = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match.expectedPropertyCase;
  var expectedEventCase = match.expectedEventCase;
  var totalWarningCount = match.warningCount;
  var schemaBundle = SchemaBundleContext.use();
  var match$1 = BranchDiscrepancies.use();
  var issuesById = match$1.issuesById;
  var branchAuditErrorCount = match$1.branchAuditErrorCount;
  var branchAuditWarningCount = match$1.branchAuditWarningCount;
  var branchAuditChecks = match$1.branchAuditChecks;
  var expandedBranchAuditAvailability = AvoLimits.ExpandedBranchAudit.computeAvailability(workspace);
  var mayExpand = expandedBranchAuditAvailability === "AvailableDuringTrial" || expandedBranchAuditAvailability === "Available";
  var discrepancyAuditInitiallyOpened = React.useMemo((function (param) {
          if (branchAuditWarningCount > 0) {
            return mayExpand;
          } else {
            return false;
          }
        }), []);
  var branchAuditSettings$1 = React.useMemo((function (param) {
          return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.branchAuditSettings), Models.BranchAuditSettings.$$default);
        }), [user]);
  var match$2 = React.useState(function (param) {
        return branchAuditSettings$1.showIssuesOnly;
      });
  var setShowIssuesOnly = match$2[1];
  var showIssuesOnly$1 = match$2[0];
  var filteredIssueCategories = React.useMemo((function (param) {
          return Belt_Array.keep(branchAuditChecks, (function (issueCategory) {
                        var variant = issueCategory.NAME;
                        if (variant === "PropertyConflictOnEvent" || variant === "EventsWithUnexpectedCase" || variant === "EventsWithoutDescription" || variant === "SameEventName") {
                          var match = issueCategory.VAL;
                          var enabled = match[0];
                          if (showIssuesOnly$1 && branchAuditWarningCount > 0) {
                            if (enabled) {
                              return Belt_List.length(match[2]) > 0;
                            } else {
                              return false;
                            }
                          } else {
                            return enabled;
                          }
                        }
                        if (variant === "EventsWithoutExpectedValues") {
                          var match$1 = issueCategory.VAL;
                          var enabled$1 = match$1[0];
                          if (showIssuesOnly$1 && branchAuditWarningCount > 0) {
                            if (enabled$1) {
                              return Belt_List.length(match$1[2]) > 0;
                            } else {
                              return false;
                            }
                          } else {
                            return enabled$1;
                          }
                        }
                        var match$2 = issueCategory.VAL;
                        var enabled$2 = match$2[0];
                        if (showIssuesOnly$1 && branchAuditWarningCount > 0) {
                          if (enabled$2) {
                            return Belt_List.length(match$2[2]) > 0;
                          } else {
                            return false;
                          }
                        } else {
                          return enabled$2;
                        }
                      }));
        }), [
        showIssuesOnly$1,
        branchAuditChecks
      ]);
  var existingEventNames = React.useMemo((function (param) {
          var dict = {};
          Belt_List.forEach(GetEventNamespaceUseCase.getEventNamespaceWithIds(undefined, branchModelWithPulledMaster.events), (function (param) {
                  var id = param[1];
                  var normalizedName = Case.to_("Camel", param[0]);
                  var ids = Js_dict.get(dict, normalizedName);
                  if (ids !== undefined) {
                    dict[normalizedName] = Belt_List.concat(ids, {
                          hd: id,
                          tl: /* [] */0
                        });
                  } else {
                    dict[normalizedName] = {
                      hd: id,
                      tl: /* [] */0
                    };
                  }
                }));
          return dict;
        }), [branchModelWithPulledMaster.events]);
  var existingPropertyNames = React.useMemo((function (param) {
          var dict = {};
          Belt_List.forEach(ModelUtils.getPropertyNamespaceWithIds(branchModelWithPulledMaster), (function (param) {
                  var id = param[1];
                  var normalizedName = Case.to_("Camel", param[0]);
                  var ids = Js_dict.get(dict, normalizedName);
                  if (ids !== undefined) {
                    dict[normalizedName] = Belt_List.concat(ids, {
                          hd: id,
                          tl: /* [] */0
                        });
                  } else {
                    dict[normalizedName] = {
                      hd: id,
                      tl: /* [] */0
                    };
                  }
                }));
          return dict;
        }), [branchModelWithPulledMaster]);
  var auditChecksEnabled = React.useMemo((function (param) {
          return Belt_Array.keep(branchAuditChecks, getIsIssueCategoryEnabled).length;
        }), [branchAuditChecks]);
  Hooks.useDidUpdate1((function (param) {
          FirebaseUtils.setShowIssuesPreference(user.id, showIssuesOnly$1);
        }), [showIssuesOnly$1]);
  var warningString = AvoUtils.plural(undefined, undefined, branchAuditWarningCount, "issue");
  var errorString = AvoUtils.plural(undefined, undefined, branchAuditErrorCount, "issue");
  var branchAuditAccordionTitle;
  var exit = 0;
  if (branchAuditWarningCount === 0) {
    branchAuditAccordionTitle = String(auditChecksEnabled) + " of " + String(auditChecksEnabled) + " audit checks passed";
  } else if (typeof branchStatus !== "object") {
    exit = 1;
  } else {
    switch (branchStatus.TAG) {
      case "Merged" :
          branchAuditAccordionTitle = warningString + " introduced on merged branch";
          break;
      case "Draft" :
          if (match.config.pointOfBlock === "beforeRequestForReview" && branchAuditErrorCount > 0) {
            branchAuditAccordionTitle = "Fix " + errorString + " before requesting review";
          } else {
            exit = 1;
          }
          break;
      case "Approved" :
          branchAuditAccordionTitle = "Fix " + errorString + " to merge this branch";
          break;
      default:
        exit = 1;
    }
  }
  if (exit === 1) {
    branchAuditAccordionTitle = warningString + " on branch";
  }
  React.useEffect((function (param) {
          AnalyticsRe.issuePreviewDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, totalWarningCount, "BranchReview", branchAuditWarningCount, schemaBundle.branchId, schemaBundle.schemaId);
        }), [branchAuditWarningCount]);
  return React.createElement(Accordion.Item.make, {
              initiallyExpanded: discrepancyAuditInitiallyOpened,
              disabled: !mayExpand,
              onDisabledClick: (function (param) {
                  Curry._1(globalSend, {
                        TAG: "OpenModal",
                        _0: {
                          NAME: "BillingPrompt",
                          VAL: "ExpandBranchAudit"
                        }
                      });
                }),
              children: null
            }, React.createElement(Accordion.ItemHeader.make, {
                  title: {
                    NAME: "Component",
                    VAL: React.createElement("div", {
                          className: branchAuditSettingsContainer
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.light12,
                              children: branchAuditAccordionTitle
                            }), React.createElement("div", {
                              className: branchAuditSettings,
                              role: "button",
                              onClick: (function (e) {
                                  e.stopPropagation();
                                  Curry._1(globalSend, {
                                        TAG: "OpenSlideOver",
                                        _0: {
                                          NAME: "DiscrepanciesConfig",
                                          VAL: undefined
                                        }
                                      });
                                })
                            }, React.createElement(Icon.make, {
                                  type_: "settings",
                                  size: "small"
                                })), expandedBranchAuditAvailability === "Available" ? null : React.createElement("span", {
                                className: planLightning,
                                role: "button",
                                onClick: (function (param) {
                                    Curry._1(globalSend, {
                                          TAG: "OpenModal",
                                          _0: {
                                            NAME: "BillingPrompt",
                                            VAL: "ExpandBranchAudit"
                                          }
                                        });
                                  })
                              }, React.createElement(PlanLightning.make, {
                                    tooltipText: expandedBranchAuditAvailability === "AvailableDuringTrial" ? "Reviewing branch audit issues is available during trial and on the " + Workspace.printPlanName(AvoLimits.NameMapping.availableOnPlan(workspace).name) + " plan" : "Reviewing branch audit issues is available on the " + Workspace.printPlanName(AvoLimits.NameMapping.availableOnPlan(workspace).name) + " plan. "
                                  })))
                  },
                  ariaLabel: branchAuditAccordionTitle,
                  icon: React.createElement(BranchAudit$IssueIcon, {
                        icon: branchAuditWarningCount > 0 ? "Issues" : "NoIssues",
                        size: "Regular"
                      }),
                  theme: branchAuditWarningCount > 0 ? "yellow" : "green",
                  extra: branchAuditWarningCount > 0 ? React.createElement("div", {
                          className: showIssuesOnly,
                          onClick: (function (param) {
                              if (mayExpand) {
                                return Curry._1(setShowIssuesOnly, (function (state) {
                                              return !state;
                                            }));
                              } else {
                                return Curry._1(globalSend, {
                                            TAG: "OpenModal",
                                            _0: {
                                              NAME: "BillingPrompt",
                                              VAL: "ExpandBranchAudit"
                                            }
                                          });
                              }
                            })
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light10,
                              children: "Show issues only"
                            }), React.createElement(Switch.make, {
                              value: showIssuesOnly$1,
                              onChange: (function (param) {
                                  
                                }),
                              size: "Medium"
                            })) : null
                }), React.createElement(Accordion.ItemCollapsible.make, {
                  children: React.createElement("div", {
                        className: frame
                      }, React.createElement("div", {
                            className: issueCategoryContainer
                          }, React.createElement(React$1.AnimatePresence, {
                                initial: false,
                                children: null
                              }, Belt_Array.map(filteredIssueCategories, (function (issueCategory) {
                                      return React.createElement(BranchAudit$IssueCategory, {
                                                  branchStatus: branchStatus,
                                                  existingEventNames: existingEventNames,
                                                  existingPropertyNames: existingPropertyNames,
                                                  expectedEventCase: expectedEventCase,
                                                  expectedPropertyCase: expectedPropertyCase,
                                                  issuesById: issuesById,
                                                  issueCategory: issueCategory,
                                                  key: issueCategoryToString(issueCategory)
                                                });
                                    })), React.createElement(BranchAudit$DisabledIssues, {
                                    issueCategories: branchAuditChecks
                                  }))))
                }));
}

function BranchAudit(Props) {
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchStatus = Props.branchStatus;
  var user = Props.user;
  var sourceUpdates = Props.sourceUpdates;
  var schemaBundle = SchemaBundleContext.use();
  var onCodeChangesAuditToggled = function (numEvents, expanded) {
    AnalyticsRe.codeChangesAuditInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, numEvents, expanded ? "Expanded" : "Collapsed", schemaBundle.schemaId, schemaBundle.branchId);
  };
  var onCodeChangesAuditLinkClicked = function (numEvents) {
    AnalyticsRe.codeChangesAuditInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, numEvents, "CodeChangesLinkClicked", schemaBundle.schemaId, schemaBundle.branchId);
  };
  var onCodeChangesEventLinkClicked = function ($$event, sourceId, numEvents) {
    AnalyticsRe.codeChangesAuditSourceInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, $$event.id, $$event.name, "Event", numEvents, BranchImplementationUtils.hasCodegenForSource($$event, sourceId) ? "Codegen" : "Manual", "CodeChangesEventLinkClicked", schemaBundle.schemaId, schemaBundle.branchId);
  };
  var onCodeChangesAuditSourceToggled = function (source, numEvents, implementationMethod, expanded) {
    AnalyticsRe.codeChangesAuditSourceInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, source.id, AvoConfig.getSourceName(source), "Source", numEvents, implementationMethod, expanded ? "Expanded" : "Collapsed", schemaBundle.schemaId, schemaBundle.branchId);
  };
  var onCodeChangesAuditSourceLinkClicked = function (source, numEvents, implementationMethod) {
    AnalyticsRe.codeChangesAuditSourceInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, source.id, AvoConfig.getSourceName(source), "Source", numEvents, implementationMethod, "CodeChangesLinkClicked", schemaBundle.schemaId, schemaBundle.branchId);
  };
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: headerContainer
                }, React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      children: "Branch Audit"
                    })), React.createElement(Accordion.make, {
                  allowMultipleOpens: true,
                  size: "large",
                  chevronPosition: "right",
                  children: null
                }, React.createElement(BranchAudit$DiscrepancyAuditAccordionItem, {
                      branchModelWithPulledMaster: branchModelWithPulledMaster,
                      branchStatus: branchStatus,
                      user: user
                    }), React.createElement(CodeChangesAuditAccordionItem.make, {
                      branchStatus: branchStatus,
                      onCodeChangesAuditToggled: onCodeChangesAuditToggled,
                      onCodeChangesAuditLinkClicked: onCodeChangesAuditLinkClicked,
                      onCodeChangesEventLinkClicked: onCodeChangesEventLinkClicked,
                      onCodeChangesAuditSourceToggled: onCodeChangesAuditSourceToggled,
                      onCodeChangesAuditSourceLinkClicked: onCodeChangesAuditSourceLinkClicked,
                      sourceUpdates: sourceUpdates
                    })));
}

var make = BranchAudit;

export {
  make ,
}
/* headerContainer Not a pure module */
