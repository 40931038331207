// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Nanoid from "nanoid";
import * as RoleUtils from "../RoleUtils.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as NamedBranch from "../NamedBranch.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as CreateButtonIcon from "./CreateButtonIcon.mjs";
import * as EmptyStatesStyles from "./EmptyStatesStyles.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as MetricsEmptyIllustration from "./MetricsEmptyIllustration.mjs";

function MetricsEmpty(Props) {
  var sendActions = Props.sendActions;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use();
  React.useEffect((function (param) {
          AnalyticsRe.emptyStateDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Metrics", schemaBundle.schemaId);
        }), []);
  return React.createElement("main", {
              className: EmptyStatesStyles.rootStyles
            }, React.createElement("div", {
                  className: EmptyStatesStyles.illustrationStyles
                }, React.createElement(MetricsEmptyIllustration.make, {})), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement(Title.make, {
                  children: "Use Metrics to map your tracking journey",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.light10,
                  maxWidth: Css.px(520),
                  children: null
                }, "Whether it's a Funnel, Segmentation, Retention, or Proportion - Avo enables you to map out exactly what you want to track. Read more about Metrics in the ", React.createElement("a", {
                      className: EmptyStatesStyles.linkStyles,
                      href: "https://www.avo.app/docs/workspace/tracking-plan/metrics",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "Avo docs"
                        })), "."), RoleUtils.canEdit(role) ? React.createElement("div", {
                    className: EmptyStatesStyles.actionsStyles
                  }, React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Metrics", "Setup", undefined);
                            var metricId = Nanoid.nanoid();
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      Router.Schema.pushDrawerItem(undefined, undefined, {
                                            NAME: "metric",
                                            VAL: [
                                              metricId,
                                              undefined,
                                              false
                                            ]
                                          });
                                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                      AnalyticsRe.metricInitiated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, metricId, "", "Metrics", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "AddMetric",
                                        VAL: metricId
                                      },
                                      {
                                        metricId: metricId,
                                        metricQuery: metricId
                                      }
                                    ]]
                                ]);
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Create a Metric"
                          }))) : null);
}

var make = MetricsEmpty;

export {
  make ,
}
/* Css Not a pure module */
