// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_int from "rescript/lib/es6/js_int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FeatureFlag from "../../shared/FeatureFlag.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as CodegenRules from "./CodegenRules.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as CliFeatureFlag from "../../shared/CliFeatureFlag.mjs";
import * as CodegenCommons from "./CodegenCommons.mjs";
import * as ModelUtils_mapped from "../../app/src/ModelUtils_mapped.mjs";
import * as BeltListExtensions from "../../app/src/BeltListExtensions.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as GetMostSpecificRegexUseCase from "../../app/src/regex/GetMostSpecificRegexUseCase.mjs";
import * as ComputeUniqueAllowedValuesSetsUseCase from "../../model/src/eventSpecificPropertyValues/ComputeUniqueAllowedValuesSetsUseCase.mjs";
import * as ValidatePinnedValueWithPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/ValidatePinnedValueWithPropertyValuesUseCase.mjs";

function printLiteral(literal) {
  return literal.VAL;
}

function getShape(validations) {
  return Belt_Option.getWithDefault(Belt_List.head(Belt_List.keepMap(validations, (function (validation) {
                        if (typeof validation === "object" && validation.NAME === "Shape") {
                          return validation.VAL;
                        }
                        
                      }))), /* [] */0);
}

function printRawType(integrationType, hasSnowplowIntegerTypes, property) {
  var integerTypeInJsonSchema = Belt_Option.mapWithDefault(CliFeatureFlag.featureFlagValues.contents, false, (function (flags) {
          return Belt_Option.getWithDefault(Belt_MapString.get(flags, FeatureFlag.featureFlagToString("IntegerTypeInJsonSchema")), false);
        }));
  var type_ = property.type_;
  switch (type_) {
    case "any" :
        return ;
    case "bool" :
        return "boolean";
    case "int" :
        if (integrationType === "Snowplow" && hasSnowplowIntegerTypes || integerTypeInJsonSchema) {
          return "integer";
        } else {
          return "number";
        }
    case "float" :
    case "long" :
        return "number";
    case "object" :
        return "object";
    case "string" :
        return "string";
    default:
      return Pervasives.failwith("unsupported type " + type_);
  }
}

function printObjectValueType(integrationType, hasSnowplowIntegerTypes, value) {
  var integerTypeInJsonSchema = Belt_Option.mapWithDefault(CliFeatureFlag.featureFlagValues.contents, false, (function (flags) {
          return Belt_Option.getWithDefault(Belt_MapString.get(flags, FeatureFlag.featureFlagToString("IntegerTypeInJsonSchema")), false);
        }));
  var type_ = value.VAL.type_;
  if (type_ === "Object") {
    return Pervasives.failwith("object type not supported deeply nested");
  } else if (type_ === "Any") {
    return Pervasives.failwith("any type not supported deeply nested");
  } else if (type_ === "Int") {
    if (integrationType === "Snowplow" && hasSnowplowIntegerTypes || integerTypeInJsonSchema) {
      return "integer";
    } else {
      return "number";
    }
  } else if (type_ === "Long" || type_ === "Float") {
    return "number";
  } else if (type_ === "Bool") {
    return "boolean";
  } else {
    return "string";
  }
}

function printObjectType(integrationType, hasSnowplowIntegerTypes, item) {
  var underlying = printObjectValueType(integrationType, hasSnowplowIntegerTypes, item.value);
  var match = item.value;
  var restrictions;
  if (integrationType === "Snowplow" && hasSnowplowIntegerTypes) {
    restrictions = /* [] */0;
  } else {
    var match$1 = match.VAL.type_;
    restrictions = match$1 === "Long" || match$1 === "Int" ? ({
          hd: [
            "multipleOf",
            1
          ],
          tl: /* [] */0
        }) : /* [] */0;
  }
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "type",
                      item.list ? "array" : underlying
                    ],
                    tl: /* [] */0
                  },
                  item.list ? ({
                        hd: [
                          "items",
                          Json_encode.object_(Belt_List.concatMany([
                                    {
                                      hd: [
                                        "type",
                                        underlying
                                      ],
                                      tl: /* [] */0
                                    },
                                    restrictions
                                  ]))
                        ],
                        tl: /* [] */0
                      }) : restrictions
                ]));
}

function printAdditionalPropertyValidationsForSnowplow(hasSnowplowIntegerTypes, property) {
  var rawType = printRawType("Snowplow", hasSnowplowIntegerTypes, property);
  if (!(Caml_obj.equal(rawType, "number") || Caml_obj.equal(rawType, "integer"))) {
    return /* [] */0;
  }
  var hasMaxValidation = Belt_List.someU(property.validations, (function (validation) {
          if (typeof validation !== "object") {
            return false;
          }
          if (validation.NAME !== "Max") {
            return false;
          }
          var match = validation.VAL;
          if (typeof match !== "object") {
            return false;
          }
          var variant = match.NAME;
          if (variant === "FloatLit") {
            return true;
          } else {
            return variant === "IntLit";
          }
        }));
  var hasMinValidation = Belt_List.someU(property.validations, (function (validation) {
          if (typeof validation !== "object") {
            return false;
          }
          if (validation.NAME !== "Min") {
            return false;
          }
          var match = validation.VAL;
          if (typeof match !== "object") {
            return false;
          }
          var variant = match.NAME;
          if (variant === "FloatLit") {
            return true;
          } else {
            return variant === "IntLit";
          }
        }));
  return Belt_List.concatMany([
              hasMinValidation ? /* [] */0 : ({
                    hd: [
                      "minimum",
                      Js_int.min
                    ],
                    tl: /* [] */0
                  }),
              hasMaxValidation ? /* [] */0 : ({
                    hd: [
                      "maximum",
                      Js_int.max
                    ],
                    tl: /* [] */0
                  })
            ]);
}

function matchesAtLeastOneDestination(destinations, id) {
  return Belt_List.some(destinations, (function (destination) {
                return destination.id === id;
              }));
}

function printPropertyUnderlyingType(integrationType, hasSnowplowIntegerTypes, property, optional) {
  var propertyType = printRawType(integrationType, hasSnowplowIntegerTypes, property);
  return Belt_Option.map(propertyType, (function (propertyType) {
                if (optional) {
                  return Json_encode.list((function (prim) {
                                return prim;
                              }), {
                              hd: propertyType,
                              tl: {
                                hd: "null",
                                tl: /* [] */0
                              }
                            });
                } else {
                  return propertyType;
                }
              }));
}

function printPinnedPropertyType(optional, pinnedValue, integrationType, hasSnowplowIntegerTypes, property) {
  return Belt_List.concat(integrationType === "AvoJsonSchema" ? Belt_Option.mapWithDefault(printPropertyUnderlyingType(integrationType, hasSnowplowIntegerTypes, property, optional), /* [] */0, (function (underlying) {
                      return {
                              hd: [
                                "type",
                                underlying
                              ],
                              tl: /* [] */0
                            };
                    })) : /* [] */0, {
              hd: [
                "const",
                pinnedValue.VAL
              ],
              tl: /* [] */0
            });
}

function getNameMappings(model, property, isGlobalWorkspace) {
  return Belt_Array.keepMapU(model.rules, (function (rule) {
                var match = rule.item;
                var match$1 = rule.definition;
                if (typeof match !== "object") {
                  return ;
                }
                if (match.NAME !== "Property") {
                  return ;
                }
                if (typeof match$1 !== "object") {
                  return ;
                }
                var variant = match$1.NAME;
                var propertyId = match.VAL;
                if (variant === "NameMapping") {
                  if (propertyId !== property.id) {
                    return ;
                  }
                  var match$2 = match$1.VAL;
                  var name = match$2.name;
                  var destination = match$2.destination;
                  if (destination === undefined) {
                    return ;
                  }
                  if (typeof destination !== "object") {
                    return [
                            Belt_Option.getWithDefault(name, property.name),
                            "all"
                          ];
                  }
                  var id = destination.VAL;
                  if (matchesAtLeastOneDestination(Belt_List.fromArray(Curry._1(TrackingPlanMappedModel.Destinations.toArray, model.destinations)), id)) {
                    return [
                            Belt_Option.getWithDefault(name, property.name),
                            id
                          ];
                  } else {
                    return ;
                  }
                }
                if (variant !== "NameInLocalWorkspace") {
                  return ;
                }
                var match$3 = match$1.VAL;
                var match$4 = match$3.workspace;
                if (match$4 === undefined) {
                  return ;
                }
                if (typeof match$4 === "object") {
                  var name$1 = match$3.name;
                  if (name$1 !== undefined && isGlobalWorkspace && propertyId === property.id) {
                    return [
                            name$1,
                            match$4.VAL
                          ];
                  } else {
                    return ;
                  }
                }
                var name$2 = match$3.name;
                if (name$2 !== undefined && isGlobalWorkspace && propertyId === property.id) {
                  return [
                          name$2,
                          "all"
                        ];
                }
                
              }));
}

var Private = {
  escapeQuotes: CodegenCommons.escapeDoubleQuotes,
  getShape: getShape,
  printRawType: printRawType,
  printObjectValueType: printObjectValueType,
  printObjectType: printObjectType,
  printAdditionalPropertyValidationsForSnowplow: printAdditionalPropertyValidationsForSnowplow,
  matchesAtLeastOneDestination: matchesAtLeastOneDestination,
  printPropertyUnderlyingType: printPropertyUnderlyingType,
  printPinnedPropertyType: printPinnedPropertyType,
  getNameMappings: getNameMappings
};

function printPropertyValidation(model, legacyModel, modelPropertiesArray, integrationType, hasSnowplowIntegerTypes, eventId, sourceIds, property, includeRegex, includedDestinationIds, isGlobalWorkspace) {
  return BeltListExtensions.flatMap(property.validations, (function (validation) {
                var variant = validation.NAME;
                if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                  return /* [] */0;
                }
                if (variant === "Regex") {
                  if (!includeRegex) {
                    return /* [] */0;
                  }
                  var mostSpecificRegex = GetMostSpecificRegexUseCase.getMostSpecificRegex(validation.VAL, eventId);
                  if (mostSpecificRegex !== undefined && property.type_ === "string") {
                    return {
                            hd: [
                              "pattern",
                              mostSpecificRegex
                            ],
                            tl: /* [] */0
                          };
                  } else {
                    return /* [] */0;
                  }
                }
                if (variant === "Max") {
                  var match = validation.VAL;
                  var variant$1 = match.NAME;
                  if (variant$1 === "StringLit" || variant$1 === "BooleanLit") {
                    return /* [] */0;
                  } else {
                    return {
                            hd: [
                              "maximum",
                              match.VAL
                            ],
                            tl: /* [] */0
                          };
                  }
                }
                if (variant === "Min") {
                  var match$1 = validation.VAL;
                  var variant$2 = match$1.NAME;
                  if (variant$2 === "StringLit" || variant$2 === "BooleanLit") {
                    return /* [] */0;
                  } else {
                    return {
                            hd: [
                              "minimum",
                              match$1.VAL
                            ],
                            tl: /* [] */0
                          };
                  }
                }
                if (variant === "NestedProperty") {
                  var nestedProperties = Belt_List.keepMapU(validation.VAL, (function (nestedPropertyRef) {
                          return Belt_Option.flatMap(Curry._2(TrackingPlanMappedModel.Properties.get, model.properties, nestedPropertyRef.id), (function (nestedProperty) {
                                        if (integrationType === "Snowplow" && nestedProperty.name === "schema") {
                                          return ;
                                        } else {
                                          return [
                                                  nestedPropertyRef,
                                                  nestedProperty
                                                ];
                                        }
                                      }));
                        }));
                  var requiredProps = Belt_List.toArray(Belt_List.keepMap(nestedProperties, (function (param) {
                              var property = param[1];
                              if (!property.optionalWhenInObject) {
                                return CodegenRules.remapPropertyName(legacyModel, undefined, includedDestinationIds, eventId, property);
                              }
                              
                            })));
                  return Belt_List.concat({
                              hd: [
                                "properties",
                                Json_encode.object_(Belt_List.mapWithIndex(nestedProperties, (function (index, param) {
                                            var nestedProperty = param[1];
                                            var propertyName = CodegenRules.remapPropertyName(legacyModel, undefined, includedDestinationIds, eventId, nestedProperty);
                                            return [
                                                    propertyName,
                                                    printProperty(model, legacyModel, modelPropertiesArray, integrationType, hasSnowplowIntegerTypes, eventId, property.id, param[0], nestedProperty, sourceIds, nestedProperty.optionalWhenInObject, isGlobalWorkspace, includeRegex, index, includedDestinationIds, undefined)
                                                  ];
                                          })))
                              ],
                              tl: {
                                hd: [
                                  "additionalProperties",
                                  Belt_List.length(nestedProperties) <= 0
                                ],
                                tl: /* [] */0
                              }
                            }, Caml_obj.notequal(requiredProps, []) ? ({
                                  hd: [
                                    "required",
                                    requiredProps
                                  ],
                                  tl: /* [] */0
                                }) : /* [] */0);
                }
                if (variant !== "Shape") {
                  if (validation.VAL && !ModelUtils_mapped.hasMigrated(model, "EventSpecificAllowedPropertyValues")) {
                    return {
                            hd: [
                              "enum",
                              Belt_List.toArray(Belt_List.map(CodegenCommons.getLegacyMatches(property), (function (match_) {
                                          return match_;
                                        })))
                            ],
                            tl: /* [] */0
                          };
                  } else {
                    return /* [] */0;
                  }
                }
                if (!validation.VAL) {
                  return /* [] */0;
                }
                var shape = getShape(property.validations);
                return {
                        hd: [
                          "properties",
                          Json_encode.object_(Belt_List.map(shape, (function (item) {
                                      return [
                                              item.key,
                                              printObjectType(integrationType, hasSnowplowIntegerTypes, item)
                                            ];
                                    })))
                        ],
                        tl: {
                          hd: [
                            "additionalProperties",
                            false
                          ],
                          tl: {
                            hd: [
                              "required",
                              Belt_List.toArray(Belt_List.keepMap(shape, (function (item) {
                                          if (!item.optional) {
                                            return item.key;
                                          }
                                          
                                        })))
                            ],
                            tl: /* [] */0
                          }
                        }
                      };
              }));
}

function printEventSpecificPropertyValues(model, property, sourceIds, eventId, uniqueAllowedValuesSets) {
  if (!ModelUtils_mapped.hasMigrated(model, "EventSpecificAllowedPropertyValues")) {
    return /* [] */0;
  }
  if (!CodegenCommons.isEnum(property, model, sourceIds, eventId, uniqueAllowedValuesSets)) {
    return /* [] */0;
  }
  var eventSpecificAllowedValues = CodegenCommons.getPropertyAllowedValues(model, sourceIds, property, eventId, uniqueAllowedValuesSets);
  return {
          hd: [
            "enum",
            Belt_Array.map(Belt_SetString.toArray(eventSpecificAllowedValues), (function (match_) {
                    return match_;
                  }))
          ],
          tl: /* [] */0
        };
}

function printType(model, legacyModel, modelPropertiesArray, integrationType, hasSnowplowIntegerTypes, eventId, propertyRef, parentPropertyId, property, sourceIds, optional, isGlobalWorkspace, includeRegex, uniqueAllowedValuesSets, includedDestinationIds, param) {
  var l;
  var exit = 0;
  switch (property.type_) {
    case "any" :
        l = /* [] */0;
        break;
    case "object" :
        if (integrationType === "MParticle") {
          l = /* [] */0;
        } else {
          exit = 1;
        }
        break;
    default:
      exit = 1;
  }
  if (exit === 1) {
    var exit$1 = 0;
    if (property.list && integrationType === "MParticle") {
      l = /* [] */0;
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (propertyRef !== undefined) {
        var pinnedValue = propertyRef.pinnedValue;
        var exit$2 = 0;
        if (pinnedValue !== undefined && ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, property, ModelUtils_mapped.hasMigrated(model, "EventSpecificAllowedPropertyValues")) && integrationType !== "MParticle" && integrationType !== "RudderStack") {
          l = printPinnedPropertyType(optional, pinnedValue, integrationType, hasSnowplowIntegerTypes, property);
        } else {
          exit$2 = 3;
        }
        if (exit$2 === 3) {
          if (parentPropertyId !== undefined && Belt_Option.isSome(CodegenRules.getNestedPropertyPinnedValue(propertyRef, parentPropertyId, eventId, legacyModel)) && integrationType !== "MParticle" && integrationType !== "RudderStack") {
            var match = Belt_Option.getExn(CodegenRules.getNestedPropertyPinnedValue(propertyRef, parentPropertyId, eventId, legacyModel));
            l = printPinnedPropertyType(optional, match[0], integrationType, hasSnowplowIntegerTypes, property);
          } else {
            l = /* [] */0;
          }
        }
        
      } else {
        l = /* [] */0;
      }
    }
    
  }
  var underlying = printPropertyUnderlyingType(integrationType, hasSnowplowIntegerTypes, property, optional);
  var restrictions = Belt_List.concatMany([
        integrationType === "Snowplow" && hasSnowplowIntegerTypes && property.type_ === "int" || !(property.type_ === "int" || property.type_ === "long") ? /* [] */0 : ({
              hd: [
                "multipleOf",
                1
              ],
              tl: /* [] */0
            }),
        printPropertyValidation(model, legacyModel, modelPropertiesArray, integrationType, hasSnowplowIntegerTypes, eventId, sourceIds, property, includeRegex, includedDestinationIds, isGlobalWorkspace),
        printEventSpecificPropertyValues(model, property, sourceIds, eventId, uniqueAllowedValuesSets),
        integrationType === "Snowplow" ? printAdditionalPropertyValidationsForSnowplow(hasSnowplowIntegerTypes, property) : /* [] */0
      ]);
  var match$1 = property.list;
  return Belt_List.concatMany([
              l,
              match$1 ? (
                  optional ? ({
                        hd: [
                          "type",
                          Json_encode.list((function (prim) {
                                  return prim;
                                }), {
                                hd: "array",
                                tl: {
                                  hd: "null",
                                  tl: /* [] */0
                                }
                              })
                        ],
                        tl: /* [] */0
                      }) : ({
                        hd: [
                          "type",
                          "array"
                        ],
                        tl: /* [] */0
                      })
                ) : (
                  underlying !== undefined ? ({
                        hd: [
                          "type",
                          underlying
                        ],
                        tl: /* [] */0
                      }) : /* [] */0
                ),
              property.list && integrationType !== "RudderStack" ? ({
                    hd: [
                      "items",
                      Json_encode.object_(Belt_List.concatMany([
                                Belt_Option.mapWithDefault(printRawType(integrationType, hasSnowplowIntegerTypes, property), /* [] */0, (function (rawType) {
                                        return {
                                                hd: [
                                                  "type",
                                                  rawType
                                                ],
                                                tl: /* [] */0
                                              };
                                      })),
                                restrictions
                              ]))
                    ],
                    tl: /* [] */0
                  }) : restrictions
            ]);
}

function printProperty(model, legacyModel, modelPropertiesArray, integrationType, hasSnowplowIntegerTypes, eventId, parentPropertyId, propertyRef, property, sourceIds, optional, isGlobalWorkspace, includeRegex, index, includedDestinationIds, param) {
  var uniqueAllowedValuesSets = ComputeUniqueAllowedValuesSetsUseCase.compute(model, modelPropertiesArray, sourceIds, Caml_option.some(eventId), property);
  var nameMappings = getNameMappings(model, property, isGlobalWorkspace);
  var description_1 = CodegenCommons.escapeDoubleQuotes(property.description);
  var description = [
    "description",
    description_1
  ];
  var globalDescription = Belt_Option.flatMapU(model.globalRequirements, (function (param) {
          return Belt_Option.mapU(Curry._2(TrackingPlanMappedModel.Properties.get, param.properties, property.id), (function (param) {
                        return [
                                "globalDescription",
                                param.description
                              ];
                      }));
        }));
  var descriptions = Belt_Option.mapWithDefaultU(globalDescription, {
        hd: description,
        tl: /* [] */0
      }, (function (globalDescription) {
          return {
                  hd: description,
                  tl: {
                    hd: globalDescription,
                    tl: /* [] */0
                  }
                };
        }));
  var match = property.sendAs;
  var match$1 = integrationType === "AvoJsonSchema";
  var tmp;
  tmp = typeof match !== "object" || !match$1 ? /* [] */0 : ({
        hd: [
          "groupTypeId",
          match._0
        ],
        tl: /* [] */0
      });
  return Json_encode.object_(Belt_List.concatMany([
                  integrationType === "AvoJsonSchema" ? ({
                        hd: [
                          "id",
                          property.id
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  integrationType === "AvoJsonSchema" ? Belt_Option.mapWithDefault(index, /* [] */0, (function (index) {
                            return {
                                    hd: [
                                      "index",
                                      index
                                    ],
                                    tl: /* [] */0
                                  };
                          })) : /* [] */0,
                  integrationType === "AvoJsonSchema" ? descriptions : ({
                        hd: description,
                        tl: /* [] */0
                      }),
                  integrationType === "AvoJsonSchema" ? ({
                        hd: [
                          "nameMapping",
                          Belt_Array.mapU(nameMappings, (function (param) {
                                  return Json_encode.object_({
                                              hd: [
                                                "name",
                                                param[0]
                                              ],
                                              tl: {
                                                hd: [
                                                  isGlobalWorkspace ? "workspaceId" : "destinationId",
                                                  param[1]
                                                ],
                                                tl: /* [] */0
                                              }
                                            });
                                }))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  integrationType === "AvoJsonSchema" && property.operation === "SnowplowContext" ? ({
                        hd: [
                          "operations",
                          property.operation === "SnowplowContext" ? ["SnowplowContext"] : []
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  integrationType === "AvoJsonSchema" ? ({
                        hd: [
                          "isSystemProperty",
                          property.sendAs === "SystemProperty"
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  tmp,
                  printType(model, legacyModel, modelPropertiesArray, integrationType, hasSnowplowIntegerTypes, eventId, propertyRef, parentPropertyId, property, sourceIds, optional, isGlobalWorkspace, includeRegex, uniqueAllowedValuesSets, includedDestinationIds, undefined)
                ]));
}

function requiredPropertiesArray(model, legacyModel, includedDestinationIds, properties, $$event, includedSources) {
  return Belt_List.toArray(Belt_List.keepMap(properties, (function (property) {
                    var optional = ModelUtils_mapped.isPropertyOptionalOnEventsAndSources({
                          hd: $$event.id,
                          tl: /* [] */0
                        }, includedSources, model.events, property);
                    if (!optional) {
                      return CodegenRules.remapPropertyName(legacyModel, undefined, includedDestinationIds, $$event.id, property);
                    }
                    
                  })));
}

function printPropertiesJsonObject(model, legacyModel, modelPropertiesArray, includedDestinationIds, properties, integrationType, hasSnowplowIntegerTypes, $$event, includedSources, includeRegex, isGlobalWorkspace) {
  return Json_encode.object_(Belt_List.map(properties, (function (property) {
                    var optional = ModelUtils_mapped.isPropertyOptionalOnEventsAndSources({
                          hd: $$event.id,
                          tl: /* [] */0
                        }, includedSources, model.events, property);
                    var propertyRef = ModelUtils_mapped.getPropertyRefById($$event.id, property.id, model.events);
                    return [
                            CodegenRules.remapPropertyName(legacyModel, undefined, includedDestinationIds, $$event.id, property),
                            printProperty(model, legacyModel, modelPropertiesArray, integrationType, hasSnowplowIntegerTypes, $$event.id, undefined, propertyRef, property, Belt_List.toArray(includedSources), optional, isGlobalWorkspace, includeRegex, undefined, includedDestinationIds, undefined)
                          ];
                  })));
}

function printPropertiesJsonSchema(model, legacyModel, includedDestinationIds, properties, modelPropertiesArray, integrationType, hasSnowplowIntegerTypes, $$event, includedSources, includeRegex, isGlobalWorkspace) {
  var requiredProps = requiredPropertiesArray(model, legacyModel, includedDestinationIds, properties, $$event, includedSources);
  return Json_encode.object_(Belt_List.concat({
                  hd: [
                    "type",
                    "object"
                  ],
                  tl: {
                    hd: [
                      "properties",
                      printPropertiesJsonObject(model, legacyModel, modelPropertiesArray, includedDestinationIds, properties, integrationType, hasSnowplowIntegerTypes, $$event, includedSources, includeRegex, isGlobalWorkspace)
                    ],
                    tl: {
                      hd: [
                        "additionalProperties",
                        false
                      ],
                      tl: /* [] */0
                    }
                  }
                }, Caml_obj.notequal(requiredProps, []) ? ({
                      hd: [
                        "required",
                        requiredProps
                      ],
                      tl: /* [] */0
                    }) : /* [] */0));
}

export {
  printLiteral ,
  Private ,
  printPropertyValidation ,
  printEventSpecificPropertyValues ,
  printType ,
  printProperty ,
  requiredPropertiesArray ,
  printPropertiesJsonObject ,
  printPropertiesJsonSchema ,
}
/* CodegenRules Not a pure module */
