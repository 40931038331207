// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Select from "../Select.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LoadingCircle from "../LoadingCircle.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GetMParticleDataPlanModel from "../../../shared/models/GetMParticleDataPlanModel.mjs";

function IntegrationHeaderPlanVersionPicker(Props) {
  var integration = Props.integration;
  var branchId = Props.branchId;
  var integrationId = Props.integrationId;
  var mParticlePlanVersion = Props.mParticlePlanVersion;
  var handleChange = Props.handleChange;
  var workspace = WorkspaceContext.use();
  var debouncedIntegration = Hooks.useDebounced((function (param) {
          return integration;
        }), 1000);
  var match = React.useState(function (param) {
        return "Loading";
      });
  var setVersions = match[1];
  var versions = match[0];
  React.useEffect((function (param) {
          Firebase.Auth.unsafeGetCurrentUser(Firebase.app().auth()).getIdToken().then(function (token) {
                    return fetch(Firebase.apiUrl + "/integration/mParticle/versions", {
                                method: "POST",
                                body: Caml_option.some(JSON.stringify(GetMParticleDataPlanModel.encodeRequestBody({
                                              schemaId: workspace.id,
                                              branchId: branchId,
                                              integrationId: integrationId
                                            }))),
                                headers: Caml_option.some(new Headers([
                                          [
                                            "Accept",
                                            "application/json"
                                          ],
                                          [
                                            "Content-Type",
                                            "application/json"
                                          ],
                                          [
                                            "Authorization",
                                            "Bearer " + token
                                          ]
                                        ]))
                              });
                  }).then(function (res) {
                  return res.json();
                }).then(function (response) {
                var response$1 = GetMParticleDataPlanModel.decodeResponseBody(response);
                return Promise.resolve(Curry._1(setVersions, (function (param) {
                                  var error = response$1.error;
                                  if (error !== undefined) {
                                    return {
                                            NAME: "Error",
                                            VAL: error
                                          };
                                  } else {
                                    return {
                                            NAME: "Loaded",
                                            VAL: Belt_Option.getWithDefault(response$1.versions, [])
                                          };
                                  }
                                })));
              });
        }), [debouncedIntegration]);
  var match$1 = integration.config;
  if (match$1 === undefined) {
    return null;
  }
  if (match$1.TAG !== "MParticleDataMaster") {
    return null;
  }
  var integrationConfig = match$1._0;
  var isEnabled = integrationConfig.clientId !== undefined && integrationConfig.clientSecret !== undefined && integrationConfig.workspaceId !== undefined ? integrationConfig.planId !== undefined : false;
  return React.createElement(React.Fragment, undefined, typeof versions === "object" ? (
                versions.NAME === "Loaded" ? null : React.createElement($$Text.make, {
                        color: Styles.Color.red,
                        children: "Could not load existing plan versions"
                      })
              ) : React.createElement(LoadingCircle.make, {
                    color: Styles.Color.light07
                  }), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement(Select.make, {
                  disabled: !isEnabled,
                  onSelect: (function (version) {
                      switch (version) {
                        case "loading" :
                        case "new" :
                            return Curry._1(handleChange, undefined);
                        default:
                          return Curry._1(handleChange, Caml_format.int_of_string(version));
                      }
                    }),
                  options: Belt_Array.concat([[
                          {
                            NAME: "Label",
                            VAL: "Publish to a new plan version"
                          },
                          "new"
                        ]], typeof versions === "object" ? (
                          versions.NAME === "Loaded" ? Belt_Array.reverse(Belt_Array.map(versions.VAL, (function (version) {
                                        return [
                                                {
                                                  NAME: "Label",
                                                  VAL: "Publish to v. " + String(version)
                                                },
                                                String(version)
                                              ];
                                      }))) : [[
                                {
                                  NAME: "Disabled",
                                  VAL: "Could not load existing plan versions"
                                },
                                "loading"
                              ]]
                        ) : [[
                            {
                              NAME: "Disabled",
                              VAL: "Loading existing plan versions..."
                            },
                            "loading"
                          ]]),
                  value: mParticlePlanVersion !== undefined ? String(mParticlePlanVersion) : "new"
                }));
}

var make = IntegrationHeaderPlanVersionPicker;

export {
  make ,
}
/* Text Not a pure module */
