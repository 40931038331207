// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Models from "./Models.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BranchStatus from "./externals/BranchStatus.mjs";
import * as ProfilePhoto from "./ProfilePhoto.mjs";
import * as Core from "@mantine/core";
import * as Collaborators from "./Collaborators.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as EditCollaboratorsPopup from "./EditCollaboratorsPopup.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.paddingTop(Css.px(8)),
              tl: {
                hd: Css.paddingRight(Css.px(20)),
                tl: {
                  hd: Css.paddingBottom(Css.px(8)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(24)),
                    tl: {
                      hd: Css.minHeight(Css.px(62)),
                      tl: {
                        hd: Css.flexShrink(0.0),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var photosStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.marginLeft(Css.px(4)),
          tl: /* [] */0
        }
      }
    });

var photoStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(-4)),
      tl: /* [] */0
    });

function dropdownStyles(offset) {
  var variant = offset.NAME;
  var tmp;
  if (variant === "y") {
    tmp = {
      hd: Css.translateY(Css.px(offset.VAL)),
      tl: /* [] */0
    };
  } else if (variant === "xy") {
    var match = offset.VAL;
    tmp = {
      hd: Css.translateX(Css.px(match[0])),
      tl: {
        hd: Css.translateY(Css.px(match[1])),
        tl: /* [] */0
      }
    };
  } else {
    tmp = {
      hd: Css.translateX(Css.px(offset.VAL)),
      tl: /* [] */0
    };
  }
  return {
          hd: Css.borderRadius(Css.px(5)),
          tl: {
            hd: Css.padding2(Css.px(4), Css.px(0)),
            tl: {
              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                            NAME: "num",
                            VAL: 0.10
                          }))),
              tl: {
                hd: Css.transforms(tmp),
                tl: /* [] */0
              }
            }
          }
        };
}

function CollaboratorsView$CollaboratorProfilePhotos(Props) {
  var ids = Props.ids;
  var notDisplayedCount = Props.notDisplayedCount;
  if (ids.length !== 0) {
    return React.createElement("div", {
                className: photosStyles
              }, Belt_Array.map(ids, (function (id) {
                      return React.createElement("div", {
                                  key: id,
                                  className: photoStyles
                                }, React.createElement(ProfilePhoto.FromUserId.make, {
                                      id: id,
                                      size: 28
                                    }));
                    })), notDisplayedCount !== 0 ? React.createElement("div", {
                      className: photoStyles
                    }, React.createElement(ProfilePhoto.Base.make, {
                          email: "+" + String(notDisplayedCount),
                          size: 28
                        })) : null);
  } else {
    return React.createElement($$Text.make, {
                size: "Small",
                weight: "Semi",
                color: Styles.Color.light08,
                children: "No Collaborators"
              });
  }
}

var CollaboratorProfilePhotos = {
  make: CollaboratorsView$CollaboratorProfilePhotos
};

function CollaboratorsView(Props) {
  var branchId = Props.branchId;
  var branchStatus = Props.branchStatus;
  var viewerId = ViewerContext.use().id;
  var globalSend = GlobalSendContext.use();
  var schemaBundle = SchemaBundleContext.use();
  var collaborators = Collaborators.useCollaborators(branchId);
  var sendActions = SendActionsContext.use();
  var match = WorkspaceContext.use();
  var members = match.members;
  var memberIds = React.useMemo((function (param) {
          return Belt_List.toArray(Belt_List.map(members, (function (member) {
                            return member.id;
                          })));
        }), [members]);
  var reviewers = React.useMemo((function (param) {
          return Belt_List.keep(collaborators, (function (c) {
                        return Caml_obj.equal(Caml_option.undefined_to_opt(c.isReviewer), true);
                      }));
        }), [collaborators]);
  var workspaceUsers = FirebaseFetcherHooks.useUsers(memberIds);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match$1 = React.useState(function (param) {
        return false;
      });
  var setPopupOpen = match$1[1];
  var availability = AvoLimits.Collaboration.computeAvailability(workspace);
  var membersToDisplay = Belt_Array.slice(Belt_Array.map(Belt_List.toArray(collaborators), (function (prim) {
              return prim.id;
            })), 0, 5);
  var handleToggleCollaborator = function (user, checked) {
    if (checked) {
      Collaborators.addCollaborator(schemaBundle, viewerId, {
            NAME: "Branch",
            VAL: branchId
          }, "Branch", branchId, Caml_option.nullable_to_opt(user.email), "AddCollaborator", sendActions, undefined, undefined, user.id);
    } else {
      var collaborator = Belt_List.getBy(collaborators, (function (c) {
              return c.id === user.id;
            }));
      if (collaborator !== undefined) {
        var collaborator$1 = Caml_option.valFromOption(collaborator);
        Collaborators.removeCollaborator(schemaBundle, viewerId, {
              NAME: "Branch",
              VAL: branchId
            }, "Branch", branchId, collaborator$1, sendActions, (function (param) {
                var arg = BranchStatus.toAnalytics(branchStatus);
                var arg$1;
                arg$1 = typeof branchStatus !== "object" || branchStatus.TAG !== "Approved" ? 0 : Belt_List.length(branchStatus._0);
                var arg$2 = workspace.settings.numRequiredApprovals;
                var arg$3 = workspace.settings.requireAdminApproval ? "Admin" : "Editor";
                var arg$4 = Belt_List.size(reviewers);
                var member = Belt_List.getBy(workspace.members, (function (member) {
                        return member.id === collaborator$1.id;
                      }));
                var arg$5;
                if (member !== undefined) {
                  var match = Models.Role.tFromJs(Caml_option.valFromOption(member).role);
                  arg$5 = match === "Admin" ? "Admin" : "Editor";
                } else {
                  arg$5 = "Editor";
                }
                var arg$6 = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.email), "");
                var arg$7 = schemaBundle.schemaId;
                AnalyticsUtils.withSchemaBundle((function (param) {
                        var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
                          return AnalyticsRe.branchReviewerRemoved(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21);
                        };
                        return function (param) {
                          var func$1 = Curry._1(func, param);
                          return function (param) {
                            var func$2 = Curry._1(func$1, param);
                            return function (param) {
                              var func$3 = Curry._1(func$2, param);
                              return function (param) {
                                var func$4 = Curry._1(func$3, param);
                                return function (param) {
                                  var func$5 = Curry._1(func$4, param);
                                  return function (param) {
                                    var func$6 = Curry._1(func$5, param);
                                    return function (param) {
                                      var func$7 = Curry._1(func$6, param);
                                      return function (param) {
                                        var func$8 = Curry._1(func$7, param);
                                        return function (param) {
                                          var func$9 = Curry._1(func$8, param);
                                          return function (param) {
                                            return Curry.app(func$9, [
                                                        param,
                                                        arg,
                                                        arg$1,
                                                        arg$2,
                                                        arg$3,
                                                        "BranchHeader",
                                                        arg$4,
                                                        arg$5,
                                                        arg$6,
                                                        branchId,
                                                        branchId,
                                                        arg$7
                                                      ]);
                                          };
                                        };
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          };
                        };
                      }), schemaBundle);
              }));
      }
      
    }
  };
  var tmp = {
    label: "Collaborators",
    onClick: (function (param) {
        if (availability === "AvailableDuringTrial" || availability === "Available") {
          return Curry._1(setPopupOpen, (function (popupOpen) {
                        return !popupOpen;
                      }));
        } else {
          return Curry._1(globalSend, {
                      TAG: "OpenModal",
                      _0: {
                        NAME: "BillingPrompt",
                        VAL: "AddBranchCollaborator"
                      }
                    });
        }
      }),
    style: "outline"
  };
  var tmp$1 = availability === "Available" ? undefined : "lightning";
  if (tmp$1 !== undefined) {
    tmp.icon = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(CollaboratorsView$CollaboratorProfilePhotos, {
                  ids: membersToDisplay,
                  notDisplayedCount: Belt_List.length(collaborators) - membersToDisplay.length | 0
                }), React.createElement(Spacer.make, {
                  width: 16
                }), React.createElement(Mantine.Popover.make, {
                  onChange: (function (popupOpen) {
                      Curry._1(setPopupOpen, (function (param) {
                              return popupOpen;
                            }));
                    }),
                  opened: match$1[0],
                  position: "bottom-end",
                  children: null
                }, React.createElement(Core.Popover.Target, {
                      children: React.createElement(Button.make, tmp)
                    }), React.createElement(Mantine.Popover.Dropdown.make, {
                      additionalStyles: dropdownStyles({
                            NAME: "xy",
                            VAL: [
                              0,
                              0
                            ]
                          }),
                      children: React.createElement(EditCollaboratorsPopup.WithUsers.make, {
                            collaboratorIds: Belt_List.map(collaborators, (function (prim) {
                                    return prim.id;
                                  })),
                            onToggle: handleToggleCollaborator,
                            users: workspaceUsers
                          })
                    })));
}

var make = CollaboratorsView;

export {
  rootStyles ,
  photosStyles ,
  photoStyles ,
  dropdownStyles ,
  CollaboratorProfilePhotos ,
  make ,
}
/* rootStyles Not a pure module */
