// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Input from "./Input.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as PropertyValidationUtils from "./PropertyValidationUtils.mjs";
import * as GetAllPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/GetAllPropertyValuesUseCase.mjs";

function PinNestedPropertyValueModal(Props) {
  var model = Props.model;
  var propertyId = Props.propertyId;
  var nestedPropertyId = Props.nestedPropertyId;
  var onClose = Props.onClose;
  var sendActions = SendActionsContext.use();
  var match = React.useState(function (param) {
        return Belt_Option.flatMap(ModelUtils.getNestedPropertyRefById(propertyId, nestedPropertyId, model), (function (propRef) {
                      return propRef.pinnedValue;
                    }));
      });
  var setPinnedValue = match[1];
  var pinnedValue = match[0];
  var match$1 = ModelUtils.getNestedPropertyRefById(propertyId, nestedPropertyId, model);
  var match$2 = ModelUtils.getPropertyById(propertyId, model);
  var match$3 = ModelUtils.getPropertyById(nestedPropertyId, model);
  var tmp;
  var exit = 0;
  if (match$1 !== undefined) {
    if (match$2 !== undefined && !(match$2.TAG === "PropertyRef" || match$3 === undefined)) {
      var property = match$2._0;
      if (match$3.TAG === "PropertyRef") {
        exit = 1;
      } else {
        var nestedProperty = match$3._0;
        var allowedValues = Belt_List.fromArray(GetAllPropertyValuesUseCase.getWithLegacyFallback(ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"), nestedProperty));
        var match$4 = nestedProperty.type_;
        var tmp$1;
        switch (match$4) {
          case "bool" :
              var tmp$2;
              var exit$1 = 0;
              if (pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "BooleanLit") {
                tmp$2 = null;
              } else {
                exit$1 = 2;
              }
              if (exit$1 === 2) {
                tmp$2 = React.createElement("option", undefined, "Pick a value");
              }
              tmp$1 = React.createElement("select", {
                    className: Curry._1(Css.merge, {
                          hd: Curry._1(Css.style, Styles.select),
                          tl: {
                            hd: Curry._1(Css.style, {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.padding(Css.px(15)),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: /* [] */0
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }),
                    value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "BooleanLit" ? Pervasives.string_of_bool(pinnedValue.VAL) : "",
                    onChange: (function ($$event) {
                        Curry._1(setPinnedValue, (function (param) {
                                return {
                                        NAME: "BooleanLit",
                                        VAL: Pervasives.bool_of_string($$event.target.value)
                                      };
                              }));
                      })
                  }, tmp$2, React.createElement("option", {
                        id: "true"
                      }, "true"), React.createElement("option", {
                        id: "false"
                      }, "false"));
              break;
          case "float" :
              var tmp$3 = {
                value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "FloatLit" ? pinnedValue.VAL.toString() : "",
                onChange: (function (value) {
                    Curry._1(setPinnedValue, (function (param) {
                            return {
                                    NAME: "FloatLit",
                                    VAL: Caml_format.float_of_string(value)
                                  };
                          }));
                  }),
                placeholder: Belt_Option.getWithDefault(Belt_List.head(Belt_List.keepMap(nestedProperty.validations, (function (validation) {
                                if (typeof validation !== "object") {
                                  return ;
                                }
                                if (validation.NAME !== "Min") {
                                  return ;
                                }
                                var match = validation.VAL;
                                if (typeof match === "object" && match.NAME === "FloatLit") {
                                  return match.VAL.toString();
                                }
                                
                              }))), "42"),
                step: 0.001,
                type_: "number"
              };
              var tmp$4 = PropertyValidationUtils.getMinFloat(nestedProperty);
              if (tmp$4 !== undefined) {
                tmp$3.min = tmp$4;
              }
              var tmp$5 = PropertyValidationUtils.getMaxFloat(nestedProperty);
              if (tmp$5 !== undefined) {
                tmp$3.max = tmp$5;
              }
              tmp$1 = React.createElement(Input.make, tmp$3);
              break;
          case "int" :
              var tmp$6 = {
                value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "IntLit" ? String(pinnedValue.VAL) : "",
                onChange: (function (value) {
                    Curry._1(setPinnedValue, (function (param) {
                            return {
                                    NAME: "IntLit",
                                    VAL: Caml_format.int_of_string(value)
                                  };
                          }));
                  }),
                placeholder: Belt_Option.getWithDefault(Belt_Option.map(PropertyValidationUtils.getMinInt(nestedProperty), (function (prim) {
                            return String(prim);
                          })), "42"),
                step: 1,
                type_: "number"
              };
              var tmp$7 = PropertyValidationUtils.getMinFloat(nestedProperty);
              if (tmp$7 !== undefined) {
                tmp$6.min = tmp$7;
              }
              var tmp$8 = PropertyValidationUtils.getMaxFloat(nestedProperty);
              if (tmp$8 !== undefined) {
                tmp$6.max = tmp$8;
              }
              tmp$1 = React.createElement(Input.make, tmp$6);
              break;
          case "string" :
              if (allowedValues) {
                var tmp$9;
                var exit$2 = 0;
                if (pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "StringLit") {
                  tmp$9 = null;
                } else {
                  exit$2 = 2;
                }
                if (exit$2 === 2) {
                  tmp$9 = React.createElement("option", undefined, "Pick a value");
                }
                tmp$1 = React.createElement("select", {
                      className: Curry._1(Css.merge, {
                            hd: Curry._1(Css.style, Styles.select),
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.width(Css.pct(100)),
                                    tl: {
                                      hd: Css.padding(Css.px(15)),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: /* [] */0
                                      }
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "StringLit" ? pinnedValue.VAL : "",
                      onChange: (function ($$event) {
                          Curry._1(setPinnedValue, (function (param) {
                                  return {
                                          NAME: "StringLit",
                                          VAL: $$event.target.value
                                        };
                                }));
                        })
                    }, tmp$9, Belt_List.toArray(Belt_List.map(allowedValues, (function (match_) {
                                return React.createElement("option", {
                                            id: match_
                                          }, match_);
                              }))));
              } else {
                tmp$1 = React.createElement(Input.make, {
                      value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "StringLit" ? pinnedValue.VAL : "",
                      onChange: (function (value) {
                          Curry._1(setPinnedValue, (function (param) {
                                  return {
                                          NAME: "StringLit",
                                          VAL: value
                                        };
                                }));
                        }),
                      placeholder: "Pinned Value"
                    });
              }
              break;
          default:
            tmp$1 = "Cannot pin property of type " + match$4;
        }
        tmp = React.createElement("div", undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(5), Css.px(0)),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.regular),
                          tl: {
                            hd: Css.lineHeight(Css.pct(150.0)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, "When the value of the property", React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin2(Css.px(0), Css.px(3)),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: /* [] */0
                            }
                          })
                    }, nestedProperty.name), "is pinned it will always be sent as the pinned value with the ", React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin2(Css.px(0), Css.px(3)),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: /* [] */0
                            }
                          })
                    }, property.name), "property. Developers will not be required to pass in value for the property when an event is being sent."), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding4(Css.px(15), Css.px(0), Css.px(10), Css.px(0)),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.regular),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: /* [] */0
                          }
                        }
                      })
                }, "Always send", React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin2(Css.px(0), Css.px(3)),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: /* [] */0
                            }
                          })
                    }, nestedProperty.name), "with", React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin2(Css.px(0), Css.px(3)),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: /* [] */0
                            }
                          })
                    }, property.name), "as:"), React.createElement("form", {
                  onSubmit: (function (prim) {
                      prim.preventDefault();
                    })
                }, tmp$1, Caml_obj.notequal(match$1.pinnedValue, pinnedValue) ? React.createElement(Button.make, {
                        label: "Set Pinned Value",
                        onClick: (function (param) {
                            if (pinnedValue !== undefined) {
                              Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    [[
                                        {
                                          NAME: "UpdateNestedPropertyPinnedValue",
                                          VAL: [
                                            propertyId,
                                            nestedPropertyId,
                                            pinnedValue
                                          ]
                                        },
                                        {
                                          propertyId: propertyId,
                                          propertyQuery: propertyId
                                        }
                                      ]]
                                  ]);
                            } else {
                              Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    [[
                                        {
                                          NAME: "RemoveNestedPropertyPinnedValue",
                                          VAL: [
                                            propertyId,
                                            nestedPropertyId
                                          ]
                                        },
                                        {
                                          propertyId: propertyId,
                                          propertyQuery: propertyId
                                        }
                                      ]]
                                  ]);
                            }
                            Curry._1(onClose, undefined);
                          })
                      }) : null), React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.button),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.small),
                                  tl: {
                                    hd: Css.color(Styles.Color.light08),
                                    tl: {
                                      hd: Css.paddingTop(Css.px(20)),
                                      tl: {
                                        hd: Css.textAlign("center"),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.color(Styles.Color.light10),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      Curry._1(onClose, undefined);
                    })
                }, "Cancel"));
      }
    } else {
      exit = 1;
    }
  } else {
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.padding(Css.px(25)),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: {
                    hd: Css.color(Styles.Color.red),
                    tl: /* [] */0
                  }
                }
              })
        }, "Nested property not found on property.");
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.padding(Css.px(25)),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: {
                    hd: Css.color(Styles.Color.red),
                    tl: /* [] */0
                  }
                }
              })
        }, "Property not found.");
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(500)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.padding4(Css.px(30), Css.px(30), Css.px(30), Css.px(30)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.cursor("default"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Large",
                  weight: "Semi",
                  textAlign: "center",
                  children: "Pin Property Value"
                }), React.createElement(Spacer.make, {
                  height: 15
                }), tmp);
}

var make = PinNestedPropertyValueModal;

export {
  make ,
}
/* Css Not a pure module */
