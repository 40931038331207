// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Invite from "./Invite.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DidMount from "./DidMount.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LoadingFullscreen from "./LoadingFullscreen.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function NoAccess$NoInvites(Props) {
  var schemaId = Props.schemaId;
  React.useEffect((function (param) {
          AnalyticsRe.schemaNotFound(false, schemaId);
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.minHeight(Css.vh(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(50)),
                        tl: {
                          hd: Css.flex({
                                NAME: "num",
                                VAL: 1.0
                              }),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("a", {
                      href: "/"
                    }, React.createElement(Icons.AvoLogo.make, {
                          width: 100,
                          fill: Styles.Color.light04
                        }))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flex({
                              NAME: "num",
                              VAL: 5.0
                            }),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.textAlign("center"),
                              tl: {
                                hd: Css.maxWidth(Css.px(250)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.bold),
                            tl: {
                              hd: Css.color(Styles.Color.light04),
                              tl: {
                                hd: Css.fontSize(Css.px(100)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, "404"), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.flex({
                                  NAME: "num",
                                  VAL: 5.0
                                }),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.medium),
                                tl: {
                                  hd: Css.color(Styles.Color.light10),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, "We could not find the project you requested 🤔"), React.createElement(Spacer.make, {
                      height: 24
                    }), React.createElement(Button.make, {
                      label: "View My Projects",
                      onClick: (function (param) {
                          Router.push(undefined, "/");
                        })
                    })), React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.button),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.flex({
                                      NAME: "num",
                                      VAL: 1.0
                                    }),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.color(Styles.Color.light07),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.medium),
                                      tl: {
                                        hd: Css.padding(Css.px(15)),
                                        tl: {
                                          hd: Css.textDecoration("underline"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      Intercom.showNewMessage();
                    })
                }, "Does something look wrong? Contact us!"));
}

var NoInvites = {
  make: NoAccess$NoInvites
};

function NoAccess(Props) {
  var schemaId = Props.schemaId;
  var userId = Props.userId;
  var auth = Firebase.getAuth();
  var currentUserEmail = Belt_Option.getExn(Caml_option.nullable_to_opt(auth.currentUser)).email;
  var userSchemaInvitesState = FirebaseFetcherHooks.useUserSchemaInvites((currentUserEmail == null) ? undefined : Caml_option.some(currentUserEmail));
  if (typeof userSchemaInvitesState === "object") {
    return Belt_Option.mapWithDefault(Belt_List.head(Belt_List.keep(userSchemaInvitesState.VAL, (function (invite) {
                          return invite.schemaId === schemaId;
                        }))), React.createElement(NoAccess$NoInvites, {
                    schemaId: schemaId
                  }), (function (invite) {
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.justifyContent("spaceBetween"),
                                        tl: {
                                          hd: Css.flexDirection("column"),
                                          tl: {
                                            hd: Css.minHeight(Css.vh(100)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement(DidMount.make, {
                                  didMount: (function (param) {
                                      AnalyticsRe.schemaNotFound(true, schemaId);
                                    })
                                }), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.padding(Css.px(50)),
                                        tl: {
                                          hd: Css.flex({
                                                NAME: "num",
                                                VAL: 1.0
                                              }),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement("a", {
                                      href: "/"
                                    }, React.createElement(Icons.AvoLogo.make, {
                                          width: 100,
                                          fill: Styles.Color.light04
                                        }))), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.flex({
                                              NAME: "num",
                                              VAL: 1.0
                                            }),
                                        tl: /* [] */0
                                      })
                                }, React.createElement(Invite.make, {
                                      invite: invite,
                                      userId: userId
                                    })), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.flex({
                                              NAME: "num",
                                              VAL: 1.0
                                            }),
                                        tl: /* [] */0
                                      })
                                }));
                }));
  } else if (userSchemaInvitesState === "Initial") {
    return React.createElement(LoadingFullscreen.make, {
                message: "Loading workspace..."
              });
  } else {
    return React.createElement(NoAccess$NoInvites, {
                schemaId: schemaId
              });
  }
}

function NoAccess$NoBranches(Props) {
  var schemaId = Props.schemaId;
  var schemaBundle = SchemaBundleContext.use();
  React.useEffect((function (param) {
          AnalyticsRe.branchNotFound(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaId);
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.minHeight(Css.vh(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(50)),
                        tl: {
                          hd: Css.flex({
                                NAME: "num",
                                VAL: 1.0
                              }),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("a", {
                      href: "/"
                    }, React.createElement(Icons.AvoLogo.make, {
                          width: 100,
                          fill: Styles.Color.light04
                        }))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flex({
                              NAME: "num",
                              VAL: 5.0
                            }),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.textAlign("center"),
                              tl: {
                                hd: Css.maxWidth(Css.px(250)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.bold),
                            tl: {
                              hd: Css.color(Styles.Color.light04),
                              tl: {
                                hd: Css.fontSize(Css.px(100)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, "404"), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.flex({
                                  NAME: "num",
                                  VAL: 5.0
                                }),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.medium),
                                tl: {
                                  hd: Css.color(Styles.Color.light10),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, "We could not find the branch you requested 🤔"), React.createElement(Spacer.make, {
                      height: 24
                    }), React.createElement(Button.make, {
                      label: "View all branches",
                      onClick: (function (param) {
                          Router.push(undefined, Curry._4(Router.Link.get, {
                                    NAME: "schema",
                                    VAL: schemaId
                                  }, undefined, "dashboard", /* [] */0));
                        })
                    })), React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.button),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.flex({
                                      NAME: "num",
                                      VAL: 1.0
                                    }),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.color(Styles.Color.light07),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.medium),
                                      tl: {
                                        hd: Css.padding(Css.px(15)),
                                        tl: {
                                          hd: Css.textDecoration("underline"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      Intercom.showNewMessage();
                    })
                }, "Does something look wrong? Contact us!"));
}

var NoBranches = {
  make: NoAccess$NoBranches
};

var make = NoAccess;

export {
  NoInvites ,
  make ,
  NoBranches ,
}
/* Css Not a pure module */
