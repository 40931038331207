// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Hooks$1 from "@mantine/hooks";
import * as NamespaceIndex from "../../shared/models/NamespaceIndex.mjs";
import LodashThrottle from "lodash.throttle";

function Make(Item) {
  var scrollResultIntoView = function (state) {
    var maybeElement = Belt_Array.get(Belt_Array.keepMap(state.resultRefs.contents, (function (param) {
                if (param[0] === state.focusIndex) {
                  return Caml_option.some(param[1]);
                }
                
              })), 0);
    if (maybeElement !== undefined) {
      Caml_option.valFromOption(maybeElement).scrollIntoView({
            block: "nearest",
            inline: "nearest"
          });
      return ;
    }
    
  };
  var getAllOptions = function (options) {
    return Belt_Array.keepU(Belt_Array.concatMany(Belt_Array.mapU(options, (function (param) {
                          return param[1];
                        }))), (function (option) {
                  return true;
                }));
  };
  var getActionableOptions = function (options) {
    return Belt_Array.keepU(Belt_Array.concatMany(Belt_Array.mapU(options, (function (param) {
                          return param[1];
                        }))), (function (option) {
                  return option.NAME !== "Message";
                }));
  };
  var countActionableOptions = function (options) {
    return getActionableOptions(options).length;
  };
  var popup = Curry._1(Css.style, {
        hd: Css.top(Css.px(30)),
        tl: {
          hd: Css.zIndex(Styles.ZIndex.aboveAll),
          tl: {
            hd: Css.position("absolute"),
            tl: {
              hd: Css.marginBottom(Css.px(15)),
              tl: {
                hd: Css.zIndex(Styles.ZIndex.aboveAll),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: {
                    hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.borderBottomRightRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.overflow("hidden"),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.minWidth(Css.px(400)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  var searchInput = Curry._1(Css.merge, {
        hd: Curry._1(Css.style, Styles.input),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.display("block"),
                tl: {
                  hd: Css.width(Css.pct(100)),
                  tl: {
                    hd: Css.padding4(Css.px(15), Css.px(0), Css.px(15), Css.px(15)),
                    tl: {
                      hd: Css.fontWeight(Styles.FontWeight.semi),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.regular),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      });
  var results = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.height(Css.px(250)),
          tl: {
            hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
            tl: /* [] */0
          }
        }
      });
  var resultsList = Curry._1(Css.style, {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.height(Css.pct(100)),
          tl: {
            hd: Css.flexShrink(0.0),
            tl: {
              hd: Css.flex({
                    NAME: "num",
                    VAL: 1.0
                  }),
              tl: /* [] */0
            }
          }
        }
      });
  var resultsDetail = Curry._1(Css.style, {
        hd: Css.width(Css.pct(50)),
        tl: {
          hd: Css.flexShrink(0.0),
          tl: {
            hd: Css.flexGrow(0.0),
            tl: {
              hd: Css.flex({
                    NAME: "num",
                    VAL: 1.0
                  }),
              tl: {
                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                tl: /* [] */0
              }
            }
          }
        }
      });
  var resultLabel = Curry._1(Css.style, {
        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
        tl: {
          hd: Css.padding2(Css.px(8), Css.px(16)),
          tl: {
            hd: Css.textTransform("uppercase"),
            tl: /* [] */0
          }
        }
      });
  var result = function (clickable) {
    return Curry._1(Css.merge, {
                hd: Curry._1(Css.style, Styles.button),
                tl: {
                  hd: Curry._1(Css.style, {
                        hd: Css.display("block"),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: {
                            hd: Css.textAlign("left"),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.margin(Css.px(0)),
                                tl: {
                                  hd: Css.padding(Css.px(0)),
                                  tl: {
                                    hd: Css.cursor(clickable ? "pointer" : "default"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              });
  };
  var Style = {
    popup: popup,
    searchInput: searchInput,
    results: results,
    resultsList: resultsList,
    resultsDetail: resultsDetail,
    resultLabel: resultLabel,
    result: result
  };
  var AutofillInputNew$Make$Popup = function (Props) {
    var onSelect = Props.onSelect;
    var onCreate = Props.onCreate;
    var onPull = Props.onPull;
    var placeholder = Props.placeholder;
    var search = Props.search;
    var renderItem = Props.renderItem;
    var renderDetails = Props.renderDetails;
    var refreshKey = Props.refreshKey;
    var getIndexOfValue = Props.getIndexOfValue;
    var resultsContainerStylesOpt = Props.resultsContainerStyles;
    var onClose = Props.onClose;
    var resultsContainerStyles = resultsContainerStylesOpt !== undefined ? resultsContainerStylesOpt : /* [] */0;
    var getFocusIndex = function (state) {
      if (state.focusIndex < 0) {
        return state.previewFocusIndex;
      } else {
        return state.focusIndex;
      }
    };
    var results$1 = Curry._1(search, "");
    var initialIndex = Belt_Option.mapWithDefaultU(getIndexOfValue, 0, (function (getIndexOfValue) {
            return Curry._1(getIndexOfValue, getActionableOptions(results$1));
          }));
    var match = React.useReducer((function (state, action) {
            if (typeof action !== "object") {
              if (action === "IncrementFocus") {
                var currentFocusIndex = getFocusIndex(state);
                var nextFocusIndex = currentFocusIndex < (getActionableOptions(state.results).length - 1 | 0) ? currentFocusIndex + 1 | 0 : getActionableOptions(state.results).length - 1 | 0;
                var newState_query = state.query;
                var newState_results = state.results;
                var newState_resultRefs = state.resultRefs;
                var newState = {
                  query: newState_query,
                  focusIndex: nextFocusIndex,
                  previewFocusIndex: nextFocusIndex,
                  results: newState_results,
                  resultRefs: newState_resultRefs,
                  resultsHoverable: false
                };
                scrollResultIntoView(newState);
                return newState;
              }
              var currentFocusIndex$1 = getFocusIndex(state);
              var nextFocusIndex$1 = currentFocusIndex$1 > 0 ? currentFocusIndex$1 - 1 | 0 : 0;
              var newState_query$1 = state.query;
              var newState_results$1 = state.results;
              var newState_resultRefs$1 = state.resultRefs;
              var newState$1 = {
                query: newState_query$1,
                focusIndex: nextFocusIndex$1,
                previewFocusIndex: nextFocusIndex$1,
                results: newState_results$1,
                resultRefs: newState_resultRefs$1,
                resultsHoverable: false
              };
              scrollResultIntoView(newState$1);
              return newState$1;
            } else {
              switch (action.TAG) {
                case "UpdateQuery" :
                    var query = action._0;
                    var results = Curry._1(search, query);
                    return {
                            query: query,
                            focusIndex: 0,
                            previewFocusIndex: 0,
                            results: results,
                            resultRefs: {
                              contents: []
                            },
                            resultsHoverable: true
                          };
                case "SetPreviewFocus" :
                    var index = action._0;
                    return {
                            query: state.query,
                            focusIndex: state.focusIndex !== index ? -1 : state.focusIndex,
                            previewFocusIndex: index,
                            results: state.results,
                            resultRefs: state.resultRefs,
                            resultsHoverable: true
                          };
                case "SetState" :
                    return action._0;
                
              }
            }
          }), {
          query: "",
          focusIndex: initialIndex,
          previewFocusIndex: initialIndex,
          results: results$1,
          resultRefs: {
            contents: []
          },
          resultsHoverable: true
        });
    var send = match[1];
    var state = match[0];
    React.useEffect((function (param) {
            window.addEventListener("click", onClose);
            return (function (param) {
                      window.removeEventListener("click", onClose);
                    });
          }), []);
    Hooks.useDidUpdate1((function (param) {
            var results = Curry._1(search, state.query);
            Curry._1(send, {
                  TAG: "SetState",
                  _0: {
                    query: state.query,
                    focusIndex: state.focusIndex >= getActionableOptions(results).length ? 0 : state.focusIndex,
                    previewFocusIndex: state.previewFocusIndex,
                    results: results,
                    resultRefs: state.resultRefs,
                    resultsHoverable: state.resultsHoverable
                  }
                });
          }), [refreshKey]);
    var handleKeyDown = function ($$event) {
      var match = $$event.key;
      var match$1 = $$event.shiftKey;
      switch (match) {
        case "ArrowDown" :
            $$event.preventDefault();
            return Curry._1(send, "IncrementFocus");
        case "ArrowUp" :
            $$event.preventDefault();
            return Curry._1(send, "DecrementFocus");
        case "Enter" :
            var match$2 = Belt_Array.get(getActionableOptions(state.results), getFocusIndex(state));
            if (match$2 === undefined) {
              return ;
            }
            var variant = match$2.NAME;
            if (variant === "Message") {
              return ;
            }
            if (variant === "CreateNew") {
              Curry._2(onCreate, match$2.VAL, undefined);
              return Curry._1(onClose, undefined);
            }
            if (variant === "CreateNewWithGrouping") {
              var match$3 = match$2.VAL;
              Curry._2(onCreate, match$3[0], match$3[1]);
              return Curry._1(onClose, undefined);
            }
            if (variant === "Item") {
              Curry._3(onSelect, match$2.VAL, state.query, getFocusIndex(state));
              return Curry._1(onClose, undefined);
            }
            var match$4 = match$2.VAL;
            Curry._4(onPull, match$4[0], match$4[1], state.query, getFocusIndex(state));
            $$event.stopPropagation();
            return ;
        case "Escape" :
            $$event.stopPropagation();
            return Curry._1(onClose, undefined);
        case "Tab" :
            if (match$1) {
              $$event.preventDefault();
              return Curry._1(send, "DecrementFocus");
            } else {
              $$event.preventDefault();
              return Curry._1(send, "IncrementFocus");
            }
        default:
          return ;
      }
    };
    var lastPreviewFocusRef = React.useRef(-1);
    var throttledSetPreviewFocus = React.useMemo((function (param) {
            return LodashThrottle((function (index) {
                          if (lastPreviewFocusRef.current !== index) {
                            lastPreviewFocusRef.current = index;
                            return Curry._1(send, {
                                        TAG: "SetPreviewFocus",
                                        _0: index
                                      });
                          }
                          
                        }), 50, {
                        leading: true,
                        trailing: true
                      });
          }), [send]);
    var tmp;
    if (Caml_obj.equal(getAllOptions(state.results), [])) {
      tmp = null;
    } else {
      var match$1 = Belt_Array.get(getActionableOptions(state.results), state.previewFocusIndex);
      var match$2 = Belt_Array.get(getAllOptions(state.results), state.previewFocusIndex);
      tmp = React.createElement("div", {
            className: results
          }, React.createElement("div", {
                className: resultsList
              }, Belt_Array.reduceU(state.results, [
                      [],
                      0
                    ], (function (param, param$1) {
                        var options = param$1[1];
                        var maybeLabel = param$1[0];
                        var index = param[1];
                        return [
                                Belt_Array.concatMany([
                                      param[0],
                                      maybeLabel !== undefined ? (
                                          options.length !== 0 ? [React.createElement("div", {
                                                    key: "label-" + maybeLabel,
                                                    className: resultLabel
                                                  }, React.createElement($$Text.make, {
                                                        size: "Tiny",
                                                        weight: "Semi",
                                                        color: Styles.Color.light10,
                                                        children: maybeLabel
                                                      }))] : []
                                        ) : [],
                                      Belt_Array.mapWithIndexU(options, (function (subIndex, item) {
                                              var index$1 = index + subIndex | 0;
                                              var variant = item.NAME;
                                              var tmp;
                                              if (variant === "Message") {
                                                tmp = "message" + Curry._1(Item.messageToString, item.VAL);
                                              } else if (variant === "CreateNew") {
                                                tmp = "createNew" + item.VAL;
                                              } else if (variant === "CreateNewWithGrouping") {
                                                var match = item.VAL;
                                                tmp = "createNewWithGrouping" + match[0] + match[1];
                                              } else if (variant === "Item") {
                                                tmp = Curry._1(Item.getId, item.VAL);
                                              } else {
                                                var match$1 = item.VAL;
                                                tmp = NamespaceIndex.Client.getNamespaceId(match$1[0], match$1[1]);
                                              }
                                              return React.createElement("button", {
                                                          key: tmp,
                                                          ref: (function (resultRef) {
                                                              var refsWithoutResult = Belt_Array.keep(state.resultRefs.contents, (function (x) {
                                                                      return x[0] !== index$1;
                                                                    }));
                                                              state.resultRefs.contents = (resultRef == null) ? refsWithoutResult : Belt_Array.concat(refsWithoutResult, [[
                                                                        index$1,
                                                                        resultRef
                                                                      ]]);
                                                            }),
                                                          className: result(item.NAME !== "Message"),
                                                          onClick: (function (param) {
                                                              var variant = item.NAME;
                                                              if (variant === "Message") {
                                                                return ;
                                                              }
                                                              if (variant === "CreateNew") {
                                                                Curry._2(onCreate, item.VAL, undefined);
                                                                return Curry._1(onClose, undefined);
                                                              }
                                                              if (variant === "CreateNewWithGrouping") {
                                                                var match = item.VAL;
                                                                Curry._2(onCreate, match[0], match[1]);
                                                                return Curry._1(onClose, undefined);
                                                              }
                                                              if (variant === "Item") {
                                                                Curry._3(onSelect, item.VAL, state.query, index$1);
                                                                return Curry._1(onClose, undefined);
                                                              }
                                                              var match$1 = item.VAL;
                                                              Curry._4(onPull, match$1[0], match$1[1], state.query, index$1);
                                                              Curry._1(onClose, undefined);
                                                            }),
                                                          onMouseMove: (function ($$event) {
                                                              if ($$event.movementX !== 0 || $$event.movementY !== 0) {
                                                                return throttledSetPreviewFocus(index$1);
                                                              }
                                                              
                                                            })
                                                        }, Curry._3(renderItem, item, state.focusIndex === index$1, state.resultsHoverable));
                                            }))
                                    ]),
                                index + options.length | 0
                              ];
                      }))[0]), React.createElement("div", {
                className: resultsDetail
              }, match$1 !== undefined ? Curry._1(renderDetails, match$1) : (
                  match$2 !== undefined && typeof match$2 === "object" && match$2.NAME === "Message" ? Curry._1(renderDetails, match$2) : null
                )));
    }
    return React.createElement("div", {
                className: Curry._1(Css.merge, {
                      hd: popup,
                      tl: {
                        hd: Curry._1(Css.style, resultsContainerStyles),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement("input", {
                    className: searchInput,
                    autoFocus: true,
                    placeholder: placeholder,
                    value: state.query,
                    onKeyDown: handleKeyDown,
                    onChange: (function ($$event) {
                        Curry._1(send, {
                              TAG: "UpdateQuery",
                              _0: $$event.target.value
                            });
                      })
                  }), tmp);
  };
  var Popup = {
    scrollResultIntoView: scrollResultIntoView,
    getAllOptions: getAllOptions,
    getActionableOptions: getActionableOptions,
    countActionableOptions: countActionableOptions,
    Style: Style,
    make: AutofillInputNew$Make$Popup
  };
  var AutofillInputNew$Make = function (Props) {
    var onSelect = Props.onSelect;
    var onCreate = Props.onCreate;
    var onPull = Props.onPull;
    var placeholder = Props.placeholder;
    var search = Props.search;
    var renderItem = Props.renderItem;
    var renderDetails = Props.renderDetails;
    var refreshKey = Props.refreshKey;
    var autoFocusOpt = Props.autoFocus;
    var getIndexOfValue = Props.getIndexOfValue;
    var renderButton = Props.renderButton;
    var resultsContainerStylesOpt = Props.resultsContainerStyles;
    var keepExpandedOpt = Props.keepExpanded;
    var onClose = Props.onClose;
    var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
    var resultsContainerStyles = resultsContainerStylesOpt !== undefined ? resultsContainerStylesOpt : /* [] */0;
    var keepExpanded = keepExpandedOpt !== undefined ? keepExpandedOpt : false;
    var match = Hooks.useDisclosure(autoFocus);
    var popupActions = match[1];
    var isOpen = match[0];
    var buttonRef = React.useRef(null);
    var handleOpen = React.useCallback((function (param) {
            Curry._1(popupActions.open_, undefined);
          }), []);
    var handleClose = React.useCallback((function (param) {
            Belt_Option.forEach(onClose, (function (cb) {
                    Curry._1(cb, undefined);
                  }));
            Curry._1(popupActions.close, undefined);
            var domButton = buttonRef.current;
            if (!(domButton == null)) {
              domButton.focus();
              return ;
            }
            
          }), [
          buttonRef,
          popupActions
        ]);
    var ref = Hooks$1.useClickOutside(function (param) {
          if (isOpen) {
            return Curry._1(handleClose, undefined);
          }
          
        });
    var tmp;
    if (isOpen || keepExpanded) {
      var tmp$1 = {
        onSelect: onSelect,
        onCreate: onCreate,
        onPull: onPull,
        placeholder: placeholder,
        search: search,
        renderItem: renderItem,
        renderDetails: renderDetails,
        refreshKey: refreshKey,
        resultsContainerStyles: resultsContainerStyles,
        onClose: handleClose
      };
      if (getIndexOfValue !== undefined) {
        tmp$1.getIndexOfValue = Caml_option.valFromOption(getIndexOfValue);
      }
      tmp = React.createElement(AutofillInputNew$Make$Popup, tmp$1);
    } else {
      tmp = null;
    }
    return React.createElement("div", {
                ref: ref,
                className: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }),
                onClick: (function ($$event) {
                    $$event.stopPropagation();
                  })
              }, renderButton !== undefined ? Curry._3(renderButton, handleOpen, buttonRef, isOpen) : React.createElement(Button.make, {
                      label: placeholder,
                      onClick: handleOpen,
                      ref: buttonRef
                    }), tmp);
  };
  return {
          Popup: Popup,
          make: AutofillInputNew$Make
        };
}

export {
  Make ,
}
/* Css Not a pure module */
