// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Js_int from "rescript/lib/es6/js_int.js";
import * as Models from "../../../app/src/Models.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "../../../app/src/RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BeltListExtensions from "../../../app/src/BeltListExtensions.mjs";

var Params = {};

var MemberContext = {
  Params: Params
};

var unifiedBillingSchemaIds = [
  [
    "TdeN88HzlqbmAN5wJKwK",
    "mmOJOeDynleNTkyVp3Ys"
  ],
  [
    "K66LFDF3gWUXIUfJhqZi",
    "4sKcw8ZgzSHPUKoyF3XW",
    "Pe1erihoPAiw9iUTw4Hs",
    "y279xaVyqGeT7ieJdThw",
    "m82TBzByIxTQBc5gTZ2z",
    "IH2DuzuGPzO3BZPAXCj9",
    "6ZP2LE8MN4pyLkGlxHu0",
    "e8lWNbDhL62OKBqpGaJn",
    "sqoxRWf6JlvN6LEzKcHg",
    "3dlS0lUD2vRGoQMCQ0nn",
    "9BIS4gNl4PHUp8TFcqUd"
  ],
  [
    "yXbFvXu5GZ3j0uXXEPGy",
    "y2SHUZNik2PGrEQcBooS"
  ],
  [
    "8WT8YaEC2F3w5fo31aV0",
    "k40YS7ldg2EfkSy6DFNf",
    "3IIKyPmX22y3Wpa0Tis3"
  ],
  [
    "3e1seM3y1IIbUDvO5EoI",
    "8w4TDueCuvau97jtmtL5"
  ]
];

function getMaybeUnifiedBilling(schemaId) {
  return Belt_Array.getBy(unifiedBillingSchemaIds, (function (schemaIds) {
                return Belt_Array.some(schemaIds, (function (id) {
                              return id === schemaId;
                            }));
              }));
}

function mergeMembersFromMultipleWorkspaces(multipleSchemaMembers) {
  var __x = Belt_List.fromArray(Belt_Array.concatMany(multipleSchemaMembers));
  return Belt_List.toArray(Belt_List.keepMap(BeltListExtensions.group((function (a, b) {
                        return Caml_obj.compare(Caml_option.undefined_to_opt(a.email), Caml_option.undefined_to_opt(b.email));
                      }), __x), (function (members) {
                    return Belt_List.head(Belt_List.sort(members, (function (a, b) {
                                      return Caml.int_compare(RoleUtils.getRoleRank(Models.Role.tFromJs(a.role)), RoleUtils.getRoleRank(Models.Role.tFromJs(b.role)));
                                    })));
                  })));
}

function filterAvoMembers(members) {
  return Belt_Array.keep(members, (function (member) {
                return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(member.email), true, (function (email) {
                              if (email.endsWith("@avo.sh")) {
                                return false;
                              } else {
                                return !email.endsWith("@avo.app");
                              }
                            }));
              }));
}

function countWorkspaceMembers(members) {
  var members$1 = filterAvoMembers(members);
  var numOfAdmins = Belt_Array.keep(members$1, (function (item) {
          return item.role === "Admin";
        })).length;
  var numOfEditors = Belt_Array.keep(members$1, (function (item) {
          return item.role === "Editor";
        })).length;
  var numOfCommenters = Belt_Array.keep(members$1, (function (item) {
          if (item.role === "CommentOnly") {
            return true;
          } else {
            return item.role === "Comment Only";
          }
        })).length;
  var numOfViewers = Belt_Array.keep(members$1, (function (item) {
          return item.role === "Viewer";
        })).length;
  var numOfBillingOnly = Belt_Array.keep(members$1, (function (item) {
          return item.role === "Billing Only";
        })).length;
  var numOfCodegenAccess = Belt_Array.keep(members$1, (function (item) {
          return item.role === Models.Role.tToJs("CodegenAccess");
        })).length;
  return {
          totalAdmins: numOfAdmins,
          totalEditors: numOfEditors,
          totalCommenters: numOfCommenters,
          totalViewers: numOfViewers,
          totalBillingUsers: numOfBillingOnly,
          totalCodegenAccess: numOfCodegenAccess
        };
}

function countPayingWorkspaceMembers(members, workspacePlan) {
  var match = countWorkspaceMembers(members);
  var totalCodegenAccess = match.totalCodegenAccess;
  var totalCommenters = match.totalCommenters;
  var totalEditors = match.totalEditors;
  var totalAdmins = match.totalAdmins;
  var limit = workspacePlan.commentOnlyMembers;
  var tmp;
  if (typeof limit !== "object") {
    switch (limit) {
      case "CountAsEditors" :
          tmp = totalCommenters;
          break;
      case "NotAvailable" :
      case "Unlimited" :
          tmp = 0;
          break;
      
    }
  } else {
    tmp = limit.TAG === "AdditionalCost" ? totalCommenters : Math.max(0, totalCommenters - limit._0 | 0);
  }
  var totalPayingSeats = (totalAdmins + totalEditors | 0) + tmp | 0;
  return {
          totalAdmins: totalAdmins,
          totalEditors: totalEditors,
          totalCommenters: totalCommenters,
          totalViewers: match.totalViewers,
          totalBillingUsers: match.totalBillingUsers,
          totalCodegenAccess: totalCodegenAccess,
          totalPayingSeats: totalPayingSeats,
          totalPayingCodegenAccess: totalCodegenAccess
        };
}

function countPayingAndAdditionalWorkspaceMembers(members, includedUsers, workspacePlan) {
  var match = countPayingWorkspaceMembers(members, workspacePlan);
  var totalCommenters = match.totalCommenters;
  var totalEditors = match.totalEditors;
  var totalAdmins = match.totalAdmins;
  var includedUsersCount = typeof includedUsers === "object" ? includedUsers.VAL : Js_int.max;
  var numOfAdminsOverBaseline = totalAdmins - includedUsersCount | 0;
  var numOfEditorsOverBaseline = numOfAdminsOverBaseline >= 0 ? totalEditors : totalEditors + numOfAdminsOverBaseline | 0;
  var limit = workspacePlan.commentOnlyMembers;
  var numOfCommentersOverCommentersBaseline;
  numOfCommentersOverCommentersBaseline = typeof limit !== "object" ? (
      limit === "Unlimited" ? 0 : totalCommenters
    ) : (
      limit.TAG === "Limited" ? Math.max(0, totalCommenters - limit._0 | 0) : totalCommenters
    );
  var numOfCommentersOverBaseline = numOfEditorsOverBaseline >= 0 ? numOfCommentersOverCommentersBaseline : numOfCommentersOverCommentersBaseline + numOfEditorsOverBaseline | 0;
  return {
          totalAdmins: totalAdmins,
          totalEditors: totalEditors,
          totalCommenters: totalCommenters,
          totalViewers: match.totalViewers,
          totalPayingSeats: match.totalPayingSeats,
          totalPayingCodegenAccess: match.totalPayingCodegenAccess,
          adminsOverBaseline: Math.max(numOfAdminsOverBaseline, 0),
          editorsOverBaseline: Math.max(numOfEditorsOverBaseline, 0),
          commentersOverBaseline: Math.max(numOfCommentersOverBaseline, 0)
        };
}

export {
  MemberContext ,
  unifiedBillingSchemaIds ,
  getMaybeUnifiedBilling ,
  mergeMembersFromMultipleWorkspaces ,
  filterAvoMembers ,
  countWorkspaceMembers ,
  countPayingWorkspaceMembers ,
  countPayingAndAdditionalWorkspaceMembers ,
}
/* Models Not a pure module */
