// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "./Title.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Models from "./Models.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Changes from "../../shared/Changes.mjs";
import * as Mantine from "./Mantine.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoSelect from "./AvoSelect.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PlatformIcon from "./PlatformIcon.mjs";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as PortalTooltip from "./PortalTooltip.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as AppVersionsStore from "./AppVersionsStore.mjs";
import * as AppVersionsTypes from "../../shared/models/AppVersionsTypes.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as InspectorVersionValidationIntent from "./InspectorVersionValidationIntent.mjs";
import * as InspectorVersionValidationIntentHelpers from "./InspectorVersionValidationIntentHelpers.mjs";

function sourcePlatformToAnalytics(platform) {
  if (platform === "ReactNative") {
    return "ReactNative";
  } else if (platform === "DotNet") {
    return "Net";
  } else if (platform === "Android") {
    return "Android";
  } else if (platform === "Python") {
    return "Python";
  } else if (platform === "JsonSchema") {
    return "JSONSchema";
  } else if (platform === "Go") {
    return "Go";
  } else if (platform === "Gtm") {
    return "GoogleTagManager";
  } else if (platform === "IOS") {
    return "IOS";
  } else if (platform === "Php") {
    return "Php";
  } else if (platform === "Web") {
    return "Web";
  } else if (platform === "KotlinMultiplatform") {
    return "KotlinMultiplatform";
  } else if (platform === "MacOS") {
    return "MacOS";
  } else if (platform === "Expo") {
    return "Expo";
  } else if (platform === "Java") {
    return "Java";
  } else if (platform === "Node") {
    return "NodeJs";
  } else if (platform === "Ruby") {
    return "Ruby";
  } else if (platform === "Flutter") {
    return "Flutter";
  } else if (platform === "Unity") {
    return "Unity";
  } else {
    return "Other";
  }
}

function sourcePlatformToCategory(platform) {
  if (platform === "Flutter" || platform === "Expo" || platform === "KotlinMultiplatform" || platform === "ReactNative") {
    return "CrossMinusplatform";
  } else if (platform === "Ruby" || platform === "Node" || platform === "Java" || platform === "Php" || platform === "Go" || platform === "Python" || platform === "DotNet") {
    return "Backend";
  } else if (platform === "Android") {
    return "Android";
  } else if (platform === "IOS") {
    return "IOS";
  } else if (platform === "Web") {
    return "Web";
  } else if (platform === "MacOS") {
    return "Desktop";
  } else if (platform === "Unity") {
    return "GameEngine";
  } else {
    return "Other";
  }
}

function validationIntentToAnalytics(intent) {
  if (intent === "nextVersion") {
    return "NextVersion";
  } else if (intent === "oneHourAfterMerge") {
    return "OneHourAfterBranchMerge";
  } else if (intent === "currentVersion") {
    return "CurrentVersion";
  } else if (intent === "twentyFourHoursAfterMerge") {
    return "_24HoursAfterBranchMerge";
  } else {
    return "DontIgnore";
  }
}

function intentToAnalytics(intent) {
  if (intent === "nextVersion") {
    return "NextVersion";
  } else if (intent === "oneHourAfterMerge") {
    return "OneHourAfterBranchMerge";
  } else if (intent === "currentVersion") {
    return "CurrentVersion";
  } else if (intent === "twentyFourHoursAfterMerge") {
    return "_24HoursAfterBranchMerge";
  } else {
    return "DontIgnore";
  }
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(10)),
      tl: {
        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(32), undefined, undefined, Css.rgba(0, 0, 0, {
                      NAME: "num",
                      VAL: 0.10
                    }))),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.paddingTop(Css.px(32)),
            tl: {
              hd: Css.paddingRight(Css.px(24)),
              tl: {
                hd: Css.paddingBottom(Css.px(24)),
                tl: {
                  hd: Css.paddingLeft(Css.px(32)),
                  tl: {
                    hd: Css.width(Css.px(480)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var sourceStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginBottom(Css.px(12)),
            tl: /* [] */0
          }
        }
      }
    });

var actionStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: {
            hd: Css.gap(Css.px(12)),
            tl: {
              hd: Css.marginTop(Css.px(24)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function getDontIgnoreOption(param) {
  return [{
            label: InspectorVersionValidationIntent.toDisplayString("dontIgnore"),
            value: "dontIgnore"
          }];
}

function getVersionOptions(latestVersion) {
  if (latestVersion !== undefined && latestVersion.NAME !== "invalid") {
    return [
            {
              label: InspectorVersionValidationIntent.toDisplayString("currentVersion"),
              value: "currentVersion",
              description: "Current latest version: " + latestVersion.VAL,
              group: "Ignore Until Version"
            },
            {
              label: InspectorVersionValidationIntent.toDisplayString("nextVersion"),
              value: "nextVersion",
              group: "Ignore Until Version"
            }
          ];
  }
  return [
          {
            label: InspectorVersionValidationIntent.toDisplayString("currentVersion"),
            value: "currentVersion",
            description: "No versions detected for source",
            group: "Ignore Until Version",
            disabled: true
          },
          {
            label: InspectorVersionValidationIntent.toDisplayString("nextVersion"),
            value: "nextVersion",
            description: "No versions detected for source",
            group: "Ignore Until Version",
            disabled: true
          }
        ];
}

function getTimedOptions(param) {
  return [
          {
            label: InspectorVersionValidationIntent.toDisplayString("oneHourAfterMerge"),
            value: "oneHourAfterMerge",
            group: "Ignore Until Time"
          },
          {
            label: InspectorVersionValidationIntent.toDisplayString("twentyFourHoursAfterMerge"),
            value: "twentyFourHoursAfterMerge",
            group: "Ignore Until Time"
          }
        ];
}

function getOptions(latestVersion) {
  return Belt_Array.concatMany([
              getVersionOptions(latestVersion),
              getTimedOptions(),
              getDontIgnoreOption()
            ]);
}

function InspectorVersionValidationModal(Props) {
  var onClose = Props.onClose;
  var model = Props.model;
  var changes = Props.changes;
  var match = SchemaBundleContext.use();
  var schemaId = match.schemaId;
  var match$1 = AppVersionsStore.use(undefined, 2, undefined);
  var fetchStatus = match$1[1];
  var appVersions = match$1[0];
  var match$2 = FirebaseFetcherHooks.useValidationIntents(schemaId, match.branchId);
  var intentsFetchStatus = match$2[1];
  var intents = match$2[0];
  var schemaBundle = SchemaBundleContext.use();
  var addToast = Toast.useAddToast();
  var match$3 = React.useState(function (param) {
        return false;
      });
  var setSaveAsDefaults = match$3[1];
  var saveAsDefaults = match$3[0];
  var member = WorkspaceContext.useMember();
  var sources = Belt_Array.keepMap(changes.breakingSources, (function (id) {
          return ModelUtils.getSourceByIdWithArchive(id, model);
        }));
  var match$4 = React.useState(function (param) {
        return Belt_MapString.fromArray(Belt_Array.map(sources, (function (source) {
                          return [
                                  source.id,
                                  [
                                    source,
                                    "unset"
                                  ]
                                ];
                        })));
      });
  var setSourceValidation = match$4[1];
  var sourceValidation = match$4[0];
  React.useEffect((function (param) {
          var inspectorBreakingChangeInfo = Changes.getAnalyticsBreakingChangeInfo(changes);
          AnalyticsRe.releaseVersionConfigurationModalOpened(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, inspectorBreakingChangeInfo.breakingChangeImpactedEventCount, inspectorBreakingChangeInfo.breakingChangeImpactedEventIds, inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds, inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount, schemaBundle.branchId, schemaBundle.schemaId);
        }), []);
  var handleConfirm = function (param) {
    var intents$1 = Belt_Array.keepMap(Belt_MapString.toArray(Belt_MapString.map(sourceValidation, (function (param) {
                    return param[1];
                  }))), (function (param) {
            var value = param[1];
            var sourceId = param[0];
            if (typeof value === "object") {
              var value$1 = value.VAL;
              if (value$1 !== undefined) {
                return [
                        sourceId,
                        value$1
                      ];
              } else {
                return ;
              }
            }
            var intent = Belt_MapString.get(intents, sourceId);
            if (intent !== undefined) {
              return [
                      sourceId,
                      intent
                    ];
            } else {
              return [
                      sourceId,
                      "dontIgnore"
                    ];
            }
          }));
    var firebaseActions = Belt_Array.concat([FirebaseUtils.setInspectorValidationIntentsForBranch(schemaId, Router.Schema.getBranchId(), intents$1)], saveAsDefaults ? [FirebaseUtils.setInspectorValidationIntentsDefaults(schemaId, intents$1)] : []);
    $$Promise.$$catch(Promise.all(firebaseActions).then(function (param) {
              var configured = Belt_Array.keepMap(Belt_MapString.toArray(Belt_MapString.map(sourceValidation, (function (param) {
                              return param[1];
                            }))), (function (param) {
                      var value = param[1];
                      if (typeof value !== "object") {
                        return ;
                      }
                      if (value.NAME !== "set") {
                        return ;
                      }
                      var value$1 = value.VAL;
                      if (value$1 !== undefined) {
                        return [
                                param[0],
                                value$1
                              ];
                      }
                      
                    }));
              var inspectorBreakingChangeInfo = Changes.getAnalyticsBreakingChangeInfo(changes);
              AnalyticsRe.releaseVersionConfigurationCompleted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, inspectorBreakingChangeInfo.breakingChangeImpactedEventCount, inspectorBreakingChangeInfo.breakingChangeImpactedEventIds, inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds, inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount, configured.length, Belt_Array.map(configured, (function (param) {
                          return param[0];
                        })), Belt_Array.map(configured, (function (param) {
                          return intentToAnalytics(param[1]);
                        })), Belt_Array.map(intents$1, (function (param) {
                          return intentToAnalytics(param[1]);
                        })), saveAsDefaults ? "Checked" : "Unchecked", schemaBundle.branchId, schemaBundle.schemaId);
            }), (function (param) {
            Curry._1(addToast, {
                  message: "Failed to update settings",
                  toastType: "Error"
                });
            return Promise.resolve();
          }));
    Curry._1(onClose, undefined);
  };
  var hasChanges = Belt_MapString.some(sourceValidation, (function (_id, param) {
          var intent = param[1];
          if (typeof intent === "object") {
            return intent.NAME === "set";
          } else {
            return false;
          }
        }));
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(Title.make, {
                  children: "Temporarily ignore Inspector issues for " + AvoUtils.plural(undefined, undefined, changes.breakingEvents.length, "event") + "  events from " + AvoUtils.plural(undefined, undefined, changes.breakingSources.length, "source"),
                  size: "Tiny"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light10,
                  children: "This branch has changes that may not be present in older app versions. To reduce noise in your issues and alerts, configure Inspector to ignore new issues for the impacted events until the changes are live."
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.px(210)),
                        tl: {
                          hd: Css.alignSelf("flexEnd"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Tiny",
                      weight: "Semi",
                      color: Styles.Color.light10,
                      children: "IGNORE UNTIL"
                    })), React.createElement(Spacer.make, {
                  height: 8
                }), Belt_Array.map(Belt_MapString.valuesToArray(sourceValidation), (function (param) {
                    var value = param[1];
                    var source = param[0];
                    var latestVersion = AppVersionsTypes.getLatestVersion(undefined, appVersions, source.id);
                    var match = Belt_MapString.get(intents, source.id);
                    var value$1 = typeof value === "object" ? value.VAL : (
                        match !== undefined ? match : undefined
                      );
                    var maybeImpactedEventIds = Belt_MapString.get(changes.breakingEventIdsBySource, source.id);
                    var tmp = {
                      data: getOptions(latestVersion),
                      defaultValue: "dontIgnore",
                      disabled: fetchStatus !== "success" || intentsFetchStatus !== "success",
                      maxHeight: 500,
                      minWidth: 220,
                      maxWidth: 220,
                      onChange: (function (param) {
                          Curry._1(setSourceValidation, (function (sourceValidation) {
                                  return Belt_MapString.set(sourceValidation, source.id, [
                                              source,
                                              {
                                                NAME: "set",
                                                VAL: param
                                              }
                                            ]);
                                }));
                          var inspectorBreakingChangeInfo = Changes.getAnalyticsBreakingChangeInfo(changes);
                          return AnalyticsRe.releaseVersionSourceOptionSelected(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, inspectorBreakingChangeInfo.breakingChangeImpactedEventCount, inspectorBreakingChangeInfo.breakingChangeImpactedEventIds, inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds, inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount, validationIntentToAnalytics(Belt_Option.getWithDefault(param, "dontIgnore")), Belt_Option.mapWithDefault(source.platform, "Other", sourcePlatformToAnalytics), Belt_Option.mapWithDefault(source.platform, "Other", sourcePlatformToCategory), schemaBundle.branchId, schemaBundle.schemaId);
                        })
                    };
                    if (value$1 !== undefined) {
                      tmp.value = Caml_option.valFromOption(value$1);
                    }
                    return React.createElement("div", {
                                key: source.id,
                                className: sourceStyles
                              }, React.createElement(PlatformIcon.make, {
                                    platform: source.platform,
                                    sizeWithBorder: 42
                                  }), React.createElement(Spacer.make, {
                                    width: 20
                                  }), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.minWidth(Css.px(0)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Medium",
                                        weight: "Semi",
                                        singleLine: true,
                                        color: Styles.Color.light12,
                                        children: AvoConfig.getSourceName(source)
                                      }), React.createElement(PortalTooltip.make, {
                                        renderTooltip: (function (param) {
                                            return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                                            height: 4
                                                          }), maybeImpactedEventIds !== undefined ? Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(Caml_option.valFromOption(maybeImpactedEventIds)), (function (eventId) {
                                                                    return ModelUtils.getEventById(eventId, model);
                                                                  })), (function ($$event) {
                                                                return React.createElement("div", undefined, React.createElement($$Text.make, {
                                                                                size: "Small",
                                                                                color: Styles.Color.white,
                                                                                children: $$event.name
                                                                              }));
                                                              })) : null);
                                          }),
                                        children: React.createElement("span", undefined, React.createElement($$Text.make, {
                                                  size: "Small",
                                                  color: Styles.Color.light10,
                                                  children: AvoUtils.plural(undefined, undefined, Belt_Option.mapWithDefault(maybeImpactedEventIds, 0, Belt_SetString.size), "event")
                                                }))
                                      })), React.createElement(Spacer.make, {
                                    width: 8,
                                    grow: 1.0
                                  }), React.createElement(AvoSelect.make, tmp));
                  })), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement(Mantine.Checkbox.make, {
                  checked: saveAsDefaults,
                  color: "magenta100",
                  disabled: Belt_Option.mapWithDefault(member, true, (function (member) {
                          return Caml_obj.notequal(Models.Role.tFromJs(member.role), "Admin");
                        })),
                  label: React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light10,
                        children: "Set as default for these sources when merging branches from now on (this can be reconfigured for each branch)"
                      }),
                  onChange: (function (param) {
                      Curry._1(setSaveAsDefaults, (function (value) {
                              return !value;
                            }));
                    }),
                  size: {
                    NAME: "num",
                    VAL: 16
                  }
                }), React.createElement("div", {
                  className: actionStyles
                }, React.createElement(Button.make, {
                      label: "Cancel",
                      onClick: (function (param) {
                          Curry._1(onClose, undefined);
                        }),
                      style: "outline"
                    }), React.createElement(Button.make, {
                      disabled: !hasChanges,
                      label: "Update Settings",
                      onClick: handleConfirm
                    })));
}

function InspectorVersionValidationModal$MergedDetails(Props) {
  var onClose = Props.onClose;
  var eventIds = Props.eventIds;
  var sourceValidation = Props.sourceValidation;
  var branchModel = Props.branchModel;
  var eventIdsSet = Belt_SetString.fromArray(Belt_List.toArray(eventIds));
  var events = Belt_MapString.fromArray(Belt_Array.keepMapU(Belt_List.toArray(branchModel.events), (function ($$event) {
              if (Belt_SetString.has(eventIdsSet, $$event.id)) {
                return [
                        $$event.id,
                        $$event
                      ];
              }
              
            })));
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(Title.make, {
                  children: "Inspector temporarily ignored issues for " + AvoUtils.plural(undefined, undefined, Belt_List.size(eventIds), "event") + " from " + AvoUtils.plural(undefined, undefined, Belt_MapString.size(sourceValidation), "source"),
                  size: "Tiny"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light10,
                  children: "This branch had changes that were not present in older app versions. To reduce noise in your issues and alerts, Inspector was configured to ignore new issues for the impacted events until the changes are live."
                }), React.createElement(Spacer.make, {
                  height: 32
                }), Belt_Array.map(Belt_MapString.toArray(sourceValidation), (function (param) {
                    var sourceId = param[0];
                    var maybeImpactedEventIds = Belt_MapString.keysToArray(Belt_MapString.keepU(events, (function (_eventId, $$event) {
                                return Belt_List.someU($$event.includeSources, (function (param) {
                                              return param.id === sourceId;
                                            }));
                              })));
                    var branchSource = Belt_List.getByU(branchModel.sources, (function (param) {
                            return param.id === sourceId;
                          }));
                    var platform = Belt_Option.flatMap(branchSource, (function (param) {
                            return param.platform;
                          }));
                    return React.createElement("div", {
                                key: sourceId,
                                className: sourceStyles
                              }, React.createElement(PlatformIcon.make, {
                                    platform: platform,
                                    sizeWithBorder: 42
                                  }), React.createElement(Spacer.make, {
                                    width: 20
                                  }), React.createElement("div", undefined, React.createElement($$Text.make, {
                                        size: "Medium",
                                        weight: "Semi",
                                        lines: 1,
                                        color: Styles.Color.light12,
                                        children: AvoConfig.getSourceNameById(branchModel, sourceId)
                                      }), React.createElement(PortalTooltip.make, {
                                        renderTooltip: (function (param) {
                                            return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                                            height: 4
                                                          }), maybeImpactedEventIds.length !== 0 ? Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(Belt_SetString.fromArray(maybeImpactedEventIds)), (function (eventId) {
                                                                    return ModelUtils.getEventById(eventId, branchModel);
                                                                  })), (function ($$event) {
                                                                return React.createElement("div", undefined, React.createElement($$Text.make, {
                                                                                size: "Small",
                                                                                color: Styles.Color.white,
                                                                                children: $$event.name
                                                                              }));
                                                              })) : null);
                                          }),
                                        children: React.createElement("span", undefined, React.createElement($$Text.make, {
                                                  size: "Small",
                                                  color: Styles.Color.light10,
                                                  children: AvoUtils.plural(undefined, undefined, maybeImpactedEventIds.length, "event")
                                                }))
                                      })), React.createElement(Spacer.make, {
                                    width: 8,
                                    grow: 1.0
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Semi",
                                    color: Styles.Color.light11,
                                    children: InspectorVersionValidationIntentHelpers.toString(param[1])
                                  }));
                  })), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: actionStyles
                }, React.createElement(Button.make, {
                      label: "Close",
                      onClick: (function (param) {
                          Curry._1(onClose, undefined);
                        })
                    })));
}

var MergedDetails = {
  make: InspectorVersionValidationModal$MergedDetails
};

var make = InspectorVersionValidationModal;

export {
  sourcePlatformToAnalytics ,
  sourcePlatformToCategory ,
  validationIntentToAnalytics ,
  intentToAnalytics ,
  rootStyles ,
  sourceStyles ,
  actionStyles ,
  getDontIgnoreOption ,
  getVersionOptions ,
  getTimedOptions ,
  getOptions ,
  make ,
  MergedDetails ,
}
/* rootStyles Not a pure module */
