// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as NameInput from "./NameInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NameInputUtils from "./NameInputUtils.mjs";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";

function isValid(name, expectedPropertyCase, model) {
  return NameInputUtils.isValid(NameInputUtils.validateInput(ModelUtils_mapped.getPropertyNamespace(model), name, name, expectedPropertyCase, true));
}

function handleSubmit(name, id, sendActions, onSuccess, addToast) {
  Curry.app(sendActions, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (_toBranch) {
            Curry._1(onSuccess, undefined);
            Curry._1(addToast, {
                  message: "Group type \"" + name + "\" created 🎉",
                  toastType: "Success",
                  action: {
                    message: "View",
                    toastCallback: (function (param) {
                        Router.Schema.pushDrawerItem(undefined, undefined, {
                              NAME: "propertyGroup",
                              VAL: [
                                id,
                                undefined
                              ]
                            });
                      })
                  }
                });
          }),
        undefined,
        [[
            {
              NAME: "CreateGroupType",
              VAL: [
                id,
                name,
                ""
              ]
            },
            {
              groupTypeId: name,
              groupTypeQuery: name
            }
          ]]
      ]);
}

function NewGroupTypeModal(Props) {
  var afterCreateCallback = Props.afterCreateCallback;
  var onClose = Props.onClose;
  var groupTypeName = Props.groupTypeName;
  var model = Props.model;
  var addToast = Toast.useAddToast();
  var match = React.useContext(DiscrepancyContext.context);
  var sendActions = SendActionsContext.use();
  var newId = React.useMemo((function (param) {
          return Uuid.v4();
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(600)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(NameInput.make, {
                  name: Belt_Option.getWithDefault(groupTypeName, ""),
                  existingNames: Belt_Array.map(model.groupTypes, (function (group) {
                          return group.name;
                        })),
                  expectedCase: match.expectedPropertyCase,
                  forceCase: Curry._1(ValidationConfigV2.Rule.isEnforced, match.config.properties.case),
                  autoFocus: true,
                  onChange: (function (name, _namingConvention, _correctCase, _onReset) {
                      handleSubmit(name, newId, sendActions, (function (param) {
                              Curry._1(onClose, undefined);
                              Belt_Option.forEach(afterCreateCallback, (function (cb) {
                                      Curry._2(cb, newId, name);
                                    }));
                            }), addToast);
                    }),
                  submitLabel: "Create Group Type",
                  placeholder: "Group Type",
                  onClose: (function (param) {
                      Curry._1(onClose, undefined);
                    }),
                  fullscreen: true,
                  itemType: "GroupType",
                  actionType: "Create"
                }));
}

var make = NewGroupTypeModal;

export {
  isValid ,
  handleSubmit ,
  make ,
}
/* Css Not a pure module */
