// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Models from "./Models.mjs";
import * as DateFns from "./DateFns.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BeltListExtensions from "./BeltListExtensions.mjs";

function filterSystemActions(actions) {
  return Belt_List.keep(actions, (function (item) {
                return !Belt_Option.getWithDefault(Caml_option.undefined_to_opt(item.system), false);
              }));
}

function groupCompare(a, b) {
  var match = a.contents;
  var match$1 = b.contents;
  if (typeof match !== "object") {
    if (match === "StartedImport") {
      return match$1 === "StartedImport";
    } else {
      return false;
    }
  }
  var variant = match.NAME;
  if (variant === "UpdateIntegrationAutoPublish") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateIntegrationAutoPublish") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateGoalDescription") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateGoalDescription") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdatePropertyName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdatePropertyName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdatePropertyDescription") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdatePropertyDescription") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "ReorderEventsInGoalV2") {
    if (typeof match$1 === "object" && match$1.NAME === "ReorderEventsInGoalV2") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "ReorderMetricsInGoal") {
    if (typeof match$1 === "object" && match$1.NAME === "ReorderMetricsInGoal") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateSourceName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateSourceName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "ReorderEventsInGoal") {
    if (typeof match$1 === "object" && match$1.NAME === "ReorderEventsInGoal") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "ReorderMetricsInGoalV2") {
    if (typeof match$1 === "object" && match$1.NAME === "ReorderMetricsInGoalV2") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "ReorderEventsInMetricV2") {
    if (typeof match$1 === "object" && match$1.NAME === "ReorderEventsInMetricV2") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateIntegrationConfig") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateIntegrationConfig") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateDestinationName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateDestinationName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateEventDescription") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateEventDescription") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "ReorderEventsInMetric") {
    if (typeof match$1 === "object" && match$1.NAME === "ReorderEventsInMetric") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdatePropertyGroupDescription") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdatePropertyGroupDescription") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateGoalName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateGoalName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateMetricName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateMetricName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateIntegrationName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateIntegrationName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdatePropertyGroupName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdatePropertyGroupName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateEventName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateEventName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateIntegrationFilters") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateIntegrationFilters") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateMetricDescription") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateMetricDescription") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateDestinationDevApiKey") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateDestinationDevApiKey") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateLibraryName") {
    if (typeof match$1 === "object" && match$1.NAME === "UpdateLibraryName") {
      return match.VAL[0] === match$1.VAL[0];
    } else {
      return false;
    }
  } else if (variant === "UpdateDestinationProdApiKey" && typeof match$1 === "object" && match$1.NAME === "UpdateDestinationProdApiKey") {
    return match.VAL[0] === match$1.VAL[0];
  } else {
    return false;
  }
}

function groupActions(actions) {
  return BeltListExtensions.groupConsecutive(actions, groupCompare);
}

function groupActionsByDate(actions) {
  return BeltListExtensions.groupConsecutive(actions, (function (a, b) {
                return DateFns.format("yyyy.MM.dd", Belt_Option.getWithDefault(Models.toDateOption(a.createdAt), new Date())) === DateFns.format("yyyy.MM.dd", Belt_Option.getWithDefault(Models.toDateOption(b.createdAt), new Date()));
              }));
}

export {
  filterSystemActions ,
  groupCompare ,
  groupActions ,
  groupActionsByDate ,
}
/* Models Not a pure module */
