// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as DiffSource from "./DiffSource.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function DiffSources(Props) {
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var branchStatus = Props.branchStatus;
  var branchId = Props.branchId;
  var withCopyToBranchOpt = Props.withCopyToBranch;
  var collapsibleOpt = Props.collapsible;
  var withCopyToBranch = withCopyToBranchOpt !== undefined ? withCopyToBranchOpt : false;
  var collapsible = collapsibleOpt !== undefined ? collapsibleOpt : false;
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match !== undefined && typeof match === "object" && match.NAME === "Source") {
    return React.createElement(DiffSource.make, {
                actions: actions,
                masterModel: masterModel,
                branchModel: branchModel,
                branchStatus: branchStatus,
                sourceId: match.VAL,
                branchId: branchId,
                withCopyToBranch: withCopyToBranch,
                collapsible: collapsible
              });
  } else {
    return null;
  }
}

var make = DiffSources;

export {
  make ,
}
/* react Not a pure module */
