// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as SemanticSearchResult from "../../../shared/models/SemanticSearchResult.mjs";

function getSemanticSearchResults(schemaId, query) {
  return $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app().auth().currentUser)).getIdToken().then(function (token) {
                    return fetch("/api/workspaces/" + schemaId + "/semanticSearch?query=" + query, {
                                method: "GET",
                                headers: Caml_option.some(new Headers([
                                          [
                                            "Accept",
                                            "application/json"
                                          ],
                                          [
                                            "Content-Type",
                                            "application/json"
                                          ],
                                          [
                                            "Authorization",
                                            "Bearer " + token
                                          ]
                                        ]))
                              });
                  }).then(function (res) {
                  var unknownErrorCode = res.status;
                  if (unknownErrorCode !== 200) {
                    if (unknownErrorCode !== 401) {
                      console.error("Internal error from API", unknownErrorCode);
                      return Promise.resolve({
                                  NAME: "Error",
                                  VAL: "Unknown"
                                });
                    } else {
                      return Promise.resolve({
                                  NAME: "Error",
                                  VAL: "InvalidToken"
                                });
                    }
                  } else {
                    return res.json().then(function (json) {
                                  return Promise.resolve(Json_decode.field("results", (function (param) {
                                                    return Json_decode.array(SemanticSearchResult.decode, param);
                                                  }), json));
                                }).then(function (results) {
                                return Promise.resolve({
                                            NAME: "Success",
                                            VAL: results
                                          });
                              });
                  }
                }), (function (error) {
                console.error("/semantic/search fetch error", error);
                return Promise.resolve({
                            NAME: "Error",
                            VAL: "Unknown"
                          });
              }));
}

export {
  getSemanticSearchResults ,
}
/* Firebase Not a pure module */
