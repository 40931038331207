// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Styles from "../styles.mjs";
import * as Switch from "../Switch.mjs";
import * as ArrayExt from "../ArrayExt.mjs";
import * as AvoModel from "../avoModel.mjs";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "../AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "../NamedBranch.mjs";
import * as CommentInput from "../CommentInput.mjs";
import * as DrawerHeader from "../detailviews/DrawerHeader.mjs";
import * as IconInfinity from "../IconInfinity.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as EntityLightning from "../EntityLightning.mjs";
import * as IntegrationUtils from "../../../api/functions/src/IntegrationUtils.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as IntegrationConfig from "./IntegrationConfig.mjs";
import * as IntegrationExport from "./IntegrationExport.mjs";
import * as IntegrationHeader from "./IntegrationHeader.mjs";
import * as ObjectActivityLog from "../ObjectActivityLog.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as IntegrationAnalytics from "./IntegrationAnalytics.mjs";
import * as IntegrationEventsFilter from "./IntegrationEventsFilter.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.width(Css.pct(100)),
          tl: {
            hd: Css.height(Css.pct(100.0)),
            tl: {
              hd: Css.marginLeft(Css.px(-2)),
              tl: {
                hd: Css.marginRight(Css.px(16)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: {
                    hd: Css.zIndex(1),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var contentStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.paddingTop(Css.px(20)),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.paddingLeft(Css.px(52)),
            tl: {
              hd: Css.overflowY("auto"),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var filterStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingTop(Css.px(4)),
          tl: {
            hd: Css.paddingRight(Css.px(12)),
            tl: {
              hd: Css.paddingBottom(Css.px(4)),
              tl: {
                hd: Css.paddingLeft(Css.px(8)),
                tl: {
                  hd: Css.marginTop(Css.px(6)),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.background(Styles.Color.light02),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var labelBoxStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(16)),
      tl: {
        hd: Css.marginBottom(Css.px(6)),
        tl: {
          hd: Css.marginLeft(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

var eventPillStyles = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(2), Css.px(8)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light04),
        tl: {
          hd: Css.borderRadius(Css.px(100)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: {
              hd: Css.marginTop(Css.px(6)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var usedInEventsPillStyles = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(2), Css.px(8)),
      tl: {
        hd: Css.marginRight(Css.px(4)),
        tl: {
          hd: Css.marginTop(Css.px(6)),
          tl: /* [] */0
        }
      }
    });

var switchRowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.backgroundColor(Styles.Color.light02),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.padding2(Css.px(8), Css.px(10)),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var commentBoxStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(16)),
      tl: {
        hd: Css.marginLeft(Css.px(-52)),
        tl: {
          hd: Css.marginRight(Css.px(-16)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light02),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
              tl: /* [] */0
            }
          }
        }
      }
    });

var activityLogBoxStyles = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(-37)),
      tl: /* [] */0
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding4(Css.px(12), Css.px(12), Css.px(12), Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderTopRightRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.borderTopLeftRadius(Styles.Border.radius),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var rowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding4(Css.px(12), Css.px(16), Css.px(12), Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderTopWidth(Css.px(0)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var footerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("start"),
          tl: {
            hd: Css.padding4(Css.px(12), Css.px(16), Css.px(12), Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderTopWidth(Css.px(0)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var showMoreButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding2(Css.px(4), Css.px(8)),
              tl: {
                hd: Css.borderRadius(Css.px(16)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light08),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light08),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "background-color"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.backgroundColor(Styles.Color.light10),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var toggleButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.padding2(Css.px(4), Css.px(8)),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "border-color"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.borderColor(Styles.Color.light08),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function Integration$ListHeader(Props) {
  var title = Props.title;
  var showToggleButtonOpt = Props.showToggleButton;
  var toggleButtonLabel = Props.toggleButtonLabel;
  var onToggle = Props.onToggle;
  var showToggleButton = showToggleButtonOpt !== undefined ? showToggleButtonOpt : true;
  return React.createElement("div", {
              className: headerStyles
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: title
                }), showToggleButton ? React.createElement("button", {
                    className: toggleButtonStyles,
                    onClick: (function (param) {
                        Curry._1(onToggle, undefined);
                      })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light10,
                        children: toggleButtonLabel
                      })) : null);
}

var ListHeader = {
  make: Integration$ListHeader
};

function Integration$ListFooter(Props) {
  var itemCount = Props.itemCount;
  var visibleCount = Props.visibleCount;
  var itemLabel = Props.itemLabel;
  var isExpanded = Props.isExpanded;
  var onToggle = Props.onToggle;
  return React.createElement("div", {
              className: footerStyles
            }, React.createElement("button", {
                  className: showMoreButtonStyles,
                  onClick: (function (param) {
                      Curry._1(onToggle, undefined);
                    })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light01,
                      children: String(itemCount - visibleCount | 0) + (
                        isExpanded ? " less" : " more"
                      ) + " " + itemLabel + (
                        (itemCount - visibleCount | 0) > 1 ? "s" : ""
                      )
                    })));
}

var ListFooter = {
  make: Integration$ListFooter
};

function compare(a, b) {
  var makeKey = function (param) {
    return param[0].id;
  };
  return Caml.string_compare(makeKey(a), makeKey(b));
}

var DedupePropertyBundlesToEvents = ArrayExt.Dedupe({
      compare: compare
    });

function updateFilters(integrations, integration, schemaBundle, sendActions, id, filters) {
  Curry.app(sendActions, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            IntegrationAnalytics.trackIntegrationConfigured(integrations, {
                  id: integration.id,
                  name: integration.name,
                  config: integration.config,
                  filters: filters,
                  autoPublish: integration.autoPublish
                }, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "AutoPublish");
          }),
        undefined,
        [[
            {
              NAME: "UpdateIntegrationFilters",
              VAL: [
                id,
                filters
              ]
            },
            {
              integrationId: id,
              integrationQuery: id
            }
          ]]
      ]);
}

function Integration(Props) {
  var integrationId = Props.integrationId;
  var goToCommentId = Props.goToCommentId;
  var integrations = Props.integrations;
  var openBranches = Props.openBranches;
  var currentBranch = Props.currentBranch;
  var model = Props.model;
  var role = Props.role;
  var addToast = Toast.useAddToast();
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use();
  var sendActions = SendActionsContext.use();
  var workspaceLimits = AvoLimits.computeLimits(workspace, model);
  var match = AvoLimits.Integration.entityStatus(workspaceLimits, integrationId);
  var planHint = match[1];
  var availability = match[0];
  var handleDeletePress = function (param) {
    if (window.confirm("Are you sure you want to delete this integration?")) {
      Router.Schema.replaceDrawerItems(undefined, Belt_List.keep(Router.getStaticRoute().drawerItems, (function (item) {
                  if (typeof item === "object" && item.NAME === "integration") {
                    return item.VAL[0] !== integrationId;
                  } else {
                    return true;
                  }
                })));
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            [[
                {
                  NAME: "RemoveIntegration",
                  VAL: integrationId
                },
                {
                  integrationId: integrationId,
                  integrationQuery: integrationId
                }
              ]]
          ]);
      return Curry._1(addToast, {
                  message: "The integration has been deleted"
                });
    }
    
  };
  var integration = Belt_List.getBy(integrations, (function (integration) {
          return integration.id === integrationId;
        }));
  if (integration === undefined) {
    return "Integration not found.";
  }
  var events = integration.filters === /* [] */0 ? AvoModel.filterExcludedEventsBySourcesFromModel(model, IntegrationUtils.findSources(IntegrationUtils.findDestinations(integration, model), model)).events : AvoModel.filterExcludedEvents(model.events, integration.filters);
  var affetctedPropertyBundles = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.mapU(events, (function ($$event) {
                      return Belt_List.mapU(Belt_List.keepMap($$event.propertyBundles, (function (bundleRef) {
                                        return AvoModel.resolvePropertyBundleRef(model.propertyBundles, bundleRef);
                                      })), (function (bundle) {
                                    return bundle.name;
                                  }));
                    })))));
  var match$1 = integration.config;
  var includedPropertyBundlesLabel = match$1 !== undefined && match$1.TAG === "AdobeExperiencePlatform" ? " and " + AvoUtils.plural(undefined, undefined, Belt_SetString.size(affetctedPropertyBundles), "property bundle") : "";
  var eventCount = Belt_List.length(events);
  var events$1 = integration.filters === /* [] */0 ? AvoModel.filterExcludedEventsBySourcesFromModel(model, IntegrationUtils.findSources(IntegrationUtils.findDestinations(integration, model), model)).events : AvoModel.filterExcludedEvents(model.events, integration.filters);
  var match$2 = integration.config;
  var tmp;
  var exit = 0;
  if (match$2 !== undefined && match$2.TAG === "AdobeExperiencePlatform") {
    var affetctedPropertyBundles$1 = Belt_List.flatten(Belt_List.mapU(events$1, (function ($$event) {
                return Belt_List.keepMap($$event.propertyBundles, (function (bundleRef) {
                              return AvoModel.resolvePropertyBundleRef(model.propertyBundles, bundleRef);
                            }));
              })));
    var propertyBundlesToEvents = Curry._1(DedupePropertyBundlesToEvents.dedupe, Belt_List.toArray(Belt_List.map(affetctedPropertyBundles$1, (function (propertyBundle) {
                    var events$2 = Belt_List.keep(events$1, (function ($$event) {
                            return Belt_List.some($$event.propertyBundles, (function (bundleRef) {
                                          return bundleRef.id === propertyBundle.id;
                                        }));
                          }));
                    return [
                            propertyBundle,
                            events$2
                          ];
                  }))));
    var sortedBundles = propertyBundlesToEvents.slice().sort(function (param, param$1) {
          var value = param[0].name.toLocaleLowerCase().localeCompare(param$1[0].name.toLocaleLowerCase());
          if (value < 0.0) {
            return -1;
          } else if (value > 0.0) {
            return 1;
          } else {
            return 0;
          }
        });
    var allBundlesLength = sortedBundles.length;
    var mayCollapse = allBundlesLength > 3;
    var bundlesToTake = mayCollapse ? 3 : allBundlesLength;
    var maybePartialBundles = mayCollapse ? sortedBundles.slice(0, bundlesToTake) : [];
    var match$3 = React.useState(function (param) {
          return false;
        });
    if (allBundlesLength > 0) {
      var setShowAllBundles = match$3[1];
      var showAllBundles = match$3[0];
      tmp = React.createElement("div", undefined, React.createElement(Integration$ListHeader, {
                title: "Property bundles",
                showToggleButton: mayCollapse,
                toggleButtonLabel: (
                  showAllBundles ? "Show fewer" : "Show all"
                ) + " property bundles",
                onToggle: (function (param) {
                    Curry._1(setShowAllBundles, (function (prev) {
                            return !prev;
                          }));
                  })
              }), Belt_Array.mapU(mayCollapse && !showAllBundles ? maybePartialBundles : sortedBundles, (function (param) {
                  var bundle = param[0];
                  return React.createElement("div", {
                              key: bundle.id,
                              className: rowStyles
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.flexBasis(Css.px(200)),
                                        tl: {
                                          hd: Css.flexShrink(0.0),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Medium",
                                      weight: "Semi",
                                      singleLine: true,
                                      color: Styles.Color.light12,
                                      maxWidth: Css.px(200),
                                      withWordBreak: false,
                                      children: bundle.name
                                    })), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.flexWrap("wrap"),
                                          tl: {
                                            hd: Css.flexGrow(1.0),
                                            tl: {
                                              hd: Css.justifyContent("flexEnd"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement("div", {
                                      className: usedInEventsPillStyles
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.light11,
                                          children: "Used in events:"
                                        })), Belt_List.toArray(Belt_List.mapU(param[1], (function ($$event) {
                                            return React.createElement("div", {
                                                        key: $$event.id,
                                                        className: eventPillStyles
                                                      }, React.createElement($$Text.make, {
                                                            size: "Small",
                                                            weight: "Semi",
                                                            color: Styles.Color.light11,
                                                            children: $$event.name
                                                          }));
                                          })))));
                })), mayCollapse ? React.createElement(Integration$ListFooter, {
                  itemCount: allBundlesLength,
                  visibleCount: bundlesToTake,
                  itemLabel: "bundle",
                  isExpanded: showAllBundles,
                  onToggle: (function (param) {
                      Curry._1(setShowAllBundles, (function (prev) {
                              return !prev;
                            }));
                    })
                }) : null);
    } else {
      tmp = React.createElement("div", undefined, React.createElement("div", {
                className: headerStyles
              }, React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: "No property bundles included"
                  })));
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var sortedEvents = Belt_List.sortU(events$1, (function (a, b) {
            var value = a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
            if (value < 0.0) {
              return -1;
            } else if (value > 0.0) {
              return 1;
            } else {
              return 0;
            }
          }));
    var allEventsLength = Belt_List.length(sortedEvents);
    var mayCollapse$1 = allEventsLength > 4;
    var eventsToTake = mayCollapse$1 ? 4 : allEventsLength;
    var maybePartialEvents = mayCollapse$1 ? Belt_List.take(sortedEvents, eventsToTake) : undefined;
    var match$4 = React.useState(function (param) {
          return false;
        });
    var setShowAllEvents = match$4[1];
    var showAllEvents = match$4[0];
    tmp = React.createElement("div", undefined, React.createElement(Integration$ListHeader, {
              title: allEventsLength === 0 ? "No events included" : "Event name",
              showToggleButton: maybePartialEvents !== undefined,
              toggleButtonLabel: (
                showAllEvents ? "Show fewer" : "Show all"
              ) + " events",
              onToggle: (function (param) {
                  Curry._1(setShowAllEvents, (function (prev) {
                          return !prev;
                        }));
                })
            }), Belt_List.toArray(Belt_List.mapU(maybePartialEvents !== undefined && !showAllEvents ? maybePartialEvents : sortedEvents, (function ($$event) {
                    return React.createElement("div", {
                                key: $$event.id,
                                className: rowStyles
                              }, React.createElement($$Text.make, {
                                    size: "Medium",
                                    weight: "Semi",
                                    singleLine: true,
                                    color: Styles.Color.light12,
                                    maxWidth: Css.px(380),
                                    withWordBreak: false,
                                    children: $$event.name
                                  }));
                  }))), maybePartialEvents !== undefined ? React.createElement(Integration$ListFooter, {
                itemCount: allEventsLength,
                visibleCount: Belt_List.length(maybePartialEvents),
                itemLabel: "event",
                isExpanded: showAllEvents,
                onToggle: (function (param) {
                    Curry._1(setShowAllEvents, (function (prev) {
                            return !prev;
                          }));
                  })
              }) : null);
  }
  var tmp$1 = {
    object_: {
      NAME: "Integration",
      VAL: [
        integrationId,
        AvoConfig.getIntegrationName(integration)
      ]
    },
    objectId: integrationId,
    currentBranch: currentBranch,
    showComments: true,
    openBranches: openBranches
  };
  if (goToCommentId !== undefined) {
    tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
  }
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(DrawerHeader.make, {
                  kind: "Integration",
                  options: [{
                      NAME: "Option",
                      VAL: {
                        label: "Delete Integration",
                        onClick: handleDeletePress
                      }
                    }],
                  children: React.createElement($$Text.make, {
                        size: "Large",
                        weight: "Semi",
                        color: Styles.Color.light12,
                        children: AvoConfig.getIntegrationName(integration)
                      })
                }), React.createElement("div", {
                  className: contentStyles
                }, React.createElement(IntegrationHeader.make, {
                      role: role,
                      currentBranch: currentBranch,
                      integrations: integrations,
                      integration: integration,
                      availability: availability
                    }), React.createElement(IntegrationConfig.make, {
                      integrations: integrations,
                      integration: integration,
                      role: role
                    }), React.createElement("div", {
                      className: labelBoxStyles
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: "Events Filter"
                        })), React.createElement("div", {
                      className: filterStyles
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.flexGrow(1.0),
                                tl: /* [] */0
                              })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.darkBlue,
                              children: (
                                Belt_List.length(model.events) === eventCount ? "All " : ""
                              ) + AvoUtils.plural(undefined, undefined, eventCount, "event") + includedPropertyBundlesLabel + " included"
                            })), React.createElement(IntegrationEventsFilter.make, {
                          model: model,
                          filters: integration.filters,
                          onRemove: (function (filterBy) {
                              updateFilters(integrations, integration, schemaBundle, sendActions, integration.id, Belt_List.keepU(integration.filters, (function (item) {
                                          return Caml_obj.notequal(item, filterBy);
                                        })));
                            }),
                          onAdd: (function (filterBy) {
                              var match = Belt_List.getBy(integration.filters, (function (item) {
                                      return Caml_obj.equal(item, filterBy);
                                    }));
                              if (match !== undefined) {
                                return ;
                              } else {
                                return updateFilters(integrations, integration, schemaBundle, sendActions, integration.id, {
                                            hd: filterBy,
                                            tl: integration.filters
                                          });
                              }
                            })
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.height(Css.px(8)),
                            tl: /* [] */0
                          })
                    }), tmp, React.createElement(React.Fragment, undefined, React.createElement("div", {
                          className: labelBoxStyles
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light11,
                              children: null
                            }, "Auto Publishing", React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.marginLeft(Css.px(4)),
                                        tl: {
                                          hd: Css.display("inlineFlex"),
                                          tl: {
                                            hd: Css.verticalAlign("top"),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement(EntityLightning.make, {
                                      availability: availability,
                                      planHint: planHint,
                                      tooltipDirection: "right",
                                      stroke: Css.transparent,
                                      fill: Styles.Color.light10
                                    })))), React.createElement("div", {
                          className: switchRowStyles
                        }, React.createElement(IconInfinity.make, {}), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.flexGrow(1.0),
                                    tl: {
                                      hd: Css.marginLeft(Css.px(8)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.light11,
                                  children: "Automatically publish changes when branches are merged?"
                                })), React.createElement(Switch.make, {
                              value: integration.autoPublish,
                              onChange: (function (autoPublish) {
                                  Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (branch) {
                                            IntegrationAnalytics.trackIntegrationConfigured(integrations, {
                                                  id: integration.id,
                                                  name: integration.name,
                                                  config: integration.config,
                                                  filters: integration.filters,
                                                  autoPublish: autoPublish
                                                }, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "AutoPublish");
                                          }),
                                        undefined,
                                        [[
                                            {
                                              NAME: "UpdateIntegrationAutoPublish",
                                              VAL: [
                                                integration.id,
                                                autoPublish
                                              ]
                                            },
                                            {
                                              integrationId: integration.id,
                                              integrationQuery: integration.id
                                            }
                                          ]]
                                      ]);
                                }),
                              disabled: availability === "Unavailable"
                            }))), React.createElement("div", {
                      className: labelBoxStyles
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: null
                        }, "Payload preview", React.createElement("span", {
                              className: Curry._1(Css.style, {
                                    hd: Css.marginLeft(Css.px(4)),
                                    tl: {
                                      hd: Css.display("inlineFlex"),
                                      tl: {
                                        hd: Css.verticalAlign("top"),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, React.createElement(EntityLightning.make, {
                                  availability: availability,
                                  planHint: planHint,
                                  tooltipDirection: "right",
                                  stroke: Css.transparent,
                                  fill: Styles.Color.light10
                                })))), React.createElement(IntegrationExport.make, {
                      schemaId: workspace.id,
                      branchId: NamedBranch.unnamedBranchToId(currentBranch),
                      integration: integration,
                      availability: availability
                    }), React.createElement("div", {
                      className: labelBoxStyles
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: "Activity Log"
                        })), React.createElement("div", {
                      className: activityLogBoxStyles
                    }, React.createElement(ObjectActivityLog.make, tmp$1)), React.createElement("div", {
                      className: commentBoxStyles
                    }, React.createElement(CommentInput.make, {
                          threadId: integration.id,
                          itemType: "Integration",
                          itemName: AvoConfig.getIntegrationName(integration),
                          mini: true,
                          currentBranch: currentBranch,
                          role: role,
                          location: "Integration"
                        }))));
}

var make = Integration;

export {
  rootStyles ,
  contentStyles ,
  filterStyles ,
  labelBoxStyles ,
  eventPillStyles ,
  usedInEventsPillStyles ,
  switchRowStyles ,
  commentBoxStyles ,
  activityLogBoxStyles ,
  headerStyles ,
  rowStyles ,
  footerStyles ,
  showMoreButtonStyles ,
  toggleButtonStyles ,
  ListHeader ,
  ListFooter ,
  DedupePropertyBundlesToEvents ,
  updateFilters ,
  make ,
}
/* rootStyles Not a pure module */
