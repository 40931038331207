// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Asset from "../Asset.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as CopyButton from "../CopyButton.mjs";
import * as ZoomableImage from "../../../api/functions/src/ZoomableImage.mjs";
import * as InspectorViewInstallDocs from "./InspectorViewInstallDocs.mjs";

var docsCodeBoxStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.fontWeight("normal"),
        tl: /* [] */0
      }
    });

var docsCodeStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.marginTop(Css.px(12)),
          tl: {
            hd: Css.marginBottom(Css.px(0)),
            tl: {
              hd: Css.display("block"),
              tl: {
                hd: Css.backgroundColor(Styles.Color.light02),
                tl: {
                  hd: Css.paddingTop(Css.px(12)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(8)),
                    tl: {
                      hd: Css.paddingRight(Css.px(70)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(12)),
                        tl: {
                          hd: Css.fontFamily(Styles.FontFamily.polar),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.medium),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: {
                                hd: Css.color(Styles.Color.light12),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                  tl: {
                                    hd: Css.borderRadius(Css.px(6)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var copyButtonBoxStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(7)),
        tl: {
          hd: Css.top(Css.px(7)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

function InspectorViewInstallDocsAdobeTags(Props) {
  var apiKey = Props.apiKey;
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light12,
                  children: "Follow the steps below to set up Avo as an Adobe Tags Extension."
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light12,
                  children: "Install the Extension in Adobe Experience Platform."
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement(InspectorViewInstallDocs.Link.make, {
                  path: "workspace/connect-inspector-to-segment",
                  label: "Adobe Tags Extension docs"
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "How to set up Avo as an Adobe Tags Extension:"
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 1,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: "You finished this step by creating this source. Well done! 🎉"
                      })
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 2,
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: null
                    }, "Copy this ", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "API key"
                        })), React.createElement("div", {
                      className: docsCodeBoxStyles
                    }, React.createElement("pre", {
                          className: docsCodeStyles
                        }, apiKey), React.createElement("div", {
                          className: copyButtonBoxStyles
                        }, React.createElement(CopyButton.make, {
                              copyString: apiKey
                            })))), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 3,
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: "Install the Avo Inspector Tag Extension in Adobe Experience Platform."
                    }), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement(ZoomableImage.make, {
                      src: Asset.getPath("adobeInstallExtension.png"),
                      alt: "Adobe Install Extension"
                    }), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: "Paste the API key above into the API key field in the extension settings."
                    })), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 4,
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: "Create a new Rule in Adobe Experience Platform."
                    }), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement(ZoomableImage.make, {
                      src: Asset.getPath("adobeCreateRule.png"),
                      alt: "Adobe Edit Rule"
                    })), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 5,
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: null
                    }, "Configure", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: " Event "
                        }), "by selecting either Adobe Client DataLayer or Google DataLayer extension pushEvent."), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement(ZoomableImage.make, {
                      src: Asset.getPath("adobeConfigureEvent.png"),
                      alt: "Adobe Configure Event"
                    })), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 6,
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: null
                    }, "Create a new", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: " Action "
                        }), "by selecting the ", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: " Avo Inspector "
                        }), "extension"), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement(ZoomableImage.make, {
                      src: Asset.getPath("adobeConfigureAction.png"),
                      alt: "Adobe Configure Action"
                    })), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 7,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: "Finally save the Rule and build it to your app, Avo will start to monitor events in your data layer ✅"
                      })
                }));
}

var make = InspectorViewInstallDocsAdobeTags;

export {
  docsCodeBoxStyles ,
  docsCodeStyles ,
  copyButtonBoxStyles ,
  make ,
}
/* docsCodeBoxStyles Not a pure module */
