// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as AvoModel from "./avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SheetTypes from "./SheetTypes.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as PropertiesSheetCreateGroupType from "./PropertiesSheetCreateGroupType.mjs";
import * as PropertiesSheetEmptyGroupTypes from "./PropertiesSheetEmptyGroupTypes.mjs";

function useScrollSync(param) {
  var syncHeaderAndSheetScroll = function (sheet, header) {
    sheet.addEventListener("scroll", (function (param) {
            header.scrollLeft = sheet.scrollLeft;
          }));
  };
  var sheetRef = React.useRef(null);
  var sheetDomRef = React.useRef(null);
  var sheetHeaderRef = React.useRef(null);
  var handleOuterRef = function (sheetDomNullable) {
    sheetDomRef.current = sheetDomNullable;
    var match = sheetHeaderRef.current;
    if (!(sheetDomNullable == null) && !(match == null)) {
      return syncHeaderAndSheetScroll(sheetDomNullable, match);
    }
    
  };
  var handleSheetHeaderRef = function (headerRef) {
    sheetHeaderRef.current = headerRef;
    var match = sheetDomRef.current;
    if (!(match == null) && !(headerRef == null)) {
      return syncHeaderAndSheetScroll(match, headerRef);
    }
    
  };
  return {
          handleOuterRef: handleOuterRef,
          handleSheetHeaderRef: handleSheetHeaderRef,
          handleReactWindowRef: sheetRef,
          sheetRef: sheetRef
        };
}

var General = {
  useScrollSync: useScrollSync
};

var Events = {
  useScrollSync: useScrollSync
};

function getDefaultColumns(param) {
  return [
          {
            columnType: "PropertyName",
            width: 320,
            order: 0,
            visible: true
          },
          {
            columnType: "PropertyType",
            width: 90,
            order: 1,
            visible: true
          },
          {
            columnType: "PropertyIsList",
            width: 60,
            order: 2,
            visible: true
          },
          {
            columnType: "PropertyEvents",
            width: 160,
            order: 3,
            visible: true
          },
          {
            columnType: "PropertyConstraints",
            width: 160,
            order: 4,
            visible: true
          },
          {
            columnType: "PropertyAbsence",
            width: 160,
            order: 5,
            visible: true
          },
          {
            columnType: "EventPropertyGroup",
            width: 160,
            order: 6,
            visible: true
          },
          {
            columnType: "PropertyNameMapping",
            width: 160,
            order: 7,
            visible: true
          }
        ];
}

function getSendAsLabel(sendAs) {
  if (typeof sendAs === "object") {
    return sendAs._0;
  }
  switch (sendAs) {
    case "SystemProperty" :
        return "System Properties";
    case "EventProperty" :
        return "Event Properties";
    case "UserProperty" :
        return "User Properties";
    
  }
}

function derivePropertyColumnsFromServer(fromServer) {
  if (fromServer !== undefined) {
    return Belt_List.toArray(Belt_List.sort(Belt_List.map(Belt_List.fromArray(getDefaultColumns()), (function (defaultColumn) {
                          return Belt_Option.mapWithDefault(Js_dict.get(fromServer, SheetTypes.columnTypeToJs(defaultColumn.columnType)), defaultColumn, (function (columnFromSettingsFromServer) {
                                        return {
                                                columnType: defaultColumn.columnType,
                                                width: columnFromSettingsFromServer.width,
                                                order: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(columnFromSettingsFromServer.order), defaultColumn.order),
                                                visible: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(columnFromSettingsFromServer.visible), true)
                                              };
                                      }));
                        })), (function (a, b) {
                      return Caml.int_compare(a.order, b.order);
                    })));
  } else {
    return getDefaultColumns();
  }
}

function updatePropertyColumnSettingsOnServer(viewerId, columns) {
  var columnSettings = Js_dict.fromArray(Belt_Array.map(columns, (function (column) {
              return [
                      SheetTypes.columnTypeToJs(column.columnType),
                      {
                        width: column.width,
                        visible: Js_null_undefined.fromOption(column.visible),
                        order: Js_null_undefined.fromOption(column.order)
                      }
                    ];
            })));
  return FirebaseUtils.setPropertyColumnSettings(viewerId, columnSettings);
}

function getPropertiesRows(properties, groupTypes, segmentation) {
  var isInSegmentation = function (sendAs) {
    if (segmentation === undefined) {
      return true;
    } else {
      return Caml_obj.equal(segmentation, sendAs);
    }
  };
  var sortedProperties = Belt_List.toArray(Belt_List.keepMapU(properties, (function (property) {
              if (property.TAG === "PropertyRef") {
                return ;
              } else {
                return property._0;
              }
            })));
  sortedProperties.sort(function (a, b) {
        var n = a.name.localeCompare(b.name);
        if (n > 0.0) {
          return 1;
        } else if (n < 0.0) {
          return -1;
        } else {
          return 0;
        }
      });
  var eventPropertiesSection;
  if (isInSegmentation("EventProperty")) {
    var eventProperties = Belt_Array.keepMapU(sortedProperties, (function (property) {
            if (property.sendAs === "EventProperty") {
              return {
                      TAG: "Property",
                      _0: property
                    };
            }
            
          }));
    eventPropertiesSection = Belt_Array.concatMany([
          [{
              TAG: "CategoryHeader",
              _0: "Event Properties",
              _1: "EventProperty",
              _2: "PROPERTY TYPE",
              _3: eventProperties.length
            }],
          eventProperties,
          ["CategoryFooter"]
        ]);
  } else {
    eventPropertiesSection = [];
  }
  var userPropertiesSection;
  if (isInSegmentation("UserProperty")) {
    var userProperties = Belt_Array.keepMapU(sortedProperties, (function (property) {
            if (property.sendAs === "UserProperty") {
              return {
                      TAG: "Property",
                      _0: property
                    };
            }
            
          }));
    userPropertiesSection = Belt_Array.concatMany([
          [{
              TAG: "CategoryHeader",
              _0: "User Properties",
              _1: "UserProperty",
              _2: "PROPERTY TYPE",
              _3: userProperties.length
            }],
          userProperties,
          ["CategoryFooter"]
        ]);
  } else {
    userPropertiesSection = [];
  }
  var systemPropertiesSection;
  if (isInSegmentation("SystemProperty")) {
    var systemProperties = Belt_Array.keepMapU(sortedProperties, (function (property) {
            if (property.sendAs === "SystemProperty") {
              return {
                      TAG: "Property",
                      _0: property
                    };
            }
            
          }));
    systemPropertiesSection = Belt_Array.concatMany([
          [{
              TAG: "CategoryHeader",
              _0: "System Properties",
              _1: "SystemProperty",
              _2: "PROPERTY TYPE",
              _3: systemProperties.length
            }],
          systemProperties,
          ["CategoryFooter"]
        ]);
  } else {
    systemPropertiesSection = [];
  }
  var groupPropertiesRows = [];
  Belt_Array.forEach(Belt_Array.keepU(groupTypes, (function (param) {
              return isInSegmentation({
                          TAG: "GroupProperty",
                          _0: param.id
                        });
            })), (function (groupType) {
          var propertiesInGroup = Belt_Array.keepU(sortedProperties, (function (param) {
                  return Caml_obj.equal(param.sendAs, {
                              TAG: "GroupProperty",
                              _0: groupType.id
                            });
                }));
          groupPropertiesRows.push({
                TAG: "CategoryHeader",
                _0: groupType.name,
                _1: {
                  TAG: "GroupProperty",
                  _0: groupType.id
                },
                _2: "GROUP PROPERTY",
                _3: propertiesInGroup.length
              });
          Belt_Array.forEach(propertiesInGroup, (function (property) {
                  groupPropertiesRows.push({
                        TAG: "Property",
                        _0: property
                      });
                }));
          groupPropertiesRows.push("CategoryFooter");
        }));
  var createGroupTypeRow;
  var exit = 0;
  if (segmentation !== undefined && typeof segmentation !== "object") {
    createGroupTypeRow = [];
  } else {
    exit = 1;
  }
  if (exit === 1) {
    createGroupTypeRow = [Caml_obj.equal(groupTypes, []) ? ({
            TAG: "Info",
            _0: (function (param) {
                return React.createElement(PropertiesSheetEmptyGroupTypes.make, {
                            globalSend: param.globalSend,
                            columns: param.columns
                          });
              }),
            _1: 227
          }) : ({
            TAG: "Info",
            _0: (function (param) {
                return React.createElement(PropertiesSheetCreateGroupType.make, {
                            columns: param.columns,
                            globalSend: param.globalSend
                          });
              }),
            _1: 50
          })];
  }
  return Belt_Array.concatMany([
              eventPropertiesSection,
              userPropertiesSection,
              systemPropertiesSection,
              groupPropertiesRows,
              createGroupTypeRow
            ]);
}

function getPropertyGroups(propertyGroups, properties) {
  var rows = [];
  Belt_List.forEach(propertyGroups, (function (propertyGroup) {
          rows.push({
                TAG: "EventPropertyGroupHeader",
                _0: propertyGroup,
                _1: Belt_List.length(propertyGroup.properties),
                _2: Router.Link.addDrawerItem(undefined, {
                      NAME: "propertyGroup",
                      VAL: [
                        propertyGroup.id,
                        undefined
                      ]
                    })
              });
          Belt_List.forEach(AvoModel.resolvePropertyIds(properties, propertyGroup.properties), (function (property) {
                  rows.push({
                        TAG: "EventGroupProperty",
                        _0: property
                      });
                }));
          rows.push("CategoryFooter");
        }));
  return rows;
}

function usePropertySheetColumns(user) {
  var match = React.useState(function (param) {
        return derivePropertyColumnsFromServer(Caml_option.nullable_to_opt(user.propertyColumnSettings));
      });
  var columns = match[0];
  React.useEffect((function (param) {
          var shouldUpdate = Caml_obj.notequal(derivePropertyColumnsFromServer(Caml_option.nullable_to_opt(user.propertyColumnSettings)), columns);
          if (shouldUpdate) {
            updatePropertyColumnSettingsOnServer(user.id, columns);
          }
          
        }), [columns]);
  return [
          columns,
          match[1]
        ];
}

function sendAsToAnalyticsSegmentation(segmentation) {
  if (segmentation === undefined) {
    return "All";
  }
  if (typeof segmentation === "object") {
    return "Group";
  }
  switch (segmentation) {
    case "SystemProperty" :
        return "System";
    case "EventProperty" :
        return "Event";
    case "UserProperty" :
        return "User";
    
  }
}

var Properties = {
  useScrollSync: useScrollSync,
  getDefaultColumns: getDefaultColumns,
  getSendAsLabel: getSendAsLabel,
  derivePropertyColumnsFromServer: derivePropertyColumnsFromServer,
  updatePropertyColumnSettingsOnServer: updatePropertyColumnSettingsOnServer,
  getPropertiesRows: getPropertiesRows,
  getPropertyGroups: getPropertyGroups,
  usePropertySheetColumns: usePropertySheetColumns,
  sendAsToAnalyticsSegmentation: sendAsToAnalyticsSegmentation
};

export {
  General ,
  Events ,
  Properties ,
}
/* react Not a pure module */
