// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as IconX from "./IconX.mjs";
import * as Icons from "./Icons.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "./DateFns.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TrialRequest from "./TrialRequest.mjs";
import * as React$1 from "motion/react";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as SegmentControl from "./SegmentControl.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as IconArrowTopRight from "./IconArrowTopRight.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as GetPricingTiersEndpoint from "../../shared/endpoints/GetPricingTiersEndpoint.mjs";
import * as BillingPlansCheckoutTeamV3 from "./BillingPlansCheckoutTeamV3.mjs";

function fetchPricingPlans(param) {
  return Firebase.Auth.unsafeGetCurrentUser(Firebase.app().auth()).getIdToken().then(function (userToken) {
                  return fetch(Firebase.apiUrl + "/billing/plans", {
                              method: "GET",
                              headers: Caml_option.some(new Headers([
                                        [
                                          "Accept",
                                          "application/json"
                                        ],
                                        [
                                          "Content-Type",
                                          "application/json"
                                        ],
                                        [
                                          "Authorization",
                                          "Bearer " + userToken
                                        ]
                                      ]))
                            });
                }).then(function (response) {
                return response.json().then(function (json) {
                            return [
                                    response,
                                    json
                                  ];
                          });
              }).then(function (param) {
              var response = param[0];
              if (response.status === 200) {
                return Promise.resolve(GetPricingTiersEndpoint.$$Response.decode(param[1]));
              } else {
                return Promise.reject(Js_exn.raiseError("Failed to fetch pricing tiers. Got status: " + String(response.status)));
              }
            });
}

var root = Curry._1(Css.style, {
      hd: Css.padding(Css.px(24)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: /* [] */0
        }
      }
    });

var header = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(32)),
      tl: {
        hd: Css.marginTop(Css.px(20)),
        tl: /* [] */0
      }
    });

var plans = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.gap(Css.px(16)),
            tl: {
              hd: Css.alignItems("flexStart"),
              tl: {
                hd: Css.borderRadius(Css.px(10)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var slideOver = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.maxWidth(Css.vw(70.0)),
              tl: {
                hd: Css.minWidth(Css.px(320)),
                tl: {
                  hd: Css.overflow("auto"),
                  tl: {
                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.boxShadow(Styles.Shadow.standard),
                        tl: {
                          hd: Css.display("flex"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var slideOverClose = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.top(Css.px(16)),
                tl: {
                  hd: Css.right(Css.px(16)),
                  tl: {
                    hd: Css.width(Css.px(32)),
                    tl: {
                      hd: Css.height(Css.px(32)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: {
                                hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light10),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light11),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var backdrop = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.zIndex(Styles.ZIndex.aboveAll),
                tl: {
                  hd: Css.backgroundColor({
                        NAME: "rgb",
                        VAL: [
                          0,
                          0,
                          0
                        ]
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var plan = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.maxWidth(Css.px(380)),
        tl: {
          hd: Css.height(Css.px(686)),
          tl: {
            hd: Css.flexGrow(1.0),
            tl: {
              hd: Css.flexShrink(1.0),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.boxShadow(Styles.Shadow.standard),
                  tl: {
                    hd: Css.borderRadius(Css.px(16)),
                    tl: {
                      hd: Css.paddingTop(Css.px(40)),
                      tl: {
                        hd: Css.paddingRight(Css.px(32)),
                        tl: {
                          hd: Css.paddingBottom(Css.px(40)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(32)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var planHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gap(Css.px(10)),
          tl: /* [] */0
        }
      }
    });

var trialingBox = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.avoPink, 0.1)),
          tl: {
            hd: Css.width(Css.pct(100.0)),
            tl: {
              hd: Css.height(Css.px(40)),
              tl: {
                hd: Css.paddingLeft(Css.px(12)),
                tl: {
                  hd: Css.paddingRight(Css.px(4)),
                  tl: {
                    hd: Css.marginBottom(Css.px(24)),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.selector(" svg", {
                              hd: Css.flexShrink(0.0),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var planDivider = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.height(Css.px(1)),
        tl: {
          hd: Css.background(Styles.Color.light04),
          tl: {
            hd: Css.marginBottom(Css.px(16)),
            tl: {
              hd: Css.marginTop(Css.px(32)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var startTrialButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.color(Styles.Color.light11),
              tl: {
                hd: Css.textDecoration("underline"),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "color"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.light12),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.lineHeight(Css.px(40)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var comparePlansLink = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.maxWidth(Css.px(1174)),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.alignSelf("stretch"),
              tl: {
                hd: Css.marginTop(Css.px(32)),
                tl: {
                  hd: Css.textDecoration("none"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.textDecoration("underline"),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function BillingPlansOrb$Feature(Props) {
  var text = Props.text;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("flexStart"),
                      tl: {
                        hd: Css.gap(Css.px(8)),
                        tl: {
                          hd: Css.marginTop(Css.px(16)),
                          tl: {
                            hd: Styles.prettyWrap,
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.padding(Css.px(1)),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.light10),
                                tl: {
                                  hd: Css.borderRadius(Css.pct(50.0)),
                                  tl: {
                                    hd: Css.marginTop(Css.pxFloat(2.5)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(Icon.make, {
                      type_: "simpleCheckmark",
                      size: {
                        NAME: "int",
                        VAL: 10
                      },
                      color: Styles.Color.light11
                    })), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light11,
                  children: text
                }));
}

function BillingPlansOrb$CurrentPlanPill(Props) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.backgroundColor(Styles.Color.lightGreen),
                    tl: {
                      hd: Css.borderRadius(Css.px(100)),
                      tl: {
                        hd: Css.paddingTop(Css.px(4)),
                        tl: {
                          hd: Css.paddingRight(Css.px(8)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(4)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(8)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.darkGreen,
                  children: "Current plan"
                }));
}

function BillingPlansOrb(Props) {
  var currentPlanName = Props.currentPlanName;
  var payment = Props.payment;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use();
  var globalSend = GlobalSendContext.use();
  var match = TrialRequest.useTrialRequest();
  var match$1 = React.useState(function (param) {
        return "annual";
      });
  var setPeriod = match$1[1];
  var period = match$1[0];
  var match$2 = React.useState(function (param) {
        return "Initial";
      });
  var setPricingPlans = match$2[1];
  var pricingPlans = match$2[0];
  var updatePricing = function (param) {
    Curry._1(setPricingPlans, (function (param) {
            return "Pending";
          }));
    $$Promise.$$catch(fetchPricingPlans().then(function (json) {
              Curry._1(setPricingPlans, (function (param) {
                      return {
                              TAG: "Success",
                              _0: json.plans
                            };
                    }));
            }), (function (_error) {
            Curry._1(setPricingPlans, (function (param) {
                    return "Error";
                  }));
            return Promise.resolve();
          }));
  };
  React.useEffect((function (param) {
          updatePricing();
        }), []);
  var match$3 = React.useState(function (param) {
        return false;
      });
  var setCheckingOut = match$3[1];
  var checkingOut = match$3[0];
  Hooks.useIntercomVisibility(checkingOut);
  var handleContactSupport = function (param) {
    if (Intercom.isAvailable()) {
      return Intercom.showNewMessage("Hey! I have a some questions about using Avo to help my team scale.");
    } else {
      window.location.href = "mailto:support@avo.app?subject=Questions%20about%20Avo%20for%20my%20team";
      return ;
    }
  };
  var handleDowngrade = function (param) {
    if (Intercom.isAvailable()) {
      Intercom.showNewMessage("Hi. I'd like to downgrade my subscription to the Free plan please.");
    } else {
      window.location.href = "mailto:support@avo.app?subject=Downgrade%20to%20Free%20plan";
    }
    AnalyticsRe.cancelSubscriptionButtonClicked(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, AnalyticsUtils.planToAnalyticsPlan(currentPlanName), schemaBundle.branchId, schemaBundle.schemaId);
  };
  var handleUpgrade = function (planName, buttonCopy, _event) {
    var exit = 0;
    switch (planName) {
      case "TeamV3" :
      case "TeamV3Annual" :
          exit = 1;
          break;
      case "EnterpriseV2" :
          if (Intercom.isAvailable()) {
            Intercom.showNewMessage("Hi! I'd love to upgrade to the Enterprise plan. What are the next steps?");
          } else {
            window.location.href = "mailto:support@avo.app?subject=Upgrade%20to%20Enterprise%20plan";
          }
          break;
      default:
        
    }
    if (exit === 1) {
      Curry._1(setCheckingOut, (function (param) {
              return true;
            }));
    }
    AnalyticsRe.subscriptionUpgradeStarted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, AnalyticsUtils.planToAnalyticsPlan(planName), AnalyticsUtils.planToAnalyticsPlanSlug(planName), 300.0, planName === "TeamV3Annual" || planName === "EnterpriseV2" ? "Annual" : "Monthly", buttonCopy, schemaBundle.branchId, schemaBundle.schemaId);
  };
  var handleViewDetails = function (param) {
    window.open(payment.portalUrl, "_blank");
    AnalyticsRe.subscriptionDetailsViewed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, AnalyticsUtils.planToAnalyticsPlan(currentPlanName), AnalyticsUtils.planToAnalyticsPlanSlug(currentPlanName), 300.0, currentPlanName === "TeamV3Annual" || currentPlanName === "EnterpriseV2" ? "Annual" : "Monthly", schemaBundle.schemaId);
  };
  var tmp;
  tmp = currentPlanName === "FreeV3" ? React.createElement(Button.make, {
          label: "View subscription details",
          onClick: handleViewDetails,
          size: "large",
          style: "outline"
        }) : React.createElement(Button.make, {
          label: "Downgrade",
          onClick: handleDowngrade,
          size: "large",
          style: "outline"
        });
  var tmp$1;
  switch (currentPlanName) {
    case "TeamV3" :
        tmp$1 = React.createElement(SegmentControl.make, {
              options: [[
                  "Monthly",
                  "monthly"
                ]],
              onSelect: (function (param) {
                  
                }),
              selected: "monthly",
              disabled: true,
              circular: true
            });
        break;
    case "TeamV3Annual" :
        tmp$1 = React.createElement(SegmentControl.make, {
              options: [[
                  "Annual",
                  "annual"
                ]],
              onSelect: (function (param) {
                  
                }),
              selected: "annual",
              disabled: true,
              circular: true
            });
        break;
    default:
      tmp$1 = React.createElement(SegmentControl.make, {
            options: [
              [
                "Annual",
                "annual"
              ],
              [
                "Monthly",
                "monthly"
              ]
            ],
            onSelect: (function (period) {
                Curry._1(setPeriod, (function (param) {
                        return period;
                      }));
              }),
            selected: period,
            circular: true
          });
  }
  var match$4 = workspace.trial;
  var tmp$2;
  var exit = 0;
  switch (currentPlanName) {
    case "FreeV3" :
        var exit$1 = 0;
        if (match$4 !== undefined) {
          var exit$2 = 0;
          switch (match$4.plan.name) {
            case "TeamV3" :
            case "TeamV3Annual" :
                exit$2 = 3;
                break;
            default:
              exit$1 = 2;
          }
          if (exit$2 === 3) {
            var partial_arg = period === "annual" ? "TeamV3Annual" : "TeamV3";
            tmp$2 = React.createElement("div", {
                  className: trialingBox
                }, React.createElement(Icons.Timer.make, {
                      size: 16,
                      color: Styles.Color.avoPinkShade2
                    }), React.createElement(Spacer.make, {
                      width: 12
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.avoPinkShade2,
                      children: null
                    }, DateFns.formatDistanceToNow(false, false, Belt_Option.mapWithDefault(workspace.trial, new Date(), (function (trial) {
                                return trial.endAt;
                              }))) + " left of your trial. ", React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              return handleUpgrade(partial_arg, "Upgrade now", param);
                            }),
                          style: "MagentaSecondary",
                          children: "Upgrade now"
                        })));
          }
          
        } else {
          exit$1 = 2;
        }
        if (exit$1 === 2) {
          var partial_arg$1 = period === "annual" ? "TeamV3Annual" : "TeamV3";
          tmp$2 = React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("baseline"),
                        tl: {
                          hd: Css.flexWrap("wrap"),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement(Button.make, {
                    label: "Build your Team plan",
                    loading: checkingOut && pricingPlans === "Pending",
                    onClick: (function (param) {
                        return handleUpgrade(partial_arg$1, "Build your Team plan", param);
                      }),
                    size: "large"
                  }), match[0] === "Available" ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: null
                        }, "Or ", React.createElement("button", {
                              className: startTrialButton,
                              onClick: (function (param) {
                                  Curry._1(globalSend, {
                                        TAG: "OpenModal",
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "TryForFree"
                                        }
                                      });
                                })
                            }, React.createElement($$Text.make, {
                                  element: "Span",
                                  size: "Medium",
                                  weight: "Semi",
                                  children: "start free trial"
                                })))) : null);
        }
        break;
    case "TeamV3" :
    case "TeamV3Annual" :
        exit = 1;
        break;
    case "EnterpriseV2" :
        tmp$2 = React.createElement(Button.make, {
              label: "Downgrade",
              onClick: handleDowngrade,
              size: "large",
              style: "outline"
            });
        break;
    default:
      tmp$2 = React.createElement(Spacer.make, {
            height: 40
          });
  }
  if (exit === 1) {
    tmp$2 = React.createElement(Button.make, {
          label: "View subscription details",
          onClick: handleViewDetails,
          size: "large",
          style: "outline"
        });
  }
  var match$5 = workspace.trial;
  var tmp$3;
  var exit$3 = 0;
  if (currentPlanName === "EnterpriseV2") {
    tmp$3 = React.createElement(Button.make, {
          label: "View subscription details",
          onClick: handleViewDetails,
          size: "large",
          style: "outline"
        });
  } else if (match$5 !== undefined) {
    var exit$4 = 0;
    switch (match$5.plan.name) {
      case "Enterprise" :
      case "EnterpriseV2" :
          exit$4 = 2;
          break;
      default:
        exit$3 = 1;
    }
    if (exit$4 === 2) {
      tmp$3 = React.createElement("div", {
            className: trialingBox
          }, React.createElement(Icons.Timer.make, {
                size: 16,
                color: Styles.Color.avoPinkShade2
              }), React.createElement(Spacer.make, {
                width: 12
              }), React.createElement($$Text.make, {
                size: "Small",
                color: Styles.Color.avoPinkShade2,
                children: null
              }, DateFns.formatDistanceToNow(false, false, Belt_Option.mapWithDefault(workspace.trial, new Date(), (function (trial) {
                          return trial.endAt;
                        }))) + " left of your trial. ", React.createElement(TextButton.make, {
                    onClick: (function (param) {
                        return handleUpgrade("EnterpriseV2", "Contact sales", param);
                      }),
                    style: "MagentaSecondary",
                    children: "Contact sales"
                  })));
    }
    
  } else {
    exit$3 = 1;
  }
  if (exit$3 === 1) {
    tmp$3 = React.createElement(Button.make, {
          label: "Contact sales",
          onClick: (function (param) {
              return handleUpgrade("EnterpriseV2", "Contact sales", param);
            }),
          size: "large"
        });
  }
  var tmp$4;
  tmp$4 = checkingOut && typeof pricingPlans === "object" ? React.createElement(React.Fragment, undefined, React.createElement(React$1.motion.div, {
              animate: {
                opacity: 0.4
              },
              transition: {
                duration: 0.3
              },
              initial: {
                opacity: 0.0
              },
              exit: {
                opacity: 0.0
              },
              className: backdrop,
              key: "backdrop",
              onClick: (function (param) {
                  Curry._1(setCheckingOut, (function (param) {
                          return false;
                        }));
                })
            }), React.createElement(React$1.motion.div, {
              animate: {
                opacity: 1,
                x: 0
              },
              transition: {
                duration: 0.3
              },
              initial: {
                opacity: 0,
                x: 50
              },
              exit: {
                opacity: 0,
                x: 50
              },
              className: slideOver,
              key: "slide-over",
              children: null
            }, React.createElement("button", {
                  className: slideOverClose,
                  onClick: (function (param) {
                      Curry._1(setCheckingOut, (function (param) {
                              return false;
                            }));
                    })
                }, React.createElement(IconX.make, {})), React.createElement(BillingPlansCheckoutTeamV3.make, {
                  pricingPlans: pricingPlans._0,
                  onContactSupport: handleContactSupport,
                  initialPeriod: period
                }))) : null;
  return React.createElement("div", {
              className: root
            }, React.createElement("header", {
                  className: header
                }, React.createElement(Title.make, {
                      children: "Upgrade your tracking plan",
                      size: "Huge"
                    }), React.createElement(Spacer.make, {
                      height: 12
                    }), React.createElement($$Text.make, {
                      size: "Large",
                      color: Styles.Color.light10,
                      children: null
                    }, "We’re here to help your team scale! If you have any questions please ", React.createElement(TextButton.make, {
                          onClick: handleContactSupport,
                          style: "Blue",
                          size: "Large",
                          children: "reach out"
                        }), ".")), React.createElement(Spacer.make, {
                  height: 40
                }), React.createElement("div", {
                  className: plans
                }, React.createElement("article", {
                      className: plan
                    }, React.createElement("header", {
                          className: planHeader
                        }, React.createElement(Title.make, {
                              children: "Free",
                              color: Styles.Color.avoPink,
                              size: "Small"
                            }), currentPlanName === "FreeV3" ? React.createElement(BillingPlansOrb$CurrentPlanPill, {}) : null), React.createElement(Spacer.make, {
                          height: 12
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.light12,
                          children: "For startups and small teams who want to move from spreadsheets to a single source of truth."
                        }), React.createElement(Spacer.make, {
                          height: 52
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Always"
                        }), React.createElement(Title.make, {
                          children: "Free",
                          size: "Large"
                        }), React.createElement(Spacer.make, {
                          height: 12
                        }), tmp, React.createElement("div", {
                          className: planDivider
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "What's included?"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "2 tracking plan editor seats"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Unlimited comment seats"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "5 stakeholder domains"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "100k events observed in Inspector "
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Codegen included for editors"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Schema sync to 1 downstream schema registry"
                        })), React.createElement("article", {
                      className: plan
                    }, React.createElement("header", {
                          className: planHeader
                        }, React.createElement(Title.make, {
                              children: "Team",
                              color: Styles.Color.avoPink,
                              size: "Small"
                            }), currentPlanName === "TeamV3" || currentPlanName === "TeamV3Annual" ? React.createElement(BillingPlansOrb$CurrentPlanPill, {}) : null, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.flexGrow(1.0),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.justifyContent("flexEnd"),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, tmp$1)), React.createElement(Spacer.make, {
                          height: 12
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.light12,
                          children: "For scaleups and data-driven teams who need a configurable tracking plan and data quality workflow."
                        }), React.createElement(Spacer.make, {
                          height: 32
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Starting at"
                        }), React.createElement(Title.make, {
                          children: null,
                          size: "Large"
                        }, period === "annual" ? "$50" : "$60", React.createElement($$Text.make, {
                              element: "Span",
                              size: "Medium",
                              weight: "Regular",
                              color: Styles.Color.light11,
                              children: " Per editor/month"
                            })), React.createElement(Spacer.make, {
                          height: 12
                        }), tmp$2, React.createElement("div", {
                          className: planDivider
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "All of Free, plus..."
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "5 tracking plan editor seats"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Unlimited stakeholder domains"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Access to more events observed in Inspector"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Codegen-only permissions"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Schema sync to up to 5 downstream schema registries"
                        })), React.createElement("article", {
                      className: plan
                    }, React.createElement("header", {
                          className: planHeader
                        }, React.createElement(Title.make, {
                              children: "Enterprise",
                              color: Styles.Color.avoPink,
                              size: "Small"
                            }), currentPlanName === "EnterpriseV2" ? React.createElement(BillingPlansOrb$CurrentPlanPill, {}) : null), React.createElement(Spacer.make, {
                          height: 12
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.light12,
                          children: "For large organizations needing data quality enforcement, backed by robust security and dedicated support."
                        }), React.createElement(Spacer.make, {
                          height: 32
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Talk to us for"
                        }), React.createElement(Title.make, {
                          children: "Custom pricing",
                          size: "Large"
                        }), React.createElement(Spacer.make, {
                          height: 12
                        }), tmp$3, React.createElement("div", {
                          className: planDivider
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "All of Team, plus..."
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Unlimited stakeholder domains"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "1M events observed in Inspector"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Enforceable admin approvals"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Enforceable data standards"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Stakeholder impact visibility"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Schema sync to your entire stack"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Enterprise-grade security"
                        }), React.createElement(BillingPlansOrb$Feature, {
                          text: "Premium support"
                        }))), React.createElement("a", {
                  className: comparePlansLink,
                  href: "https://avo.app/pricing",
                  rel: "noopener",
                  target: "_blank"
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Medium",
                      color: Styles.Color.light10,
                      children: "Compare plans"
                    }), React.createElement(Spacer.make, {
                      display: "inlineBlock",
                      width: 2
                    }), React.createElement(IconArrowTopRight.make, {
                      size: 6,
                      color: Styles.Color.light10
                    })), React.createElement(React$1.AnimatePresence, {
                  children: tmp$4
                }));
}

var make = BillingPlansOrb;

export {
  make ,
}
/* root Not a pure module */
