// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import QuillDelta from "quill-delta";
import * as QuillWithToolbar from "./QuillWithToolbar.mjs";
import * as OpsWithDeltaState from "./OpsWithDeltaState.mjs";

var wrapper = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: {
        hd: Css.paddingRight(Css.px(12)),
        tl: {
          hd: Css.flexShrink(1.0),
          tl: {
            hd: Css.overflowX("auto"),
            tl: {
              hd: Css.paddingBottom(Css.px(12)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var editorStyles = Curry._1(Css.style, {
      hd: Css.selector(" div.ql-editor", {
            hd: Css.padding(Css.px(0)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.selector(" div.ql-toolbar", {
              hd: Css.marginLeft(Css.px(-4)),
              tl: {
                hd: Css.padding(Css.px(0)),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var S = {
  wrapper: wrapper,
  editorStyles: editorStyles
};

function TriggerModalDescription(Props) {
  var $$event = Props.event;
  var description = Props.description;
  var onUpdate = Props.onUpdate;
  var variantIdentifier = Props.variantIdentifier;
  var triggerId = Props.triggerId;
  var schemaBundle = Props.schemaBundle;
  var viewerCanEdit = Props.viewerCanEdit;
  return React.createElement("div", {
              className: wrapper
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light10,
                  children: "Trigger Description"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement("div", {
                  className: editorStyles
                }, React.createElement(OpsWithDeltaState.make, {
                      value: new QuillDelta(JSON.parse(description)),
                      onFlush: (function (description) {
                          Curry._1(onUpdate, JSON.stringify(description));
                        }),
                      onChangeCompleted: (function (param, param$1) {
                          if (variantIdentifier !== undefined) {
                            return AnalyticsRe.eventTriggerConfiguredOnVariant($$event.id, $$event.name, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "UpdateDescription", "Description", $$event.triggers.length, variantIdentifier.variantId, schemaBundle.branchId, schemaBundle.schemaId);
                          } else {
                            return AnalyticsRe.eventTriggerConfiguredOnEvent($$event.id, $$event.name, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "UpdateDescription", "Description", $$event.triggers.length, schemaBundle.branchId, schemaBundle.schemaId);
                          }
                        }),
                      children: (function (value, onChange, onBlur, param) {
                          return React.createElement(QuillWithToolbar.make, {
                                      value: value,
                                      onChange: onChange,
                                      id: "trigger-" + triggerId,
                                      placeholder: "Trigger description...",
                                      onBlur: (function (param) {
                                          Curry._1(onBlur, value);
                                        }),
                                      scrollOnFocus: false,
                                      readOnly: !viewerCanEdit,
                                      key: triggerId
                                    });
                        })
                    })));
}

var make = TriggerModalDescription;

export {
  S ,
  make ,
}
/* wrapper Not a pure module */
