// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function getInspectorStatusPriority(isEventModified, statusOfEnv) {
  if (isEventModified) {
    switch (statusOfEnv) {
      case "Prod" :
          return 3;
      case "Dev" :
          return 1;
      case "Staging" :
          return 2;
      
    }
  } else {
    switch (statusOfEnv) {
      case "Prod" :
          return 1;
      case "Dev" :
          return 2;
      case "Staging" :
          return 3;
      
    }
  }
}

function compareInspectorLoadingStatus(loadingStatusA, loadingStatusB) {
  if (typeof loadingStatusA !== "object" && loadingStatusA !== "Loading") {
    if (typeof loadingStatusB !== "object" && loadingStatusB !== "Loading") {
      return 0;
    } else {
      return 1;
    }
  }
  if (typeof loadingStatusB !== "object" && loadingStatusB !== "Loading") {
    return -1;
  } else {
    return 0;
  }
}

function orderInspectorStatusByPriority(isEventModified, statusArray) {
  return statusArray.slice().sort(function (a, b) {
              if (a.TAG !== "Inspector") {
                return 999;
              }
              if (b.TAG !== "Inspector") {
                return 999;
              }
              var sortLoadingStatus = compareInspectorLoadingStatus(a._1, b._1);
              if (sortLoadingStatus === 0) {
                return Caml.int_compare(getInspectorStatusPriority(isEventModified, a._0), getInspectorStatusPriority(isEventModified, b._0));
              } else {
                return sortLoadingStatus;
              }
            });
}

function shouldShowCodegenFirst(isEventModified, inspectorStatuses, codegenStatus) {
  var knowsCodegenStatus;
  if (codegenStatus.TAG === "Inspector") {
    knowsCodegenStatus = false;
  } else {
    var match = codegenStatus._0;
    knowsCodegenStatus = typeof match !== "object" && match !== "Loading" ? false : true;
  }
  var knowsDevInspectorStatus = Belt_Array.someU(inspectorStatuses, (function (status) {
          if (status.TAG !== "Inspector") {
            return false;
          }
          switch (status._0) {
            case "Dev" :
                var tmp = status._1;
                if (typeof tmp !== "object" && tmp !== "Loading") {
                  return false;
                } else {
                  return true;
                }
            case "Prod" :
            case "Staging" :
                return false;
            
          }
        }));
  var knowsProdInspectorStatus = Belt_Array.someU(inspectorStatuses, (function (status) {
          if (status.TAG !== "Inspector") {
            return false;
          }
          switch (status._0) {
            case "Prod" :
                var tmp = status._1;
                if (typeof tmp !== "object" && tmp !== "Loading") {
                  return false;
                } else {
                  return true;
                }
            case "Dev" :
            case "Staging" :
                return false;
            
          }
        }));
  var eventModifiedAndCodegenStatusKnown = isEventModified && knowsCodegenStatus;
  var eventModifiedAndInspectorStatusUnknown = isEventModified && !knowsDevInspectorStatus;
  var knowsCodegenStatusButNotInspectorStatus = knowsCodegenStatus && !knowsDevInspectorStatus && !knowsProdInspectorStatus;
  if (eventModifiedAndCodegenStatusKnown || eventModifiedAndInspectorStatusUnknown) {
    return true;
  } else {
    return knowsCodegenStatusButNotInspectorStatus;
  }
}

var orderInspectorStatusesBasedOnIsEventModified = orderInspectorStatusByPriority;

function highlightedStatus(isEventModified, codegenStatus, inspectorStatuses) {
  var shouldShowCodegenFirst$1 = shouldShowCodegenFirst(isEventModified, inspectorStatuses, codegenStatus);
  if (shouldShowCodegenFirst$1) {
    return codegenStatus;
  } else {
    return Belt_Option.getWithDefault(Belt_Array.get(orderInspectorStatusByPriority(isEventModified, inspectorStatuses), 0), {
                TAG: "Inspector",
                _0: "Prod",
                _1: "Unknown"
              });
  }
}

export {
  getInspectorStatusPriority ,
  compareInspectorLoadingStatus ,
  orderInspectorStatusByPriority ,
  shouldShowCodegenFirst ,
  orderInspectorStatusesBasedOnIsEventModified ,
  highlightedStatus ,
}
/* No side effect */
