// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as DrawerHeader from "./detailviews/DrawerHeader.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function DrawerContainer__EventNotFound(Props) {
  var eventId = Props.eventId;
  var match = ModelStore.Mapped.use();
  var isArchived = Curry._2(TrackingPlanMappedModel.Events.has, match.archive.events, eventId);
  var isPerhaps = Curry._1(TrackingPlanMappedModel.Events.toArray, Curry._2(TrackingPlanMappedModel.Events.keep, match.events, (function (param) {
              return param.id.startsWith(eventId);
            })));
  var tmp;
  if (isArchived) {
    tmp = "This event has been archived.";
  } else {
    var len = isPerhaps.length;
    if (len !== 1) {
      tmp = len !== 0 ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  weight: "Semi",
                  children: "An event with this id wasn’t found"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), "Did you mean any of these?", Belt_Array.map(isPerhaps, (function (param) {
                    var id = param.id;
                    return React.createElement(Link.make, {
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "event",
                                      VAL: [
                                        id,
                                        undefined,
                                        undefined,
                                        false
                                      ]
                                    }),
                                className: Curry._1(Css.style, {
                                      hd: Css.color(Styles.Color.darkBlue),
                                      tl: {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: Styles.Duration.$$short
                                            }, undefined, undefined, "color"),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.color(Styles.Color.deepBlue),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }),
                                children: React.createElement($$Text.make, {
                                      children: param.name
                                    }),
                                key: id
                              });
                  }))) : "An event with this id wasn’t found.";
    } else {
      var $$event = isPerhaps[0];
      tmp = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                weight: "Semi",
                children: "An event with this id wasn’t found"
              }), React.createElement(Spacer.make, {
                height: 16
              }), "Did you mean ", React.createElement(Link.make, {
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "event",
                      VAL: [
                        $$event.id,
                        undefined,
                        undefined,
                        false
                      ]
                    }),
                className: Curry._1(Css.style, {
                      hd: Css.color(Styles.Color.darkBlue),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$short
                            }, undefined, undefined, "color"),
                        tl: {
                          hd: Css.hover({
                                hd: Css.color(Styles.Color.deepBlue),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }),
                children: $$event.name
              }), "?");
    }
  }
  return React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
                  kind: "Event",
                  children: isArchived ? "Archived event" : "Event not found"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.pct(100.0)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      textAlign: "center",
                      children: tmp
                    })));
}

var make = DrawerContainer__EventNotFound;

export {
  make ,
}
/* Css Not a pure module */
