// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Input from "./Input.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

var root = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(10)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.padding(Css.px(24)),
          tl: {
            hd: Css.width(Css.px(580)),
            tl: {
              hd: Css.maxHeight({
                    NAME: "subtract",
                    VAL: [
                      Css.vh(100.0),
                      Css.px(32)
                    ]
                  }),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("column"),
                  tl: {
                    hd: Css.alignItems("flexStart"),
                    tl: {
                      hd: Css.boxShadows({
                            hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(8), Css.px(18), Css.px(-6), undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.12)),
                            tl: {
                              hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(12), Css.px(42), Css.px(-4), undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.12)),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var labelRow = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.width(Css.pct(100.0)),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.paddingLeft(Css.px(11)),
            tl: {
              hd: Css.marginTop(Css.px(16)),
              tl: {
                hd: Css.marginBottom(Css.px(2)),
                tl: {
                  hd: Css.paddingRight(Css.px(11)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var buttons = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignSelf("stretch"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.justifyContent("flexEnd"),
            tl: {
              hd: Css.gap(Css.px(16)),
              tl: {
                hd: Css.marginTop(Css.px(16)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function AddRegexValidationModal(Props) {
  var onClose = Props.onClose;
  var regexTarget = Props.regexTarget;
  var sendActions = SendActionsContext.use();
  var properties = ModelStore.Mapped.useProperties();
  var schemaBundle = SchemaBundleContext.use();
  var propertyId = regexTarget._0;
  var testValueInputRef = React.useRef(null);
  var property = Belt_Option.getExn(Curry._2(TrackingPlanMappedModel.Properties.get, properties, propertyId));
  var match = PropertyUtils.getRegexValidation(property);
  var maybeExisting;
  switch (regexTarget.TAG) {
    case "Property" :
        if (match !== undefined) {
          var propertyRule = match.propertyRule;
          maybeExisting = propertyRule !== undefined ? propertyRule : undefined;
        } else {
          maybeExisting = undefined;
        }
        break;
    case "Event" :
        maybeExisting = match !== undefined ? Belt_MapString.get(match.eventOverrides, regexTarget._1.id) : undefined;
        break;
    case "EventVariant" :
        var maybePropertyOverride = Belt_MapString.get(regexTarget._2.propertyOverrides, propertyId);
        maybeExisting = Belt_Option.flatMap(maybePropertyOverride, (function (override) {
                if (typeof override !== "object") {
                  return ;
                }
                var regexOverride = override._0.regexValidation;
                if (typeof regexOverride !== "object") {
                  return ;
                } else {
                  return regexOverride._0;
                }
              }));
        break;
    
  }
  var match$1 = React.useState(function (param) {
        return Belt_Option.mapWithDefault(maybeExisting, "", (function (rule) {
                      return rule.regex;
                    }));
      });
  var setRegex = match$1[1];
  var regex = match$1[0];
  var match$2 = React.useState(function (param) {
        return Belt_Option.mapWithDefault(maybeExisting, "", (function (rule) {
                      return rule.testValue;
                    }));
      });
  var setSample = match$2[1];
  var sample = match$2[0];
  var handleSave = function (param) {
    var maybeEventId;
    switch (regexTarget.TAG) {
      case "Property" :
          maybeEventId = undefined;
          break;
      case "Event" :
          maybeEventId = regexTarget._1.id;
          break;
      case "EventVariant" :
          maybeEventId = regexTarget._2.baseEventId;
          break;
      
    }
    var exit = 0;
    switch (regexTarget.TAG) {
      case "Property" :
      case "Event" :
          exit = 1;
          break;
      case "EventVariant" :
          var variant = regexTarget._2;
          var $$event = regexTarget._1;
          var propertyId = regexTarget._0;
          Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                    AnalyticsRe.eventVariantUpdatedProperty(variant.baseEventId, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "UpdatePropertyRegex", variant.id, property.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), variant.baseEventId);
                  }),
                undefined,
                [[
                    {
                      NAME: "UpdatePropertyRegexOverrideOnEventVariant",
                      VAL: [
                        TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                        propertyId,
                        {
                          regex: regex,
                          testValue: sample
                        }
                      ]
                    },
                    {
                      propertyId: propertyId,
                      propertyQuery: propertyId,
                      eventVariantId: variant.id,
                      eventVariantQuery: variant.id
                    }
                  ]]
              ]);
          break;
      
    }
    if (exit === 1) {
      var propertyId$1 = regexTarget._0;
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            (function (branch) {
                if (maybeEventId !== undefined) {
                  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                  return AnalyticsRe.regexInteractionEventSpecific(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, maybeExisting === undefined ? "Created" : "Updated", propertyId$1, maybeEventId, schemaBundle$1.schemaId, NamedBranch.getId(branch));
                }
                var schemaBundle$2 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                AnalyticsRe.regexInteraction(schemaBundle$2.schemaId, schemaBundle$2.schemaName, schemaBundle$2.schemaBillingStatus, schemaBundle$2.branchId, schemaBundle$2.branchName, schemaBundle$2.schemaSubscriptionPlan, schemaBundle$2.schemaTrialPlan, schemaBundle$2.authenticationConfig, schemaBundle$2.schemaSubscriptionPlanSlug, schemaBundle$2.schemaTrialPlanSlug, schemaBundle$2.schemaType, maybeExisting === undefined ? "Created" : "Updated", propertyId$1, schemaBundle$2.schemaId, NamedBranch.getId(branch));
              }),
            undefined,
            [[
                {
                  NAME: "UpdatePropertyRegexValidation",
                  VAL: [
                    propertyId$1,
                    maybeEventId,
                    {
                      regex: regex,
                      testValue: sample
                    }
                  ]
                },
                {
                  propertyId: propertyId$1,
                  propertyQuery: propertyId$1
                }
              ]]
          ]);
    }
    Curry._1(onClose, undefined);
  };
  var isValid;
  try {
    new RegExp(regex);
    isValid = true;
  }
  catch (exn){
    isValid = false;
  }
  var sampleIsValid;
  try {
    sampleIsValid = new RegExp(regex).test(sample);
  }
  catch (exn$1){
    sampleIsValid = false;
  }
  var tmp;
  switch (regexTarget.TAG) {
    case "Property" :
        tmp = "Define a custom Regular Expression (Regex) for the property. The validation applies to the property wherever it is added, ensuring consistent validation or pattern matching across all instances where the property is used.";
        break;
    case "Event" :
        tmp = "Define a custom Regular Expression (Regex) for the property when it is sent with this event. This action will not impact any other events to which the property is attached.";
        break;
    case "EventVariant" :
        tmp = "Define a custom Regular Expression (Regex) for the property when it is sent with this event variant. This action will not impact any other events to which the property is attached.";
        break;
    
  }
  var tmp$1;
  switch (regexTarget.TAG) {
    case "Property" :
        tmp$1 = React.createElement($$Text.make, {
              size: "Medium",
              color: Styles.Color.light12,
              children: null
            }, "The property ", React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: property.name
                }), " should match:");
        break;
    case "Event" :
        tmp$1 = React.createElement($$Text.make, {
              size: "Medium",
              color: Styles.Color.light12,
              children: null
            }, "For ", React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: regexTarget._1.name
                }), " event the property ", React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: property.name
                }), " should match:");
        break;
    case "EventVariant" :
        tmp$1 = React.createElement($$Text.make, {
              size: "Medium",
              color: Styles.Color.light12,
              children: null
            }, "For ", React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: regexTarget._1.name
                }), " - ", React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: regexTarget._2.nameSuffix
                }), " event variant the property ", React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: property.name
                }), " should match:");
        break;
    
  }
  var tmp$2 = regex === "" ? null : (
      isValid ? React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.darkGreen,
              children: "Valid regex"
            }) : React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.darkRed,
              children: "Invalid regex"
            })
    );
  var tmp$3;
  tmp$3 = isValid && sample !== "" ? (
      sampleIsValid ? React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.darkGreen,
              children: "Matches regex pattern"
            }) : React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.darkRed,
              children: "Does not match regex pattern"
            })
    ) : null;
  return React.createElement("section", {
              className: root
            }, React.createElement(Title.make, {
                  children: "Add Regex validation",
                  size: "Small"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.light11,
                  children: tmp
                }), React.createElement(Spacer.make, {
                  height: 16
                }), tmp$1, React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: labelRow
                }, React.createElement($$Text.make, {
                      element: "Label",
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      htmlFor: "regex",
                      children: "Regular Expression"
                    }), tmp$2), React.createElement(Input.make, {
                  value: regex,
                  onChange: (function (value) {
                      Curry._1(setRegex, (function (param) {
                              return value;
                            }));
                    }),
                  id: "regex",
                  autoFocus: true,
                  onEnter: (function ($$event) {
                      var value = $$event.target.value;
                      if (value === "") {
                        return ;
                      }
                      var dom = testValueInputRef.current;
                      if (!(dom == null)) {
                        dom.focus();
                        return ;
                      }
                      
                    }),
                  placeholder: "Type or paste in your regex..."
                }), React.createElement("div", {
                  className: labelRow
                }, React.createElement($$Text.make, {
                      element: "Label",
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      htmlFor: "regex",
                      children: "Sample string"
                    }), tmp$3), React.createElement(Input.make, {
                  value: sample,
                  onChange: (function (value) {
                      Curry._1(setSample, (function (param) {
                              return value;
                            }));
                    }),
                  id: "testValue",
                  onEnter: (function (param) {
                      if (regex !== "") {
                        return handleSave();
                      }
                      
                    }),
                  placeholder: "Type in a sample value to test regex against...",
                  forwardRef: testValueInputRef
                }), React.createElement("div", {
                  className: buttons
                }, React.createElement(Button.make, {
                      label: "Cancel",
                      onClick: (function (param) {
                          Curry._1(onClose, undefined);
                        }),
                      size: "large",
                      style: "ghost"
                    }), React.createElement(Button.make, {
                      disabled: regex === "" || !isValid,
                      label: maybeExisting === undefined ? "Add Regex" : "Edit Regex",
                      onClick: handleSave,
                      size: "large"
                    })));
}

var make = AddRegexValidationModal;

export {
  make ,
}
/* root Not a pure module */
