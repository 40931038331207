// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as IconX from "./IconX.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Nanoid from "nanoid";
import * as AvoModel from "./avoModel.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as PropertyInput from "./PropertyInput.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as MetricItemCondition from "./MetricItemCondition.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function MetricItem(Props) {
  var metric = Props.metric;
  var metricItem = Props.metricItem;
  var $$event = Props.event;
  var index = Props.index;
  var sendActions = Props.sendActions;
  var goalId = Props.goalId;
  var model = Props.model;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var draggableOpt = Props.draggable;
  var draggable = draggableOpt !== undefined ? draggableOpt : true;
  var schemaBundle = SchemaBundleContext.use();
  var match = React.useState(function (param) {
        return "Initial";
      });
  var setStatus = match[1];
  var status = match[0];
  var id = metricItem.VAL.id;
  var match$1 = Router.getStaticRoute().branch;
  var demoEventId = match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "branch" && match$1.VAL.startsWith("demo-") && $$event.name === "Onboarding Started (Demo)" ? "new-workspace-demo-metric-event" : undefined;
  var match$2 = metric.type_;
  var tmp = {
    path: Router.Link.addDrawerItem(undefined, {
          NAME: "event",
          VAL: [
            $$event.id,
            undefined,
            undefined,
            false
          ]
        }),
    className: Curry._1(Css.style, {
          hd: Css.fontSize(Styles.FontSize.small),
          tl: {
            hd: Css.fontWeight(Styles.FontWeight.semi),
            tl: /* [] */0
          }
        }),
    children: $$event.name
  };
  if (demoEventId !== undefined) {
    tmp.id = Caml_option.valFromOption(demoEventId);
  }
  var match$3 = metric.type_;
  var metricEvent = metricItem.VAL;
  var options = AvoUtils.getPropertyOptions(model, $$event);
  var allPropertiesAvailableOnEventForMetricView = AvoModel.getAllPropertiesAvailableOnEventForMetricView(model, $$event);
  var match$4 = metric.type_;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(2), Css.px(0)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("strong", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.width(Css.px(24)),
                                tl: {
                                  hd: Css.height(Css.px(24)),
                                  tl: {
                                    hd: Css.borderRadius(Css.pct(100)),
                                    tl: {
                                      hd: Css.marginRight(Css.px(10)),
                                      tl: {
                                        hd: Css.marginTop(Css.px(8)),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.small),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.bold),
                                            tl: {
                                              hd: Css.color(Styles.Color.white),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.avoPink),
                                                tl: {
                                                  hd: Css.cursor(draggable ? "grab" : "default"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, match$2 === "Funnel" ? String(index + 1 | 0) : String.fromCharCode(index + 65 | 0)), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding4(Css.px(5), Css.px(5), Css.px(5), Css.px(10)),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.light02),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("spaceBetween"),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement(Link.make, tmp), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              }),
                          onClick: (function ($$event) {
                              $$event.stopPropagation();
                              $$event.preventDefault();
                            })
                        }, React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.small),
                                              tl: {
                                                hd: Css.color(Styles.Color.darkBlue),
                                                tl: {
                                                  hd: Css.marginRight(Css.px(4)),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.deepBlue),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  Curry._1(setStatus, (function (param) {
                                          return "AddingWhere";
                                        }));
                                })
                            }, "+ where"), match$3 === "EventSegmentation" ? React.createElement("button", {
                                className: Curry._1(Css.merge, {
                                      hd: Curry._1(Css.style, Styles.button),
                                      tl: {
                                        hd: Curry._1(Css.style, {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.small),
                                                tl: {
                                                  hd: Css.color(Styles.Color.darkBlue),
                                                  tl: {
                                                    hd: Css.marginRight(Css.px(4)),
                                                    tl: {
                                                      hd: Css.hover({
                                                            hd: Css.color(Styles.Color.deepBlue),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                onClick: (function (param) {
                                    Curry._1(setStatus, (function (param) {
                                            return "AddingGroupBy";
                                          }));
                                  })
                              }, "+ group by") : null, React.createElement(ContextMenu.make, {
                              options: [{
                                  NAME: "Option",
                                  VAL: {
                                    label: "Remove Event",
                                    onClick: (function (param) {
                                        var tmp = {
                                          eventId: $$event.id,
                                          eventQuery: $$event.id,
                                          metricId: metric.id,
                                          metricQuery: metric.id
                                        };
                                        if (goalId !== undefined) {
                                          tmp.goalId = Caml_option.valFromOption(goalId);
                                        }
                                        Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                  AnalyticsRe.metricUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, metric.id, metric.name, $$event.id, $$event.name, "EventRemoved", metric.type_, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                }),
                                              undefined,
                                              [[
                                                  {
                                                    NAME: "RemoveEventFromMetric",
                                                    VAL: [
                                                      metric.id,
                                                      id,
                                                      $$event.id
                                                    ]
                                                  },
                                                  tmp
                                                ]]
                                            ]);
                                      })
                                  }
                                }]
                            }))), React.createElement("div", undefined, React.createElement("div", undefined, React.createElement("div", undefined, Belt_List.toArray(Belt_List.mapWithIndex(metricEvent.where, (function (index, metricWhere) {
                                        return React.createElement(MetricItemCondition.make, {
                                                    currentFilters: currentFilters,
                                                    filters: filters,
                                                    event: $$event,
                                                    index: index,
                                                    metric: metric,
                                                    metricEvent: metricEvent,
                                                    metricWhere: metricWhere,
                                                    model: model,
                                                    onSelectProperty: (function (param) {
                                                        Curry._1(setStatus, (function (param) {
                                                                return "Initial";
                                                              }));
                                                      }),
                                                    options: options,
                                                    allPropertiesAvailableOnEventForMetricView: allPropertiesAvailableOnEventForMetricView,
                                                    key: metricWhere.id
                                                  });
                                      })))), status === "AddingWhere" ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.width(Css.pct(100)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement("span", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.fontSize(Styles.FontSize.small),
                                          tl: {
                                            hd: Css.color(Styles.Color.light10),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.paddingRight(Css.px(6)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        })
                                  }, "where"), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.width(Css.pct(100)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(PropertyInput.make, {
                                        options: options,
                                        onSelect: (function (item, param, sendActions) {
                                            if (item.NAME === "Bundle") {
                                              return Pervasives.failwith("Property bundles cannot be added to other property bundles.");
                                            }
                                            var property = item.VAL;
                                            Curry._1(setStatus, (function (param) {
                                                    return "Initial";
                                                  }));
                                            if (Belt_List.some(allPropertiesAvailableOnEventForMetricView, (function (prop) {
                                                      return prop.id === property.id;
                                                    }))) {
                                              return Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [[
                                                              {
                                                                NAME: "SetMetricWhereV2",
                                                                VAL: [
                                                                  metric.id,
                                                                  metricEvent.id,
                                                                  $$event.id,
                                                                  Nanoid.nanoid(),
                                                                  property.id,
                                                                  "EQ",
                                                                  /* [] */0
                                                                ]
                                                              },
                                                              {
                                                                eventId: $$event.id,
                                                                propertyId: property.id,
                                                                propertyQuery: property.id,
                                                                metricId: metric.id,
                                                                metricQuery: metric.id
                                                              }
                                                            ]]
                                                        ]);
                                            } else if (window.confirm("The property " + property.name + " is not attached to the " + $$event.name + " event. Would you like to add it?")) {
                                              return Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [
                                                            [
                                                              {
                                                                NAME: "AddPropertyRef",
                                                                VAL: [
                                                                  $$event.id,
                                                                  property.id
                                                                ]
                                                              },
                                                              {
                                                                eventId: $$event.id,
                                                                eventQuery: $$event.id,
                                                                propertyId: property.id,
                                                                propertyQuery: property.id,
                                                                metricId: metric.id
                                                              }
                                                            ],
                                                            [
                                                              {
                                                                NAME: "SetMetricWhereV2",
                                                                VAL: [
                                                                  metric.id,
                                                                  metricEvent.id,
                                                                  $$event.id,
                                                                  Nanoid.nanoid(),
                                                                  property.id,
                                                                  "EQ",
                                                                  /* [] */0
                                                                ]
                                                              },
                                                              {
                                                                eventId: $$event.id,
                                                                propertyId: property.id,
                                                                propertyQuery: property.id,
                                                                metricId: metric.id,
                                                                metricQuery: metric.id
                                                              }
                                                            ]
                                                          ]
                                                        ]);
                                            } else {
                                              return ;
                                            }
                                          }),
                                        canCreate: true,
                                        extraCreateActions: (function (propertyId) {
                                            return [[
                                                      {
                                                        NAME: "SetMetricWhereV2",
                                                        VAL: [
                                                          metric.id,
                                                          metricEvent.id,
                                                          $$event.id,
                                                          Nanoid.nanoid(),
                                                          propertyId,
                                                          "EQ",
                                                          /* [] */0
                                                        ]
                                                      },
                                                      {
                                                        eventId: $$event.id,
                                                        propertyId: propertyId,
                                                        propertyQuery: propertyId,
                                                        metricId: metric.id,
                                                        metricQuery: metric.id
                                                      }
                                                    ]];
                                          }),
                                        onCreateStart: (function (param) {
                                            Curry._1(setStatus, (function (param) {
                                                    return "Initial";
                                                  }));
                                          }),
                                        currentFilters: currentFilters,
                                        renderButton: (function (onClick, buttonRef, _isOpen) {
                                            return React.createElement("button", {
                                                        ref: buttonRef,
                                                        className: Curry._1(Css.merge, {
                                                              hd: Curry._1(Css.style, Styles.button),
                                                              tl: {
                                                                hd: Curry._1(Css.style, {
                                                                      hd: Css.padding2(Css.px(2), Css.px(6)),
                                                                      tl: {
                                                                        hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
                                                                        tl: {
                                                                          hd: Css.borderRadius(Styles.Border.radius),
                                                                          tl: {
                                                                            hd: Css.color(Styles.Color.darkBlue),
                                                                            tl: {
                                                                              hd: Css.backgroundColor(Styles.Color.lightBlue),
                                                                              tl: {
                                                                                hd: Css.fontSize(Styles.FontSize.small),
                                                                                tl: {
                                                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                                  tl: {
                                                                                    hd: Css.marginRight(Css.px(4)),
                                                                                    tl: {
                                                                                      hd: Css.fontStyle("italic"),
                                                                                      tl: /* [] */0
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        onClick: onClick
                                                      }, "Select property...");
                                          }),
                                        parent: {
                                          NAME: "Event",
                                          VAL: $$event.id
                                        },
                                        sendActions: sendActions,
                                        filters: filters,
                                        propertyLocation: "MetricDetails"
                                      }))) : null, React.createElement("div", undefined, Belt_List.toArray(Belt_List.map(metricEvent.groupBy, (function (param) {
                                        var propertyId = param.propertyId;
                                        var id = param.id;
                                        var maybeProperty = ModelUtils.getPropertyById(propertyId, model);
                                        var maybeArchivedProperty = ModelUtils.getArchivedPropertyById(propertyId, model);
                                        var maybeEnrichedProperty = Belt_List.getBy(allPropertiesAvailableOnEventForMetricView, (function (property) {
                                                return property.id === propertyId;
                                              }));
                                        var property;
                                        if (maybeProperty !== undefined) {
                                          if (maybeProperty.TAG === "PropertyRef") {
                                            return "not found";
                                          }
                                          property = maybeProperty._0;
                                        } else if (maybeEnrichedProperty !== undefined) {
                                          property = maybeEnrichedProperty;
                                        } else {
                                          if (maybeArchivedProperty === undefined) {
                                            return "not found";
                                          }
                                          if (maybeArchivedProperty.TAG === "PropertyRef") {
                                            return "not found";
                                          }
                                          var property$1 = maybeArchivedProperty._0;
                                          return React.createElement("div", {
                                                      key: id + "-archived-" + property$1.id,
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.display("flex"),
                                                            tl: {
                                                              hd: Css.alignItems("center"),
                                                              tl: /* [] */0
                                                            }
                                                          })
                                                    }, React.createElement($$Text.make, {
                                                          size: "Medium",
                                                          weight: "Semi",
                                                          color: Styles.Color.orange,
                                                          children: "Archived property: " + property$1.name
                                                        }), React.createElement(Spacer.make, {
                                                          width: 4
                                                        }), React.createElement("button", {
                                                          className: Curry._1(Css.merge, {
                                                                hd: Curry._1(Css.style, Styles.button),
                                                                tl: {
                                                                  hd: Curry._1(Css.style, {
                                                                        hd: Css.display("flex"),
                                                                        tl: {
                                                                          hd: Css.alignItems("center"),
                                                                          tl: {
                                                                            hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
                                                                            tl: {
                                                                              hd: Css.padding2(Css.px(5), Css.px(5)),
                                                                              tl: {
                                                                                hd: Css.marginLeft(Css.px(6)),
                                                                                tl: {
                                                                                  hd: Css.hover({
                                                                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.red),
                                                                                        tl: /* [] */0
                                                                                      }),
                                                                                  tl: /* [] */0
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }),
                                                                  tl: /* [] */0
                                                                }
                                                              }),
                                                          onClick: (function (param) {
                                                              Curry.app(sendActions, [
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    [[
                                                                        {
                                                                          NAME: "RemoveMetricGroupByV2",
                                                                          VAL: [
                                                                            metric.id,
                                                                            metricEvent.id,
                                                                            $$event.id,
                                                                            id
                                                                          ]
                                                                        },
                                                                        {
                                                                          eventId: $$event.id,
                                                                          propertyId: property$1.id,
                                                                          propertyQuery: property$1.id,
                                                                          metricId: metric.id,
                                                                          metricQuery: metric.id
                                                                        }
                                                                      ]]
                                                                  ]);
                                                            })
                                                        }, React.createElement(IconX.make, {
                                                              size: 12
                                                            })));
                                        }
                                        var context = {
                                          eventId: $$event.id,
                                          propertyId: property.id,
                                          propertyQuery: property.id,
                                          metricId: metric.id,
                                          metricQuery: metric.id
                                        };
                                        return React.createElement("div", {
                                                    key: id + "-" + property.id,
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.display("flex"),
                                                          tl: {
                                                            hd: Css.alignItems("center"),
                                                            tl: {
                                                              hd: Css.width(Css.pct(100)),
                                                              tl: {
                                                                hd: Css.margin2(Css.px(5), Css.px(0)),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        })
                                                  }, React.createElement("span", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.fontSize(Styles.FontSize.small),
                                                              tl: {
                                                                hd: Css.color(Styles.Color.light10),
                                                                tl: {
                                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            })
                                                      }, "grouped by"), React.createElement(PropertyInput.make, {
                                                        options: options,
                                                        onSelect: (function (item, param, sendActions) {
                                                            if (item.NAME === "Bundle") {
                                                              return Pervasives.failwith("Property bundles cannot be added to other property bundles.");
                                                            }
                                                            var property = item.VAL;
                                                            Curry._1(setStatus, (function (param) {
                                                                    return "Initial";
                                                                  }));
                                                            if (Belt_List.some(allPropertiesAvailableOnEventForMetricView, (function (prop) {
                                                                      return prop.id === property.id;
                                                                    }))) {
                                                              return Curry.app(sendActions, [
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          [[
                                                                              {
                                                                                NAME: "SetMetricGroupByV2",
                                                                                VAL: [
                                                                                  metric.id,
                                                                                  metricEvent.id,
                                                                                  $$event.id,
                                                                                  id,
                                                                                  property.id
                                                                                ]
                                                                              },
                                                                              context
                                                                            ]]
                                                                        ]);
                                                            } else if (window.confirm("The property " + property.name + " is not attached to the " + $$event.name + " event. Would you like to add it?")) {
                                                              return Curry.app(sendActions, [
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          [
                                                                            [
                                                                              {
                                                                                NAME: "AddPropertyRef",
                                                                                VAL: [
                                                                                  $$event.id,
                                                                                  property.id
                                                                                ]
                                                                              },
                                                                              {
                                                                                eventId: $$event.id,
                                                                                eventQuery: $$event.id,
                                                                                propertyId: property.id,
                                                                                propertyQuery: property.id,
                                                                                metricId: metric.id
                                                                              }
                                                                            ],
                                                                            [
                                                                              {
                                                                                NAME: "SetMetricGroupByV2",
                                                                                VAL: [
                                                                                  metric.id,
                                                                                  metricEvent.id,
                                                                                  $$event.id,
                                                                                  id,
                                                                                  property.id
                                                                                ]
                                                                              },
                                                                              context
                                                                            ]
                                                                          ]
                                                                        ]);
                                                            } else {
                                                              return ;
                                                            }
                                                          }),
                                                        canCreate: true,
                                                        extraCreateActions: (function (propertyId) {
                                                            return [[
                                                                      {
                                                                        NAME: "SetMetricGroupByV2",
                                                                        VAL: [
                                                                          metric.id,
                                                                          metricEvent.id,
                                                                          $$event.id,
                                                                          Nanoid.nanoid(),
                                                                          propertyId
                                                                        ]
                                                                      },
                                                                      context
                                                                    ]];
                                                          }),
                                                        currentFilters: currentFilters,
                                                        getIndexOfValue: (function (options) {
                                                            return Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(Belt_Array.mapWithIndex(options, (function (index, option) {
                                                                                      return [
                                                                                              index,
                                                                                              option
                                                                                            ];
                                                                                    })), (function (x) {
                                                                                  var match = x[1];
                                                                                  if (typeof match !== "object") {
                                                                                    return ;
                                                                                  }
                                                                                  if (match.NAME !== "Item") {
                                                                                    return ;
                                                                                  }
                                                                                  var match$1 = match.VAL;
                                                                                  if (typeof match$1 === "object" && match$1.NAME === "Property" && match$1.VAL.id === property.id) {
                                                                                    return x[0];
                                                                                  }
                                                                                  
                                                                                })), 0), 0);
                                                          }),
                                                        renderButton: (function (onClick, buttonRef, _isOpen) {
                                                            return React.createElement("button", {
                                                                        ref: buttonRef,
                                                                        className: Curry._1(Css.merge, {
                                                                              hd: Curry._1(Css.style, Styles.button),
                                                                              tl: {
                                                                                hd: Curry._1(Css.style, {
                                                                                      hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
                                                                                      tl: {
                                                                                        hd: Css.borderRadius(Styles.Border.radius),
                                                                                        tl: {
                                                                                          hd: Css.color(Styles.Color.darkBlue),
                                                                                          tl: {
                                                                                            hd: Css.backgroundColor(Styles.Color.lightBlue),
                                                                                            tl: {
                                                                                              hd: Css.fontSize(Styles.FontSize.small),
                                                                                              tl: {
                                                                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                                                tl: {
                                                                                                  hd: Css.marginLeft(Css.px(6)),
                                                                                                  tl: /* [] */0
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }),
                                                                                tl: /* [] */0
                                                                              }
                                                                            }),
                                                                        onClick: onClick
                                                                      }, property.name);
                                                          }),
                                                        parent: {
                                                          NAME: "Event",
                                                          VAL: $$event.id
                                                        },
                                                        sendActions: sendActions,
                                                        filters: filters,
                                                        propertyLocation: "MetricDetails"
                                                      }), React.createElement("button", {
                                                        className: Curry._1(Css.merge, {
                                                              hd: Curry._1(Css.style, Styles.button),
                                                              tl: {
                                                                hd: Curry._1(Css.style, {
                                                                      hd: Css.display("flex"),
                                                                      tl: {
                                                                        hd: Css.alignItems("center"),
                                                                        tl: {
                                                                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
                                                                          tl: {
                                                                            hd: Css.padding2(Css.px(5), Css.px(5)),
                                                                            tl: {
                                                                              hd: Css.marginLeft(Css.px(6)),
                                                                              tl: {
                                                                                hd: Css.hover({
                                                                                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.red),
                                                                                      tl: /* [] */0
                                                                                    }),
                                                                                tl: /* [] */0
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        onClick: (function (param) {
                                                            Curry.app(sendActions, [
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  [[
                                                                      {
                                                                        NAME: "RemoveMetricGroupByV2",
                                                                        VAL: [
                                                                          metric.id,
                                                                          metricEvent.id,
                                                                          $$event.id,
                                                                          id
                                                                        ]
                                                                      },
                                                                      context
                                                                    ]]
                                                                ]);
                                                          })
                                                      }, React.createElement(IconX.make, {
                                                            size: 12
                                                          })));
                                      })))), match$4 === "EventSegmentation" && status === "AddingGroupBy" ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.width(Css.pct(100)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement("span", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.fontSize(Styles.FontSize.small),
                                          tl: {
                                            hd: Css.color(Styles.Color.light10),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.paddingRight(Css.px(6)),
                                                tl: {
                                                  hd: Css.flexShrink(0.0),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, "grouped by"), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.width(Css.pct(100)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(PropertyInput.make, {
                                        options: options,
                                        onSelect: (function (item, param, sendActions) {
                                            if (item.NAME === "Bundle") {
                                              return Pervasives.failwith("Property bundles cannot be added to other property bundles.");
                                            }
                                            var property = item.VAL;
                                            Curry._1(setStatus, (function (param) {
                                                    return "Initial";
                                                  }));
                                            if (Belt_List.some(allPropertiesAvailableOnEventForMetricView, (function (prop) {
                                                      return prop.id === property.id;
                                                    }))) {
                                              return Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [[
                                                              {
                                                                NAME: "SetMetricGroupByV2",
                                                                VAL: [
                                                                  metric.id,
                                                                  metricEvent.id,
                                                                  $$event.id,
                                                                  Nanoid.nanoid(),
                                                                  property.id
                                                                ]
                                                              },
                                                              {
                                                                eventId: $$event.id,
                                                                propertyId: property.id,
                                                                propertyQuery: property.id,
                                                                metricId: metric.id,
                                                                metricQuery: metric.id
                                                              }
                                                            ]]
                                                        ]);
                                            } else if (window.confirm("The property " + property.name + " is not attached to the " + $$event.name + " event. Would you like to add it?")) {
                                              return Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [
                                                            [
                                                              {
                                                                NAME: "AddPropertyRef",
                                                                VAL: [
                                                                  $$event.id,
                                                                  property.id
                                                                ]
                                                              },
                                                              {
                                                                eventId: $$event.id,
                                                                eventQuery: $$event.id,
                                                                propertyId: property.id,
                                                                propertyQuery: property.id,
                                                                metricId: metric.id
                                                              }
                                                            ],
                                                            [
                                                              {
                                                                NAME: "SetMetricGroupByV2",
                                                                VAL: [
                                                                  metric.id,
                                                                  metricEvent.id,
                                                                  $$event.id,
                                                                  Nanoid.nanoid(),
                                                                  property.id
                                                                ]
                                                              },
                                                              {
                                                                eventId: $$event.id,
                                                                propertyId: property.id,
                                                                propertyQuery: property.id,
                                                                metricId: metric.id,
                                                                metricQuery: metric.id
                                                              }
                                                            ]
                                                          ]
                                                        ]);
                                            } else {
                                              return ;
                                            }
                                          }),
                                        canCreate: true,
                                        extraCreateActions: (function (propertyId) {
                                            return [[
                                                      {
                                                        NAME: "SetMetricGroupByV2",
                                                        VAL: [
                                                          metric.id,
                                                          metricEvent.id,
                                                          $$event.id,
                                                          Nanoid.nanoid(),
                                                          propertyId
                                                        ]
                                                      },
                                                      {
                                                        eventId: $$event.id,
                                                        propertyId: propertyId,
                                                        propertyQuery: propertyId,
                                                        metricId: metric.id,
                                                        metricQuery: metric.id
                                                      }
                                                    ]];
                                          }),
                                        onCreateStart: (function (param) {
                                            Curry._1(setStatus, (function (param) {
                                                    return "Initial";
                                                  }));
                                          }),
                                        currentFilters: currentFilters,
                                        renderButton: (function (onClick, buttonRef, _isOpen) {
                                            return React.createElement("button", {
                                                        ref: buttonRef,
                                                        className: Curry._1(Css.merge, {
                                                              hd: Curry._1(Css.style, Styles.button),
                                                              tl: {
                                                                hd: Curry._1(Css.style, {
                                                                      hd: Css.padding2(Css.px(2), Css.px(6)),
                                                                      tl: {
                                                                        hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
                                                                        tl: {
                                                                          hd: Css.borderRadius(Styles.Border.radius),
                                                                          tl: {
                                                                            hd: Css.color(Styles.Color.darkBlue),
                                                                            tl: {
                                                                              hd: Css.backgroundColor(Styles.Color.lightBlue),
                                                                              tl: {
                                                                                hd: Css.fontSize(Styles.FontSize.small),
                                                                                tl: {
                                                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                                  tl: {
                                                                                    hd: Css.marginRight(Css.px(4)),
                                                                                    tl: {
                                                                                      hd: Css.fontStyle("italic"),
                                                                                      tl: /* [] */0
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        onClick: onClick
                                                      }, "Select property...");
                                          }),
                                        parent: {
                                          NAME: "Event",
                                          VAL: $$event.id
                                        },
                                        sendActions: sendActions,
                                        filters: filters,
                                        propertyLocation: "MetricDetails"
                                      }))) : null))));
}

var make = MetricItem;

export {
  make ,
}
/* Css Not a pure module */
