// Generated by ReScript, PLEASE EDIT WITH CARE


var _map = {"OldestFirst":"Oldest First","NewestFirst":"Newest First","Alphabetical":"Alphabetical","NoSorting":"No Sorting"};

var _revMap = {"Oldest First":"OldestFirst","Newest First":"NewestFirst","Alphabetical":"Alphabetical","No Sorting":"NoSorting"};

function eventSortToJs(param) {
  return _map[param];
}

function eventSortFromJs(param) {
  return _revMap[param];
}

var _map$1 = {"NoSorting":"Chronological","Alphabetical":"Alphabetical"};

var _revMap$1 = {"Chronological":"NoSorting","Alphabetical":"Alphabetical"};

function metricsSortToJs(param) {
  return _map$1[param];
}

function metricsSortFromJs(param) {
  return _revMap$1[param];
}

var metricsSortOptions = [
  "NoSorting",
  "Alphabetical"
];

function filterToString(filter) {
  var variant = filter.NAME;
  if (variant === "Domain") {
    return "domain:" + filter.VAL;
  } else if (variant === "Source") {
    return "source:" + filter.VAL;
  } else if (variant === "Destination") {
    return "destination:" + filter.VAL;
  } else if (variant === "Tag") {
    return "tag:" + filter.VAL;
  } else if (variant === "Property") {
    return "property:" + filter.VAL;
  } else if (variant === "Category") {
    return "category:" + filter.VAL;
  } else if (variant === "PropertyGroup") {
    return "property_group:" + filter.VAL;
  } else {
    return "action:" + filter.VAL;
  }
}

export {
  eventSortToJs ,
  eventSortFromJs ,
  metricsSortToJs ,
  metricsSortFromJs ,
  metricsSortOptions ,
  filterToString ,
}
/* No side effect */
