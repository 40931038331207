// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as ArrayExt from "./ArrayExt.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as React$1 from "motion/react";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BillingPromptModal from "./BillingPromptModal.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as Belt_MutableSetString from "rescript/lib/es6/belt_MutableSetString.js";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as CopyChangesToBranchModal from "./CopyChangesToBranchModal.mjs";
import * as CopyChangesToBranchModal__Types from "./CopyChangesToBranchModal__Types.mjs";
import * as CopyChangesToBranchModal__Utils from "./CopyChangesToBranchModal__Utils.mjs";
import * as GlobalSendOnNextBranchScheduler from "./branchImplementation/GlobalSendOnNextBranchScheduler.mjs";
import * as CopyChangesToBranchModal__Tooltip from "./CopyChangesToBranchModal__Tooltip.mjs";
import * as CopyChangesToBranchModal__BranchIcon from "./CopyChangesToBranchModal__BranchIcon.mjs";
import * as CopyChangesToBranchModal__ChangesToCopy from "./CopyChangesToBranchModal__ChangesToCopy.mjs";

var modalStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.gap(Css.px(8)),
            tl: {
              hd: Css.justifyContent("stretch"),
              tl: {
                hd: Css.boxShadow(Styles.Shadow.standard),
                tl: {
                  hd: Css.borderRadius(Css.px(10)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.width(Css.px(600)),
                        tl: {
                          hd: Css.padding(Css.px(24)),
                          tl: {
                            hd: Css.wordWrap("breakWord"),
                            tl: {
                              hd: Css.maxHeight(Css.vh(85)),
                              tl: {
                                hd: Css.overflowY("scroll"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var titleStyles = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top(Css.px(-24)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.3)),
          tl: {
            hd: Css.backdropFilter({
                  hd: {
                    NAME: "blur",
                    VAL: Css.px(3)
                  },
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.padding(Css.px(24)),
              tl: {
                hd: Css.paddingBottom(Css.px(16)),
                tl: {
                  hd: Css.margin(Css.px(-24)),
                  tl: {
                    hd: Css.marginBottom(Css.px(0)),
                    tl: {
                      hd: Css.marginTop(Css.px(-48)),
                      tl: {
                        hd: Css.zIndex(1),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var buttonsPalette = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("flexEnd"),
        tl: {
          hd: Css.gap(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

function CopyChangesToBranchModal__Pull$BranchWrapper(Props) {
  var allSame = Props.allSame;
  var branchId = Props.branchId;
  var canRender = Props.canRender;
  var onSelectBranchId = Props.onSelectBranchId;
  var checked = Props.checked;
  var mainBranchName = Props.mainBranchName;
  var otherBranches = Props.otherBranches;
  var children = Props.children;
  var openBranches = ModelStore.Mapped.useOpenBranches();
  return React.createElement("div", {
              className: Curry._1(Css.merge, {
                    hd: canRender ? Curry._1(Css.style, /* [] */0) : Curry._1(Css.style, {
                            hd: Css.overflow("hidden"),
                            tl: {
                              hd: Css.margin(Css.px(0)),
                              tl: {
                                hd: Css.padding(Css.px(0)),
                                tl: {
                                  hd: Css.height(Css.px(0)),
                                  tl: {
                                    hd: Css.opacity(0.0),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }),
                    tl: {
                      hd: Curry._1(Css.style, {
                            hd: Css.paddingBottom(Css.px(8)),
                            tl: {
                              hd: Css.borderBottom(Css.px(1), Css.solid, Styles.Color.light04),
                              tl: {
                                hd: Css.selector(":last-child", {
                                      hd: Css.borderBottom(Css.px(0), Css.solid, Styles.Color.light04),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.gap(Css.px(8)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(16)),
                              tl: {
                                hd: Css.marginLeft(Css.px(-6)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }),
                  onClick: onSelectBranchId
                }, allSame ? React.createElement(Spacer.make, {
                        width: 0
                      }) : React.createElement("input", {
                        className: Curry._1(Css.style, {
                              hd: Css.marginBottom(Css.px(-2)),
                              tl: {
                                hd: Css.width(Css.px(20)),
                                tl: {
                                  hd: Css.height(Css.px(20)),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        checked: checked,
                        readOnly: true,
                        type: "radio"
                      }), React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.flexWrap("wrap"),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          size: "Medium",
                          color: Styles.Color.light11,
                          children: null
                        }, "Found on ", React.createElement(CopyChangesToBranchModal__BranchIcon.make, {
                              branchId: branchId,
                              size: 22
                            }), React.createElement($$Text.make, {
                              element: "Span",
                              weight: "Semi",
                              singleLine: true,
                              children: mainBranchName
                            }), otherBranches.length !== 0 ? React.createElement(React.Fragment, undefined, " and ", React.createElement($$Text.make, {
                                    element: "Span",
                                    weight: "Semi",
                                    children: React.createElement(SimpleTooltip.WithComponent.make, {
                                          containerStyles: Curry._1(Css.style, {
                                                hd: Css.display("inline"),
                                                tl: /* [] */0
                                              }),
                                          disabled: otherBranches.length === 0,
                                          tooltip: (function (param) {
                                              return React.createElement($$Text.make, {
                                                          size: "Small",
                                                          children: null
                                                        }, "Other branches", React.createElement("br", undefined), Belt_Array.keepMap(otherBranches, (function (branchId) {
                                                                return Belt_Option.map(Curry._2(TrackingPlanMappedModel.OpenBranches.get, openBranches, branchId), (function (param) {
                                                                              var branchName = param[1];
                                                                              return React.createElement("div", {
                                                                                          key: branchName,
                                                                                          className: Curry._1(Css.style, {
                                                                                                hd: Css.display("flex"),
                                                                                                tl: {
                                                                                                  hd: Css.alignItems("center"),
                                                                                                  tl: /* [] */0
                                                                                                }
                                                                                              })
                                                                                        }, React.createElement(CopyChangesToBranchModal__BranchIcon.make, {
                                                                                              branchId: branchId,
                                                                                              size: 14
                                                                                            }), React.createElement($$Text.make, {
                                                                                              weight: "Semi",
                                                                                              children: branchName
                                                                                            }));
                                                                            }));
                                                              })));
                                            }),
                                          children: AvoUtils.plural("other branches", true, otherBranches.length, "other branch")
                                        })
                                  })) : null))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.filter({
                              hd: {
                                NAME: "grayscale",
                                VAL: checked ? 0.0 : 50.0
                              },
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.opacity(checked ? 1.0 : 0.7),
                          tl: /* [] */0
                        }
                      })
                }, children));
}

function CopyChangesToBranchModal__Pull$BranchStatusFetcher(Props) {
  var branchId = Props.branchId;
  var onBranchStatusSuccess = Props.onBranchStatusSuccess;
  var match = SchemaBundleContext.use();
  var branchStatus = FirebaseFetcherHooks.useBranchState("Fetch", match.schemaId, branchId);
  React.useEffect((function (param) {
          if (branchStatus !== undefined) {
            Curry._1(onBranchStatusSuccess, branchStatus.branchStatus);
          }
          
        }), [branchStatus]);
  return null;
}

function cleanActions(actions) {
  return Belt_Array.map(Belt_List.toArray(AvoUtils.actionByObject(Belt_List.fromArray(Belt_Array.map(actions, (function (prim) {
                                return prim[0];
                              }))))), (function (prim) {
                return prim[1];
              }));
}

function printToSet(actions) {
  return Belt_SetString.fromArray(Belt_Array.map(actions, (function (action) {
                    return JSON.stringify(Printer.printAction(action));
                  })));
}

function consolidateBranchActions(namespaceIndexItems, branchActionsMap, branchStatusesMap) {
  var branchStatusCmp = function (branchStatusesMap, branchIdA, branchIdB) {
    var match = Belt_MapString.get(branchStatusesMap, branchIdA);
    var match$1 = Belt_MapString.get(branchStatusesMap, branchIdB);
    if (match === undefined) {
      if (match$1 !== undefined) {
        return 1;
      } else {
        return 0;
      }
    }
    if (match$1 === undefined) {
      return -1;
    }
    var branchStatusB = Caml_option.valFromOption(match$1);
    var branchStatusA = Caml_option.valFromOption(match);
    var exit = 0;
    var exit$1 = 0;
    var exit$2 = 0;
    var exit$3 = 0;
    if (branchStatusA !== undefined) {
      if (branchStatusA === "Approved") {
        return -1;
      }
      exit$3 = 5;
    } else {
      exit$3 = 5;
    }
    if (exit$3 === 5) {
      if (branchStatusB !== undefined) {
        if (branchStatusB === "Approved") {
          return 1;
        }
        exit$2 = 4;
      } else {
        exit$2 = 4;
      }
    }
    if (exit$2 === 4) {
      if (branchStatusA !== undefined) {
        if (branchStatusA === "ChangesRequested") {
          return -1;
        }
        exit$1 = 3;
      } else {
        exit$1 = 3;
      }
    }
    if (exit$1 === 3) {
      if (branchStatusB !== undefined) {
        if (branchStatusB === "ChangesRequested") {
          return 1;
        }
        exit = 2;
      } else {
        exit = 2;
      }
    }
    if (exit === 2 && branchStatusA === "ReadyForReview") {
      return -1;
    }
    if (branchStatusB === "ReadyForReview") {
      return 1;
    } else {
      return 0;
    }
  };
  var branchesWithSameActions = Belt_Array.map(ArrayExt.group((function (param, param$1) {
              var branchActionsASet = printToSet(cleanActions(param[1]));
              var branchActionsBSet = printToSet(cleanActions(param$1[1]));
              return Belt_SetString.cmp(branchActionsASet, branchActionsBSet);
            }), Belt_MapString.toArray(branchActionsMap)), (function (branchBunch) {
          return Belt_Array.map(branchBunch, (function (prim) {
                        return prim[0];
                      }));
        }));
  var namespaceIndexItemsPrioritizedByBranchStatus = Belt_SortArray.stableSortBy(namespaceIndexItems, (function (param, param$1) {
          return branchStatusCmp(branchStatusesMap, param.branchId, param$1.branchId);
        }));
  var consolidatedBranchIds = Belt_MutableSetString.fromArray([]);
  return Belt_SortArray.stableSortBy(Belt_Array.keepMap(namespaceIndexItemsPrioritizedByBranchStatus, (function (namespaceIndexItem) {
                    if (Belt_MutableSetString.has(consolidatedBranchIds, namespaceIndexItem.branchId)) {
                      return ;
                    }
                    var branchBunch = Belt_Option.getWithDefault(Belt_Array.getBy(branchesWithSameActions, (function (branchBunch) {
                                return Belt_Array.some(branchBunch, (function (branchId) {
                                              return branchId === namespaceIndexItem.branchId;
                                            }));
                              })), [namespaceIndexItem.branchId]);
                    Belt_MutableSetString.mergeMany(consolidatedBranchIds, branchBunch);
                    return [
                            namespaceIndexItem,
                            branchBunch
                          ];
                  })), (function (param, param$1) {
                var branchStatusCmp$1 = branchStatusCmp(branchStatusesMap, param[0].branchId, param$1[0].branchId);
                if (branchStatusCmp$1 === 1) {
                  return Caml.int_compare(param[1].length, param$1[1].length);
                } else {
                  return branchStatusCmp$1;
                }
              }));
}

function initialState(namespaceIndexItems) {
  return {
          namespaceIndexItems: namespaceIndexItems,
          selectedBranchId: Belt_Option.map(Belt_Array.get(namespaceIndexItems, 0), (function (param) {
                  return param.branchId;
                })),
          branchActions: undefined,
          branchConflicts: undefined,
          consolidatedBranchActions: consolidateBranchActions(namespaceIndexItems, undefined, undefined),
          branchModels: undefined,
          isLoading: false,
          isDisabled: true,
          canRender: false,
          branchStatuses: Belt_MapString.fromArray(Belt_Array.map(namespaceIndexItems, (function (param) {
                      return [
                              param.branchId,
                              undefined
                            ];
                    }))),
          ignoredItems: undefined
        };
}

function isReady(branchActions, branchConflicts, namespaceIndexItems, branchStatuses, branchId) {
  if (Belt_MapString.every(branchStatuses, (function (param, branchStatus) {
            return Belt_Option.isSome(branchStatus);
          })) && Belt_Option.isSome(Belt_MapString.get(branchActions, branchId)) && Belt_Option.isSome(Belt_MapString.get(branchConflicts, branchId))) {
    return Belt_Array.every(namespaceIndexItems, (function (param) {
                  return Belt_MapString.has(branchActions, param.branchId);
                }));
  } else {
    return false;
  }
}

function hasActions(branchActions, branchId) {
  var match = Belt_MapString.get(branchActions, branchId);
  if (match !== undefined) {
    return match.length !== 0;
  } else {
    return false;
  }
}

function isDisabled(branchActions, branchConflicts, namespaceIndexItems, branchStatuses, maybeBranchId) {
  return Belt_Option.mapWithDefault(maybeBranchId, true, (function (branchId) {
                if (!isReady(branchActions, branchConflicts, namespaceIndexItems, branchStatuses, branchId) || !hasActions(branchActions, branchId)) {
                  return true;
                } else {
                  var match = Belt_MapString.get(branchConflicts, branchId);
                  if (match !== undefined && match) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }));
}

function getSelectedBranchId(canRender, previousCanRender, consolidatedBranchActions, currentSelectedBranchId) {
  if (canRender && !previousCanRender) {
    return Belt_Array.get(Belt_Array.map(consolidatedBranchActions, (function (param) {
                      return param[0].branchId;
                    })), 0);
  } else {
    return currentSelectedBranchId;
  }
}

function handleAction(state, action) {
  switch (action.TAG) {
    case "SetNamespaceIndexItems" :
        var namespaceIndexItems = action._0;
        var consolidatedBranchActions = consolidateBranchActions(namespaceIndexItems, state.branchActions, state.branchStatuses);
        var canRender = Belt_Option.mapWithDefault(state.selectedBranchId, false, (function (branchId) {
                return isReady(state.branchActions, state.branchConflicts, namespaceIndexItems, state.branchStatuses, branchId);
              }));
        var selectedBranchId = getSelectedBranchId(canRender, state.canRender, consolidatedBranchActions, state.selectedBranchId);
        return {
                namespaceIndexItems: state.namespaceIndexItems,
                selectedBranchId: selectedBranchId,
                branchActions: state.branchActions,
                branchConflicts: state.branchConflicts,
                consolidatedBranchActions: consolidateBranchActions(namespaceIndexItems, state.branchActions, state.branchStatuses),
                branchModels: state.branchModels,
                isLoading: state.isLoading,
                isDisabled: state.isDisabled,
                canRender: canRender,
                branchStatuses: state.branchStatuses,
                ignoredItems: state.ignoredItems
              };
    case "SelectBranchId" :
        var branchId = action._0;
        return {
                namespaceIndexItems: state.namespaceIndexItems,
                selectedBranchId: branchId,
                branchActions: state.branchActions,
                branchConflicts: state.branchConflicts,
                consolidatedBranchActions: state.consolidatedBranchActions,
                branchModels: state.branchModels,
                isLoading: state.isLoading,
                isDisabled: isDisabled(state.branchActions, state.branchConflicts, state.namespaceIndexItems, state.branchStatuses, branchId),
                canRender: state.canRender,
                branchStatuses: state.branchStatuses,
                ignoredItems: state.ignoredItems
              };
    case "SetBranchState" :
        var branchModel = action._3;
        var mergeConflicts = action._2;
        var actions = action._1;
        var branchId$1 = action._0;
        var branchActions = Belt_MapString.set(state.branchActions, branchId$1, actions);
        var branchConflicts = Belt_MapString.set(state.branchConflicts, branchId$1, mergeConflicts);
        var isDisabled$1 = isDisabled(branchActions, branchConflicts, state.namespaceIndexItems, state.branchStatuses, state.selectedBranchId);
        var branchModels = Belt_MapString.set(state.branchModels, branchId$1, branchModel);
        var match = Belt_MapString.get(state.branchActions, branchId$1);
        var match$1 = Belt_MapString.get(state.branchConflicts, branchId$1);
        var match$2 = Belt_MapString.get(state.branchModels, branchId$1);
        if (match !== undefined) {
          if (match$1 !== undefined) {
            if (match$2 !== undefined) {
              if (!(Caml_obj.notequal(match, actions) || Caml_obj.notequal(match$1, mergeConflicts) || Caml_obj.notequal(match$2, branchModel))) {
                return state;
              }
              var consolidatedBranchActions$1 = consolidateBranchActions(state.namespaceIndexItems, branchActions, state.branchStatuses);
              var canRender$1 = Belt_Option.mapWithDefault(state.selectedBranchId, false, (function (branchId) {
                      return isReady(branchActions, branchConflicts, state.namespaceIndexItems, state.branchStatuses, branchId);
                    }));
              var selectedBranchId$1 = getSelectedBranchId(canRender$1, state.canRender, consolidatedBranchActions$1, state.selectedBranchId);
              return {
                      namespaceIndexItems: state.namespaceIndexItems,
                      selectedBranchId: selectedBranchId$1,
                      branchActions: branchActions,
                      branchConflicts: branchConflicts,
                      consolidatedBranchActions: consolidatedBranchActions$1,
                      branchModels: branchModels,
                      isLoading: state.isLoading,
                      isDisabled: isDisabled$1,
                      canRender: canRender$1,
                      branchStatuses: state.branchStatuses,
                      ignoredItems: state.ignoredItems
                    };
            }
            match$2 !== undefined;
          } else {
            match$2 !== undefined;
          }
        }
        var consolidatedBranchActions$2 = consolidateBranchActions(state.namespaceIndexItems, branchActions, state.branchStatuses);
        var canRender$2 = Belt_Option.mapWithDefault(state.selectedBranchId, false, (function (branchId) {
                return isReady(branchActions, branchConflicts, state.namespaceIndexItems, state.branchStatuses, branchId);
              }));
        var selectedBranchId$2 = getSelectedBranchId(canRender$2, state.canRender, consolidatedBranchActions$2, state.selectedBranchId);
        return {
                namespaceIndexItems: state.namespaceIndexItems,
                selectedBranchId: selectedBranchId$2,
                branchActions: branchActions,
                branchConflicts: branchConflicts,
                consolidatedBranchActions: consolidatedBranchActions$2,
                branchModels: branchModels,
                isLoading: state.isLoading,
                isDisabled: isDisabled$1,
                canRender: canRender$2,
                branchStatuses: state.branchStatuses,
                ignoredItems: state.ignoredItems
              };
    case "SetIsLoading" :
        return {
                namespaceIndexItems: state.namespaceIndexItems,
                selectedBranchId: state.selectedBranchId,
                branchActions: state.branchActions,
                branchConflicts: state.branchConflicts,
                consolidatedBranchActions: state.consolidatedBranchActions,
                branchModels: state.branchModels,
                isLoading: action._0,
                isDisabled: state.isDisabled,
                canRender: state.canRender,
                branchStatuses: state.branchStatuses,
                ignoredItems: state.ignoredItems
              };
    case "SetBranchStatus" :
        var branchStatuses = Belt_MapString.set(state.branchStatuses, action._0, action._1);
        var isDisabled$2 = isDisabled(state.branchActions, state.branchConflicts, state.namespaceIndexItems, branchStatuses, state.selectedBranchId);
        var consolidatedBranchActions$3 = consolidateBranchActions(state.namespaceIndexItems, state.branchActions, branchStatuses);
        var canRender$3 = Belt_Option.mapWithDefault(state.selectedBranchId, false, (function (branchId) {
                return isReady(state.branchActions, state.branchConflicts, state.namespaceIndexItems, branchStatuses, branchId);
              }));
        var selectedBranchId$3 = getSelectedBranchId(canRender$3, state.canRender, consolidatedBranchActions$3, state.selectedBranchId);
        return {
                namespaceIndexItems: state.namespaceIndexItems,
                selectedBranchId: selectedBranchId$3,
                branchActions: state.branchActions,
                branchConflicts: state.branchConflicts,
                consolidatedBranchActions: consolidatedBranchActions$3,
                branchModels: state.branchModels,
                isLoading: state.isLoading,
                isDisabled: isDisabled$2,
                canRender: canRender$3,
                branchStatuses: branchStatuses,
                ignoredItems: state.ignoredItems
              };
    case "SetIgnoredItems" :
        return {
                namespaceIndexItems: state.namespaceIndexItems,
                selectedBranchId: state.selectedBranchId,
                branchActions: state.branchActions,
                branchConflicts: state.branchConflicts,
                consolidatedBranchActions: state.consolidatedBranchActions,
                branchModels: state.branchModels,
                isLoading: state.isLoading,
                isDisabled: state.isDisabled,
                canRender: state.canRender,
                branchStatuses: state.branchStatuses,
                ignoredItems: Belt_MapString.set(state.ignoredItems, action._0, action._1)
              };
    
  }
}

function CopyChangesToBranchModal__Pull$PullModal(Props) {
  var branchItemSyncOrigin = Props.branchItemSyncOrigin;
  var namespaceIndexItems = Props.namespaceIndexItems;
  var pageName = Props.pageName;
  var onSuccess = Props.onSuccess;
  var onClose = Props.onClose;
  var schemaBundle = SchemaBundleContext.use();
  var branchName = schemaBundle.branchName;
  var currentBranchId = schemaBundle.branchId;
  var schemaId = schemaBundle.schemaId;
  var openBranches = ModelStore.Mapped.useOpenBranches();
  var addToast = Toast.useAddToast();
  var sendActions = SendActionsContext.use();
  var match = Belt_Array.get(namespaceIndexItems, 0);
  var match$1 = match !== undefined ? [
      match.itemType,
      match.itemId,
      match.name
    ] : Pervasives.failwith("No item type found");
  var itemName = match$1[2];
  var itemId = match$1[1];
  var itemType = match$1[0];
  var copyItems;
  copyItems = itemType === "Events" ? [{
        NAME: "event",
        VAL: itemId
      }] : [{
        NAME: "property",
        VAL: itemId
      }];
  var parentType = CopyChangesToBranchModal__Types.ParentType.fromCopyItems(copyItems);
  var itemIds = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.map(namespaceIndexItems, (function (param) {
                  return param.itemId;
                }))));
  var maybeItemId = itemIds.length === 1 ? Belt_Array.get(itemIds, 0) : undefined;
  var match$2 = React.useReducer(handleAction, initialState(namespaceIndexItems));
  var reduce = match$2[1];
  var state = match$2[0];
  Hooks.useDidUpdate1((function (param) {
          Curry._1(reduce, {
                TAG: "SetNamespaceIndexItems",
                _0: namespaceIndexItems
              });
        }), [namespaceIndexItems]);
  React.useEffect((function (param) {
          var arg = Belt_Option.getWithDefault(maybeItemId, "multiple");
          var arg$1;
          arg$1 = itemType === "Events" ? "Event" : "Property";
          var arg$2 = currentBranchId;
          var arg$3 = branchName;
          AnalyticsUtils.withSchemaBundle((function (param) {
                  var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22) {
                    return AnalyticsRe.branchItemSyncStarted(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22);
                  };
                  return function (param) {
                    var func$1 = Curry._1(func, param);
                    return function (param) {
                      var func$2 = Curry._1(func$1, param);
                      return function (param) {
                        var func$3 = Curry._1(func$2, param);
                        return function (param) {
                          var func$4 = Curry._1(func$3, param);
                          return function (param) {
                            var func$5 = Curry._1(func$4, param);
                            return function (param) {
                              var func$6 = Curry._1(func$5, param);
                              return function (param) {
                                var func$7 = Curry._1(func$6, param);
                                return function (param) {
                                  var func$8 = Curry._1(func$7, param);
                                  return function (param) {
                                    var func$9 = Curry._1(func$8, param);
                                    return function (param) {
                                      return Curry.app(func$9, [
                                                  param,
                                                  arg,
                                                  itemName,
                                                  arg$1,
                                                  arg$2,
                                                  arg$3,
                                                  "N/A",
                                                  undefined,
                                                  "Pull",
                                                  branchItemSyncOrigin,
                                                  pageName,
                                                  schemaId,
                                                  currentBranchId
                                                ]);
                                    };
                                  };
                                };
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                }), schemaBundle);
        }), []);
  var onError = function (param) {
    Curry._1(reduce, {
          TAG: "SetIsLoading",
          _0: false
        });
    Curry._1(addToast, {
          message: "Failed to pull changes to current branch",
          toastType: "Error"
        });
  };
  var onProceedWithCopy = async function (sourceBranchId, actions) {
    Curry._1(reduce, {
          TAG: "SetIsLoading",
          _0: true
        });
    return Curry.app(sendActions, [
                {
                  NAME: "Branch",
                  VAL: currentBranchId
                },
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (param) {
                    Curry._1(reduce, {
                          TAG: "SetIsLoading",
                          _0: false
                        });
                    var itemId = Belt_Option.getWithDefault(maybeItemId, Belt_Option.mapWithDefault(Belt_Array.getBy(namespaceIndexItems, (function (param) {
                                    return param.branchId === sourceBranchId;
                                  })), "N/A", (function (param) {
                                return param.itemId;
                              })));
                    var arg;
                    arg = itemType === "Events" ? "Event" : "Property";
                    var arg$1 = Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.OpenBranches.get, openBranches, sourceBranchId), "N/A", (function (prim) {
                            return prim[1];
                          }));
                    var arg$2 = Belt_Option.mapWithDefault(Belt_MapString.get(state.branchActions, sourceBranchId), 0, (function (prim) {
                            return prim.length;
                          }));
                    var arg$3 = Belt_Option.mapWithDefault(Belt_MapString.get(state.ignoredItems, sourceBranchId), 0, Belt_Map.size);
                    var arg$4 = Belt_Option.mapWithDefault(Belt_MapString.get(state.ignoredItems, sourceBranchId), [], CopyChangesToBranchModal__Types.CopyItem.getIgnoredAnalyticsTypes);
                    var arg$5 = Belt_Option.mapWithDefault(Belt_Array.getBy(state.consolidatedBranchActions, (function (param) {
                                return sourceBranchId === param[0].branchId;
                              })), 1, (function (param) {
                            return param[1].length + 1 | 0;
                          }));
                    var arg$6 = state.consolidatedBranchActions.length;
                    AnalyticsUtils.withSchemaBundle((function (param) {
                            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28) {
                              return AnalyticsRe.branchItemSyncCompletedPull(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21, param$22, param$23, param$24, param$25, param$26, param$27, param$28);
                            };
                            return function (param) {
                              var func$1 = Curry._1(func, param);
                              return function (param) {
                                var func$2 = Curry._1(func$1, param);
                                return function (param) {
                                  var func$3 = Curry._1(func$2, param);
                                  return function (param) {
                                    var func$4 = Curry._1(func$3, param);
                                    return function (param) {
                                      var func$5 = Curry._1(func$4, param);
                                      return function (param) {
                                        var func$6 = Curry._1(func$5, param);
                                        return function (param) {
                                          var func$7 = Curry._1(func$6, param);
                                          return function (param) {
                                            var func$8 = Curry._1(func$7, param);
                                            return function (param) {
                                              var func$9 = Curry._1(func$8, param);
                                              return function (param) {
                                                return Curry.app(func$9, [
                                                            param,
                                                            itemId,
                                                            itemName,
                                                            arg,
                                                            currentBranchId,
                                                            branchName,
                                                            sourceBranchId,
                                                            arg$1,
                                                            arg$2,
                                                            arg$3,
                                                            arg$4,
                                                            "Pull",
                                                            branchItemSyncOrigin,
                                                            pageName,
                                                            arg$5,
                                                            arg$6,
                                                            0,
                                                            schemaId,
                                                            currentBranchId
                                                          ]);
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          }), schemaBundle);
                    Curry._1(addToast, {
                          message: "Changes pulled to current branch",
                          toastType: "Success"
                        });
                    var tmp;
                    tmp = itemType === "Events" ? [{
                          NAME: "event",
                          VAL: itemId
                        }] : [{
                          NAME: "property",
                          VAL: itemId
                        }];
                    var resolvedCopyItems = CopyChangesToBranchModal__Utils.resolveCopyItems(tmp, Belt_Option.getWithDefault(Belt_MapString.get(state.branchModels, sourceBranchId), TrackingPlanModel.empty));
                    Belt_Option.forEach(onSuccess, (function (onSuccess) {
                            setTimeout((function (param) {
                                    Curry._2(onSuccess, sendActions, resolvedCopyItems);
                                  }), 2000);
                          }));
                    return Curry._1(onClose, undefined);
                  }),
                onError,
                actions
              ]);
  };
  return React.createElement("div", {
              className: modalStyles
            }, React.createElement("div", {
                  className: titleStyles
                }, React.createElement($$Text.make, {
                      size: "Huge",
                      weight: "Bold",
                      color: Styles.Color.light11,
                      children: "Pull changes to current branch"
                    }), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement(CopyChangesToBranchModal__Tooltip.make, {
                      copyMode: "Pull"
                    })), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.gap(Css.px(16)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, state.canRender ? React.createElement($$Text.make, {
                        color: Styles.Color.light11,
                        children: state.consolidatedBranchActions.length > 1 ? "This " + (
                            parentType === "Property" ? "property" : (
                                parentType === "Event" ? "event" : (
                                    parentType === "PropertyBundle" ? "property bundle" : "source"
                                  )
                              )
                          ) + " has different shapes depending on which branch it is on. Review and pick the shape as well as the related changes you want to pull to your branch. By default, all related changes are included." : "Review and pick the related changes to the " + (
                            parentType === "Property" ? "property" : (
                                parentType === "Event" ? "event" : (
                                    parentType === "PropertyBundle" ? "property bundle" : "source"
                                  )
                              )
                          ) + " you want to pull to your branch. By default, all related changes are included.",
                        pretty: true
                      }) : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.backgroundColor(Styles.Color.light03),
                              tl: {
                                hd: Css.padding(Css.px(24)),
                                tl: {
                                  hd: Css.borderRadius(Css.px(8)),
                                  tl: {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.gap(Css.px(8)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement(Mantine.Loader.make, {
                            size: 16,
                            color: Styles.Color.avoPink
                          }), React.createElement($$Text.make, {
                            color: Styles.Color.light10,
                            children: "Loading changes to push"
                          })), React.createElement(React$1.motion.div, {
                      animate: state.canRender ? "render" : "hide",
                      variants: {
                        render: {
                          maxHeight: "100%",
                          height: "100%",
                          opacity: 1.0
                        },
                        hide: {
                          maxHeight: "0",
                          height: "0",
                          opacity: 0.0
                        }
                      },
                      className: Curry._1(Css.merge, {
                            hd: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("column"),
                                    tl: {
                                      hd: Css.gap(Css.px(16)),
                                      tl: /* [] */0
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }),
                      children: Belt_Array.map(state.consolidatedBranchActions, (function (param) {
                              var onBranchIds = param[1];
                              var match = param[0];
                              var branchId = match.branchId;
                              return React.createElement(CopyChangesToBranchModal__Pull$BranchWrapper, {
                                          allSame: state.consolidatedBranchActions.length === 1,
                                          branchId: branchId,
                                          canRender: state.canRender,
                                          onSelectBranchId: (function (param) {
                                              Curry._1(reduce, {
                                                    TAG: "SelectBranchId",
                                                    _0: branchId
                                                  });
                                            }),
                                          checked: Belt_Option.mapWithDefault(state.selectedBranchId, false, (function (selectedBranchId) {
                                                  return Belt_Array.some(onBranchIds, (function (branchId) {
                                                                return branchId === selectedBranchId;
                                                              }));
                                                })),
                                          mainBranchName: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.OpenBranches.get, openBranches, branchId), "Untitled branch", (function (prim) {
                                                  return prim[1];
                                                })),
                                          otherBranches: Belt_Array.keep(onBranchIds, (function (bId) {
                                                  return bId !== branchId;
                                                })),
                                          children: React.createElement(CopyChangesToBranchModal__ChangesToCopy.make, {
                                                onClose: (function (param) {
                                                    
                                                  }),
                                                copyItems: copyItems,
                                                originBranch: {
                                                  NAME: "branch",
                                                  VAL: branchId
                                                },
                                                destinationBranch: {
                                                  NAME: "Branch",
                                                  VAL: {
                                                    id: currentBranchId,
                                                    name: branchName
                                                  }
                                                },
                                                copyMode: "Pull",
                                                branchItemSyncOrigin: branchItemSyncOrigin,
                                                pageName: pageName,
                                                withCta: false,
                                                onStateChange: (function (getActionsToSend, mergeConflicts, branchModel) {
                                                    Curry._1(reduce, {
                                                          TAG: "SetBranchState",
                                                          _0: branchId,
                                                          _1: Curry._3(getActionsToSend, branchId, currentBranchId, branchName),
                                                          _2: mergeConflicts,
                                                          _3: branchModel
                                                        });
                                                  }),
                                                onExcludedItemsChange: (function (ignoredItems) {
                                                    Curry._1(reduce, {
                                                          TAG: "SetIgnoredItems",
                                                          _0: branchId,
                                                          _1: ignoredItems
                                                        });
                                                  }),
                                                parentType: parentType,
                                                key: branchId + match.name
                                              }),
                                          key: branchId
                                        });
                            }))
                    }), React.createElement("div", {
                      className: buttonsPalette
                    }, React.createElement(Button.make, {
                          label: "Cancel",
                          onClick: (function (param) {
                              Curry._1(onClose, undefined);
                            }),
                          size: "large",
                          style: "ghost"
                        }), React.createElement(Button.make, {
                          disabled: state.isDisabled,
                          label: "Pull to branch",
                          loading: state.isLoading,
                          onClick: (function (param) {
                              Belt_Option.forEach(state.selectedBranchId, (function (branchId) {
                                      Belt_Option.forEach(Belt_MapString.get(state.branchActions, branchId), (function (appliedActions) {
                                              onProceedWithCopy(branchId, appliedActions);
                                            }));
                                    }));
                            }),
                          size: "large",
                          style: "primary"
                        }))), Belt_Array.map(Belt_MapString.keysToArray(state.branchStatuses), (function (branchId) {
                    return React.createElement(CopyChangesToBranchModal__Pull$BranchStatusFetcher, {
                                branchId: branchId,
                                onBranchStatusSuccess: (function (branchStatus) {
                                    Curry._1(reduce, {
                                          TAG: "SetBranchStatus",
                                          _0: branchId,
                                          _1: branchStatus
                                        });
                                  })
                              });
                  })));
}

function CopyChangesToBranchModal__Pull(Props) {
  var branchItemSyncOrigin = Props.branchItemSyncOrigin;
  var namespaceIndexItems = Props.namespaceIndexItems;
  var pageName = Props.pageName;
  var onSuccess = Props.onSuccess;
  var role = Props.role;
  var onClose = Props.onClose;
  var currentBranchId = Router.Schema.getBranchId();
  var scheduleGlobalSendOnNextBranch = GlobalSendOnNextBranchScheduler.useScheduleGlobalSend();
  var workspace = WorkspaceContext.use();
  var isPullToMain = currentBranchId === "master";
  var pushPullBetweenBranchesAvailability = AvoLimits.PushPullBetweenBranches.computeAvailability(workspace);
  if (!(pushPullBetweenBranchesAvailability === "AvailableDuringTrial" || pushPullBetweenBranchesAvailability === "Available")) {
    return React.createElement(BillingPromptModal.make, {
                onClose: (function (param) {
                    Curry._1(onClose, undefined);
                  }),
                onCloseAll: (function (param) {
                    Curry._1(onClose, undefined);
                  }),
                paywallName: "PushPullChanges",
                viewerRole: role
              });
  }
  if (isPullToMain) {
    return React.createElement(CopyChangesToBranchModal.PullToMainModal.make, {
                onClose: onClose,
                onBranchSelected: (function (branchId) {
                    if (branchId !== "master") {
                      return Curry._1(scheduleGlobalSendOnNextBranch, {
                                  TAG: "OpenModal",
                                  _0: {
                                    NAME: "PullChanges",
                                    VAL: [
                                      namespaceIndexItems,
                                      onSuccess,
                                      pageName,
                                      branchItemSyncOrigin
                                    ]
                                  }
                                });
                    } else {
                      return Pervasives.failwith("Cannot pull to main");
                    }
                  })
              });
  }
  var tmp = {
    branchItemSyncOrigin: branchItemSyncOrigin,
    namespaceIndexItems: namespaceIndexItems,
    pageName: pageName,
    onClose: onClose
  };
  if (onSuccess !== undefined) {
    tmp.onSuccess = Caml_option.valFromOption(onSuccess);
  }
  return React.createElement(CopyChangesToBranchModal__Pull$PullModal, tmp);
}

var make = CopyChangesToBranchModal__Pull;

export {
  make ,
}
/* modalStyles Not a pure module */
