// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Styles from "./styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as TextButton from "./TextButton.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ObjectsDiff from "./ObjectsDiff.mjs";

function PullMasterWarning(Props) {
  var schemaId = Props.schemaId;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var masterSinceOpenedDiff = Props.masterSinceOpenedDiff;
  var latestMasterAction = Props.latestMasterAction;
  var branchId = Props.branchId;
  var branchStatus = Props.branchStatus;
  var role = Props.role;
  var events = Props.events;
  var match = React.useState(function (param) {
        return false;
      });
  var setShowDiff = match[1];
  var showDiff = match[0];
  var match$1 = React.useState(function (param) {
        return "Ready";
      });
  var setStatus = match$1[1];
  var status = match$1[0];
  var handlePull = function (param) {
    Curry._1(setStatus, (function (param) {
            return "Loading";
          }));
    $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app().auth().currentUser)).getIdToken().then(function (token) {
                  return fetch(Firebase.apiUrl + "/c/v1/master/pull", {
                              method: "POST",
                              body: Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                            [
                                              "schemaId",
                                              schemaId
                                            ],
                                            [
                                              "branchId",
                                              branchId
                                            ],
                                            [
                                              "force",
                                              true
                                            ]
                                          ]))),
                              headers: Caml_option.some(new Headers([
                                        [
                                          "Accept",
                                          "application/json"
                                        ],
                                        [
                                          "Content-Type",
                                          "application/json"
                                        ],
                                        [
                                          "Authorization",
                                          "Bearer " + token
                                        ]
                                      ]))
                            });
                }).then(function (prim) {
                return prim.json();
              }).then(function (_response) {
              Curry._1(setStatus, (function (param) {
                      return "Ready";
                    }));
              return Promise.resolve();
            }), (function (error) {
            console.log("auth error", error);
            window.alert("Something went wrong when pulling from main, please try again.");
            Sentry.captureException(error, {});
            Curry._1(setStatus, (function (param) {
                    return "Ready";
                  }));
            return Promise.resolve();
          }));
  };
  var match$2 = Belt_List.length(masterSinceOpenedDiff) > 0;
  if (!match$2) {
    return null;
  }
  if (latestMasterAction === undefined) {
    return null;
  }
  var renderPullButton = function (param) {
    return React.createElement(Button.make, {
                disabled: status === "Loading",
                label: status === "Loading" ? "Pulling Main..." : "Pull Main Branch",
                onClick: handlePull,
                style: {
                  NAME: "custom",
                  VAL: {
                    customColor: Styles.Color.deepOrange,
                    hoverColor: Styles.Color.light12,
                    customBackgroundColor: Styles.Color.orange,
                    customBackgroundHoverColor: Styles.Color.darken(Styles.Color.orange, 10),
                    optionalCustomOutlineColor: undefined,
                    optionalCustomOutlineHoverColor: undefined
                  }
                }
              });
  };
  var tmp;
  if (showDiff) {
    var masterActions = AvoUtils.groupActionsByObjectId(AvoUtils.actionByObject(masterSinceOpenedDiff));
    tmp = React.createElement("div", undefined, React.createElement(ObjectsDiff.make, {
              branchStatus: branchStatus,
              fromModel: masterModelBeforeBranch,
              toModel: masterModel,
              actions: masterActions,
              branchId: branchId,
              withCommentsAndCopyToBranch: false,
              events: events
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.justifyContent("flexEnd"),
                      tl: {
                        hd: Css.marginTop(Css.px(15)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, renderPullButton()));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(20)),
                    tl: {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.border(Css.px(1), Css.solid, Styles.Color.orange),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light01),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontSize(Styles.FontSize.regular),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: /* [] */0
                                }
                              })
                        }, "This branch is not up to date with the main branch."), RoleUtils.canEdit(role) ? React.createElement(TextButton.make, {
                            onClick: (function (param) {
                                Curry._1(setShowDiff, (function (showDiff) {
                                        return !showDiff;
                                      }));
                              }),
                            style: "DarkOrange",
                            size: "Small",
                            children: showDiff ? "Hide Main Changes" : "Show Main Changes"
                          }) : React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.darkOrange,
                            children: "Ask someone with editor or admin access to pull the main branch"
                          })), renderPullButton()), React.createElement("div", undefined, tmp));
}

var make = PullMasterWarning;

export {
  make ,
}
/* Css Not a pure module */
