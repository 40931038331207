// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as EventNameComponent from "../../shared/models/EventNameComponent.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as EventNameComponentCollection from "./EventNameComponentCollection.mjs";

function useStructure(schemaId, nameComponents, name) {
  var match = React.useState(function (param) {
        return "Loading";
      });
  var setState = match[1];
  var nameComponents$1 = EventNameComponentCollection.useEventNameComponent(schemaId, ValidationConfigV2.NameComponent.getNameConfigAsString(nameComponents), name);
  React.useEffect((function (param) {
          if (typeof nameComponents$1 === "object") {
            var json = nameComponents$1.VAL;
            Curry._1(setState, (function (param) {
                    return {
                            NAME: "Success",
                            VAL: Json_decode.field("components", EventNameComponent.decodeArray, json)
                          };
                  }));
          } else if (nameComponents$1 === "NotFound") {
            Curry._1(setState, (function (param) {
                    return "NotFound";
                  }));
          } else {
            Curry._1(setState, (function (param) {
                    return "Loading";
                  }));
          }
        }), [nameComponents$1]);
  return match[0];
}

export {
  useStructure ,
}
/* react Not a pure module */
