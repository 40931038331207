// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Router from "./Router.mjs";
import * as $$Comment from "./Comment.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AvoConfigTypes from "../../shared/models/AvoConfigTypes.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as QuillToPlainText from "../../shared/utils/QuillToPlainText.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as ObjectActivityLogAction from "./ObjectActivityLogAction.mjs";

function EventActivityItem(Props) {
  var $$event = Props.event;
  var model = Props.model;
  var action = Props.action;
  var userId = Props.userId;
  var userIds = Props.userIds;
  var goToId = Props.goToId;
  var openBranches = Props.openBranches;
  var showComments = Props.showComments;
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace();
  var users = FirebaseFetcherHooks.useUsers(userIds);
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  var name;
  if (typeof match !== "object") {
    return null;
  }
  var variant = match.NAME;
  if (variant === "AddProperty") {
    var match$1 = match.VAL;
    return React.createElement(ObjectActivityLogAction.make, {
                action: action,
                users: users$1,
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "property",
                      VAL: [
                        match$1[1],
                        undefined
                      ]
                    }),
                openBranches: openBranches,
                children: null
              }, "added the property ", React.createElement("b", undefined, match$1[2]));
  }
  if (variant === "AddEventType") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: null
              }, "added the event type ", React.createElement("b", undefined, TrackingPlanModel.eventTypeToJs(match.VAL[1])));
  }
  if (variant === "UpdateTriggerContent") {
    var triggerId = match.VAL[1];
    var match$2 = ModelUtils.getTriggerByIdAndEventId(triggerId, $$event.id, model);
    if (match$2 !== undefined) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          $$event.id,
                          undefined,
                          triggerId,
                          false
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "updated content for the trigger ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$2.description)));
    } else {
      return null;
    }
  }
  if (variant === "AddEventSpecificPropertyValueForSomeEvents" || variant === "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources" || variant === "AddEventSpecificPropertyValueForAllEvents" || variant === "RemoveDomainItems" || variant === "ClearAllEventSpecificPropertyValuesFromProperty" || variant === "AddDomainItems" || variant === "RemoveEventSpecificPropertyValueForAllEvents") {
    return null;
  }
  if (variant === "AddEvent") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "created the event"
              });
  }
  if (variant === "RemovePropertyFromWhitelist") {
    var match$3 = match.VAL;
    var match$4 = ModelUtils.getPropertyByIdWithArchive(match$3[1], model);
    if (match$4 !== undefined && match$4.TAG !== "PropertyRef") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "stopped sending the property ", React.createElement("b", undefined, match$4._0.name), " to ", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$3[2])), " with this event");
    } else {
      return null;
    }
  }
  if (variant === "UpdateTrackingPlanAuditConfig" || variant === "RemoveDomainMembers" || variant === "UpdateDomainDescription" || variant === "ArchiveDomain" || variant === "UpdateDomainName" || variant === "CreateDomain" || variant === "AddDomainMembers" || variant === "UpdateDomainMemberRole" || variant === "ClearSourceOverrideOnVariant" || variant === "SetSourceOverrideOnVariant") {
    return null;
  }
  if (variant === "CreateDemoBranch") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "created the event on a demo branch"
              });
  }
  if (variant === "ConvertEventToGlobalEvent") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "merged the event with global requirements"
              });
  }
  if (variant === "AddTag") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: null
              }, "added the tag ", React.createElement("b", undefined, match.VAL[1]));
  }
  if (variant === "AddPropertyRef") {
    var match$5 = match.VAL;
    var propertyId = match$5[1];
    var match$6 = ModelUtils.getEventByIdWithArchive(match$5[0], model);
    var match$7 = ModelUtils.getPropertyByIdWithArchive(propertyId, model);
    if (match$6 !== undefined && match$7 !== undefined && match$7.TAG !== "PropertyRef") {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId,
                          undefined
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "added the property ", React.createElement("b", undefined, match$7._0.name));
    } else {
      return null;
    }
  }
  if (variant === "RemoveEvent") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "deleted the event"
              });
  }
  if (variant === "EventComment") {
    if (!showComments) {
      return null;
    }
    var match$8 = match.VAL;
    var eventId = match$8[0];
    return React.createElement($$Comment.make, {
                threadId: $$event.id,
                itemType: "Event",
                itemName: $$event.name,
                commentId: match$8[1],
                events: model.events,
                userId: userId,
                eventId: eventId,
                goToId: goToId,
                action: action,
                link: (function (commentId) {
                    return Router.Link.addDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  eventId,
                                  commentId,
                                  undefined,
                                  false
                                ]
                              });
                  }),
                openBranches: openBranches
              });
  }
  if (variant === "ExcludeEventFromSource" || variant === "ExcludeEventFromSourceV2") {
    var source = ModelUtils.getSourceByIdWithArchive(match.VAL[1], model);
    if (source !== undefined) {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "removed the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source)));
    } else {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "updated the sources this event is sent from"
                });
    }
  }
  if (variant === "ConvertEventToGlobalEventV2") {
    return React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: "merged the event with global requirements"
              });
  }
  if (variant === "UpdateTriggerSources") {
    var match$9 = match.VAL;
    var sources = match$9[2];
    var triggerId$1 = match$9[1];
    var sourceNames;
    sourceNames = typeof sources !== "object" ? (
        isGlobalWorkspace ? "Workspace Independent" : "Source Independent"
      ) : Belt_Array.map(Belt_Array.keepMap(sources._0, (function (sourceId) {
                    return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                  })), AvoConfig.getSourceName).join(", ");
    var match$10 = ModelUtils.getTriggerFromEvent(triggerId$1, $$event);
    if (match$10 !== undefined) {
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          $$event.id,
                          undefined,
                          triggerId$1,
                          false
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "updated a trigger with the description ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$10.description)), " to be triggered with ", React.createElement("b", undefined, sourceNames));
    } else {
      return null;
    }
  }
  if (variant === "UpdateEventUniqueNameV2") {
    var name$1 = match.VAL[1];
    if (name$1 === "") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "removed the unique name"
                });
    }
    name = name$1;
  } else {
    if (variant === "CreateEventTrigger") {
      var match$11 = match.VAL;
      var sources$1 = match$11[2];
      var triggerId$2 = match$11[1];
      var sourceNames$1;
      sourceNames$1 = typeof sources$1 !== "object" ? (
          isGlobalWorkspace ? "Workspace Independent" : "Source Independent"
        ) : Belt_Array.map(Belt_Array.keepMap(sources$1._0, (function (sourceId) {
                      return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                    })), AvoConfig.getSourceName).join(", ");
      var match$12 = ModelUtils.getTriggerFromEvent(triggerId$2, $$event);
      if (match$12 !== undefined) {
        return React.createElement(ObjectActivityLogAction.make, {
                    action: action,
                    users: users$1,
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            $$event.id,
                            undefined,
                            triggerId$2,
                            false
                          ]
                        }),
                    openBranches: openBranches,
                    children: null
                  }, "created a trigger for ", React.createElement("b", undefined, sourceNames$1), " with the description ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$12.description)));
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertyRegexValidation") {
      var match$13 = match.VAL;
      if (match$13[1] === undefined) {
        return null;
      }
      var maybeRule = match$13[2];
      var match$14 = ModelUtils.getPropertyById(match$13[0], model);
      if (match$14 === undefined) {
        return null;
      }
      if (match$14.TAG === "PropertyRef") {
        return null;
      }
      var property = match$14._0;
      if (maybeRule !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "added the regex validation ", React.createElement("b", undefined, maybeRule.regex), " to the property ", React.createElement("b", undefined, property.name));
      } else {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "removed the regex validation from the property", React.createElement("b", undefined, property.name));
      }
    }
    if (variant === "RemoveEventSpecificPropertyValue") {
      var match$15 = match.VAL;
      var sources$2 = match$15[3];
      var literal = match$15[2];
      var match$16 = ModelUtils.getPropertyByIdWithArchive(match$15[1], model);
      if (typeof literal !== "object") {
        return null;
      }
      if (literal.NAME !== "StringLit") {
        return null;
      }
      if (match$16 === undefined) {
        return null;
      }
      if (match$16.TAG === "PropertyRef") {
        return null;
      }
      var sourcesAreAllEventSources = Belt_SetString.eq(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map($$event.includeSources, (function (param) {
                          return param.id;
                        })))), sources$2);
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "removed the allowed value ", React.createElement("b", undefined, literal.VAL), " on the property ", React.createElement("b", undefined, match$16._0.name), sourcesAreAllEventSources ? null : React.createElement(React.Fragment, undefined, " for ", React.createElement("b", undefined, Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(sources$2), (function (sourceId) {
                                        return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                                      })), AvoConfig.getSourceName).join(", "))));
    }
    if (variant === "Archive") {
      var match$17 = match.VAL;
      if (typeof match$17 !== "object") {
        return null;
      }
      var variant$1 = match$17.NAME;
      if (variant$1 === "Event") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "archived the event"
                  });
      }
      if (variant$1 !== "EventVariant") {
        return null;
      }
      var match$18 = ModelUtils.getEventVariantByIdWithArchive(model, match$17.VAL);
      if (match$18 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "archived the variant ", React.createElement("b", undefined, match$18.nameSuffix));
      } else {
        return null;
      }
    }
    if (variant === "RemovePropertyRefV3" || variant === "RemovePropertyRefV2Deprecated") {
      var match$19 = match.VAL;
      var match$20 = ModelUtils.getEventByIdWithArchive(match$19[0], model);
      var match$21 = ModelUtils.getPropertyByIdWithArchive(match$19[1], model);
      if (match$20 !== undefined && match$21 !== undefined && match$21.TAG !== "PropertyRef") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "removed the property ", React.createElement("b", undefined, match$21._0.name));
      } else {
        return null;
      }
    }
    if (variant === "RemoveTag") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "removed the tag ", React.createElement("b", undefined, match.VAL[1]));
    }
    if (variant === "AddUserToGroup") {
      var groupTypeName = ModelUtils.getGroupTypeName(match.VAL[0], model);
      if (groupTypeName !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "started associating the user with the ", React.createElement("b", undefined, groupTypeName), " group type");
      } else {
        return null;
      }
    }
    if (variant === "SetEventSpecificPropertyValueSources") {
      var match$22 = match.VAL;
      var removedSources = match$22[4];
      var addedSources = match$22[3];
      var literal$1 = match$22[2];
      var match$23 = ModelUtils.getPropertyByIdWithArchive(match$22[1], model);
      if (typeof literal$1 !== "object") {
        return null;
      }
      if (literal$1.NAME !== "StringLit") {
        return null;
      }
      if (match$23 === undefined) {
        return null;
      }
      var match$24 = literal$1.VAL;
      if (match$23.TAG === "PropertyRef") {
        return null;
      }
      var property$1 = match$23._0;
      var tmp;
      if (Belt_SetString.isEmpty(addedSources)) {
        tmp = null;
      } else {
        var sources$3 = Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(addedSources), (function (sourceId) {
                      return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                    })), AvoConfig.getSourceName).join(", ");
        tmp = React.createElement(ObjectActivityLogAction.Content.make, {
              action: action,
              users: users$1,
              openBranches: openBranches,
              children: null
            }, "added the allowed value ", React.createElement("b", undefined, match$24), " to the property ", React.createElement("b", undefined, property$1.name), " for ", React.createElement("b", undefined, sources$3));
      }
      var tmp$1;
      if (Belt_SetString.isEmpty(removedSources)) {
        tmp$1 = null;
      } else {
        var sources$4 = Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(removedSources), (function (sourceId) {
                      return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                    })), AvoConfig.getSourceName).join(", ");
        tmp$1 = React.createElement(ObjectActivityLogAction.Content.make, {
              action: action,
              users: users$1,
              openBranches: openBranches,
              children: null
            }, "removed the allowed value ", React.createElement("b", undefined, match$24), " from the property ", React.createElement("b", undefined, property$1.name), " for ", React.createElement("b", undefined, sources$4));
      }
      return React.createElement(React.Fragment, undefined, tmp, tmp$1);
    }
    if (variant === "ToggleIncludeEventInCodegenForSource") {
      var match$25 = match.VAL;
      var includeInCodegen = match$25[2];
      var source$1 = ModelUtils.getSourceByIdWithArchive(match$25[1], model);
      if (source$1 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, (
                    includeInCodegen ? "included the event in" : "excluded the event from"
                  ) + " Codegen for the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$1)));
      } else {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: (
                      includeInCodegen ? "included the event in" : "excluded the event from"
                    ) + " Codegen for a source"
                  });
      }
    }
    if (variant === "IncludeEventInSource" || variant === "IncludeEventInSourceV2") {
      var source$2 = ModelUtils.getSourceByIdWithArchive(match.VAL[1], model);
      if (source$2 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "added the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$2)));
      } else {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "updated the sources this event is sent from "
                  });
      }
    }
    if (variant === "DeleteComment" || variant === "AddSystemProperty") {
      return null;
    }
    if (variant === "AddEventSpecificPropertyValue") {
      var match$26 = match.VAL;
      var sources$5 = match$26[3];
      var literal$2 = match$26[2];
      var match$27 = ModelUtils.getPropertyByIdWithArchive(match$26[1], model);
      if (typeof literal$2 !== "object") {
        return null;
      }
      if (literal$2.NAME !== "StringLit") {
        return null;
      }
      if (match$27 === undefined) {
        return null;
      }
      if (match$27.TAG === "PropertyRef") {
        return null;
      }
      var sourcesAreAllEventSources$1 = Belt_SetString.eq(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map($$event.includeSources, (function (param) {
                          return param.id;
                        })))), sources$5);
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "added the allowed value ", React.createElement("b", undefined, literal$2.VAL), " to the property ", React.createElement("b", undefined, match$27._0.name), sourcesAreAllEventSources$1 ? null : React.createElement(React.Fragment, undefined, " for ", React.createElement("b", undefined, Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(sources$5), (function (sourceId) {
                                        return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                                      })), AvoConfig.getSourceName).join(", "))));
    }
    if (variant === "UpdateEventDescription") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "updated the description to " + match.VAL[1]
                });
    }
    if (variant === "DuplicateMetric") {
      var metric = ModelUtils.getMetricByIdWithArchive(match.VAL[1], model);
      if (metric !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "added the event to the metric ", React.createElement("b", undefined, metric.name));
      } else {
        return null;
      }
    }
    if (variant === "AddEventToMetric") {
      var metric$1 = ModelUtils.getMetricByIdWithArchive(match.VAL[0], model);
      if (metric$1 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "added the event to the metric ", React.createElement("b", undefined, metric$1.name));
      } else {
        return null;
      }
    }
    if (variant === "ExcludeDestinationFromEventSource") {
      var match$28 = match.VAL;
      var sourceId = match$28[1];
      var match$29 = ModelUtils.getSourceByIdWithArchive(sourceId, model);
      var match$30 = ModelUtils.getDestinationByIdWithArchive(match$28[2], model);
      if (match$29 !== undefined && match$30 !== undefined) {
        return React.createElement(ObjectActivityLogAction.make, {
                    action: action,
                    users: users$1,
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "source",
                          VAL: [
                            sourceId,
                            "overview"
                          ]
                        }),
                    openBranches: openBranches,
                    children: null
                  }, "stopped sending this event to ", React.createElement("b", undefined, ModelUtils.getDestinationName(match$30)), " from ", React.createElement("b", undefined, AvoConfig.getSourceName(match$29)));
      } else {
        return null;
      }
    }
    if (variant === "UpdateTriggerDescription") {
      var match$31 = match.VAL;
      return React.createElement(ObjectActivityLogAction.make, {
                  action: action,
                  users: users$1,
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          $$event.id,
                          undefined,
                          match$31[1],
                          false
                        ]
                      }),
                  openBranches: openBranches,
                  children: null
                }, "updated the description for the trigger ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$31[2])));
    }
    if (variant === "AddPropertyToWhitelist") {
      var match$32 = match.VAL;
      var match$33 = ModelUtils.getPropertyByIdWithArchive(match$32[1], model);
      if (match$33 !== undefined && match$33.TAG !== "PropertyRef") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "started sending the property ", React.createElement("b", undefined, match$33._0.name), " to ", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$32[2])), " with this event");
      } else {
        return null;
      }
    }
    if (variant === "CopyChangesToBranch") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "copied changes from ", React.createElement("b", undefined, match.VAL[1]));
    }
    if (variant === "AddTriggerSource") {
      var match$34 = match.VAL;
      var triggerId$3 = match$34[1];
      var match$35 = ModelUtils.getSourceByIdWithArchive(match$34[2], model);
      var match$36 = ModelUtils.getTriggerByIdAndEventId(triggerId$3, $$event.id, model);
      if (match$35 !== undefined && match$36 !== undefined) {
        return React.createElement(ObjectActivityLogAction.make, {
                    action: action,
                    users: users$1,
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            $$event.id,
                            undefined,
                            triggerId$3,
                            false
                          ]
                        }),
                    openBranches: openBranches,
                    children: null
                  }, "added ", React.createElement("b", undefined, AvoConfig.getSourceName(match$35)), " as a source for the trigger with the description ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$36.description)));
      } else {
        return null;
      }
    }
    if (variant === "IncludeDestinationInEventSource") {
      var match$37 = match.VAL;
      var sourceId$1 = match$37[1];
      var match$38 = ModelUtils.getSourceByIdWithArchive(sourceId$1, model);
      var match$39 = ModelUtils.getDestinationByIdWithArchive(match$37[2], model);
      if (match$38 !== undefined && match$39 !== undefined) {
        return React.createElement(ObjectActivityLogAction.make, {
                    action: action,
                    users: users$1,
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "source",
                          VAL: [
                            sourceId$1,
                            "overview"
                          ]
                        }),
                    openBranches: openBranches,
                    children: null
                  }, "started sending this event to ", React.createElement("b", undefined, ModelUtils.getDestinationName(match$39)), " from ", React.createElement("b", undefined, AvoConfig.getSourceName(match$38)));
      } else {
        return null;
      }
    }
    if (variant === "AddGroupTypeToLogEvent") {
      var groupTypeName$1 = ModelUtils.getGroupTypeName(match.VAL[0], model);
      if (groupTypeName$1 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "started associating this event with the ", React.createElement("b", undefined, groupTypeName$1), " group type");
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertyPinnedValue") {
      var match$40 = match.VAL;
      var match$41 = ModelUtils.getPropertyById(match$40[1], model);
      if (match$41 !== undefined && match$41.TAG !== "PropertyRef") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "pinned the value of ", React.createElement("b", undefined, match$41._0.name), " to ", React.createElement("b", undefined, PropertyUtils.printPinnedValue(match$40[2])));
      } else {
        return null;
      }
    }
    if (variant === "RemoveUserFromGroup") {
      var groupTypeName$2 = ModelUtils.getGroupTypeName(match.VAL[0], model);
      if (groupTypeName$2 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "removed associating the user with the ", React.createElement("b", undefined, groupTypeName$2), " group type");
      } else {
        return null;
      }
    }
    if (variant === "DuplicateEvent") {
      return Belt_Option.mapWithDefault(ModelUtils.getEventByIdWithArchive(match.VAL[0][0], model), null, (function (param) {
                    return React.createElement(ObjectActivityLogAction.Content.make, {
                                action: action,
                                users: users$1,
                                openBranches: openBranches,
                                children: null
                              }, "duplicated the event from ", React.createElement("i", undefined, param.name));
                  }));
    }
    if (variant === "CreateEventVariant") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "created the variant: ", React.createElement("b", undefined, match.VAL[1]));
    }
    if (variant === "RemoveEventType") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "removed the event type ", React.createElement("b", undefined, TrackingPlanModel.eventTypeToJs(match.VAL[1])));
    }
    if (variant === "UpdateEventUniqueName") {
      name = match.VAL[1];
    } else {
      if (variant === "UpdateEventName") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "changed the name to ", React.createElement("b", undefined, match.VAL[1]));
      }
      if (variant === "RemoveGroupTypeFromLogEvent") {
        var groupTypeName$3 = ModelUtils.getGroupTypeName(match.VAL[0], model);
        if (groupTypeName$3 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "stopped associating this event with the ", React.createElement("b", undefined, groupTypeName$3), " group type");
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyGroupFromEvent") {
        var group = ModelUtils.getPropertyBundleByIdWithArchive(match.VAL[1], model);
        if (group !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "removed the property bundle ", React.createElement("b", undefined, group.name));
        } else {
          return null;
        }
      }
      if (variant === "ConfigureInspectorValidation") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "configured the event for Inspector validation"
                  });
      }
      if (variant === "RemoveEventFromMetric") {
        var metric$2 = ModelUtils.getMetricByIdWithArchive(match.VAL[0], model);
        if (metric$2 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "removed the event from the metric ", React.createElement("b", undefined, metric$2.name));
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyPinnedValue") {
        var match$42 = ModelUtils.getPropertyById(match.VAL[1], model);
        if (match$42 !== undefined && match$42.TAG !== "PropertyRef") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "removed the pinned value of ", React.createElement("b", undefined, match$42._0.name));
        } else {
          return null;
        }
      }
      if (variant === "RemoveEventTrigger") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "removed a trigger"
                  });
      }
      if (variant === "RemoveTriggerSource") {
        var match$43 = match.VAL;
        var triggerId$4 = match$43[1];
        var match$44 = ModelUtils.getSourceByIdWithArchive(match$43[2], model);
        var match$45 = ModelUtils.getTriggerByIdAndEventId(triggerId$4, $$event.id, model);
        if (match$44 !== undefined && match$45 !== undefined) {
          return React.createElement(ObjectActivityLogAction.make, {
                      action: action,
                      users: users$1,
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "event",
                            VAL: [
                              $$event.id,
                              undefined,
                              triggerId$4,
                              false
                            ]
                          }),
                      openBranches: openBranches,
                      children: null
                    }, "removed ", React.createElement("b", undefined, AvoConfig.getSourceName(match$44)), " as a source for the trigger with the description ", React.createElement("b", undefined, QuillToPlainText.quillToPlainText(match$45.description)));
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyAbsence") {
        var match$46 = match.VAL;
        var maybeEventSourceAbsence = match$46[2];
        var propertyAbsenceUpdateType = match$46[1];
        var match$47 = ModelUtils.getPropertyByIdWithArchive(match$46[0], model);
        if (match$47 === undefined) {
          return null;
        }
        if (match$47.TAG === "PropertyRef") {
          return null;
        }
        var tmp$2;
        tmp$2 = maybeEventSourceAbsence !== undefined ? (
            typeof maybeEventSourceAbsence !== "object" ? "never sent" : "sometimes sent"
          ) : "always sent";
        var tmp$3;
        if (typeof propertyAbsenceUpdateType !== "object") {
          tmp$3 = React.createElement(React.Fragment, undefined, " on ", React.createElement("b", undefined, "all events"));
        } else {
          switch (propertyAbsenceUpdateType.TAG) {
            case "Event" :
                tmp$3 = " on this event";
                break;
            case "Source" :
                var source$3 = ModelUtils.getSourceByIdWithArchive(propertyAbsenceUpdateType._0, model);
                tmp$3 = source$3 !== undefined ? React.createElement(React.Fragment, undefined, " from the ", React.createElement("b", undefined, AvoConfig.getSourceName(source$3)), " source") : " from one source";
                break;
            case "EventSource" :
                var match$48 = ModelUtils.getEventByIdWithArchive(propertyAbsenceUpdateType._0, model);
                var match$49 = ModelUtils.getSourceByIdWithArchive(propertyAbsenceUpdateType._1, model);
                tmp$3 = match$48 !== undefined && match$49 !== undefined ? React.createElement(React.Fragment, undefined, " on this event when sent from the ", React.createElement("b", undefined, AvoConfig.getSourceName(match$49)), " source") : " on one event and source";
                break;
            
          }
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "set the property ", React.createElement("b", undefined, match$47._0.name), " to be ", React.createElement("b", undefined, tmp$2), tmp$3);
      }
      if (variant === "Unarchive") {
        var match$50 = match.VAL;
        if (typeof match$50 !== "object") {
          return null;
        }
        var variant$2 = match$50.NAME;
        if (variant$2 === "Event") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "unarchived the event"
                    });
        }
        if (variant$2 !== "EventVariant") {
          return null;
        }
        var match$51 = ModelUtils.getEventVariantByIdWithArchive(model, match$50.VAL);
        if (match$51 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "unarchived the variant ", React.createElement("b", undefined, match$51.nameSuffix));
        } else {
          return null;
        }
      }
      if (variant === "CompletedImport") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "imported the event"
                  });
      }
      if (variant !== "AddPropertyGroupToEvent") {
        return null;
      }
      var group$1 = ModelUtils.getPropertyBundleByIdWithArchive(match.VAL[1], model);
      if (group$1 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "added the property bundle ", React.createElement("b", undefined, group$1.name));
      } else {
        return null;
      }
    }
  }
  return React.createElement(ObjectActivityLogAction.Content.make, {
              action: action,
              users: users$1,
              openBranches: openBranches,
              children: null
            }, "set unique name to ", React.createElement("b", undefined, name));
}

var make = EventActivityItem;

export {
  make ,
}
/* react Not a pure module */
