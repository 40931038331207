// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AvoModel from "../avoModel.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IntegrationUtils from "../../../api/functions/src/IntegrationUtils.mjs";

function findPropertyBundlesPublishedToAdobeExperiencePlatform(model) {
  var adobeExperiencePlatformItegrations = Belt_List.keepU(model.integrations, (function (integration) {
          var match = integration.config;
          if (match !== undefined && match.TAG === "AdobeExperiencePlatform") {
            return true;
          } else {
            return false;
          }
        }));
  var affectedEvents = Belt_List.flatten(Belt_List.mapU(adobeExperiencePlatformItegrations, (function (adobeExperiencePlatformIntegration) {
              if (adobeExperiencePlatformIntegration.filters === /* [] */0) {
                return AvoModel.filterExcludedEventsBySourcesFromModel(model, IntegrationUtils.findSources(IntegrationUtils.findDestinations(adobeExperiencePlatformIntegration, model), model)).events;
              } else {
                return AvoModel.filterExcludedEvents(model.events, adobeExperiencePlatformIntegration.filters);
              }
            })));
  return Belt_List.flatten(Belt_List.mapU(affectedEvents, (function ($$event) {
                    return Belt_List.keepMap($$event.propertyBundles, (function (bundleRef) {
                                  return AvoModel.resolvePropertyBundleRef(model.propertyBundles, bundleRef);
                                }));
                  })));
}

function isBreakingChange(actions, model) {
  var propertyBundleIdsWhereAPropertyWasRemoved = Belt_Array.keepMapU(actions, (function (param) {
          var action = param[0];
          if (typeof action === "object" && action.NAME === "RemovePropertyFromGroup") {
            return action.VAL[0];
          }
          
        }));
  var propertyBundlesConnectedToAdobeExperiencePlatform = findPropertyBundlesPublishedToAdobeExperiencePlatform(model);
  var isRemovingAPropertyFromIncludedBundles = Belt_List.someU(propertyBundlesConnectedToAdobeExperiencePlatform, (function (adobeConnectedPropertyBundle) {
          return Belt_Array.someU(propertyBundleIdsWhereAPropertyWasRemoved, (function (changedPropertyBundleId) {
                        return adobeConnectedPropertyBundle.id === changedPropertyBundleId;
                      }));
        }));
  var propertyIdsChangingType = Belt_Array.keepMapU(actions, (function (param) {
          var action = param[0];
          if (typeof action === "object" && action.NAME === "UpdatePropertyType") {
            return action.VAL[0];
          }
          
        }));
  var doesAdobeConnectedPropertyBundleHavePropertyIdsChangingType = Belt_Array.someU(propertyIdsChangingType, (function (propertyIdChangingType) {
          return Belt_List.someU(propertyBundlesConnectedToAdobeExperiencePlatform, (function (adobeConnectedPropertyBundle) {
                        return Belt_List.someU(adobeConnectedPropertyBundle.properties, (function (bundlePropertyId) {
                                      return bundlePropertyId === propertyIdChangingType;
                                    }));
                      }));
        }));
  if (isRemovingAPropertyFromIncludedBundles) {
    return true;
  } else {
    return doesAdobeConnectedPropertyBundleHavePropertyIdsChangingType;
  }
}

export {
  isBreakingChange ,
}
/* AvoModel Not a pure module */
