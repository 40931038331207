// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as AppVersionComparisonUtils from "../../../api/functions/src/AppVersionComparisonUtils.mjs";

function getHigherVersion(oldVersion, newVersion) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldVersion, newVersion);
  switch (match) {
    case "Greater" :
    case "Equal" :
        return oldVersion;
    case "Smaller" :
        return newVersion;
    
  }
}

function mergeAlwaysSeenCorrectlyLatestVersionWithNew(newStatus, correctCount, totalCount, lastSeenCorrectly, oldSourceHighestVersion, newSourceHighestVersion) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, newSourceHighestVersion);
  switch (match) {
    case "Greater" :
        return {
                TAG: "AlwaysSeenCorrectlyLatestVersion",
                _0: correctCount,
                _1: totalCount,
                _2: lastSeenCorrectly
              };
    case "Equal" :
        if (typeof newStatus !== "object") {
          return {
                  TAG: "AlwaysSeenCorrectlyLatestVersion",
                  _0: correctCount,
                  _1: totalCount,
                  _2: lastSeenCorrectly
                };
        }
        switch (newStatus.TAG) {
          case "AlwaysSeenCorrectlyLatestVersion" :
              return {
                      TAG: "AlwaysSeenCorrectlyLatestVersion",
                      _0: correctCount + newStatus._0 | 0,
                      _1: totalCount + newStatus._1 | 0,
                      _2: newStatus._2
                    };
          case "SometimesSeenCorrectlyLatestVersion" :
              return {
                      TAG: "SometimesSeenCorrectlyLatestVersion",
                      _0: correctCount + newStatus._0 | 0,
                      _1: totalCount + newStatus._1 | 0,
                      _2: newStatus._2,
                      _3: newStatus._3
                    };
          case "NeverSeenCorrectlyLatestVersion" :
              return {
                      TAG: "SometimesSeenCorrectlyLatestVersion",
                      _0: newStatus._0 + correctCount | 0,
                      _1: newStatus._1 + totalCount | 0,
                      _2: lastSeenCorrectly,
                      _3: newStatus._2
                    };
          default:
            return {
                    TAG: "AlwaysSeenCorrectlyLatestVersion",
                    _0: correctCount,
                    _1: totalCount,
                    _2: lastSeenCorrectly
                  };
        }
    case "Smaller" :
        if (typeof newStatus !== "object") {
          if (oldSourceHighestVersion !== undefined) {
            return {
                    TAG: "AlwaysSeenCorrectlyInOlderVersion",
                    _0: correctCount,
                    _1: totalCount,
                    _2: oldSourceHighestVersion,
                    _3: lastSeenCorrectly
                  };
          } else {
            return {
                    TAG: "AlwaysSeenCorrectlyInOlderVersion",
                    _0: correctCount,
                    _1: totalCount,
                    _2: "unknown",
                    _3: lastSeenCorrectly
                  };
          }
        }
        switch (newStatus.TAG) {
          case "AlwaysSeenCorrectlyInOlderVersion" :
              var oldVersion = newStatus._2;
              var match$1 = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, oldVersion);
              switch (match$1) {
                case "Greater" :
                    return {
                            TAG: "AlwaysSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: Belt_Option.getExn(oldSourceHighestVersion),
                            _3: lastSeenCorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "AlwaysSeenCorrectlyInOlderVersion",
                            _0: newStatus._0 + correctCount | 0,
                            _1: newStatus._1 + totalCount | 0,
                            _2: oldVersion,
                            _3: newStatus._3
                          };
                case "Smaller" :
                    return newStatus;
                
              }
          case "SometimesSeenCorrectlyInOlderVersion" :
              var oldVersion$1 = newStatus._2;
              var match$2 = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, oldVersion$1);
              switch (match$2) {
                case "Greater" :
                    return {
                            TAG: "AlwaysSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: Belt_Option.getExn(oldSourceHighestVersion),
                            _3: lastSeenCorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newStatus._0 + correctCount | 0,
                            _1: newStatus._1 + totalCount | 0,
                            _2: oldVersion$1,
                            _3: newStatus._3,
                            _4: newStatus._4
                          };
                case "Smaller" :
                    return newStatus;
                
              }
          case "NeverSeenCorrectlyInOlderVersion" :
              var oldVersion$2 = newStatus._2;
              var match$3 = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, oldVersion$2);
              switch (match$3) {
                case "Greater" :
                    return {
                            TAG: "AlwaysSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: Belt_Option.getExn(oldSourceHighestVersion),
                            _3: lastSeenCorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newStatus._0 + correctCount | 0,
                            _1: newStatus._1 + totalCount | 0,
                            _2: oldVersion$2,
                            _3: lastSeenCorrectly,
                            _4: newStatus._3
                          };
                case "Smaller" :
                    return newStatus;
                
              }
          default:
            return newStatus;
        }
    
  }
}

function mergeSometimesSeenCorrectlyLatestVersionWithNew(newStatus, correctCount, totalCount, lastSeenCorrectly, lastSeenIncorrectly, oldSourceHighestVersion, newSourceHighestVersion) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, newSourceHighestVersion);
  switch (match) {
    case "Greater" :
        return {
                TAG: "SometimesSeenCorrectlyLatestVersion",
                _0: correctCount,
                _1: totalCount,
                _2: lastSeenCorrectly,
                _3: lastSeenIncorrectly
              };
    case "Equal" :
        if (typeof newStatus !== "object") {
          return {
                  TAG: "SometimesSeenCorrectlyLatestVersion",
                  _0: correctCount,
                  _1: totalCount,
                  _2: lastSeenCorrectly,
                  _3: lastSeenIncorrectly
                };
        }
        switch (newStatus.TAG) {
          case "AlwaysSeenCorrectlyLatestVersion" :
              return {
                      TAG: "SometimesSeenCorrectlyLatestVersion",
                      _0: correctCount + newStatus._0 | 0,
                      _1: totalCount + newStatus._1 | 0,
                      _2: newStatus._2,
                      _3: lastSeenIncorrectly
                    };
          case "SometimesSeenCorrectlyLatestVersion" :
              return {
                      TAG: "SometimesSeenCorrectlyLatestVersion",
                      _0: correctCount + newStatus._0 | 0,
                      _1: totalCount + newStatus._1 | 0,
                      _2: newStatus._2,
                      _3: newStatus._3
                    };
          case "NeverSeenCorrectlyLatestVersion" :
              return {
                      TAG: "SometimesSeenCorrectlyLatestVersion",
                      _0: newStatus._0 + correctCount | 0,
                      _1: newStatus._1 + totalCount | 0,
                      _2: lastSeenCorrectly,
                      _3: newStatus._2
                    };
          default:
            return {
                    TAG: "SometimesSeenCorrectlyLatestVersion",
                    _0: correctCount,
                    _1: totalCount,
                    _2: lastSeenCorrectly,
                    _3: lastSeenIncorrectly
                  };
        }
    case "Smaller" :
        if (typeof newStatus !== "object") {
          if (oldSourceHighestVersion !== undefined) {
            return {
                    TAG: "SometimesSeenCorrectlyInOlderVersion",
                    _0: correctCount,
                    _1: totalCount,
                    _2: oldSourceHighestVersion,
                    _3: lastSeenCorrectly,
                    _4: lastSeenIncorrectly
                  };
          } else {
            return {
                    TAG: "SometimesSeenCorrectlyInOlderVersion",
                    _0: correctCount,
                    _1: totalCount,
                    _2: "unknown",
                    _3: lastSeenCorrectly,
                    _4: lastSeenIncorrectly
                  };
          }
        }
        switch (newStatus.TAG) {
          case "AlwaysSeenCorrectlyInOlderVersion" :
              var oldVersion = newStatus._2;
              var match$1 = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, oldVersion);
              switch (match$1) {
                case "Greater" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: Belt_Option.getExn(oldSourceHighestVersion),
                            _3: lastSeenCorrectly,
                            _4: lastSeenIncorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newStatus._0 + correctCount | 0,
                            _1: newStatus._1 + totalCount | 0,
                            _2: oldVersion,
                            _3: newStatus._3,
                            _4: lastSeenIncorrectly
                          };
                case "Smaller" :
                    return newStatus;
                
              }
          case "SometimesSeenCorrectlyInOlderVersion" :
              var oldVersion$1 = newStatus._2;
              var match$2 = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, oldVersion$1);
              switch (match$2) {
                case "Greater" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: Belt_Option.getExn(oldSourceHighestVersion),
                            _3: lastSeenCorrectly,
                            _4: lastSeenIncorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newStatus._0 + correctCount | 0,
                            _1: newStatus._1 + totalCount | 0,
                            _2: oldVersion$1,
                            _3: newStatus._3,
                            _4: newStatus._4
                          };
                case "Smaller" :
                    return newStatus;
                
              }
          case "NeverSeenCorrectlyInOlderVersion" :
              var oldVersion$2 = newStatus._2;
              var match$3 = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, oldVersion$2);
              switch (match$3) {
                case "Greater" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: Belt_Option.getExn(oldSourceHighestVersion),
                            _3: lastSeenCorrectly,
                            _4: lastSeenIncorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newStatus._0 + correctCount | 0,
                            _1: newStatus._1 + totalCount | 0,
                            _2: oldVersion$2,
                            _3: lastSeenCorrectly,
                            _4: newStatus._3
                          };
                case "Smaller" :
                    return newStatus;
                
              }
          default:
            return newStatus;
        }
    
  }
}

function mergeNeverSeenCorrectlyLatestVersionWithNew(newStatus, correctCount, totalCount, lastSeenIncorrectly, oldSourceHighestVersion, newSourceHighestVersion) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, newSourceHighestVersion);
  switch (match) {
    case "Greater" :
        return {
                TAG: "NeverSeenCorrectlyLatestVersion",
                _0: 0,
                _1: totalCount,
                _2: lastSeenIncorrectly
              };
    case "Equal" :
        if (typeof newStatus !== "object") {
          return {
                  TAG: "NeverSeenCorrectlyLatestVersion",
                  _0: 0,
                  _1: totalCount,
                  _2: lastSeenIncorrectly
                };
        }
        switch (newStatus.TAG) {
          case "AlwaysSeenCorrectlyLatestVersion" :
              return {
                      TAG: "SometimesSeenCorrectlyLatestVersion",
                      _0: correctCount + newStatus._0 | 0,
                      _1: totalCount + newStatus._1 | 0,
                      _2: newStatus._2,
                      _3: lastSeenIncorrectly
                    };
          case "SometimesSeenCorrectlyLatestVersion" :
              return {
                      TAG: "SometimesSeenCorrectlyLatestVersion",
                      _0: correctCount + newStatus._0 | 0,
                      _1: totalCount + newStatus._1 | 0,
                      _2: newStatus._2,
                      _3: newStatus._3
                    };
          case "NeverSeenCorrectlyLatestVersion" :
              return {
                      TAG: "NeverSeenCorrectlyLatestVersion",
                      _0: 0,
                      _1: newStatus._1 + totalCount | 0,
                      _2: newStatus._2
                    };
          default:
            return {
                    TAG: "NeverSeenCorrectlyLatestVersion",
                    _0: 0,
                    _1: totalCount,
                    _2: lastSeenIncorrectly
                  };
        }
    case "Smaller" :
        if (typeof newStatus !== "object") {
          if (oldSourceHighestVersion !== undefined) {
            return {
                    TAG: "NeverSeenCorrectlyInOlderVersion",
                    _0: correctCount,
                    _1: totalCount,
                    _2: oldSourceHighestVersion,
                    _3: lastSeenIncorrectly
                  };
          } else {
            return {
                    TAG: "NeverSeenCorrectlyInOlderVersion",
                    _0: correctCount,
                    _1: totalCount,
                    _2: "unknown",
                    _3: lastSeenIncorrectly
                  };
          }
        }
        switch (newStatus.TAG) {
          case "AlwaysSeenCorrectlyInOlderVersion" :
              var oldVersion = newStatus._2;
              var match$1 = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, oldVersion);
              switch (match$1) {
                case "Greater" :
                    return {
                            TAG: "NeverSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: Belt_Option.getExn(oldSourceHighestVersion),
                            _3: lastSeenIncorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newStatus._0 + correctCount | 0,
                            _1: newStatus._1 + totalCount | 0,
                            _2: oldVersion,
                            _3: newStatus._3,
                            _4: lastSeenIncorrectly
                          };
                case "Smaller" :
                    return newStatus;
                
              }
          case "SometimesSeenCorrectlyInOlderVersion" :
              var oldVersion$1 = newStatus._2;
              var match$2 = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, oldVersion$1);
              switch (match$2) {
                case "Greater" :
                    return {
                            TAG: "NeverSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: Belt_Option.getExn(oldSourceHighestVersion),
                            _3: lastSeenIncorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newStatus._0 + correctCount | 0,
                            _1: newStatus._1 + totalCount | 0,
                            _2: oldVersion$1,
                            _3: newStatus._3,
                            _4: newStatus._4
                          };
                case "Smaller" :
                    return newStatus;
                
              }
          case "NeverSeenCorrectlyInOlderVersion" :
              var oldVersion$2 = newStatus._2;
              var match$3 = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, oldVersion$2);
              switch (match$3) {
                case "Greater" :
                    return {
                            TAG: "NeverSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: Belt_Option.getExn(oldSourceHighestVersion),
                            _3: lastSeenIncorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "NeverSeenCorrectlyInOlderVersion",
                            _0: newStatus._0 + correctCount | 0,
                            _1: newStatus._1 + totalCount | 0,
                            _2: oldVersion$2,
                            _3: newStatus._3
                          };
                case "Smaller" :
                    return newStatus;
                
              }
          default:
            return newStatus;
        }
    
  }
}

function mergeAlwaysSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion(oldCorrect, oldTotal, oldEventVersion, oldLastSeen, newCorrect, newTotal, newEventVersion, newLastSeen) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newEventVersion);
  switch (match) {
    case "Greater" :
        return {
                TAG: "AlwaysSeenCorrectlyInOlderVersion",
                _0: oldCorrect,
                _1: oldTotal,
                _2: oldEventVersion,
                _3: oldLastSeen
              };
    case "Equal" :
        return {
                TAG: "AlwaysSeenCorrectlyInOlderVersion",
                _0: newCorrect + oldCorrect | 0,
                _1: newTotal + oldTotal | 0,
                _2: newEventVersion,
                _3: newLastSeen
              };
    case "Smaller" :
        return {
                TAG: "AlwaysSeenCorrectlyInOlderVersion",
                _0: newCorrect,
                _1: newTotal,
                _2: newEventVersion,
                _3: newLastSeen
              };
    
  }
}

function mergeAlwaysSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion(oldCorrect, oldTotal, oldEventVersion, oldLastSeen, newCorrect, newTotal, newEventVersion, newLastSeenCorrect, newLastSeenIncorrect) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newEventVersion);
  switch (match) {
    case "Greater" :
        return {
                TAG: "AlwaysSeenCorrectlyInOlderVersion",
                _0: oldCorrect,
                _1: oldTotal,
                _2: oldEventVersion,
                _3: oldLastSeen
              };
    case "Equal" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: newCorrect + oldCorrect | 0,
                _1: newTotal + oldTotal | 0,
                _2: newEventVersion,
                _3: newLastSeenCorrect,
                _4: newLastSeenIncorrect
              };
    case "Smaller" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: newCorrect,
                _1: newTotal,
                _2: newEventVersion,
                _3: newLastSeenCorrect,
                _4: newLastSeenIncorrect
              };
    
  }
}

function mergeAlwaysSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion(oldCorrect, oldTotal, oldEventVersion, oldLastSeen, newCorrect, newTotal, newEventVersion, newLastSeenIncorrect) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newEventVersion);
  switch (match) {
    case "Greater" :
        return {
                TAG: "AlwaysSeenCorrectlyInOlderVersion",
                _0: oldCorrect,
                _1: oldTotal,
                _2: oldEventVersion,
                _3: oldLastSeen
              };
    case "Equal" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: newCorrect + oldCorrect | 0,
                _1: newTotal + oldTotal | 0,
                _2: newEventVersion,
                _3: oldLastSeen,
                _4: newLastSeenIncorrect
              };
    case "Smaller" :
        return {
                TAG: "NeverSeenCorrectlyInOlderVersion",
                _0: newCorrect,
                _1: newTotal,
                _2: newEventVersion,
                _3: newLastSeenIncorrect
              };
    
  }
}

function compareOldAlwaysSeenWithNewStatusWhenSourceVersionsAreLessOrEqual(newStatus, correctCount, totalCount, oldEventVersion, lastSeenCorrectly) {
  if (typeof newStatus !== "object") {
    return {
            TAG: "AlwaysSeenCorrectlyInOlderVersion",
            _0: correctCount,
            _1: totalCount,
            _2: oldEventVersion,
            _3: lastSeenCorrectly
          };
  }
  switch (newStatus.TAG) {
    case "AlwaysSeenCorrectlyInOlderVersion" :
        return mergeAlwaysSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenCorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3);
    case "SometimesSeenCorrectlyInOlderVersion" :
        return mergeAlwaysSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenCorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3, newStatus._4);
    case "NeverSeenCorrectlyInOlderVersion" :
        return mergeAlwaysSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenCorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3);
    default:
      return newStatus;
  }
}

function mergeAlwaysSeenCorrectOlderVersionWithNewStatus(newStatus, correctCount, totalCount, oldEventVersion, lastSeenCorrectly, oldSourceHighestVersion, newSourceHighestVersion) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, newSourceHighestVersion);
  switch (match) {
    case "Greater" :
        if (typeof newStatus !== "object") {
          return {
                  TAG: "AlwaysSeenCorrectlyInOlderVersion",
                  _0: correctCount,
                  _1: totalCount,
                  _2: oldEventVersion,
                  _3: lastSeenCorrectly
                };
        }
        switch (newStatus.TAG) {
          case "AlwaysSeenCorrectlyLatestVersion" :
              var newLastSeen = newStatus._2;
              var newTotal = newStatus._1;
              var newCorrect = newStatus._0;
              var match$1 = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newSourceHighestVersion);
              switch (match$1) {
                case "Greater" :
                    return {
                            TAG: "AlwaysSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: oldEventVersion,
                            _3: lastSeenCorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "AlwaysSeenCorrectlyInOlderVersion",
                            _0: newCorrect + correctCount | 0,
                            _1: newTotal + totalCount | 0,
                            _2: oldEventVersion,
                            _3: newLastSeen
                          };
                case "Smaller" :
                    return {
                            TAG: "AlwaysSeenCorrectlyInOlderVersion",
                            _0: newCorrect,
                            _1: newTotal,
                            _2: Belt_Option.getExn(newSourceHighestVersion),
                            _3: newLastSeen
                          };
                
              }
          case "SometimesSeenCorrectlyLatestVersion" :
              var newLastSeenIncorrect = newStatus._3;
              var newLastSeenCorrect = newStatus._2;
              var newTotal$1 = newStatus._1;
              var newCorrect$1 = newStatus._0;
              var match$2 = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newSourceHighestVersion);
              switch (match$2) {
                case "Greater" :
                    return {
                            TAG: "AlwaysSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: oldEventVersion,
                            _3: lastSeenCorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newCorrect$1 + correctCount | 0,
                            _1: newTotal$1 + totalCount | 0,
                            _2: oldEventVersion,
                            _3: newLastSeenCorrect,
                            _4: newLastSeenIncorrect
                          };
                case "Smaller" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newCorrect$1,
                            _1: newTotal$1,
                            _2: Belt_Option.getExn(newSourceHighestVersion),
                            _3: newLastSeenCorrect,
                            _4: newLastSeenIncorrect
                          };
                
              }
          case "NeverSeenCorrectlyLatestVersion" :
              var newLastSeenIncorrect$1 = newStatus._2;
              var newTotal$2 = newStatus._1;
              var match$3 = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newSourceHighestVersion);
              switch (match$3) {
                case "Greater" :
                    return {
                            TAG: "AlwaysSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: oldEventVersion,
                            _3: lastSeenCorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newStatus._0 + correctCount | 0,
                            _1: newTotal$2 + totalCount | 0,
                            _2: oldEventVersion,
                            _3: lastSeenCorrectly,
                            _4: newLastSeenIncorrect$1
                          };
                case "Smaller" :
                    return {
                            TAG: "NeverSeenCorrectlyInOlderVersion",
                            _0: 0,
                            _1: newTotal$2,
                            _2: Belt_Option.getExn(newSourceHighestVersion),
                            _3: newLastSeenIncorrect$1
                          };
                
              }
          case "AlwaysSeenCorrectlyInOlderVersion" :
              return mergeAlwaysSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenCorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3);
          case "SometimesSeenCorrectlyInOlderVersion" :
              return mergeAlwaysSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenCorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3, newStatus._4);
          case "NeverSeenCorrectlyInOlderVersion" :
              return mergeAlwaysSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenCorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3);
          
        }
    case "Equal" :
    case "Smaller" :
        return compareOldAlwaysSeenWithNewStatusWhenSourceVersionsAreLessOrEqual(newStatus, correctCount, totalCount, oldEventVersion, lastSeenCorrectly);
    
  }
}

var AlwaysSeenCorrectOlderVersion = {
  mergeAlwaysSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion: mergeAlwaysSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion,
  mergeAlwaysSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion: mergeAlwaysSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion,
  mergeAlwaysSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion: mergeAlwaysSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion,
  compareOldAlwaysSeenWithNewStatusWhenSourceVersionsAreLessOrEqual: compareOldAlwaysSeenWithNewStatusWhenSourceVersionsAreLessOrEqual,
  mergeAlwaysSeenCorrectOlderVersionWithNewStatus: mergeAlwaysSeenCorrectOlderVersionWithNewStatus
};

function mergeSometimesSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion(oldCorrect, oldTotal, oldEventVersion, oldLastSeenCorrect, oldLastSeenIncorrect, newCorrect, newTotal, newEventVersion, newLastSeen) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newEventVersion);
  switch (match) {
    case "Greater" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: oldCorrect,
                _1: oldTotal,
                _2: oldEventVersion,
                _3: oldLastSeenCorrect,
                _4: oldLastSeenIncorrect
              };
    case "Equal" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: newCorrect + oldCorrect | 0,
                _1: newTotal + oldTotal | 0,
                _2: newEventVersion,
                _3: newLastSeen,
                _4: oldLastSeenIncorrect
              };
    case "Smaller" :
        return {
                TAG: "AlwaysSeenCorrectlyInOlderVersion",
                _0: newCorrect,
                _1: newTotal,
                _2: newEventVersion,
                _3: newLastSeen
              };
    
  }
}

function mergeSometimesSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion(oldCorrect, oldTotal, oldEventVersion, oldLastSeenCorrect, oldLastSeenIncorrect, newCorrect, newTotal, newEventVersion, newLastSeenCorrect, newLastSeenIncorrect) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newEventVersion);
  switch (match) {
    case "Greater" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: oldCorrect,
                _1: oldTotal,
                _2: oldEventVersion,
                _3: oldLastSeenCorrect,
                _4: oldLastSeenIncorrect
              };
    case "Equal" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: newCorrect + oldCorrect | 0,
                _1: newTotal + oldTotal | 0,
                _2: newEventVersion,
                _3: newLastSeenCorrect,
                _4: newLastSeenIncorrect
              };
    case "Smaller" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: newCorrect,
                _1: newTotal,
                _2: newEventVersion,
                _3: newLastSeenCorrect,
                _4: newLastSeenIncorrect
              };
    
  }
}

function mergeSometimesSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion(oldCorrect, oldTotal, oldEventVersion, oldLastSeenCorrect, oldLastSeenIncorrect, newCorrect, newTotal, newEventVersion, newLastSeenIncorrect) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newEventVersion);
  switch (match) {
    case "Greater" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: oldCorrect,
                _1: oldTotal,
                _2: oldEventVersion,
                _3: oldLastSeenCorrect,
                _4: oldLastSeenIncorrect
              };
    case "Equal" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: newCorrect + oldCorrect | 0,
                _1: newTotal + oldTotal | 0,
                _2: newEventVersion,
                _3: oldLastSeenCorrect,
                _4: newLastSeenIncorrect
              };
    case "Smaller" :
        return {
                TAG: "NeverSeenCorrectlyInOlderVersion",
                _0: 0,
                _1: newTotal,
                _2: newEventVersion,
                _3: newLastSeenIncorrect
              };
    
  }
}

function compareOldSometimesSeenWithNewStatusWhenSourceVersionsAreLessOrEqual(newStatus, correctCount, totalCount, oldEventVersion, lastSeenCorrectly, lastSeenIncorrectly) {
  if (typeof newStatus !== "object") {
    return {
            TAG: "SometimesSeenCorrectlyInOlderVersion",
            _0: correctCount,
            _1: totalCount,
            _2: oldEventVersion,
            _3: lastSeenCorrectly,
            _4: lastSeenIncorrectly
          };
  }
  switch (newStatus.TAG) {
    case "AlwaysSeenCorrectlyInOlderVersion" :
        return mergeSometimesSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenCorrectly, lastSeenIncorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3);
    case "SometimesSeenCorrectlyInOlderVersion" :
        return mergeSometimesSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenCorrectly, lastSeenIncorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3, newStatus._4);
    case "NeverSeenCorrectlyInOlderVersion" :
        return mergeSometimesSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenCorrectly, lastSeenIncorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3);
    default:
      return newStatus;
  }
}

function mergeSometimesSeenCorrectOlderVersionWithNewStatus(newStatus, correctCount, totalCount, oldEventVersion, lastSeenCorrectly, lastSeenIncorrectly, oldSourceHighestVersion, newSourceHighestVersion) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, newSourceHighestVersion);
  switch (match) {
    case "Greater" :
        if (typeof newStatus !== "object") {
          return {
                  TAG: "SometimesSeenCorrectlyInOlderVersion",
                  _0: correctCount,
                  _1: totalCount,
                  _2: oldEventVersion,
                  _3: lastSeenCorrectly,
                  _4: lastSeenIncorrectly
                };
        }
        switch (newStatus.TAG) {
          case "AlwaysSeenCorrectlyLatestVersion" :
              var newLastSeen = newStatus._2;
              var newTotal = newStatus._1;
              var newCorrect = newStatus._0;
              var match$1 = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newSourceHighestVersion);
              switch (match$1) {
                case "Greater" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: oldEventVersion,
                            _3: lastSeenCorrectly,
                            _4: lastSeenIncorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newCorrect + correctCount | 0,
                            _1: newTotal + totalCount | 0,
                            _2: oldEventVersion,
                            _3: newLastSeen,
                            _4: lastSeenIncorrectly
                          };
                case "Smaller" :
                    return {
                            TAG: "AlwaysSeenCorrectlyInOlderVersion",
                            _0: newCorrect,
                            _1: newTotal,
                            _2: Belt_Option.getExn(newSourceHighestVersion),
                            _3: newLastSeen
                          };
                
              }
          case "SometimesSeenCorrectlyLatestVersion" :
              var newLastSeenIncorrect = newStatus._3;
              var newLastSeenCorrect = newStatus._2;
              var newTotal$1 = newStatus._1;
              var newCorrect$1 = newStatus._0;
              var match$2 = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newSourceHighestVersion);
              switch (match$2) {
                case "Greater" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: oldEventVersion,
                            _3: lastSeenCorrectly,
                            _4: lastSeenIncorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newCorrect$1 + correctCount | 0,
                            _1: newTotal$1 + totalCount | 0,
                            _2: oldEventVersion,
                            _3: newLastSeenCorrect,
                            _4: newLastSeenIncorrect
                          };
                case "Smaller" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newCorrect$1,
                            _1: newTotal$1,
                            _2: Belt_Option.getExn(newSourceHighestVersion),
                            _3: newLastSeenCorrect,
                            _4: newLastSeenIncorrect
                          };
                
              }
          case "NeverSeenCorrectlyLatestVersion" :
              var newLastSeenIncorrect$1 = newStatus._2;
              var newTotal$2 = newStatus._1;
              var match$3 = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newSourceHighestVersion);
              switch (match$3) {
                case "Greater" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: correctCount,
                            _1: totalCount,
                            _2: oldEventVersion,
                            _3: lastSeenCorrectly,
                            _4: lastSeenIncorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newStatus._0 + correctCount | 0,
                            _1: newTotal$2 + totalCount | 0,
                            _2: oldEventVersion,
                            _3: lastSeenCorrectly,
                            _4: newLastSeenIncorrect$1
                          };
                case "Smaller" :
                    return {
                            TAG: "NeverSeenCorrectlyInOlderVersion",
                            _0: 0,
                            _1: newTotal$2,
                            _2: Belt_Option.getExn(newSourceHighestVersion),
                            _3: newLastSeenIncorrect$1
                          };
                
              }
          case "AlwaysSeenCorrectlyInOlderVersion" :
              return mergeSometimesSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenCorrectly, lastSeenIncorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3);
          case "SometimesSeenCorrectlyInOlderVersion" :
              return mergeSometimesSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenCorrectly, lastSeenIncorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3, newStatus._4);
          case "NeverSeenCorrectlyInOlderVersion" :
              return mergeSometimesSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenCorrectly, lastSeenIncorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3);
          
        }
    case "Equal" :
    case "Smaller" :
        break;
    
  }
  return compareOldSometimesSeenWithNewStatusWhenSourceVersionsAreLessOrEqual(newStatus, correctCount, totalCount, oldEventVersion, lastSeenCorrectly, lastSeenIncorrectly);
}

var SometimesSeenCorrectOlderVersion = {
  mergeSometimesSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion: mergeSometimesSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion,
  mergeSometimesSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion: mergeSometimesSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion,
  mergeSometimesSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion: mergeSometimesSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion,
  compareOldSometimesSeenWithNewStatusWhenSourceVersionsAreLessOrEqual: compareOldSometimesSeenWithNewStatusWhenSourceVersionsAreLessOrEqual,
  mergeSometimesSeenCorrectOlderVersionWithNewStatus: mergeSometimesSeenCorrectOlderVersionWithNewStatus
};

function mergeNeverSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion(oldCorrect, oldTotal, oldEventVersion, oldLastSeen, newCorrect, newTotal, newEventVersion, newLastSeen) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newEventVersion);
  switch (match) {
    case "Greater" :
        return {
                TAG: "NeverSeenCorrectlyInOlderVersion",
                _0: 0,
                _1: oldTotal,
                _2: oldEventVersion,
                _3: oldLastSeen
              };
    case "Equal" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: newCorrect + oldCorrect | 0,
                _1: newTotal + oldTotal | 0,
                _2: newEventVersion,
                _3: newLastSeen,
                _4: oldLastSeen
              };
    case "Smaller" :
        return {
                TAG: "AlwaysSeenCorrectlyInOlderVersion",
                _0: newCorrect,
                _1: newTotal,
                _2: newEventVersion,
                _3: newLastSeen
              };
    
  }
}

function mergeNeverSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion(oldCorrect, oldTotal, oldEventVersion, oldLastSeen, newCorrect, newTotal, newEventVersion, newLastSeenCorrect, newLastSeenIncorrect) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newEventVersion);
  switch (match) {
    case "Greater" :
        return {
                TAG: "NeverSeenCorrectlyInOlderVersion",
                _0: 0,
                _1: oldTotal,
                _2: oldEventVersion,
                _3: oldLastSeen
              };
    case "Equal" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: newCorrect + oldCorrect | 0,
                _1: newTotal + oldTotal | 0,
                _2: newEventVersion,
                _3: newLastSeenCorrect,
                _4: newLastSeenIncorrect
              };
    case "Smaller" :
        return {
                TAG: "SometimesSeenCorrectlyInOlderVersion",
                _0: newCorrect,
                _1: newTotal,
                _2: newEventVersion,
                _3: newLastSeenCorrect,
                _4: newLastSeenIncorrect
              };
    
  }
}

function mergeNeverSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion(oldTotal, oldEventVersion, oldLastSeen, newTotal, newEventVersion, newLastSeenIncorrect) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newEventVersion);
  switch (match) {
    case "Greater" :
        return {
                TAG: "NeverSeenCorrectlyInOlderVersion",
                _0: 0,
                _1: oldTotal,
                _2: oldEventVersion,
                _3: oldLastSeen
              };
    case "Equal" :
        return {
                TAG: "NeverSeenCorrectlyInOlderVersion",
                _0: 0,
                _1: newTotal + oldTotal | 0,
                _2: newEventVersion,
                _3: newLastSeenIncorrect
              };
    case "Smaller" :
        return {
                TAG: "NeverSeenCorrectlyInOlderVersion",
                _0: 0,
                _1: newTotal,
                _2: newEventVersion,
                _3: newLastSeenIncorrect
              };
    
  }
}

function compareOldNeverSeenWithNewStatusWhenSourceVersionsAreLessOrEqual(newStatus, correctCount, totalCount, oldEventVersion, lastSeenIncorrectly) {
  if (typeof newStatus !== "object") {
    return {
            TAG: "NeverSeenCorrectlyInOlderVersion",
            _0: 0,
            _1: totalCount,
            _2: oldEventVersion,
            _3: lastSeenIncorrectly
          };
  }
  switch (newStatus.TAG) {
    case "AlwaysSeenCorrectlyInOlderVersion" :
        return mergeNeverSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenIncorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3);
    case "SometimesSeenCorrectlyInOlderVersion" :
        return mergeNeverSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenIncorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3, newStatus._4);
    case "NeverSeenCorrectlyInOlderVersion" :
        return mergeNeverSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion(totalCount, oldEventVersion, lastSeenIncorrectly, newStatus._1, newStatus._2, newStatus._3);
    default:
      return newStatus;
  }
}

function mergeNeverSeenCorrectOlderVersionWithNewStatus(newStatus, correctCount, totalCount, oldEventVersion, lastSeenIncorrectly, oldSourceHighestVersion, newSourceHighestVersion) {
  var match = AppVersionComparisonUtils.compareToAppVersion(oldSourceHighestVersion, newSourceHighestVersion);
  switch (match) {
    case "Greater" :
        if (typeof newStatus !== "object") {
          return {
                  TAG: "NeverSeenCorrectlyInOlderVersion",
                  _0: 0,
                  _1: totalCount,
                  _2: oldEventVersion,
                  _3: lastSeenIncorrectly
                };
        }
        switch (newStatus.TAG) {
          case "AlwaysSeenCorrectlyLatestVersion" :
              var newLastSeen = newStatus._2;
              var newTotal = newStatus._1;
              var newCorrect = newStatus._0;
              var match$1 = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newSourceHighestVersion);
              switch (match$1) {
                case "Greater" :
                    return {
                            TAG: "NeverSeenCorrectlyInOlderVersion",
                            _0: 0,
                            _1: totalCount,
                            _2: oldEventVersion,
                            _3: lastSeenIncorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newCorrect + correctCount | 0,
                            _1: newTotal + totalCount | 0,
                            _2: oldEventVersion,
                            _3: newLastSeen,
                            _4: lastSeenIncorrectly
                          };
                case "Smaller" :
                    return {
                            TAG: "AlwaysSeenCorrectlyInOlderVersion",
                            _0: newCorrect,
                            _1: newTotal,
                            _2: Belt_Option.getExn(newSourceHighestVersion),
                            _3: newLastSeen
                          };
                
              }
          case "SometimesSeenCorrectlyLatestVersion" :
              var newLastSeenIncorrect = newStatus._3;
              var newLastSeenCorrect = newStatus._2;
              var newTotal$1 = newStatus._1;
              var newCorrect$1 = newStatus._0;
              var match$2 = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newSourceHighestVersion);
              switch (match$2) {
                case "Greater" :
                    return {
                            TAG: "NeverSeenCorrectlyInOlderVersion",
                            _0: 0,
                            _1: totalCount,
                            _2: oldEventVersion,
                            _3: lastSeenIncorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newCorrect$1 + correctCount | 0,
                            _1: newTotal$1 + totalCount | 0,
                            _2: oldEventVersion,
                            _3: newLastSeenCorrect,
                            _4: newLastSeenIncorrect
                          };
                case "Smaller" :
                    return {
                            TAG: "SometimesSeenCorrectlyInOlderVersion",
                            _0: newCorrect$1,
                            _1: newTotal$1,
                            _2: Belt_Option.getExn(newSourceHighestVersion),
                            _3: newLastSeenCorrect,
                            _4: newLastSeenIncorrect
                          };
                
              }
          case "NeverSeenCorrectlyLatestVersion" :
              var newLastSeenIncorrect$1 = newStatus._2;
              var newTotal$2 = newStatus._1;
              var match$3 = AppVersionComparisonUtils.compareToAppVersion(oldEventVersion, newSourceHighestVersion);
              switch (match$3) {
                case "Greater" :
                    return {
                            TAG: "NeverSeenCorrectlyInOlderVersion",
                            _0: 0,
                            _1: totalCount,
                            _2: oldEventVersion,
                            _3: lastSeenIncorrectly
                          };
                case "Equal" :
                    return {
                            TAG: "NeverSeenCorrectlyInOlderVersion",
                            _0: 0,
                            _1: newTotal$2 + totalCount | 0,
                            _2: oldEventVersion,
                            _3: newLastSeenIncorrect$1
                          };
                case "Smaller" :
                    return {
                            TAG: "NeverSeenCorrectlyInOlderVersion",
                            _0: 0,
                            _1: newTotal$2,
                            _2: Belt_Option.getExn(newSourceHighestVersion),
                            _3: newLastSeenIncorrect$1
                          };
                
              }
          case "AlwaysSeenCorrectlyInOlderVersion" :
              return mergeNeverSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenIncorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3);
          case "SometimesSeenCorrectlyInOlderVersion" :
              return mergeNeverSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion(correctCount, totalCount, oldEventVersion, lastSeenIncorrectly, newStatus._0, newStatus._1, newStatus._2, newStatus._3, newStatus._4);
          case "NeverSeenCorrectlyInOlderVersion" :
              return mergeNeverSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion(totalCount, oldEventVersion, lastSeenIncorrectly, newStatus._1, newStatus._2, newStatus._3);
          
        }
    case "Equal" :
    case "Smaller" :
        return compareOldNeverSeenWithNewStatusWhenSourceVersionsAreLessOrEqual(newStatus, correctCount, totalCount, oldEventVersion, lastSeenIncorrectly);
    
  }
}

var NeverSeenCorrectOlderVersion = {
  mergeNeverSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion: mergeNeverSeenCorrectOlderVersionWithNewStatusAlwaysSeenCorrectOlderVersion,
  mergeNeverSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion: mergeNeverSeenCorrectOlderVersionWithNewStatusSometimesSeenCorrectOlderVersion,
  mergeNeverSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion: mergeNeverSeenCorrectOlderVersionWithNewStatusNeverSeenCorrectOlderVersion,
  compareOldNeverSeenWithNewStatusWhenSourceVersionsAreLessOrEqual: compareOldNeverSeenWithNewStatusWhenSourceVersionsAreLessOrEqual,
  mergeNeverSeenCorrectOlderVersionWithNewStatus: mergeNeverSeenCorrectOlderVersionWithNewStatus
};

var MergeEventStatus = {
  mergeAlwaysSeenCorrectlyLatestVersionWithNew: mergeAlwaysSeenCorrectlyLatestVersionWithNew,
  mergeSometimesSeenCorrectlyLatestVersionWithNew: mergeSometimesSeenCorrectlyLatestVersionWithNew,
  mergeNeverSeenCorrectlyLatestVersionWithNew: mergeNeverSeenCorrectlyLatestVersionWithNew,
  AlwaysSeenCorrectOlderVersion: AlwaysSeenCorrectOlderVersion,
  SometimesSeenCorrectOlderVersion: SometimesSeenCorrectOlderVersion,
  NeverSeenCorrectOlderVersion: NeverSeenCorrectOlderVersion
};

function mergeEventStatus(oldEventStatus, newEventStatus, oldSourceHighestVersion, newSourceHighestVersion) {
  if (typeof oldEventStatus !== "object") {
    return newEventStatus;
  }
  switch (oldEventStatus.TAG) {
    case "AlwaysSeenCorrectlyLatestVersion" :
        return mergeAlwaysSeenCorrectlyLatestVersionWithNew(newEventStatus, oldEventStatus._0, oldEventStatus._1, oldEventStatus._2, oldSourceHighestVersion, newSourceHighestVersion);
    case "SometimesSeenCorrectlyLatestVersion" :
        return mergeSometimesSeenCorrectlyLatestVersionWithNew(newEventStatus, oldEventStatus._0, oldEventStatus._1, oldEventStatus._2, oldEventStatus._3, oldSourceHighestVersion, newSourceHighestVersion);
    case "NeverSeenCorrectlyLatestVersion" :
        return mergeNeverSeenCorrectlyLatestVersionWithNew(newEventStatus, oldEventStatus._0, oldEventStatus._1, oldEventStatus._2, oldSourceHighestVersion, newSourceHighestVersion);
    case "AlwaysSeenCorrectlyInOlderVersion" :
        return mergeAlwaysSeenCorrectOlderVersionWithNewStatus(newEventStatus, oldEventStatus._0, oldEventStatus._1, oldEventStatus._2, oldEventStatus._3, oldSourceHighestVersion, newSourceHighestVersion);
    case "SometimesSeenCorrectlyInOlderVersion" :
        return mergeSometimesSeenCorrectOlderVersionWithNewStatus(newEventStatus, oldEventStatus._0, oldEventStatus._1, oldEventStatus._2, oldEventStatus._3, oldEventStatus._4, oldSourceHighestVersion, newSourceHighestVersion);
    case "NeverSeenCorrectlyInOlderVersion" :
        return mergeNeverSeenCorrectOlderVersionWithNewStatus(newEventStatus, oldEventStatus._0, oldEventStatus._1, oldEventStatus._2, oldEventStatus._3, oldSourceHighestVersion, newSourceHighestVersion);
    
  }
}

function mergeSource(oldSource, newSource) {
  var highestVersion = getHigherVersion(oldSource.highestVersion, newSource.highestVersion);
  return {
          sourceId: oldSource.sourceId,
          highestVersion: highestVersion,
          status: mergeEventStatus(oldSource.status, newSource.status, oldSource.highestVersion, newSource.highestVersion)
        };
}

function mergeStatus(currentEvents, newEvents) {
  var updatedEvents = Belt_MapString.mapWithKey(currentEvents, (function (eventName, $$event) {
          var updatedSources = Belt_MapString.mapWithKey($$event.sources, (function (sourceId, source) {
                  var newSource = Belt_Option.flatMap(Belt_MapString.get(newEvents, eventName), (function (newEvent) {
                          return Belt_MapString.get(newEvent.sources, sourceId);
                        }));
                  if (newSource !== undefined) {
                    return mergeSource(source, newSource);
                  } else {
                    return source;
                  }
                }));
          var newSources = Belt_MapString.toArray(Belt_Option.getWithDefault(Belt_Option.map(Belt_MapString.get(newEvents, eventName), (function (newEvent) {
                          return Belt_MapString.keep(newEvent.sources, (function (sourceId, param) {
                                        return Belt_MapString.get($$event.sources, sourceId) === undefined;
                                      }));
                        })), undefined));
          var updatedSources$1 = Belt_MapString.mergeMany(updatedSources, newSources);
          return {
                  eventName: $$event.eventName,
                  sources: updatedSources$1
                };
        }));
  var newEvents$1 = Belt_MapString.toArray(Belt_MapString.keep(newEvents, (function (eventId, param) {
              return Belt_MapString.get(currentEvents, eventId) === undefined;
            })));
  return Belt_MapString.mergeMany(updatedEvents, newEvents$1);
}

export {
  getHigherVersion ,
  MergeEventStatus ,
  mergeEventStatus ,
  mergeSource ,
  mergeStatus ,
}
/* AppVersionComparisonUtils Not a pure module */
