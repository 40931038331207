// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CmdK from "../CmdK.mjs";
import * as Kbar from "kbar";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Fuzzaldrin from "fuzzaldrin";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "../NamedBranch.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as NamespaceIndexStore from "./NamespaceIndexStore.mjs";
import * as OrganizationContext from "../OrganizationContext.mjs";

function extract(items) {
  return Belt_MapString.valuesToArray(Belt_MapString.mapWithKey(items, (function (key, items) {
                    return {
                            key: key,
                            items: items
                          };
                  })));
}

function searchItems(items, searchQuery) {
  return Fuzzaldrin.filter(items, searchQuery, {
              key: "key",
              maxResults: 10
            });
}

function getOtherBranchesLength(items) {
  if (items.length > 1) {
    return items.length - 1 | 0;
  }
  
}

function createActions(getBranchName, items) {
  return Belt_Array.keepMapU(items, (function (item) {
                var firstItem = Belt_Array.get(item.items, 0);
                if (firstItem === undefined) {
                  return ;
                }
                var match = firstItem.itemType;
                var match$1 = firstItem.itemSubType;
                var item$1;
                if (match === "Events") {
                  item$1 = {
                    TAG: "BranchPreview",
                    _0: {
                      TAG: "Event",
                      _0: firstItem.name,
                      _1: ""
                    },
                    _1: Curry._1(getBranchName, firstItem.branchId),
                    _2: getOtherBranchesLength(item.items)
                  };
                } else if (match$1 !== undefined) {
                  switch (match$1) {
                    case "EventProperty" :
                        item$1 = {
                          TAG: "BranchPreview",
                          _0: {
                            TAG: "Property",
                            _0: firstItem.name,
                            _1: "EventProperty",
                            _2: undefined,
                            _3: ""
                          },
                          _1: Curry._1(getBranchName, firstItem.branchId),
                          _2: getOtherBranchesLength(item.items)
                        };
                        break;
                    case "UserProperty" :
                        item$1 = {
                          TAG: "BranchPreview",
                          _0: {
                            TAG: "Property",
                            _0: firstItem.name,
                            _1: "UserProperty",
                            _2: undefined,
                            _3: ""
                          },
                          _1: Curry._1(getBranchName, firstItem.branchId),
                          _2: getOtherBranchesLength(item.items)
                        };
                        break;
                    case "SystemProperty" :
                        item$1 = {
                          TAG: "BranchPreview",
                          _0: {
                            TAG: "Property",
                            _0: firstItem.name,
                            _1: "SystemProperty",
                            _2: undefined,
                            _3: ""
                          },
                          _1: Curry._1(getBranchName, firstItem.branchId),
                          _2: getOtherBranchesLength(item.items)
                        };
                        break;
                    case "GroupProperty" :
                        item$1 = Pervasives.failwith("Need group property type (no group id)");
                        break;
                    
                  }
                } else {
                  item$1 = undefined;
                }
                if (item$1 !== undefined) {
                  return Caml_option.some(Curry.app(CmdK.Action.t, [
                                  firstItem.itemId,
                                  item$1,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (param) {
                                      var match = firstItem.itemSubType;
                                      Router.Schema.pushDrawerItem(undefined, undefined, {
                                            NAME: "branchPreview",
                                            VAL: [
                                              firstItem.branchId,
                                              match !== undefined ? ({
                                                    NAME: "property",
                                                    VAL: [
                                                      firstItem.itemId,
                                                      undefined
                                                    ]
                                                  }) : ({
                                                    NAME: "event",
                                                    VAL: [
                                                      firstItem.itemId,
                                                      undefined,
                                                      undefined,
                                                      false
                                                    ]
                                                  })
                                            ]
                                          });
                                    }),
                                  "search",
                                  undefined
                                ]));
                }
                
              }));
}

function useNamespaceIndexCmdKResults(searchQuery) {
  var mainModel = ModelStore.useMainModel();
  var eventItems = Curry._1(NamespaceIndexStore.Events.use, undefined).items;
  var eventPropertyItems = Curry._1(NamespaceIndexStore.EventProperties.use, undefined).items;
  var userPropertyItems = Curry._1(NamespaceIndexStore.UserProperties.use, undefined).items;
  var systemPropertyItems = Curry._1(NamespaceIndexStore.SystemProperties.use, undefined).items;
  var isLocalWorkspace = OrganizationContext.useIsLocalWorkspace();
  var searchableItems = React.useMemo((function (param) {
          if (isLocalWorkspace) {
            return [];
          } else {
            return Belt_Array.concatMany([
                        extract(eventItems),
                        extract(eventPropertyItems),
                        extract(userPropertyItems),
                        extract(systemPropertyItems)
                      ]);
          }
        }), [
        eventItems,
        eventPropertyItems,
        userPropertyItems,
        systemPropertyItems,
        isLocalWorkspace
      ]);
  var getBranchName = React.useCallback((function (branchId) {
          return NamedBranch.getName(NamedBranch.fromUnnamed(mainModel, {
                          NAME: "Branch",
                          VAL: branchId
                        }));
        }), [mainModel]);
  Kbar.useRegisterActions(createActions(getBranchName, searchItems(searchableItems, searchQuery)), [
        searchQuery,
        mainModel
      ]);
}

var CmdK$1 = {
  extract: extract,
  searchItems: searchItems,
  getOtherBranchesLength: getOtherBranchesLength,
  createActions: createActions,
  useNamespaceIndexCmdKResults: useNamespaceIndexCmdKResults
};

export {
  CmdK$1 as CmdK,
}
/* CmdK Not a pure module */
