// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Monospace from "./Monospace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as DiffComponents from "./DiffComponents.mjs";
import * as DiffEventTriggers from "./DiffEventTriggers.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as PrintPropertyOverrideDiffUseCase from "./eventVariants/PrintPropertyOverrideDiffUseCase.mjs";

function containerStyles(borderColor) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.padding2(Css.px(10), Css.px(15)),
                      tl: {
                        hd: Css.gap(Css.px(8)),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", borderColor),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function getTextColor(state) {
  if (state === "added") {
    return Styles.Color.darkGreen;
  } else if (state === "updated") {
    return Styles.Color.light10;
  } else {
    return Styles.Color.darkRed;
  }
}

function getBorderColor(state) {
  if (state === "added") {
    return Styles.Color.darkGreen;
  } else if (state === "updated") {
    return Styles.Color.light07;
  } else {
    return Styles.Color.darkRed;
  }
}

var contentStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("flexEnd"),
          tl: {
            hd: Css.textAlign("right"),
            tl: /* [] */0
          }
        }
      }
    });

function DiffEventVariants$PropertyPill(Props) {
  var state = Props.state;
  var propertyName = Props.propertyName;
  var children = Props.children;
  return React.createElement("div", {
              className: containerStyles(getBorderColor(state))
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: getTextColor(state),
                  children: propertyName
                }), React.createElement("div", {
                  className: contentStyles
                }, children));
}

function getFrameColor(frame) {
  if (frame === "added") {
    return Styles.Color.darkGreen;
  } else if (frame === "updated") {
    return Styles.Color.light04;
  } else {
    return Styles.Color.darkRed;
  }
}

function DiffEventVariants$DiffedEventVariant(Props) {
  var name = Props.name;
  var description = Props.description;
  var children = Props.children;
  var frame = Props.frame;
  var variantIdentifier = Props.variantIdentifier;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.border(Css.px(1), "solid", getFrameColor(frame)),
                    tl: {
                      hd: Css.borderRadius(Css.px(5)),
                      tl: {
                        hd: Css.padding(Css.px(8)),
                        tl: {
                          hd: Css.paddingTop(Css.px(12)),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("column"),
                              tl: {
                                hd: Css.gap(Css.px(4)),
                                tl: {
                                  hd: Css.transition({
                                        NAME: "ms",
                                        VAL: Styles.Duration.$$short
                                      }, undefined, undefined, "background-color"),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.backgroundColor(Styles.Color.white),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              onClick: (function ($$event) {
                  $$event.stopPropagation();
                  Router.Schema.pushDrawerItem(undefined, undefined, {
                        NAME: "eventVariant",
                        VAL: [
                          variantIdentifier,
                          undefined
                        ]
                      });
                })
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: name
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light11,
                  withWordBreak: true,
                  children: description
                }), React.createElement(Spacer.make, {
                  height: 4
                }), children);
}

function DiffEventVariants$DiffEventVariantProperties(Props) {
  var children = Props.children;
  return React.createElement(React.Fragment, undefined, Caml_obj.equal(React.Children.toArray(children), []) ? null : React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: "Property variations"
                  }), children);
}

function DiffEventVariants$DiffSources(Props) {
  var actions = Props.actions;
  var model = ModelStore.Mapped.use();
  var sourceName = function (sourceId) {
    return Belt_Option.mapWithDefault(TrackingPlanMappedModel.WithArchive.getSource(model, sourceId), sourceId, AvoConfig.getSourceName);
  };
  var sourceActions = Belt_Array.keepMapU(actions, (function (action) {
          if (typeof action !== "object") {
            return ;
          }
          var variant = action.NAME;
          if (variant === "SetSourceOverrideOnVariant") {
            return Caml_option.some(React.createElement(DiffComponents.Row.make, {
                            label: sourceName(action.VAL[1]),
                            change: "Remove"
                          }));
          }
          if (variant === "ClearSourceOverrideOnVariant") {
            return Caml_option.some(React.createElement(DiffComponents.Row.make, {
                            label: sourceName(action.VAL[1]),
                            change: "Add"
                          }));
          }
          if (variant !== "AddSourceConfig") {
            return ;
          }
          var match = action.VAL;
          if (match[1] === "ForceFlattenPropertyBundlesInCodegen") {
            return Caml_option.some(React.createElement(DiffComponents.Row.make, {
                            label: sourceName(match[0]),
                            change: "Add"
                          }));
          }
          
        }));
  if (Caml_obj.equal(sourceActions, [])) {
    return null;
  } else {
    return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: "Sources"
                  }), sourceActions);
  }
}

var root = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.gap(Css.px(8)),
            tl: {
              hd: Css.paddingLeft(Css.px(26)),
              tl: {
                hd: Css.paddingRight(Css.px(10)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var addedStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.darkGreen),
      tl: {
        hd: Css.backgroundColor(Styles.Color.lightGreen),
        tl: /* [] */0
      }
    });

var removedStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.darkRed),
      tl: {
        hd: Css.backgroundColor(Styles.Color.lightRed),
        tl: /* [] */0
      }
    });

function DiffEventVariants$DiffEventVariantPropertyAllowedValues(Props) {
  var actionsOnProperty = Props.actionsOnProperty;
  var prevAllowedValuesOverride = Props.prevAllowedValuesOverride;
  var allowedValuesActions = Belt_Array.keep(actionsOnProperty, (function (action) {
          if (typeof action !== "object") {
            return false;
          }
          var variant = action.NAME;
          if (variant === "ClearEventVariantSpecificPropertyValueOverride" || variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources") {
            return true;
          } else {
            return variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed";
          }
        }));
  if (Caml_obj.equal(allowedValuesActions, [])) {
    return null;
  } else {
    return React.createElement($$Text.make, {
                size: "Small",
                color: Styles.Color.light11,
                children: null
              }, "Allowed values changes: ", Belt_Array.mapWithIndex(Belt_Array.keepMap(allowedValuesActions, (function (action) {
                          if (typeof action === "object") {
                            var variant = action.NAME;
                            if (variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed") {
                              var match = action.VAL[2];
                              if (typeof match !== "object") {
                                return ;
                              }
                              if (match.NAME !== "StringLit") {
                                return ;
                              }
                              var value = match.VAL;
                              return [
                                      value,
                                      React.createElement("span", {
                                            className: removedStyles
                                          }, React.createElement(Monospace.make, {
                                                children: value
                                              }))
                                    ];
                            }
                            if (variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources") {
                              var match$1 = action.VAL[2];
                              if (typeof match$1 !== "object") {
                                return ;
                              }
                              if (match$1.NAME !== "StringLit") {
                                return ;
                              }
                              var value$1 = match$1.VAL;
                              return [
                                      value$1,
                                      React.createElement("span", {
                                            className: addedStyles
                                          }, React.createElement(Monospace.make, {
                                                children: value$1
                                              }))
                                    ];
                            }
                            
                          }
                          if (typeof prevAllowedValuesOverride !== "object") {
                            return ;
                          }
                          if (typeof action !== "object") {
                            return ;
                          }
                          if (action.NAME !== "ClearEventVariantSpecificPropertyValueOverride") {
                            return ;
                          }
                          var match$2 = action.VAL[2];
                          if (typeof match$2 !== "object") {
                            return ;
                          }
                          if (match$2.NAME !== "StringLit") {
                            return ;
                          }
                          var value$2 = match$2.VAL;
                          var match$3 = Belt_MapString.get(prevAllowedValuesOverride._0, value$2);
                          if (match$3 !== undefined) {
                            if (typeof match$3 !== "object") {
                              return [
                                      value$2,
                                      React.createElement("span", {
                                            className: addedStyles
                                          }, React.createElement(Monospace.make, {
                                                children: value$2
                                              }))
                                    ];
                            } else {
                              return [
                                      value$2,
                                      React.createElement("span", {
                                            className: removedStyles
                                          }, React.createElement(Monospace.make, {
                                                children: value$2
                                              }))
                                    ];
                            }
                          }
                          
                        })), (function (index, param) {
                      return React.createElement("span", {
                                  key: param[0]
                                }, index === 0 ? null : ", ", param[1]);
                    })));
  }
}

function DiffEventVariants(Props) {
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var $$event = Props.event;
  var nextEvent = Props.nextEvent;
  var actions = Props.actions;
  var actionsByVariant = Belt_Array.reduce(Belt_Array.keepMapU(actions, (function (action) {
              if (typeof action !== "object") {
                return ;
              }
              var variant = action.NAME;
              if (variant === "UpdatePropertyPinnedValueOnEventVariant" || variant === "ClearEventVariantSpecificPropertyValueOverride" || variant === "UpdateEventVariantTriggerContent" || variant === "ClearPropertyRegexOverrideFromEventVariant" || variant === "RemovePropertyFromEventVariant" || variant === "CreateEventVariant" || variant === "AddEventVariantTriggerSource" || variant === "UpdateEventVariantTriggerDescription" || variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources" || variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed" || variant === "SetPropertyAbsenceOnVariant" || variant === "UpdateEventVariantDescription" || variant === "ClearEventVariantPropertyOverride" || variant === "UpdatePropertyRegexOverrideOnEventVariant" || variant === "ClearSourceOverrideOnVariant" || variant === "CreateEventVariantTrigger" || variant === "RemoveEventVariantTriggerSource" || variant === "SetSourceOverrideOnVariant" || variant === "ClearPropertyPinnedValueFromEventVariant" || variant === "UpdateEventVariantTriggerSources" || variant === "AddPropertyToEventVariant" || variant === "RemoveEventVariantTrigger") {
                return [
                        action.VAL[0].variantId,
                        action
                      ];
              }
              if (variant !== "Archive") {
                return ;
              }
              var match = action.VAL;
              if (typeof match === "object" && match.NAME === "EventVariant") {
                return [
                        match.VAL.variantId,
                        action
                      ];
              }
              
            })), undefined, (function (map, param) {
          var action = param[1];
          return Belt_MapString.update(map, param[0], (function (actions) {
                        if (actions !== undefined) {
                          return Belt_Array.concat(actions, [action]);
                        } else {
                          return [action];
                        }
                      }));
        }));
  var newVariants = Belt_Array.keepMapU(Belt_MapString.toArray(Belt_MapString.keepU(actionsByVariant, (function (_id, actions) {
                  return Belt_Array.someU(actions, (function (action) {
                                if (typeof action === "object") {
                                  return action.NAME === "CreateEventVariant";
                                } else {
                                  return false;
                                }
                              }));
                }))), (function (param) {
          var variantId = param[0];
          var newVariant = Belt_Array.getByU(nextEvent.variants, (function (param) {
                  return param.id === variantId;
                }));
          if (newVariant !== undefined) {
            return [
                    newVariant,
                    param[1]
                  ];
          }
          
        }));
  var removedVariants = Belt_Array.keepMapU(Belt_MapString.toArray(Belt_MapString.keepU(actionsByVariant, (function (_id, actions) {
                  return Belt_Array.someU(actions, (function (action) {
                                if (typeof action !== "object") {
                                  return false;
                                }
                                if (action.NAME !== "Archive") {
                                  return false;
                                }
                                var match = action.VAL;
                                if (typeof match === "object") {
                                  return match.NAME === "EventVariant";
                                } else {
                                  return false;
                                }
                              }));
                }))), (function (param) {
          var variantId = param[0];
          var removedVariant = Belt_Array.getByU($$event.variants, (function (param) {
                  return param.id === variantId;
                }));
          if (removedVariant !== undefined) {
            return [
                    removedVariant,
                    param[1]
                  ];
          }
          
        }));
  var changedVariants = Belt_Array.keepMap(Belt_MapString.toArray(Belt_MapString.keepU(actionsByVariant, (function (id, _actions) {
                  if (Belt_Array.everyU(newVariants, (function (param) {
                            return param[0].id !== id;
                          }))) {
                    return Belt_Array.everyU(removedVariants, (function (param) {
                                  return param[0].id !== id;
                                }));
                  } else {
                    return false;
                  }
                }))), (function (param) {
          var variantId = param[0];
          var match = Belt_Array.getByU($$event.variants, (function (param) {
                  return param.id === variantId;
                }));
          var match$1 = Belt_Array.getByU(nextEvent.variants, (function (param) {
                  return param.id === variantId;
                }));
          if (match !== undefined && match$1 !== undefined) {
            return [
                    match,
                    match$1,
                    param[1]
                  ];
          }
          
        }));
  var actionsByProperty = Belt_Array.reduceU(Belt_Array.keepMapU(actions, (function (action) {
              if (typeof action !== "object") {
                return ;
              }
              var variant = action.NAME;
              if (variant === "UpdatePropertyPinnedValueOnEventVariant" || variant === "ClearEventVariantSpecificPropertyValueOverride" || variant === "ClearPropertyRegexOverrideFromEventVariant" || variant === "RemovePropertyFromEventVariant" || variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources" || variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed" || variant === "SetPropertyAbsenceOnVariant" || variant === "ClearEventVariantPropertyOverride" || variant === "UpdatePropertyRegexOverrideOnEventVariant" || variant === "ClearPropertyPinnedValueFromEventVariant" || variant === "AddPropertyToEventVariant") {
                return [
                        action.VAL[1],
                        action
                      ];
              }
              
            })), undefined, (function (map, param) {
          var action = param[1];
          return Belt_MapString.update(map, param[0], (function (actions) {
                        if (actions !== undefined) {
                          return Belt_Array.concat(actions, [action]);
                        } else {
                          return [action];
                        }
                      }));
        }));
  if (Caml_obj.equal(newVariants, []) && Caml_obj.equal(changedVariants, []) && Caml_obj.equal(removedVariants, [])) {
    return null;
  } else {
    return React.createElement("div", {
                className: root
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.paddingTop(Css.px(12)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(4)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light11,
                        children: "Event variant changes"
                      })), Belt_Array.mapU(removedVariants, (function (param) {
                      var actions = param[1];
                      var variant = param[0];
                      return React.createElement(DiffEventVariants$DiffedEventVariant, {
                                  name: JsDiff.renderStringDiff(undefined, undefined, undefined, $$event.name + " - " + variant.nameSuffix, ""),
                                  description: JsDiff.renderStringDiff(undefined, undefined, undefined, variant.description, ""),
                                  children: null,
                                  frame: "removed",
                                  variantIdentifier: TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                                  key: variant.id
                                }, React.createElement(DiffEventTriggers.make, {
                                      branchModel: branchModel,
                                      masterModel: masterModel,
                                      triggers: variant.triggers,
                                      nextTriggers: [],
                                      actions: Belt_List.fromArray(actions),
                                      variantId: variant.id
                                    }), React.createElement(DiffEventVariants$DiffSources, {
                                      actions: actions
                                    }), React.createElement(DiffEventVariants$DiffEventVariantProperties, {
                                      children: Belt_Array.mapU(Belt_Array.keepMapU(Belt_MapString.toArray(variant.propertyOverrides), (function (param) {
                                                  var overrideType = param[1];
                                                  var overridePropertyId = param[0];
                                                  return Belt_Option.mapU(Belt_Option.flatMapU(Belt_List.getByU(masterModel.properties, (function (property) {
                                                                        return ModelUtils.getPropertyId(property) === overridePropertyId;
                                                                      })), (function (property) {
                                                                    return ModelUtils.resolveProperty(masterModel, property);
                                                                  })), (function (property) {
                                                                return [
                                                                        property,
                                                                        overrideType
                                                                      ];
                                                              }));
                                                })), (function (param) {
                                              var property = param[0];
                                              return React.createElement(DiffEventVariants$PropertyPill, {
                                                          state: "removed",
                                                          propertyName: property.name,
                                                          children: React.createElement($$Text.make, {
                                                                size: "Small",
                                                                weight: "Regular",
                                                                color: Styles.Color.light11,
                                                                children: PrintPropertyOverrideDiffUseCase.oneLine(param[1])
                                                              }),
                                                          key: property.id
                                                        });
                                            }))
                                    }));
                    })), Belt_Array.mapU(newVariants, (function (param) {
                      var actions = param[1];
                      var variant = param[0];
                      return React.createElement(DiffEventVariants$DiffedEventVariant, {
                                  name: JsDiff.renderStringDiff(undefined, undefined, undefined, "", $$event.name + " - " + variant.nameSuffix),
                                  description: JsDiff.renderStringDiff(undefined, undefined, undefined, "", variant.description),
                                  children: null,
                                  frame: "added",
                                  variantIdentifier: TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                                  key: variant.id
                                }, React.createElement(DiffEventTriggers.make, {
                                      branchModel: branchModel,
                                      masterModel: masterModel,
                                      triggers: [],
                                      nextTriggers: variant.triggers,
                                      actions: Belt_List.fromArray(actions),
                                      variantId: variant.id
                                    }), React.createElement(DiffEventVariants$DiffSources, {
                                      actions: actions
                                    }), React.createElement(DiffEventVariants$DiffEventVariantProperties, {
                                      children: Belt_Array.mapU(Belt_Array.keepMapU(Belt_MapString.toArray(variant.propertyOverrides), (function (param) {
                                                  var overrideType = param[1];
                                                  var overridePropertyId = param[0];
                                                  return Belt_Option.mapU(Belt_Option.flatMapU(Belt_List.getByU(branchModel.properties, (function (property) {
                                                                        return ModelUtils.getPropertyId(property) === overridePropertyId;
                                                                      })), (function (property) {
                                                                    return ModelUtils.resolveProperty(branchModel, property);
                                                                  })), (function (property) {
                                                                return [
                                                                        property,
                                                                        overrideType
                                                                      ];
                                                              }));
                                                })), (function (param) {
                                              var property = param[0];
                                              return React.createElement(DiffEventVariants$PropertyPill, {
                                                          state: "added",
                                                          propertyName: property.name,
                                                          children: null,
                                                          key: property.id
                                                        }, React.createElement($$Text.make, {
                                                              size: "Small",
                                                              weight: "Regular",
                                                              color: Styles.Color.darkGreen,
                                                              children: PrintPropertyOverrideDiffUseCase.oneLine(param[1])
                                                            }), React.createElement(DiffEventVariants$DiffEventVariantPropertyAllowedValues, {
                                                              actionsOnProperty: Belt_Option.getWithDefault(Belt_MapString.get(actionsByProperty, property.id), []),
                                                              prevAllowedValuesOverride: "NoneOverride"
                                                            }));
                                            }))
                                    }));
                    })), Belt_Array.mapU(changedVariants, (function (param) {
                      var actions = param[2];
                      var nextVariant = param[1];
                      var prevVariant = param[0];
                      var hasDescriptionChangeAction = Belt_Array.someU(actions, (function (action) {
                              if (typeof action === "object") {
                                return action.NAME === "UpdateEventVariantDescription";
                              } else {
                                return false;
                              }
                            }));
                      var newProperties = Belt_Array.keepMapU(Belt_MapString.toArray(Belt_MapString.keepU(actionsByProperty, (function (_id, actions) {
                                      return Belt_Array.someU(actions, (function (action) {
                                                    if (typeof action !== "object") {
                                                      return false;
                                                    }
                                                    var variant = action.NAME;
                                                    if (variant === "RemovePropertyFromEventVariant") {
                                                      return true;
                                                    } else {
                                                      return variant === "AddPropertyToEventVariant";
                                                    }
                                                  }));
                                    }))), (function (param) {
                              var propertyId = param[0];
                              var match = ModelUtils.resolvePropertyById(propertyId, branchModel);
                              var match$1 = Belt_MapString.get(nextVariant.propertyOverrides, propertyId);
                              if (match !== undefined && match$1 !== undefined) {
                                return [
                                        match,
                                        match$1,
                                        param[1]
                                      ];
                              }
                              
                            }));
                      var removedPropertyOverrides = Belt_Array.keepMapU(Belt_MapString.keysToArray(Belt_MapString.keepU(actionsByProperty, (function (_id, actions) {
                                      if (actions.length !== 1) {
                                        return false;
                                      }
                                      var match = actions[0];
                                      if (typeof match === "object") {
                                        return match.NAME === "ClearEventVariantPropertyOverride";
                                      } else {
                                        return false;
                                      }
                                    }))), (function (propertyId) {
                              var match = ModelUtils.resolvePropertyById(propertyId, branchModel);
                              var match$1 = Belt_MapString.get(prevVariant.propertyOverrides, propertyId);
                              if (match !== undefined && match$1 !== undefined) {
                                return [
                                        match,
                                        match$1
                                      ];
                              }
                              
                            }));
                      var changedProperties = Belt_Array.keepMap(Belt_MapString.toArray(Belt_MapString.keepU(actionsByProperty, (function (id, _actions) {
                                      if (Belt_Array.everyU(newProperties, (function (param) {
                                                return param[0].id !== id;
                                              }))) {
                                        return Belt_Array.everyU(removedPropertyOverrides, (function (param) {
                                                      return param[0].id !== id;
                                                    }));
                                      } else {
                                        return false;
                                      }
                                    }))), (function (param) {
                              var propertyId = param[0];
                              var match = Belt_MapString.get(prevVariant.propertyOverrides, propertyId);
                              var match$1 = Belt_MapString.get(nextVariant.propertyOverrides, propertyId);
                              var match$2 = ModelUtils.resolvePropertyById(propertyId, branchModel);
                              if (match !== undefined && !(typeof match !== "object" || !(match$1 !== undefined && !(typeof match$1 !== "object" || match$2 === undefined)))) {
                                return [
                                        match._0,
                                        match$1._0,
                                        param[1],
                                        match$2
                                      ];
                              }
                              
                            }));
                      return React.createElement(DiffEventVariants$DiffedEventVariant, {
                                  name: $$event.name + " - " + nextVariant.nameSuffix,
                                  description: hasDescriptionChangeAction ? JsDiff.renderStringDiff(undefined, undefined, undefined, prevVariant.description, nextVariant.description) : nextVariant.description,
                                  children: null,
                                  frame: "updated",
                                  variantIdentifier: TrackingPlanModel.EventVariant.makeVariantIdentifier(nextVariant),
                                  key: nextVariant.id
                                }, React.createElement(DiffEventTriggers.make, {
                                      branchModel: branchModel,
                                      masterModel: masterModel,
                                      triggers: prevVariant.triggers,
                                      nextTriggers: nextVariant.triggers,
                                      actions: Belt_List.fromArray(actions),
                                      variantId: nextVariant.id
                                    }), React.createElement(DiffEventVariants$DiffSources, {
                                      actions: actions
                                    }), React.createElement(DiffEventVariants$DiffEventVariantProperties, {
                                      children: null
                                    }, Belt_Array.mapU(newProperties, (function (param) {
                                            var property = param[0];
                                            return React.createElement(DiffEventVariants$PropertyPill, {
                                                        state: "added",
                                                        propertyName: property.name,
                                                        children: null,
                                                        key: property.id
                                                      }, React.createElement($$Text.make, {
                                                            size: "Small",
                                                            weight: "Regular",
                                                            color: Styles.Color.darkGreen,
                                                            children: PrintPropertyOverrideDiffUseCase.oneLine(param[1])
                                                          }), React.createElement(DiffEventVariants$DiffEventVariantPropertyAllowedValues, {
                                                            actionsOnProperty: param[2],
                                                            prevAllowedValuesOverride: "NoneOverride"
                                                          }));
                                          })), Belt_Array.mapU(changedProperties, (function (param) {
                                            var property = param[3];
                                            var actionsOnProperty = param[2];
                                            var nextOverride = param[1];
                                            var prevOverride = param[0];
                                            return React.createElement(DiffEventVariants$PropertyPill, {
                                                        state: "updated",
                                                        propertyName: property.name,
                                                        children: null,
                                                        key: property.id
                                                      }, Belt_Array.some(actionsOnProperty, (function (action) {
                                                              if (typeof action !== "object") {
                                                                return false;
                                                              }
                                                              var variant = action.NAME;
                                                              if (variant === "RemovePropertyFromEventVariant") {
                                                                return true;
                                                              } else {
                                                                return variant === "AddPropertyToEventVariant";
                                                              }
                                                            })) ? React.createElement($$Text.make, {
                                                              size: "Small",
                                                              weight: "Regular",
                                                              color: Styles.Color.light11,
                                                              children: JsDiff.renderStringDiff(undefined, undefined, false, PrintPropertyOverrideDiffUseCase.includeExcludeOverride({
                                                                        TAG: "Overridden",
                                                                        _0: prevOverride
                                                                      }), PrintPropertyOverrideDiffUseCase.includeExcludeOverride({
                                                                        TAG: "Overridden",
                                                                        _0: nextOverride
                                                                      }))
                                                            }) : null, Belt_Array.some(actionsOnProperty, (function (action) {
                                                              if (typeof action !== "object") {
                                                                return false;
                                                              }
                                                              var variant = action.NAME;
                                                              if (variant === "UpdatePropertyPinnedValueOnEventVariant") {
                                                                return true;
                                                              } else {
                                                                return variant === "ClearPropertyPinnedValueFromEventVariant";
                                                              }
                                                            })) ? React.createElement($$Text.make, {
                                                              size: "Small",
                                                              weight: "Regular",
                                                              color: Styles.Color.light11,
                                                              children: JsDiff.renderStringDiff(undefined, undefined, false, PrintPropertyOverrideDiffUseCase.pinOverride({
                                                                        TAG: "Overridden",
                                                                        _0: prevOverride
                                                                      }), PrintPropertyOverrideDiffUseCase.pinOverride({
                                                                        TAG: "Overridden",
                                                                        _0: nextOverride
                                                                      }))
                                                            }) : null, Belt_Array.some(actionsOnProperty, (function (action) {
                                                              if (typeof action !== "object") {
                                                                return false;
                                                              }
                                                              var variant = action.NAME;
                                                              if (variant === "ClearPropertyRegexOverrideFromEventVariant") {
                                                                return true;
                                                              } else {
                                                                return variant === "UpdatePropertyRegexOverrideOnEventVariant";
                                                              }
                                                            })) ? React.createElement($$Text.make, {
                                                              size: "Small",
                                                              weight: "Regular",
                                                              color: Styles.Color.light11,
                                                              children: JsDiff.renderStringDiff(undefined, undefined, false, PrintPropertyOverrideDiffUseCase.regexOverride({
                                                                        TAG: "Overridden",
                                                                        _0: prevOverride
                                                                      }), PrintPropertyOverrideDiffUseCase.regexOverride({
                                                                        TAG: "Overridden",
                                                                        _0: nextOverride
                                                                      }))
                                                            }) : null, Belt_Array.some(actionsOnProperty, (function (action) {
                                                              if (typeof action === "object") {
                                                                return action.NAME === "SetPropertyAbsenceOnVariant";
                                                              } else {
                                                                return false;
                                                              }
                                                            })) ? React.createElement($$Text.make, {
                                                              size: "Small",
                                                              weight: "Regular",
                                                              color: Styles.Color.light11,
                                                              children: JsDiff.renderStringDiff(undefined, undefined, false, PrintPropertyOverrideDiffUseCase.absenceOverride({
                                                                        TAG: "Overridden",
                                                                        _0: prevOverride
                                                                      }), PrintPropertyOverrideDiffUseCase.absenceOverride({
                                                                        TAG: "Overridden",
                                                                        _0: nextOverride
                                                                      }))
                                                            }) : null, React.createElement(DiffEventVariants$DiffEventVariantPropertyAllowedValues, {
                                                            actionsOnProperty: actionsOnProperty,
                                                            prevAllowedValuesOverride: prevOverride.allowedValues
                                                          }));
                                          })), Belt_Array.mapU(removedPropertyOverrides, (function (param) {
                                            var property = param[0];
                                            return React.createElement(DiffEventVariants$PropertyPill, {
                                                        state: "removed",
                                                        propertyName: property.name,
                                                        children: PrintPropertyOverrideDiffUseCase.oneLine(param[1]),
                                                        key: property.id
                                                      });
                                          }))));
                    })));
  }
}

var make = DiffEventVariants;

export {
  make ,
}
/* contentStyles Not a pure module */
