// Generated by ReScript, PLEASE EDIT WITH CARE


function toHeader(format) {
  switch (format) {
    case "JsonSchema" :
    case "AvoJson" :
        return [
                "application/json",
                "json"
              ];
    case "Csv" :
        return [
                "text/csv",
                "csv"
              ];
    
  }
}

function toAnalytics(t) {
  switch (t) {
    case "JsonSchema" :
        return "Json";
    case "AvoJson" :
        return "AvoJson";
    case "Csv" :
        return "Csv";
    
  }
}

export {
  toHeader ,
  toAnalytics ,
}
/* No side effect */
