// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as CreateButtonIcon from "./CreateButtonIcon.mjs";
import * as EmptyStatesStyles from "./EmptyStatesStyles.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";

function PropertiesEmpty(Props) {
  var viewerCanEdit = Props.viewerCanEdit;
  var schemaBundle = SchemaBundleContext.use();
  var globalSend = GlobalSendContext.use();
  return React.createElement("main", {
              className: EmptyStatesStyles.rootStyles
            }, React.createElement(Title.make, {
                  children: "Enrich your events with Properties",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.light10,
                  maxWidth: Css.px(520),
                  children: null
                }, "In Avo you can create Events Properties, User Properties, and System Properties and they can be organized in Groups. Define them once, apply them everywhere. Read more about Properties in the ", React.createElement("a", {
                      className: EmptyStatesStyles.linkStyles,
                      href: "https://www.avo.app/docs/workspace/tracking-plan/properties",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "Avo docs"
                        })), "."), viewerCanEdit ? React.createElement("div", {
                    className: EmptyStatesStyles.actionsStyles
                  }, React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Properties", "Setup", undefined);
                            Curry._1(globalSend, {
                                  TAG: "OpenModal",
                                  _0: {
                                    NAME: "NewProperty",
                                    VAL: [
                                      "",
                                      "EventProperty",
                                      undefined,
                                      (function (propertyId, _propertyName) {
                                          Router.Schema.pushDrawerItem(undefined, undefined, {
                                                NAME: "property",
                                                VAL: [
                                                  propertyId,
                                                  undefined
                                                ]
                                              });
                                        }),
                                      "PropertiesView"
                                    ]
                                  }
                                });
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Create an Event Property"
                          })), React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Properties", "Setup", undefined);
                            Curry._1(globalSend, {
                                  TAG: "OpenModal",
                                  _0: {
                                    NAME: "NewProperty",
                                    VAL: [
                                      "",
                                      "UserProperty",
                                      undefined,
                                      (function (propertyId, _propertyName) {
                                          Router.Schema.pushDrawerItem(undefined, undefined, {
                                                NAME: "property",
                                                VAL: [
                                                  propertyId,
                                                  undefined
                                                ]
                                              });
                                        }),
                                      "PropertiesView"
                                    ]
                                  }
                                });
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Create a User Property"
                          })), React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Properties", "Setup", undefined);
                            Curry._1(globalSend, {
                                  TAG: "OpenModal",
                                  _0: {
                                    NAME: "NewProperty",
                                    VAL: [
                                      "",
                                      "SystemProperty",
                                      undefined,
                                      (function (propertyId, _propertyName) {
                                          Router.Schema.pushDrawerItem(undefined, undefined, {
                                                NAME: "property",
                                                VAL: [
                                                  propertyId,
                                                  undefined
                                                ]
                                              });
                                        }),
                                      "PropertiesView"
                                    ]
                                  }
                                });
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Create a System Property"
                          }))) : null);
}

var make = PropertiesEmpty;

export {
  make ,
}
/* Css Not a pure module */
