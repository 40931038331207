// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Zustand from "../Zustand.mjs";
import * as Zustand$1 from "zustand";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

var StoreConfig = {};

var ZustandStore = Zustand.MakeStore(StoreConfig);

var store = Zustand$1.create(function (set, _get) {
      return {
              scheduledGlobalSends: [],
              scheduleGlobalSend: (function (action) {
                  set(function (state) {
                        return {
                                scheduledGlobalSends: Belt_Array.concat(state.scheduledGlobalSends, [action]),
                                scheduleGlobalSend: state.scheduleGlobalSend,
                                currentBranch: state.currentBranch,
                                reset: state.reset
                              };
                      });
                }),
              currentBranch: undefined,
              reset: (function (currentBranch) {
                  set(function (state) {
                        return {
                                scheduledGlobalSends: [],
                                scheduleGlobalSend: state.scheduleGlobalSend,
                                currentBranch: currentBranch,
                                reset: state.reset
                              };
                      });
                })
            };
    });

function useScheduleGlobalSend(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.scheduleGlobalSend;
              }));
}

function useRunScheduledGlobalSends(currentBranch, globalSend) {
  var scheduledGlobalSends = Curry._2(ZustandStore.use, store, (function (state) {
          return state.scheduledGlobalSends;
        }));
  var resetScheduledGlobalSends = Curry._2(ZustandStore.use, store, (function (state) {
          return state.reset;
        }));
  var prevCurrentBranch = Curry._2(ZustandStore.use, store, (function (state) {
          return state.currentBranch;
        }));
  React.useEffect((function (param) {
          if (Caml_obj.notequal(prevCurrentBranch, currentBranch)) {
            Belt_Array.forEach(scheduledGlobalSends, Curry.__1(globalSend));
            Curry._1(resetScheduledGlobalSends, currentBranch);
          }
          
        }), [currentBranch]);
}

export {
  StoreConfig ,
  ZustandStore ,
  store ,
  useScheduleGlobalSend ,
  useRunScheduledGlobalSends ,
}
/* ZustandStore Not a pure module */
