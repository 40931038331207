// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Nanoid from "nanoid";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as NewEventModal from "./NewEventModal.mjs";
import * as PlanLightning from "./PlanLightning.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as AutofillInputNew from "./AutofillInputNew.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as NamespaceIndexStore from "./namespaceIndex/NamespaceIndexStore.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as AutoFillCasingDetail from "./AutoFillCasingDetail.mjs";
import * as AutoFillInputCreateItem from "./AutoFillInputCreateItem.mjs";

function getId($$event) {
  return $$event.id;
}

function messageToString(msg) {
  return msg;
}

var Config = {
  getId: getId,
  messageToString: messageToString
};

var EventAutofillInput = AutofillInputNew.Make(Config);

var rowActiveStyle_0 = Css.backgroundColor(Styles.Color.light02);

var rowActiveStyle_1 = {
  hd: Css.color(Styles.Color.darkBlue),
  tl: {
    hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
    tl: /* [] */0
  }
};

var rowActiveStyle = {
  hd: rowActiveStyle_0,
  tl: rowActiveStyle_1
};

var rowHoverStyle_0 = Css.backgroundColor(Styles.Color.light02);

var rowHoverStyle = {
  hd: rowHoverStyle_0,
  tl: /* [] */0
};

function queryEvents(events, selectedEvents, expectedEventCase, eventNamespaceIndex, query) {
  var availableEvents = Belt_List.keep(events, (function ($$event) {
          return !Belt_Array.some(selectedEvents, (function (selectedEventId) {
                        return selectedEventId === $$event.id;
                      }));
        }));
  var results = [[
      undefined,
      Belt_Array.map(Fuzzaldrin.filter(Belt_List.toArray(availableEvents), query, {
                key: "name",
                maxResults: 100
              }), (function ($$event) {
              return {
                      NAME: "Item",
                      VAL: $$event
                    };
            }))
    ]];
  var nameToCreate = Case.to_(expectedEventCase, query);
  var canCreate = !Belt_List.some(events, (function ($$event) {
          return Case.to_("Camel", $$event.name) === Case.to_("Camel", query);
        })) && Case.to_("Camel", query) !== "" && NewEventModal.isValid(nameToCreate, expectedEventCase, events);
  var namespaceIndexResults = Belt_SetString.fromArray(Fuzzaldrin.filter(Belt_MapString.keysToArray(eventNamespaceIndex), query, {
            maxResults: 5
          }));
  var filteredNamespaceIndex = Belt_Array.map(Belt_MapString.toArray(Belt_MapString.keep(eventNamespaceIndex, (function (eventName, param) {
                  return Belt_SetString.has(namespaceIndexResults, eventName);
                }))), (function (item) {
          return {
                  NAME: "ItemFromOtherBranch",
                  VAL: item
                };
        }));
  var otherNamespaceResults = Caml_obj.equal(filteredNamespaceIndex, []) ? [] : [[
        "Found on other branches",
        filteredNamespaceIndex
      ]];
  return Belt_Array.concatMany([
              results,
              otherNamespaceResults,
              canCreate ? [[
                    undefined,
                    [{
                        NAME: "CreateNew",
                        VAL: query
                      }]
                  ]] : []
            ]);
}

function onSelect(sendActions, onSelectSuccess, selectActions, item, param, param$1) {
  Curry.app(sendActions, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            Curry._3(onSelectSuccess, item.id, item.name, branch);
          }),
        undefined,
        Curry._1(selectActions, item.id)
      ]);
}

function EventAutofill(Props) {
  var events = Props.events;
  var selectedEventsOpt = Props.selectedEvents;
  var onSelectSuccess = Props.onSelectSuccess;
  var renderButton = Props.renderButton;
  var currentFilters = Props.currentFilters;
  var eventOrigin = Props.eventOrigin;
  var selectActions = Props.selectActions;
  var selectedEvents = selectedEventsOpt !== undefined ? selectedEventsOpt : [];
  var model = ModelStore.useModel();
  var mainModel = ModelStore.Mapped.useMainModel();
  var schemaBundle = SchemaBundleContext.use();
  var addToast = Toast.useAddToast();
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var config = match.config;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use();
  var sendActions = SendActionsContext.use();
  var pushPullBetweenBranchesAvailability = AvoLimits.PushPullBetweenBranches.computeAvailability(workspace);
  var hasBasicNameCasing = ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events);
  var eventNamespaceIndex = Curry._1(NamespaceIndexStore.Events.use, undefined).items;
  return React.createElement(EventAutofillInput.make, {
              onSelect: (function (param, param$1, param$2) {
                  return onSelect(sendActions, onSelectSuccess, selectActions, param, param$1, param$2);
                }),
              onCreate: (function (name, _var) {
                  if (!hasBasicNameCasing) {
                    return Curry._1(globalSend, {
                                TAG: "OpenModal",
                                _0: {
                                  NAME: "NewEvent",
                                  VAL: [
                                    name,
                                    undefined,
                                    Curry.__3(onSelectSuccess),
                                    selectActions,
                                    "MetricDetails",
                                    false
                                  ]
                                }
                              });
                  }
                  var name$1 = NameUtils.getCorrectCase(expectedEventCase, name);
                  var eventId = Nanoid.nanoid();
                  NewEventModal.createEvent(eventId, name$1, Case.toAnalytics(expectedEventCase), true, workspace, model, sendActions, addToast, currentFilters, undefined, (function (branch) {
                          Curry._3(onSelectSuccess, eventId, name$1, branch);
                        }), selectActions, schemaBundle, eventOrigin, config, undefined, undefined, undefined);
                }),
              onPull: (function (param, items, query, index) {
                  var pageName = eventOrigin === "PropertyDetails" ? "Property" : (
                      eventOrigin === "CategoryDetails" ? "Category" : (
                          eventOrigin === "MetricDetails" ? "Metric" : Pervasives.failwith("Unsupported property location for pageName: " + AnalyticsRe.eventOriginToJs(eventOrigin))
                        )
                    );
                  var branchItemSyncOrigin = eventOrigin === "PropertyDetails" ? "PropertyDetails" : (
                      eventOrigin === "CategoryDetails" ? "CategoryDetails" : (
                          eventOrigin === "MetricDetails" ? "MetricDetails" : Pervasives.failwith("Unsupported property location for branchItemSyncOrigin: " + AnalyticsRe.eventOriginToJs(eventOrigin))
                        )
                    );
                  Curry._1(globalSend, {
                        TAG: "OpenModal",
                        _0: {
                          NAME: "PullChanges",
                          VAL: [
                            items,
                            (function (sendActions, resolvedCopyItems) {
                                var maybeEvent = Belt_Array.get(Belt_Array.keepMap(resolvedCopyItems, (function (item) {
                                            if (typeof item === "object" && item.NAME === "event") {
                                              return item.VAL;
                                            }
                                            
                                          })), 0);
                                if (maybeEvent !== undefined) {
                                  return onSelect(sendActions, onSelectSuccess, selectActions, maybeEvent, query, index);
                                } else {
                                  return Curry._1(globalSend, {
                                              TAG: "OpenModal",
                                              _0: {
                                                NAME: "AlertModal",
                                                VAL: [
                                                  "Could not add event",
                                                  "Please try adding the event again.",
                                                  "Ok",
                                                  (function (param) {
                                                      
                                                    })
                                                ]
                                              }
                                            });
                                }
                              }),
                            pageName,
                            branchItemSyncOrigin
                          ]
                        }
                      });
                }),
              placeholder: "Add event...",
              search: (function (param) {
                  return queryEvents(events, selectedEvents, expectedEventCase, eventNamespaceIndex, param);
                }),
              renderItem: (function (item, isActive, isHoverable) {
                  var variant = item.NAME;
                  if (variant === "Message") {
                    return React.createElement("div", undefined, item.VAL);
                  }
                  if (variant === "CreateNew") {
                    return React.createElement(AutoFillInputCreateItem.make, {
                                name: item.VAL,
                                autoCorrect: hasBasicNameCasing,
                                correctCasing: expectedEventCase,
                                isActive: isActive,
                                isHoverable: isHoverable
                              });
                  }
                  if (variant === "CreateNewWithGrouping") {
                    return null;
                  }
                  if (variant === "Item") {
                    return React.createElement("div", {
                                className: Curry._1(Css.merge, {
                                      hd: isActive ? Curry._1(Css.style, {
                                              hd: Css.padding(Css.px(16)),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.light02),
                                                tl: {
                                                  hd: Css.color(Styles.Color.darkBlue),
                                                  tl: {
                                                    hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }) : Curry._1(Css.style, {
                                              hd: Css.padding(Css.px(16)),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.white),
                                                tl: {
                                                  hd: Css.color(Styles.Color.light12),
                                                  tl: {
                                                    hd: Css.border(Css.px(1), "solid", "transparent"),
                                                    tl: {
                                                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }),
                                      tl: {
                                        hd: !isActive && isHoverable ? Curry._1(Css.style, {
                                                hd: Css.hover({
                                                      hd: Css.backgroundColor(Styles.Color.light02),
                                                      tl: /* [] */0
                                                    }),
                                                tl: /* [] */0
                                              }) : Curry._1(Css.style, /* [] */0),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Semi",
                                    children: item.VAL.name
                                  }));
                  }
                  var match = item.VAL;
                  var branchIds = Belt_SetString.fromArray(Belt_Array.map(match[1], (function (param) {
                              return param.branchId;
                            })));
                  var match$1 = Belt_SetString.toArray(branchIds);
                  var tmp;
                  if (match$1.length !== 1) {
                    tmp = AvoUtils.plural("other branches", undefined, Belt_SetString.size(branchIds), "other branch");
                  } else {
                    var branchId = match$1[0];
                    tmp = NamedBranch.getName(NamedBranch.fromUnnamed__mapped(mainModel, {
                              NAME: "Branch",
                              VAL: branchId
                            }));
                  }
                  return React.createElement("div", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, {
                                          hd: Css.padding(Css.px(16)),
                                          tl: {
                                            hd: Css.border(Css.px(1), "solid", "transparent"),
                                            tl: {
                                              hd: Css.borderBottom(Css.px(1), Css.solid, isActive ? Styles.Color.darkBlue : Styles.Color.light04),
                                              tl: /* [] */0
                                            }
                                          }
                                        }),
                                    tl: {
                                      hd: Curry._1(Css.style, isActive ? rowActiveStyle : (
                                              isHoverable ? ({
                                                    hd: Css.hover(rowHoverStyle),
                                                    tl: /* [] */0
                                                  }) : /* [] */0
                                            )),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  children: match[0]
                                }), React.createElement(Spacer.make, {
                                  height: 8
                                }), React.createElement($$Text.make, {
                                  element: "Span",
                                  size: "Tiny",
                                  color: Styles.Color.light12,
                                  children: React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, "Pull from ", React.createElement(Spacer.make, {
                                            width: 4
                                          }), React.createElement(Icon.make, {
                                            type_: "branch",
                                            size: {
                                              NAME: "int",
                                              VAL: 12
                                            },
                                            color: Styles.Color.light11
                                          }), React.createElement(Spacer.make, {
                                            width: 2
                                          }), React.createElement($$Text.make, {
                                            element: "Span",
                                            weight: "Semi",
                                            color: Styles.Color.light11,
                                            children: tmp
                                          }), pushPullBetweenBranchesAvailability === "Available" ? null : (
                                          pushPullBetweenBranchesAvailability === "AvailableDuringTrial" ? React.createElement("span", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css_Legacy_Core.SVG.fill("transparent"),
                                                        tl: {
                                                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light11),
                                                          tl: {
                                                            hd: Css.marginLeft(Css.px(4)),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      })
                                                }, React.createElement(PlanLightning.make, {
                                                      tooltipText: "Pull events from other branches is available during your trial"
                                                    })) : React.createElement("span", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css_Legacy_Core.SVG.fill("transparent"),
                                                        tl: {
                                                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light11),
                                                          tl: {
                                                            hd: Css.marginLeft(Css.px(4)),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      })
                                                }, React.createElement(PlanLightning.make, {
                                                      tooltipText: "Upgrade your plan to pull events from other branches"
                                                    }))
                                        ))
                                }));
                }),
              renderDetails: (function (x) {
                  var variant = x.NAME;
                  if (variant === "CreateNewWithGrouping" || variant === "Message") {
                    return null;
                  }
                  if (variant === "CreateNew") {
                    var name = x.VAL;
                    if (hasBasicNameCasing) {
                      if (NameUtils.isCorrectCase(expectedEventCase, name)) {
                        return null;
                      } else {
                        return React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.padding(Css.px(16)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(AutoFillCasingDetail.make, {
                                        autoCorrect: true,
                                        toggleAutoCorrect: (function (param) {
                                            
                                          }),
                                        canDisableAutoCorrect: false,
                                        expectedCase: expectedEventCase,
                                        name: name,
                                        kind: "Event"
                                      }));
                      }
                    } else {
                      return React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.padding(Css.px(16)),
                                        tl: /* [] */0
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Medium",
                                      weight: "Semi",
                                      color: Styles.Color.light11,
                                      children: "\"" + name + "\" not found"
                                    }), React.createElement(Spacer.make, {
                                      height: 8
                                    }), React.createElement($$Text.make, {
                                      size: "Small",
                                      color: Styles.Color.light11,
                                      children: "naming convention: \"" + ValidationConfigV2.EventsV2.getNamesAsStructuredString(config.events) + "\""
                                    }));
                    }
                  }
                  if (variant !== "Item") {
                    return null;
                  }
                  var $$event = x.VAL;
                  var maybeEvent = Belt_List.getBy(events, (function (item) {
                          return $$event.id === item.id;
                        }));
                  var tmp;
                  if (maybeEvent !== undefined) {
                    var sources = maybeEvent.includeSources;
                    tmp = React.createElement("div", undefined, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: /* [] */0
                                    }
                                  })
                            }, maybeEvent.name), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: {
                                      hd: Css.padding2(Css.px(10), Css.px(0)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, maybeEvent.description), sources ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.small),
                                        tl: {
                                          hd: Css.wordWrap("breakWord"),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, "Sent from " + Belt_Array.map(Belt_Array.keepMap(Belt_List.toArray(sources), (function (source) {
                                            return ModelUtils.getSourceById(source.id, model);
                                          })), AvoConfig.getSourceName).join(", ")) : React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.color(Styles.Color.red),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.small),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, "Not sent from any source"));
                  } else {
                    tmp = React.createElement("div", undefined, $$event.name);
                  }
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding(Css.px(15)),
                                    tl: {
                                      hd: Css.overflow("auto"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, tmp);
                }),
              refreshKey: [
                events,
                selectedEvents
              ],
              renderButton: renderButton,
              resultsContainerStyles: {
                hd: Css.left(Css.px(0)),
                tl: {
                  hd: Css.width("auto"),
                  tl: {
                    hd: Css.right(Css.px(0)),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var make = EventAutofill;

export {
  Config ,
  EventAutofillInput ,
  rowActiveStyle ,
  rowHoverStyle ,
  queryEvents ,
  onSelect ,
  make ,
}
/* EventAutofillInput Not a pure module */
