// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Quill from "./externals/quill.mjs";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Collapsible from "./Collapsible.mjs";
import QuillDelta from "quill-delta";
import * as DiffComments from "./DiffComments.mjs";
import * as DiffComponents from "./DiffComponents.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as DiffCollapseButton from "./DiffCollapseButton.mjs";

function DiffGoal(Props) {
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var withComments = Props.withComments;
  var events = Props.events;
  var collapsibleOpt = Props.collapsible;
  var rightElementOpt = Props.rightElement;
  var collapsible = collapsibleOpt !== undefined ? collapsibleOpt : false;
  var rightElement = rightElementOpt !== undefined ? Caml_option.valFromOption(rightElementOpt) : null;
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "Goal") {
    return null;
  }
  var goalId = match.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.someU(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "AddGoal";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.someU(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "Goal";
          } else {
            return false;
          }
        }));
  var goal = Belt_Option.getWithDefault(Belt_List.getBy(masterModel.goals, (function (goal) {
              return goal.id === goalId;
            })), TrackingPlanModel.emptyGoal(goalId));
  var nextGoal = Belt_Option.getWithDefault(Belt_List.getBy(branchModel.goals, (function (goal) {
              return goal.id === goalId;
            })), TrackingPlanModel.emptyGoal(goalId));
  var addMetricActions = Belt_List.keepMapU(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "AddMetricToGoal") {
            return x;
          }
          
        }));
  var removeMetricActions = Belt_List.keepMapU(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "RemoveMetricFromGoal") {
            return x;
          }
          
        }));
  var addEventActions = Belt_List.keepMapU(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "AddEventToGoal") {
            return x;
          }
          
        }));
  var removeEventActions = Belt_List.keepMapU(actions$1, (function (x) {
          if (typeof x === "object" && x.NAME === "RemoveEventFromGoal") {
            return x;
          }
          
        }));
  var maybeDescriptionAction = Belt_List.getByU(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "UpdateGoalDescription";
          } else {
            return false;
          }
        }));
  var match$1 = Hooks.useDisclosure(collapsible);
  var collapseAction = match$1[1];
  var collapsed = match$1[0];
  var tmp;
  if (withComments) {
    var tmp$1 = {
      branchId: branchId,
      threadId: goal.id,
      itemType: "Goal",
      itemName: goal.name,
      events: events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement(DiffComments.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement(DiffComponents.Item.make, {
                  borderColor: hasCreate ? Styles.Color.darkGreen : (
                      hasArchive ? Styles.Color.darkRed : Styles.Color.light06
                    ),
                  children: null,
                  onClick: (function (param) {
                      if (collapsible) {
                        return Curry._1(collapseAction.toggle, undefined);
                      } else {
                        return Router.Schema.pushDrawerItem(undefined, undefined, {
                                    NAME: "category",
                                    VAL: [
                                      goal.id,
                                      undefined
                                    ]
                                  });
                      }
                    })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin(Css.px(-15)),
                            tl: {
                              hd: Css.marginBottom(Css.px(-20)),
                              tl: {
                                hd: Css.marginRight(Css.px(-26)),
                                tl: {
                                  hd: Css.padding(Css.px(23)),
                                  tl: {
                                    hd: Css.borderBottom(Css.px(1), Css.solid, collapsed ? "transparent" : Styles.Color.light04),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, collapsible ? React.createElement(DiffCollapseButton.make, {
                            toggle: collapseAction.toggle,
                            isOpen: !collapsed,
                            changeType: hasCreate ? "Create" : (
                                hasArchive ? "Archive" : "Update"
                              )
                          }) : null, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: JsDiff.renderStringDiff(undefined, undefined, undefined, goal.name, nextGoal.name)
                        }), React.createElement(Spacer.make, {
                          width: 4,
                          grow: 1.0
                        }), rightElement), React.createElement(Collapsible.make, {
                      collapsed: collapsed,
                      children: null
                    }, React.createElement(Spacer.make, {
                          height: 20
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.paddingTop(Css.px(24)),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("column"),
                                    tl: {
                                      hd: Css.gap(Css.px(8)),
                                      tl: {
                                        hd: Css.padding(Css.px(8)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, maybeDescriptionAction !== undefined ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontSize(Styles.FontSize.small),
                                      tl: /* [] */0
                                    }),
                                dangerouslySetInnerHTML: {
                                  __html: Quill.toHTMLWithDiff(new QuillDelta(JSON.parse(goal.description)), new QuillDelta(JSON.parse(nextGoal.description)), undefined, undefined)
                                }
                              }) : null, addMetricActions === /* [] */0 && removeMetricActions === /* [] */0 ? null : React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light11,
                                children: "Metrics"
                              }), Belt_List.toArray(Belt_List.map(Belt_List.keepMapU(addMetricActions, (function (x) {
                                        if (typeof x !== "object") {
                                          return ;
                                        }
                                        if (x.NAME !== "AddMetricToGoal") {
                                          return ;
                                        }
                                        var metricId = x.VAL[1];
                                        return Belt_List.getBy(branchModel.metrics, (function (metric) {
                                                      return metric.id === metricId;
                                                    }));
                                      })), (function (metric) {
                                    return React.createElement(DiffComponents.Row.make, {
                                                label: metric.name,
                                                change: "Add",
                                                key: metric.id
                                              });
                                  }))), Belt_List.toArray(Belt_List.map(Belt_List.keepMapU(removeMetricActions, (function (action) {
                                        if (typeof action !== "object") {
                                          return ;
                                        }
                                        if (action.NAME !== "RemoveMetricFromGoal") {
                                          return ;
                                        }
                                        var metricId = action.VAL[1];
                                        return Belt_List.getBy(branchModel.metrics, (function (metric) {
                                                      return metric.id === metricId;
                                                    }));
                                      })), (function (metric) {
                                    return React.createElement(DiffComponents.Row.make, {
                                                label: metric.name,
                                                change: "Remove",
                                                key: metric.id
                                              });
                                  }))), addEventActions === /* [] */0 && removeEventActions === /* [] */0 ? null : React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light11,
                                children: "Events"
                              }), Belt_List.toArray(Belt_List.map(Belt_List.keepMapU(addEventActions, (function (action) {
                                        if (typeof action !== "object") {
                                          return ;
                                        }
                                        if (action.NAME !== "AddEventToGoal") {
                                          return ;
                                        }
                                        var eventId = action.VAL[1];
                                        return Belt_List.getBy(branchModel.events, (function ($$event) {
                                                      return $$event.id === eventId;
                                                    }));
                                      })), (function ($$event) {
                                    return React.createElement(DiffComponents.Row.make, {
                                                label: $$event.name,
                                                change: "Add",
                                                key: $$event.id
                                              });
                                  }))), Belt_List.toArray(Belt_List.map(Belt_List.keepMapU(removeEventActions, (function (action) {
                                        if (typeof action !== "object") {
                                          return ;
                                        }
                                        if (action.NAME !== "RemoveEventFromGoal") {
                                          return ;
                                        }
                                        var eventId = action.VAL[1];
                                        return Belt_List.getBy(branchModel.events, (function ($$event) {
                                                      return $$event.id === eventId;
                                                    }));
                                      })), (function ($$event) {
                                    return React.createElement(DiffComponents.Row.make, {
                                                label: $$event.name,
                                                change: "Remove",
                                                key: $$event.id
                                              });
                                  })))))), tmp);
}

var make = DiffGoal;

export {
  make ,
}
/* Css Not a pure module */
