// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AvoModel from "../../../app/src/avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as TrackingPlanModel from "../TrackingPlanModel.mjs";
import * as BeltListExtensions from "../../../app/src/BeltListExtensions.mjs";
import * as GetRegexValidationUseCase from "../../../app/src/regex/GetRegexValidationUseCase.mjs";

function filterOutRemovals(overrides) {
  return Belt_List.keepU(Belt_MapString.toList(overrides), (function (param) {
                var tmp = param[1];
                if (typeof tmp !== "object") {
                  return false;
                } else {
                  return true;
                }
              }));
}

function computeIncludedSourcesUnion(baseEvent) {
  var variants = baseEvent.variants;
  if (variants.length === 0) {
    return baseEvent.includeSources;
  }
  var includedSourcesOverridesNotRemovals = BeltListExtensions.flatMap(Belt_List.fromArray(variants), (function (variant) {
          return filterOutRemovals(variant.sourceOverrides);
        }));
  var match = Belt_List.partitionU(baseEvent.includeSources, (function (includedSource) {
          return Belt_List.getByU(includedSourcesOverridesNotRemovals, (function (param) {
                        return param[0] === includedSource.id;
                      })) !== undefined;
        }));
  var newIncludedSourcesInVariants = Belt_List.mapU(Belt_List.keepU(includedSourcesOverridesNotRemovals, (function (param) {
              var sourceId = param[0];
              return Belt_List.getByU(baseEvent.includeSources, (function (includedSource) {
                            return includedSource.id === sourceId;
                          })) === undefined;
            })), (function (param) {
          var sourceOverride = param[1];
          var tmp;
          if (typeof sourceOverride !== "object") {
            tmp = false;
          } else {
            var includeInCodegen = sourceOverride._0.includeInCodegen;
            tmp = typeof includeInCodegen !== "object" ? false : includeInCodegen._0;
          }
          return {
                  id: param[0],
                  includeInCodegen: tmp,
                  inspectorValidation: undefined
                };
        }));
  var overriddenIncludedSources = Belt_List.mapU(match[0], (function (baseIncludedSource) {
          var hasIncludedSourcesInAtLeastOneVariant = Belt_List.someU(includedSourcesOverridesNotRemovals, (function (param) {
                  var sourceOverride = param[1];
                  if (param[0] !== baseIncludedSource.id) {
                    return false;
                  }
                  if (typeof sourceOverride !== "object") {
                    return false;
                  }
                  var includeInCodegenInVariant = sourceOverride._0.includeInCodegen;
                  if (typeof includeInCodegenInVariant !== "object") {
                    return false;
                  } else {
                    return includeInCodegenInVariant._0;
                  }
                }));
          return {
                  id: baseIncludedSource.id,
                  includeInCodegen: baseIncludedSource.includeInCodegen || hasIncludedSourcesInAtLeastOneVariant,
                  inspectorValidation: baseIncludedSource.inspectorValidation
                };
        }));
  return Belt_List.concatMany([
              match[1],
              newIncludedSourcesInVariants,
              overriddenIncludedSources
            ]);
}

function computePropertyRefsUnion(baseEvent) {
  var variants = baseEvent.variants;
  if (variants.length === 0) {
    return baseEvent.directPropertyRefs;
  }
  var propertyOverridesNotRemovals = BeltListExtensions.flatMap(Belt_List.fromArray(variants), (function (variant) {
          return filterOutRemovals(variant.propertyOverrides);
        }));
  var match = Belt_List.partitionU(Belt_List.keepMapU(baseEvent.directPropertyRefs, ModelUtils.getPropertyRefFromProperty), (function (propertyRef) {
          return Belt_List.getByU(propertyOverridesNotRemovals, (function (param) {
                        return param[0] === propertyRef.id;
                      })) !== undefined;
        }));
  var overriddenProperties = Belt_List.reduceU(propertyOverridesNotRemovals, match[0], (function (overriddenPropertyRefs, param) {
          var propertyOverride = param[1];
          var propertyId = param[0];
          var match = Belt_List.getByU(overriddenPropertyRefs, (function (overridenPropertyRef) {
                  return overridenPropertyRef.id === propertyId;
                }));
          if (match !== undefined) {
            return Belt_List.mapU(overriddenPropertyRefs, (function (overridenPropertyRef) {
                          if (overridenPropertyRef.id !== propertyId) {
                            return overridenPropertyRef;
                          }
                          var tmp;
                          if (typeof propertyOverride !== "object") {
                            tmp = undefined;
                          } else {
                            var pinnedOverride = propertyOverride._0.pinnedValue;
                            if (typeof pinnedOverride !== "object") {
                              tmp = overridenPropertyRef.pinnedValue;
                            } else {
                              var pinnedOverride$1 = pinnedOverride._0;
                              tmp = Caml_obj.equal(overridenPropertyRef.pinnedValue, pinnedOverride$1) ? pinnedOverride$1 : undefined;
                            }
                          }
                          return {
                                  id: overridenPropertyRef.id,
                                  description: overridenPropertyRef.description,
                                  pinnedValue: tmp
                                };
                        }));
          }
          var init = TrackingPlanModel.emptyPropertyRef(propertyId);
          var tmp;
          if (typeof propertyOverride !== "object") {
            tmp = undefined;
          } else {
            var pinnedOverride = propertyOverride._0.pinnedValue;
            tmp = typeof pinnedOverride !== "object" ? undefined : pinnedOverride._0;
          }
          return {
                  hd: {
                    id: init.id,
                    description: init.description,
                    pinnedValue: tmp
                  },
                  tl: overriddenPropertyRefs
                };
        }));
  return Belt_List.mapU(Belt_List.concat(match[1], overriddenProperties), (function (propertyRef) {
                return {
                        TAG: "PropertyRef",
                        _0: propertyRef
                      };
              }));
}

function getConsistentRegexOverAllVariantsAndBaseEvent($$event, property) {
  var regex = GetRegexValidationUseCase.get(property);
  var baseEventSpecificRegex = regex !== undefined ? Belt_Option.mapU(Belt_MapString.get(regex.eventOverrides, $$event.id), (function (regex) {
            return regex.regex;
          })) : undefined;
  var variantsSpecificRegex = Belt_Array.reduceU($$event.variants, "NoRegex", (function (sameRegex, variant) {
          var variantPropertyOverride = Belt_MapString.get(variant.propertyOverrides, property.id);
          var variantSpecificRegexOverride;
          if (variantPropertyOverride !== undefined && typeof variantPropertyOverride === "object") {
            var match = variantPropertyOverride._0.regexValidation;
            if (typeof match !== "object") {
              variantSpecificRegexOverride = "NotSet";
            } else {
              var regexOverride = match._0;
              variantSpecificRegexOverride = regexOverride !== undefined ? ({
                    NAME: "SomeValue",
                    VAL: regexOverride.regex
                  }) : "AnyValue";
            }
          } else {
            variantSpecificRegexOverride = "NotSet";
          }
          if (variantSpecificRegexOverride === "AnyValue") {
            return "InconsistentRegex";
          }
          if (typeof sameRegex !== "object") {
            if (sameRegex === "InconsistentRegex") {
              return "InconsistentRegex";
            } else if (typeof variantSpecificRegexOverride === "object") {
              return {
                      NAME: "SameRegex",
                      VAL: variantSpecificRegexOverride.VAL
                    };
            } else {
              return "NoRegex";
            }
          }
          var regex = sameRegex.VAL;
          if (typeof variantSpecificRegexOverride === "object" && regex !== variantSpecificRegexOverride.VAL) {
            return "InconsistentRegex";
          } else {
            return {
                    NAME: "SameRegex",
                    VAL: regex
                  };
          }
        }));
  if (typeof variantsSpecificRegex !== "object") {
    if (variantsSpecificRegex === "InconsistentRegex") {
      return ;
    } else {
      return baseEventSpecificRegex;
    }
  }
  var variantRegex = variantsSpecificRegex.VAL;
  if (Caml_obj.equal(variantRegex, baseEventSpecificRegex)) {
    return variantRegex;
  }
  
}

function unionSingleEventVariants(model, $$event) {
  if (Caml_obj.equal($$event.variants, [])) {
    return model;
  }
  var modelWithUnionEvent = ModelUtils.updateEvent(model, $$event.id, (function ($$event) {
          return {
                  id: $$event.id,
                  name: $$event.name,
                  uniqueName: $$event.uniqueName,
                  description: $$event.description,
                  directPropertyRefs: computePropertyRefsUnion($$event),
                  propertyBundles: $$event.propertyBundles,
                  variants: [],
                  types: $$event.types,
                  tags: $$event.tags,
                  excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                  includeSources: computeIncludedSourcesUnion($$event),
                  includeDestinations: $$event.includeDestinations,
                  hashes: $$event.hashes,
                  propertyWhitelist: $$event.propertyWhitelist,
                  eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                  userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                  triggers: $$event.triggers,
                  globalRequirementsMetadata: $$event.globalRequirementsMetadata
                };
        }));
  var modelWithUnionEventAndAbsences = Belt_Array.reduceU($$event.variants, modelWithUnionEvent, (function (modelToEnrichWithAllVariantAbsences, variant) {
          return Belt_Array.reduceU(Belt_MapString.toArray(variant.propertyOverrides), modelToEnrichWithAllVariantAbsences, (function (modelToEnrichWithSinglePropertyOverrideAbsence, param) {
                        var propertyId = param[0];
                        var propertyOverride = param[1];
                        var property = ModelUtils.resolvePropertyById(propertyId, modelToEnrichWithSinglePropertyOverrideAbsence);
                        if (property === undefined) {
                          return modelToEnrichWithSinglePropertyOverrideAbsence;
                        }
                        var isPropertyIncludedOnEvent = ModelUtils.isPropertyIncludedOnEventAndSources(undefined, property, $$event.id, Belt_List.map($$event.includeSources, (function (includedSource) {
                                    return includedSource.id;
                                  })), model, undefined);
                        var match = isPropertyIncludedOnEvent ? "PropertyIsIncludedInBase" : "PropertyNotIncludedInBase";
                        var exit = 0;
                        var absenceOverride;
                        if (typeof propertyOverride !== "object") {
                          if (match === "PropertyNotIncludedInBase") {
                            return modelToEnrichWithSinglePropertyOverrideAbsence;
                          }
                          exit = 1;
                        } else {
                          var absenceOverride$1 = propertyOverride._0.absence;
                          if (typeof absenceOverride$1 !== "object") {
                            if (match === "PropertyIsIncludedInBase") {
                              return modelToEnrichWithSinglePropertyOverrideAbsence;
                            }
                            absenceOverride = propertyOverride._0.absence;
                            exit = 2;
                          } else {
                            switch (absenceOverride$1._0) {
                              case "AlwaysSent" :
                                  if (match === "PropertyIsIncludedInBase") {
                                    return modelToEnrichWithSinglePropertyOverrideAbsence;
                                  }
                                  absenceOverride = propertyOverride._0.absence;
                                  exit = 2;
                                  break;
                              case "SometimesSent" :
                                  if (match === "PropertyIsIncludedInBase") {
                                    absenceOverride = absenceOverride$1;
                                    exit = 2;
                                  } else {
                                    absenceOverride = propertyOverride._0.absence;
                                    exit = 2;
                                  }
                                  break;
                              case "NeverSent" :
                                  if (match === "PropertyNotIncludedInBase") {
                                    return ModelUtils.updateProperty(modelToEnrichWithSinglePropertyOverrideAbsence, propertyId, (function (property) {
                                                  var newrecord = Caml_obj.obj_dup(property);
                                                  var match = property.absence;
                                                  var tmp;
                                                  tmp = match !== undefined ? (
                                                      typeof match !== "object" ? ({
                                                            TAG: "Mixed",
                                                            _0: Belt_MapString.fromArray([[
                                                                    $$event.id,
                                                                    {
                                                                      TAG: "AllSources",
                                                                      _0: "NeverSent"
                                                                    }
                                                                  ]])
                                                          }) : (
                                                          match.TAG === "SometimesSent" ? ({
                                                                TAG: "SometimesSent",
                                                                _0: match._0
                                                              }) : ({
                                                                TAG: "Mixed",
                                                                _0: Belt_MapString.set(match._0, $$event.id, {
                                                                      TAG: "AllSources",
                                                                      _0: "NeverSent"
                                                                    })
                                                              })
                                                        )
                                                    ) : undefined;
                                                  newrecord.absence = tmp;
                                                  return newrecord;
                                                }));
                                  }
                                  exit = 1;
                                  break;
                              
                            }
                          }
                        }
                        switch (exit) {
                          case 1 :
                              return ModelUtils.updateProperty(modelToEnrichWithSinglePropertyOverrideAbsence, propertyId, (function (property) {
                                            var newrecord = Caml_obj.obj_dup(property);
                                            var absence = property.absence;
                                            var tmp;
                                            if (absence !== undefined) {
                                              if (typeof absence !== "object") {
                                                tmp = {
                                                  TAG: "Mixed",
                                                  _0: Belt_MapString.fromArray([[
                                                          $$event.id,
                                                          {
                                                            TAG: "AllSources",
                                                            _0: {
                                                              TAG: "SometimesSent",
                                                              _0: "Never sent in a variant"
                                                            }
                                                          }
                                                        ]])
                                                };
                                              } else if (absence.TAG === "SometimesSent") {
                                                tmp = absence;
                                              } else {
                                                var absence$1 = absence._0;
                                                tmp = Belt_MapString.get(absence$1, $$event.id) === undefined ? ({
                                                      TAG: "Mixed",
                                                      _0: Belt_MapString.set(absence$1, $$event.id, {
                                                            TAG: "AllSources",
                                                            _0: {
                                                              TAG: "SometimesSent",
                                                              _0: "Never sent in a variant"
                                                            }
                                                          })
                                                    }) : absence;
                                              }
                                            } else {
                                              tmp = undefined;
                                            }
                                            newrecord.absence = tmp;
                                            return newrecord;
                                          }));
                          case 2 :
                              var message;
                              if (typeof absenceOverride !== "object") {
                                message = "Present in a variant";
                              } else {
                                switch (absenceOverride._0) {
                                  case "AlwaysSent" :
                                      message = "Always sent in a variant";
                                      break;
                                  case "SometimesSent" :
                                      message = "Sometimes sent in a variant";
                                      break;
                                  case "NeverSent" :
                                      message = "Never sent in a variant";
                                      break;
                                  
                                }
                              }
                              return ModelUtils.updateProperty(modelToEnrichWithSinglePropertyOverrideAbsence, propertyId, (function (property) {
                                            var newrecord = Caml_obj.obj_dup(property);
                                            var match = property.absence;
                                            var tmp;
                                            tmp = match !== undefined ? (
                                                typeof match !== "object" ? ({
                                                      TAG: "Mixed",
                                                      _0: Belt_MapString.fromArray([[
                                                              $$event.id,
                                                              {
                                                                TAG: "AllSources",
                                                                _0: {
                                                                  TAG: "SometimesSent",
                                                                  _0: message
                                                                }
                                                              }
                                                            ]])
                                                    }) : (
                                                    match.TAG === "SometimesSent" ? ({
                                                          TAG: "SometimesSent",
                                                          _0: match._0
                                                        }) : ({
                                                          TAG: "Mixed",
                                                          _0: Belt_MapString.set(match._0, $$event.id, {
                                                                TAG: "AllSources",
                                                                _0: {
                                                                  TAG: "SometimesSent",
                                                                  _0: message
                                                                }
                                                              })
                                                        })
                                                  )
                                              ) : undefined;
                                            newrecord.absence = tmp;
                                            return newrecord;
                                          }));
                          
                        }
                      }));
        }));
  var modelWithUnionEventAndAllowedValues = Belt_Array.reduceU($$event.variants, modelWithUnionEventAndAbsences, (function (modelToEnrichWithAllVariantAllowedValues, variant) {
          return Belt_Array.reduceU(Belt_MapString.toArray(variant.propertyOverrides), modelToEnrichWithAllVariantAllowedValues, (function (modelToEnrichWithSinglePropertyOverrideAllowedValues, param) {
                        var propertyId = param[0];
                        var propertyOverride = param[1];
                        var property = ModelUtils.resolvePropertyById(propertyId, modelToEnrichWithSinglePropertyOverrideAllowedValues);
                        if (property === undefined) {
                          return modelToEnrichWithSinglePropertyOverrideAllowedValues;
                        }
                        ModelUtils.isPropertyIncludedOnEventAndSources(undefined, property, $$event.id, Belt_List.map($$event.includeSources, (function (includedSource) {
                                    return includedSource.id;
                                  })), model, undefined);
                        if (typeof propertyOverride !== "object") {
                          return modelToEnrichWithSinglePropertyOverrideAllowedValues;
                        }
                        var allowedValuesOverride = propertyOverride._0.allowedValues;
                        if (typeof allowedValuesOverride !== "object") {
                          return modelToEnrichWithSinglePropertyOverrideAllowedValues;
                        }
                        var allowedValuesOverride$1 = allowedValuesOverride._0;
                        return ModelUtils.updateProperty(modelToEnrichWithSinglePropertyOverrideAllowedValues, propertyId, (function (property) {
                                      var newrecord = Caml_obj.obj_dup(property);
                                      newrecord.eventSpecificAllowedPropertyValues = Belt_Array.mapU(property.eventSpecificAllowedPropertyValues, (function (param) {
                                              var disallowedEventsToSources = param[1];
                                              var allowedValueLiteral = param[0];
                                              var allowedValuesString = typeof allowedValueLiteral === "object" && allowedValueLiteral.NAME === "StringLit" ? allowedValueLiteral.VAL : Pervasives.failwith("Only string literals are supported for allowed values");
                                              var maybeAllowedValueDisallowedSourcesOverride = Belt_MapString.get(allowedValuesOverride$1, allowedValuesString);
                                              var newDisallowedEventsToSources = maybeAllowedValueDisallowedSourcesOverride !== undefined ? Belt_MapString.update(disallowedEventsToSources, $$event.id, (function (baseEventDisallowedSources) {
                                                        if (baseEventDisallowedSources === undefined) {
                                                          return ;
                                                        }
                                                        if (typeof baseEventDisallowedSources !== "object") {
                                                          if (typeof maybeAllowedValueDisallowedSourcesOverride !== "object") {
                                                            return "Disallowed";
                                                          }
                                                          
                                                        } else if (typeof maybeAllowedValueDisallowedSourcesOverride !== "object") {
                                                          return baseEventDisallowedSources;
                                                        }
                                                        if (Belt_SetString.isEmpty(maybeAllowedValueDisallowedSourcesOverride._0)) {
                                                          return ;
                                                        } else if (typeof baseEventDisallowedSources !== "object") {
                                                          return maybeAllowedValueDisallowedSourcesOverride;
                                                        } else {
                                                          return {
                                                                  TAG: "DisallowedSources",
                                                                  _0: Belt_SetString.intersect(baseEventDisallowedSources._0, maybeAllowedValueDisallowedSourcesOverride._0)
                                                                };
                                                        }
                                                      })) : disallowedEventsToSources;
                                              return [
                                                      allowedValueLiteral,
                                                      newDisallowedEventsToSources
                                                    ];
                                            }));
                                      return newrecord;
                                    }));
                      }));
        }));
  return Belt_List.reduceU(AvoModel.getResolvedPropertiesForEvent(modelWithUnionEventAndAllowedValues, $$event), modelWithUnionEventAndAllowedValues, (function (modelEnriching, property) {
                return ModelUtils.updateProperty(modelEnriching, property.id, (function (property) {
                              var consistentRegex = getConsistentRegexOverAllVariantsAndBaseEvent($$event, property);
                              var newrecord = Caml_obj.obj_dup(property);
                              newrecord.validations = Belt_List.keepMapU(property.validations, (function (validation) {
                                      if (typeof validation !== "object") {
                                        return validation;
                                      }
                                      if (validation.NAME !== "Regex") {
                                        return validation;
                                      }
                                      var regex = validation.VAL;
                                      var newValidation = consistentRegex !== undefined ? ({
                                            propertyRule: regex.propertyRule,
                                            eventOverrides: Belt_MapString.set(regex.eventOverrides, $$event.id, {
                                                  regex: consistentRegex,
                                                  testValue: Belt_Option.getWithDefault(Belt_Option.mapU(Belt_MapString.get(regex.eventOverrides, $$event.id), (function (regex) {
                                                              return regex.testValue;
                                                            })), "")
                                                })
                                          }) : ({
                                            propertyRule: undefined,
                                            eventOverrides: Belt_MapString.remove(regex.eventOverrides, $$event.id)
                                          });
                                      if (newValidation.propertyRule !== undefined || Belt_MapString.size(newValidation.eventOverrides) > 0) {
                                        return {
                                                NAME: "Regex",
                                                VAL: newValidation
                                              };
                                      }
                                      
                                    }));
                              return newrecord;
                            }));
              }));
}

function unionAllEventVariants(model) {
  return Belt_List.reduceU(model.events, model, unionSingleEventVariants);
}

export {
  unionSingleEventVariants ,
  unionAllEventVariants ,
}
/* AvoModel Not a pure module */
