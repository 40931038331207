// Generated by ReScript, PLEASE EDIT WITH CARE


function triggerLink(schemaId, branchId, eventId, triggerId) {
  if (branchId === "master") {
    return "https://www.avo.app/schemas/" + schemaId + "/events/" + eventId + "/trigger/" + triggerId;
  } else {
    return "https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/events/" + eventId + "/trigger/" + triggerId;
  }
}

export {
  triggerLink ,
}
/* No side effect */
