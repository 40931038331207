// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Guides from "./markdown/guides.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DocsLink from "./DocsLink.mjs";
import * as FileView from "./FileView.mjs";
import * as Markdown from "./Markdown.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SourceSetupDestinationCodeExamples from "./SourceSetupDestinationCodeExamples.mjs";

function renderWarning(message) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.backgroundColor(Styles.Color.red),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", Styles.Color.darkRed),
                      tl: {
                        hd: Css.color(Styles.Color.white),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.margin2(Css.px(20), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, message);
}

var destinationInterfaceIntroText = "The Destination Interface is a set of callbacks to connect Avo to your existing tracking SDK or endpoint. Below is an example of a Destination Interface. You'll pass the Destination Interface into the Avo initilization method.";

var destinationInterfaceIntro = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
          color: Styles.Color.light11,
          children: destinationInterfaceIntroText
        }), React.createElement(Spacer.make, {
          height: 4
        }), React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: /* [] */0
              })
        }, React.createElement($$Text.make, {
              element: "Span",
              color: Styles.Color.light11,
              children: "Learn more about the Destination Interface "
            }), React.createElement(Spacer.make, {
              width: 4
            }), React.createElement(DocsLink.make, {
              path: "implementation/destinations#destination-interface-overview",
              label: "in the docs"
            })), React.createElement(Spacer.make, {
          height: 8
        }));

var changeToDestinationInterface = React.createElement($$Text.make, {
      color: Styles.Color.light11,
      children: "Update the destination configuration before proceeding: Change the destination mode for this destination from 'Avo Managed' to 'Destination Interface' in the list of destinations above."
    });

function renderDestinationInstructions(destinationMode, platform, language, destinationType) {
  var exit = 0;
  var exit$1 = 0;
  if (platform === undefined) {
    return null;
  }
  if (platform === "Flutter" || platform === "Go" || platform === "Other") {
    return null;
  }
  if (language === undefined) {
    return null;
  }
  if (language === "go" || language === "dart") {
    return null;
  }
  if (language === "re" || language === "js") {
    return null;
  }
  if (destinationType === undefined) {
    return null;
  }
  switch (destinationMode) {
    case "DestinationInterface" :
        if (destinationType === "Snowplow") {
          return React.createElement($$Text.make, {
                      color: Styles.Color.light11,
                      children: "Docs for how to initialize Avo with a Snowplow destination coming soon. Reach out to us!"
                    });
        }
        if (language === "json") {
          return null;
        }
        exit = language === "objc" ? 10 : (
            language === "js_v2" ? 1 : (
                language === "re_v2" ? 2 : (
                    language === "cs" ? 8 : (
                        language === "kt" ? 7 : (
                            language === "py3" || language === "py" ? 5 : (
                                language === "rb" ? 11 : (
                                    language === "ts" ? 9 : (
                                        language === "php" ? 6 : (
                                            language === "swift" ? 4 : 3
                                          )
                                      )
                                  )
                              )
                          )
                      )
                  )
              )
          );
        break;
    case "AvoManaged" :
    case "Legacy" :
        exit$1 = 12;
        break;
    
  }
  if (exit$1 === 12) {
    var exit$2 = 0;
    if (language !== undefined) {
      if (language === "java") {
        if (destinationType === "Custom") {
          exit = 3;
        } else {
          exit$2 = 13;
        }
      } else if (language === "json") {
        if (destinationType !== undefined) {
          if (destinationType === "Custom") {
            return null;
          }
          exit$2 = 13;
        } else {
          exit$2 = 13;
        }
      } else if (language === "objc") {
        if (destinationType === "Custom") {
          exit = 10;
        } else {
          exit$2 = 13;
        }
      } else if (language === "js_v2") {
        if (destinationType === "Custom") {
          exit = 1;
        } else {
          exit$2 = 13;
        }
      } else if (language === "re_v2") {
        if (destinationType === "Custom") {
          exit = 2;
        } else {
          exit$2 = 13;
        }
      } else if (language === "cs") {
        if (destinationType === "Custom") {
          exit = 8;
        } else {
          exit$2 = 13;
        }
      } else if (language === "kt") {
        if (destinationType === "Custom") {
          exit = 7;
        } else {
          exit$2 = 13;
        }
      } else if (language === "py3" || language === "py") {
        if (destinationType === "Custom") {
          exit = 5;
        } else {
          exit$2 = 13;
        }
      } else if (language === "rb") {
        if (destinationType === "Custom") {
          exit = 11;
        } else {
          exit$2 = 13;
        }
      } else if (language === "ts") {
        if (destinationType === "Custom") {
          exit = 9;
        } else {
          exit$2 = 13;
        }
      } else if (language === "php") {
        if (destinationType === "Custom") {
          exit = 6;
        } else {
          exit$2 = 13;
        }
      } else if (language === "swift" && destinationType === "Custom") {
        exit = 4;
      } else {
        exit$2 = 13;
      }
    } else {
      exit$2 = 13;
    }
    if (exit$2 === 13) {
      if (platform === "DotNet") {
        if (destinationType === "Mixpanel") {
          return "Docs coming soon, contact us for setup details for Mixpanel.";
        } else {
          return changeToDestinationInterface;
        }
      } else if (platform === "Android") {
        if (destinationType === "Braze") {
          return React.createElement(Markdown.make, {
                      source: Guides.androidBraze
                    });
        } else if (destinationType === "Segment") {
          return React.createElement(Markdown.make, {
                      source: Guides.androidSegment
                    });
        } else if (destinationType === "Mixpanel") {
          return React.createElement(Markdown.make, {
                      source: Guides.androidMixpanel
                    });
        } else if (destinationType === "Amplitude") {
          return React.createElement(Markdown.make, {
                      source: Guides.androidAmplitude
                    });
        } else if (destinationType === "FirebaseAnalytics") {
          return React.createElement(Markdown.make, {
                      source: Guides.androidFirebaseAnalytics
                    });
        } else {
          return changeToDestinationInterface;
        }
      } else if (platform === "Python") {
        if (destinationType === "Mixpanel") {
          return React.createElement(Markdown.make, {
                      source: Guides.pythonMixpanel
                    });
        } else {
          return changeToDestinationInterface;
        }
      } else if (platform === "Gtm" || platform === "JsonSchema") {
        return null;
      } else if (platform === "IOS") {
        if (destinationType === "Braze") {
          return React.createElement(Markdown.make, {
                      source: Guides.iosBraze
                    });
        } else if (destinationType === "Segment") {
          return React.createElement(Markdown.make, {
                      source: Guides.iosSegment
                    });
        } else if (destinationType === "Mixpanel") {
          return React.createElement(Markdown.make, {
                      source: Guides.iosMixpanel
                    });
        } else if (destinationType === "Amplitude") {
          return React.createElement(Markdown.make, {
                      source: Guides.iosAmplitude
                    });
        } else if (destinationType === "FirebaseAnalytics") {
          return React.createElement(Markdown.make, {
                      source: Guides.iosFirebaseAnalytics
                    });
        } else {
          return changeToDestinationInterface;
        }
      } else if (platform === "Php") {
        return changeToDestinationInterface;
      } else if (platform === "Web") {
        if (destinationType === "FullStory" || destinationType === "Amplitude" || destinationType === "Intercom" || destinationType === "Mixpanel" || destinationType === "Segment") {
          return React.createElement($$Text.make, {
                      color: Styles.Color.light11,
                      children: "Destination is fully managed by Avo. Avo will take care of fetching the SDK and initializing it. No preperation required."
                    });
        } else {
          return changeToDestinationInterface;
        }
      } else if (platform === "KotlinMultiplatform") {
        return changeToDestinationInterface;
      } else if (platform === "MacOS") {
        if (destinationType === "Amplitude") {
          return React.createElement(Markdown.make, {
                      source: Guides.iosAmplitude
                    });
        } else {
          return changeToDestinationInterface;
        }
      } else if (platform === "Expo") {
        if (destinationType === "Segment") {
          return React.createElement(Markdown.make, {
                      source: Guides.expoSegment
                    });
        } else if (destinationType === "Amplitude") {
          return React.createElement(Markdown.make, {
                      source: Guides.expoAmplitude
                    });
        } else {
          return changeToDestinationInterface;
        }
      } else if (platform === "Java") {
        return changeToDestinationInterface;
      } else if (platform === "Node") {
        if (destinationType === "Segment") {
          return React.createElement(Markdown.make, {
                      source: Guides.nodeSegment
                    });
        } else if (destinationType === "Mixpanel") {
          return React.createElement(Markdown.make, {
                      source: Guides.nodeMixpanel
                    });
        } else if (destinationType === "Intercom") {
          return React.createElement(Markdown.make, {
                      source: Guides.nodeIntercom
                    });
        } else if (destinationType === "Amplitude") {
          return React.createElement(Markdown.make, {
                      source: Guides.nodeAmplitude
                    });
        } else {
          return changeToDestinationInterface;
        }
      } else if (platform === "Ruby") {
        if (destinationType === "Segment") {
          return "Docs coming soon, contact us for setup details for Segment.";
        } else if (destinationType === "Amplitude") {
          return "No additional setup is required to send to Amplitude!";
        } else {
          return changeToDestinationInterface;
        }
      } else if (platform === "Unity") {
        if (destinationType === "Mixpanel") {
          return "Docs coming soon, contact us for setup details for Mixpanel.";
        } else {
          return changeToDestinationInterface;
        }
      } else if (destinationType === "Segment") {
        return React.createElement(Markdown.make, {
                    source: Guides.reactNativeSegment
                  });
      } else if (destinationType === "Amplitude") {
        return React.createElement(Markdown.make, {
                    source: Guides.reactNativeAmplitude
                  });
      } else if (destinationType === "FirebaseAnalytics") {
        return React.createElement(Markdown.make, {
                    source: Guides.reactNativeFirebase
                  });
      } else {
        return changeToDestinationInterface;
      }
    }
    
  }
  switch (exit) {
    case 1 :
        return React.createElement(React.Fragment, undefined, destinationInterfaceIntro, React.createElement(FileView.make, {
                        filename: "Example Destination Interface in Javascript",
                        code: SourceSetupDestinationCodeExamples.jsV2
                      }));
    case 2 :
        return React.createElement(React.Fragment, undefined, destinationInterfaceIntro, React.createElement(FileView.make, {
                        filename: "Example Destination Interface in Reason",
                        code: SourceSetupDestinationCodeExamples.reV2
                      }));
    case 3 :
        return React.createElement(React.Fragment, undefined, destinationInterfaceIntro, React.createElement(FileView.make, {
                        filename: "Example Destination Interface in Java",
                        code: SourceSetupDestinationCodeExamples.java
                      }));
    case 4 :
        return React.createElement(React.Fragment, undefined, destinationInterfaceIntro, React.createElement(FileView.make, {
                        filename: "Example Destination Interface in Swift",
                        code: SourceSetupDestinationCodeExamples.swift
                      }));
    case 5 :
        return React.createElement(React.Fragment, undefined, destinationInterfaceIntro, React.createElement(FileView.make, {
                        filename: "Example Destination Interface in Python",
                        code: SourceSetupDestinationCodeExamples.py
                      }));
    case 6 :
        return React.createElement(React.Fragment, undefined, destinationInterfaceIntro, React.createElement(FileView.make, {
                        filename: "Example Destination Interface in PHP",
                        code: SourceSetupDestinationCodeExamples.php
                      }));
    case 7 :
        return React.createElement(React.Fragment, undefined, destinationInterfaceIntro, React.createElement(FileView.make, {
                        filename: "Example Destination Interface in Kotlin",
                        code: SourceSetupDestinationCodeExamples.kt
                      }));
    case 8 :
        return React.createElement(React.Fragment, undefined, destinationInterfaceIntro, React.createElement(FileView.make, {
                        filename: "Example Destination Interface in C#",
                        code: SourceSetupDestinationCodeExamples.cs
                      }));
    case 9 :
        return React.createElement(React.Fragment, undefined, destinationInterfaceIntro, React.createElement(FileView.make, {
                        filename: "Example Destination Interface in Typescript",
                        code: SourceSetupDestinationCodeExamples.ts
                      }));
    case 10 :
        return React.createElement(React.Fragment, undefined, destinationInterfaceIntro, React.createElement(FileView.make, {
                        filename: "Example Destination Interface in Objective-C",
                        code: SourceSetupDestinationCodeExamples.objc
                      }));
    case 11 :
        return React.createElement(React.Fragment, undefined, destinationInterfaceIntro, React.createElement(FileView.make, {
                        filename: "Example Destination Interface in Ruby",
                        code: SourceSetupDestinationCodeExamples.rb
                      }));
    
  }
}

function SourceSetupDestinations(Props) {
  var model = Props.model;
  var source = Props.source;
  var allSupportedModelDestinations = AvoUtils.getDestinationsSupport(source.platform, source.language, model.destinations);
  var destinations = Belt_List.keepMapU(source.destinations, (function (destinationRef) {
          var destinationId = destinationRef.destinationId;
          var isDestinationSupported = Belt_List.someU(allSupportedModelDestinations, (function (param) {
                  if (param[1].id === destinationId) {
                    return param[2];
                  } else {
                    return false;
                  }
                }));
          var maybeDestination = ModelUtils.getDestinationById(destinationId, model);
          if (isDestinationSupported) {
            return Belt_Option.map(maybeDestination, (function (destination) {
                          return [
                                  destination,
                                  destinationRef
                                ];
                        }));
          }
          
        }));
  return React.createElement(React.Fragment, undefined, destinations === /* [] */0 && Caml_obj.notequal(source.platform, "JsonSchema") ? renderWarning("No destinations are set. No events will be sent from this source until you add destinations.") : null, React.createElement(Spacer.make, {
                  height: 8
                }), Belt_List.toArray(Belt_List.mapU(destinations, (function (param) {
                        var destination = param[0];
                        return React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.paddingLeft(Css.px(16)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement($$Text.make, {
                                        weight: "Semi",
                                        color: Styles.Color.light11,
                                        children: ModelUtils.getDestinationName(destination)
                                      }), React.createElement(Spacer.make, {
                                        height: 4
                                      }), renderDestinationInstructions(param[1].destinationMode, source.platform, source.language, destination.type_), React.createElement(Spacer.make, {
                                        height: 16
                                      }));
                      }))));
}

var make = SourceSetupDestinations;

export {
  renderWarning ,
  destinationInterfaceIntroText ,
  destinationInterfaceIntro ,
  changeToDestinationInterface ,
  renderDestinationInstructions ,
  make ,
}
/* destinationInterfaceIntro Not a pure module */
