// Generated by ReScript, PLEASE EDIT WITH CARE


function toAnalytics(status) {
  if (typeof status !== "object") {
    return "Open";
  }
  switch (status.TAG) {
    case "Merged" :
        return "Merged";
    case "Closed" :
        return "Deleted";
    case "ReadyForReview" :
        return "ReadyForReview";
    case "ChangesRequested" :
        return "ChangesRequested";
    case "Approved" :
        return "Approved";
    default:
      return "Open";
  }
}

export {
  toAnalytics ,
}
/* No side effect */
