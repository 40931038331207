// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as BranchStatusPill from "./BranchStatusPill.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as BranchStateFirebaseModelUtils from "../../shared/models/BranchStateFirebaseModelUtils.mjs";

function CopyChangesToBranchModal__BranchIcon(Props) {
  var branchId = Props.branchId;
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  var match = SchemaBundleContext.use();
  var branchState = FirebaseFetcherHooks.useBranchState("Fetch", match.schemaId, branchId);
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.display("inlineBlock"),
                    tl: {
                      hd: Css.paddingRight(Css.em(0.2)),
                      tl: {
                        hd: Css.transform(Css.translateY(Css.em(0.18 * size / 24.0))),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement(SimpleTooltip.WithComponent.make, {
                  tooltip: (function (param) {
                      return React.createElement($$Text.make, {
                                  size: "Small",
                                  children: branchState !== undefined ? BranchStateFirebaseModelUtils.toString(branchState.branchStatus) : "Unknown"
                                });
                    }),
                  children: React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.position("relative"),
                                    tl: {
                                      hd: Css.width(Css.px(size)),
                                      tl: {
                                        hd: Css.height(Css.px(size)),
                                        tl: {
                                          hd: Css.borderRadius(Css.px(size / 2 | 0)),
                                          tl: {
                                            hd: Css.backgroundColor(branchState !== undefined ? BranchStatusPill.getBackgroundColor(branchState.branchStatus) : Styles.Color.light03),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement(Icon.make, {
                            type_: "branch",
                            size: {
                              NAME: "int",
                              VAL: 0.6667 * size | 0
                            },
                            color: branchState !== undefined ? BranchStatusPill.getColor(branchState.branchStatus) : Styles.Color.light10
                          }))
                }));
}

var make = CopyChangesToBranchModal__BranchIcon;

export {
  make ,
}
/* Css Not a pure module */
