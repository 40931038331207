// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AvoLimits from "../../app/src/AvoLimits.mjs";

function fromBranchStatus(branchStatus) {
  if (typeof branchStatus !== "object") {
    if (branchStatus === "Master") {
      return "Approved";
    } else {
      return "Unknown";
    }
  }
  switch (branchStatus.TAG) {
    case "Merged" :
        return "Merged";
    case "Closed" :
        return "Closed";
    case "Open" :
    case "Draft" :
        return "Draft";
    case "ReadyForReview" :
        return "ReadyForReview";
    case "ChangesRequested" :
        return "ChangesRequested";
    case "Approved" :
        return "Approved";
    
  }
}

function toString(status) {
  if (status === "Closed") {
    return "Closed";
  } else if (status === "ChangesRequested") {
    return "Changes Requested";
  } else if (status === "Approved") {
    return "Approved";
  } else if (status === "Draft") {
    return "Draft";
  } else if (status === "Unknown") {
    return "Unknown";
  } else if (status === "Open") {
    return "Open";
  } else if (status === "Merged") {
    return "Merged";
  } else {
    return "Ready For Review";
  }
}

function enrichStatusWithApprovals(status, approvalsCount, workspace) {
  var match = AvoLimits.ApprovalWorkflow.computeAvailability(workspace);
  var approvalWorkflow = match === "AvailableDuringTrial" || match === "Available";
  if (status === "Closed") {
    return "Closed";
  } else if (status === "ChangesRequested") {
    if (approvalWorkflow) {
      return "ChangesRequested";
    } else {
      return "Open";
    }
  } else if (status === "Approved") {
    if (approvalWorkflow) {
      if (workspace.settings.numRequiredApprovals <= approvalsCount) {
        return "Approved";
      } else {
        return "ReadyForReview";
      }
    } else {
      return "Open";
    }
  } else if (status === "Draft") {
    if (approvalWorkflow) {
      return "Draft";
    } else {
      return "Open";
    }
  } else if (status === "Unknown") {
    return "Unknown";
  } else if (status === "Open") {
    return "Open";
  } else if (status === "Merged") {
    return "Merged";
  } else if (approvalWorkflow) {
    return "ReadyForReview";
  } else {
    return "Open";
  }
}

export {
  fromBranchStatus ,
  toString ,
  enrichStatusWithApprovals ,
}
/* AvoLimits Not a pure module */
