// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SettingsSources from "./SettingsSources.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as SettingsDestinations from "./SettingsDestinations.mjs";

function Connections(Props) {
  var model = Props.model;
  var globalSend = Props.globalSend;
  var match = React.useState(function (param) {
        
      });
  var setActiveItem = match[1];
  var activeItem = match[0];
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace();
  var itemCount = Belt_List.length(model.sources) > Belt_List.length(model.destinations) ? Belt_List.length(model.sources) : Belt_List.length(model.destinations);
  var graphHeight = Math.imul(itemCount, 70);
  var destinations = Belt_List.toArray(model.destinations);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("flexStart"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.overflow("auto"),
                          tl: {
                            hd: Css.minWidth(Css.px(880)),
                            tl: {
                              hd: Css.padding(Css.px(16)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(SettingsSources.make, {
                  model: model,
                  onHover: (function (sourceId) {
                      Curry._1(setActiveItem, (function (param) {
                              return {
                                      NAME: "Source",
                                      VAL: sourceId
                                    };
                            }));
                    }),
                  onLeave: (function (sourceId) {
                      Curry._1(setActiveItem, (function (activeItem) {
                              if (activeItem !== undefined && Caml_obj.equal(activeItem, {
                                      NAME: "Source",
                                      VAL: sourceId
                                    })) {
                                return ;
                              } else {
                                return activeItem;
                              }
                            }));
                    }),
                  globalSend: globalSend
                }), React.createElement("svg", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(65)),
                        tl: {
                          hd: Css.height(Css.px(graphHeight)),
                          tl: {
                            hd: Css.width(Css.px(150)),
                            tl: {
                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.setAlpha(Styles.Color.light07, 0.2)),
                              tl: {
                                hd: Css_Legacy_Core.SVG.fill("transparent"),
                                tl: {
                                  hd: Css.flexShrink(0.0),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, Belt_List.toArray(Belt_List.flatten(Belt_List.mapWithIndexU(model.sources, (function (sourceIndex, source) {
                                return Belt_List.keepMapU(source.destinations, (function (param) {
                                              var destinationId = param.destinationId;
                                              var maybeDestination = Belt_List.getByU(model.destinations, (function (destination) {
                                                      return destination.id === destinationId;
                                                    }));
                                              if (maybeDestination === undefined) {
                                                return ;
                                              }
                                              if (!(Belt_Option.isSome(maybeDestination.type_) && AvoUtils.isDestinationSupportedForSource(source.platform, source.language, Belt_Option.getExn(maybeDestination.type_)))) {
                                                return ;
                                              }
                                              var destinationIndex = Belt_Option.getExn(Belt_Array.getIndexByU(destinations, (function (destination) {
                                                          return destination.id === destinationId;
                                                        })));
                                              return Caml_option.some(React.createElement("path", {
                                                              key: source.id + destinationId,
                                                              className: Curry._1(Css.style, activeItem !== undefined ? (
                                                                      activeItem.NAME === "Destination" ? (
                                                                          activeItem.VAL === destinationId ? ({
                                                                                hd: Css_Legacy_Core.SVG.stroke(Styles.Color.avoPink),
                                                                                tl: /* [] */0
                                                                              }) : /* [] */0
                                                                        ) : (
                                                                          activeItem.VAL === source.id ? ({
                                                                                hd: Css_Legacy_Core.SVG.stroke(Styles.Color.avoPink),
                                                                                tl: /* [] */0
                                                                              }) : /* [] */0
                                                                        )
                                                                    ) : /* [] */0),
                                                              d: "M0," + String(35 + Math.imul(sourceIndex, 70) | 0) + " C100," + String(35 + Math.imul(sourceIndex, 70) | 0) + "  100," + String(35 + Math.imul(destinationIndex, 70) | 0) + " 150," + String(35 + Math.imul(destinationIndex, 70) | 0),
                                                              strokeWidth: "4"
                                                            }));
                                            }));
                              }))))), isGlobalWorkspace ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.minWidth(Css.px(350)),
                            tl: /* [] */0
                          }
                        })
                  }) : React.createElement(SettingsDestinations.make, {
                    model: model,
                    onHover: (function (sourceId) {
                        Curry._1(setActiveItem, (function (param) {
                                return {
                                        NAME: "Destination",
                                        VAL: sourceId
                                      };
                              }));
                      }),
                    onLeave: (function (sourceId) {
                        Curry._1(setActiveItem, (function (activeItem) {
                                if (activeItem !== undefined && Caml_obj.equal(activeItem, {
                                        NAME: "Destination",
                                        VAL: sourceId
                                      })) {
                                  return ;
                                } else {
                                  return activeItem;
                                }
                              }));
                      }),
                    globalSend: globalSend
                  }));
}

var make = Connections;

export {
  make ,
}
/* Css Not a pure module */
