// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "../../../app/src/AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as BeltListExtensions from "../../../app/src/BeltListExtensions.mjs";

function findDestinations(integration, model) {
  var destinationFilters = Belt_List.keepMap(integration.filters, (function (filterBy) {
          if (typeof filterBy !== "object") {
            return ;
          }
          if (filterBy.NAME !== "Destination") {
            return ;
          }
          var partial_arg = model.sources;
          return Belt_Option.flatMap(ModelUtils.getDestinationById(filterBy.VAL, model), (function (param) {
                        return AvoConfig.tryToConvertDestinationToValidDestinationMaybe(partial_arg, param);
                      }));
        }));
  if (destinationFilters !== /* [] */0) {
    return destinationFilters;
  }
  var match = integration.config;
  if (match === undefined) {
    return /* [] */0;
  }
  switch (match.TAG) {
    case "Protocols" :
        return Belt_List.keepMap(model.destinations, (function (destination) {
                      if (Caml_obj.equal(destination.type_, "Segment")) {
                        return AvoConfig.tryToConvertDestinationToValidDestinationMaybe(model.sources, destination);
                      }
                      
                    }));
    case "Taxonomy" :
        return Belt_List.keepMap(model.destinations, (function (destination) {
                      if (Caml_obj.equal(destination.type_, "Amplitude")) {
                        return AvoConfig.tryToConvertDestinationToValidDestinationMaybe(model.sources, destination);
                      }
                      
                    }));
    case "Lexicon" :
        return Belt_List.keepMap(model.destinations, (function (destination) {
                      if (Caml_obj.equal(destination.type_, "Mixpanel")) {
                        return AvoConfig.tryToConvertDestinationToValidDestinationMaybe(model.sources, destination);
                      }
                      
                    }));
    case "Webhook" :
        return Belt_List.keepMap(model.destinations, (function (destination) {
                      return AvoConfig.tryToConvertDestinationToValidDestinationMaybe(model.sources, destination);
                    }));
    case "SnowplowDataStructures" :
        return Belt_List.keepMap(model.destinations, (function (destination) {
                      if (Caml_obj.equal(destination.type_, "Snowplow")) {
                        return AvoConfig.tryToConvertDestinationToValidDestinationMaybe(model.sources, destination);
                      }
                      
                    }));
    case "MParticleDataMaster" :
        return Belt_List.keepMap(model.destinations, (function (destination) {
                      if (Caml_obj.equal(destination.type_, "MParticle")) {
                        return AvoConfig.tryToConvertDestinationToValidDestinationMaybe(model.sources, destination);
                      }
                      
                    }));
    case "RudderstackTrackingPlans" :
        return Belt_List.keepMap(model.destinations, (function (destination) {
                      if (Caml_obj.equal(destination.type_, "Rudderstack")) {
                        return AvoConfig.tryToConvertDestinationToValidDestinationMaybe(model.sources, destination);
                      }
                      
                    }));
    case "RudderstackDataCatalog" :
        return Belt_List.keepMap(model.destinations, (function (destination) {
                      if (Caml_obj.equal(destination.type_, "Rudderstack")) {
                        return AvoConfig.tryToConvertDestinationToValidDestinationMaybe(model.sources, destination);
                      }
                      
                    }));
    case "AdobeExperiencePlatform" :
        return Belt_List.keepMap(model.destinations, (function (destination) {
                      if (Caml_obj.equal(destination.type_, "AdobeAnalytics")) {
                        return AvoConfig.tryToConvertDestinationToValidDestinationMaybe(model.sources, destination);
                      }
                      
                    }));
    
  }
}

function findSources(destinations, model) {
  return BeltListExtensions.dedupeOrdered(BeltListExtensions.flatMap(destinations, (function (destination) {
                    return Belt_List.keepMap(AvoConfig.sourcesByDestination(model, destination.id), (function (source) {
                                  return AvoConfig.tryToConvertSourceToValidSourceMaybe(source, model);
                                }));
                  })), (function (source) {
                return source.id;
              }));
}

function hasPublishableConfig(config) {
  if (config === undefined) {
    return false;
  }
  switch (config.TAG) {
    case "Protocols" :
        var match = config._0;
        var accessKey = match.accessKey;
        if (accessKey === undefined) {
          return false;
        }
        var trackingPlanId = match.trackingPlanId;
        if (trackingPlanId === undefined) {
          return false;
        }
        var workspace = match.workspace;
        if (workspace !== undefined && accessKey !== "" && workspace !== "") {
          return trackingPlanId !== "";
        } else {
          return false;
        }
    case "Taxonomy" :
        var match$1 = config._0;
        var apiKey = match$1.apiKey;
        if (apiKey === undefined) {
          return false;
        }
        var secretKey = match$1.secretKey;
        if (secretKey !== undefined && apiKey !== "") {
          return secretKey !== "";
        } else {
          return false;
        }
    case "Lexicon" :
        var match$2 = config._0;
        var userName = match$2.userName;
        if (userName === undefined) {
          return false;
        }
        var secretKey$1 = match$2.secretKey;
        if (secretKey$1 === undefined) {
          return false;
        }
        var projectId = match$2.projectId;
        if (projectId !== undefined && userName !== "" && secretKey$1 !== "") {
          return projectId !== "";
        } else {
          return false;
        }
    case "Webhook" :
        var webhookUrl = config._0.webhookUrl;
        if (webhookUrl !== undefined) {
          return webhookUrl !== "";
        } else {
          return false;
        }
    case "SnowplowDataStructures" :
        var match$3 = config._0;
        var accessToken = match$3.accessToken;
        if (accessToken === undefined) {
          return false;
        }
        var organizationId = match$3.organizationId;
        if (organizationId === undefined) {
          return false;
        }
        var vendor = match$3.vendor;
        if (vendor !== undefined && accessToken !== "" && organizationId !== "") {
          return vendor !== "";
        } else {
          return false;
        }
    case "MParticleDataMaster" :
        var match$4 = config._0;
        var clientId = match$4.clientId;
        if (clientId === undefined) {
          return false;
        }
        var clientSecret = match$4.clientSecret;
        if (clientSecret === undefined) {
          return false;
        }
        var workspaceId = match$4.workspaceId;
        if (workspaceId === undefined) {
          return false;
        }
        var planId = match$4.planId;
        if (planId !== undefined && clientId !== "" && clientSecret !== "" && workspaceId !== "") {
          return planId !== "";
        } else {
          return false;
        }
    case "RudderstackTrackingPlans" :
        var match$5 = config._0;
        var accessToken$1 = match$5.accessToken;
        if (accessToken$1 === undefined) {
          return false;
        }
        var trackingPlanDisplayName = match$5.trackingPlanDisplayName;
        if (trackingPlanDisplayName === undefined) {
          return false;
        }
        var emailAddress = match$5.emailAddress;
        if (emailAddress !== undefined && accessToken$1 !== "" && emailAddress !== "") {
          return trackingPlanDisplayName !== "";
        } else {
          return false;
        }
    case "RudderstackDataCatalog" :
        var match$6 = config._0;
        var accessToken$2 = match$6.accessToken;
        if (accessToken$2 === undefined) {
          return false;
        }
        var trackingPlanId$1 = match$6.trackingPlanId;
        if (trackingPlanId$1 !== undefined && accessToken$2 !== "") {
          return trackingPlanId$1 !== "";
        } else {
          return false;
        }
    case "AdobeExperiencePlatform" :
        var match$7 = config._0;
        var clientId$1 = match$7.clientId;
        if (clientId$1 === undefined) {
          return false;
        }
        var orgId = match$7.orgId;
        if (orgId === undefined) {
          return false;
        }
        var clientSecret$1 = match$7.clientSecret;
        if (clientSecret$1 !== undefined && clientId$1 !== "" && orgId !== "") {
          return clientSecret$1 !== "";
        } else {
          return false;
        }
    
  }
}

function isAutoPublishable(integration) {
  var isPublishable = hasPublishableConfig(integration.config);
  var match = integration.autoPublish;
  var match$1 = integration.config;
  if (match && isPublishable) {
    return match$1 !== undefined;
  } else {
    return false;
  }
}

function toAutoPublish(model, workspace) {
  var workspaceLimits = AvoLimits.computeLimits(workspace, model);
  return Belt_List.keepU(model.integrations, (function (integration) {
                var match = AvoLimits.Integration.entityStatus(workspaceLimits, integration.id);
                if (isAutoPublishable(integration)) {
                  return match[0] !== "Unavailable";
                } else {
                  return false;
                }
              }));
}

function configToAnalyticsType(config) {
  if (config === undefined) {
    return ;
  }
  switch (config.TAG) {
    case "Protocols" :
        return "SegmentProtocols";
    case "Taxonomy" :
        return "AmplitudeGovern";
    case "Lexicon" :
        return "MixpanelLexicon";
    case "Webhook" :
        return "Webhook";
    case "SnowplowDataStructures" :
        return "SnowplowDataStructures";
    case "MParticleDataMaster" :
        return "MParticleDataMaster";
    case "RudderstackTrackingPlans" :
        return "RudderstackTrackingPlans";
    case "RudderstackDataCatalog" :
        return "RudderstackDataCatalog";
    case "AdobeExperiencePlatform" :
        return "AdobeExperiencePlatform";
    
  }
}

export {
  findDestinations ,
  findSources ,
  hasPublishableConfig ,
  isAutoPublishable ,
  toAutoPublish ,
  configToAnalyticsType ,
}
/* AvoConfig Not a pure module */
