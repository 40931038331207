// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Organization from "../../model/src/Organization.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

var context = React.createContext(undefined);

var provider = context.Provider;

function OrganizationContext$OrganizationProvider(Props) {
  var organizationId = Props.organizationId;
  var children = Props.children;
  var organizationConfigResult = FirebaseFetcherHooks.useOrganizationConfig(organizationId);
  return React.createElement(provider, {
              value: typeof organizationConfigResult === "object" && organizationConfigResult.NAME === "Success" ? Organization.Json.decode(organizationConfigResult.VAL) : undefined,
              children: children
            });
}

var OrganizationProvider = {
  make: OrganizationContext$OrganizationProvider
};

function OrganizationContext(Props) {
  var schema = Props.schema;
  var children = Props.children;
  var organizationId = Belt_Option.map(Caml_option.nullable_to_opt(schema.organization), (function (param) {
          return param.organizationId;
        }));
  if (organizationId !== undefined) {
    return React.createElement(OrganizationContext$OrganizationProvider, {
                organizationId: organizationId,
                children: children
              });
  } else {
    return React.createElement(provider, {
                value: undefined,
                children: children
              });
  }
}

function useOrganization(param) {
  return React.useContext(context);
}

function useIsGlobalWorkspace(param) {
  var match = WorkspaceContext.use();
  var schemaId = match.id;
  var organization = React.useContext(context);
  return Belt_Option.mapWithDefault(organization, false, (function (param) {
                return Belt_Array.some(param.globalWorkspaceIds, (function (id) {
                              return id === schemaId;
                            }));
              }));
}

function useIsLocalWorkspace(param) {
  var match = WorkspaceContext.use();
  var schemaId = match.id;
  var organization = React.useContext(context);
  return Belt_Option.mapWithDefault(organization, false, (function (param) {
                return Belt_Array.some(param.localWorkspaceIds, (function (id) {
                              return id === schemaId;
                            }));
              }));
}

var make = OrganizationContext;

export {
  context ,
  provider ,
  OrganizationProvider ,
  make ,
  useOrganization ,
  useIsGlobalWorkspace ,
  useIsLocalWorkspace ,
}
/* context Not a pure module */
