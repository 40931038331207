// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PropertyUtils from "../PropertyUtils.mjs";

function oneLine(overrideType) {
  var absenceOverride;
  var allowedValues;
  var regexValidation;
  if (typeof overrideType !== "object") {
    return "Excluded from this variant";
  }
  var match = overrideType._0;
  var match$1 = match.pinnedValue;
  if (typeof match$1 !== "object") {
    var absenceOverride$1 = match.absence;
    var exit = 0;
    if (typeof absenceOverride$1 !== "object") {
      var tmp = match.allowedValues;
      if (typeof tmp !== "object") {
        var tmp$1 = match.regexValidation;
        if (typeof tmp$1 !== "object") {
          return "Included in this variant";
        }
        exit = 2;
      } else {
        exit = 2;
      }
    } else {
      exit = 2;
    }
    if (exit === 2) {
      absenceOverride = absenceOverride$1;
      allowedValues = match.allowedValues;
      regexValidation = match.regexValidation;
    }
    
  } else {
    var literal = match$1._0;
    if (literal !== undefined) {
      return "Pinned to " + PropertyUtils.printPinnedValue(literal) + " on this variant";
    }
    var absenceOverride$2 = match.absence;
    var exit$1 = 0;
    if (typeof absenceOverride$2 !== "object") {
      var tmp$2 = match.allowedValues;
      if (typeof tmp$2 !== "object") {
        var tmp$3 = match.regexValidation;
        if (typeof tmp$3 !== "object") {
          return "Pinned value removed on this variant";
        }
        exit$1 = 2;
      } else {
        exit$1 = 2;
      }
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      absenceOverride = absenceOverride$2;
      allowedValues = match.allowedValues;
      regexValidation = match.regexValidation;
    }
    
  }
  var absenceDiffString;
  if (typeof absenceOverride !== "object") {
    absenceDiffString = undefined;
  } else {
    var tmp$4;
    switch (absenceOverride._0) {
      case "AlwaysSent" :
          tmp$4 = "always sent";
          break;
      case "SometimesSent" :
          tmp$4 = "sometimes sent";
          break;
      case "NeverSent" :
          tmp$4 = "never sent";
          break;
      
    }
    absenceDiffString = "presence set to " + tmp$4;
  }
  var regexDiffString;
  if (typeof regexValidation !== "object") {
    regexDiffString = undefined;
  } else {
    var regex = regexValidation._0;
    regexDiffString = regex !== undefined ? "regex set to \"" + regex.regex + "\"" : "regex override removed";
  }
  var allowedValuesDiffString;
  allowedValuesDiffString = typeof allowedValues !== "object" ? undefined : "allowed values override added";
  var allDiffStrings = Belt_Array.keepMap([
        absenceDiffString,
        regexDiffString,
        allowedValuesDiffString
      ], (function (x) {
          return x;
        }));
  var allDiffLabel = allDiffStrings.join(", ");
  return allDiffLabel.charAt(0).toUpperCase() + allDiffLabel.slice(1) + " on this variant";
}

function includeExcludeOverride(overrideType) {
  if (typeof overrideType !== "object") {
    return "Excluded from this variant";
  } else {
    return "Included in this variant";
  }
}

function pinOverride(overrideType) {
  if (typeof overrideType !== "object") {
    return "";
  }
  var match = overrideType._0.pinnedValue;
  if (typeof match !== "object") {
    return "No pinned value override on this variant";
  }
  var literal = match._0;
  if (literal !== undefined) {
    return "Pinned to " + PropertyUtils.printPinnedValue(literal) + " on this variant";
  } else {
    return "Pinned value override removed on this variant";
  }
}

function absenceOverride(overrideType) {
  if (typeof overrideType !== "object") {
    return "";
  }
  var absenceOverride$1 = overrideType._0.absence;
  if (typeof absenceOverride$1 !== "object") {
    return "No presence override on this variant";
  }
  var tmp;
  switch (absenceOverride$1._0) {
    case "AlwaysSent" :
        tmp = "Always Sent";
        break;
    case "SometimesSent" :
        tmp = "Sometimes Sent";
        break;
    case "NeverSent" :
        tmp = "Never Sent";
        break;
    
  }
  return "Presence override set to " + tmp + " on this variant";
}

function regexOverride(overrideType) {
  if (typeof overrideType !== "object") {
    return "";
  }
  var match = overrideType._0.regexValidation;
  if (typeof match !== "object") {
    return "No regex override on this variant";
  }
  var regex = match._0;
  if (regex !== undefined) {
    return "Regex set to \"" + regex.regex + "\" on this variant";
  } else {
    return "Regex override removed on this variant";
  }
}

export {
  oneLine ,
  includeExcludeOverride ,
  pinOverride ,
  absenceOverride ,
  regexOverride ,
}
/* PropertyUtils Not a pure module */
