// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function InspectorViewInstallDocs$Link(Props) {
  var path = Props.path;
  var onClick = Props.onClick;
  var label = Props.label;
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.display("inlineFlex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.gap(Css.px(4)),
              tl: {
                hd: Css.color(Styles.Color.primaryPurpleShade1),
                tl: {
                  hd: Css.textDecoration("none"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.textDecoration("underline"),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }),
    href: "https://www.avo.app/docs/" + path,
    target: "_blank"
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("a", tmp, React.createElement(Icon.make, {
                  type_: "outsideLink",
                  color: Styles.Color.primaryPurpleShade1
                }), React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  weight: "Semi",
                  children: label
                }));
}

var Link = {
  make: InspectorViewInstallDocs$Link
};

function InspectorViewInstallDocs$Step(Props) {
  var n = Props.n;
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("grid"),
                    tl: {
                      hd: Css.gridTemplateColumns({
                            hd: Css.px(60),
                            tl: {
                              hd: Css.fr(1.0),
                              tl: /* [] */0
                            }
                          }),
                      tl: {
                        hd: Css.alignItems("baseline"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light10,
                  children: "Step " + String(n)
                }), React.createElement("div", undefined, children));
}

var Step = {
  make: InspectorViewInstallDocs$Step
};

export {
  Link ,
  Step ,
}
/* Css Not a pure module */
