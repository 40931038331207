// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as PortalMenuAnimator from "./PortalMenuAnimator.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as PropertyAbsenceUtils from "../../shared/utils/PropertyAbsenceUtils.mjs";
import * as PortalMenu_deprecated from "./PortalMenu_deprecated.mjs";

function absenceModeToLabel(isGlobalWorkspace, absenceMode) {
  if (absenceMode === "Sometimes") {
    return "Sometimes sent";
  } else if (absenceMode === "Always") {
    return "Always sent";
  } else if (absenceMode === "Never") {
    return "Never sent";
  } else if (isGlobalWorkspace) {
    return "Sent depending on workspace";
  } else {
    return "Sent depending on source";
  }
}

function absenceModeFromLabel(jsAbsenceMode) {
  switch (jsAbsenceMode) {
    case "Always sent" :
        return "Always";
    case "Never sent" :
        return "Never";
    case "Sent depending on source" :
    case "Sent depending on workspace" :
        return "BySource";
    case "Sometimes sent" :
        return "Sometimes";
    default:
      return ;
  }
}

var _map = {"Always":"Always sent","Sometimes":"Sometimes sent","Never":"Never sent","NoOverride":"No override"};

var _revMap = {"Always sent":"Always","Sometimes sent":"Sometimes","Never sent":"Never","No override":"NoOverride"};

var sourceStates = [
  "Always",
  "Sometimes",
  "Never"
];

function getEventAndSourceState(absence, eventId, sourceId) {
  if (typeof absence !== "object") {
    return "Always";
  }
  if (absence.TAG === "SometimesSent") {
    return "Sometimes";
  }
  var match = Belt_MapString.get(absence._0, eventId);
  if (match === undefined) {
    return "Always";
  }
  if (match.TAG === "AllSources") {
    var tmp = match._0;
    if (typeof tmp !== "object") {
      return "Never";
    } else {
      return "Sometimes";
    }
  }
  var match$1 = Belt_MapString.get(match._0, sourceId);
  if (match$1 !== undefined) {
    if (typeof match$1 !== "object") {
      return "Never";
    } else {
      return "Sometimes";
    }
  } else {
    return "Always";
  }
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(4)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.minWidth(Css.px(300)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                                NAME: "num",
                                VAL: 0.10
                              }))),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.paddingLeft(Css.px(16)),
              tl: {
                hd: Css.paddingRight(Css.px(16)),
                tl: {
                  hd: Css.paddingTop(Css.px(16)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(16)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var sourcesStyles = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(16)),
      tl: {
        hd: Css.paddingRight(Css.px(16)),
        tl: {
          hd: Css.paddingTop(Css.px(12)),
          tl: {
            hd: Css.paddingBottom(Css.px(16)),
            tl: {
              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
              tl: /* [] */0
            }
          }
        }
      }
    });

var rowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.marginTop(Css.px(4)),
              tl: {
                hd: Css.marginBottom(Css.px(4)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function PropertyAbsenceConfig$PropertyAbsenceVariantOverridePopup(Props) {
  var property = Props.property;
  var $$event = Props.event;
  var onClose = Props.onClose;
  var variant = Props.variant;
  var sendActions = Props.sendActions;
  var schemaBundle = SchemaBundleContext.use();
  var match = React.useState(function (param) {
        var match = Belt_MapString.get(variant.propertyOverrides, property.id);
        if (match === undefined) {
          return "NoOverride";
        }
        if (typeof match !== "object") {
          return "NoOverride";
        }
        var match$1 = match._0.absence;
        if (typeof match$1 !== "object") {
          return "NoOverride";
        }
        switch (match$1._0) {
          case "AlwaysSent" :
              return "Always";
          case "SometimesSent" :
              return "Sometimes";
          case "NeverSent" :
              return "Never";
          
        }
      });
  var setAbsenceOverride = match[1];
  var absenceOverride = match[0];
  return React.createElement("section", {
              className: rootStyles,
              onKeyDown: (function ($$event) {
                  if ($$event.key === "Escape") {
                    return Curry._1(onClose, undefined);
                  }
                  
                })
            }, React.createElement("header", {
                  className: headerStyles
                }, React.createElement($$Text.make, {
                      element: "H1",
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "For this variant"
                    }), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement(Select.make, {
                      onSelect: (function (absenceOverrideString) {
                          var newAbsenceOverride = Belt_Option.getWithDefault(_revMap[absenceOverrideString], "NoOverride");
                          Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (branch) {
                                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                    AnalyticsRe.eventVariantUpdatedPropertyPresenceOverride(variant.baseEventId, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, variant.id, property.id, absenceOverride === "Always" ? "AlwaysSent" : (
                                            absenceOverride === "Never" ? "NeverSent" : (
                                                absenceOverride === "NoOverride" ? "NoOverride" : "SometimesSent"
                                              )
                                          ), newAbsenceOverride === "Always" ? "AlwaysSent" : (
                                            newAbsenceOverride === "Never" ? "NeverSent" : (
                                                newAbsenceOverride === "NoOverride" ? "NoOverride" : "SometimesSent"
                                              )
                                          ), schemaBundle$1.schemaId, NamedBranch.getId(branch), $$event.id);
                                  }),
                                undefined,
                                [[
                                    {
                                      NAME: "SetPropertyAbsenceOnVariant",
                                      VAL: [
                                        TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                                        property.id,
                                        newAbsenceOverride === "Always" ? ({
                                              TAG: "SomeOverride",
                                              _0: "AlwaysSent"
                                            }) : (
                                            newAbsenceOverride === "Never" ? ({
                                                  TAG: "SomeOverride",
                                                  _0: "NeverSent"
                                                }) : (
                                                newAbsenceOverride === "NoOverride" ? "NoneOverride" : ({
                                                      TAG: "SomeOverride",
                                                      _0: "SometimesSent"
                                                    })
                                              )
                                          )
                                      ]
                                    },
                                    {
                                      eventId: variant.baseEventId,
                                      eventQuery: variant.baseEventId,
                                      propertyId: property.id,
                                      propertyQuery: property.id,
                                      eventVariantId: variant.id,
                                      eventVariantQuery: variant.id
                                    }
                                  ]]
                              ]);
                          Curry._1(setAbsenceOverride, (function (param) {
                                  return newAbsenceOverride;
                                }));
                        }),
                      options: Belt_Array.map([
                            "Always",
                            "Sometimes",
                            "Never",
                            "NoOverride"
                          ], (function (absenceOverride) {
                              return [
                                      {
                                        NAME: "Label",
                                        VAL: _map[absenceOverride]
                                      },
                                      _map[absenceOverride]
                                    ];
                            })),
                      value: _map[absenceOverride]
                    })));
}

function PropertyAbsenceConfig$PropertyAbsenceEditPopup(Props) {
  var model = Props.model;
  var absence = Props.absence;
  var $$event = Props.event;
  var onClose = Props.onClose;
  var property = Props.property;
  var sources = Props.sources;
  var sendActions = Props.sendActions;
  var schemaBundle = SchemaBundleContext.use();
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace();
  var globalSend = GlobalSendContext.use();
  var match = AvoLimits.PropertyAbsenceByEventAndSource.computeAvailability(workspace);
  var absenceConfigAvailable = match === "AvailableDuringTrial" || match === "Available";
  var match$1 = React.useState(function (param) {
        var simplestMode = PropertyAbsenceUtils.getSimplestAbsenceModeByEvent(absence, sources, $$event, property.sendAs);
        if (simplestMode === "Never") {
          return "BySource";
        } else {
          return simplestMode;
        }
      });
  var setMode = match$1[1];
  var mode = match$1[0];
  React.useEffect((function (param) {
          var simplestMode = PropertyAbsenceUtils.getSimplestAbsenceModeByEvent(absence, sources, $$event, property.sendAs);
          var newMode = simplestMode === "Never" ? "BySource" : simplestMode;
          Curry._1(setMode, (function (mode) {
                  if (mode === "BySource") {
                    return "BySource";
                  } else {
                    return newMode;
                  }
                }));
        }), [absence]);
  var match$2 = AvoConfig.determineSendingToSources(sources, $$event);
  var eventSources = typeof match$2 === "object" ? match$2.VAL : (
      match$2 === "None" ? /* [] */0 : sources
    );
  return React.createElement("section", {
              className: rootStyles,
              onKeyDown: (function ($$event) {
                  if ($$event.key === "Escape") {
                    return Curry._1(onClose, undefined);
                  }
                  
                })
            }, React.createElement("header", {
                  className: headerStyles
                }, React.createElement($$Text.make, {
                      element: "H1",
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "For this event"
                    }), React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement(Select.make, {
                      onSelect: (function (modeString) {
                          var newMode = Belt_Option.getWithDefault(absenceModeFromLabel(modeString), "Always");
                          if (!absenceConfigAvailable && newMode === "BySource") {
                            return Curry._1(globalSend, {
                                        TAG: "OpenModal",
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "ConfigureEventsPerPlatform"
                                        }
                                      });
                          }
                          var confirmationCopy = "Switching to " + (absenceModeToLabel(isGlobalWorkspace, newMode) + " will cause your config to change. Are you sure you want to proceed?");
                          var match = PropertyAbsenceUtils.getSimplestAbsenceModeByEvent(absence, sources, $$event, property.sendAs);
                          var showConfirm = match === "BySource" ? newMode === "Always" || newMode === "Sometimes" : false;
                          var shouldProceed = showConfirm ? window.confirm(confirmationCopy) : true;
                          if (!shouldProceed) {
                            return ;
                          }
                          var newMode$1;
                          var exit = 0;
                          var exit$1 = 0;
                          if (mode === "Always" || mode === "Sometimes") {
                            if (newMode === "BySource") {
                              return Curry._1(setMode, (function (param) {
                                            return "BySource";
                                          }));
                            }
                            exit$1 = 3;
                          } else {
                            exit$1 = 3;
                          }
                          if (exit$1 === 3) {
                            if ((mode === "Sometimes" || mode === "BySource") && newMode === "Always") {
                              newMode$1 = newMode;
                            } else {
                              exit = 2;
                            }
                          }
                          if (exit === 2) {
                            if (!(mode === "Always" || mode === "BySource")) {
                              return ;
                            }
                            if (newMode !== "Sometimes") {
                              return ;
                            }
                            newMode$1 = newMode;
                          }
                          Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (branch) {
                                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                    var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup(model, property);
                                    var match = property.absence;
                                    var tmp;
                                    tmp = match !== undefined && typeof match === "object" ? (
                                        match.TAG === "SometimesSent" ? "SometimesSent" : "DependingOnSource"
                                      ) : "AlwaysSent";
                                    AnalyticsRe.propertyConfigured(propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Presence", "UpdatePropertyPresenceByEvent", undefined, tmp, undefined, newMode$1 === "Always" ? "AlwaysSent" : "SometimesSent", undefined, undefined, undefined, undefined, undefined, schemaBundle$1.branchId, schemaBundle$1.schemaId);
                                  }),
                                undefined,
                                [[
                                    {
                                      NAME: "UpdatePropertyAbsence",
                                      VAL: [
                                        property.id,
                                        {
                                          TAG: "Event",
                                          _0: $$event.id
                                        },
                                        newMode$1 === "Always" ? undefined : ({
                                              TAG: "SometimesSent",
                                              _0: ""
                                            })
                                      ]
                                    },
                                    {
                                      eventId: $$event.id,
                                      eventQuery: $$event.id,
                                      propertyId: property.id,
                                      propertyQuery: property.id
                                    }
                                  ]]
                              ]);
                          Curry._1(setMode, (function (param) {
                                  return newMode$1;
                                }));
                        }),
                      options: Belt_Array.map(Belt_Array.concat([
                                "Always",
                                "Sometimes"
                              ], property.sendAs === "SystemProperty" ? [] : ["BySource"]), (function (mode) {
                              return [
                                      {
                                        NAME: "Label",
                                        VAL: absenceConfigAvailable || mode !== "BySource" ? absenceModeToLabel(isGlobalWorkspace, mode) : "⚡️ " + absenceModeToLabel(isGlobalWorkspace, mode)
                                      },
                                      absenceModeToLabel(isGlobalWorkspace, mode)
                                    ];
                            })),
                      value: absenceModeToLabel(isGlobalWorkspace, mode)
                    })), mode !== "BySource" ? null : React.createElement("div", {
                    className: sourcesStyles
                  }, Belt_List.toArray(Belt_List.map(Belt_List.sort(eventSources, (function (a, b) {
                                  return Caml_obj.compare(a.name, b.name);
                                })), (function (source) {
                              return React.createElement("div", {
                                          key: source.id,
                                          className: rowStyles
                                        }, React.createElement($$Text.make, {
                                              size: "Small",
                                              weight: "Semi",
                                              singleLine: true,
                                              color: Styles.Color.light10,
                                              children: AvoConfig.getSourceName(source)
                                            }), React.createElement(Spacer.make, {
                                              width: 4
                                            }), React.createElement(Select.make, {
                                              disabled: !absenceConfigAvailable,
                                              onSelect: (function (newState) {
                                                  var sourceId = source.id;
                                                  var state = Belt_Option.getWithDefault(absenceModeFromLabel(newState), "Always");
                                                  var eventSourceAbsence = state === "Sometimes" ? ({
                                                        TAG: "SometimesSent",
                                                        _0: ""
                                                      }) : (
                                                      state === "Always" ? undefined : (
                                                          state === "Never" ? "NeverSent" : Pervasives.failwith("Cannot use by source here")
                                                        )
                                                    );
                                                  var action_1 = [
                                                    property.id,
                                                    {
                                                      TAG: "EventSource",
                                                      _0: $$event.id,
                                                      _1: sourceId
                                                    },
                                                    eventSourceAbsence
                                                  ];
                                                  var action = {
                                                    NAME: "UpdatePropertyAbsence",
                                                    VAL: action_1
                                                  };
                                                  Curry.app(sendActions, [
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        (function (branch) {
                                                            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                            var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup(model, property);
                                                            var match = property.absence;
                                                            var tmp;
                                                            tmp = match !== undefined && typeof match === "object" ? (
                                                                match.TAG === "SometimesSent" ? "SometimesSent" : "DependingOnSource"
                                                              ) : "AlwaysSent";
                                                            AnalyticsRe.propertyConfigured(propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Presence", "UpdatePropertyPresenceByEventAndSource", undefined, tmp, undefined, "DependingOnSource", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                          }),
                                                        undefined,
                                                        [[
                                                            action,
                                                            {
                                                              eventId: $$event.id,
                                                              eventQuery: $$event.id,
                                                              propertyId: property.id,
                                                              propertyQuery: property.id
                                                            }
                                                          ]]
                                                      ]);
                                                }),
                                              options: Belt_Array.map(sourceStates, (function (state) {
                                                      return [
                                                              {
                                                                NAME: "Label",
                                                                VAL: absenceModeToLabel(isGlobalWorkspace, state)
                                                              },
                                                              absenceModeToLabel(isGlobalWorkspace, state)
                                                            ];
                                                    })),
                                              value: absenceModeToLabel(isGlobalWorkspace, getEventAndSourceState(absence, $$event.id, source.id))
                                            }));
                            })))));
}

var containerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("baseline"),
          tl: {
            hd: Css.flexShrink(1.0),
            tl: {
              hd: Css.maxWidth(Css.pct(100)),
              tl: {
                hd: Css.marginRight(Css.px(4)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var buttonStyle = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.marginLeft(Css.px(1)),
                      tl: {
                        hd: Css.paddingTop(Css.px(3)),
                        tl: {
                          hd: Css.paddingRight(Css.px(4)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(3)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(6)),
                              tl: {
                                hd: Css.borderRadius(Css.px(4)),
                                tl: {
                                  hd: Css.color(Styles.Color.light10),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$short
                                        }, undefined, undefined, "all"),
                                    tl: {
                                      hd: Icon.styles({
                                            hd: Css_Legacy_Core.SVG.fill("transparent"),
                                            tl: {
                                              hd: Css.transition({
                                                    NAME: "ms",
                                                    VAL: Styles.Duration.$$short
                                                  }, undefined, undefined, "all"),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(Styles.Color.light02),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: {
                                                  hd: Icon.color(Styles.Color.light12),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function PropertyAbsenceConfig(Props) {
  var property = Props.property;
  var $$event = Props.event;
  var viewerCanEdit = Props.viewerCanEdit;
  var variant = Props.variant;
  var sendActions = SendActionsContext.use();
  var model = ModelStore.useModel();
  var sources = ModelStore.useSources();
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace();
  var match = AvoLimits.PropertyAbsenceByEventAndSource.computeAvailability(workspace);
  var absenceConfigAvailable = match === "AvailableDuringTrial" || match === "Available";
  var editButtonRef = React.useRef(null);
  var match$1 = React.useState(function (param) {
        return false;
      });
  var setEditModalVisible = match$1[1];
  var variantPropertyOverrides = variant !== undefined ? Belt_Option.mapU(Belt_MapString.get(variant.propertyOverrides, property.id), (function (override) {
            return override;
          })) : undefined;
  var isRemovedPropertyOnVariant = variantPropertyOverrides !== undefined && typeof variantPropertyOverrides !== "object" ? true : false;
  var canEditAbsence = absenceConfigAvailable && viewerCanEdit && property.sendAs !== "SystemProperty" && !isRemovedPropertyOnVariant;
  var variantOverrideLabel;
  if (variantPropertyOverrides !== undefined && typeof variantPropertyOverrides === "object") {
    var absence = variantPropertyOverrides._0.absence;
    if (typeof absence !== "object") {
      variantOverrideLabel = undefined;
    } else {
      var tmp;
      switch (absence._0) {
        case "AlwaysSent" :
            tmp = "always sent (on this event variant)";
            break;
        case "SometimesSent" :
            tmp = "sometimes sent (on this event variant)";
            break;
        case "NeverSent" :
            tmp = "never sent (on this event variant)";
            break;
        
      }
      variantOverrideLabel = tmp;
    }
  } else {
    variantOverrideLabel = undefined;
  }
  var textColor = variantOverrideLabel !== undefined ? Styles.Color.darkGreen : Styles.Color.light10;
  var backgroundColor = variantOverrideLabel !== undefined ? Styles.Color.lightGreen : Css.transparent;
  var match$2 = property.absence;
  return React.createElement("div", {
              className: containerStyles
            }, match$2 !== undefined ? (
                canEditAbsence ? React.createElement("button", {
                        ref: editButtonRef,
                        className: buttonStyle,
                        onClick: (function (param) {
                            Curry._1(setEditModalVisible, (function (param) {
                                    return true;
                                  }));
                          })
                      }, React.createElement($$Text.make, {
                            element: "Span",
                            size: "Small",
                            weight: "Regular",
                            color: textColor,
                            backgroundColor: backgroundColor,
                            children: variantOverrideLabel !== undefined ? variantOverrideLabel : absenceModeToLabel(isGlobalWorkspace, PropertyAbsenceUtils.getSimplestAbsenceModeByEvent(match$2, sources, $$event, property.sendAs))
                          }), React.createElement(Spacer.make, {
                            width: 2
                          }), React.createElement(Icon.make, {
                            type_: "chevronDown"
                          }), React.createElement(PortalMenu_deprecated.make, {
                            distance: 4,
                            visible: match$1[0],
                            onClose: (function (param) {
                                Curry._1(setEditModalVisible, (function (param) {
                                        return false;
                                      }));
                              }),
                            recalculateOnBoundingRectChanges: true,
                            referenceItemRef: editButtonRef,
                            children: React.createElement(PortalMenuAnimator.make, {
                                  key_: "edit-absence-" + property.id,
                                  children: variant !== undefined ? React.createElement(PropertyAbsenceConfig$PropertyAbsenceVariantOverridePopup, {
                                          property: property,
                                          event: $$event,
                                          onClose: (function (param) {
                                              Curry._1(setEditModalVisible, (function (param) {
                                                      return false;
                                                    }));
                                            }),
                                          variant: variant,
                                          sendActions: sendActions
                                        }) : React.createElement(PropertyAbsenceConfig$PropertyAbsenceEditPopup, {
                                          model: model,
                                          absence: match$2,
                                          event: $$event,
                                          onClose: (function (param) {
                                              Curry._1(setEditModalVisible, (function (param) {
                                                      return false;
                                                    }));
                                            }),
                                          property: property,
                                          sources: sources,
                                          sendActions: sendActions
                                        })
                                })
                          })) : React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("inlineFlex"),
                              tl: {
                                hd: Css.flexShrink(0.0),
                                tl: {
                                  hd: Css.marginLeft(Css.px(4)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            element: "Span",
                            size: "Small",
                            weight: "Regular",
                            color: textColor,
                            backgroundColor: backgroundColor,
                            children: variantOverrideLabel !== undefined ? variantOverrideLabel : absenceModeToLabel(isGlobalWorkspace, PropertyAbsenceUtils.getSimplestAbsenceModeByEvent(match$2, sources, $$event, property.sendAs))
                          }))
              ) : null);
}

var make = PropertyAbsenceConfig;

export {
  absenceModeToLabel ,
  make ,
}
/* rootStyles Not a pure module */
