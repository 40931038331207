// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as StringExt from "./StringExt.mjs";
import * as TextButton from "./TextButton.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";

function AutoFillCasingDetail(Props) {
  var autoCorrect = Props.autoCorrect;
  var toggleAutoCorrect = Props.toggleAutoCorrect;
  var canDisableAutoCorrect = Props.canDisableAutoCorrect;
  var expectedCase = Props.expectedCase;
  var name = Props.name;
  var kind = Props.kind;
  var globalSend = GlobalSendContext.use();
  var kindLabel;
  kindLabel = kind === "Property" ? "property" : "event";
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: autoCorrect ? "Auto-converting to " + NameUtils.getCorrectCase("Title", Case.tToJs(expectedCase)) + " Case" : "Auto-convert disabled"
                }), autoCorrect ? React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.textDecoration("lineThrough"),
                          tl: {
                            hd: Css.textDecorationColor(Styles.Color.red),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.paleRed,
                        children: name
                      })) : React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light09,
                    children: name
                  }), autoCorrect ? React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.green,
                    children: NameUtils.getCorrectCase(expectedCase, name)
                  }) : React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.textDecoration("lineThrough"),
                          tl: {
                            hd: Css.textDecorationColor(Styles.Color.green),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.green,
                        children: NameUtils.getCorrectCase(expectedCase, name)
                      })), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light11,
                  children: "Avo checks that " + kindLabel + " names match your workspace's preferred casing."
                }), React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      Curry._1(globalSend, {
                            TAG: "OpenSlideOver",
                            _0: {
                              NAME: "DiscrepanciesConfig",
                              VAL: undefined
                            }
                          });
                    }),
                  style: "Blue",
                  size: "Small",
                  children: "Configure casing rules"
                }), canDisableAutoCorrect ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(Mantine.Checkbox.make, {
                        checked: autoCorrect,
                        label: StringExt.widowless("Auto-convert " + kindLabel + " name"),
                        labelPosition: "right",
                        onChange: toggleAutoCorrect,
                        size: "xs"
                      })) : null);
}

var make = AutoFillCasingDetail;

export {
  make ,
}
/* Css Not a pure module */
