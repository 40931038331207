// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Nanoid from "nanoid";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelStore from "../ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as DomainStore from "../DomainStore.mjs";
import * as AppFeatureFlag from "../AppFeatureFlag.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as DiscrepancyContext from "../DiscrepancyContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as ImportReview__V2__Utils from "../import/ImportReview__V2__Utils.mjs";
import * as InspectorEventViewModelUtils from "./InspectorEventViewModelUtils.mjs";
import * as InspectorToTrackingPlanHelpers from "./InspectorToTrackingPlanHelpers.mjs";
import * as InspectorViewContextSidebarIssues__V2 from "./InspectorViewContextSidebarIssues__V2.mjs";

var containerStyle = Curry._1(Css.style, {
      hd: Css.width(Css.px(280)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexShrink(0.0),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light02),
              tl: {
                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(3), Css.px(10), Css.px(0), undefined, Css.rgba(0, 0, 0, {
                              NAME: "num",
                              VAL: 0.1
                            }))),
                tl: {
                  hd: Css.zIndex(1),
                  tl: {
                    hd: Css.overflowY("auto"),
                    tl: {
                      hd: Css.height(Css.pct(100.0)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var emptyStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.flexGrow(1.0),
                tl: {
                  hd: Css.padding(Css.px(20)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var emptyTitleStyle = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.large),
      tl: {
        hd: Css.color(Styles.Color.light10),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.semi),
          tl: {
            hd: Css.textAlign("center"),
            tl: {
              hd: Css.lineHeight(Css.pct(140.0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var emptySubtitleStyle = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.medium),
      tl: {
        hd: Css.color(Styles.Color.light08),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.semi),
          tl: {
            hd: Css.textAlign("center"),
            tl: {
              hd: Css.paddingTop(Css.px(5)),
              tl: {
                hd: Css.lineHeight(Css.pct(140.0)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var addEventBackdrop = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.backgroundColor(Css.rgba(0, 0, 0, {
                          NAME: "num",
                          VAL: 0.2
                        })),
                tl: {
                  hd: Css.zIndex(Styles.ZIndex.sidebar + 10 | 0),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var addEvent = Curry._1(Css.style, {
      hd: Css.width(Css.px(280)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light02),
            tl: {
              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(3), Css.px(10), Css.px(0), undefined, Css.rgba(0, 0, 0, {
                            NAME: "num",
                            VAL: 0.1
                          }))),
              tl: {
                hd: Css.zIndex(Styles.ZIndex.sidebar + 11 | 0),
                tl: {
                  hd: Css.overflowY("auto"),
                  tl: {
                    hd: Css.height(Css.vh(100)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function InspectorViewContextSidebar__V2(Props) {
  var cell = Props.cell;
  var modelSources = Props.modelSources;
  var modelEvents = Props.modelEvents;
  var rules = Props.rules;
  var modelProperties = Props.modelProperties;
  var modelDestinations = Props.modelDestinations;
  var globalSend = GlobalSendContext.use();
  var model = ModelStore.useModel();
  var mappedSources = ModelStore.Mapped.useSources();
  var domainEvents = DomainStore.useDomainEvents();
  var schemaBundle = SchemaBundleContext.use();
  var sendActions = SendActionsContext.use();
  var match = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match.expectedPropertyCase;
  var addToast = Toast.useAddToast();
  var shouldCreateAsSometimesSent = AppFeatureFlag.useFeatureFlag("InspectorAddToTrackingPlanAsSometimesSent");
  var onAddEventToTrackingPlan = function ($$event) {
    var importModel = ImportReview__V2__Utils.toImportModel((function (prim) {
            return Nanoid.nanoid();
          }), model, modelProperties, mappedSources, [$$event.eventShape], "AddOnly", domainEvents);
    Curry._1(globalSend, {
          TAG: "OpenModal",
          _0: {
            NAME: "ImportReview",
            VAL: [
              importModel,
              "AddOnly",
              "InspectorEventsSidebar"
            ]
          }
        });
  };
  var onAddPropertyToTrackingPlan = function (property, $$event) {
    InspectorToTrackingPlanHelpers.Property.onCreatePropertyV2($$event, property, model, sendActions, schemaBundle, expectedPropertyCase, addToast, globalSend, "InspectorSidebar", shouldCreateAsSometimesSent);
  };
  var tmp;
  var exit = 0;
  if (cell !== undefined && typeof cell === "object") {
    var variant = cell.NAME;
    if (variant === "PropertyVolume" || variant === "PropertyName" || variant === "PropertyIssues") {
      var match$1 = cell.VAL;
      var $$event = match$1[0];
      tmp = React.createElement(InspectorViewContextSidebarIssues__V2.make, {
            cell: cell,
            eventName: $$event.eventName,
            onAddEvent: (function (param) {
                onAddEventToTrackingPlan($$event);
              }),
            onAddProperty: (function (property) {
                onAddPropertyToTrackingPlan(property, $$event);
              }),
            sourceIds: Belt_List.fromArray(InspectorEventViewModelUtils.$$Event.getSourceIds($$event)),
            sourceIssues: InspectorEventViewModelUtils.Property.getIssuesGroupedBySource(InspectorEventViewModelUtils.$$Event.getSourceIds($$event), match$1[1]),
            modelSources: modelSources,
            modelEvents: modelEvents,
            rules: rules,
            modelProperties: modelProperties,
            modelDestinations: modelDestinations
          });
    } else if (variant === "PropertySourceVolume" || variant === "PropertySourceIssues") {
      var match$2 = cell.VAL;
      var propertySourceId = match$2[2];
      var property = match$2[1];
      var $$event$1 = match$2[0];
      tmp = React.createElement(InspectorViewContextSidebarIssues__V2.make, {
            cell: cell,
            eventName: $$event$1.eventName,
            onAddEvent: (function (param) {
                onAddEventToTrackingPlan($$event$1);
              }),
            onAddProperty: (function (property) {
                onAddPropertyToTrackingPlan(property, $$event$1);
              }),
            sourceIds: Belt_Option.mapWithDefault(propertySourceId, /* [] */0, (function (id) {
                    return {
                            hd: id,
                            tl: /* [] */0
                          };
                  })),
            sourceIssues: Belt_Option.mapWithDefault(propertySourceId, [], (function (sourceId) {
                    return InspectorEventViewModelUtils.Property.getIssuesGroupedBySource([sourceId], property);
                  })),
            modelSources: modelSources,
            modelEvents: modelEvents,
            rules: rules,
            modelProperties: modelProperties,
            modelDestinations: modelDestinations
          });
    } else if (variant === "Error") {
      exit = 1;
    } else if (variant === "EventIssues" || variant === "EventName" || variant === "EventVolume") {
      var $$event$2 = cell.VAL;
      tmp = React.createElement(InspectorViewContextSidebarIssues__V2.make, {
            cell: cell,
            eventName: $$event$2.eventName,
            onAddEvent: (function (param) {
                onAddEventToTrackingPlan($$event$2);
              }),
            onAddProperty: (function (property) {
                onAddPropertyToTrackingPlan(property, $$event$2);
              }),
            sourceIds: Belt_List.fromArray(InspectorEventViewModelUtils.$$Event.getSourceIds($$event$2)),
            sourceIssues: InspectorEventViewModelUtils.$$Event.getPropertyIssuesGroupedBySource(undefined, $$event$2),
            modelSources: modelSources,
            modelEvents: modelEvents,
            rules: rules,
            modelProperties: modelProperties,
            modelDestinations: modelDestinations
          });
    } else {
      var match$3 = cell.VAL;
      var eventSourceId = match$3[1];
      var $$event$3 = match$3[0];
      tmp = React.createElement(InspectorViewContextSidebarIssues__V2.make, {
            cell: cell,
            eventName: $$event$3.eventName,
            onAddEvent: (function (param) {
                onAddEventToTrackingPlan($$event$3);
              }),
            onAddProperty: (function (property) {
                onAddPropertyToTrackingPlan(property, $$event$3);
              }),
            sourceIds: Belt_Option.mapWithDefault(eventSourceId, /* [] */0, (function (id) {
                    return {
                            hd: id,
                            tl: /* [] */0
                          };
                  })),
            sourceIssues: Belt_Option.mapWithDefault(eventSourceId, [], (function (sourceId) {
                    return InspectorEventViewModelUtils.$$Event.getPropertyIssuesGroupedBySource([sourceId], $$event$3);
                  })),
            modelSources: modelSources,
            modelEvents: modelEvents,
            rules: rules,
            modelProperties: modelProperties,
            modelDestinations: modelDestinations
          });
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: emptyStyle
        }, React.createElement("div", {
              className: emptyTitleStyle
            }, "Issues Inspector"), React.createElement("div", {
              className: emptySubtitleStyle
            }, "Select an issue in the table to see more info"));
  }
  return React.createElement("section", {
              className: containerStyle
            }, tmp);
}

var make = InspectorViewContextSidebar__V2;

export {
  containerStyle ,
  emptyStyle ,
  emptyTitleStyle ,
  emptySubtitleStyle ,
  addEventBackdrop ,
  addEvent ,
  make ,
}
/* containerStyle Not a pure module */
