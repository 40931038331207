// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ActionDiff from "../../shared/ActionDiff.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_MutableSet from "rescript/lib/es6/belt_MutableSet.js";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as MappedModelTransform from "../../model/src/MappedModelTransform.mjs";
import * as Belt_MutableMapString from "rescript/lib/es6/belt_MutableMapString.js";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as CopyChangesToBranchModal__Types from "./CopyChangesToBranchModal__Types.mjs";

function dedupe(items) {
  var itemsSet = Belt_MutableSet.make(CopyChangesToBranchModal__Types.CopyItem.Comparator);
  return Belt_Array.keep(items, (function (item) {
                if (Belt_MutableSet.has(itemsSet, item)) {
                  return false;
                } else {
                  Belt_MutableSet.add(itemsSet, item);
                  return true;
                }
              }));
}

function prioritize(items) {
  var itemsSet = Belt_Set.fromArray(items, CopyChangesToBranchModal__Types.CopyItem.Comparator);
  return dedupe(Belt_Array.keep(items, (function (item) {
                    var variant = item.NAME;
                    if (variant === "sourceDestination") {
                      return !Belt_Set.has(itemsSet, CopyChangesToBranchModal__Types.CopyItem.sourceSpecificToEventSpecific(item));
                    } else if (variant === "source" || variant === "category" || variant === "property" || variant === "destination" || variant === "propertyBundle" || variant === "event") {
                      return true;
                    } else {
                      return !Belt_Set.has(itemsSet, CopyChangesToBranchModal__Types.CopyItem.toStandalone(item));
                    }
                  })));
}

var CopyItems = {
  dedupe: dedupe,
  prioritize: prioritize
};

function resolveCopyItemActions(copyItemsActions) {
  var actions = [];
  var ignoredActions = [];
  var itemsNotFound = [];
  Belt_Array.forEachU(copyItemsActions, (function (copyItemActions) {
          var variant = copyItemActions.NAME;
          if (variant === "ignoredActions") {
            ignoredActions.push(copyItemActions.VAL[1]);
          } else if (variant === "itemNotFound") {
            itemsNotFound.push(copyItemActions.VAL);
          } else {
            actions.push(copyItemActions.VAL[1]);
          }
        }));
  var actions$1 = Belt_List.flatten(Belt_List.fromArray(actions));
  var ignoredActions$1 = Belt_List.flatten(Belt_List.fromArray(ignoredActions));
  return {
          appliedActions: actions$1,
          ignoredActions: ignoredActions$1,
          allActions: Belt_List.concat(actions$1, ignoredActions$1),
          itemsNotFound: itemsNotFound
        };
}

function allowedValueIrrelevantDueToPinnedValueOnEvent(pinnedValuesMap, eventId, propertyId, allowedValue) {
  var propertyPinnedValues = Belt_MapString.get(pinnedValuesMap, eventId);
  if (propertyPinnedValues === undefined) {
    return true;
  }
  var propertyPinnedValue = Belt_MapString.get(Caml_option.valFromOption(propertyPinnedValues), propertyId);
  if (propertyPinnedValue !== undefined) {
    return Caml_obj.notequal(Caml_option.valFromOption(propertyPinnedValue), allowedValue);
  } else {
    return false;
  }
}

function allowedValueIrrelevantDueToPinnedValueForAllEvents(eventIds, pinnedValuesMap, propertyId, allowedValue) {
  return Belt_Array.everyU(Belt_SetString.toArray(eventIds), (function (eventId) {
                return allowedValueIrrelevantDueToPinnedValueOnEvent(pinnedValuesMap, eventId, propertyId, allowedValue);
              }));
}

function getFilteredEventsPropertyActionsToCopy(eventPropertyActionsToCopy, pinnedValuesMap, propertyId, relevantEventIds) {
  return Belt_List.keepMapU(eventPropertyActionsToCopy, (function (action) {
                var eventId;
                var allowedValue;
                if (typeof action !== "object") {
                  return action;
                }
                var variant = action.NAME;
                if (variant === "AddEventSpecificPropertyValueForAllEvents" || variant === "RemoveEventSpecificPropertyValueForAllEvents") {
                  var match = action.VAL[1];
                  if (typeof match === "object" && match.NAME === "StringLit" && allowedValueIrrelevantDueToPinnedValueForAllEvents(relevantEventIds, pinnedValuesMap, propertyId, match.VAL)) {
                    return ;
                  } else {
                    return action;
                  }
                }
                if (variant === "SetPropertyAbsenceOnVariant" || variant === "UpdatePropertyRegexOverrideOnEventVariant") {
                  if (Belt_SetString.has(relevantEventIds, action.VAL[0].baseEventId)) {
                    return action;
                  } else {
                    return ;
                  }
                }
                if (variant === "UpdatePropertyRegexValidation") {
                  var eventId$1 = action.VAL[1];
                  if (eventId$1 !== undefined && !Belt_SetString.has(relevantEventIds, eventId$1)) {
                    return ;
                  } else {
                    return action;
                  }
                }
                if (variant === "AddEventSpecificPropertyValue" || variant === "RemoveEventSpecificPropertyValue") {
                  var match$1 = action.VAL;
                  var match$2 = match$1[2];
                  if (typeof match$2 !== "object") {
                    return action;
                  }
                  if (match$2.NAME !== "StringLit") {
                    return action;
                  }
                  eventId = match$1[0];
                  allowedValue = match$2.VAL;
                } else if (variant === "SetEventSpecificPropertyValueSources") {
                  var match$3 = action.VAL;
                  var match$4 = match$3[2];
                  if (typeof match$4 !== "object") {
                    return action;
                  }
                  if (match$4.NAME !== "StringLit") {
                    return action;
                  }
                  eventId = match$3[0];
                  allowedValue = match$4.VAL;
                } else {
                  if (variant === "AddEventSpecificPropertyValueForSomeEvents") {
                    var match$5 = action.VAL;
                    var match$6 = match$5[1];
                    if (typeof match$6 !== "object") {
                      return action;
                    }
                    if (match$6.NAME !== "StringLit") {
                      return action;
                    }
                    var propertyValueDisallowedEvents = match$5[2];
                    if (allowedValueIrrelevantDueToPinnedValueForAllEvents(Belt_SetString.keep(relevantEventIds, (function (eventId) {
                                  return !Belt_MapString.has(propertyValueDisallowedEvents, eventId);
                                })), pinnedValuesMap, propertyId, match$6.VAL)) {
                      return ;
                    } else {
                      return action;
                    }
                  }
                  if (variant !== "UpdatePropertyAbsence") {
                    return action;
                  }
                  var match$7 = action.VAL[1];
                  var exit = 0;
                  if (typeof match$7 !== "object") {
                    return action;
                  }
                  switch (match$7.TAG) {
                    case "Source" :
                        return action;
                    case "Event" :
                    case "EventSource" :
                        exit = 2;
                        break;
                    
                  }
                  if (exit === 2) {
                    if (Belt_SetString.has(relevantEventIds, match$7._0)) {
                      return action;
                    } else {
                      return ;
                    }
                  }
                  
                }
                if (allowedValueIrrelevantDueToPinnedValueOnEvent(pinnedValuesMap, eventId, propertyId, allowedValue)) {
                  return ;
                } else {
                  return action;
                }
              }));
}

function getPinnedValuesMap(relevantEventIds, toMappedEvents) {
  return Belt_MapString.fromArray(Belt_Array.keepMapU(Belt_SetString.toArray(relevantEventIds), (function (eventId) {
                    var match = Curry._2(TrackingPlanMappedModel.Events.get, toMappedEvents, eventId);
                    if (match !== undefined) {
                      return [
                              eventId,
                              Belt_MapString.fromArray(Belt_Array.keepMapU(Belt_List.toArray(match.directPropertyRefs), (function (propertyRef) {
                                          if (propertyRef.TAG !== "PropertyRef") {
                                            return ;
                                          }
                                          var match = propertyRef._0;
                                          var match$1 = match.pinnedValue;
                                          if (match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "StringLit") {
                                            return [
                                                    match.id,
                                                    match$1.VAL
                                                  ];
                                          }
                                          
                                        })))
                            ];
                    }
                    
                  })));
}

function keepPropertyRelatedRule(ignoredItemsSet, propertyId, rule) {
  var match = rule.item;
  var rulePropertyId;
  var variant = match.NAME;
  if (variant === "PropertyRef") {
    rulePropertyId = match.VAL[1];
  } else {
    if (variant !== "Property") {
      return ;
    }
    rulePropertyId = match.VAL;
  }
  if (rulePropertyId !== propertyId) {
    return ;
  }
  var definition = rule.definition;
  var variant$1 = definition.NAME;
  if (variant$1 === "NameMapping") {
    var match$1 = definition.VAL.destination;
    if (match$1 === undefined) {
      return rule;
    }
    if (typeof match$1 !== "object") {
      return rule;
    }
    var destinationId = match$1.VAL;
    if (Belt_Set.has(ignoredItemsSet, {
            NAME: "destination",
            VAL: destinationId
          }) || Belt_Set.has(ignoredItemsSet, {
            NAME: "eventDestination",
            VAL: destinationId
          }) || Belt_Set.has(ignoredItemsSet, {
            NAME: "sourceDestination",
            VAL: destinationId
          })) {
      return ;
    } else {
      return rule;
    }
  }
  if (variant$1 !== "NameInLocalWorkspace") {
    return rule;
  }
  var match$2 = definition.VAL.workspace;
  if (match$2 !== undefined && typeof match$2 === "object" && Belt_Set.has(ignoredItemsSet, {
          NAME: "eventSource",
          VAL: match$2.VAL
        })) {
    return ;
  } else {
    return rule;
  }
}

function getPropertyCopyActions(fromModel, toModel, toMappedEvents, fromMappedProperties, fromMappedPropertiesArchive, hasMigratedToEventSpecificAllowedValues, ignoredItemsSet, toProperty) {
  var propertyActionsToCopy = ActionDiff.getNewAndUpdatedPropertyActions(fromMappedProperties, fromMappedPropertiesArchive, fromModel, toModel, toMappedEvents, hasMigratedToEventSpecificAllowedValues, toProperty);
  var keepPropertyRelatedRules = function (rules, propertyId) {
    return Belt_List.keepMap(rules, (function (param) {
                  return keepPropertyRelatedRule(ignoredItemsSet, propertyId, param);
                }));
  };
  var ruleActionsToCopy = ActionDiff.diffRules(keepPropertyRelatedRules(fromModel.rules, toProperty.id), keepPropertyRelatedRules(toModel.rules, toProperty.id), toModel);
  return Belt_List.concat(propertyActionsToCopy, ruleActionsToCopy);
}

function getEventPropertyCopyActions(fromModel, toModel, toMappedEvents, fromMappedProperties, fromMappedPropertiesArchive, hasMigratedToEventSpecificAllowedValues, relevantEventIds, toProperty, ignoredItemsSet) {
  var propertyActionsToCopy = getPropertyCopyActions(fromModel, toModel, toMappedEvents, fromMappedProperties, fromMappedPropertiesArchive, hasMigratedToEventSpecificAllowedValues, ignoredItemsSet, toProperty);
  var pinnedValuesMap = getPinnedValuesMap(relevantEventIds, toMappedEvents);
  return getFilteredEventsPropertyActionsToCopy(propertyActionsToCopy, pinnedValuesMap, toProperty.id, relevantEventIds);
}

function getFilteredEventCategoryActionsToCopy(relevantEventIds, eventCategoryActionsToCopy) {
  return Belt_List.keepU(eventCategoryActionsToCopy, (function (action) {
                if (typeof action !== "object") {
                  return true;
                }
                var variant = action.NAME;
                if (variant === "ReorderGoals" || variant === "RemoveMetricFromGoal" || variant === "ReorderGoalsV2" || variant === "AddMetricToGoal" || variant === "GoalComment" || variant === "ReorderMetricsInGoalV2" || variant === "ReorderEventsInGoal" || variant === "ReorderMetricsInGoal" || variant === "ReorderEventsInGoalV2") {
                  return false;
                }
                if (variant !== "Archive") {
                  if (variant === "RemoveEventFromGoal" || variant === "AddEventToGoal") {
                    return Belt_SetString.has(relevantEventIds, action.VAL[1]);
                  } else {
                    return true;
                  }
                }
                return true;
              }));
}

function getEventCategoryCopyActions(fromGoals, fromGoalsArchive, toMappedEvents, relevantEventIds, category) {
  return getFilteredEventCategoryActionsToCopy(relevantEventIds, ActionDiff.getNewAndUpdatedGoalActions(fromGoals, fromGoalsArchive, toMappedEvents, category));
}

function isIgnoredSource(ignoredSet, sourceId) {
  if (Belt_Set.has(ignoredSet, {
          NAME: "eventSource",
          VAL: sourceId
        })) {
    return true;
  } else {
    return Belt_Set.has(ignoredSet, {
                NAME: "source",
                VAL: sourceId
              });
  }
}

function isIgnoredDestination(ignoredSet, destinationId) {
  if (Belt_Set.has(ignoredSet, {
          NAME: "destination",
          VAL: destinationId
        }) || Belt_Set.has(ignoredSet, {
          NAME: "eventDestination",
          VAL: destinationId
        })) {
    return true;
  } else {
    return Belt_Set.has(ignoredSet, {
                NAME: "sourceDestination",
                VAL: destinationId
              });
  }
}

function filterDestinationActionsToCopyForSource(ignoredItemsSet, actions) {
  return Belt_List.keepMapU(actions, (function (action) {
                if (typeof action !== "object") {
                  return action;
                }
                var variant = action.NAME;
                if ((variant === "ExcludeDestinationFromSource" || variant === "IncludeDestinationInSourceV2" || variant === "LegacyToggleDestination" || variant === "LegacyIncludeDestinationInSource" || variant === "UpdateSourceDestinationMode") && isIgnoredDestination(ignoredItemsSet, action.VAL[1])) {
                  return ;
                } else {
                  return action;
                }
              }));
}

function filterCategoryActionsToCopy(ignoredItemsSet, ignoredChangesSet, actions) {
  var filteredActions = [];
  var actions$1 = Belt_List.keepU(actions, (function (action) {
          var categoryId;
          if (typeof action !== "object") {
            return true;
          }
          var variant = action.NAME;
          if (variant === "RemoveGoal" || variant === "AddGoal") {
            categoryId = action.VAL;
          } else {
            if (variant === "UpdateGoalName" || variant === "AddMetricToGoal" || variant === "ReorderEventsInGoal" || variant === "ReorderEventsInGoalV2" || variant === "UpdateGoalDescription") {
              if (Belt_Set.has(ignoredChangesSet, {
                      NAME: "category",
                      VAL: action.VAL[0]
                    })) {
                return true;
              } else {
                filteredActions.push(action);
                return false;
              }
            }
            if (variant === "RemoveMetricFromGoal" || variant === "ReorderMetricsInGoalV2" || variant === "ReorderMetricsInGoal") {
              return true;
            }
            if (!(variant === "RemoveEventFromGoal" || variant === "AddEventToGoal")) {
              return true;
            }
            categoryId = action.VAL[0];
          }
          if (Belt_Set.has(ignoredItemsSet, {
                  NAME: "category",
                  VAL: categoryId
                })) {
            return true;
          } else {
            filteredActions.push(action);
            return false;
          }
        }));
  return [
          Belt_List.fromArray(filteredActions),
          actions$1
        ];
}

function filterEventActionsToCopy(ignoredItemsSet, actions) {
  return Belt_List.keepMapU(actions, (function (action) {
                if (typeof action !== "object") {
                  return action;
                }
                var variant = action.NAME;
                if (variant === "RemovePropertyRefV3" || variant === "RemovePropertyRefV2Deprecated" || variant === "AddPropertyRef") {
                  if (!Belt_Set.has(ignoredItemsSet, {
                          NAME: "eventProperty",
                          VAL: action.VAL[1]
                        })) {
                    return action;
                  } else {
                    return ;
                  }
                }
                if (variant === "ExcludeEventFromSource" || variant === "IncludeEventInSource" || variant === "ExcludeEventFromSourceV2") {
                  if (!Belt_Set.has(ignoredItemsSet, {
                          NAME: "eventSource",
                          VAL: action.VAL[1]
                        })) {
                    return action;
                  } else {
                    return ;
                  }
                }
                if (variant === "IncludeEventInSourceV2") {
                  var match = action.VAL;
                  var sourceId = match[1];
                  if (Belt_Set.has(ignoredItemsSet, {
                          NAME: "eventSource",
                          VAL: sourceId
                        })) {
                    return ;
                  } else {
                    return {
                            NAME: "IncludeEventInSourceV2",
                            VAL: [
                              match[0],
                              sourceId,
                              Belt_List.keepU(match[2], (function (id) {
                                      return !isIgnoredDestination(ignoredItemsSet, id);
                                    })),
                              match[3]
                            ]
                          };
                  }
                }
                if (!(variant === "IncludeDestinationInEventSource" || variant === "ExcludeDestinationFromEventSource")) {
                  if (variant === "AddPropertyGroupToEvent" && Belt_Set.has(ignoredItemsSet, {
                          NAME: "eventPropertyBundle",
                          VAL: action.VAL[1]
                        })) {
                    return ;
                  } else {
                    return action;
                  }
                }
                var match$1 = action.VAL;
                if (isIgnoredSource(ignoredItemsSet, match$1[1]) || isIgnoredDestination(ignoredItemsSet, match$1[2])) {
                  return ;
                } else {
                  return action;
                }
              }));
}

function keepEventRelatedRule(ignoredItemsSet, eventId, rule) {
  var match = rule.item;
  var ruleEventId;
  var variant = match.NAME;
  if (variant === "Property" || variant === "PropertyRef") {
    return ;
  }
  ruleEventId = variant === "Event" ? match.VAL : match.VAL[0];
  if (ruleEventId !== eventId) {
    return ;
  }
  var definition = rule.definition;
  var variant$1 = definition.NAME;
  if (variant$1 === "NameMapping") {
    var match$1 = definition.VAL.destination;
    if (match$1 === undefined) {
      return rule;
    }
    if (typeof match$1 !== "object") {
      return rule;
    }
    var destinationId = match$1.VAL;
    if (!Belt_Set.has(ignoredItemsSet, {
            NAME: "destination",
            VAL: destinationId
          }) && !Belt_Set.has(ignoredItemsSet, {
            NAME: "eventDestination",
            VAL: destinationId
          }) && !Belt_Set.has(ignoredItemsSet, {
            NAME: "sourceDestination",
            VAL: destinationId
          })) {
      return rule;
    } else {
      return ;
    }
  }
  if (variant$1 !== "NameInLocalWorkspace") {
    return rule;
  }
  var match$2 = definition.VAL.workspace;
  if (match$2 !== undefined && typeof match$2 === "object" && Belt_Set.has(ignoredItemsSet, {
          NAME: "eventSource",
          VAL: match$2.VAL
        })) {
    return ;
  } else {
    return rule;
  }
}

function getEventCopyActions(fromRules, toModel, fromMappedEvents, fromMappedEventsArchive, toMappedProperties, toMappedPropertyBundles, toGlobalRequirements, toEvent, ignoredItemsSet) {
  var eventActionsToCopy = ActionDiff.getNewAndUpdatedEventActions(fromMappedEvents, fromMappedEventsArchive, toMappedProperties, toMappedPropertyBundles, toGlobalRequirements, toEvent);
  var keepEventRelatedRules = function (rules, eventId) {
    return Belt_List.keepMap(rules, (function (param) {
                  return keepEventRelatedRule(ignoredItemsSet, eventId, param);
                }));
  };
  var ruleActionsToCopy = ActionDiff.diffRules(keepEventRelatedRules(fromRules, toEvent.id), keepEventRelatedRules(toModel.rules, toEvent.id), toModel);
  return Belt_List.concat(eventActionsToCopy, ruleActionsToCopy);
}

function getPropertyBundleCopyActions(withEventOpt, toMappedPropertyBundles, propertyBundleId) {
  var withEvent = withEventOpt !== undefined ? withEventOpt : false;
  var match = Curry._2(TrackingPlanMappedModel.PropertyBundles.get, toMappedPropertyBundles, propertyBundleId);
  var eventPropertyCopyItems = match !== undefined ? Belt_List.toArray(Belt_List.mapU(match.properties, (function (propertyId) {
                if (withEvent) {
                  return {
                          NAME: "eventProperty",
                          VAL: propertyId
                        };
                } else {
                  return {
                          NAME: "property",
                          VAL: propertyId
                        };
                }
              }))) : [];
  return Belt_Array.concat([{
                NAME: "eventPropertyBundle",
                VAL: propertyBundleId
              }], eventPropertyCopyItems);
}

function getItemsActions(toModel, fromModel, ignoredItemsOpt, copyItems) {
  var ignoredItems = ignoredItemsOpt !== undefined ? Caml_option.valFromOption(ignoredItemsOpt) : Belt_Map.make(CopyChangesToBranchModal__Types.CopyItem.Comparator);
  var fromMappedEvents = Curry._1(MappedModelTransform.Events.toMapped, fromModel.events);
  var fromMappedEventsArchive = Curry._1(MappedModelTransform.Events.toMapped, fromModel.archive.events);
  var fromMappedProperties = Curry._1(MappedModelTransform.Properties.toMapped, fromModel.properties);
  var fromMappedPropertiesArchive = Curry._1(MappedModelTransform.Properties.toMapped, fromModel.archive.properties);
  var toMappedEvents = Curry._1(MappedModelTransform.Events.toMapped, toModel.events);
  var toMappedProperties = Curry._1(MappedModelTransform.Properties.toMapped, toModel.properties);
  var toMappedPropertyBundles = Curry._1(MappedModelTransform.PropertyBundles.toMapped, toModel.propertyBundles);
  var toMappedSources = Curry._1(MappedModelTransform.Sources.toMapped, toModel.sources);
  var toMappedDestinations = Curry._1(MappedModelTransform.Destinations.toMapped, toModel.destinations);
  var toMappedCategories = Curry._1(MappedModelTransform.Categories.toMapped, toModel.goals);
  var ignoredChangesSet = Belt_Set.fromArray(Belt_Map.keysToArray(ignoredItems), CopyChangesToBranchModal__Types.CopyItem.Comparator);
  var ignoredItemsSet = Belt_Set.fromArray(Belt_Array.keepMap(Belt_Map.toArray(ignoredItems), (function (param) {
              if (param[1] === "ExcludeItem") {
                return param[0];
              }
              
            })), CopyChangesToBranchModal__Types.CopyItem.Comparator);
  var hasMigratedToEventSpecificAllowedValues = ModelUtils.hasMigrated(toModel, "EventSpecificAllowedPropertyValues");
  var relevantEventIds = Belt_SetString.fromArray(Belt_Array.keepMap(copyItems, (function (copyItem) {
              if (typeof copyItem === "object" && copyItem.NAME === "event") {
                return copyItem.VAL;
              }
              
            })));
  return Belt_Array.concatMany(Belt_Array.map(prioritize(dedupe(Belt_Array.concatMany(Belt_Array.map(copyItems, (function (copyItem) {
                                    if (Belt_Set.has(ignoredItemsSet, copyItem)) {
                                      return [];
                                    }
                                    var variant = copyItem.NAME;
                                    if (variant === "sourceDestination") {
                                      return [{
                                                NAME: "sourceDestination",
                                                VAL: copyItem.VAL
                                              }];
                                    }
                                    if (variant === "eventProperty") {
                                      return [{
                                                NAME: "eventProperty",
                                                VAL: copyItem.VAL
                                              }];
                                    }
                                    if (variant === "eventPropertyBundle") {
                                      return getPropertyBundleCopyActions(true, toMappedPropertyBundles, copyItem.VAL);
                                    }
                                    if (variant === "event") {
                                      var eventId = copyItem.VAL;
                                      var match = Curry._2(TrackingPlanMappedModel.Events.get, toMappedEvents, eventId);
                                      var eventAssociatedActions;
                                      if (match !== undefined) {
                                        var eventPropertyCopyItems = Curry._2(TrackingPlanMappedModel.Properties.mapToArray, ModelUtils_mapped.getEventProperties(toMappedEvents, toMappedProperties, toMappedPropertyBundles, eventId), (function (param) {
                                                return {
                                                        NAME: "eventProperty",
                                                        VAL: param.id
                                                      };
                                              }));
                                        var propertyBundleCopyItems = Belt_List.toArray(Belt_List.mapU(match.propertyBundles, (function (propertyBundleRef) {
                                                    return {
                                                            NAME: "eventPropertyBundle",
                                                            VAL: propertyBundleRef.id
                                                          };
                                                  })));
                                        var eventSourceCopyItems = Belt_List.toArray(Belt_List.mapU(match.includeSources, (function (param) {
                                                    return {
                                                            NAME: "eventSource",
                                                            VAL: param.id
                                                          };
                                                  })));
                                        var eventDestinationCopyItems = Belt_List.toArray(Belt_List.flatten(Belt_List.mapU(match.includeDestinations, (function (source) {
                                                        var match = source.VAL;
                                                        var sourceId = match[0];
                                                        if (Belt_Set.has(ignoredItemsSet, {
                                                                NAME: "source",
                                                                VAL: sourceId
                                                              }) || Belt_Set.has(ignoredItemsSet, {
                                                                NAME: "eventSource",
                                                                VAL: sourceId
                                                              })) {
                                                          return /* [] */0;
                                                        } else {
                                                          return Belt_List.map(match[1], (function (id) {
                                                                        return {
                                                                                NAME: "eventDestination",
                                                                                VAL: id
                                                                              };
                                                                      }));
                                                        }
                                                      }))));
                                        var eventCategoryCopyItems = Belt_Array.mapU(ModelUtils_mapped.getGoalsWithEvent(eventId, toMappedCategories), (function (param) {
                                                return {
                                                        NAME: "category",
                                                        VAL: param.id
                                                      };
                                              }));
                                        eventAssociatedActions = Belt_Array.concatMany([
                                              eventPropertyCopyItems,
                                              propertyBundleCopyItems,
                                              eventSourceCopyItems,
                                              eventDestinationCopyItems,
                                              eventCategoryCopyItems
                                            ]);
                                      } else {
                                        eventAssociatedActions = [];
                                      }
                                      return Belt_Array.concat([{
                                                    NAME: "event",
                                                    VAL: eventId
                                                  }], eventAssociatedActions);
                                    }
                                    if (variant === "eventSource") {
                                      return [{
                                                NAME: "eventSource",
                                                VAL: copyItem.VAL
                                              }];
                                    }
                                    if (variant === "propertyBundle") {
                                      return getPropertyBundleCopyActions(false, toMappedPropertyBundles, copyItem.VAL);
                                    }
                                    if (variant === "destination") {
                                      return [{
                                                NAME: "destination",
                                                VAL: copyItem.VAL
                                              }];
                                    }
                                    if (variant === "property") {
                                      return [{
                                                NAME: "property",
                                                VAL: copyItem.VAL
                                              }];
                                    }
                                    if (variant === "category") {
                                      return [{
                                                NAME: "category",
                                                VAL: copyItem.VAL
                                              }];
                                    }
                                    if (variant !== "source") {
                                      return [{
                                                NAME: "eventDestination",
                                                VAL: copyItem.VAL
                                              }];
                                    }
                                    var sourceId = copyItem.VAL;
                                    var match$1 = Curry._2(TrackingPlanMappedModel.Sources.get, toMappedSources, sourceId);
                                    var sourceAssociatedActions = match$1 !== undefined ? Belt_List.toArray(Belt_List.mapU(match$1.destinations, (function (param) {
                                                  return {
                                                          NAME: "destination",
                                                          VAL: param.destinationId
                                                        };
                                                }))) : [];
                                    return Belt_Array.concat([{
                                                  NAME: "source",
                                                  VAL: sourceId
                                                }], sourceAssociatedActions);
                                  }))))), (function (copyItem) {
                    var variant = copyItem.NAME;
                    if (variant === "eventProperty") {
                      var match = ModelUtils.getPropertyById(copyItem.VAL, toModel);
                      if (match === undefined) {
                        return [{
                                  NAME: "itemNotFound",
                                  VAL: copyItem
                                }];
                      }
                      if (match.TAG === "PropertyRef") {
                        return [{
                                  NAME: "itemNotFound",
                                  VAL: copyItem
                                }];
                      }
                      var actions = getEventPropertyCopyActions(fromModel, toModel, toMappedEvents, fromMappedProperties, fromMappedPropertiesArchive, hasMigratedToEventSpecificAllowedValues, relevantEventIds, match._0, ignoredItemsSet);
                      if (Belt_Set.has(ignoredChangesSet, copyItem)) {
                        return [{
                                  NAME: "ignoredActions",
                                  VAL: [
                                    copyItem,
                                    actions
                                  ]
                                }];
                      } else {
                        return [{
                                  NAME: "actions",
                                  VAL: [
                                    copyItem,
                                    actions
                                  ]
                                }];
                      }
                    }
                    if (variant === "propertyBundle" || variant === "eventPropertyBundle") {
                      var toPropertyBundle = Curry._2(TrackingPlanMappedModel.PropertyBundles.get, toMappedPropertyBundles, copyItem.VAL);
                      if (toPropertyBundle === undefined) {
                        return [{
                                  NAME: "itemNotFound",
                                  VAL: copyItem
                                }];
                      }
                      var actions$1 = ActionDiff.getNewAndUpdatedPropertyBundleActions(fromModel.propertyBundles, fromModel.archive.propertyBundles, toMappedPropertyBundles, toModel.globalRequirements, toMappedProperties, toPropertyBundle);
                      if (Belt_Set.has(ignoredChangesSet, copyItem)) {
                        return [{
                                  NAME: "ignoredActions",
                                  VAL: [
                                    copyItem,
                                    actions$1
                                  ]
                                }];
                      } else {
                        return [{
                                  NAME: "actions",
                                  VAL: [
                                    copyItem,
                                    actions$1
                                  ]
                                }];
                      }
                    }
                    if (variant === "event") {
                      var toEvent = Curry._2(TrackingPlanMappedModel.Events.get, toMappedEvents, copyItem.VAL);
                      if (toEvent === undefined) {
                        return [{
                                  NAME: "itemNotFound",
                                  VAL: copyItem
                                }];
                      }
                      var actions$2 = getEventCopyActions(fromModel.rules, toModel, fromMappedEvents, fromMappedEventsArchive, toMappedProperties, toMappedPropertyBundles, toModel.globalRequirements, toEvent, ignoredItemsSet);
                      var filteredActions = filterEventActionsToCopy(ignoredItemsSet, actions$2);
                      return [
                              {
                                NAME: "actions",
                                VAL: [
                                  copyItem,
                                  filteredActions
                                ]
                              },
                              {
                                NAME: "ignoredActions",
                                VAL: [
                                  copyItem,
                                  actions$2
                                ]
                              }
                            ];
                    }
                    if (variant === "source" || variant === "eventSource") {
                      var sourceId = copyItem.VAL;
                      var toSource = Curry._2(TrackingPlanMappedModel.Sources.get, toMappedSources, sourceId);
                      if (toSource === undefined) {
                        return [{
                                  NAME: "itemNotFound",
                                  VAL: copyItem
                                }];
                      }
                      var actions$3 = ActionDiff.getNewAndUpdatedSourceActions(fromModel.sources, fromModel.archive.sources, toSource);
                      var filteredActions$1 = filterDestinationActionsToCopyForSource(ignoredItemsSet, actions$3);
                      if (isIgnoredSource(ignoredChangesSet, sourceId)) {
                        return [{
                                  NAME: "ignoredActions",
                                  VAL: [
                                    copyItem,
                                    actions$3
                                  ]
                                }];
                      } else {
                        return [
                                {
                                  NAME: "actions",
                                  VAL: [
                                    copyItem,
                                    filteredActions$1
                                  ]
                                },
                                {
                                  NAME: "ignoredActions",
                                  VAL: [
                                    copyItem,
                                    actions$3
                                  ]
                                }
                              ];
                      }
                    }
                    if (variant === "property") {
                      var match$1 = ModelUtils.getPropertyById(copyItem.VAL, toModel);
                      if (match$1 !== undefined) {
                        if (match$1.TAG === "PropertyRef") {
                          return [{
                                    NAME: "itemNotFound",
                                    VAL: copyItem
                                  }];
                        } else {
                          return [{
                                    NAME: "actions",
                                    VAL: [
                                      copyItem,
                                      getPropertyCopyActions(fromModel, toModel, toMappedEvents, fromMappedProperties, fromMappedPropertiesArchive, hasMigratedToEventSpecificAllowedValues, ignoredItemsSet, match$1._0)
                                    ]
                                  }];
                        }
                      } else {
                        return [{
                                  NAME: "itemNotFound",
                                  VAL: copyItem
                                }];
                      }
                    }
                    if (variant === "category") {
                      var category = Curry._2(TrackingPlanMappedModel.Categories.get, toMappedCategories, copyItem.VAL);
                      if (category === undefined) {
                        return [{
                                  NAME: "itemNotFound",
                                  VAL: copyItem
                                }];
                      }
                      var actions$4 = getEventCategoryCopyActions(fromModel.goals, fromModel.archive.goals, toMappedEvents, relevantEventIds, category);
                      var match$2 = filterCategoryActionsToCopy(ignoredItemsSet, ignoredChangesSet, actions$4);
                      return [
                              {
                                NAME: "actions",
                                VAL: [
                                  copyItem,
                                  match$2[0]
                                ]
                              },
                              {
                                NAME: "ignoredActions",
                                VAL: [
                                  copyItem,
                                  match$2[1]
                                ]
                              }
                            ];
                    }
                    var destinationId = copyItem.VAL;
                    var toDestination = Curry._2(TrackingPlanMappedModel.Destinations.get, toMappedDestinations, destinationId);
                    if (toDestination === undefined) {
                      return [{
                                NAME: "itemNotFound",
                                VAL: copyItem
                              }];
                    }
                    var actions$5 = ActionDiff.getNewAndUpdatedDestinationActions(fromModel.destinations, fromModel.archive.destinations, toDestination);
                    if (isIgnoredDestination(ignoredChangesSet, destinationId)) {
                      return [{
                                NAME: "ignoredActions",
                                VAL: [
                                  copyItem,
                                  actions$5
                                ]
                              }];
                    } else {
                      return [{
                                NAME: "actions",
                                VAL: [
                                  copyItem,
                                  actions$5
                                ]
                              }];
                    }
                  })));
}

function getAnalyticsItem(mappedEvents, mappedProperties, mappedPropertyBundles, copyItems) {
  var events = Belt_Array.keepU(copyItems, (function (item) {
          if (typeof item === "object") {
            return item.NAME === "event";
          } else {
            return false;
          }
        }));
  var propertyBundles = Belt_Array.keepU(copyItems, (function (item) {
          if (typeof item === "object") {
            return item.NAME === "propertyBundle";
          } else {
            return false;
          }
        }));
  var properties = Belt_Array.keepU(copyItems, (function (item) {
          if (typeof item === "object") {
            return item.NAME === "property";
          } else {
            return false;
          }
        }));
  if (events.length !== 0) {
    var eventId = CopyChangesToBranchModal__Types.CopyItem.getId(Belt_Array.getExn(events, 0));
    return [
            eventId,
            Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Events.get, mappedEvents, eventId), "", (function (param) {
                    return param.name;
                  })),
            "Event"
          ];
  }
  if (propertyBundles.length !== 0) {
    var propertyBundleId = CopyChangesToBranchModal__Types.CopyItem.getId(Belt_Array.getExn(propertyBundles, 0));
    return [
            propertyBundleId,
            Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.PropertyBundles.get, mappedPropertyBundles, propertyBundleId), "", (function (param) {
                    return param.name;
                  })),
            "PropertyGroup"
          ];
  }
  if (properties.length === 0) {
    return [
            "",
            "",
            "Event"
          ];
  }
  var propertyId = CopyChangesToBranchModal__Types.CopyItem.getId(Belt_Array.getExn(properties, 0));
  return [
          propertyId,
          Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Properties.get, mappedProperties, propertyId), "", (function (param) {
                  return param.name;
                })),
          "Property"
        ];
}

function resolveCopyItems(copyItems, toModel) {
  return Belt_Array.keepMap(copyItems, (function (copyItem) {
                var variant = copyItem.NAME;
                if (variant === "sourceDestination") {
                  return Belt_Option.map(ModelUtils.getDestinationById(copyItem.VAL, toModel), (function (destination) {
                                return {
                                        NAME: "sourceDestination",
                                        VAL: destination
                                      };
                              }));
                } else if (variant === "eventProperty") {
                  return Belt_Option.map(ModelUtils.getModelPropertyById(copyItem.VAL, toModel), (function (property) {
                                return {
                                        NAME: "eventProperty",
                                        VAL: property
                                      };
                              }));
                } else if (variant === "eventPropertyBundle") {
                  return Belt_Option.map(ModelUtils.getPropertyBundleById(copyItem.VAL, toModel), (function (propertyBundle) {
                                return {
                                        NAME: "eventPropertyBundle",
                                        VAL: propertyBundle
                                      };
                              }));
                } else if (variant === "event") {
                  return Belt_Option.map(ModelUtils.getEventById(copyItem.VAL, toModel), (function ($$event) {
                                return {
                                        NAME: "event",
                                        VAL: $$event
                                      };
                              }));
                } else if (variant === "eventSource") {
                  return Belt_Option.map(ModelUtils.getSourceById(copyItem.VAL, toModel), (function (source) {
                                return {
                                        NAME: "eventSource",
                                        VAL: source
                                      };
                              }));
                } else if (variant === "propertyBundle") {
                  return Belt_Option.map(ModelUtils.getPropertyBundleById(copyItem.VAL, toModel), (function (propertyBundle) {
                                return {
                                        NAME: "propertyBundle",
                                        VAL: propertyBundle
                                      };
                              }));
                } else if (variant === "destination") {
                  return Belt_Option.map(ModelUtils.getDestinationById(copyItem.VAL, toModel), (function (destination) {
                                return {
                                        NAME: "destination",
                                        VAL: destination
                                      };
                              }));
                } else if (variant === "property") {
                  return Belt_Option.map(ModelUtils.getModelPropertyById(copyItem.VAL, toModel), (function (property) {
                                return {
                                        NAME: "property",
                                        VAL: property
                                      };
                              }));
                } else if (variant === "category") {
                  return Belt_Option.map(ModelUtils.getGoalById(copyItem.VAL, toModel), (function (category) {
                                return {
                                        NAME: "category",
                                        VAL: category
                                      };
                              }));
                } else if (variant === "source") {
                  return Belt_Option.map(ModelUtils.getSourceById(copyItem.VAL, toModel), (function (source) {
                                return {
                                        NAME: "source",
                                        VAL: source
                                      };
                              }));
                } else {
                  return Belt_Option.map(ModelUtils.getDestinationById(copyItem.VAL, toModel), (function (destination) {
                                return {
                                        NAME: "eventDestination",
                                        VAL: destination
                                      };
                              }));
                }
              }));
}

function getItemId(actions) {
  if (!actions) {
    return Pervasives.failwith("Unknown diff type");
  }
  var match = actions.hd[0];
  if (typeof match !== "object") {
    return Pervasives.failwith("Unknown diff type");
  }
  var variant = match.NAME;
  if (variant === "Metric" || variant === "Goal" || variant === "PropertyGroup" || variant === "Event" || variant === "Property" || variant === "GroupType" || variant === "Integration" || variant === "Destination" || variant === "Source") {
    return match.VAL;
  } else {
    return Pervasives.failwith("Unknown diff type");
  }
}

var Actions = {
  getItemId: getItemId
};

function existingItem(actions, item) {
  return !Belt_List.someU(actions, (function (action) {
                if (typeof item !== "object") {
                  return false;
                }
                var variant = item.NAME;
                if (variant === "destination" || variant === "sourceDestination" || variant === "eventDestination") {
                  var match = action[1];
                  if (typeof match === "object" && match.NAME === "CreateDestination") {
                    return item.VAL === match.VAL;
                  } else {
                    return false;
                  }
                }
                if (variant === "property" || variant === "eventProperty") {
                  var match$1 = action[1];
                  if (typeof match$1 === "object" && match$1.NAME === "CreateProperty") {
                    return item.VAL === match$1.VAL[0];
                  } else {
                    return false;
                  }
                }
                if (variant === "propertyBundle" || variant === "eventPropertyBundle") {
                  var match$2 = action[1];
                  if (typeof match$2 === "object" && match$2.NAME === "CreatePropertyGroup") {
                    return item.VAL === match$2.VAL[0];
                  } else {
                    return false;
                  }
                }
                if (variant === "source" || variant === "eventSource") {
                  var match$3 = action[1];
                  if (typeof match$3 === "object" && match$3.NAME === "CreateSource") {
                    return item.VAL === match$3.VAL;
                  } else {
                    return false;
                  }
                }
                if (variant !== "category") {
                  return false;
                }
                var match$4 = action[1];
                if (typeof match$4 === "object" && match$4.NAME === "AddGoal") {
                  return item.VAL === match$4.VAL;
                } else {
                  return false;
                }
              }));
}

function itemAdded(actions, item) {
  return Belt_List.someU(actions, (function (actions) {
                return Belt_List.someU(actions, (function (action) {
                              if (typeof item !== "object") {
                                return false;
                              }
                              var variant = item.NAME;
                              if (variant === "destination" || variant === "sourceDestination" || variant === "eventDestination") {
                                var destinationId = item.VAL;
                                var match = action[1];
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var variant$1 = match.NAME;
                                if (variant$1 === "LegacyIncludeDestinationInSource") {
                                  return Belt_List.has(match.VAL[2], destinationId, (function (prim0, prim1) {
                                                return prim0 === prim1;
                                              }));
                                } else if (variant$1 === "IncludeDestinationInSourceV2") {
                                  return match.VAL[1] === destinationId;
                                } else if (variant$1 === "IncludeDestinationInEventSource") {
                                  return match.VAL[2] === destinationId;
                                } else {
                                  return false;
                                }
                              }
                              if (variant === "property" || variant === "eventProperty") {
                                var match$1 = action[1];
                                if (typeof match$1 === "object" && match$1.NAME === "AddPropertyRef") {
                                  return match$1.VAL[1] === item.VAL;
                                } else {
                                  return false;
                                }
                              }
                              if (variant === "propertyBundle" || variant === "eventPropertyBundle") {
                                var match$2 = action[1];
                                if (typeof match$2 === "object" && match$2.NAME === "AddPropertyGroupToEvent") {
                                  return match$2.VAL[1] === item.VAL;
                                } else {
                                  return false;
                                }
                              }
                              if (variant === "source" || variant === "eventSource") {
                                var match$3 = action[1];
                                if (typeof match$3 !== "object") {
                                  return false;
                                }
                                var variant$2 = match$3.NAME;
                                if (variant$2 === "IncludeEventInSource" || variant$2 === "IncludeEventInSourceV2") {
                                  return match$3.VAL[1] === item.VAL;
                                } else {
                                  return false;
                                }
                              }
                              if (variant !== "category") {
                                return false;
                              }
                              var match$4 = action[1];
                              if (typeof match$4 === "object" && match$4.NAME === "AddEventToGoal") {
                                return match$4.VAL[0] === item.VAL;
                              } else {
                                return false;
                              }
                            }));
              }));
}

var Item = {
  existingItem: existingItem,
  itemAdded: itemAdded
};

var getItemIgnoreState = Belt_Map.get;

function getItemIgnoreStateValue(ignoredItems, item) {
  var match = Belt_Map.get(ignoredItems, item);
  if (match !== undefined) {
    if (match === "ExcludeChanges") {
      return "ExcludeChanges";
    } else {
      return "ExcludeItem";
    }
  } else {
    return "IncludeChanges";
  }
}

function itemIsDisabled(ignoredItems, item) {
  return Belt_Option.isSome(Belt_Map.get(ignoredItems, item));
}

var IgnoreState = {
  getItemIgnoreState: getItemIgnoreState,
  getItemIgnoreStateValue: getItemIgnoreStateValue,
  itemIsDisabled: itemIsDisabled
};

function includeEventActionsForSources(eventActions, sourceActions) {
  var sourcesWithActions = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(sourceActions, (function (sourceItemActions) {
                  if (!sourceItemActions) {
                    return ;
                  }
                  var match = sourceItemActions.hd[0];
                  if (typeof match === "object" && match.NAME === "Source") {
                    return match.VAL;
                  }
                  
                }))));
  var eventActions$1 = Belt_List.flatten(Belt_List.map(eventActions, (function (itemActions) {
              return Belt_List.map(itemActions, (function (prim) {
                            return prim[1];
                          }));
            })));
  var eventSourceActionsMap = Belt_MutableMapString.fromArray([]);
  Belt_List.forEach(eventActions$1, (function (action) {
          if (typeof action !== "object") {
            return ;
          }
          var variant = action.NAME;
          if (!(variant === "ExcludeEventFromSource" || variant === "IncludeEventInSource" || variant === "IncludeEventInSourceV2" || variant === "ToggleIncludeEventInCodegenForSource" || variant === "ExcludeEventFromSourceV2")) {
            return ;
          }
          var sourceId = action.VAL[1];
          if (!Belt_SetString.has(sourcesWithActions, sourceId)) {
            return Belt_MutableMapString.update(eventSourceActionsMap, sourceId, (function (maybeActions) {
                          if (maybeActions !== undefined) {
                            return Belt_List.concat(maybeActions, {
                                        hd: [
                                          {
                                            NAME: "Source",
                                            VAL: sourceId
                                          },
                                          action
                                        ],
                                        tl: /* [] */0
                                      });
                          } else {
                            return {
                                    hd: [
                                      {
                                        NAME: "Source",
                                        VAL: sourceId
                                      },
                                      action
                                    ],
                                    tl: /* [] */0
                                  };
                          }
                        }));
          }
          
        }));
  return Belt_List.concat(sourceActions, Belt_List.fromArray(Belt_MutableMapString.valuesToArray(eventSourceActionsMap)));
}

export {
  CopyItems ,
  resolveCopyItemActions ,
  allowedValueIrrelevantDueToPinnedValueOnEvent ,
  allowedValueIrrelevantDueToPinnedValueForAllEvents ,
  getFilteredEventsPropertyActionsToCopy ,
  getPinnedValuesMap ,
  keepPropertyRelatedRule ,
  getPropertyCopyActions ,
  getEventPropertyCopyActions ,
  getFilteredEventCategoryActionsToCopy ,
  getEventCategoryCopyActions ,
  isIgnoredSource ,
  isIgnoredDestination ,
  filterDestinationActionsToCopyForSource ,
  filterCategoryActionsToCopy ,
  filterEventActionsToCopy ,
  keepEventRelatedRule ,
  getEventCopyActions ,
  getPropertyBundleCopyActions ,
  getItemsActions ,
  getAnalyticsItem ,
  resolveCopyItems ,
  Actions ,
  Item ,
  IgnoreState ,
  includeEventActionsForSources ,
}
/* ActionDiff Not a pure module */
