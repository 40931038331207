// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../../../shared/models/Env.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as InspectorViewApi from "./InspectorViewApi.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as AuthenticationMethods from "../AuthenticationMethods.mjs";

var context = React.createContext([
      "Initial",
      (function (_status) {
          
        })
    ]);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var ContextProvider = {
  makeProps: makeProps,
  make: make
};

function InspectorStats$Context(Props) {
  var children = Props.children;
  var match = React.useState(function (param) {
        return "Initial";
      });
  return React.createElement(make, makeProps([
                  match[0],
                  match[1]
                ], children, undefined));
}

var Context = {
  make: InspectorStats$Context
};

function refreshAuthToken(addToast) {
  return AuthenticationMethods.refreshIdToken().then(function (result) {
              if (result.TAG === "Ok") {
                return Promise.resolve();
              }
              Curry._1(addToast, {
                    message: "Session expired. Please log in again to continue.",
                    toastType: "Error"
                  });
              return Promise.resolve();
            });
}

function useStatsWithCache(param) {
  var workspace = WorkspaceContext.use();
  var match = React.useContext(context);
  var setStats = match[1];
  var stats = match[0];
  var addToast = Toast.useAddToast();
  var fetchStats = function (param) {
    InspectorViewApi.getStatsWithCache(workspace.id).then(function (response) {
          if (response.NAME === "Error") {
            Curry._1(setStats, (function (currentStats) {
                    if (typeof currentStats === "object") {
                      return currentStats;
                    }
                    switch (currentStats) {
                      case "Initial" :
                      case "Loading" :
                          return "Error";
                      case "Error" :
                          return currentStats;
                      
                    }
                  }));
            if (response.VAL === "InvalidToken") {
              return refreshAuthToken(addToast);
            } else {
              return Promise.resolve();
            }
          }
          var payload = response.VAL;
          Curry._1(setStats, (function (param) {
                  return {
                          TAG: "Success",
                          _0: payload
                        };
                }));
          return Promise.resolve();
        });
  };
  React.useEffect((function (param) {
          fetchStats();
        }), []);
  var interval;
  if (typeof stats !== "object") {
    switch (stats) {
      case "Initial" :
      case "Loading" :
          interval = 15000;
          break;
      case "Error" :
          interval = 60000;
          break;
      
    }
  } else {
    interval = Caml_obj.notequal(stats._0, []) ? 0 : 15000;
  }
  Hooks.useInterval(interval, fetchStats);
  return stats;
}

function useHasStats(param) {
  var stats = useStatsWithCache();
  if (typeof stats !== "object" || !Caml_obj.notequal(stats._0, [])) {
    return false;
  } else {
    return true;
  }
}

function useStatsWithPolling(initialStats, param) {
  var workspace = WorkspaceContext.use();
  var match = React.useState(function (param) {
        return Belt_Option.mapWithDefault(initialStats, "Loading", (function (stats) {
                      return {
                              TAG: "Success",
                              _0: stats
                            };
                    }));
      });
  var setStats = match[1];
  var addToast = Toast.useAddToast();
  var documentVisibility = Hooks.useDocumentVisibility();
  var fetchStats = React.useCallback((function (param) {
          InspectorViewApi.getFreshStats(workspace.id).then(function (response) {
                if (response.NAME === "Error") {
                  Curry._1(setStats, (function (currentStats) {
                          if (typeof currentStats === "object") {
                            return currentStats;
                          }
                          switch (currentStats) {
                            case "Initial" :
                            case "Loading" :
                                return "Error";
                            case "Error" :
                                return currentStats;
                            
                          }
                        }));
                  if (response.VAL === "InvalidToken") {
                    return refreshAuthToken(addToast);
                  } else {
                    return Promise.resolve();
                  }
                }
                var payload = response.VAL;
                Curry._1(setStats, (function (param) {
                        return {
                                TAG: "Success",
                                _0: payload
                              };
                      }));
                return Promise.resolve();
              });
        }), []);
  React.useEffect((function (param) {
          Curry._1(fetchStats, undefined);
        }), []);
  Hooks.useInterval(documentVisibility === "visible" ? 15000 : 0, fetchStats);
  return match[0];
}

function getSourceStatus(source, inspectorStats) {
  if (typeof inspectorStats !== "object") {
    switch (inspectorStats) {
      case "Initial" :
      case "Loading" :
          return "Loading";
      case "Error" :
          return "Waiting";
      
    }
  } else {
    var stats = Belt_Array.getByU(inspectorStats._0, (function (param) {
            return param.sourceId === source.id;
          }));
    if (stats === undefined) {
      return "Waiting";
    }
    var env = Belt_Array.keepMap(stats.envs, (function (env) {
            return Env.fromString(env.env);
          }));
    return {
            TAG: "Receiving",
            _0: env
          };
  }
}

export {
  context ,
  ContextProvider ,
  Context ,
  refreshAuthToken ,
  useStatsWithCache ,
  useHasStats ,
  useStatsWithPolling ,
  getSourceStatus ,
}
/* context Not a pure module */
