// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Asset from "../Asset.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as DocsLink from "../DocsLink.mjs";
import * as CopyButton from "../CopyButton.mjs";
import * as IconCheckmark from "../IconCheckmark.mjs";
import * as LoadingCircle from "../LoadingCircle.mjs";

function gtmTypeToString(gtmType) {
  switch (gtmType) {
    case "WebTemplate" :
        return "Web Template";
    case "WebRecipe" :
        return "Web Recipe";
    case "ServerTemplate" :
        return "Server Template";
    
  }
}

function gtmTypeFromString(gtmTypeString) {
  switch (gtmTypeString) {
    case "Server Template" :
        return "ServerTemplate";
    case "Web Recipe" :
        return "WebRecipe";
    case "Web Template" :
        return "WebTemplate";
    default:
      return Js_exn.raiseError("Unknown GTM type");
  }
}

var docsContentStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(0), Css.px(32), Css.px(0), Css.px(32)),
      tl: /* [] */0
    });

var docsDescriptionStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(16)),
      tl: {
        hd: Css.marginBottom(Css.px(8)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.color(Styles.Color.light12),
                tl: {
                  hd: Css.lineHeight(Css.pct(140.0)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var docsCodeBoxStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.fontWeight("normal"),
        tl: /* [] */0
      }
    });

var docsCodeStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.marginTop(Css.px(12)),
          tl: {
            hd: Css.display("block"),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light11),
              tl: {
                hd: Css.paddingTop(Css.px(16)),
                tl: {
                  hd: Css.paddingRight(Css.px(70)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(16)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(24)),
                      tl: {
                        hd: Css.fontFamily(Styles.FontFamily.polar),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.small),
                          tl: {
                            hd: Css.color(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Css.px(3)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var docsNoteStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.marginTop(Css.px(12)),
          tl: {
            hd: Css.display("block"),
            tl: {
              hd: Css.backgroundColor(Styles.Color.orange),
              tl: {
                hd: Css.paddingTop(Css.px(16)),
                tl: {
                  hd: Css.paddingRight(Css.px(24)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(16)),
                    tl: {
                      hd: Css.fontSize(Styles.FontSize.small),
                      tl: {
                        hd: Css.color(Styles.Color.light11),
                        tl: {
                          hd: Css.borderRadius(Css.px(3)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var listNumberStyle = Curry._1(Css.style, {
      hd: Css.fontWeight(Styles.FontWeight.semi),
      tl: {
        hd: Css.fontSize(Styles.FontSize.medium),
        tl: /* [] */0
      }
    });

var docsLinkStyles = Curry._1(Css.style, {
      hd: Css.display("inlineBlock"),
      tl: {
        hd: Css.color(Styles.Color.darkBlue),
        tl: {
          hd: Css.textDecoration("none"),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: 200.0
                }, undefined, undefined, "color"),
            tl: {
              hd: Css.hover({
                    hd: Css.color(Styles.Color.deepBlue),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var copyButtonBoxStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(10)),
        tl: {
          hd: Css.top(Css.px(10)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

function serverTemplateInstructions(hasSeenEvents) {
  return React.createElement("div", {
              className: docsContentStyles
            }, React.createElement("p", {
                  className: docsDescriptionStyles
                }, React.createElement(DocsLink.make, {
                      path: "inspector/connect-inspector-to-gtm#inspector-server-side-gtm-integration-beta",
                      label: "Please check the docs for the installation instructions."
                    })), React.createElement("p", {
                  className: docsDescriptionStyles
                }, React.createElement(Spacer.make, {
                      width: 4
                    }), hasSeenEvents ? React.createElement(React.Fragment, undefined, React.createElement(IconCheckmark.make, {
                            size: 12,
                            color: Styles.Color.green
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.green,
                            children: "Seen events"
                          })) : React.createElement(React.Fragment, undefined, React.createElement(LoadingCircle.make, {
                            size: 16,
                            color: Styles.Color.light08
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Waiting for your first events"
                          }))));
}

function webRecipeInstructions(apiKey, hasSeenEvents) {
  return React.createElement("ol", {
              className: docsContentStyles
            }, React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: "Download the "
                        }), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement("a", {
                          href: "https://raw.githubusercontent.com/avohq/gtm-web-recipe/main/GTM-58RX3LLN_workspace19.json"
                        }, "Avo Inspector Recipe file"), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: "."
                        }))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: null
                        }, "In your Google Tag Manager container pick ", React.createElement("b", undefined, "Admin"), " in the top menu, then press ", React.createElement("b", undefined, "Import Container"), " in the right column."))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: null
                        }, "Pick your workspace, ", React.createElement("b", undefined, "Merge"), " option and choose the file you downloaded on step 1. Press ", React.createElement("b", undefined, "Confirm"), " at the bottom. It will automatically close the import screen and your workspace will have the recipe added."))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: null
                        }, "Update the value of the ", React.createElement("b", undefined, "Avo Inspector API Key"), " variable in your container to the following key.")), React.createElement("div", {
                      className: docsCodeBoxStyles
                    }, React.createElement("pre", {
                          className: docsCodeStyles
                        }, apiKey), React.createElement("div", {
                          className: copyButtonBoxStyles
                        }, React.createElement(CopyButton.make, {
                              copyString: apiKey
                            })))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: null
                        }, React.createElement("i", undefined, "Recommended."), " Update the ", React.createElement("b", undefined, "Avo App Name"), " field with relevant application name."))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: null
                        }, React.createElement("i", undefined, "Optional."), " You can edit the ", React.createElement("b", undefined, "Avo Events To Exclude"), " and ", React.createElement("b", undefined, "Avo Properties To Exclude"), " variables to filter out unwanted data, but make sure that you keep the JSON Array format."))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: "That's it, your GTM container is ready to send the events metadata to the Avo Inspector."
                        }))), React.createElement("div", {
                  className: docsNoteStyles
                }, React.createElement("ul", undefined, React.createElement("li", undefined, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Regular",
                              children: null
                            }, "If you run your app in ", React.createElement("b", undefined, "Preview"), " mode the tag will send the events to the ", React.createElement("b", undefined, "Development"), " environment in Inspector.")), React.createElement("li", undefined, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Regular",
                              children: null
                            }, "After you ", React.createElement("b", undefined, "Submit"), " the changes the events will be sent to the ", React.createElement("b", undefined, "Production"), " environment in Inspector.")))), React.createElement("p", {
                  className: docsDescriptionStyles
                }, React.createElement(Spacer.make, {
                      width: 4
                    }), hasSeenEvents ? React.createElement(React.Fragment, undefined, React.createElement(IconCheckmark.make, {
                            size: 12,
                            color: Styles.Color.green
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.green,
                            children: "Seen events"
                          })) : React.createElement(React.Fragment, undefined, React.createElement(LoadingCircle.make, {
                            size: 16,
                            color: Styles.Color.light08
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Waiting for your first events"
                          }))), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement(DocsLink.make, {
                  path: "inspector/connect-inspector-to-gtm",
                  label: "Learn more in the docs"
                }));
}

function webTemplateInstructions(apiKey, hasSeenEvents) {
  return React.createElement("ol", {
              className: docsContentStyles
            }, React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: null
                        }, "In your Google Tag Manager container pick ", React.createElement("b", undefined, "Templates"), " in the side menu, then press ", React.createElement("b", undefined, "Search Gallery"), " in the top right and look for ", React.createElement("b", undefined, "Avo Inspector"), " in the gallery. Press ", React.createElement("b", undefined, "Add to workspace"), " and ", React.createElement("b", undefined, "Add.")))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: null
                        }, "Choose ", React.createElement("b", undefined, "Tags"), " in the main side menu, then ", React.createElement("b", undefined, "New"), " on the right. Click ", React.createElement("b", undefined, "Tag Configuration"), " section and pick the ", React.createElement("i", undefined, "Avo Inspector"), " template from the ", React.createElement("i", undefined, "Custom"), " section of the list."))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: null
                        }, "Paste you ", React.createElement("b", undefined, "API key"), " into the ", React.createElement("b", undefined, "Inspector API Key"), "  field.")), React.createElement("div", {
                      className: docsCodeBoxStyles
                    }, React.createElement("pre", {
                          className: docsCodeStyles
                        }, apiKey), React.createElement("div", {
                          className: copyButtonBoxStyles
                        }, React.createElement(CopyButton.make, {
                              copyString: apiKey
                            })))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: null
                        }, "Fill the ", React.createElement("b", undefined, "Application name"), " field."))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: null
                        }, React.createElement("i", undefined, "Optional."), " You can edit the ", React.createElement("b", undefined, "Event names to ignore"), " and ", React.createElement("b", undefined, "Property names to ignore"), " fields to filter out unwanted data, but make sure that you keep the JSON Array format."))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: null
                        }, "Click the ", React.createElement("b", undefined, "Triggering"), " section and add a trigger that fires on every event. If you don't have it, create as shown below.")), React.createElement("p", undefined, React.createElement("img", {
                          className: Curry._1(Css.style, {
                                hd: Css.width(Css.px(340)),
                                tl: /* [] */0
                              }),
                          src: Asset.getPath("GtmAllEventsTrigger.png")
                        }))), React.createElement("li", {
                  className: listNumberStyle
                }, React.createElement("p", {
                      className: docsDescriptionStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          children: null
                        }, "Press ", React.createElement("b", undefined, "Save"), " and you are done."))), React.createElement("div", {
                  className: docsNoteStyles
                }, React.createElement("ul", undefined, React.createElement("li", undefined, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Regular",
                              children: null
                            }, "If you run your app in ", React.createElement("b", undefined, "Preview"), " mode the tag will send the events to the ", React.createElement("b", undefined, "Development"), " environment in Inspector.")), React.createElement("li", undefined, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Regular",
                              children: null
                            }, "After you ", React.createElement("b", undefined, "Submit"), " the changes the events will be sent to the ", React.createElement("b", undefined, "Production"), " environment in Inspector.")))), React.createElement("p", {
                  className: docsDescriptionStyles
                }, React.createElement(Spacer.make, {
                      width: 4
                    }), hasSeenEvents ? React.createElement(React.Fragment, undefined, React.createElement(IconCheckmark.make, {
                            size: 12,
                            color: Styles.Color.green
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.green,
                            children: "Seen events"
                          })) : React.createElement(React.Fragment, undefined, React.createElement(LoadingCircle.make, {
                            size: 16,
                            color: Styles.Color.light08
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Waiting for your first events"
                          }))), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement(DocsLink.make, {
                  path: "inspector/connect-inspector-to-gtm",
                  label: "Learn more in the docs"
                }));
}

function InspectorViewInstallDocsGtmWebTemplate(Props) {
  var apiKey = Props.apiKey;
  var hasSeenEvents = Props.hasSeenEvents;
  var match = React.useState(function (param) {
        return "WebTemplate";
      });
  var setRecipeOrTemplate = match[1];
  var recipeOrTemplate = match[0];
  var tmp;
  switch (recipeOrTemplate) {
    case "WebTemplate" :
        tmp = webTemplateInstructions(apiKey, hasSeenEvents);
        break;
    case "WebRecipe" :
        tmp = webRecipeInstructions(apiKey, hasSeenEvents);
        break;
    case "ServerTemplate" :
        tmp = serverTemplateInstructions(hasSeenEvents);
        break;
    
  }
  return React.createElement("div", undefined, React.createElement("select", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("block"),
                        tl: Styles.select
                      }),
                  value: gtmTypeToString(recipeOrTemplate),
                  onChange: (function (formEvent) {
                      var recipeOrTemplate = formEvent.target.value;
                      Curry._1(setRecipeOrTemplate, (function (param) {
                              return gtmTypeFromString(recipeOrTemplate);
                            }));
                    })
                }, React.createElement("option", undefined, "Web Template"), React.createElement("option", undefined, "Web Recipe"), React.createElement("option", undefined, "Server Template")), tmp);
}

var make = InspectorViewInstallDocsGtmWebTemplate;

export {
  gtmTypeToString ,
  gtmTypeFromString ,
  docsContentStyles ,
  docsDescriptionStyles ,
  docsCodeBoxStyles ,
  docsCodeStyles ,
  docsNoteStyles ,
  listNumberStyle ,
  docsLinkStyles ,
  copyButtonBoxStyles ,
  serverTemplateInstructions ,
  webRecipeInstructions ,
  webTemplateInstructions ,
  make ,
}
/* docsContentStyles Not a pure module */
