// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Tooltip from "./Tooltip.mjs";
import * as Markdown from "./Markdown.mjs";

function CopyChangesToBranchModal__Tooltip(Props) {
  var copyMode = Props.copyMode;
  return React.createElement(Tooltip.make, {
              title: (
                copyMode === "Push" ? "Push" : "Pull"
              ) + "ing changes to branch",
              children: React.createElement(Tooltip.Content.make, {
                    children: React.createElement(Markdown.make, {
                          source: "When an item is " + (
                            copyMode === "Push" ? "push" : "pull"
                          ) + "ed to a branch, a snapshot of the item is added in the selected shape. The id of the item remains the same on both the source and destination branches, but any changes made to each version are independent.\n\nWhen an item is merged on one branch, the following can happen:\n- If no changes were made to the item on either branch, it merges on one and disappears when pulling main to the other.\n- If changes were made on one or more branches, the base item merges, but any unmerged changes remain on other branches after pulling main.\n\nSee [documentation](https://www.avo.app/docs/data-design/branches/pulling-pushing-items-between-branches) for more details."
                        })
                  })
            });
}

var make = CopyChangesToBranchModal__Tooltip;

export {
  make ,
}
/* react Not a pure module */
