// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Mantine from "./Mantine.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as GlobalPill from "./GlobalPill.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as BulkContext from "./BulkContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as KeyListener from "./KeyListener.mjs";
import * as FocusContext from "./FocusContext.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as DiscrepancyTypes from "./DiscrepancyTypes.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as EventNameComponent from "../../shared/models/EventNameComponent.mjs";
import * as EventsSheet__Utils from "./EventsSheet__Utils.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as BranchDiscrepancies from "./BranchDiscrepancies.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as EventsSheet__EditableNameCell from "./EventsSheet__EditableNameCell.mjs";
import * as EventsSheet__EditableNameCellContext from "./EventsSheet__EditableNameCellContext.mjs";
import * as EventsSheet__EventNameComponentsContext from "./EventsSheet__EventNameComponentsContext.mjs";

function indicatorStyles(modificationState) {
  return Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.top(Css.px(0)),
                tl: {
                  hd: Css.left(Css.px(0)),
                  tl: {
                    hd: Css.height(Css.pct(100)),
                    tl: {
                      hd: Css.paddingTop(Css.px(2)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(2)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(3)),
                          tl: {
                            hd: Css.paddingRight(Css.px(4)),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.zIndex(5),
                                  tl: {
                                    hd: Css.after({
                                          hd: Css.display("block"),
                                          tl: {
                                            hd: Css.unsafe("content", "' '"),
                                            tl: {
                                              hd: Css.backgroundColor(modificationState === "New" ? Styles.Color.green : Styles.Color.primaryPurple),
                                              tl: {
                                                hd: Css.width(Css.px(2)),
                                                tl: {
                                                  hd: Css.height(Css.pct(100)),
                                                  tl: {
                                                    hd: Css.borderRadius(Css.px(2)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function EventsSheet__NameCell$ModifiedIndicator(Props) {
  var modificationState = Props.modificationState;
  return React.createElement(SimpleTooltip.WithComponent.make, {
              arrowSize: 8,
              position: "right",
              tooltip: (function (param) {
                  var tmp;
                  switch (modificationState) {
                    case "Unmodified" :
                        tmp = "";
                        break;
                    case "Modified" :
                        tmp = "Modified";
                        break;
                    case "New" :
                        tmp = "New";
                        break;
                    
                  }
                  return React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.white,
                              children: tmp
                            });
                }),
              children: React.createElement("div", {
                    className: indicatorStyles(modificationState)
                  })
            });
}

function EventsSheet__NameCell$ExistingEventNameValidation(Props) {
  var eventId = Props.eventId;
  var eventName = Props.eventName;
  var handleArchive = Props.handleArchive;
  var setIsEditing = Props.setIsEditing;
  var setActiveEditableEventId = Props.setActiveEditableEventId;
  var events = ModelStore.Mapped.useEvents();
  var otherEvent = Curry._2(TrackingPlanMappedModel.Events.getBy, events, (function ($$event) {
          if ($$event.id !== eventId) {
            return Case.camel($$event.name) === Case.camel(eventName);
          } else {
            return false;
          }
        }));
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: /* [] */0
                    }
                  })
            }, otherEvent !== undefined ? React.createElement($$Text.make, {
                    element: "Span",
                    size: "Small",
                    color: Styles.Color.light11,
                    children: null
                  }, "Another event called ", React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            Router.Schema.pushDrawerItem(undefined, undefined, {
                                  NAME: "event",
                                  VAL: [
                                    otherEvent.id,
                                    undefined,
                                    undefined,
                                    false
                                  ]
                                });
                          }),
                        style: "Blue",
                        children: React.createElement($$Text.make, {
                              element: "Span",
                              children: otherEvent.name
                            })
                      }), " already exists in your tracking plan") : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.gap(Css.px(16)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(TextButton.make, {
                      onClick: (function (param) {
                          Curry._1(handleArchive, undefined);
                        }),
                      style: "Red",
                      children: React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            singleLine: true,
                            children: "Remove event"
                          })
                    }), React.createElement(TextButton.make, {
                      onClick: (function (param) {
                          Curry._1(setIsEditing, (function (param) {
                                  return true;
                                }));
                          Curry._1(setActiveEditableEventId, (function (param) {
                                  return eventId;
                                }));
                        }),
                      style: "Blue",
                      children: React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            singleLine: true,
                            children: "Edit name"
                          })
                    })));
}

function EventsSheet__NameCell$NameCellValidation(Props) {
  var eventId = Props.eventId;
  var eventName = Props.eventName;
  var handleArchive = Props.handleArchive;
  var isEditing = Props.isEditing;
  var sendActions = Props.sendActions;
  var setIsEditing = Props.setIsEditing;
  var addToast = Toast.useAddToast();
  var setActiveEditableEventId = Curry._1(EventsSheet__EditableNameCellContext.Provider.SetState.use, undefined);
  var match = BranchDiscrepancies.use();
  var branchAuditIssuesOnEvent = Js_dict.get(match.issuesById, eventId);
  var branchAuditValidations = branchAuditIssuesOnEvent !== undefined ? Belt_Array.keepMapU(branchAuditIssuesOnEvent, (function (issue) {
            var match = issue.issue;
            var match$1 = DiscrepancyTypes.getSeverity(issue.issue);
            var variant = match.NAME;
            if (variant === "SameEventName") {
              if (match$1 !== undefined) {
                return [
                        issue,
                        match$1
                      ];
              } else {
                return ;
              }
            } else if (variant === "PropertyConflictOnEvent" || variant === "EventsWithoutDescription") {
              return ;
            } else if (variant === "EventsWithoutExpectedValues") {
              if (match$1 !== undefined) {
                return [
                        issue,
                        match$1
                      ];
              } else {
                return ;
              }
            } else if (variant === "EventsWithUnexpectedCase" && match$1 !== undefined) {
              return [
                      issue,
                      match$1
                    ];
            } else {
              return ;
            }
          })) : [];
  var match$1 = Belt_Array.reduceU(branchAuditValidations, undefined, (function (highestSeverity, param) {
          var severity = param[1];
          if (highestSeverity !== undefined) {
            if (highestSeverity === "warning" && severity === "enforce") {
              return severity;
            } else {
              return highestSeverity;
            }
          } else if (severity === "warning" || severity === "enforce") {
            return severity;
          } else {
            return highestSeverity;
          }
        }));
  var branchAuditValidationIcon = match$1 !== undefined ? (
      match$1 === "warning" ? [
          "warningFilled",
          Styles.Color.orange,
          Styles.Color.darkOrange
        ] : [
          "enforce",
          Styles.Color.darkRed,
          Styles.Color.darkRed
        ]
    ) : undefined;
  if (isEditing || branchAuditValidationIcon === undefined) {
    return null;
  } else {
    return React.createElement(React.Fragment, undefined, React.createElement(SimpleTooltip.WithComponent.make, {
                    color: "light",
                    delayIn: 100,
                    position: "right",
                    tooltip: (function (param) {
                        return React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.flexDirection("column"),
                                            tl: {
                                              hd: Css.gap(Css.px(16)),
                                              tl: {
                                                hd: Css.padding(Css.px(8)),
                                                tl: {
                                                  hd: Css.paddingLeft(Css.px(4)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }),
                                    onClick: (function (prim) {
                                        prim.stopPropagation();
                                      })
                                  }, Belt_Array.map(branchAuditValidations, (function (param) {
                                          var severity = param[1];
                                          var issue = param[0];
                                          var match = issue.issue;
                                          var match$1 = issue.suggestion;
                                          var match$2 = issue.suggestionCallback;
                                          var tmp;
                                          var exit = 0;
                                          if (match$1 !== undefined && match$2 !== undefined) {
                                            tmp = React.createElement(React.Fragment, undefined, React.createElement(TextButton.make, {
                                                      onClick: (function (param) {
                                                          Curry._2(match$2, sendActions, addToast);
                                                        }),
                                                      style: "Blue",
                                                      children: React.createElement($$Text.make, {
                                                            size: "Small",
                                                            weight: "Semi",
                                                            children: StringExt.widowless(match$1)
                                                          })
                                                    }));
                                          } else {
                                            exit = 1;
                                          }
                                          if (exit === 1) {
                                            var variant = match.NAME;
                                            tmp = variant === "SameEventName" ? React.createElement(EventsSheet__NameCell$ExistingEventNameValidation, {
                                                    eventId: eventId,
                                                    eventName: eventName,
                                                    handleArchive: handleArchive,
                                                    setIsEditing: setIsEditing,
                                                    setActiveEditableEventId: setActiveEditableEventId
                                                  }) : (
                                                variant === "PropertyConflictOnEvent" || variant === "EventsWithUnexpectedCase" || variant === "EventsWithoutDescription" || variant !== "EventsWithoutExpectedValues" ? null : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                                            size: "Small",
                                                            color: Styles.Color.light11,
                                                            children: StringExt.widowless("Event name is incomplete as it is missing or has invalid fields")
                                                          }), React.createElement(TextButton.make, {
                                                            onClick: (function (param) {
                                                                Curry._1(setIsEditing, (function (param) {
                                                                        return true;
                                                                      }));
                                                                Curry._1(setActiveEditableEventId, (function (param) {
                                                                        return eventId;
                                                                      }));
                                                              }),
                                                            style: "Blue",
                                                            children: React.createElement($$Text.make, {
                                                                  size: "Small",
                                                                  weight: "Semi",
                                                                  singleLine: true,
                                                                  children: "Edit name"
                                                                })
                                                          }))
                                              );
                                          }
                                          return React.createElement("div", {
                                                      key: DiscrepancyTypes.discrepancyTypeToString(issue.issue),
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.display("flex"),
                                                            tl: {
                                                              hd: Css.alignItems("flexStart"),
                                                              tl: {
                                                                hd: Css.gap(Css.px(8)),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          })
                                                    }, React.createElement(Icon.make, {
                                                          type_: severity === "enforce" ? "enforce" : "warningFilled",
                                                          size: "small",
                                                          color: severity === "enforce" ? Styles.Color.darkRed : Styles.Color.orange
                                                        }), React.createElement("div", {
                                                          className: Curry._1(Css.style, {
                                                                hd: Css.display("flex"),
                                                                tl: {
                                                                  hd: Css.flexDirection("column"),
                                                                  tl: {
                                                                    hd: Css.gap(Css.px(6)),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              })
                                                        }, React.createElement($$Text.make, {
                                                              size: "Small",
                                                              weight: "Semi",
                                                              color: Styles.Color.light11,
                                                              children: DiscrepancyTypes.discrepancyToDescription(issue.issue)
                                                            }), tmp));
                                        })));
                      }),
                    children: React.createElement("button", {
                          className: Curry._1(Css.merge, {
                                hd: Curry._1(Css.style, Styles.buttonReset),
                                tl: {
                                  hd: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.borderRadius(Css.px(3)),
                                          tl: {
                                            hd: Css.padding(Css.px(0)),
                                            tl: {
                                              hd: Icon.styles({
                                                    hd: Css.transition({
                                                          NAME: "ms",
                                                          VAL: Styles.Duration.$$short
                                                        }, undefined, undefined, "all"),
                                                    tl: /* [] */0
                                                  }),
                                              tl: {
                                                hd: Css.hover({
                                                      hd: Icon.color(branchAuditValidationIcon[2]),
                                                      tl: /* [] */0
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement(Icon.make, {
                              type_: branchAuditValidationIcon[0],
                              size: {
                                NAME: "int",
                                VAL: 16
                              },
                              color: branchAuditValidationIcon[1]
                            }))
                  }));
  }
}

function EventsSheet__NameCell(Props) {
  var currentFilters = Props.currentFilters;
  var columns = Props.columns;
  var eventId = Props.eventId;
  var eventName = Props.eventName;
  var isGlobal = Props.isGlobal;
  var groupedByCategory = Props.groupedByCategory;
  var modificationState = Props.modificationState;
  var selected = Props.selected;
  var innerBorderColor = Props.innerBorderColor;
  var outerBorderColor = Props.outerBorderColor;
  var backgroundColor = Props.backgroundColor;
  var schemaBundle = Props.schemaBundle;
  var viewerCanEdit = Props.viewerCanEdit;
  var styles = Props.styles;
  var handleArchive = Props.handleArchive;
  var sendActions = Props.sendActions;
  var match = BulkContext.use();
  var toggleEventIds = match.toggleEventIds;
  var selectedEventIds = match.selectedEventIds;
  var handleSelectRow = function ($$event) {
    $$event.stopPropagation();
    Curry._1(toggleEventIds, [eventId]);
    var selected = Belt_SetString.has(selectedEventIds, eventId);
    var n = Belt_SetString.size(selectedEventIds) + (
      selected ? -1 : 1
    ) | 0;
    var arg = selected ? "DeselectRow" : "SelectRow";
    var arg$1 = schemaBundle.schemaId;
    var arg$2 = schemaBundle.branchId;
    AnalyticsUtils.withSchemaBundle((function (param) {
            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
              return AnalyticsRe.bulkEditingInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14);
            };
            return function (param) {
              var func$1 = Curry._1(func, param);
              return function (param) {
                var func$2 = Curry._1(func$1, param);
                return function (param) {
                  var func$3 = Curry._1(func$2, param);
                  return function (param) {
                    var func$4 = Curry._1(func$3, param);
                    return function (param) {
                      var func$5 = Curry._1(func$4, param);
                      return function (param) {
                        var func$6 = Curry._1(func$5, param);
                        return function (param) {
                          var func$7 = Curry._1(func$6, param);
                          return function (param) {
                            var func$8 = Curry._1(func$7, param);
                            return function (param) {
                              var func$9 = Curry._1(func$8, param);
                              return function (param) {
                                return Curry._5(func$9, param, arg, n, arg$1, arg$2);
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                };
              };
            };
          }), schemaBundle);
  };
  var match$1 = React.useState(function (param) {
        return false;
      });
  var setIsEditing = match$1[1];
  var isEditing = match$1[0];
  var isEditingDebounced = Hooks.useDebounced((function (param) {
          return isEditing;
        }), 0);
  var match$2 = React.useContext(DiscrepancyContext.context);
  var nameComponents = ValidationConfigV2.EventsV2.getNameComponents(match$2.config.events);
  var currentComponents = EventsSheet__EventNameComponentsContext.useEventName(eventName);
  var structure = React.useMemo((function (param) {
          if (currentComponents !== undefined) {
            return currentComponents;
          } else {
            return EventNameComponent.extractComponentsFromName(eventName, nameComponents);
          }
        }), [
        eventName,
        currentComponents,
        nameComponents
      ]);
  var columnWidth = EventsSheet__Utils.getColumnWidthByType(columns, "EventName");
  var activeEditableEventId = Curry._1(EventsSheet__EditableNameCellContext.Provider.State.use, undefined);
  var setActiveEditableEventId = Curry._1(EventsSheet__EditableNameCellContext.Provider.SetState.use, undefined);
  var canInlineEditName = viewerCanEdit && schemaBundle.branchId !== "master";
  var nameCellRef = React.useRef(null);
  var hasFocus = FocusContext.useHasFocus(nameCellRef.current);
  KeyListener.use(!hasFocus, undefined, undefined, (function (e) {
          e.stopPropagation();
          e.preventDefault();
        }), (function (e) {
          e.stopPropagation();
          e.preventDefault();
          if (canInlineEditName) {
            Curry._1(setActiveEditableEventId, (function (param) {
                    return eventId;
                  }));
            return Curry._1(setIsEditing, (function (param) {
                          return true;
                        }));
          }
          
        }), "Enter");
  React.useEffect((function (param) {
          if (Caml_obj.notequal(activeEditableEventId, eventId) && isEditing) {
            Curry._1(setIsEditing, (function (param) {
                    return false;
                  }));
          }
          
        }), [
        activeEditableEventId,
        isEditing
      ]);
  return React.createElement("div", {
              className: Curry._6(styles, columnWidth, groupedByCategory, selected, innerBorderColor, outerBorderColor, backgroundColor)
            }, modificationState !== "Unmodified" ? React.createElement(EventsSheet__NameCell$ModifiedIndicator, {
                    modificationState: modificationState
                  }) : null, viewerCanEdit ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.label("bulk-checkbox"),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.paddingTop(Css.px(12)),
                              tl: {
                                hd: Css.paddingRight(Css.px(10)),
                                tl: {
                                  hd: Css.paddingBottom(Css.px(12)),
                                  tl: {
                                    hd: Css.paddingLeft(Css.px(6)),
                                    tl: {
                                      hd: Belt_SetString.has(selectedEventIds, eventId) ? Css.opacity(1.0) : Css.opacity(0.0),
                                      tl: {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: Styles.Duration.$$short
                                            }, undefined, undefined, "opacity"),
                                        tl: {
                                          hd: Css.focusWithin({
                                                hd: Css.opacity(1.0),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }),
                    onClick: handleSelectRow
                  }, React.createElement(Mantine.Checkbox.make, {
                        checked: Belt_SetString.has(selectedEventIds, eventId),
                        onChange: (function (_event) {
                            
                          }),
                        size: "xs"
                      })) : React.createElement(Spacer.make, {
                    width: 24
                  }), isEditingDebounced ? React.createElement(EventsSheet__EditableNameCell.make, {
                    columnWidth: columnWidth,
                    currentFilters: currentFilters,
                    eventId: eventId,
                    eventName: eventName,
                    nameComponents: nameComponents,
                    onExitEditing: (function (param) {
                        Curry._1(setIsEditing, (function (param) {
                                return false;
                              }));
                        Curry._1(setActiveEditableEventId, (function (param) {
                                
                              }));
                        setTimeout((function (param) {
                                Belt_Option.forEach(Caml_option.nullable_to_opt(nameCellRef.current), (function (el) {
                                        el.focus();
                                      }));
                              }), 0);
                      }),
                    schemaBundle: schemaBundle,
                    structure: structure,
                    key: eventName
                  }) : React.createElement("div", {
                    ref: nameCellRef,
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.minWidth(Css.px(0)),
                              tl: {
                                hd: Css.flexShrink(1.0),
                                tl: {
                                  hd: canInlineEditName ? Css.cursor("text") : Styles.emptyStyle,
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }),
                    tabIndex: 0,
                    onClick: (function (e) {
                        if (canInlineEditName) {
                          e.stopPropagation();
                          e.preventDefault();
                          Curry._1(setIsEditing, (function (param) {
                                  return true;
                                }));
                          return Curry._1(setActiveEditableEventId, (function (param) {
                                        return eventId;
                                      }));
                        }
                        
                      })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.gap(Css.px(4)),
                                  tl: {
                                    hd: Css.wordBreak("breakAll"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Medium",
                            lines: 1,
                            color: Styles.Color.light12,
                            maxWidth: Css.pct(100),
                            title: eventName,
                            children: eventName
                          }), isGlobal ? React.createElement(GlobalPill.make, {}) : null)), React.createElement(Spacer.make, {
                  width: 4,
                  grow: 1.0
                }), React.createElement(EventsSheet__NameCell$NameCellValidation, {
                  eventId: eventId,
                  eventName: eventName,
                  handleArchive: handleArchive,
                  isEditing: isEditing,
                  sendActions: sendActions,
                  setIsEditing: setIsEditing
                }), isEditing ? null : React.createElement("button", {
                    className: Curry._1(Css.merge, {
                          hd: Curry._1(Css.style, Styles.buttonReset),
                          tl: {
                            hd: Curry._1(Css.style, {
                                  hd: Css.label("event-details-chevron"),
                                  tl: {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Icon.color(Styles.Color.light10),
                                      tl: {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: Styles.Duration.$$short
                                            }, undefined, undefined, "all"),
                                        tl: {
                                          hd: Css.opacity(0.0),
                                          tl: {
                                            hd: Css.padding(Css.px(1)),
                                            tl: {
                                              hd: Css.marginLeft(Css.px(8)),
                                              tl: {
                                                hd: Css.marginRight(Css.px(-10)),
                                                tl: {
                                                  hd: Css.borderRadius(Css.px(3)),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Icon.color(Styles.Color.light12),
                                                          tl: {
                                                            hd: Css.backgroundColor(Styles.Color.light04),
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    tl: {
                                                      hd: Css.focus({
                                                            hd: Css.opacity(1.0),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(Icon.make, {
                        type_: "chevronRight",
                        size: {
                          NAME: "int",
                          VAL: 20
                        }
                      })));
}

var make = EventsSheet__NameCell;

export {
  make ,
}
/* Css Not a pure module */
