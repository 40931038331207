// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as DateFns from "../DateFns.mjs";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as IconErrorHexagon from "../IconErrorHexagon.mjs";
import * as IconTrackingPlan from "../IconTrackingPlan.mjs";
import * as ModelUtils_mapped from "../ModelUtils_mapped.mjs";
import * as IconCheckmarkCircle from "../IconCheckmarkCircle.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as InspectorPropertyType from "../../../shared/models/InspectorPropertyType.mjs";
import * as InspectorViewSelectors from "./InspectorViewSelectors.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";
import * as InspectorIssuesGenerator from "../../../api/functions/src/inspectorPipeline/utils/InspectorIssuesGenerator.mjs";
import * as InspectorEventViewModelUtils from "./InspectorEventViewModelUtils.mjs";
import * as InspectorViewTrackingPlanUtils from "./InspectorViewTrackingPlanUtils.mjs";

var locationStyles = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.FontFamily.polar),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.medium),
        tl: {
          hd: Css.fontStyle("normal"),
          tl: /* [] */0
        }
      }
    });

function InspectorViewContextSidebarIssues__V2$IssueSeen(Props) {
  var cell = Props.cell;
  var sourceName = Props.sourceName;
  var versionSeen = Props.versionSeen;
  var tmp;
  if (typeof cell === "object") {
    var variant = cell.NAME;
    tmp = variant === "EventSourceIssues" || variant === "PropertySourceIssues" || variant === "EventSourceVolume" ? React.createElement(React.Fragment, undefined, "in version ", React.createElement("em", {
                className: locationStyles
              }, versionSeen.version)) : (
        variant === "EventIssues" || variant === "EventName" || variant === "EventVolume" || variant === "PropertyIssues" ? React.createElement(React.Fragment, undefined, "on ", React.createElement("em", {
                    className: locationStyles
                  }, sourceName + " (" + versionSeen.version + ")")) : null
      );
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: DateFns.formatDistance(undefined, undefined, new Date(), new Date(versionSeen.date)) + " ago "
                }), tmp);
}

var IssueSeen = {
  locationStyles: locationStyles,
  make: InspectorViewContextSidebarIssues__V2$IssueSeen
};

function getCellPropertyVolume(cell, propertyName) {
  if (typeof cell !== "object") {
    return ;
  }
  var variant = cell.NAME;
  if (variant === "PropertyVolume" || variant === "PropertyName" || variant === "PropertyIssues") {
    var match = cell.VAL;
    return match[1].propertyShape.presence.percentagePresent * match[0].totalVolume;
  }
  if (variant === "PropertySourceVolume" || variant === "PropertySourceIssues") {
    var match$1 = cell.VAL;
    var property = match$1[1];
    var $$event = match$1[0];
    return Belt_Option.mapU(match$1[2], (function (sourceId) {
                  return InspectorEventViewModelUtils.$$Event.getPropertyProportionTotalCount([sourceId], $$event, property) * 100.0;
                }));
  }
  if (variant === "EventIssues" || variant === "EventName" || variant === "EventVolume") {
    var $$event$1 = cell.VAL;
    return Belt_Option.map(Belt_Array.getByU($$event$1.properties, (function (property) {
                      return property.propertyName === propertyName;
                    })), (function (property) {
                  return InspectorEventViewModelUtils.$$Event.getPropertyProportionTotalCount(undefined, $$event$1, property) * 100.0;
                }));
  }
  var match$2 = cell.VAL;
  var maybeEventSourceId = match$2[1];
  var $$event$2 = match$2[0];
  var maybeProperty = Belt_Array.getByU($$event$2.properties, (function (p) {
          return p.propertyName === propertyName;
        }));
  if (maybeEventSourceId === undefined) {
    return ;
  }
  if (maybeProperty === undefined) {
    return ;
  }
  var propertySourceId = Belt_Array.getByU(InspectorEventViewModelUtils.Property.getSourceIds(maybeProperty), (function (id) {
          return id === maybeEventSourceId;
        }));
  return Belt_Option.mapU(propertySourceId, (function (sourceId) {
                return InspectorEventViewModelUtils.$$Event.getPropertyProportionTotalCount([sourceId], $$event$2, maybeProperty) * 100.0;
              }));
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.listStyleType("none"),
      tl: {
        hd: Css.paddingTop(Css.px(16)),
        tl: {
          hd: Css.paddingBottom(Css.px(16)),
          tl: {
            hd: Css.marginRight(Css.px(24)),
            tl: {
              hd: Css.marginLeft(Css.px(24)),
              tl: {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var typeStyles = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.FontFamily.polar),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.medium),
        tl: {
          hd: Css.fontStyle("normal"),
          tl: /* [] */0
        }
      }
    });

var origins = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexWrap("wrap"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginBottom(Css.px(4)),
            tl: {
              hd: Css.marginTop(Css.px(4)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var originsList = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(0)),
      tl: {
        hd: Css.marginBottom(Css.px(4)),
        tl: {
          hd: Css.margin(Css.px(0)),
          tl: /* [] */0
        }
      }
    });

var origin = Curry._1(Css.style, {
      hd: Css.listStyleType("none"),
      tl: {
        hd: Css.display("inlineBlock"),
        tl: {
          hd: Css.color(Styles.Color.white),
          tl: {
            hd: Css.fontSize(Styles.FontSize.small),
            tl: {
              hd: Css.fontWeight(Styles.FontWeight.semi),
              tl: {
                hd: Css.backgroundColor(Styles.Color.light07),
                tl: {
                  hd: Css.paddingLeft(Css.px(8)),
                  tl: {
                    hd: Css.paddingTop(Css.px(2)),
                    tl: {
                      hd: Css.paddingRight(Css.px(8)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(2)),
                        tl: {
                          hd: Css.borderRadius(Css.px(100)),
                          tl: {
                            hd: Css.marginTop(Css.px(4)),
                            tl: {
                              hd: Css.marginRight(Css.px(4)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function InspectorViewContextSidebarIssues__V2$IssueGroup(Props) {
  var schemaSources = Props.schemaSources;
  var cell = Props.cell;
  var issueGroup = Props.issueGroup;
  var mappedProperties = Props.mappedProperties;
  var mappedDestinations = Props.mappedDestinations;
  var rules = Props.rules;
  var propertyTypesInGroup = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.mapU(issueGroup, (function (param) {
                      return Belt_Array.mapU(param[1].propertyShape.propertyTypes, (function (param) {
                                    return InspectorPropertyType.toString(param.type_);
                                  }));
                    })))));
  return Belt_Option.mapWithDefault(Belt_Array.get(issueGroup, 0), null, (function (param) {
                var issue = param[3];
                var property = param[1];
                var sourceId = param[0];
                var modelProperty = ModelUtils_mapped.getPropertyByNameOrNameMapping(rules, mappedDestinations, mappedProperties, property.propertyName, undefined);
                var propertyExists = Belt_Option.isSome(modelProperty);
                var tmp;
                if (typeof issue !== "object") {
                  tmp = null;
                } else if (propertyExists) {
                  if (typeof issue === "object") {
                    switch (issue.TAG) {
                      case "MissingExpectedProperty" :
                          var percentagePresentOnSource = InspectorEventViewModelUtils.Property.getPresencePercentage(sourceId, property);
                          tmp = React.createElement(React.Fragment, undefined, " is ", React.createElement("strong", undefined, percentagePresentOnSource !== undefined && percentagePresentOnSource !== 0.0 ? (
                                      percentagePresentOnSource !== 100.0 ? "sometimes sent" : "always sent"
                                    ) : "never sent"), Belt_Option.mapWithDefault(percentagePresentOnSource, null, (function (presencePercentage) {
                                      return React.createElement($$Text.make, {
                                                  color: Styles.Color.light10,
                                                  children: null
                                                }, "Missing in ", React.createElement($$Text.make, {
                                                      element: "Span",
                                                      weight: "Semi",
                                                      children: null
                                                    }, String(Js_math.ceil(100.0 - presencePercentage * 100.0)), "%"), " of Event Volume");
                                    })));
                          break;
                      case "PropertyTypeInconsistentWithTrackingPlan" :
                          var match = issue._0;
                          tmp = React.createElement(React.Fragment, undefined, "Tracking Plan expects ", React.createElement("em", {
                                    className: typeStyles
                                  }, match.expectedPropertyType), " but is sent as ", React.createElement("em", {
                                    className: typeStyles
                                  }, match.actualPropertyType));
                          break;
                      case "UnexpectedProperty" :
                          var percentagePresentOnSource$1 = InspectorEventViewModelUtils.Property.getPresencePercentage(sourceId, property);
                          tmp = React.createElement(React.Fragment, undefined, "Unexpected Property on event", Belt_Option.mapWithDefault(percentagePresentOnSource$1, null, (function (volume) {
                                      return React.createElement($$Text.make, {
                                                  color: Styles.Color.light10,
                                                  children: null
                                                }, "appeared in ", React.createElement($$Text.make, {
                                                      element: "Span",
                                                      weight: "Semi",
                                                      children: null
                                                    }, String(Js_math.ceil(volume)), "%"), " of Event Volume");
                                    })));
                          break;
                      case "InconsistentType" :
                          var propertyTypes = issue._0.propertyTypes;
                          tmp = React.createElement(React.Fragment, undefined, "sending types ", Belt_Array.mapWithIndexU(propertyTypesInGroup, (function (index, issue) {
                                      if ((propertyTypes.length - 2 | 0) === index) {
                                        return React.createElement(React.Fragment, {
                                                    children: null,
                                                    key: index
                                                  }, React.createElement("em", {
                                                        className: typeStyles
                                                      }, issue), " and ");
                                      } else if ((propertyTypes.length - 1 | 0) === index) {
                                        return React.createElement("em", {
                                                    key: String(index),
                                                    className: typeStyles
                                                  }, issue);
                                      } else {
                                        return React.createElement(React.Fragment, {
                                                    children: null,
                                                    key: index
                                                  }, React.createElement("em", {
                                                        className: typeStyles
                                                      }, issue), ", ");
                                      }
                                    })));
                          break;
                      
                    }
                  }
                  
                } else {
                  tmp = "not found in Tracking Plan";
                }
                var tmp$1;
                if (typeof cell === "object") {
                  var variant = cell.NAME;
                  tmp$1 = variant === "PropertySourceVolume" || variant === "EventSourceIssues" || variant === "PropertySourceIssues" || variant === "EventSourceVolume" || !(variant === "EventIssues" || variant === "EventName" || variant === "EventVolume" || variant === "PropertyIssues") ? null : React.createElement("div", {
                          className: origins
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              color: Styles.Color.light10,
                              children: "Sources"
                            }), React.createElement(Spacer.make, {
                              width: 4
                            }), React.createElement("ul", {
                              className: originsList
                            }, Belt_Array.mapU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(issueGroup, (function (param) {
                                                return param[0];
                                              })))), (function (sourceId) {
                                    return React.createElement("li", {
                                                key: sourceId,
                                                className: origin
                                              }, Belt_Option.getWithDefault(AvoConfig.sourceIdToName(sourceId, schemaSources), "-"));
                                  }))));
                } else {
                  tmp$1 = null;
                }
                return React.createElement("article", {
                            className: rootStyles
                          }, React.createElement($$Text.make, {
                                element: "H1",
                                size: "Medium",
                                weight: "Semi",
                                withWordBreak: true,
                                children: param[2]
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.red,
                                children: tmp
                              }), tmp$1);
              }));
}

var IssueGroup = {
  rootStyles: rootStyles,
  typeStyles: typeStyles,
  origins: origins,
  originsList: originsList,
  origin: origin,
  make: InspectorViewContextSidebarIssues__V2$IssueGroup
};

var rootStyles$1 = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.overflow("hidden"),
        tl: {
          hd: Css.maxHeight(Css.pct(100.0)),
          tl: {
            hd: Css.overflowY("auto"),
            tl: /* [] */0
          }
        }
      }
    });

function buttonStyles(disabled) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("row"),
                          tl: {
                            hd: Css.alignItems("flexStart"),
                            tl: {
                              hd: Css.padding(Css.px(16)),
                              tl: {
                                hd: Css.marginTop(Css.px(-1)),
                                tl: {
                                  hd: Css.marginLeft(Css.px(8)),
                                  tl: {
                                    hd: Css.marginRight(Css.px(8)),
                                    tl: {
                                      hd: Css.cursor(disabled ? "default" : "pointer"),
                                      tl: {
                                        hd: Css.borderRadius(Styles.Border.radius),
                                        tl: {
                                          hd: Css.hover(disabled ? /* [] */0 : ({
                                                    hd: Css.backgroundColor(Styles.Color.light02),
                                                    tl: /* [] */0
                                                  })),
                                          tl: {
                                            hd: Css.backgroundColor("transparent"),
                                            tl: {
                                              hd: Css.transition({
                                                    NAME: "ms",
                                                    VAL: Styles.Duration.$$default
                                                  }, undefined, undefined, "background"),
                                              tl: {
                                                hd: Css.after({
                                                      hd: Css.display("block"),
                                                      tl: {
                                                        hd: Css.position("absolute"),
                                                        tl: {
                                                          hd: Css.backgroundColor(Styles.Color.light02),
                                                          tl: {
                                                            hd: Css.height(Css.px(1)),
                                                            tl: {
                                                              hd: Css.left(Css.px(16)),
                                                              tl: {
                                                                hd: Css.right(Css.px(16)),
                                                                tl: {
                                                                  hd: Css.bottom(Css.px(0)),
                                                                  tl: {
                                                                    hd: Css.unsafe("content", "' '"),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var buttonInfoStyles = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.marginLeft(Css.px(12)),
        tl: /* [] */0
      }
    });

function buttonChevronStyles(isOpen) {
  return Curry._1(Css.style, {
              hd: Css.alignSelf("stretch"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
                    tl: {
                      hd: Css.transform({
                            NAME: "rotate",
                            VAL: {
                              NAME: "deg",
                              VAL: isOpen ? 0.0 : 90.0
                            }
                          }),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$default
                            }, undefined, undefined, "transform"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var openBorderStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.bottom(Css.px(16)),
          tl: {
            hd: Css.top(Css.px(16)),
            tl: {
              hd: Css.width(Css.px(2)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.red),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function InspectorViewContextSidebarIssues__V2$IssueType(Props) {
  var schemaSources = Props.schemaSources;
  var cell = Props.cell;
  var issueGroups = Props.issueGroups;
  var title = Props.title;
  var mappedProperties = Props.mappedProperties;
  var mappedDestinations = Props.mappedDestinations;
  var rules = Props.rules;
  var schemaBundle = SchemaBundleContext.use();
  var match = React.useState(function (param) {
        return false;
      });
  var setisOpen = match[1];
  var isOpen = match[0];
  var isDisabled = Caml_obj.equal(issueGroups, []);
  var match$1 = issueGroups.length;
  var tmp;
  var exit = 0;
  if (match$1 !== 0) {
    if (typeof cell === "object") {
      var variant = cell.NAME;
      if (variant === "PropertySourceVolume" || variant === "PropertyVolume" || variant === "PropertyName" || variant === "PropertySourceIssues" || variant === "PropertyIssues") {
        tmp = React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.red,
              children: "Affects this property"
            });
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
  } else {
    tmp = null;
  }
  if (exit === 1) {
    tmp = React.createElement($$Text.make, {
          size: "Small",
          weight: "Semi",
          color: Styles.Color.red,
          children: "Affects " + AvoUtils.plural("properties", undefined, match$1, "property")
        });
  }
  return React.createElement("section", {
              className: rootStyles$1
            }, React.createElement("a", {
                  className: buttonStyles(isDisabled),
                  role: "button",
                  tabIndex: 0,
                  onClick: (function (param) {
                      if (!isOpen) {
                        AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ExpandIssueType", schemaBundle.schemaId);
                      }
                      Curry._1(setisOpen, (function (isOpen) {
                              if (isDisabled) {
                                return false;
                              } else {
                                return !isOpen;
                              }
                            }));
                    })
                }, React.createElement(IconErrorHexagon.make, {
                      size: 16
                    }), React.createElement("header", {
                      className: buttonInfoStyles
                    }, React.createElement($$Text.make, {
                          element: "H1",
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: title
                        }), tmp), Caml_obj.notequal(issueGroups, []) ? React.createElement("div", {
                        className: buttonChevronStyles(isOpen)
                      }, React.createElement("svg", {
                            height: "6",
                            width: "10",
                            fill: "none",
                            viewBox: "0 0 10 6",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, React.createElement("path", {
                                d: "M1 1L5 5L9 1",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeMiterlimit: "10"
                              }))) : null), isOpen ? Belt_Array.mapWithIndexU(issueGroups, (function (index, issueGroup) {
                      return React.createElement(InspectorViewContextSidebarIssues__V2$IssueGroup, {
                                  schemaSources: schemaSources,
                                  cell: cell,
                                  issueGroup: issueGroup,
                                  mappedProperties: mappedProperties,
                                  mappedDestinations: mappedDestinations,
                                  rules: rules,
                                  key: String(index)
                                });
                    })) : null, isOpen ? React.createElement("div", {
                    className: openBorderStyles
                  }) : null);
}

var IssueType = {
  rootStyles: rootStyles$1,
  buttonStyles: buttonStyles,
  buttonInfoStyles: buttonInfoStyles,
  buttonChevronStyles: buttonChevronStyles,
  openBorderStyles: openBorderStyles,
  make: InspectorViewContextSidebarIssues__V2$IssueType
};

var issueFreeGroup = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("flexStart"),
            tl: {
              hd: Css.marginLeft(Css.px(24)),
              tl: {
                hd: Css.marginRight(Css.px(24)),
                tl: {
                  hd: Css.paddingTop(Css.px(16)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(16)),
                    tl: {
                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function InspectorViewContextSidebarIssues__V2$IssueFreeType(Props) {
  var healthyCell = Props.healthyCell;
  var title = Props.title;
  var hasVolume = Props.hasVolume;
  return React.createElement("div", {
              className: issueFreeGroup
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexShrink(0.0),
                        tl: {
                          hd: Css.marginRight(Css.px(12)),
                          tl: /* [] */0
                        }
                      })
                }, hasVolume ? React.createElement(IconCheckmarkCircle.make, {
                        color: healthyCell ? Styles.Color.green : Styles.Color.light08
                      }) : React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light08,
                        children: "—"
                      })), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: title
                }));
}

var IssueFreeType = {
  issueFreeGroup: issueFreeGroup,
  make: InspectorViewContextSidebarIssues__V2$IssueFreeType
};

var headerStyles = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(16)),
      tl: {
        hd: Css.paddingBottom(Css.px(16)),
        tl: {
          hd: Css.marginLeft(Css.px(24)),
          tl: {
            hd: Css.marginRight(Css.px(24)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
              tl: /* [] */0
            }
          }
        }
      }
    });

var titleStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.alignItems("center"),
            tl: /* [] */0
          }
        }
      }
    });

var titleTextStyles = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.small),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.color(Styles.Color.light08),
          tl: {
            hd: Css.margin(Css.px(0)),
            tl: /* [] */0
          }
        }
      }
    });

var subtitleStyles = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.huge),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.margin4(Css.px(6), Css.px(0), Css.px(0), Css.px(0)),
          tl: {
            hd: Css.overflowWrap("breakWord"),
            tl: /* [] */0
          }
        }
      }
    });

var issuesContext = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.regular),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.color(Styles.Color.light08),
          tl: {
            hd: Css.marginTop(Css.px(8)),
            tl: {
              hd: Css.marginBottom(Css.px(0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var trackingPlanStatus = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("left"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.marginTop(Css.px(8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var trackingPlanStatusText = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var variantValidationDisclaimerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.margin2(Css.px(16), Css.px(21)),
        tl: {
          hd: Css.borderRadius(Css.px(6)),
          tl: {
            hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(8)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.lightOrange),
              tl: /* [] */0
            }
          }
        }
      }
    });

function InspectorViewContextSidebarIssues__V2(Props) {
  var cell = Props.cell;
  var eventName = Props.eventName;
  var onAddEvent = Props.onAddEvent;
  var onAddProperty = Props.onAddProperty;
  var sourceIds = Props.sourceIds;
  var sourceIssues = Props.sourceIssues;
  var modelSources = Props.modelSources;
  var modelEvents = Props.modelEvents;
  var rules = Props.rules;
  var modelProperties = Props.modelProperties;
  var modelDestinations = Props.modelDestinations;
  var addToast = Toast.useAddToast();
  var schemaBundle = SchemaBundleContext.use();
  var match;
  if (typeof cell === "object") {
    var variant = cell.NAME;
    if (variant === "PropertySourceVolume" || variant === "PropertyVolume" || variant === "PropertyName" || variant === "PropertySourceIssues" || variant === "PropertyIssues") {
      var property = cell.VAL[1];
      var modelEvent = InspectorIssuesGenerator.getTrackingPlanEvent_mappedModel(modelEvents, rules, eventName);
      var modelProperty = ModelUtils_mapped.getPropertyByNameOrNameMapping(rules, modelDestinations, modelProperties, property.propertyName, Belt_Option.map(modelEvent, (function ($$event) {
                  return $$event.id;
                })));
      match = [
        modelProperty,
        property
      ];
    } else {
      match = [
        undefined,
        undefined
      ];
    }
  } else {
    match = [
      undefined,
      undefined
    ];
  }
  var inspectorProperty = match[1];
  var modelProperty$1 = match[0];
  var modelEvent$1 = InspectorIssuesGenerator.getTrackingPlanEvent_mappedModel(modelEvents, rules, eventName);
  var eventExists = Belt_Option.isSome(modelEvent$1);
  var propertyExists = Belt_Option.isSome(modelProperty$1);
  var handleCopyLink = function (_event) {
    CopyTextToClipboard(window.location.href);
    AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "CopyEventIssuesLink", schemaBundle.schemaId);
    Curry._1(addToast, {
          message: "Link copied!",
          toastType: "Success"
        });
  };
  var tmp;
  if (typeof cell === "object") {
    var variant$1 = cell.NAME;
    tmp = variant$1 === "PropertySourceVolume" || variant$1 === "EventSourceIssues" || variant$1 === "PropertySourceIssues" || variant$1 === "EventSourceVolume" ? Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_List.head(sourceIds), (function (sourceId) {
                  return AvoConfig.sourceIdToName(sourceId, modelSources);
                })), "N/A") : "All Sources";
  } else {
    tmp = "All Sources";
  }
  var tmp$1;
  if (typeof cell === "object") {
    var variant$2 = cell.NAME;
    tmp$1 = variant$2 === "PropertyIssues" || variant$2 === "PropertySourceVolume" || variant$2 === "PropertyVolume" || variant$2 === "PropertyName" || variant$2 === "PropertySourceIssues" ? cell.VAL[1].propertyName : "All Properties";
  } else {
    tmp$1 = "All Properties";
  }
  var tmp$2;
  if (modelProperty$1 !== undefined) {
    if (inspectorProperty !== undefined && modelEvent$1 !== undefined) {
      var worstIssue = InspectorViewTrackingPlanUtils.getWorstPropertyIssue_(inspectorProperty);
      var worstIssue$1 = worstIssue !== undefined && !(typeof worstIssue !== "object" || !(worstIssue.TAG === "UnexpectedProperty" && !InspectorViewTrackingPlanUtils.isUnexpectedPropertyOnAllSources_(inspectorProperty))) ? undefined : worstIssue;
      var exit = 0;
      var exit$1 = 0;
      if (worstIssue$1 !== undefined) {
        if (typeof worstIssue$1 !== "object") {
          if (propertyExists) {
            exit = 1;
          } else {
            exit$1 = 2;
          }
        } else if (worstIssue$1.TAG === "UnexpectedProperty" || !propertyExists) {
          exit$1 = 2;
        } else {
          exit = 1;
        }
      } else if (propertyExists) {
        exit = 1;
      } else {
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        if (eventExists) {
          tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                    height: 4
                  }), React.createElement("div", {
                    className: trackingPlanStatus
                  }, React.createElement(Button.make, {
                        label: "Add Property To Event",
                        onClick: (function (param) {
                            AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ClickAddPropertyToTrackingPlan", schemaBundle.schemaId);
                            Curry._1(onAddProperty, inspectorProperty);
                          })
                      })));
        } else {
          exit = 1;
        }
      }
      if (exit === 1) {
        var exit$2 = 0;
        if (worstIssue$1 !== undefined && (typeof worstIssue$1 !== "object" || worstIssue$1.TAG === "UnexpectedProperty")) {
          tmp$2 = null;
        } else {
          exit$2 = 2;
        }
        if (exit$2 === 2) {
          tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                    height: 4
                  }), React.createElement("div", {
                    className: trackingPlanStatus
                  }, React.createElement(Button.make, {
                        icon: "sidebar",
                        label: "View Property",
                        onClick: (function (param) {
                            AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ClickViewPropertyInTrackingPlan", schemaBundle.schemaId);
                            Router.Schema.pushDrawerItem(undefined, undefined, {
                                  NAME: "property",
                                  VAL: [
                                    modelProperty$1.id,
                                    undefined
                                  ]
                                });
                          }),
                        style: "outline"
                      })));
        }
        
      }
      
    } else {
      tmp$2 = null;
    }
  } else {
    tmp$2 = inspectorProperty !== undefined && modelEvent$1 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                height: 4
              }), React.createElement("div", {
                className: trackingPlanStatus
              }, React.createElement(Button.make, {
                    label: "Create and add Property To Event",
                    onClick: (function (param) {
                        AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ClickAddPropertyToTrackingPlan", schemaBundle.schemaId);
                        Curry._1(onAddProperty, inspectorProperty);
                      })
                  }))) : null;
  }
  var match$1 = InspectorViewSelectors.partitionTrackingPlanAndNonTrackingPlanIssues_(sourceIssues);
  var nonTrackingPlanIssues = match$1[1];
  var trackingPlanIssues = match$1[0];
  var groupedTrackingPlanIssues = InspectorViewSelectors.groupIssuesFromMultipleSources_(trackingPlanIssues);
  var trackingPlanStatus$1 = InspectorViewSelectors.getEventTrackingPlanStatusFromCell_(cell);
  var inconsistentPropertyTypeIssues = InspectorViewSelectors.groupIssuesFromMultipleSources_(Belt_Array.keepU(nonTrackingPlanIssues, (function (param) {
              var tmp = param[3];
              if (typeof tmp !== "object" || tmp.TAG !== "InconsistentType") {
                return false;
              } else {
                return true;
              }
            })));
  var sometimesMissingIssues = InspectorViewSelectors.groupIssuesFromMultipleSources_(Belt_Array.keepU(trackingPlanIssues, (function (param) {
              var tmp = param[3];
              if (typeof tmp !== "object" || tmp.TAG !== "MissingExpectedProperty") {
                return false;
              } else {
                return true;
              }
            })));
  var healthyCell = sourceIssues.length === 0;
  var hasVolume;
  if (typeof cell === "object") {
    var variant$3 = cell.NAME;
    if (variant$3 === "PropertyVolume" || variant$3 === "PropertyName" || variant$3 === "PropertyIssues") {
      hasVolume = cell.VAL[0].totalVolume > 0.0;
    } else if (variant$3 === "PropertySourceVolume" || variant$3 === "PropertySourceIssues") {
      var match$2 = cell.VAL;
      var sourceIds$1 = Belt_Option.mapU(match$2[2], (function (id) {
              return [id];
            }));
      hasVolume = InspectorEventViewModelUtils.Property.getTotalCountInt(sourceIds$1, match$2[1]) > 0;
    } else if (variant$3 === "EventIssues" || variant$3 === "EventName" || variant$3 === "EventVolume") {
      hasVolume = cell.VAL.totalVolume > 0.0;
    } else {
      var match$3 = cell.VAL;
      var sourceIds$2 = Belt_Option.mapU(match$3[1], (function (id) {
              return [id];
            }));
      hasVolume = InspectorEventViewModelUtils.$$Event.getTotalCountInt(sourceIds$2, match$3[0]) > 0;
    }
  } else {
    hasVolume = false;
  }
  var eventHasVariants = modelEvent$1 !== undefined ? modelEvent$1.variants.length !== 0 : false;
  var tmp$3;
  var exit$3 = 0;
  var exit$4 = 0;
  if (typeof cell === "object") {
    var variant$4 = cell.NAME;
    if (variant$4 === "EventIssues" || variant$4 === "EventName" || variant$4 === "EventVolume" || variant$4 === "EventSourceIssues" || variant$4 === "EventSourceVolume") {
      if (inconsistentPropertyTypeIssues.length !== 0) {
        exit$3 = 1;
      } else {
        tmp$3 = React.createElement(InspectorViewContextSidebarIssues__V2$IssueFreeType, {
              healthyCell: healthyCell,
              title: "Properties have consistent types",
              hasVolume: hasVolume
            });
      }
    } else if (variant$4 === "PropertySourceVolume" || variant$4 === "PropertyVolume" || variant$4 === "PropertyName" || variant$4 === "PropertySourceIssues" || variant$4 === "PropertyIssues") {
      if (inconsistentPropertyTypeIssues.length !== 0) {
        exit$3 = 1;
      } else {
        tmp$3 = React.createElement(InspectorViewContextSidebarIssues__V2$IssueFreeType, {
              healthyCell: healthyCell,
              title: "Property type is consistent",
              hasVolume: hasVolume
            });
      }
    } else {
      exit$4 = 2;
    }
  } else {
    exit$4 = 2;
  }
  if (exit$4 === 2) {
    if (inconsistentPropertyTypeIssues.length !== 0) {
      exit$3 = 1;
    } else {
      tmp$3 = React.createElement(InspectorViewContextSidebarIssues__V2$IssueFreeType, {
            healthyCell: healthyCell,
            title: null,
            hasVolume: hasVolume
          });
    }
  }
  if (exit$3 === 1) {
    tmp$3 = React.createElement(InspectorViewContextSidebarIssues__V2$IssueType, {
          schemaSources: modelSources,
          cell: cell,
          issueGroups: inconsistentPropertyTypeIssues,
          title: "Inconsistent Property Type",
          mappedProperties: modelProperties,
          mappedDestinations: modelDestinations,
          rules: rules
        });
  }
  var tmp$4;
  var exit$5 = 0;
  if (typeof cell === "object") {
    var variant$5 = cell.NAME;
    if (variant$5 === "EventIssues" || variant$5 === "EventName" || variant$5 === "EventVolume" || variant$5 === "EventSourceIssues" || variant$5 === "EventSourceVolume") {
      if (sometimesMissingIssues.length !== 0 || !eventExists) {
        exit$5 = 1;
      } else {
        tmp$4 = React.createElement(InspectorViewContextSidebarIssues__V2$IssueFreeType, {
              healthyCell: healthyCell,
              title: "Properties are present as expected",
              hasVolume: hasVolume
            });
      }
    } else if ((variant$5 === "PropertySourceVolume" || variant$5 === "PropertyVolume" || variant$5 === "PropertyName" || variant$5 === "PropertySourceIssues" || variant$5 === "PropertyIssues") && !(sometimesMissingIssues.length !== 0 || !eventExists)) {
      tmp$4 = React.createElement(InspectorViewContextSidebarIssues__V2$IssueFreeType, {
            healthyCell: healthyCell,
            title: "Property is present as expected",
            hasVolume: hasVolume
          });
    } else {
      exit$5 = 1;
    }
  } else {
    exit$5 = 1;
  }
  if (exit$5 === 1) {
    tmp$4 = sometimesMissingIssues.length !== 0 ? React.createElement(InspectorViewContextSidebarIssues__V2$IssueType, {
            schemaSources: modelSources,
            cell: cell,
            issueGroups: sometimesMissingIssues,
            title: "Property Sometimes Missing",
            mappedProperties: modelProperties,
            mappedDestinations: modelDestinations,
            rules: rules
          }) : null;
  }
  var tmp$5;
  var exit$6 = 0;
  if (trackingPlanStatus$1 !== undefined && typeof trackingPlanStatus$1 === "object") {
    if (trackingPlanStatus$1.NAME === "EventFoundInTrackingPlan") {
      if (typeof cell === "object") {
        var variant$6 = cell.NAME;
        tmp$5 = variant$6 === "PropertySourceVolume" || variant$6 === "PropertyVolume" || variant$6 === "PropertyName" || variant$6 === "PropertySourceIssues" || variant$6 === "PropertyIssues" ? (
            nonTrackingPlanIssues.length !== 0 ? React.createElement(InspectorViewContextSidebarIssues__V2$IssueType, {
                    schemaSources: modelSources,
                    cell: cell,
                    issueGroups: groupedTrackingPlanIssues,
                    title: "Property conflicts with Tracking Plan",
                    mappedProperties: modelProperties,
                    mappedDestinations: modelDestinations,
                    rules: rules
                  }) : (
                propertyExists ? React.createElement(InspectorViewContextSidebarIssues__V2$IssueFreeType, {
                        healthyCell: healthyCell,
                        title: "Property matches Tracking Plan",
                        hasVolume: hasVolume
                      }) : React.createElement(InspectorViewContextSidebarIssues__V2$IssueType, {
                        schemaSources: modelSources,
                        cell: cell,
                        issueGroups: groupedTrackingPlanIssues,
                        title: "Property not in Tracking Plan",
                        mappedProperties: modelProperties,
                        mappedDestinations: modelDestinations,
                        rules: rules
                      })
              )
          ) : (
            groupedTrackingPlanIssues.length !== 0 ? React.createElement(InspectorViewContextSidebarIssues__V2$IssueType, {
                    schemaSources: modelSources,
                    cell: cell,
                    issueGroups: groupedTrackingPlanIssues,
                    title: "Event conflicts with Tracking Plan",
                    mappedProperties: modelProperties,
                    mappedDestinations: modelDestinations,
                    rules: rules
                  }) : React.createElement(InspectorViewContextSidebarIssues__V2$IssueFreeType, {
                    healthyCell: healthyCell,
                    title: "Event matches Tracking Plan",
                    hasVolume: hasVolume
                  })
          );
      } else {
        tmp$5 = null;
      }
    } else if (typeof cell === "object") {
      var variant$7 = cell.NAME;
      tmp$5 = variant$7 === "PropertySourceVolume" || variant$7 === "PropertyVolume" || variant$7 === "PropertyName" || variant$7 === "PropertySourceIssues" || variant$7 === "PropertyIssues" ? (
          trackingPlanIssues.length !== 0 ? (
              propertyExists ? React.createElement(InspectorViewContextSidebarIssues__V2$IssueType, {
                      schemaSources: modelSources,
                      cell: cell,
                      issueGroups: groupedTrackingPlanIssues,
                      title: "Property conflicts with Tracking Plan",
                      mappedProperties: modelProperties,
                      mappedDestinations: modelDestinations,
                      rules: rules
                    }) : null
            ) : (
              propertyExists ? React.createElement(InspectorViewContextSidebarIssues__V2$IssueFreeType, {
                      healthyCell: healthyCell,
                      title: "Property matches Tracking Plan",
                      hasVolume: hasVolume
                    }) : null
            )
        ) : React.createElement(InspectorViewContextSidebarIssues__V2$IssueType, {
              schemaSources: modelSources,
              cell: cell,
              issueGroups: groupedTrackingPlanIssues,
              title: "Event conflicts with Tracking Plan",
              mappedProperties: modelProperties,
              mappedDestinations: modelDestinations,
              rules: rules
            });
    } else {
      tmp$5 = null;
    }
  } else {
    exit$6 = 1;
  }
  if (exit$6 === 1) {
    if (typeof cell === "object") {
      var variant$8 = cell.NAME;
      tmp$5 = variant$8 === "PropertySourceVolume" || variant$8 === "PropertyVolume" || variant$8 === "PropertyName" || variant$8 === "PropertySourceIssues" || variant$8 === "PropertyIssues" ? (
          nonTrackingPlanIssues.length !== 0 ? React.createElement(InspectorViewContextSidebarIssues__V2$IssueType, {
                  schemaSources: modelSources,
                  cell: cell,
                  issueGroups: groupedTrackingPlanIssues,
                  title: "Property conflicts with Tracking Plan",
                  mappedProperties: modelProperties,
                  mappedDestinations: modelDestinations,
                  rules: rules
                }) : (
              propertyExists ? React.createElement(InspectorViewContextSidebarIssues__V2$IssueFreeType, {
                      healthyCell: healthyCell,
                      title: "Property matches Tracking Plan",
                      hasVolume: hasVolume
                    }) : React.createElement(InspectorViewContextSidebarIssues__V2$IssueType, {
                      schemaSources: modelSources,
                      cell: cell,
                      issueGroups: groupedTrackingPlanIssues,
                      title: "Property not in Tracking Plan",
                      mappedProperties: modelProperties,
                      mappedDestinations: modelDestinations,
                      rules: rules
                    })
            )
        ) : (
          nonTrackingPlanIssues.length !== 0 ? React.createElement(InspectorViewContextSidebarIssues__V2$IssueType, {
                  schemaSources: modelSources,
                  cell: cell,
                  issueGroups: groupedTrackingPlanIssues,
                  title: "Properties conflict with Tracking Plan",
                  mappedProperties: modelProperties,
                  mappedDestinations: modelDestinations,
                  rules: rules
                }) : null
        );
    } else {
      tmp$5 = null;
    }
  }
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: headerStyles
                }, React.createElement("div", {
                      className: titleStyles
                    }, React.createElement("h1", {
                          className: titleTextStyles
                        }, "Issue Inspector"), React.createElement(Button.make, {
                          icon: "link",
                          onClick: handleCopyLink,
                          size: "tiny",
                          style: "ghost"
                        })), React.createElement("h2", {
                      className: subtitleStyles
                    }, eventName), modelEvent$1 !== undefined ? React.createElement("div", {
                        className: trackingPlanStatus
                      }, React.createElement("div", {
                            className: trackingPlanStatusText
                          }, React.createElement(IconTrackingPlan.make, {}), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light10,
                                children: "Found in tracking plan"
                              }), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement(Button.make, {
                                icon: "sidebar",
                                label: "View",
                                onClick: (function (param) {
                                    AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ClickViewEventInTrackingPlan", schemaBundle.schemaId);
                                    Router.Schema.pushDrawerItem(undefined, undefined, {
                                          NAME: "event",
                                          VAL: [
                                            modelEvent$1.id,
                                            undefined,
                                            undefined,
                                            false
                                          ]
                                        });
                                  }),
                                style: "outline"
                              }))) : React.createElement("div", {
                        className: trackingPlanStatus
                      }, React.createElement("div", {
                            className: trackingPlanStatusText
                          }, React.createElement(IconTrackingPlan.make, {
                                color: Styles.Color.light08
                              }), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light10,
                                children: "Event not in tracking plan"
                              })), React.createElement(Spacer.make, {
                            height: 4
                          }), React.createElement(Button.make, {
                            label: "Add Event to Tracking Plan",
                            onClick: (function ($$event) {
                                AnalyticsRe.inspectorEventsSidebarInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ClickAddEventToTrackingPlan", schemaBundle.schemaId);
                                Curry._1(onAddEvent, $$event);
                              })
                          })), React.createElement("p", {
                      className: issuesContext
                    }, tmp, " - ", tmp$1), tmp$2), React.createElement(React.Fragment, undefined, eventHasVariants && !healthyCell ? React.createElement("div", {
                        className: variantValidationDisclaimerStyles
                      }, React.createElement(Icon.make, {
                            type_: "warning",
                            color: Styles.Color.orange
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            lineHeight: Css.px(20),
                            children: "Inspector Events currently does not support validation for event variants. As a result, some issues may not be relevant."
                          })) : null, tmp$3, tmp$4, tmp$5));
}

var EventsByName;

var PropertiesByName;

var make = InspectorViewContextSidebarIssues__V2;

export {
  IssueSeen ,
  getCellPropertyVolume ,
  IssueGroup ,
  IssueType ,
  IssueFreeType ,
  headerStyles ,
  titleStyles ,
  titleTextStyles ,
  subtitleStyles ,
  issuesContext ,
  trackingPlanStatus ,
  trackingPlanStatusText ,
  variantValidationDisclaimerStyles ,
  EventsByName ,
  PropertiesByName ,
  make ,
}
/* locationStyles Not a pure module */
