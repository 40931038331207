// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../../../../bs-case/src/case.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function fieldGroupBaseNameFromEvent($$event) {
  return Belt_Option.getWithDefault($$event.uniqueName, $$event.name) + " Properties";
}

function toId(tenantIdOpt, baseId, version) {
  var tenantId = tenantIdOpt !== undefined ? tenantIdOpt : "<XDM_TENANTID_PLACEHOLDER>";
  return "https://ns.adobe.com/" + tenantId + "/mixins/avo/" + baseId + (
          version > 1 ? "_v" + String(version) : ""
        );
}

function toAltId(tenantIdOpt, baseId, version) {
  var tenantId = tenantIdOpt !== undefined ? tenantIdOpt : "<XDM_TENANTID_PLACEHOLDER>";
  return "_" + tenantId + ".mixins.avo." + baseId + (
          version > 1 ? "_v" + String(version) : ""
        );
}

function toTitle(versionOpt, fieldGroupBaseName) {
  var version = versionOpt !== undefined ? versionOpt : 1;
  return fieldGroupBaseName + (
          version > 1 ? "_v" + String(version) : ""
        );
}

function fieldKey(baseId, version, propertyId) {
  var baseId$1 = baseId.startsWith("_") ? baseId.slice(1) : baseId;
  return Case.snake(baseId$1 + "_" + propertyId + (
              version > 1 ? "_v" + String(version) : ""
            ));
}

export {
  fieldGroupBaseNameFromEvent ,
  toId ,
  toAltId ,
  toTitle ,
  fieldKey ,
}
/* Case Not a pure module */
