// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AvoModel from "../avoModel.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as IntegrationUtils from "../../../api/functions/src/IntegrationUtils.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";

function findPropertyBundlesPublishedToAdobeExperiencePlatform(model) {
  var adobeExperiencePlatformIntegrations = Curry._2(TrackingPlanMappedModel.Integrations.keep, model.integrations, (function (integration) {
          var match = integration.config;
          if (match !== undefined && match.TAG === "AdobeExperiencePlatform") {
            return true;
          } else {
            return false;
          }
        }));
  var affectedEvents = Belt_Array.concatMany(Belt_Array.mapU(Curry._1(TrackingPlanMappedModel.Integrations.toArray, adobeExperiencePlatformIntegrations), (function (adobeExperiencePlatformIntegration) {
              if (Belt_List.length(adobeExperiencePlatformIntegration.filters) !== 0) {
                return Belt_List.toArray(AvoModel.filterExcludedEvents(Belt_List.fromArray(Curry._1(TrackingPlanMappedModel.Events.toArray, model.events)), adobeExperiencePlatformIntegration.filters));
              }
              var oldModel = TrackingPlanMappedModel.toOld(model);
              return Belt_List.toArray(AvoModel.filterExcludedEventsBySourcesFromModel(oldModel, IntegrationUtils.findSources(IntegrationUtils.findDestinations(adobeExperiencePlatformIntegration, oldModel), oldModel)).events);
            })));
  var affectedPropertyBundleIds = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.mapU(affectedEvents, (function ($$event) {
                      return Belt_List.toArray(Belt_List.map($$event.propertyBundles, (function (bundleRef) {
                                        return bundleRef.id;
                                      })));
                    })))));
  return Belt_Array.keepMapU(affectedPropertyBundleIds, (function (bundleId) {
                return Curry._2(TrackingPlanMappedModel.PropertyBundles.get, model.propertyBundles, bundleId);
              }));
}

function getFirstBreakingChangeInfo(actions, model) {
  var propertyBundlesConnectedToAdobeExperiencePlatform = findPropertyBundlesPublishedToAdobeExperiencePlatform(model);
  var aepBundleIds = Belt_SetString.fromArray(Belt_Array.map(propertyBundlesConnectedToAdobeExperiencePlatform, (function (bundle) {
              return bundle.id;
            })));
  var maybePropertyRemovalChange = Belt_Array.get(Belt_Array.keepMapU(actions, (function (param) {
              var action = param[0];
              if (typeof action !== "object") {
                return ;
              }
              if (action.NAME !== "RemovePropertyFromGroup") {
                return ;
              }
              var match = action.VAL;
              var propertyBundleId = match[0];
              if (!Belt_SetString.has(aepBundleIds, propertyBundleId)) {
                return ;
              }
              var match$1 = Curry._2(TrackingPlanMappedModel.PropertyBundles.get, model.propertyBundles, propertyBundleId);
              var match$2 = Curry._2(TrackingPlanMappedModel.Properties.get, model.properties, match[1]);
              if (match$1 !== undefined && match$2 !== undefined) {
                return {
                        propertyBundleName: match$1.name,
                        propertyName: match$2.name
                      };
              }
              
            })), 0);
  if (maybePropertyRemovalChange !== undefined) {
    return maybePropertyRemovalChange;
  } else {
    return Belt_Array.get(Belt_Array.keepMapU(actions, (function (param) {
                      var action = param[0];
                      if (typeof action !== "object") {
                        return ;
                      }
                      if (action.NAME !== "UpdatePropertyType") {
                        return ;
                      }
                      var propertyId = action.VAL[0];
                      var maybeProperty = Curry._2(TrackingPlanMappedModel.Properties.get, model.properties, propertyId);
                      if (maybeProperty === undefined) {
                        return ;
                      }
                      var maybeBundle = Belt_Array.getBy(propertyBundlesConnectedToAdobeExperiencePlatform, (function (bundle) {
                              return Belt_List.someU(bundle.properties, (function (bundlePropertyId) {
                                            return bundlePropertyId === propertyId;
                                          }));
                            }));
                      if (maybeBundle !== undefined) {
                        return {
                                propertyBundleName: maybeBundle.name,
                                propertyName: maybeProperty.name
                              };
                      }
                      
                    })), 0);
  }
}

export {
  getFirstBreakingChangeInfo ,
}
/* AvoModel Not a pure module */
