// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Router from "./Router.mjs";
import * as $$Comment from "./Comment.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AvoConfigTypes from "../../shared/models/AvoConfigTypes.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as ObjectActivityLogAction from "./ObjectActivityLogAction.mjs";

function PropertyActivityItem(Props) {
  var $$event = Props.event;
  var property = Props.property;
  var model = Props.model;
  var action = Props.action;
  var userId = Props.userId;
  var userIds = Props.userIds;
  var goToId = Props.goToId;
  var openBranches = Props.openBranches;
  var showComments = Props.showComments;
  var itemName = "event";
  var users = FirebaseFetcherHooks.useUsers(userIds);
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  if (typeof match === "object") {
    var variant = match.NAME;
    if (variant === "AddProperty") {
      var $$event$1 = ModelUtils.getEventByIdWithArchive(match.VAL[0], model);
      if ($$event$1 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "created the property and added it to the event ", React.createElement("b", undefined, $$event$1.name));
      } else {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "created the property"
                  });
      }
    }
    if (variant === "DeprecatedRemovePropertyValidationMatch") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "removed the allowed value ", React.createElement("b", undefined, match.VAL[1]));
    }
    if (variant === "TogglePropertyValidationMatchSource") {
      var match$1 = match.VAL;
      var source = ModelUtils.getSourceById(match$1[2], model);
      if (source !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, match$1[3] ? "enabled " : "disabled ", React.createElement("b", undefined, match$1[1]), " to be sent as the value of the property from ", React.createElement("b", undefined, AvoConfig.getSourceName(source)));
      } else {
        return null;
      }
    }
    if (variant === "RemoveEventSpecificPropertyValueForAllEvents") {
      var literal = match.VAL[1];
      if (typeof literal === "object" && literal.NAME === "StringLit") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "removed the allowed value ", React.createElement("b", undefined, literal.VAL));
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertyUniqueName") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "set unique name to " + match.VAL[1]
                });
    }
    if (variant === "RemovePropertyPermanently") {
      return React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: "deleted the property"
                });
    }
    if (variant === "RemovePropertyFromWhitelist") {
      var match$2 = match.VAL;
      var $$event$2 = ModelUtils.getEventByIdWithArchive(match$2[0], model);
      if ($$event$2 !== undefined) {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "stopped sending the property to ", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$2[2])), "with the " + itemName + " ", React.createElement("b", undefined, $$event$2.name));
      } else {
        return null;
      }
    }
    if (!(variant === "CompletedImport" || variant === "ImportDeprecated")) {
      if (variant === "CreateDemoBranch") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "created the property on a demo branch"
                  });
      }
      if (variant === "DeprecatedAddPropertyValidationMatch") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "added the allowed value ", React.createElement("b", undefined, match.VAL[1]));
      }
      if (variant === "DeprecatedRemovePropertyValidationMatches") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "removed all matches validation"
                  });
      }
      if (variant === "UpdatePropertyList") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "changed the property to ", React.createElement("b", undefined, match.VAL[1] ? "list" : "single item"));
      }
      if (variant === "UpdatePropertyName") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "updated the name to " + match.VAL[1]
                  });
      }
      if (variant === "UpdatePropertyDescription") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "updated the description to " + match.VAL[1]
                  });
      }
      if (variant === "ClearAllEventSpecificPropertyValuesFromProperty") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "cleared all allowed values on the property"
                  });
      }
      if (variant === "UpdatePropertySendAs") {
        var sendAs = match.VAL[1];
        var tmp;
        if (typeof sendAs !== "object") {
          switch (sendAs) {
            case "SystemProperty" :
                tmp = "system property";
                break;
            case "EventProperty" :
                tmp = "event property";
                break;
            case "UserProperty" :
                tmp = "user property";
                break;
            
          }
        } else {
          tmp = sendAs._0 + " group property";
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "changed the property to ", React.createElement("b", undefined, tmp));
      }
      if (variant === "AddRule") {
        var match$3 = match.VAL;
        var match$4 = match$3[1];
        if (typeof match$4 !== "object") {
          return null;
        }
        if (match$4.NAME !== "Property") {
          return null;
        }
        var match$5 = match$3[2];
        if (typeof match$5 !== "object") {
          return null;
        }
        var variant$1 = match$5.NAME;
        if (variant$1 === "NameMapping") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "added a name mapping"
                    });
        } else if (variant$1 === "NameInLocalWorkspace") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "added a name mapping"
                    });
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyType") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "set the type to ", React.createElement("b", undefined, match.VAL[1]));
      }
      if (variant === "AddPropertyRef") {
        var eventId = match.VAL[0];
        var $$event$3 = ModelUtils.getEventByIdWithArchive(eventId, model);
        if ($$event$3 !== undefined) {
          return React.createElement(ObjectActivityLogAction.make, {
                      action: action,
                      users: users$1,
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "event",
                            VAL: [
                              eventId,
                              undefined,
                              undefined,
                              false
                            ]
                          }),
                      openBranches: openBranches,
                      children: null
                    }, "added property to the " + itemName + " ", React.createElement("b", undefined, $$event$3.name));
        } else {
          return null;
        }
      }
      if (variant === "ConvertPropertyToGlobalPropertyV2" || variant === "ConvertPropertyToGlobalProperty") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "merged the property with global requirements"
                  });
      }
      if (variant === "UpdatePropertyRegexValidation") {
        var match$6 = match.VAL;
        var eventId$1 = match$6[1];
        if (eventId$1 !== undefined) {
          var match$7 = match$6[2];
          if (match$7 !== undefined) {
            var $$event$4 = ModelUtils.getEventByIdWithArchive(eventId$1, model);
            if ($$event$4 !== undefined) {
              return React.createElement(ObjectActivityLogAction.Content.make, {
                          action: action,
                          users: users$1,
                          openBranches: openBranches,
                          children: null
                        }, "updated the regex constraint on the event ", React.createElement("b", undefined, $$event$4.name), " to ", React.createElement("b", undefined, match$7.regex));
            } else {
              return null;
            }
          }
          var $$event$5 = ModelUtils.getEventByIdWithArchive(eventId$1, model);
          if ($$event$5 !== undefined) {
            return React.createElement(ObjectActivityLogAction.Content.make, {
                        action: action,
                        users: users$1,
                        openBranches: openBranches,
                        children: null
                      }, "removed the regex constraint on the event ", React.createElement("b", undefined, $$event$5.name));
          } else {
            return null;
          }
        }
        var match$8 = match$6[2];
        if (match$8 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "set the regex constraint to ", React.createElement("b", undefined, match$8.regex));
        } else {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "removed the regex constraint "
                    });
        }
      }
      if (variant === "RemoveEventSpecificPropertyValue") {
        var match$9 = match.VAL;
        var sources = match$9[3];
        var literal$1 = match$9[2];
        var eventId$2 = match$9[0];
        var match$10 = ModelUtils.getEventByIdWithArchive(eventId$2, model);
        if (typeof literal$1 !== "object") {
          return null;
        }
        if (literal$1.NAME !== "StringLit") {
          return null;
        }
        var match$11 = literal$1.VAL;
        if (match$10 !== undefined) {
          var sourcesAreAllEventSources = Belt_SetString.eq(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(match$10.includeSources, (function (param) {
                              return param.id;
                            })))), sources);
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "removed the allowed value ", React.createElement("b", undefined, match$11), " on the event ", React.createElement("b", undefined, match$10.name), sourcesAreAllEventSources ? null : React.createElement(React.Fragment, undefined, " for ", React.createElement("b", undefined, Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(sources), (function (sourceId) {
                                            return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                                          })), AvoConfig.getSourceName).join(", "))));
        }
        if (eventId$2 !== TrackingPlanModel.propertyAbsenceAllEventsKey) {
          return null;
        }
        var sourcesAreAllEventSources$1 = Belt_SetString.eq(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(model.sources, (function (param) {
                            return param.id;
                          })))), sources);
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "removed the allowed value ", React.createElement("b", undefined, match$11), sourcesAreAllEventSources$1 ? null : React.createElement(React.Fragment, undefined, " for ", React.createElement("b", undefined, Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(sources), (function (sourceId) {
                                          return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                                        })), AvoConfig.getSourceName).join(", "))));
      }
      if (variant === "Archive") {
        var match$12 = match.VAL;
        if (typeof match$12 === "object" && match$12.NAME === "Property") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "archived the property"
                    });
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyRefV3" || variant === "RemovePropertyRefV2Deprecated") {
        var eventId$3 = match.VAL[0];
        var $$event$6 = ModelUtils.getEventByIdWithArchive(eventId$3, model);
        if ($$event$6 !== undefined) {
          return React.createElement(ObjectActivityLogAction.make, {
                      action: action,
                      users: users$1,
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "event",
                            VAL: [
                              eventId$3,
                              undefined,
                              undefined,
                              false
                            ]
                          }),
                      openBranches: openBranches,
                      children: null
                    }, "removed property from the " + itemName + " ", React.createElement("b", undefined, $$event$6.name));
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyOperation") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "made the property ", React.createElement("b", undefined, AvoConfig.operationLabel(match.VAL[1])));
      }
      if (variant === "TogglePropertyExcludedSource") {
        var match$13 = match.VAL;
        var source$1 = ModelUtils.getSourceById(match$13[1], model);
        if (source$1 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, (
                      match$13[2] ? "started " : "stopped "
                    ) + "sending property to ", React.createElement("b", undefined, AvoConfig.getSourceName(source$1)));
        } else {
          return null;
        }
      }
      if (variant === "SetEventSpecificPropertyValueSources") {
        var match$14 = match.VAL;
        var removedSources = match$14[4];
        var addedSources = match$14[3];
        var literal$2 = match$14[2];
        var eventId$4 = match$14[0];
        var match$15 = ModelUtils.getEventByIdWithArchive(eventId$4, model);
        if (typeof literal$2 !== "object") {
          return null;
        }
        if (literal$2.NAME !== "StringLit") {
          return null;
        }
        var match$16 = literal$2.VAL;
        if (match$15 !== undefined) {
          var tmp$1;
          if (Belt_SetString.isEmpty(addedSources)) {
            tmp$1 = null;
          } else {
            var sources$1 = Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(addedSources), (function (sourceId) {
                          return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                        })), AvoConfig.getSourceName).join(", ");
            tmp$1 = React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "added the allowed value ", React.createElement("b", undefined, match$16), " on the event ", React.createElement("b", undefined, match$15.name), " for ", React.createElement("b", undefined, sources$1));
          }
          var tmp$2;
          if (Belt_SetString.isEmpty(removedSources)) {
            tmp$2 = null;
          } else {
            var sources$2 = Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(removedSources), (function (sourceId) {
                          return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                        })), AvoConfig.getSourceName).join(", ");
            tmp$2 = React.createElement(ObjectActivityLogAction.Content.make, {
                  action: action,
                  users: users$1,
                  openBranches: openBranches,
                  children: null
                }, "removed the allowed value ", React.createElement("b", undefined, match$16), " on the event ", React.createElement("b", undefined, match$15.name), " for ", React.createElement("b", undefined, sources$2));
          }
          return React.createElement(React.Fragment, undefined, tmp$1, tmp$2);
        }
        if (eventId$4 !== TrackingPlanModel.propertyAbsenceAllEventsKey) {
          return null;
        }
        var tmp$3;
        if (Belt_SetString.isEmpty(addedSources)) {
          tmp$3 = null;
        } else {
          var sources$3 = Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(addedSources), (function (sourceId) {
                        return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                      })), AvoConfig.getSourceName).join(", ");
          tmp$3 = React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: null
              }, "added the allowed value ", React.createElement("b", undefined, match$16), " for ", React.createElement("b", undefined, sources$3));
        }
        var tmp$4;
        if (Belt_SetString.isEmpty(removedSources)) {
          tmp$4 = null;
        } else {
          var sources$4 = Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(removedSources), (function (sourceId) {
                        return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                      })), AvoConfig.getSourceName).join(", ");
          tmp$4 = React.createElement(ObjectActivityLogAction.Content.make, {
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: null
              }, "removed the allowed value ", React.createElement("b", undefined, match$16), " for ", React.createElement("b", undefined, sources$4));
        }
        return React.createElement(React.Fragment, undefined, tmp$3, tmp$4);
      }
      if (variant === "DeprecatedUpdatePropertyValidationMinOrMax") {
        var match$17 = match.VAL;
        var validation = TrackingPlanModel.constraint_ToJs(match$17[2]).toLowerCase();
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "set " + validation + " constraint to ", React.createElement("b", undefined, match$17[1]));
      }
      if (variant === "UpdatePropertyOptional") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "made the property ", React.createElement("b", undefined, match.VAL[1] ? "optional" : "required"));
      }
      if (variant === "ClearEventVariantSpecificPropertyValueOverride" || variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources" || variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed") {
        return null;
      }
      if (variant === "CreateProperty" || variant === "AddSystemProperty") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "created the property"
                  });
      }
      if (variant === "UpdateRule") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "updated a name mapping"
                  });
      }
      if (variant === "AddEventSpecificPropertyValue") {
        var match$18 = match.VAL;
        var sources$5 = match$18[3];
        var literal$3 = match$18[2];
        var eventId$5 = match$18[0];
        var match$19 = ModelUtils.getEventByIdWithArchive(eventId$5, model);
        if (typeof literal$3 !== "object") {
          return null;
        }
        if (literal$3.NAME !== "StringLit") {
          return null;
        }
        var match$20 = literal$3.VAL;
        if (match$19 !== undefined) {
          var sourcesAreAllEventSources$2 = Belt_SetString.eq(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(match$19.includeSources, (function (param) {
                              return param.id;
                            })))), sources$5);
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "added the allowed value ", React.createElement("b", undefined, match$20), " on the event ", React.createElement("b", undefined, match$19.name), sourcesAreAllEventSources$2 ? null : React.createElement(React.Fragment, undefined, " for ", React.createElement("b", undefined, Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(sources$5), (function (sourceId) {
                                            return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                                          })), AvoConfig.getSourceName).join(", "))));
        }
        if (eventId$5 !== TrackingPlanModel.propertyAbsenceAllEventsKey) {
          return null;
        }
        var sourcesAreAllEventSources$3 = Belt_SetString.eq(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(model.sources, (function (param) {
                            return param.id;
                          })))), sources$5);
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "added the allowed value ", React.createElement("b", undefined, match$20), sourcesAreAllEventSources$3 ? null : React.createElement(React.Fragment, undefined, " for ", React.createElement("b", undefined, Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(sources$5), (function (sourceId) {
                                          return ModelUtils.getSourceByIdWithArchive(sourceId, model);
                                        })), AvoConfig.getSourceName).join(", "))));
      }
      if (variant === "AddEventSpecificPropertyValueForAllEvents") {
        var literal$4 = match.VAL[1];
        if (typeof literal$4 === "object" && literal$4.NAME === "StringLit") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "added the allowed value ", React.createElement("b", undefined, literal$4.VAL));
        } else {
          return null;
        }
      }
      if (variant === "AddPropertyToWhitelist") {
        var match$21 = match.VAL;
        var $$event$7 = ModelUtils.getEventByIdWithArchive(match$21[0], model);
        if ($$event$7 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "started sending the property to ", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$21[2])), "with the " + itemName + " ", React.createElement("b", undefined, $$event$7.name));
        } else {
          return null;
        }
      }
      if (variant === "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "allowed all values on all events and sources"
                  });
      }
      if (variant === "CopyChangesToBranch") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "copied changes from ", React.createElement("b", undefined, match.VAL[1]));
      }
      if (variant === "RemovePropertyFromGroup") {
        var group = ModelUtils.getPropertyBundleByIdWithArchive(match.VAL[0], model);
        if (group !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "removed property from the group ", React.createElement("b", undefined, group.name));
        } else {
          return null;
        }
      }
      if (variant === "UpdatePropertyPinnedValue") {
        var match$22 = match.VAL;
        var $$event$8 = ModelUtils.getEventById(match$22[0], model);
        if ($$event$8 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "pinned the value on ", React.createElement("b", undefined, $$event$8.name), " to ", React.createElement("b", undefined, PropertyUtils.printPinnedValue(match$22[2])));
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyValidation") {
        var validation$1 = match.VAL[1];
        var variant$2 = validation$1.NAME;
        var tmp$5;
        if (variant$2 === "EndsWith" || variant$2 === "NestedProperty" || variant$2 === "Contains" || variant$2 === "StartsWith") {
          tmp$5 = null;
        } else if (variant$2 === "Regex") {
          tmp$5 = React.createElement(React.Fragment, undefined, "removed the regex constraint ", React.createElement("b", undefined, Belt_Option.mapWithDefault(validation$1.VAL.propertyRule, null, (function (param) {
                          return param.regex;
                        }))));
        } else if (variant$2 === "Max") {
          var match$23 = validation$1.VAL;
          if (typeof match$23 === "object") {
            var variant$3 = match$23.NAME;
            tmp$5 = variant$3 === "IntLit" || variant$3 === "FloatLit" ? React.createElement(React.Fragment, undefined, "removed the max ", React.createElement("b", undefined, match$23.VAL.toString()), " constraint") : null;
          } else {
            tmp$5 = null;
          }
        } else if (variant$2 === "Min") {
          var match$24 = validation$1.VAL;
          if (typeof match$24 === "object") {
            var variant$4 = match$24.NAME;
            tmp$5 = variant$4 === "IntLit" || variant$4 === "FloatLit" ? React.createElement(React.Fragment, undefined, "removed the min ", React.createElement("b", undefined, match$24.VAL.toString()), " constraint") : null;
          } else {
            tmp$5 = null;
          }
        } else if (variant$2 === "Shape") {
          var match$25 = validation$1.VAL;
          tmp$5 = match$25 && !match$25.tl ? React.createElement(React.Fragment, undefined, "removed the object key ", React.createElement("b", undefined, match$25.hd.key)) : null;
        } else {
          var match$26 = validation$1.VAL;
          if (match$26) {
            var match$27 = match$26.hd[0].VAL;
            tmp$5 = typeof match$27 === "object" && match$27.NAME === "StringLit" && !match$26.tl ? React.createElement(React.Fragment, undefined, "removed the allowed value ", React.createElement("b", undefined, match$27.VAL)) : null;
          } else {
            tmp$5 = "removed all constraints";
          }
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: tmp$5
                  });
      }
      if (variant === "AddPropertyValidation") {
        var validation$2 = match.VAL[1];
        var variant$5 = validation$2.NAME;
        var tmp$6;
        if (variant$5 === "EndsWith" || variant$5 === "Contains" || variant$5 === "StartsWith") {
          tmp$6 = null;
        } else if (variant$5 === "Regex") {
          var match$28 = validation$2.VAL.propertyRule;
          tmp$6 = match$28 !== undefined ? React.createElement(React.Fragment, undefined, "set the regex constraint to ", React.createElement("b", undefined, match$28.regex)) : "set an event-specific regex constraint";
        } else if (variant$5 === "Max") {
          var match$29 = validation$2.VAL;
          if (typeof match$29 === "object") {
            var variant$6 = match$29.NAME;
            tmp$6 = variant$6 === "IntLit" || variant$6 === "FloatLit" ? React.createElement(React.Fragment, undefined, "set max constraint to ", React.createElement("b", undefined, match$29.VAL.toString())) : null;
          } else {
            tmp$6 = null;
          }
        } else if (variant$5 === "Min") {
          var match$30 = validation$2.VAL;
          if (typeof match$30 === "object") {
            var variant$7 = match$30.NAME;
            tmp$6 = variant$7 === "IntLit" || variant$7 === "FloatLit" ? React.createElement(React.Fragment, undefined, "set min constraint to ", React.createElement("b", undefined, match$30.VAL.toString())) : null;
          } else {
            tmp$6 = null;
          }
        } else if (variant$5 === "NestedProperty") {
          var match$31 = validation$2.VAL;
          tmp$6 = match$31 ? (
              match$31.tl ? null : React.createElement(React.Fragment, undefined, "added ", React.createElement("b", undefined, Belt_Option.getWithDefault(ModelUtils.getPropertyNameById(match$31.hd.id, model), "unknown property")), " to object")
            ) : "added object validation";
        } else if (variant$5 === "Shape") {
          var match$32 = validation$2.VAL;
          if (match$32 && !match$32.tl) {
            var item = match$32.hd;
            tmp$6 = React.createElement(React.Fragment, undefined, "set the object key ", React.createElement("b", undefined, item.key), " to be a " + PropertyUtils.printValue(item));
          } else {
            tmp$6 = "added object validation";
          }
        } else {
          var match$33 = validation$2.VAL;
          if (match$33) {
            var match$34 = match$33.hd[0].VAL;
            tmp$6 = typeof match$34 === "object" && match$34.NAME === "StringLit" && !match$33.tl ? React.createElement(React.Fragment, undefined, "added the allowed value ", React.createElement("b", undefined, match$34.VAL)) : null;
          } else {
            tmp$6 = null;
          }
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: tmp$6
                  });
      }
      if (variant === "AddEventSpecificPropertyValueForSomeEvents") {
        var match$35 = match.VAL;
        var literal$5 = match$35[1];
        var match$36 = ModelUtils.getPropertyByIdWithArchive(match$35[0], model);
        var formattedTextChunks = match$36 !== undefined ? (
            match$36.TAG === "PropertyRef" ? [] : (
                typeof literal$5 === "object" ? (
                    literal$5.NAME === "StringLit" ? [
                        {
                          TAG: "Plain",
                          _0: "added the allowed value "
                        },
                        {
                          TAG: "Bold",
                          _0: literal$5.VAL
                        },
                        {
                          TAG: "Plain",
                          _0: " on some events"
                        }
                      ] : []
                  ) : []
              )
          ) : [];
        if (formattedTextChunks.length !== 0) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: Belt_Array.mapU(formattedTextChunks, (function (textChunk) {
                              if (textChunk.TAG === "Plain") {
                                var text = textChunk._0;
                                return React.createElement(React.Fragment, {
                                            children: text,
                                            key: text
                                          });
                              }
                              var text$1 = textChunk._0;
                              return React.createElement("b", {
                                          key: text$1
                                        }, text$1);
                            }))
                    });
        } else {
          return null;
        }
      }
      if (variant === "ClearPropertyExcludedSources") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "started sending the property to ", React.createElement("b", undefined, "all sources"));
      }
      if (variant === "DeprecatedRemovePropertyValidationMinOrMax") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "removed min or max constraint"
                  });
      }
      if (variant === "PropertyComment") {
        if (!showComments) {
          return null;
        }
        var match$37 = match.VAL;
        var propertyId = match$37[0];
        var tmp$7 = {
          threadId: propertyId,
          itemType: "Property",
          itemName: property.name,
          commentId: match$37[1],
          events: model.events,
          userId: userId,
          goToId: goToId,
          action: action,
          link: (function (commentId) {
              return Router.Link.addDrawerItem(undefined, {
                          NAME: "property",
                          VAL: [
                            propertyId,
                            commentId
                          ]
                        });
            }),
          openBranches: openBranches
        };
        var tmp$8 = Belt_Option.map($$event, (function ($$event) {
                return $$event.id;
              }));
        if (tmp$8 !== undefined) {
          tmp$7.eventId = Caml_option.valFromOption(tmp$8);
        }
        return React.createElement($$Comment.make, tmp$7);
      }
      if (variant === "UpdatePropertyOptionalWhenInObject") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "made the property ", React.createElement("b", undefined, match.VAL[1] ? "optional" : "required"), " when in object");
      }
      if (variant === "AddPropertyToGroup") {
        var group$1 = ModelUtils.getPropertyBundleByIdWithArchive(match.VAL[0], model);
        if (group$1 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "added property to the group ", React.createElement("b", undefined, group$1.name));
        } else {
          return null;
        }
      }
      if (variant === "RemovePropertyPinnedValue") {
        var $$event$9 = ModelUtils.getEventById(match.VAL[0], model);
        if ($$event$9 !== undefined) {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: null
                    }, "removed the pinned value on ", React.createElement("b", undefined, $$event$9.name));
        } else {
          return null;
        }
      }
      if (variant === "DeprecatedAddPropertyValidationMinOrMax") {
        var validation$3 = TrackingPlanModel.constraint_ToJs(match.VAL[1]).toLowerCase();
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "added " + validation$3 + " constraint"
                  });
      }
      if (variant === "UpdatePropertyAbsence") {
        var match$38 = match.VAL;
        var maybeEventSourceAbsence = match$38[2];
        var propertyAbsenceUpdateType = match$38[1];
        var tmp$9;
        tmp$9 = maybeEventSourceAbsence !== undefined ? (
            typeof maybeEventSourceAbsence !== "object" ? "never sent" : "sometimes sent"
          ) : "always sent";
        var tmp$10;
        if (typeof propertyAbsenceUpdateType !== "object") {
          tmp$10 = React.createElement(React.Fragment, undefined, " on ", React.createElement("b", undefined, "all events"));
        } else {
          switch (propertyAbsenceUpdateType.TAG) {
            case "Event" :
                var eventId$6 = propertyAbsenceUpdateType._0;
                if (eventId$6 === TrackingPlanModel.propertyAbsenceAllEventsKey) {
                  tmp$10 = React.createElement(React.Fragment, undefined, " on ", React.createElement("b", undefined, "all events"));
                } else {
                  var $$event$10 = ModelUtils.getEventByIdWithArchive(eventId$6, model);
                  tmp$10 = $$event$10 !== undefined ? React.createElement(React.Fragment, undefined, " on the ", React.createElement("b", undefined, $$event$10.name), " event") : " on one event";
                }
                break;
            case "Source" :
                var source$2 = ModelUtils.getSourceByIdWithArchive(propertyAbsenceUpdateType._0, model);
                tmp$10 = source$2 !== undefined ? React.createElement(React.Fragment, undefined, " from the ", React.createElement("b", undefined, AvoConfig.getSourceName(source$2)), " source") : " from one source";
                break;
            case "EventSource" :
                var sourceId = propertyAbsenceUpdateType._1;
                var eventId$7 = propertyAbsenceUpdateType._0;
                if (eventId$7 === TrackingPlanModel.propertyAbsenceAllEventsKey) {
                  var source$3 = ModelUtils.getSourceByIdWithArchive(sourceId, model);
                  tmp$10 = source$3 !== undefined ? React.createElement(React.Fragment, undefined, " on all events when sent from the ", React.createElement("b", undefined, AvoConfig.getSourceName(source$3)), " source") : " on all events on one source";
                } else {
                  var match$39 = ModelUtils.getEventByIdWithArchive(eventId$7, model);
                  var match$40 = ModelUtils.getSourceByIdWithArchive(sourceId, model);
                  tmp$10 = match$39 !== undefined && match$40 !== undefined ? React.createElement(React.Fragment, undefined, " on the ", React.createElement("b", undefined, match$39.name), " event when sent from the ", React.createElement("b", undefined, AvoConfig.getSourceName(match$40)), " source") : " on one event and source";
                }
                break;
            
          }
        }
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: null
                  }, "set the property to be ", React.createElement("b", undefined, tmp$9), tmp$10);
      }
      if (variant !== "Unarchive") {
        if (variant === "RemoveRule") {
          return React.createElement(ObjectActivityLogAction.Content.make, {
                      action: action,
                      users: users$1,
                      openBranches: openBranches,
                      children: "removed a name mapping"
                    });
        } else {
          return null;
        }
      }
      var match$41 = match.VAL;
      if (typeof match$41 === "object" && match$41.NAME === "Property") {
        return React.createElement(ObjectActivityLogAction.Content.make, {
                    action: action,
                    users: users$1,
                    openBranches: openBranches,
                    children: "unarchived the property "
                  });
      } else {
        return null;
      }
    }
    
  } else if (match !== "StartedImport") {
    return null;
  }
  return React.createElement(ObjectActivityLogAction.Content.make, {
              action: action,
              users: users$1,
              openBranches: openBranches,
              children: "imported the property"
            });
}

var make = PropertyActivityItem;

export {
  make ,
}
/* react Not a pure module */
