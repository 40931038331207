// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Issues from "../../shared/models/Issues.mjs";
import * as $$Number from "./Number.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "./DateFns.mjs";
import * as Sidebar from "./Sidebar.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as AvoSelect from "./AvoSelect.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IssuesTable from "./inspector/IssuesTable.mjs";
import * as React$1 from "motion/react";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as InspectorViewApi from "./inspector/InspectorViewApi.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as InspectorIssuesStore from "./inspector/InspectorIssuesStore.mjs";
import * as InspectorIssueViewModel from "./inspector/InspectorIssueViewModel.mjs";

var container = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.bottom(Css.px(32)),
        tl: {
          hd: Css.left(Css.px(Sidebar.sidebarWidth)),
          tl: {
            hd: Css.right(Css.px(0)),
            tl: {
              hd: Css.pointerEvents("none"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.zIndex(1),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var bar = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(100)),
      tl: {
        hd: Css.paddingTop(Css.px(7)),
        tl: {
          hd: Css.paddingRight(Css.px(20)),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: {
              hd: Css.paddingLeft(Css.px(16)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.light12),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.gap(Css.px(12)),
                      tl: {
                        hd: Css.pointerEvents("auto"),
                        tl: {
                          hd: Css.boxShadow(Styles.Shadow.subtle),
                          tl: {
                            hd: Css.color(Styles.Color.dark12),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var iconButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.height(Css.px(28)),
                    tl: {
                      hd: Css.width(Css.px(28)),
                      tl: {
                        hd: Css.border(Css.px(1), Css.solid, "transparent"),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.marginTop(Css.px(1)),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$short
                                  }, undefined, undefined, "border-color"),
                              tl: {
                                hd: Css.selector(" path", {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$short
                                          }, undefined, undefined, "fill"),
                                      tl: {
                                        hd: Css_Legacy_Core.SVG.fill(Styles.Color.dark11),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.hover({
                                        hd: Icon.color(Styles.Color.dark12),
                                        tl: {
                                          hd: Css.borderColor(Styles.Color.dark06),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var Style = {
  container: container,
  bar: bar,
  iconButton: iconButton
};

function InspectorIssuesView__BulkBar$BarContentWrapper(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: bar
            }, children);
}

var BarContentWrapper = {
  make: InspectorIssuesView__BulkBar$BarContentWrapper
};

function InspectorIssuesView__BulkBar$BarOptionsWrapper(Props) {
  var children = Props.children;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.height(Css.px(16)),
                        tl: {
                          hd: Css.width(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light11),
                            tl: /* [] */0
                          }
                        }
                      })
                }), children);
}

var BarOptionsWrapper = {
  make: InspectorIssuesView__BulkBar$BarOptionsWrapper
};

var FloatingSelect = AvoSelect.MakeFloatingSelect({});

function useOnUpdate(status) {
  var schemaBundle = SchemaBundleContext.use();
  var workspace = WorkspaceContext.use();
  var openModal = GlobalSendContext.useOpenModal();
  var userId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app().auth().currentUser)).uid;
  var updateIssues = InspectorIssuesStore.useUpdateIssues();
  var addToast = Toast.useAddToast();
  var match = InspectorIssuesStore.useFilteredIssues(status, undefined, undefined);
  var filteredIssues = match.filteredIssues;
  var match$1 = Curry._1(IssuesTable.IssuesTable.BulkContext.use, undefined);
  var clearAll = match$1.clearAll;
  var selectedItemIds = match$1.selectedItemIds;
  var selectedIssues = React.useMemo((function (param) {
          return Curry._2(Issues.keep, filteredIssues, (function (issue) {
                        return Belt_SetString.has(selectedItemIds, issue.id);
                      }));
        }), [
        selectedItemIds,
        filteredIssues
      ]);
  return async function (value) {
    if (workspace.isSandbox) {
      return Curry._1(openModal, "SandboxLimitation");
    }
    var issuesCopy = Curry._2(Issues.mapToArray, selectedIssues, InspectorIssueViewModel.copy);
    var newStatus;
    if (value !== undefined) {
      if (typeof value === "object") {
        var intent = value.VAL;
        newStatus = typeof intent === "object" || intent === "nextAppVersion" || intent === "currentAppVersion" ? Pervasives.failwith("Unsupported issue status multi-update") : (
            intent === "in1Hour" ? ({
                  TAG: "Resolved",
                  _0: {
                    TAG: "Date",
                    _0: DateFns.addHours(1.0, new Date())
                  }
                }) : ({
                  TAG: "Resolved",
                  _0: {
                    TAG: "Date",
                    _0: DateFns.addHours(24.0, new Date())
                  }
                })
          );
      } else {
        newStatus = value === "unresolved" ? "Unresolved" : ({
              TAG: "Ignored",
              _0: "Never"
            });
      }
    } else {
      newStatus = undefined;
    }
    if (newStatus === undefined) {
      return Curry._1(addToast, {
                  message: "Failed to update status for issues",
                  toastType: "Error",
                  persist: true
                });
    }
    var optimisticallyUpdatedIssues = Curry._2(Issues.mapToArray, selectedIssues, (function (issue) {
            return {
                    id: issue.id,
                    sharedId: issue.sharedId,
                    issueType: issue.issueType,
                    issueStatus: {
                      status: newStatus,
                      updatedAt: Caml_option.some(new Date()),
                      updatedBy: userId
                    },
                    eventName: issue.eventName,
                    propertyName: issue.propertyName,
                    source: issue.source,
                    categories: issue.categories,
                    tags: issue.tags,
                    firstSeen: issue.firstSeen,
                    lastSeen: issue.lastSeen,
                    eventCount: issue.eventCount,
                    issueCount: issue.issueCount,
                    newestAppVersion: issue.newestAppVersion,
                    oldestAppVersion: issue.oldestAppVersion,
                    regression: issue.regression
                  };
          }));
    Curry._1(updateIssues, optimisticallyUpdatedIssues);
    try {
      if (newStatus !== undefined) {
        await InspectorViewApi.multiUpdateIssueStatus(newStatus, "user", Belt_SetString.toArray(selectedItemIds));
        var arg;
        if (value !== undefined) {
          if (typeof value === "object") {
            var match = value.VAL;
            arg = match === "in24Hours" ? "ResolveIn24Hours" : (
                match === "in1Hour" ? "ResolveIn1Hour" : Pervasives.failwith("Unsupported issue status multi-update (Analytics)")
              );
          } else {
            arg = value === "unresolved" ? "Unresolve" : "Ignore";
          }
        } else {
          arg = Pervasives.failwith("Unsupported issue status multi-update (Analytics)");
        }
        var arg$1 = Belt_SetString.size(selectedItemIds);
        var arg$2 = schemaBundle.schemaId;
        var arg$3 = schemaBundle.branchId;
        AnalyticsUtils.withSchemaBundle((function (param) {
                var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
                  return AnalyticsRe.issuesBulkUpdated(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14);
                };
                return function (param) {
                  var func$1 = Curry._1(func, param);
                  return function (param) {
                    var func$2 = Curry._1(func$1, param);
                    return function (param) {
                      var func$3 = Curry._1(func$2, param);
                      return function (param) {
                        var func$4 = Curry._1(func$3, param);
                        return function (param) {
                          var func$5 = Curry._1(func$4, param);
                          return function (param) {
                            var func$6 = Curry._1(func$5, param);
                            return function (param) {
                              var func$7 = Curry._1(func$6, param);
                              return function (param) {
                                var func$8 = Curry._1(func$7, param);
                                return function (param) {
                                  var func$9 = Curry._1(func$8, param);
                                  return function (param) {
                                    return Curry._5(func$9, param, arg, arg$1, arg$2, arg$3);
                                  };
                                };
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                };
              }), schemaBundle);
        Curry._1(clearAll, undefined);
        return Curry._1(addToast, {
                    message: "Successfully updated status for " + AvoUtils.plural(undefined, undefined, Belt_SetString.size(selectedItemIds), "issue"),
                    toastType: "Success"
                  });
      }
      Curry._1(updateIssues, issuesCopy);
      return Curry._1(addToast, {
                  message: "Failed to update status for issues",
                  toastType: "Error",
                  persist: true
                });
    }
    catch (_e){
      Curry._1(updateIssues, issuesCopy);
      return Curry._1(addToast, {
                  message: "Failed to update status for issues",
                  toastType: "Error",
                  persist: true
                });
    }
  };
}

function InspectorIssuesView__BulkBar$Ignore(Props) {
  var blocked = Props.blocked;
  var status = Props.status;
  var openModal = GlobalSendContext.useOpenModal();
  var onUpdate = useOnUpdate(status);
  var confirmModal_1 = [
    "Ignore selected issues",
    "Are you sure you want to ignore these issues? You can revert their status later from the Ignored section.",
    "Proceed",
    (function (param) {
        Curry._1(onUpdate, "ignored");
      }),
    (function (param) {
        
      })
  ];
  var confirmModal = {
    NAME: "ConfirmModal",
    VAL: confirmModal_1
  };
  if (status === "Unresolved") {
    return React.createElement(Button.make, {
                disabled: blocked,
                label: "Ignore",
                onClick: (function (param) {
                    if (blocked) {
                      return ;
                    } else {
                      return Curry._1(openModal, confirmModal);
                    }
                  }),
                size: "tiny",
                style: {
                  NAME: "custom",
                  VAL: {
                    customColor: Styles.Color.dark12,
                    hoverColor: Styles.Color.dark11,
                    customBackgroundColor: "transparent",
                    customBackgroundHoverColor: "transparent",
                    optionalCustomOutlineColor: undefined,
                    optionalCustomOutlineHoverColor: undefined
                  }
                }
              });
  } else {
    return null;
  }
}

var Ignore = {
  make: InspectorIssuesView__BulkBar$Ignore
};

function InspectorIssuesView__BulkBar$Revert(Props) {
  var blocked = Props.blocked;
  var status = Props.status;
  var openModal = GlobalSendContext.useOpenModal();
  var onUpdate = useOnUpdate(status);
  var confirmModal_1 = [
    "Mark selected issues as unresolved",
    "Are you sure you want to mark these issues as unresolved? You can revert their status later from the Unresolved section.",
    "Proceed",
    (function (param) {
        Curry._1(onUpdate, "unresolved");
      }),
    (function (param) {
        
      })
  ];
  var confirmModal = {
    NAME: "ConfirmModal",
    VAL: confirmModal_1
  };
  if (status !== "Unresolved") {
    return React.createElement(Button.make, {
                disabled: blocked,
                label: "Unresolve",
                onClick: (function (param) {
                    if (blocked) {
                      return ;
                    } else {
                      return Curry._1(openModal, confirmModal);
                    }
                  }),
                size: "tiny",
                style: {
                  NAME: "custom",
                  VAL: {
                    customColor: Styles.Color.dark12,
                    hoverColor: Styles.Color.dark11,
                    customBackgroundColor: "transparent",
                    customBackgroundHoverColor: "transparent",
                    optionalCustomOutlineColor: undefined,
                    optionalCustomOutlineHoverColor: undefined
                  }
                }
              });
  } else {
    return null;
  }
}

var Revert = {
  make: InspectorIssuesView__BulkBar$Revert
};

function InspectorIssuesView__BulkBar$Resolve(Props) {
  var blocked = Props.blocked;
  var status = Props.status;
  var openModal = GlobalSendContext.useOpenModal();
  var onUpdate = useOnUpdate(status);
  var confirmModal = function (value) {
    Curry._1(openModal, {
          NAME: "ConfirmModal",
          VAL: [
            "Mark issues as resolved",
            "Are you sure you want to resolve these issues? You can revert their status later from the Resolved section.",
            "Proceed",
            (function (param) {
                Curry._1(onUpdate, value);
              }),
            (function (param) {
                
              })
          ]
        });
  };
  return React.createElement(FloatingSelect.Menu.make, {
              label: "Resolve in...",
              onUpdate: confirmModal,
              rootMenuStyles: (function (c) {
                  return Curry._1(Css.merge, {
                              hd: Curry._1(FloatingSelect.Style.rootMenu, c),
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.selector("&.root", {
                                            hd: Css.color(Styles.Color.dark12),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.hover({
                                                      hd: Css.color(Styles.Color.dark11),
                                                      tl: {
                                                        hd: Css.selector(" .root-menu-chevron-down", {
                                                              hd: Icon.color(Styles.Color.dark11),
                                                              tl: /* [] */0
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }
                                          }),
                                      tl: {
                                        hd: Css.selector(" .root-menu-chevron-down", {
                                              hd: Css.transforms({
                                                    hd: Css.rotate(Css.deg(180.0)),
                                                    tl: /* [] */0
                                                  }),
                                              tl: {
                                                hd: Css.height(Css.px(16)),
                                                tl: {
                                                  hd: Icon.color(Styles.Color.dark12),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            });
                }),
              placement: "top",
              children: null
            }, React.createElement(FloatingSelect.MenuItem.make, {
                  label: "in 1 hour",
                  value: {
                    NAME: "resolved",
                    VAL: "in1Hour"
                  },
                  disabled: blocked
                }), React.createElement(FloatingSelect.MenuItem.make, {
                  label: "in 24 hours",
                  value: {
                    NAME: "resolved",
                    VAL: "in24Hours"
                  },
                  disabled: blocked
                }));
}

var Resolve = {
  make: InspectorIssuesView__BulkBar$Resolve
};

function InspectorIssuesView__BulkBar(Props) {
  var status = Props.status;
  var schemaBundle = SchemaBundleContext.use();
  var match = Curry._1(IssuesTable.IssuesTable.BulkContext.use, undefined);
  var clearAll = match.clearAll;
  var workspace = WorkspaceContext.use();
  var blocked = AvoLimits.isInspectorVolumeBlocked(workspace);
  React.useEffect((function (param) {
          Curry._1(clearAll, undefined);
        }), [status]);
  var count = Belt_SetString.size(match.selectedItemIds);
  var isVisible = count > 0;
  var handleClear = function (param) {
    Curry._1(clearAll, undefined);
    var arg = workspace.id;
    var arg$1 = schemaBundle.branchId;
    AnalyticsUtils.withSchemaBundle((function (param) {
            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
              return AnalyticsRe.bulkEditingInteractionIssues(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14);
            };
            return function (param) {
              var func$1 = Curry._1(func, param);
              return function (param) {
                var func$2 = Curry._1(func$1, param);
                return function (param) {
                  var func$3 = Curry._1(func$2, param);
                  return function (param) {
                    var func$4 = Curry._1(func$3, param);
                    return function (param) {
                      var func$5 = Curry._1(func$4, param);
                      return function (param) {
                        var func$6 = Curry._1(func$5, param);
                        return function (param) {
                          var func$7 = Curry._1(func$6, param);
                          return function (param) {
                            var func$8 = Curry._1(func$7, param);
                            return function (param) {
                              var func$9 = Curry._1(func$8, param);
                              return function (param) {
                                return Curry._5(func$9, param, 0, "ClearSelection", arg, arg$1);
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                };
              };
            };
          }), schemaBundle);
  };
  return React.createElement("div", {
              className: container
            }, React.createElement(React$1.AnimatePresence, {
                  children: isVisible ? React.createElement(React$1.motion.div, {
                          animate: {
                            opacity: 1,
                            y: 0
                          },
                          transition: {
                            duration: 0.2
                          },
                          initial: {
                            opacity: 0,
                            y: 10
                          },
                          exit: {
                            opacity: 0,
                            y: 10
                          },
                          key: "InspectorIssues__BulkBar",
                          children: React.createElement(InspectorIssuesView__BulkBar$BarContentWrapper, {
                                children: null
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginTop(Css.px(1)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Small",
                                        color: Styles.Color.dark11,
                                        children: null
                                      }, React.createElement($$Text.make, {
                                            element: "Span",
                                            weight: "Semi",
                                            color: Styles.Color.dark12,
                                            children: $$Number.toLocaleString(count)
                                          }), " selected")), React.createElement("button", {
                                    className: Curry._1(Css.merge, {
                                          hd: iconButton,
                                          tl: {
                                            hd: Curry._1(Css.style, {
                                                  hd: Css.marginLeft(Css.px(-8)),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }),
                                    label: "Clear",
                                    onClick: handleClear
                                  }, React.createElement(Icon.make, {
                                        type_: "close",
                                        size: "medium"
                                      })), React.createElement(InspectorIssuesView__BulkBar$BarOptionsWrapper, {
                                    children: null
                                  }, React.createElement(InspectorIssuesView__BulkBar$Ignore, {
                                        blocked: blocked,
                                        status: status
                                      }), React.createElement(InspectorIssuesView__BulkBar$Revert, {
                                        blocked: blocked,
                                        status: status
                                      }), React.createElement(InspectorIssuesView__BulkBar$Resolve, {
                                        blocked: blocked,
                                        status: status
                                      })))
                        }) : null
                }));
}

var make = InspectorIssuesView__BulkBar;

export {
  Style ,
  BarContentWrapper ,
  BarOptionsWrapper ,
  FloatingSelect ,
  useOnUpdate ,
  Ignore ,
  Revert ,
  Resolve ,
  make ,
}
/* container Not a pure module */
