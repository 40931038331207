// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Modal from "./Modal.mjs";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ServiceAccounts from "./ServiceAccounts.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as WorkspaceDomains from "./WorkspaceDomains.mjs";
import * as WorkspaceMembers from "./WorkspaceMembers.mjs";
import * as MembersInviteForm from "./MembersInviteForm.mjs";
import * as CloudFunctionHooks from "./CloudFunctionHooks.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as WorkspacePermissions from "./WorkspacePermissions.mjs";
import * as WorkspaceNotifications from "./WorkspaceNotifications.mjs";

var tabsStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("flexStart"),
          tl: {
            hd: Css.minWidth(Css.px(500)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light01),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("flexStart"),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: {
                      hd: Css.padding2(Css.px(8), "zero"),
                      tl: {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                          tl: {
                            hd: Css.after({
                                  hd: Css.display("block"),
                                  tl: {
                                    hd: Css.position("absolute"),
                                    tl: {
                                      hd: Css.unsafe("content", "' '"),
                                      tl: {
                                        hd: Css.width(Css.pct(100)),
                                        tl: {
                                          hd: Css.height(Css.px(16)),
                                          tl: {
                                            hd: Css.bottom(Css.px(-16)),
                                            tl: {
                                              hd: Css.pointerEvents("none"),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.avoPink),
                                                tl: {
                                                  hd: Css.background({
                                                        NAME: "linearGradient",
                                                        VAL: [
                                                          Css.deg(0.0),
                                                          [
                                                            [
                                                              Css.pct(0.0),
                                                              Styles.Color.setAlpha(Styles.Color.light12, 0.0)
                                                            ],
                                                            [
                                                              Css.pct(100.0),
                                                              Styles.Color.setAlpha(Styles.Color.light12, 0.07)
                                                            ]
                                                          ]
                                                        ]
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var tabItem = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.backgroundColor("transparent"),
              tl: {
                hd: Css.height(Css.pct(100)),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.justifyContent("flexStart"),
                      tl: {
                        hd: Css.paddingLeft(Css.px(16)),
                        tl: {
                          hd: Css.paddingRight(Css.px(16)),
                          tl: {
                            hd: Css.margin(Css.px(0)),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.medium),
                                tl: {
                                  hd: Css.color(Styles.Color.light10),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.color(Styles.Color.avoPink),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$short
                                          }, undefined, undefined, "color"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var selectedTabItem = Curry._1(Css.merge, {
      hd: tabItem,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.color(Styles.Color.avoPink),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var selectedInnerItem = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.after({
              hd: Css.display("block"),
              tl: {
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.unsafe("content", "' '"),
                  tl: {
                    hd: Css.top(Css.px(24)),
                    tl: {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.height(Css.px(2)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.avoPink),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function tabName(tab) {
  switch (tab) {
    case "Members" :
        return "Members";
    case "Permissions" :
        return "General";
    case "Notifications" :
        return "Slack Integration";
    case "ServiceAccounts" :
        return "Service Accounts";
    case "Domains" :
        return "Domains";
    
  }
}

function SettingsModal(Props) {
  var onClose = Props.onClose;
  var openOnTabOpt = Props.openOnTab;
  var role = Props.role;
  var model = Props.model;
  var openBranches = Props.openBranches;
  var openOnTab = openOnTabOpt !== undefined ? openOnTabOpt : "Members";
  var schemaBundle = SchemaBundleContext.use();
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = React.useState(function (param) {
        return openOnTab;
      });
  var setCurrentTab = match[1];
  var currentTab = match[0];
  React.useEffect((function (param) {
          var tmp;
          switch (currentTab) {
            case "Members" :
                tmp = "Members";
                break;
            case "Permissions" :
                tmp = "General";
                break;
            case "Notifications" :
                tmp = "SlackIntegration";
                break;
            case "ServiceAccounts" :
                tmp = "ServiceAccounts";
                break;
            case "Domains" :
                tmp = "Domains";
                break;
            
          }
          AnalyticsRe.settingsTabOpened(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, tmp, schemaBundle.schemaId);
        }), [currentTab]);
  var billingStats = CloudFunctionHooks.useBillingStats(workspace.id, workspace.members);
  var numCommentOnly = Belt_List.length(Belt_List.keep(workspace.members, (function (member) {
              return Caml_obj.equal(Models.Role.tFromJs(member.role), "CommentOnly");
            })));
  var tmp;
  if (role === "NoAccess" || role === "CommentOnly" || role === "Viewer" || role === "CodegenAccess" || role === "Editor") {
    tmp = undefined;
  } else if (typeof billingStats === "object") {
    var match$1 = billingStats.VAL;
    if (match$1.TAG === "Success") {
      var match$2 = match$1._0;
      var limit = workspace.plan.commentOnlyMembers;
      var tmp$1;
      if (typeof limit !== "object") {
        tmp$1 = limit === "Unlimited" ? " with unlimited comment only seats" : "";
      } else if (limit.TAG === "Limited") {
        var limit$1 = limit._0;
        tmp$1 = "and " + String(numCommentOnly > limit$1 ? limit$1 : numCommentOnly) + " out of " + (String(limit$1) + " comment only seats");
      } else {
        tmp$1 = "";
      }
      tmp = Caml_option.some(React.createElement("span", undefined, "Using " + String(match$2.currentEditorSeats) + " out of " + String(match$2.planEditorSeats) + " editor seats " + tmp$1));
    } else {
      tmp = "Could not fetch plan details";
    }
  } else {
    tmp = billingStats === "Error" ? "Could not fetch plan details" : "...";
  }
  var tabs = role === "Admin" ? [
      "Members",
      "Domains",
      "Permissions",
      "Notifications",
      "ServiceAccounts"
    ] : [
      "Members",
      "Domains"
    ];
  var tmp$2;
  if (role === "Editor") {
    tmp$2 = currentTab === "ServiceAccounts" ? React.createElement(ServiceAccounts.Create.make, {}) : null;
  } else if (role === "Admin") {
    switch (currentTab) {
      case "Members" :
          tmp$2 = React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.backgroundColor(Styles.Color.light01),
                      tl: {
                        hd: Css.padding(Css.px(16)),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light10,
                    children: "Add member"
                  }), React.createElement(Spacer.make, {
                    height: 4
                  }), React.createElement(MembersInviteForm.make, {
                    model: model
                  }));
          break;
      case "ServiceAccounts" :
          tmp$2 = React.createElement(ServiceAccounts.Create.make, {});
          break;
      default:
        tmp$2 = null;
    }
  } else {
    tmp$2 = null;
  }
  var tmp$3;
  switch (currentTab) {
    case "Members" :
        tmp$3 = React.createElement(WorkspaceMembers.make, {
              billingStats: billingStats
            });
        break;
    case "Permissions" :
        tmp$3 = React.createElement(WorkspacePermissions.make, {
              openBranches: openBranches,
              onClose: onClose
            });
        break;
    case "Notifications" :
        tmp$3 = React.createElement(WorkspaceNotifications.make, {
              onClose: onClose
            });
        break;
    case "ServiceAccounts" :
        tmp$3 = React.createElement(ServiceAccounts.make, {
              viewerhasEditorPermission: role === "Admin" || role === "Editor"
            });
        break;
    case "Domains" :
        tmp$3 = React.createElement(WorkspaceDomains.make, {
              onClose: onClose
            });
        break;
    
  }
  return React.createElement(Modal.make, {
              title: "Workspace settings",
              description: tmp,
              background: "White",
              headerBackground: "OffWhite",
              onClose: onClose,
              toolbar: React.createElement("div", {
                    className: tabsStyles
                  }, Belt_Array.map(tabs, (function (thisTab) {
                          return React.createElement("button", {
                                      key: tabName(thisTab),
                                      className: currentTab === thisTab ? selectedTabItem : tabItem,
                                      onClick: (function (param) {
                                          Curry._1(setCurrentTab, (function (param) {
                                                  return thisTab;
                                                }));
                                        })
                                    }, React.createElement("div", {
                                          className: currentTab === thisTab ? selectedInnerItem : Curry._1(Css.style, /* [] */0)
                                        }, tabName(thisTab)));
                        }))),
              footer: tmp$2,
              children: tmp$3
            });
}

var make = SettingsModal;

export {
  make ,
}
/* tabsStyles Not a pure module */
