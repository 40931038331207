// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Select from "../Select.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Tooltip from "../Tooltip.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Markdown from "../Markdown.mjs";
import * as Tooltips from "../markdown/tooltips/tooltips.mjs";
import * as AvoLimits from "../AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "../NamedBranch.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as TrackingPlanModel from "../../../model/src/TrackingPlanModel.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as PropertyAbsenceViewHelpers from "../PropertyAbsenceViewHelpers.mjs";

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding4(Css.px(12), Css.px(16), Css.px(12), Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderTopRightRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.borderTopLeftRadius(Styles.Border.radius),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function tableBoxStyles(useBottomRadius) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.pct(100.0)),
              tl: {
                hd: Css.overflow("auto"),
                tl: {
                  hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                  tl: {
                    hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
                    tl: {
                      hd: Css.borderBottomLeftRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                      tl: {
                        hd: Css.borderBottomRightRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function tableStyles(useBottomRadius) {
  return Curry._1(Css.style, {
              hd: Css.borderSpacing(Css.px(0)),
              tl: {
                hd: Css.minWidth(Css.pct(100.0)),
                tl: {
                  hd: Css.selector(" tbody tr:last-child td:first-child", {
                        hd: Css.borderBottomLeftRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.selector(" tbody tr:last-child td:last-child", {
                          hd: Css.borderBottomRightRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var tableHeaderCellStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(16)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light02),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.selector(":first-child", {
                    hd: Css.position("sticky"),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: /* [] */0
                    }
                  }),
              tl: {
                hd: Css.selector(":last-child", {
                      hd: Css.borderRightWidth(Css.px(0)),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.selector(":not(:first-child)", {
                        hd: Css.textAlign("center"),
                        tl: {
                          hd: Css.verticalAlign("middle"),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var tableNameCellStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(16)),
      tl: {
        hd: Css.borderLeftWidth(Css.px(0)),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.position("sticky"),
              tl: {
                hd: Css.left(Css.px(0)),
                tl: {
                  hd: Css.background(Styles.Color.white),
                  tl: {
                    hd: Css.zIndex(1),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var tableSelectCellStyles = Curry._1(Css.style, {
      hd: Css.selector(":last-child", {
            hd: Css.borderRightWidth(Css.px(0)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.padding(Css.px(4)),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.textAlign("center"),
              tl: {
                hd: Css.verticalAlign("middle"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function SystemPropertySources(Props) {
  var model = Props.model;
  var sources = Props.sources;
  var viewerCanEdit = Props.viewerCanEdit;
  var property = Props.property;
  var sendActions = Props.sendActions;
  var schemaBundle = SchemaBundleContext.use();
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use();
  var match = AvoLimits.PropertyAbsenceByEventAndSource.computeAvailability(workspace);
  var available = match === "AvailableDuringTrial" || match === "Available";
  var match$1 = React.useState(function (param) {
        return PropertyAbsenceViewHelpers.getDefaultModeForAbsenceSystemProp(sources, property.absence);
      });
  var setMode = match$1[1];
  var mode = match$1[0];
  var tmp;
  if (mode === "BySource") {
    var absenceMap = PropertyAbsenceViewHelpers.getFullSysAbsenceMap(sources, property.absence);
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: tableBoxStyles(true)
            }, React.createElement("table", {
                  className: tableStyles(true)
                }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("td", {
                              className: tableHeaderCellStyles
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.light11,
                                  children: "Source Name"
                                })), React.createElement("td", {
                              className: tableHeaderCellStyles
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.light11,
                                  children: "Presence"
                                })))), React.createElement("tbody", undefined, Belt_MapString.valuesToArray(Belt_MapString.mapWithKey(absenceMap, (function (sourceId, state) {
                                return React.createElement("tr", undefined, React.createElement("td", {
                                                className: tableNameCellStyles
                                              }, React.createElement($$Text.make, {
                                                    size: "Small",
                                                    weight: "Semi",
                                                    children: Belt_Option.mapWithDefault(Belt_List.getBy(sources, (function (source) {
                                                                return source.id === sourceId;
                                                              })), "Untitled", (function (source) {
                                                            return Belt_Option.getWithDefault(source.name, "Untitled");
                                                          }))
                                                  })), React.createElement("td", {
                                                className: tableSelectCellStyles
                                              }, React.createElement(Select.make, {
                                                    disabled: state === "Unavailable" || !available,
                                                    onSelect: (function (newState) {
                                                        var state = PropertyAbsenceViewHelpers.parseEventAndSourceStateString(newState);
                                                        var tmp;
                                                        switch (state) {
                                                          case "Unavailable" :
                                                          case "Never" :
                                                              tmp = "NeverSent";
                                                              break;
                                                          case "Sometimes" :
                                                              tmp = {
                                                                TAG: "SometimesSent",
                                                                _0: ""
                                                              };
                                                              break;
                                                          case "Always" :
                                                              tmp = undefined;
                                                              break;
                                                          
                                                        }
                                                        Curry.app(sendActions, [
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              (function (branch) {
                                                                  var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                  var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup(model, property);
                                                                  var match = property.absence;
                                                                  var tmp;
                                                                  tmp = match !== undefined && typeof match === "object" ? (
                                                                      match.TAG === "SometimesSent" ? "SometimesSent" : "DependingOnSource"
                                                                    ) : "AlwaysSent";
                                                                  AnalyticsRe.propertyConfigured(propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schema.schemaId, schema.schemaName, schema.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, "Presence", "UpdatePropertyPresenceByEventAndSource", undefined, tmp, undefined, "DependingOnSource", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle.schemaId);
                                                                }),
                                                              undefined,
                                                              [[
                                                                  {
                                                                    NAME: "UpdatePropertyAbsence",
                                                                    VAL: [
                                                                      property.id,
                                                                      {
                                                                        TAG: "EventSource",
                                                                        _0: TrackingPlanModel.propertyAbsenceAllEventsKey,
                                                                        _1: sourceId
                                                                      },
                                                                      tmp
                                                                    ]
                                                                  },
                                                                  {
                                                                    eventId: TrackingPlanModel.propertyAbsenceAllEventsKey,
                                                                    eventQuery: TrackingPlanModel.propertyAbsenceAllEventsKey,
                                                                    propertyId: property.id,
                                                                    propertyQuery: property.id
                                                                  }
                                                                ]]
                                                            ]);
                                                      }),
                                                    options: Belt_Array.map(state === "Unavailable" ? ["Unavailable"] : [
                                                            "Never",
                                                            "Sometimes",
                                                            "Always"
                                                          ], (function (mode) {
                                                            return [
                                                                    {
                                                                      NAME: "Label",
                                                                      VAL: PropertyAbsenceViewHelpers.getEventAndSourceStateString(mode)
                                                                    },
                                                                    PropertyAbsenceViewHelpers.getEventAndSourceStateString(mode)
                                                                  ];
                                                          })),
                                                    value: PropertyAbsenceViewHelpers.getEventAndSourceStateString(state)
                                                  })));
                              })))))));
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: headerStyles
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("row"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          element: "H1",
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: "Sent with all events"
                        }), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: {
                            itemId: property.id,
                            itemName: property.name,
                            itemType: "Property"
                          },
                          title: "System Properties",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.systemProperties
                                    })
                              })
                        })), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(Select.make, {
                      disabled: !viewerCanEdit,
                      onSelect: (function (modeString) {
                          var newMode = PropertyAbsenceViewHelpers.parseModeString(modeString);
                          if (!available && newMode === "BySource") {
                            return Curry._1(globalSend, {
                                        TAG: "OpenModal",
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "ConfigureEventsPerPlatform"
                                        }
                                      });
                          }
                          var absenceDefaultMode = PropertyAbsenceViewHelpers.getDefaultModeForAbsenceSystemProp(sources, property.absence);
                          var confirmationCopy = "Switching to " + (PropertyAbsenceViewHelpers.getModeString(newMode) + " will cause your config to change. Are you sure you want to proceed?");
                          var systemPropsAbsencesMap = PropertyAbsenceViewHelpers.getFullSysAbsenceMap(sources, property.absence);
                          var showConfirm = !PropertyAbsenceViewHelpers.sourcesEmptyOrEverythingSometimes(systemPropsAbsencesMap) && PropertyAbsenceViewHelpers.getMoreComplex(absenceDefaultMode, newMode) !== newMode;
                          var shouldProceed = showConfirm ? window.confirm(confirmationCopy) : true;
                          if (!shouldProceed) {
                            return ;
                          }
                          var exit = 0;
                          switch (mode) {
                            case "Always" :
                                switch (newMode) {
                                  case "SometimesAll" :
                                      exit = 1;
                                      break;
                                  case "Always" :
                                  case "SometimesByEvent" :
                                  case "BySource" :
                                      break;
                                  
                                }
                                break;
                            case "SometimesAll" :
                                switch (newMode) {
                                  case "Always" :
                                      exit = 2;
                                      break;
                                  case "SometimesAll" :
                                  case "SometimesByEvent" :
                                  case "BySource" :
                                      break;
                                  
                                }
                                break;
                            case "SometimesByEvent" :
                                switch (newMode) {
                                  case "Always" :
                                      exit = 2;
                                      break;
                                  case "SometimesAll" :
                                      exit = 1;
                                      break;
                                  case "SometimesByEvent" :
                                  case "BySource" :
                                      break;
                                  
                                }
                                break;
                            case "BySource" :
                                switch (newMode) {
                                  case "Always" :
                                      if (!Caml_obj.equal(property.absence, "AlwaysSent")) {
                                        exit = 2;
                                      }
                                      break;
                                  case "SometimesAll" :
                                      var match = property.absence;
                                      var tmp;
                                      tmp = match !== undefined && !(typeof match !== "object" || match.TAG !== "SometimesSent") ? true : false;
                                      if (!tmp) {
                                        exit = 1;
                                      }
                                      break;
                                  case "SometimesByEvent" :
                                  case "BySource" :
                                      break;
                                  
                                }
                                break;
                            
                          }
                          switch (exit) {
                            case 1 :
                                var id = property.id;
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                          var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup(model, property);
                                          AnalyticsRe.propertyConfigured(propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schema.schemaId, schema.schemaName, schema.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, "Presence", "UpdatePropertyPresenceGlobally", undefined, PropertyAbsenceViewHelpers.analyticsPresenceTypeBefore(mode), undefined, "SometimesSent", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle.schemaId);
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdatePropertyAbsence",
                                            VAL: [
                                              id,
                                              {
                                                TAG: "Event",
                                                _0: TrackingPlanModel.propertyAbsenceAllEventsKey
                                              },
                                              {
                                                TAG: "SometimesSent",
                                                _0: ""
                                              }
                                            ]
                                          },
                                          {
                                            propertyId: id,
                                            propertyQuery: id
                                          }
                                        ]]
                                    ]);
                                break;
                            case 2 :
                                var id$1 = property.id;
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                          var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup(model, property);
                                          AnalyticsRe.propertyConfigured(propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schema.schemaId, schema.schemaName, schema.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, "Presence", "UpdatePropertyPresenceGlobally", undefined, PropertyAbsenceViewHelpers.analyticsPresenceTypeBefore(mode), undefined, "AlwaysSent", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle.schemaId);
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdatePropertyAbsence",
                                            VAL: [
                                              id$1,
                                              {
                                                TAG: "Event",
                                                _0: TrackingPlanModel.propertyAbsenceAllEventsKey
                                              },
                                              undefined
                                            ]
                                          },
                                          {
                                            propertyId: id$1,
                                            propertyQuery: id$1
                                          }
                                        ]]
                                    ]);
                                break;
                            
                          }
                          Curry._1(setMode, (function (param) {
                                  return newMode;
                                }));
                        }),
                      options: Belt_Array.map(Belt_Array.keepU(PropertyAbsenceViewHelpers.modes, (function (mode) {
                                  return mode !== "SometimesByEvent";
                                })), (function (mode) {
                              var tmp;
                              tmp = available || mode !== "BySource" ? PropertyAbsenceViewHelpers.getModeString(mode) : "⚡️ " + PropertyAbsenceViewHelpers.getModeString("BySource");
                              return [
                                      {
                                        NAME: "Label",
                                        VAL: tmp
                                      },
                                      PropertyAbsenceViewHelpers.getModeString(mode)
                                    ];
                            })),
                      value: PropertyAbsenceViewHelpers.getModeString(mode)
                    })), tmp);
}

var make = SystemPropertySources;

export {
  headerStyles ,
  tableBoxStyles ,
  tableStyles ,
  tableHeaderCellStyles ,
  tableNameCellStyles ,
  tableSelectCellStyles ,
  make ,
}
/* headerStyles Not a pure module */
