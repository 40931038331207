// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "../ModelUtils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChangedEvent from "./ChangedEvent.mjs";
import * as DeletedEvent from "./DeletedEvent.mjs";
import * as AppFeatureFlag from "../AppFeatureFlag.mjs";
import * as BranchImplementStore from "./BranchImplementStore.mjs";
import * as BranchImplementationUtils from "./BranchImplementationUtils.mjs";

function ChangedEvents(Props) {
  var branchStatus = Props.branchStatus;
  var events = Props.events;
  var fromModel = Props.fromModel;
  var fromModelWithFlattenedEventVariants = Props.fromModelWithFlattenedEventVariants;
  var goToCommentId = Props.goToCommentId;
  var source = Props.source;
  var sourceUpdatesLoadingState = Props.sourceUpdatesLoadingState;
  var toModel = Props.toModel;
  var serverTrackingWithDeviceId = AppFeatureFlag.useFeatureFlag("ServerTrackingWithDeviceId");
  var eventSpecificPropertyValues = AppFeatureFlag.useFeatureFlag("EventSpecificPropertyValuesInCode");
  var diffViewType = BranchImplementStore.useDiffViewType();
  var enrichedModelRef = {
    contents: undefined
  };
  var enrichedFromModelRef = {
    contents: undefined
  };
  var enrichedModelWithoutValidSourceRef = {
    contents: undefined
  };
  var enrichedFromModelWithoutValidSourceRef = {
    contents: undefined
  };
  var getEnrichedModels = function (model, modelRef, modelWithoutValidSourceRef) {
    var validSource = AvoConfig.tryToConvertSourceToValidSourceMaybe(source, model);
    if (validSource !== undefined) {
      return [
              BranchImplementationUtils.getPreGeneratedModelWithValidSource(modelRef, validSource, serverTrackingWithDeviceId, eventSpecificPropertyValues, false, model),
              BranchImplementationUtils.getPreGeneratedModelWithValidSource(modelWithoutValidSourceRef, validSource, serverTrackingWithDeviceId, eventSpecificPropertyValues, true, model)
            ];
    } else {
      return [
              BranchImplementationUtils.getPreGeneratedModelWithoutValidSource(modelRef, model, serverTrackingWithDeviceId, eventSpecificPropertyValues, false, source),
              BranchImplementationUtils.getPreGeneratedModelWithoutValidSource(modelWithoutValidSourceRef, model, serverTrackingWithDeviceId, eventSpecificPropertyValues, true, source)
            ];
    }
  };
  var match = React.useMemo((function (param) {
          return getEnrichedModels(toModel, enrichedModelRef, enrichedModelWithoutValidSourceRef);
        }), [
        source,
        toModel
      ]);
  var enrichedToModelSkipImplementWithAvoFiltering = match[1];
  var enrichedToModel = match[0];
  var match$1 = React.useMemo((function (param) {
          return getEnrichedModels(fromModel, enrichedFromModelRef, enrichedFromModelWithoutValidSourceRef);
        }), [
        source,
        fromModel
      ]);
  var enrichedFromModelSkipImplementWithAvoFiltering = match$1[1];
  var enrichedFromModel = match$1[0];
  var getEnrichedModelForEvent = function (source, $$event, modelType) {
    var match = source.platform;
    var match$1 = AvoConfig.tryToConvertSourceToValidSourceMaybe(source, modelType === "toModel" ? toModel : fromModel);
    if (modelType === "toModel") {
      if (match !== undefined && match === "Flutter" && BranchImplementationUtils.hasCodegenForSource($$event, source.id)) {
        return enrichedToModelSkipImplementWithAvoFiltering;
      }
      if (match$1 !== undefined && BranchImplementationUtils.hasCodegenForSource($$event, match$1.id)) {
        return enrichedToModel;
      } else {
        return enrichedToModelSkipImplementWithAvoFiltering;
      }
    }
    if (match !== undefined && match === "Flutter" && BranchImplementationUtils.hasCodegenForSource($$event, source.id)) {
      return enrichedFromModelSkipImplementWithAvoFiltering;
    }
    if (match$1 !== undefined && BranchImplementationUtils.hasCodegenForSource($$event, match$1.id)) {
      return enrichedFromModel;
    } else {
      return enrichedFromModelSkipImplementWithAvoFiltering;
    }
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.gap(Css.px(16)),
                            tl: {
                              hd: Css.empty({
                                    hd: Css.selector(" + section", {
                                          hd: Css.display("block"),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, Belt_List.toArray(Belt_List.map(Curry._1(BranchImplementationUtils.EventComparator.sort, events), (function ($$event) {
                            if ($$event.NAME === "Updated") {
                              var match = $$event.VAL;
                              var $$event$1 = match[0];
                              var enrichedToModel = getEnrichedModelForEvent(source, $$event$1, "toModel");
                              var enrichedFromModel = getEnrichedModelForEvent(source, $$event$1, "fromModel");
                              var tmp = {
                                branchStatus: branchStatus,
                                enrichedFromModel: enrichedFromModel,
                                enrichedToModel: enrichedToModel,
                                diffViewType: diffViewType,
                                event: $$event$1,
                                fromModel: fromModel,
                                fromModelWithFlattenedEventVariants: fromModelWithFlattenedEventVariants,
                                sourceId: source.id,
                                status: match[1],
                                sourceUpdatesLoadingState: sourceUpdatesLoadingState,
                                toModel: toModel,
                                key: $$event$1.id
                              };
                              if (goToCommentId !== undefined) {
                                tmp.goToCommentId = goToCommentId;
                              }
                              return React.createElement(ChangedEvent.make, tmp);
                            }
                            var $$event$2 = $$event.VAL[0];
                            var enrichedModel = getEnrichedModelForEvent(source, $$event$2, "fromModel");
                            var tmp$1 = {
                              branchStatus: branchStatus,
                              event: $$event$2,
                              toModel: toModel,
                              sourceId: source.id,
                              enrichedModel: enrichedModel,
                              fromModel: fromModel,
                              fromModelWithFlattenedEventVariants: fromModelWithFlattenedEventVariants,
                              diffViewType: diffViewType,
                              key: $$event$2.id
                            };
                            if (goToCommentId !== undefined) {
                              tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
                            }
                            return React.createElement(DeletedEvent.make, tmp$1);
                          })))), React.createElement("section", {
                  className: Curry._1(Css.style, {
                        hd: Css.label("empty-events"),
                        tl: {
                          hd: Css.padding(Css.px(32)),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.light02),
                                tl: {
                                  hd: Css.maxWidth(Css.px(600)),
                                  tl: {
                                    hd: Css.display("none"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Could not codegen, source incomplete"
                    }), React.createElement(Spacer.make, {
                      height: 32
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: "Hold tight, we are still working out all the kinks for the Implement feature."
                    }), Belt_List.map(source.destinations, (function (param) {
                        return ModelUtils.getDestinationById(param.destinationId, toModel);
                      })) === /* [] */0 ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            height: 16
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light10,
                            children: "💡 It looks like your source doesn’t have any destinations. To create snippets you need to add a destination."
                          })) : null));
}

var make = ChangedEvents;

export {
  make ,
}
/* Css Not a pure module */
